import { render, staticRenderFns } from "./layerControls.vue?vue&type=template&id=0bd4462d&scoped=true"
import script from "./layerControls.vue?vue&type=script&lang=js"
export * from "./layerControls.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bd4462d",
  null
  
)

export default component.exports