<template>
  <div class="dashboard">
    <div v-if="loading">Loading...</div>
    <div v-else>
      <div class="mar-b-5 color-context-accent1-dark pad-5" style="border-radius: var(--br-2);border-width: var(--ln-5);">
      <h2 class="inline h2">Hey, help us make Steel Ninja even better!</h2>
      
      <button @click="openFeedback" class="btn btn btn-large btn-secondary" style="margin: 0px 20px;">Feedback
          <icon name="arrow-right-1" custom-class="icon-size-1" ></icon>
        </button>
      
      </div>

      <div>
        <button class="btn btn-small" @click="changeLog">Change Log</button>
        
      </div>

      <div>        
        Check out our help articles!
        <button class="btn btn-small" @click="navToHelp">Search Help</button>
      </div>
      
    </div>
  </div>
</template>

<script>
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapActions, mapMutations } from 'vuex';
import NavCard from '../components/NavCard.vue'
import News from './ChangeLog.vue';
export default {
  props: {
  },
  data() {
    return {
      loading: true,
    }
  },
  
  computed: {
    ...mapState('contextModule', ['current']),
    mode() {
      return this.current.mode
    },
    linkLabels() {
      console.log('dashboard mode', this.mode)
      if (this.mode === 0) {
        return {
          dashboard: 'System Dashboard',
          entityManager: 'Companies and Contractors',
          onboarding: 'Company Onboarding',
          entityInfo: 'Entity Info',
          entitySettings: 'Settings'
        };
      } else if (this.mode === 1) {
        return {
          dashboard: this.current.supplier?.businessName ?? "Loading",
          entityManager: 'Contractors',
          onboarding: 'Contractor Onboarding',
          entityInfo: 'Company Info',
          entitySettings: 'Settings'
        };
      } else if (this.mode === 2) {
        return {
          dashboard: this.current.contractor?.businessName ?? "def",
          onboarding: 'Employee Onboarding',
          entityInfo: 'Contractor Info',
          entitySettings: 'Settings'
        };
      }
      // Default fallback
      return {
        entityManager: 'Entity Manager',
        onboarding: 'Onboarding'
      };
    },
    pricingRoutePath() {
      if (this.$route.name == 'dashboard') {
        return '/pricing'
      }
      else
        return `${this.$route.path}/pricing`
    },
    colorRoutePath() {
      if (this.$route.name == 'dashboard') {
        return '/colors'
      }
      else
        return `${this.$route.path}/colors`
    },
    projectManagerRoutePath() {
      if (this.$route.name == 'dashboard') {
        return '/projects'
      }
      else
        return `${this.$route.path}/projects`
    },
    entityInfoRoutePath() {
      if (this.$route.name == 'dashboard') {
        let currentUser = JSON.parse(sessionStorage.getItem('user'));
        return `/entity/${currentUser.businessId}/info`;
      }
      else {
        return `${this.$route.path}/info`;
      }
    },
    entitySettingsRoutePath() {
      if (this.$route.name == 'dashboard') {
        let currentUser = JSON.parse(sessionStorage.getItem('user'));
        return `settings`;
      }
      else {
        return `${this.$route.path}/settings`;
      }
    },
    entityManagerRoutePath() {
      if (this.$route.name == 'dashboard') {
        return '/entities/list'
      }
      else {
        return `${this.$route.path}/contractors`
      }
    }
  },
  methods: {
    ...mapActions('businessModule', ['fetchBusiness','getBusinessSetting']),    
    navToHelp(){
      this.$router.push({ name: 'help' })
    },
    changeLog(){
      this.$router.push({ name: 'changeLog' })
    },
    openFeedback(){
      window.open("https://docs.google.com/forms/d/1dSq3T72hseOq5cEXSty-Gi34vxDzfoKcEiuUP572OPw/edit#settings", "_blank")
    },
    beforeRouteUpdate(to, from, next) {
      console.log('beforeRouteUpdate');
      next();
    },
    async doInit() {

      console.log('dashboard: created');
      let redirectUrl = sessionStorage.getItem('redirectUrl')
      if (redirectUrl && redirectUrl != '/') {
        console.log('in dashboard, redirecting to: ', redirectUrl)
        sessionStorage.removeItem('redirectUrl');
        this.$router.push({ path: redirectUrl }) // relative path
      }
      console.log('in dashboard: ', redirectUrl)

      await ContextLogic.initContext(this);

      this.loading = false;
    },
  },
  async created() {
    await this.doInit();
    if(this.mode == 1){
    }
  },
  mounted() {
    if (!this.$auth.isAuthenticated) {
      console.log('dashboard mounted: not authenticated');
      return;
    }
  },
  components: {
    NavCard,
    News
  }
}
</script>

<style scoped>
.dashboard {
  padding: 20px;
}

.card-group {
  /* margin-top: 20px; */
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.card-group a {
  margin-right: 10px;
  text-decoration: none;
  color: #3498db;
}
</style>