<template>
    <div>
        <SnuiWrapper>
        <div class="box-shadow-1 mar-0 pad-0 color-context-neutral-light" style="border-radius: var(--br-4); overflow: hidden;">
          <div class="grid gap-4 border-be pad-i-4 pad-b-4" style="grid-template-columns: 1fr auto; align-items: center;">
            <div>
              <h3 class="h5" id="p-250128171119-title">Global Tools</h3>
            </div>
            <div>
              <button class="block" data-popover1-trigger="close" aria-controls="p-250128171119" aria-expanded="false">
                <span class="sr-only">Close Popover</span>
                <Icon name="delete-1" custom-class="icon block icon-size-1"/>
              </button>
            </div>
          </div><!-- grid -->
          <div class="tabs-container" id="tabs-container-250207164836">
            <div class="tabs" role="tablist" aria-label="Content sections">
              <!-- adjust the width of the first column to be just longer than the longest tab when it's active (bold font is a little wider than normal font). The second column should be set to accomodate the main panel content of all the tabs nicely and also fit on most screens. -->
              <div class="grid" style="grid-template-columns: 11rem 19rem;">
                <div class="border-ie pad-i-4 pad-b-4">
                  <div class="grid gap-3">
                    <div v-for="(t, index) in tools">
                      <button v-on:click="runTool(t)"
                        role="tab" 
                        aria-selected="true" 
                        :aria-controls="'panel-'+t.id" 
                        :id="t.id" 
                        :tabindex="index"
                        class="tab tab-button-2"
                        :class="{active: t.id === tool.current.id}">
                        <span>{{ t.name }}</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="pad-b-4 pad-i-4">
                    <div v-if="tool.current.id==='skirt'"
                      role="tabpanel" 
                      id="panel-skirt" 
                      aria-labelledby="tab-skirt"
                      class="tab-panel active"
                      :class="{active : tool.current.id==='skirt'}">
                      <div>
                        <toolSkirt ref="toolSkirt" :iTool="tool.current"/>
                        <div class="grid mar-bs-6" style="column-gap: var(--sp-4); row-gap: var(--sp-5); grid-template-columns: 1fr 1fr">
                          <div>
                              <button @click="$emit('closePopover')" class="btn btn-small">Cancel</button>
                          </div>
                          <div>
                              <button @click="applySkirt" :disabled="isSkirtToolDisabled" class="btn btn-small btn-secondary">Apply</button>
                          </div>
                        </div>
                      </div>
                    </div>
          
                    <div v-if="tool.current.id==='wallHeight'"
                      role="tabpanel" 
                      id="panel-wallHeight" 
                      aria-labelledby="tab-wallHeight"
                      class="tab-panel active" 
                      :class="{active : tool.current.id==='wallHeight'}">
                      <div>
                        <toolWallHeight ref="toolWallHeight" :iTool="tool.current"/>
                        <div class="grid mar-bs-6" style="column-gap: var(--sp-4); row-gap: var(--sp-5); grid-template-columns: 1fr 1fr">
                          <div>
                            <button @click="$emit('closePopover')" class="btn btn-small">Cancel</button>
                          </div>
                          <div>
                              <button @click="applyWallHeight" class="btn btn-small btn-secondary">Apply</button>
                          </div>
                        </div>
                      </div>
                    </div>
          
                    <div v-if="tool.current.id==='inset'"
                      role="tabpanel" 
                      id="panel-inset" 
                      aria-labelledby="tab-inset"
                      class="tab-panel"
                      :class="{active : tool.current.id==='inset'}">
                      <div>
                        <toolInset ref="toolInset" :iTool="tool.current"/>
                        <div class="grid mar-bs-6" style="column-gap: var(--sp-4); row-gap: var(--sp-5); grid-template-columns: 1fr 1fr">
                          <div>
                            <button @click="$emit('closePopover')" class="btn btn-small">Cancel</button>
                          </div>
                          <div></div>
                          <div>
                              <button @click="applyInset" class="btn btn-small btn-secondary">Apply</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div><!-- grid -->
              
            </div><!-- tabs -->
          </div><!-- tab container -->
        </div><!-- box -->
      </SnuiWrapper>
    </div>
</template>

<script>
import SnuiWrapper from '@/components/SnuiWrapper.vue';
import ToolSkirt from './toolSkirt.vue';
import ToolWallHeight from './toolWallHeight.vue';
import ToolInset from './toolInset.vue';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';



 export default {
    props: {
     
    },
    components:{
        SnuiWrapper,
        ToolSkirt,
        ToolWallHeight,
        ToolInset
    },
    data: ()=>{
        return {
            
        };
    },
    computed:{
        ...mapGetters('designModule', [
            'tool',
            'tools',
        ]),
        isSkirtToolDisabled(){
          return this.$refs.toolSkirt ? this.$refs.isSkirtToolDisabled : false;
        }
    },
    methods: {     
        ...mapActions('designModule', [
            'setInitStageAppMounted',
            'runTool',
            'selectComponent',
            'switchToCamera',
            'cycleOrbitCamera',
            'saveDesign',
            'applyToolData'
        ]),
        ...mapMutations('designModule',[
        'showToolModal',      
        ]), 
        applySkirt(){
          this.$refs.toolSkirt.apply();
          this.applyToolLogic();
        },
        applyWallHeight(){
          this.$refs.toolWallHeight.apply();
          this.applyToolLogic();
        },
        applyInset(){
          this.$refs.toolInset.apply();
          this.applyToolLogic();
        },
        applyToolLogic(){
          this.closeGlobalToolModal();
        },
        closeGlobalToolModal(){
          this.$emit('closePopover');
        }
    },
    mounted(){
      this.runTool(this.tools[0])
    },
    created(){
        console.log(this.tools)
    }
  }
</script> 
  
<style scoped>
  

</style>
  
  
  
  