<template>
  <div id="pricing">
    <h1 class="h1">Pricing Configuration</h1>
    <br>
    <div style="text-align: left;font-size:medium">
      <p>
       
      </p>
   
    </div>
    <br/>
    <p style="text-align:left;font-size:medium"><b>Default Pricing</b> is used when a project is quoted without a contractor assigned. <br>
      For more information please visit <a style="font-weight: bold;text-decoration: underline;" target="_blank" :href="getPricingModelHelpLink()">Pricing Models</a>.<br/>    
    </p>
    <br/>
    <table style="border-collapse: collapse;">
      <tr>
        <th>
          Default Pricing
        </th>
        <th>
          Pricing Model
        </th>
        <th>
          Bolt-Up Pricing
        </th>
        <th>
          Weld-Up Pricing
        </th>        
      </tr>
      <BusinessPricingRecord :disable="!canUpdatePricing" :pBusiness="defaultPricing" v-on:update="updateBusiness" :key="defaultPricing.id"></BusinessPricingRecord>
      <br/>
      <tr>
        <th>
          Contractor Pricing
        </th>
        <th>
          Pricing Model
        </th>
        <th>
          Bolt-Up Pricing
        </th>
        <th>
          Weld-Up Pricing
        </th>        
      </tr>
      <BusinessPricingRecord v-for="cont in contractors" :disable="!canUpdatePricing" :pBusiness="cont" v-on:update="updateBusiness" :key="cont.businessId"></BusinessPricingRecord>
    </table>
  </div>        
</template>

<script>
import api from '@/api';
import EntityPricingRecord from "./BusinessPricingRecord.vue"
import { mapState, mapGetters , mapActions, mapMutations } from 'vuex';
import BusinessPricingRecord from './BusinessPricingRecord.vue';
export default {
  name: 'ContractorPricing',
  data() {
    return {
      defaultPricing: {},
      contractors: [],
      canUpdatePricing: false,
    };
  },
  computed: {
    ...mapState('contextModule', ['current'
      ,'user'
      ,'userBusiness']),

    ...mapGetters('contextModule',[
      'userIsSysAdmin'
      ,'userIsSupAdmin'
      ,'userIsContAdmin'
    ]),
    mode(){
      return this.current.mode
    },
  },
  methods: {
    async updateBusiness(businessPricing){
      console.log('updateBusiness');
      await api.setPricingConfig(businessPricing.businessId, businessPricing);
    },
    async getContractorPricing(){
      let contractorResp = await api.getPricingConfig(this.current.supplier.id);
      return contractorResp
    },
    getPricingModelHelpLink(){
      let helpArticle =  `${process.env.VUE_APP_SITE_BASE_URL}/support/PricingModels#`
      return helpArticle
    }
  },
  async created(){
    
  },
  async mounted(){
    this.contractors = await this.getContractorPricing();
    // pull out the company default as it's own setting
    this.defaultPricing = this.contractors.filter( x => x.businessId == this.current.supplier.id)[0]
    // remove the company default from the list for contractors
    this.contractors = this.contractors.filter( x => x.businessId != this.current.supplier.id)

    let isContractorAdmin = this.userIsContAdmin;
    let isSupplierAdmin = this.userIsSupAdmin;
    let isSystemAdmin = this.userIsSysAdmin;
    console.log(`contractor admin: ${isContractorAdmin} || supplier admin: ${isSupplierAdmin} || system admin: ${isSystemAdmin}`)
    this.canUpdatePricing = isSupplierAdmin || isSystemAdmin; // must be a supplier admin or a system admin
  },
  components:{
    BusinessPricingRecord
  }
};
</script>

<style scoped>
td, th{
  border:1px solid black;
  padding:5px;
  
}
th {
  font-size: medium;
}

#pricing {
  border: 2px solid gray;
  padding:10px;
  height: fit-content;
}

</style>