<template>
  <div class="sidebar-item-1 level-1">    
    <div  @mousemove="hover = true" @mouseleave="hover = false">      
      <div v-if="components.length>1"  style="">
        <!-- current component button  -->
        <div class='componentSelector btn' v-if="advDesignMode">
          <div class="test" style='display:inline;margin-right:3px;' @click='context'>{{getDescription}}</div>           
          <font-awesome-icon @click="picking = getId"  icon="fa-duotone fa-chevron-down" size='xl' style="padding-left:3px; border-left: 1px solid white"/>
        </div>

        <!-- child select list  -->
        <div style='display:inline;position:absolute;z-index:99999;background:white' @mouseleave="picking=0;">
          <div class='componentSelector' v-if='picking===getId' style="display:block;">
            <div class="componentSelectorOption" v-for="comp in components" :key="comp.id" @click="() => {selectSibling(comp)}" :class="{disabled : comp.id == selected}">
               {{comp.description}}
            </div>
            
          </div>
        </div>
        <!-- this does nothing because of vue-ifs-->
        <!-- <CompPicker  v-if="components.length==1" :comps="components[0].components" style="margin-left:15px"/> -->
        <!-- recurse for this component's children -->
        <CompPicker ref='children' :comps="getComponents" style="margin-left:25px"/>
      </div>

      <div v-if="components.length==1" class='componentSelector btn' @click='context'>{{components[0].description}}</div>

      <!-- indent and show the next tier-->
      <CompPicker  v-if="components.length==1 " :comps="components[0].components" style="margin-left:15px"/>
    </div> 
    
  </div>
</template>

<script>
//import treeObjectList  from './treeObjectList.vue'
import CompPicker from './compPicker.vue';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  name: 'CompPicker',
  
  props: {
    comps: {},
    describe: false,
    recurse: false,
    
  },
  data: ()=>{    
      return {
        hover: false,
        selected: 0        
      };
  },
  computed:{
    ...mapState('designModule',[
      'picking'
    ]),
    ...mapGetters('designModule', [
      'latestSelectedComponentAncestory',
    ]),    
    getId(){
      if(this.selectedComp === null || this.selectedComp === undefined){
        return 0
      }
      return this.selectedComp.id
    },
    getDescription(){
      if(this.selectedComp === null || this.selectedComp === undefined){        
        return 'placeholder'
      }
      return this.selectedComp.description
    },
    getComponents(){
      let arr = [];
      if(this.selectedComp === undefined)
        return arr
      return this.selectedComp.components
    },
    advDesignMode(){
        //return this.$store.getters.designMode === designModes.advanced;
        return true;
    },    
    selectedComp() {
        console.log('selected and comp', this.selected, this.components)
        let result;
            if(this.components.length>1)// || this.selected==0)
              result = this.components.filter((c) => this.idInLatestSelectedAncestry(c.id))[0];            
          
          if(!result)
          {
            // if there's only 1 or none of the children are selected, auto-select the first
            result = this.components[0];
          }
          console.log('result',result)
          return result;
        },
    components:{
        get(){
          return this.comps;
        }
    }
  },
  mounted(){
    this.doInit();
  },
  methods: {
    

...mapActions('designModule',[
        'selectComponent',        
        'setSelectedComponentId',
        'showEditComponentOptions',
      ]),
    idInLatestSelectedAncestry(id){
      let list = this.latestSelectedComponentAncestory;
      return list.includes(id);
    },
    doInit(){
      console.log('doInit props', this.comps)
      if(this.components.length>0)
      {
        let first  = this.components[0].id;
        console.log('first', first);
        this.selected = first
      }
    },
      open(){
        this.picking = true;
      },
      select: function(){
        this.selectComponent(this.selectedComp.id);
      },
      selectSibling(comp){
        this.picking = 0;
        this.selected=comp.id;
        this.setSelectedComponentId(comp.id);
        this.context(); 
      },
      context: function(){
        this.select();
        this.showEditComponentOptions(this.selectedComp.id);
      },
      change: function(){
        this.select();
        this.context();
      },

  },
  components:{
    treeObjectList: () => import('./treeObjectList.vue'),
    CompPicker
  }
}
</script> 


<style scoped>

.selected{
  background: white;
  border: solid 1px black;
}

.collided {
  color: red;
  font-weight:bold;
}

.component:hover{
  /* background: lightgray; */
}

.component.selected:hover{
  background: white;
  border: solid 1px black;
}

.componentSelector{
  padding:2px;
  border-radius: 4px;  
  display: inline-block;
  white-space: nowrap;  
  cursor: pointer;
}

.componentSelectorOption{
  padding:2px;
}


.componentSelectorOption:hover{
  background: #5e92df;
  color: white;
}

div.component{
  /* cursor: pointer; */
  border-radius: 4px;  
}


.hideOptionArrow{
  visibility: hidden;
}

.disabled{
  color:darkgray
}
</style>
