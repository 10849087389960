<template>
    <div>   
        <template>
            <div class="settingsGroup">
                <div class="settingsGroupHeader"><b>Project Settings</b></div>   
                <div class="settingsGroupBody">
                        <div class="setting">
                            <div class="" style="display: inline;">Roof Purlin Max Spacing<StatusIndicator ref="si_defaultMaxRoofPurlinSpacing"></StatusIndicator></div>
                            <div class="settingValue num">
                                <label class="composite-field">
                                    <input type="number" class="digit3 align-end" @input="dbUserSettings({id:'qt_defaultMaxPurlinSpacing',val:qt_defaultMaxPurlinSpacing})" v-model="qt_defaultMaxPurlinSpacing" min="0" max="24" :disabled="true"/> 
                                    <div class="text">feet</div>
                                </label>
                            </div>
                            <div class="settingDescription"></div>
                            <label class="block">(Coming soon!) Maximum space in feet between purlin</label>                            
                        </div>
                    </div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <span for="qt_defaultRoofPurlinCondition">Roof Purlin Condition</span><StatusIndicator ref="si_defaultRoofPurlinCondition" :successText="'Saved'"></StatusIndicator>    
                            <user-setting-dropdown @change="setUserSetting" :customStyle="{display:'inline'}"
                                            ref="qt_defaultRoofPurlinCondition"
                                            id="qt_defaultRoofPurlinCondition"
                                            :val="qt_defaultRoofPurlinCondition"
                                            name=""
                                            
                                            :options="[
                                            {name:'Flush', value:'Flush'},
                                            {name:'Bypass', value:'Bypass'},
                                            ]">                                        
                            </user-setting-dropdown>
                            
                            <label class="block">Whether roof purlins are mounted flush or bypass by default</label>
                        </div>
                    </div>
                    <div class="settingsGroupBody" v-if="true">
                        <div class="setting">
                            <div class="" style="display: inline;">Peak Purlin Space<StatusIndicator ref="si_peakSpaceSelection"></StatusIndicator></div>
                            <div class="settingValue num">
                                <label class="composite-field">
                                    <input type="number" class="digit3 align-end" @input="dbUserSettings({id:'qt_peakSpaceSelection',val:qt_peakSpaceSelection})" v-model="qt_peakSpaceSelection" min="0" max="24" :disabled="true || !canUpdateSupplierSettings"/>
                                    <div class="text">feet</div>
                                </label>
                            </div>
                            <div class="settingDescription"></div>                                
                            <label class="block">(Coming soon!) Space in inches from peak to first purlin </label>
                            
                        </div>
                    </div>
                    <!-- <div class="settingsGroupBody" v-if="showSupplierOnlySetting">
                        <div class="setting">
                            <div class="" style="display: inline;">Taper Frame Width<StatusIndicator ref="si_startTaperFrameWidth"></StatusIndicator></div>
                            <div class="settingValue num"><input  disabled="true" type="number" class="digit3" @input="dbUserSettings({id:'qt_startTaperFrameWidth',val:qt_startTaperFrameWidth})" v-model="qt_startTaperFrameWidth" min="0" max="24" /></div>
                            <div class="settingDescription"> feet</div>
                            <label class="block">(Coming soon!) </label>    
                            
                        </div>
                    </div> -->
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <div class="" style="display: inline;">Wall Girt Max Spacing<StatusIndicator ref="si_defaultMaxGirtSpaceFt"></StatusIndicator></div>
                            <div class="settingValue num">
                                <label class="composite-field">
                                    <input type="number" class="digit3 align-end" @input="dbUserSettings({id:'qt_defaultMaxGirtSpaceFt',val:qt_defaultMaxGirtSpaceFt})" v-model="qt_defaultMaxGirtSpaceFt" min="0" max="24" :disabled="true || !canUpdateSupplierSettings && !canUpdateContractorSettings"/>
                                    <div class="text">feet</div>
                                </label>
                            </div>
                            <div class="settingDescription"></div>
                            <label class="block">(Coming soon!) Maximum space in feet between girting.</label>    
                            
                        </div>
                    </div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <span for="qt_defaultWallGirtCondition">Wall Girt Condition</span><StatusIndicator ref="si_defaultWallGirtCondition" :successText="'Saved'"></StatusIndicator>    
                            <user-setting-dropdown @change="setUserSetting" :customStyle="{display:'inline'}"
                                            ref="qt_defaultWallGirtCondition"
                                            id="qt_defaultWallGirtCondition"
                                            :val="qt_defaultWallGirtCondition"
                                            name=""
                                            :disable="true"
                                            :options="[
                                            {name:'Flush', value:'Flush'},
                                            {name:'Bypass', value:'Bypass'},
                                            ]">                                        
                            </user-setting-dropdown>
                            
                            <label class="block">(Coming Soon!) Whether wall girts are mounted Flush or Bypass with columns</label>
                        </div>
                    </div>
            
                <div class="settingsGroupBody">
                    <div class="setting">
                        <span for="des_gutter">Gutters</span>
                    <user-setting-checkbox @change="setUserSetting" :customStyle="{display:'inline'}"
                                            ref="des_gutter"
                                            id="des_gutter"
                                            :val="des_gutter"                                            
                                            
                                            >
                    </user-setting-checkbox>
                    <StatusIndicator ref="si_gutter" :successText="'Saved'"></StatusIndicator>    
                    <label class="block">Include gutter trim by default</label>
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <span for="des_dripTrim">Drip Trim</span>
                        <user-setting-checkbox  @change="setUserSetting" :customStyle="{display:'inline'}"
                                                ref="des_dripTrim"
                                                id="des_dripTrim"
                                                :val="des_dripTrim"
                                                
                                                description="">
                        </user-setting-checkbox>
                        <StatusIndicator ref="si_dripTrim" :successText="'Saved'"></StatusIndicator>    
                        <label class="block">Include drip trim by default</label>
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <span for="des_tubePorchColTrim">Wrap Tube Porch Columns with Trim</span>
                        <user-setting-checkbox  @change="setUserSetting" :customStyle="{display:'inline'}"
                                                ref="des_tubePorchColTrim"
                                                id="des_tubePorchColTrim"
                                                :val="des_tubePorchColTrim"
                                                
                                                description="">
                        </user-setting-checkbox>
                        <StatusIndicator ref="si_tubePorchColTrim" :successText="'Saved'"></StatusIndicator>    
                        <label class="block">Include trim to wrap square tube porch columns by default</label>
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <span for="des_deadboltPrep">Deadbolt Prep on Walk 3o7o and 4o7o Walks</span>
                        <user-setting-checkbox  @change="setUserSetting" :customStyle="{display:'inline'}"
                                                ref="des_deadboltPrep"
                                                id="des_deadboltPrep"
                                                :val="des_deadboltPrep"
                                                
                                                description="">
                        </user-setting-checkbox>
                        <StatusIndicator ref="si_deadboltPrep" :successText="'Saved'"></StatusIndicator>    
                        <label class="block">Include a dead blot on compatible walk doors by default</label>
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <span for="des_jambCounterFlashing">Jamb Counter Flashing</span>
                        <user-setting-checkbox  @change="setUserSetting" :customStyle="{display:'inline'}"
                                                ref="des_jambCounterFlashing"
                                                id="des_jambCounterFlashing"
                                                :val="des_jambCounterFlashing"
                                                
                                                description="">
                        </user-setting-checkbox>
                        <StatusIndicator ref="si_jambCounterFlashing" :successText="'Saved'"></StatusIndicator>    
                        <label class="block">Include counter flashing by default</label>
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <span for="des_ohJambLiner">Overhead Jamb Liner</span>
                        <user-setting-checkbox  @change="setUserSetting" :customStyle="{display:'inline'}"
                                                ref="des_ohJambLiner"
                                                id="des_ohJambLiner"
                                                :val="des_ohJambLiner"
                                                
                                                description="">
                        </user-setting-checkbox>
                        <StatusIndicator ref="si_ohJambLiner" :successText="'Saved'"></StatusIndicator>    
                        <label class="block">Include overhead jamb liner by default</label>
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <span for="des_tackyTapeRoof">Tacky Tape Roof</span>
                        <user-setting-checkbox  @change="setUserSetting" :customStyle="{display:'inline'}"
                                                ref="des_tackyTapeRoof"
                                                id="des_tackyTapeRoof"
                                                :val="des_tackyTapeRoof"
                                                
                                                description="">
                        </user-setting-checkbox>
                        <StatusIndicator ref="si_tackyTapeRoof" :successText="'Saved'"></StatusIndicator>    
                        <label class="block">Include roof tacky tape by default</label>
                    </div>
                </div>
                <!-- <div class="settingsGroupBody">
                    <div class="setting">
                        <span for="qt_tackyTapeAll">Tacky Tape Over 2:12</label>
                        <user-setting-checkbox  @change="setUserSetting" :customStyle="{display:'inline'}"
                                                ref="qt_tackyTapeAll"
                                                id="qt_tackyTapeAll"
                                                :val="qt_tackyTapeAll"
                                                
                                                description="">
                        </user-setting-checkbox>
                        <StatusIndicator ref="si_tackyTapeAll" :successText="'Saved'"></StatusIndicator>    
                    </div>
                </div> -->
                <div class="settingsGroupBody">
                    <div class="setting">
                        <span for="des_touchUpPaint">Touch Up Paint</span>
                        <user-setting-checkbox  @change="setUserSetting" :customStyle="{display:'inline'}"
                                                ref="des_touchUpPaint"
                                                id="des_touchUpPaint"
                                                :val="des_touchUpPaint"
                                                
                                                description="">
                        </user-setting-checkbox>
                        <StatusIndicator ref="si_touchUpPaint" :successText="'Saved'"></StatusIndicator>    
                        <label class="block">Include touch up paint by default</label>
                    </div>
                </div>
               
                
                <div class="settingsGroupBody">
                    <div class="setting">
                        <span for="des_levelOfDetail">Rendering Detail Level</span>
                        <user-setting-dropdown  @change="setUserSetting" :customStyle="{display:'inline'}"
                                                ref="des_levelOfDetail"
                                                id="des_levelOfDetail"
                                                :val="des_levelOfDetail"
                                                name="Rendering Detail Level"
                                                
                                                :options="[{name:'Low', value:'low'},{name:'High', value:'high'}]">
                        </user-setting-dropdown>
                        <StatusIndicator ref="si_levelOfDetail" :successText="'Saved'"></StatusIndicator>    
                        <label class="block">Level of detail at which to render designs</label>
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <span for="des_insulationWall">Wall Insulation</span><StatusIndicator ref="si_insulationWall" :successText="'Saved'"></StatusIndicator>    
                        <user-setting-dropdown  @change="setUserSetting" :customStyle="{display:'inline'}"
                                                ref="des_insulationWall"
                                                id="des_insulationWall"
                                                :val="des_insulationWall"
                                                
                                                
                                                :options="[{name:'None', value:'None'},{name:'VR.3_(R_10)', value:'VR.3_(R_10)'},{name:'VR.4_(R_13)', value:'VR.4_(R_13)'},{name:'VR.6_(R_19)', value:'VR.6_(R_19)'}]">
                        </user-setting-dropdown>
                        
                        <label class="block">The wall insulation, if any, to use by default</label>
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <span for="des_insulationRoof">Roof Insulation</span><StatusIndicator ref="si_insulationRoof" :successText="'Saved'"></StatusIndicator>    
                        <user-setting-dropdown  @change="setUserSetting" :customStyle="{display:'inline'}"
                                                ref="des_insulationRoof"
                                                id="des_insulationRoof"
                                                :val="des_insulationRoof"
                                                
                                                
                                                :options="[{name:'None', value:'None'},{name:'VR.3_(R_10)', value:'VR.3_(R_10)'},{name:'VR.4_(R_13)', value:'VR.4_(R_13)'},{name:'VR.6_(R_19)', value:'VR.6_(R_19)'}]">
                        </user-setting-dropdown>
                        <label class="block">The roof insulation, if any, to use by default</label>
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <span for="des_windowStyle">Window Style</span><StatusIndicator ref="si_windowStyle" :successText="'Saved'"></StatusIndicator>    
                        <user-setting-dropdown  @change="setUserSetting" :customStyle="{display:'inline'}"
                                                ref="des_windowStyle"
                                                id="des_windowStyle"
                                                :val="des_windowStyle"
                                                
                                                
                                                :options="[{name:'Double Hung', value:'doubleHung'},{name:'Picture', value:'picture'}]">
                        </user-setting-dropdown>
                        <label class="block">Style of window to use by default</label>
                    </div>
                </div>
               

                <!-- <div class="settingsGroupBody">
                    <div class="setting">
                        <div class="" style="display: inline;">Peak Purlin Space <StatusIndicator ref="si_peakSpace"></StatusIndicator></div>
                        <div class="settingValue num"><input type="number" class="digit2" @input="dbUserSettings({id:'des_peakSpace',val:des_peakSpace})" v-model="des_peakSpace" max="12" min="3" /></div>
                        <div class="settingDescription">inches from peak</div>
                    </div>
                </div> -->

                <div class="settingsGroupBody">
                    <div class="setting">
                        <div class="" style="display: inline;">Overhead Door Margins<StatusIndicator ref="si_marginOhd"></StatusIndicator></div>
                        <div class="settingValue num">
                            <label class="composite-field">
                                <input type="number" class="digit3 align-end" @input="dbUserSettings({id:'des_marginOhdSide',val:des_marginOhdSide})" v-model="des_marginOhdSide" min="0" />
                                <div class="text">sides (inches)</div>
                            </label>
                            
                        </div>
                        <div class="settingDescription"></div>
                        <div class="settingValue num">
                            <label class="composite-field">
                                <input type="number" class="digit3 align-end" @input="dbUserSettings({id:'des_marginOhdTop',val:des_marginOhdTop})" v-model="des_marginOhdTop" min="0" />
                                <div class="text">top (inches)</div>
                            </label>
                        </div>
                        <div class="settingDescription"></div>                        
                        <label class="block">Margin, in inches, to reserve on the sides and top of overhead doors</label>
                    </div>
                </div>
                
                <div class="settingsGroupBody">
                    <div class="setting">
                        <div class="" style="display: inline;">Window Margins<StatusIndicator ref="si_marginWindowEdge"></StatusIndicator></div>
                        <div class="settingValue num">
                            <label class="composite-field">
                                <input type="number" class="digit3 align-end" @input="dbUserSettings({id:'des_marginWindowEdge',val:des_marginWindowEdge})" v-model="des_marginWindowEdge" min="0" max="24" />
                                <div class="text">perimeter (inches)</div>
                            </label>
                        </div>
                        <div class="settingDescription"></div>                        
                        <label class="block">Margin, in inches, to reserve around windows</label>
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <div class="" style="display: inline;">Wainscot Height <StatusIndicator ref="si_wainscotHeight"></StatusIndicator></div>
                        <div class="settingValue num">
                            <label class="composite-field">
                                <input type="number" class="digit3 align-end" @input="dbUserSettings({id:'des_wainscotHeight',val:des_wainscotHeight})" v-model="des_wainscotHeight" min="12" />
                                <div class="text">inches tall</div>
                            </label>
                            
                        </div>
                        <div class="settingDescription"></div>
                        <label class="block">Default height of waiscot.</label>
                        
                    </div>
                </div>
                <div class="section-group-title"><b>Color Configuration</b></div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <div class="" >
                            <StatusIndicator ref="si_color"></StatusIndicator>
                        </div>                        
                        <div class="settingDescription">Roof Panel Color </div>
                        <div class="settingValue">
                            <color-select2
                                    :settingName="'des_colorRoof'"
                                    :selected="des_colorRoof"
                                    :colors="panelColors"
                                    
                                    @change="setUserSetting">
                            </color-select2>        
                        </div>
                        <label class="block">default roof panel color</label>
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <div class="settingDescription">Wall Panel Color</div>
                        <div class="settingValue">
                            <color-select2
                                            :settingName="'des_colorWall'"
                                            :selected="des_colorWall"
                                            :colors="panelColors"
                                            
                                            @change="setUserSetting">
                            </color-select2>
                        </div>
                        <label class="block">default wall panel color</label>
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <div class="settingDescription">Wainscoting Panel Color</div>
                        <div class="settingValue">
                            <color-select2
                                            :settingName="'des_colorWainscoting'"
                                            :selected="des_colorWainscoting"
                                            :colors="panelColors"
                                            
                                            @change="setUserSetting">
                            </color-select2>
                        </div>
                        <label class="block">default wainscot panel color</label>
                    </div>
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <div class="settingDescription">Trim Panel Color</div>
                        <div class="settingValue">
                            <color-select2
                                            :settingName="'des_colorTrim'"
                                            :selected="des_colorTrim"
                                            :colors="trimColors"
                                            
                                            @change="setUserSetting">
                            </color-select2>
                        </div>
                        <label class="block">default trim color</label>
                    </div>                    
                </div>
                <div class="settingsGroupBody">
                    <div class="setting">
                        <span for="des_wainscotTrimColor">Wainscot Trim Color</span><StatusIndicator ref="si_caulkColor" :successText="'Saved'"></StatusIndicator>    
                        <user-setting-dropdown  @change="setUserSetting" :customStyle="{display:'inline'}"
                                                ref="des_wainscotTrimColor"
                                                id="des_wainscotTrimColor"
                                                :val="des_caulkColor"
                                                
                                                :options="[{name:'Wainscot', value:'Wainscot'},{name:'Wall', value:'Wall'},{name:'Trim', value:'Gutter'}]">
                        </user-setting-dropdown>
                        
                        <label class="block">Default wainscot color</label>
                    </div>
                </div>        
                <div class="settingsGroupBody">
                    <div class="setting">
                        <span for="des_caulkColor">Caulk Color</span><StatusIndicator ref="si_caulkColor" :successText="'Saved'"></StatusIndicator>    
                        <user-setting-dropdown  @change="setUserSetting" :customStyle="{display:'inline'}"
                                                ref="des_caulkColor"
                                                id="des_caulkColor"
                                                :val="des_caulkColor"
                                                
                                                :options="[{name:'Clear', value:'Clear'},{name:'Wall', value:'Wall'},{name:'Trim', value:'Gutter'}]">
                        </user-setting-dropdown>
                        
                        <label class="block">Select the default color for this trim section</label>
                    </div>
                </div>
                <div class="settingsGroupBody" v-for="item in trimColorCategories" :key="item.settingName">
                    <div class="setting">
                        <span for="item.settingName">{{ item.type }}</span><StatusIndicator :ref="statusRefs[item.settingName]" :successText="'Saved'"></StatusIndicator>    
                        <user-setting-dropdown  @change="setUserSetting" :customStyle="{display:'inline'}"
                                                :ref="item.settingName"
                                                :id="item.settingName"
                                                :val="getDynamicSettingValue(item.settingName)"
                                                
                                                :options="colorInheritanceOptions">
                        </user-setting-dropdown>
                        
                        <label class="block">Select the default color for this trim section</label>
                    </div>
                </div>
            </div>
        </template>
    </div>
  </template>
<script>
// @ is an alias to /src
import SwmAddAddress from '@/components/swm-add-address.vue';
//import swmbox  from '@/components/swmbox.vue';
import userSettingCheckbox from '@/components/user-setting-checkbox.vue';
import userSettingDropdown from '@/components/user-setting-dropdown.vue';
import colorSelect2 from '@/components/color-select2.vue';
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapActions, mapMutations } from 'vuex';
import Vue from 'vue'
import Vuex from 'vuex'
import StatusIndicator from '@/components/StatusIndicator.vue';
import api from '@/api';
import Util from '../lib/utility.js'
import NinjaColorHelper from '@/lib/colorHelper.js';
Vue.use(Vuex)


export default {
  name: 'UserProjectSettings',
  components:{
    SwmAddAddress,
    //swmbox,
    userSettingCheckbox,
    userSettingDropdown,
    colorSelect2,
    StatusIndicator
  },
  props:{
    viewingUserId:{
        type:Number
    }
  },
  data(){
    return {  
        entity: {},
        supplierPanelItems: [],
        supplierTrimProfiles:[],
        editing: false,
        allColors:[],
        trimColors:[],
        panelColors:[],
        
        
        colorInheritanceOptions:[{name: "Roof Panel Color", value: "Roof_Panel_Color"}, 
                                 {name:"Wall Panel Color", value: "Wall_Panel_Color"}, 
                                 {name:"Trim Color", value:"Trim_Color"}, 
                                 {name:"Wainscot Panel Color", value:"Wainscot_Panel_Color"}],
        trimColorCategories: [
            {type:'Trim Angle',settingName: "des_trimAngleColor"},
            {type:'Trim Corner',settingName: "des_trimCornerColor"},
            {type:'Trim Downspout',settingName: "des_trimDownspoutColor"},
            {type:'Trim Drip',settingName: "des_trimDripColor"},
            {type:'Trim Eave',settingName: "des_trimEaveColor"},
            {type:'Trim Gutter',settingName: "des_trimGutterColor"},
            {type:'Trim Head',settingName: "des_trimHeadColor"},
            {type:'Trim Jamb',settingName: "des_trimJambColor"},
            {type:'Trim JambLiner',settingName: "des_trimJambLinerColor"},
            {type:'Trim Partition',settingName: "des_trimPartitionColor"},
            {type:'Trim Rake',settingName: "des_trimRakeColor"},
            {type:'Trim Ridge',settingName: "des_trimRidgeColor"},
            {type:'Trim Transition',settingName: "des_trimTransitionColor"},
            {type:'Trim Wainscot',settingName: "des_trimWainscotColor"},
            {type:'Trim Valley',settingName: "des_trimValleyColor"}
        ],
        trimProfilesCategories:[
            {type:'Rake Profile',settingName: "qt_trimRakeProfile"},
            {type:'Ridge Profile',settingName: "qt_trimRidgeProfile"},
            {type:'Gutter Profile',settingName: "qt_trimGutterProfile"},
            {type:'Downspout Profile',settingName: "qt_trimDownspoutProfile"},
            {type:'Inside Corner Profile',settingName: "qt_trimInsideCornerProfile"},
            {type:'Outside Profile',settingName: "qt_trimOutsideCornerProfile"},
            //{type:'Head Profile',settingName: "qt_trimHeadProfile"},
            //{type:'Jamb Profile',settingName: "qt_trimJambProfile"},
            {type:'Drip/Rat Profile',settingName: "qt_trimDripRatProfile"},
            {type:'Eave Profile',settingName: "qt_trimEaveProfile"},

        ],      
        statusRefs: {   // settingName : ref
             "legalName": "si_legalName",
              "businessName": "si_businessName",
              "phoneNumber": "si_phoneNumber",
              "url": "si_url",
              "mailingAddress1": "si_mailingAddress1",
              "mailingAddress2": "si_mailingAddress2",
              "mailingAddressCity": "si_cityStateZip",
              "mailingAddressState": "si_cityStateZip",
              "mailingAddressZip": "si_cityStateZip",

              "logo_Sml": "si_logoSml",
              "logo_Lrg": "si_logoLrg",

              "qt_defaultMaxPurlinSpacing": "si_defaultMaxRoofPurlinSpacing",
              "qt_defaultMaxGirtSpaceFt": "si_defaultMaxGirtSpaceFt",
              "qt_defaultWallGirtCondition": "si_defaultWallGirtCondition",
              "qt_defaultRoofPurlinCondition": "si_defaultRoofPurlinCondition",
              "qt_peakSpaceSelection": "si_peakSpaceSelection",
              "qt_startTaperFrameWidth": "si_startTaperFrameWidth",
              
              "des_gutter": "si_gutter",
              "des_dripTrim": "si_dripTrim",
              "des_tubePorchColTrim": "si_tubePorchColTrim",
              "des_deadboltPrep": "si_deadboltPrep",
              "des_jambCounterFlashing": "si_jambCounterFlashing",
              "des_ohJambLiner": "si_ohJambLiner",
              "des_tackyTapeRoof": "si_tackyTapeRoof",
              "des_plusGirtsCutToLength": "si_plusGirtsCutToLength",
              "des_levelOfDetail": "si_levelOfDetail",
              "des_insulationWall": "si_insulationWall",
              "des_insulationRoof": "si_insulationRoof",
              "des_windowStyle": "si_windowStyle",
              "des_wainscotHeight": "si_wainscotHeight", 
              "des_peakSpace": "si_peakSpace",
              "des_marginOhdSide": "si_marginOhd",
              "des_marginOhdTop": "si_marginOhd",
              "des_marginWindowEdge": "si_marginWindowEdge",
              "des_colorRoof": "si_color",
              "des_colorWall": "si_color",
              "des_colorWainscoting": "si_color",
              "des_colorTrim": "si_color",

              "des_tackyTapeAll": "si_tackyTapeAll",
              "des_caulkColor": "si_caulkColor",
              "des_touchUpPaint": "si_touchUpPaint",     
              "des_wainscotTrimColor": "si_wainscotTrimColor",
              "qt_defaultRoofPanel": "si_defaultRoofPanel",
              "qt_defaultWallPanel": "si_defaultWallPanel",
              "qt_defaultSoffitPanel": "si_defaultSoffitPanel",

              "qt_trimRakeProfile": "si_trimRakeProfile",
              "qt_trimRidgeProfile": "si_trimRidgeProfile",
              "qt_trimGutterProfile": "si_trimGutterProfile",
              "qt_trimDownspoutProfile": "si_trimDownspoutProfile",
              "qt_trimInsideCornerProfile": "si_trimInsideCornerProfile",
              "qt_trimOutsideCornerProfile": "si_trimOutsideCornerProfile",
            //   "qt_trimHeadProfile": "si_trimHeadProfile",
            //   "qt_trimJambProfile": "si_trimJambProfile",
              "qt_trimDripRatProfile": "si_trimDripRatProfile",
              "qt_trimEaveProfile": "si_trimEaveProfile",
  
              "des_trimAngleColor": "si_angleColor",
              "des_trimCornerColor": "si_cornerColor",
              "des_trimDownspoutColor": "si_downspoutColor",
              "des_trimDripColor": "si_dripColor",
              "des_trimEaveColor": "si_eaveColor",
              "des_trimGutterColor": "si_gutterColor",
              "des_trimHeadColor": "si_headColor",
              "des_trimJambColor": "si_jambColor",
              "des_trimJambLinerColor": "si_jambLinerColor",
              "des_trimPartitionColor": "si_partitionColor",
              "des_trimRakeColor": "si_rakeColor",
              "des_trimRidgeColor": "si_ridgeColor",
              "des_trimTransitionColor": "si_transitionColor",
              "des_trimWainscotColor": "si_wainscotColor",
              "des_trimValleyColor": "si_valleyColor",
        },
      classes: {
        container: "settingsGroup",
        header: "settingsGroupHeader",
        body: "settingsGroupBody",
        setting: "setting",
        value: "settingValue",
        description: "settingDescription"
      },
      isAuthenticated: false,
        showSupplierOnlySetting: false,
        canUpdateBusinessInfo: false,
        canUpdateSupplierSettings: false,
        canUpdateContractorSettings: false,

        qt_defaultMaxPurlinSpacing: 5.1,
        qt_defaultMaxGirtSpaceFt: 5,
        qt_defaultWallGirtCondition: "Flush",
        qt_defaultRoofPurlinCondition: "Bypass",
        qt_peakSpaceSelection: 1,
        qt_startTaperFrameWidth: 60,
        
        qt_directFabCostPerDay: 650,
        qt_boltPurlinPcsPerDay: 50,
        qt_boltBeamPcsPerDay: 20,
        qt_detailCostPerHour: 70,
        qt_fabLaborSellPerLb: 1.02,
        qt_beamGalvCostPerLb: 0.50,
        qt_beamGalvSellPerLb: 0.60,
        qt_purlinGalvAdd: 1.20,
        qt_fastenerLongLifeCost: 0.12,
        qt_FastenerLongLifeMult: 2.23,
        qt_roofFieldScrewsPerSq: 40,
        qt_roofLapScrewsPerSq: 20,
        qt_wallFieldScrewsPerSq: 40,
        qt_wallLapScrewsPerSq: 30,
        qt_tackyTapeAll: false,
        des_caulkColor: "Gutter",
        des_touchUpPaint: true,
        des_wainscotTrimColor: "Wall",
        qt_defaultRoofPanel: "PBR26C",
        qt_defaultWallPanel: "PBR26C",
        qt_defaultSoffitPanel: "RBU26C",
        des_angleColor: "Trim_Color",
        des_cornerColor: "Trim_Color",
        des_downspoutColor: "Trim_Color",
        des_dripColor: "Wall_Panel_Color",
        des_eaveColor: "Trim_Color",
        des_gutterColor: "Trim_Color",
        des_headColor: "Trim_Color",
        des_jambColor: "Trim_Color",
        des_jambLinerColor: "Trim_Color",
        des_partitionColor: "Wall_Panel_Color",
        des_rakeColor: "Trim_Color",
        des_ridgeColor: "Roof_Panel_Color",
        des_transitionColor: "Roof_Panel_Color",
        des_wainscotColor: "Wainscot_PanelColor",
        des_valleyColor: "Roof_Panel_Color",
        qt_ninjaFee: "Add to subtotal",
        qt_trimRakeProfile: "RA-Com",
        qt_trimRidgeProfile: "RC10-s",
        qt_trimGutterProfile: "GU-Com",
        qt_trimDownspoutProfile: "DS-k",
        qt_trimInsideCornerProfile: "IC",
        qt_trimOutsideCornerProfile: "OU",
        qt_trimDripRatProfile: "Base",
        qt_trimEaveProfile: "LEA",

        logo_Sml: '',
        logo_Lrg: '',
        des_dripTrim: false,
        des_gutter: true,
        des_wainscotHeight: 42,
        des_tubePorchColTrim: true,
        des_deadboltPrep: true,
        des_jambCounterFlashing: false,
        des_ohJambLiner: true,
        des_tackyTapeRoof: false,
        des_plusGirtsCutToLength: false,
        des_insulationRoof: 'VR.3_(R_10)',
        des_insulationWall: 'VR.3_(R_10)',
        des_windowStyle: 'doubleHung',
        des_levelOfDetail: 'low',
        des_peakSpace: 12,
        des_marginOhdSide: 4,
        des_marginOhdTop: 15,
        des_marginWindowEdge: 9,
        des_colorRoof: null,
        des_colorWall: null,
        des_colorWainscoting: null,
        des_colorTrim: null,
    }
  },
  computed:{
    ...mapState('contextModule',[
      'user'
      ,'userBusiness'
      ,'current'
    ]),
    mode(){
      return this.current.mode
    },
    async getToken(){
      let token = await this.$auth.getIdTokenClaims();
      console.log(token)
      console.log(this.$auth)
      return token.userRoles[0];
    },
  },
  methods: {
    ...mapActions('businessModule', ['getBusinessSettings','updateBusinessInfo', 'fetchBusiness', 'updateBusinessInfo','getBusinessTrimProfiles']),
    backToBusinesses(){
        // this needs to become fully centralized if every settings page is going to need it
        ContextLogic.clearBusinessContext(this.$store);

        if(this.userBusiness.type==1)
            this.$router.push({path: `/supplier/${this.userBusiness.id}/contractors`})
        else
            this.$router.push({path: `/businesses/list`})
    },
    getDynamicSettingValue(settingName){
        return this[settingName];
    },
    async setUserSetting(data){
        var val;
        // if the object has a hex property, it's a color setting, 
        if (data.val.designerHex)
            val = data.val.id; // use color.id for colors (changed week of 2023.11.07)
        else{
            val = `${data.val}`
        }
            
        let settingsGroup = {
            name: data.id,
            value: String(val)
        }
        let payload = {
            userId: this.viewingUserId,
            settingsGroup: settingsGroup
        }

        let statusRefId = this.statusRefs[data.id]; // use the setting name to know the setting status indicator name
        let si = this.$refs[statusRefId]; // use the setting status indicator name to get the status indicator component
        if(si != undefined)
        {
            if(Array.isArray(si)){ 
                // Vue takes overwhen refs are in an v-for context and makes the Ref an array no matter what.
                si= si[0];
            }
            si.loading();
        }

        let response = await api.setUserSetting(payload);

        if(response.isError){
            si.fail();
        }
        else{
            si.success();
        }
    },
    defaultAllSettings() {
      this.qt_defaultMaxPurlinSpacing = 5.1;
      this.qt_defaultMaxGirtSpaceFt = 5;
      this.qt_defaultWallGirtCondition = "Flush";
      this.qt_defaultRoofPurlinCondition = "Bypass";
      this.qt_peakSpaceSelection = 1;
      this.qt_startTaperFrameWidth= 60,
      
      this.qt_directFabCostPerDay = 650;
      this.qt_boltPurlinPcsPerDay = 50;
      this.qt_boltBeamPcsPerDay = 20;
      this.qt_detailCostPerHour = 70;
      this.qt_fabLaborSellPerLb = 1.02;
      this.qt_beamGalvCostPerLb = 0.50;
      this.qt_beamGalvSellPerLb = 0.60;
      this.qt_purlinGalvAdd = 1.20;
      this.qt_fastenerLongLifeCost = 0.12;
      this.qt_FastenerLongLifeMult = 2.23;
      this.qt_roofFieldScrewsPerSq = 40;
      this.qt_roofLapScrewsPerSq = 20;
      this.qt_wallFieldScrewsPerSq = 40;
      this.qt_wallLapScrewsPerSq = 30;
      this.qt_tackyTapeAll = false;
      this.des_caulkColor = "Gutter";
      this.des_touchUpPaint = true;
      this.des_wainscotTrimColor = "Wall";
      this.qt_defaultRoofPanel = "PBR26C";
      this.qt_defaultWallPanel = "PBR26C";
      this.qt_defaultSoffitPanel = "RBU26C";
      this.des_angleColor = "Trim_Color";
      this.des_cornerColor = "Trim_Color";
      this.des_downspoutColor = "Trim_Color";
      this.des_dripColor = "Wall_Panel_Color";
      this.des_eaveColor = "Trim_Color";
      this.des_gutterColor = "Trim_Color";
      this.des_headColor = "Trim_Color";
      this.des_jambColor = "Trim_Color";
      this.des_jambLinerColor = "Trim_Color";
      this.des_partitionColor = "Wall_Panel_Color";
      this.des_rakeColor = "Trim_Color";
      this.des_ridgeColor = "Roof_Panel_Color";
      this.des_transitionColor = "Roof_Panel_Color";
      this.des_wainscotColor = "Wainscot_PanelColor";
      this.des_valleyColor = "Roof_Panel_Color",
      this.qt_ninjaFee= "Add to subtotal";
      this.qt_trimRakeProfile = "RA-Com";
      this.qt_trimRidgeProfile = "RC10-s";
      this.qt_trimGutterProfile = "GU-Com";
      this.qt_trimDownspoutProfile = "DS-k";
      this.qt_trimInsideCornerProfile = "IC";
      this.qt_trimOutsideCornerProfile = "OU";
      this.qt_trimDripRatProfile = "Base";
      this.qt_trimEaveProfile = "LEA";


      this.logo_Sml = '';
      this.logo_Lrg = '';
      this.des_gutter = true;
      this.des_dripTrim = false;
      this.des_wainscotHeight = 42;
      this.des_tubePorchColTrim = true;
      this.des_deadboltPrep = true;
      this.des_jambCounterFlashing = false;
      this.des_ohJambLiner = true;
      this.des_tackyTapeRoof = false;
      this.des_plusGirtsCutToLength = false;
      this.des_insulationRoof = 'VR.3_(R_10)';
      this.des_insulationWall = 'VR.3_(R_10)';
      this.des_windowStyle = 'doubleHung';
      this.des_levelOfDetail = 'low';
      this.des_peakSpace = 12
      this.des_marginOhdSide = 4
      this.des_marginOhdTop = 15
      this.des_marginWindowEdge = 9

      this.des_colorRoof = this.allColors.length > 0 ? NinjaColorHelper.first(this.allColors).id : -1;
      this.des_colorWall = this.allColors.length > 0 ? NinjaColorHelper.first(this.allColors).id : -1;
      this.des_colorWainscoting = this.allColors.length > 0 ? NinjaColorHelper.first(this.allColors).id : -1;
      this.des_colorTrim = this.allColors.length > 0 ? NinjaColorHelper.first(this.allColors).id : -1;
    },

    async loadSettingValues(){
        let pathParts = this.$route.path.split("/");
        let businessId = pathParts[2];

        await this.defaultAllSettings();
        let response = await api.getUserSettings(this.viewingUserId);
        if(response.isError){
            this.$toast.error(response.msg);
        }
        else{
            let settings = response;
            this.initSettings(settings);
        }

    },
    initSettings(settings) {
      // assign the data values
      settings.forEach((settingsGroup) => {
          this.initSetting(settingsGroup);
      })               
      
      // assigning the logo value won't set the image, so apply the base64 data to the img src

    },
    initSetting(setting) {
        if (setting.value === "true" || setting.value === "false"){
            this[setting.name] = setting.value === 'true';

            return;
        }
            
        // if (setting.name === "des_colorRoof" || setting.name === "des_colorWall" || 
        //     setting.name === "des_colorWainscoting" || setting.name === "des_colorTrim") {            
        //         this[setting.name] = Number(setting.value);
        //         return
        // }
        if (typeof setting.value !== 'undefined') {
            this[setting.name] = setting.value;
            return;
        }
    },
    async initPage(){
        this.isAuthenticated = this.$auth.isAuthenticated;
        let isContractorAdmin = this.user.roles.includes(2);
        let isSupplierAdmin = this.user.roles.includes(1);
        let isSystemAdmin = this.user.roles.includes(0);
        console.log(`contractor admin: ${isContractorAdmin} || supplier admin: ${isSupplierAdmin} || system admin: ${isSystemAdmin}`)

        // set page context
        this.showSupplierOnlySetting = this.mode == 1;
        this.canUpdateSupplierSettings = isSupplierAdmin || isSystemAdmin;
        this.canUpdateContractorSettings = isContractorAdmin || isSystemAdmin;
        this.canUpdateBusinessInfo = this.canUpdateSupplierSettings || this.canUpdateContractorSettings;
        console.log(`can update contractor settings: ${this.canUpdateContractorSettings}`)
        console.log(`can update supplier settings: ${this.canUpdateSupplierSettings}`)
        console.log(`can update entity info: ${this.canUpdateBusinessInfo}`)
            
        // get data for viewing user
        // clear data first 
        this.allColors = [];
        this.panelColors = [];
        this.trimColors = [];
        

        // System admins don't have a supplier and thus do not have colors
        let currentViewingUser = await api.getUser(this.viewingUserId);
        let isViewingUserSysAdmin = currentViewingUser.roles.includes(0)

        if(!isViewingUserSysAdmin){
            let supplierId = await api.getSupplierForUser(this.viewingUserId);
            this.allColors = await api.getSupplierColors(supplierId);
            this.panelColors = NinjaColorHelper.panelColors(this.allColors);
            this.trimColors = NinjaColorHelper.trimColors(this.allColors);
        }

        this.loadSettingValues();
    }
  },
  created() {
    this.dbUserSettings = Util.debounce(this.setUserSetting,1000);
  },
  async mounted() {
    // Get context data for logged in user
    await ContextLogic.initContext(this);
    await this.initPage();

  },
  watch:{
    async viewingUserId(){
        await this.initPage()
    },
  }
}
</script>

<style>

@media (max-width: 4924px) {
       .settingsGroup {
           font-size: 1.3em;
       }
   }
   @media (max-width: 1280px) {
       .settingsGroup {
           font-size: 1.2em;
       }
   }
   @media (max-width: 1024px) {
       .settingsGroup {
           font-size: 1.1em;
       }
   }
   @media (max-width: 768px) {
       .settingsGroup {
           font-size: 1.0em;
       }
   }
   @media (max-width: 640px) {
       .settingsGroup {
           font-size: 1.0em;
       }
   }
   .settingGroupList {
       width: 90%;
       margin: auto;
   }

   .settingsContainer {
       display: flex;
       flex-flow: row wrap;
       justify-content: space-evenly;
       align-items: flex-start;
   }

   .settingsItem {
       display: block;
       margin: 5px;
       padding: 5px;
       margin-left: 20px;
       border: 1px solid lightgrey;        
       width:100%;
       text-align: left;
   }

   /* parent  */
   
   .settingsContainer {
       flex-grow: 2;
   }


   .settingsGroupList {
       display: flex;
       flex-flow: row wrap;

   }

   /* item */
   .settingsGroup {
       display: block;
       margin: 5px;
       padding: 2px;
   }

   .settingsGroup .settingsGroupBody {
       display: block;
       flex-grow: 1;
       color: black;
       flex-shrink: 2;
       text-align: left;       
   }

   .flex {
       display: flex;
       flex-flow: row wrap;
   }

   .settingsGroup .settingsGroupHeader {
       display: block;
       font-weight: bold;
       width:100%;
       background: lightgray;
       padding: 10px;
       text-align: left;
   }

   .setting {
       display: inline-block;
       margin: 10px;
       vertical-align: top;
   }

   .settingsGroup .settingDescription {
       display: inline-block;
       margin: 3px 0 0 5px;
       font-size: .8em;
       vertical-align: top;
       
   }

   .settingsGroup .settingValue {
       flex-grow: 1;
       text-align: left;
       display: inline-block;
       margin-left: 10px;
       
   }        


   .settingValue input {       
       flex-shrink: 1;
   }

   .settingValue .designLogoSml {
       margin: 3px;
       display: block;
       text-align: left
   }

   .settingValue .designLogoSml img {
       margin: 3px;
       max-width: 150px;
       box-shadow: 0px 0px 2px black;
       display: inline-block;
       text-align: center;
       margin-left: auto;
       margin-right: auto;
       background: black
   }

   .settingValue .designLogoLrg img {
       margin: 3px;
       max-width: 480px;
       box-shadow: 0px 0px 2px black;
       display: inline-block;
       text-align: center;
       margin-left: auto;
       margin-right: auto;
   }

   .hidden {
       display: none;
   }

   .logoTypeLabel {
       width: 120px;
       display: block;
       font-size: .7em;
   }

   .horsep {
       border-top: 1px solid lightgray;
       display:inline-block;
       margin:auto;
       height:10px;
       width:95%;
       text-align:center;
   }

   .digit2 {
       width: 51px;
   }
   .digit3 {
       width: 76px;
   }

   input[type=checkbox] {
       transform: scale(1.3);
       margin-right: 10px;     
       margin-bottom: 3px;
   }

   .logo-input{
        display: inline-flex;
        margin: 20px 50px;
    }

    .logo-lrg {
        width: 300px;
        
    }

    .logo-sml {
      width: 80px;
      border-radius: 15px;
      /* background-color: #f0f1f5; */
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      
      img {
        max-width: 100%;
        /* border-radius: 50%; */
      }
    }

    .section-group-title{
        margin: 15px 10px;
        text-align: left;
        background: rgb(231, 231, 231)
    }

    input.w-s{
        width:100px;
    }
    input.w-m{
        width:350px;
    }
    input.w-l{
        width:500px;
    }

</style>
