export default class NinjaColorHelper{

    static panelColors = (allColors) => allColors.filter(c => c.forPrime)

    static trimColors = (allColors) => allColors.filter(c => c.forTrim)

    static finishColors = (allColors) => allColors.filter(c => c.forFinish)

    static first = (allColors) => {
        if(!allColors || !allColors.length)
            return null;
        const keys = Object.keys(allColors);
        const firstKey = keys[0];
        const firstValue = allColors[firstKey];
        return firstValue;
    }

    static findById = (allColors, id) => allColors.filter(c => c.id == id)[0];


}