<template>
  <div id="designer" class="designer-body" style="height:100%">
    
    <v-dialog transition="pop-out" />    
    <div id="loading" class="overlayLoading" v-if="this.loading" >
      <div class="loadingHeader">
        {{loadingMessage}}
        <img class="loadingSpinner" :src="require('@/assets/images/ajax-spinner.gif')" />
      </div>
    </div>

    <!-- Errored Message Popup -->
    <div id="loadFailed" class="overlayErrored" v-if="this.errored" >
       <div class="loadingHeader" style='width:85%'>        
        {{this.error.message}}<br />
        <br />
        <div style='max-width: 1000px;margin:auto' v-if="!isEditable">
          <b>Design Incompatability Detected</b><br>
          The 3d Designer has been updated and cannot load this design at the moment - Sorry for the inconvenience. We're working to provide backwards compatibility. Thanks for your patience.
          To go back, click <b>Back.</b><br />  
          <div class="btn" style='display:inline-block; width:fit-content; margin-top:6px; padding:6px;' @click="close" >Back</div>      
        </div>

        <br />
        <!----<div style='max-width:1000px; margin:auto'>
          <b>{{name}} - {{specs}}</b><br />
          <img class="loadingSpinner" :src="$store.getters.error.image" alt="design image" style="max-width: 60vh; max-height: 60vh;" />
        </div>-->
      </div>
    </div>

    <!-- BEGIN ROBBIES CHANGES-->
    <div class="grid" style="--grid-template-columns-md: auto 1fr auto; align-items: stretch;">
        <!-- left panel -->
        <div style="--display-xs: none; --display-md: block; inline-size: 21rem;">
          <div class="pad-5 pad-ie-4">
            
            <div class="grid gap-4" style="grid-template-columns: 1fr auto; align-items: center;">
              <div>
                <h1 class="h5 one-line-truncate">{{ projectName }}</h1>
                <!-- <span v-if="!save.inProgress && !showSaveResult">Save</span>
                <span v-if="save.inProgress" >Saving <img class="loadingSpinner" :src="require('@/assets/images/ajax-spinner.gif')" style='width:20px;display:inline;'/></span>
                <span v-if="showSaveResult && !save.inProgress && save.success">Success ✔️</span>
                <span v-if="showSaveResult && !save.inProgress && !save.success">Failed ❌</span> -->
              </div>
              <SnuiWrapper>
                <div class="pad-ie-3">
                  <div class="popover1-anchor">
                    <button aria-labelledby="tooltip-edit-project" class="icon-button-2 shift-be-3" data-popover1-trigger="toggle" aria-controls="p-250127142242" aria-expanded="false">
                      <span class="sr-only">Actions Menu</span>
                      <StatusIndicator ref="si_saveDesign"></StatusIndicator>
                      <Icon name="arrow-down-1" custom-class="icon icon-size-0 block"/>
                    </button>

                    <!-- popover menu -->
                    <div id="p-250127142242" class="popover1 attach-outside-be align-is click-outside-close" data-popover1-state="closed" role="dialog" aria-labelledby="p-250127142242-title">
                      <div class="popover-menu-1 color-context-neutral-dark mar-bs-3">
                        <h2 class="sr-only" id="p-250127142242-title">User Actions</h2>
                        <div class="grid">
                          <button @click="handleSave" data-popover1-trigger="close" aria-controls="p-250127142242" aria-expanded="false">Save</button>
                          <!-- <button data-popover1-trigger="close" aria-controls="p-250127142242" aria-expanded="false">Rename</button> -->
                          <button @click="promptClose" data-popover1-trigger="close" aria-controls="p-250127142242" aria-expanded="false">Exit Designer</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SnuiWrapper>
            </div>

            <div v-if="projectLowestRep" class="mar-bs-1"  style="font-size: var(--fs-3);">
              {{projectLowestRep.name}}
              <br />{{projectLowestRep.phoneNumber}}
              <br />{{projectLowestRep.emailAddress}}
            </div>
            
          </div><!-- top section -->
          
          <!-- divider -->
          <hr class="border-color-xxsubdued" >

          <!-- bottom section -->
          <div class="pad-b-5 pad-i-4" style="height:80vh;overflow-y:auto;">
            <h2 class="strong mar-is-3">Building Systems</h2>
            <Tree id="tree" ref="tree" />

            
          </div><!-- bottom section -->
        </div> <!-- left panel -->
        

        <!-- <div class="bg-shaded-3"> -->
          <div id="renderPane">
            <div class="addOptions centered">
              <componentOptions ref="options" v-if="show.addOptions"/>
            </div>
            <div id="objs" style="position:absolute;left:300px;"></div>
            <div id="tris" style="position:absolute;left:400px;"></div>
            <div id="verts" style="position:absolute;left:500px;"></div>

            <!-- <div v-if="true" class="overlayTextBackground fade" :class="{show: inCameraModeFirst, hide: !inCameraModeFirst}" >
              <div class="">
                Press ESC to show the cursor<br />
                Look around with the mouse.<br />
                Move around with W, A, S, D and arrow keys.<br />        
              </div>
            </div> -->

            <!-- <div class="poweredByLogo" v-if="!editable">
              <img class="loadingSpinner" :src="require('@/assets/images/powered_by_SW_36x100.png')" />
            </div> -->
          </div>
        <!-- </div> end render pain -->


        <div style="--display-xs: none; --display-md: block; inline-size: 21rem;">
          <div class="grid" style="grid-template-rows: auto 1fr; block-size: calc(100vh - var(--header-bar-block-size));">
            <!-- upper section -->
            <SnuiWrapper>
            <div>
              <div class="collapse" id="layer-view-collapse">
                <div class="pad-b-4 pad-is-5 pad-ie-4">
                  <div class="grid gap-4 mar-bs-6" style="grid-template-columns: 1fr 1fr var(--icon-button-2-size);">
                    <div>
                      <button v-on:click="setActiveLayerViewTab('layers')" class="tab-button-1 layer-view-tab-button" :class="{active : activeVisualControlTab == 'layers'}" aria-expanded="true" aria-controls="layers-content">Layers</button>
                    </div>
                    <div><button v-on:click="setActiveLayerViewTab('view')" class="tab-button-1 layer-view-tab-button" :class="{active : activeVisualControlTab == 'view'}" aria-expanded="false" aria-controls="view-content">View</button></div>
                    <div>
                      <button v-on:click="showLayerViewCollapseContent = !showLayerViewCollapseContent" class="icon-button-2 collapse-toggle" aria-expanded="false" aria-controls="layer-view-collapse-content">
                        <span class="sr-only">Toggle Content</span>
                        <Icon aria-hidden="true" focusable="false" name="arrow-down-1" custom-class="icon icon-size-0 block toggle-rotate-180"/>
                      </button>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="collapse-content" id="layer-view-collapse-content">
                  <div class="pad-b-5">
                    
                    <!-- layers content -->
                    <div v-if="activeVisualControlTab == 'layers'" class="pad-is-5 pad-ie-5 layer-view-tab-button-content" :class="{active : activeVisualControlTab == 'layers'}" id="layers-content">
                      <LayerControls></LayerControls>
                    </div><!-- layers content -->

                    <!-- view content -->		
                    <div v-if="activeVisualControlTab == 'view'" class="pad-is-5 pad-ie-4 layer-view-tab-button-content" :class="{active : activeVisualControlTab == 'view'}" id="view-content">
                      <ViewControls></ViewControls>
                    </div><!-- view content -->

                  </div><!-- pad -->
                  <hr />
                </div><!-- collapse content -->
              </div><!-- collapse -->

              <div class="popover1-anchor">
                <button class="icon-text-button-3 border-be" data-popover1-trigger="open" aria-controls="popover-global-tools" aria-expanded="false">
                  <span>Global Tools</span>
                    <Icon aria-hidden="true" focusable="false" name="add-1" custom-class="icon icon-size-1 shift-is-1"/>
                </button>
                <div id="popover-global-tools" class="popover1 attach-outside-is align-bs pad-ie-5 click-outside-close" data-popover1-state="closed" role="dialog" aria-labelledby="popover-global-tools-title">
                  <button ref="globalToolsWrapper" data-popover1-trigger="close" aria-controls="popover-global-tools" aria-expanded="false"></button>
                  <GlobalTools @closePopover="closeGlobalTools"></GlobalTools>
                  
                </div><!-- popover1 -->
						  </div><!-- popover1-anchor -->

          
              <!-- add element button and popover -->
              <div class="popover1-anchor">
                <button class="icon-text-button-3 border-be" data-popover1-trigger="open" aria-controls="popover-add-element" aria-expanded="false">
                  <span>Add Element</span>
                  <Icon aria-hidden="true" focusable="false" name="add-1" custom-class="icon icon-size-1 shift-is-1"/>
                </button>
                <div id="popover-add-element" class="popover1 attach-outside-is align-bs pad-ie-5 click-outside-close" data-popover1-state="closed" role="dialog" aria-labelledby="popover-add-element-title">
                  <button ref="addElementWrapper" data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false"></button>
                  <componentAdd @closePopover="closeComponentAdd"></componentAdd>
                </div>
              </div>
            </div> <!-- upper section -->
          </SnuiWrapper>

          <!-- scrollable lower section -->
          <div style="overflow-y: auto;">
            <div class="advancedOptions" v-if="showOptionsPanel">
                <componentOptions/>
            </div>
          </div>

          

        </div><!-- grid -->
      </div> <!-- right panel -->
        
    </div> <!-- wrapper -->
    <!-- END ROBBIES CHANGES-->

    <!-- <sessionTimeoutWarning style='position:absolute' /> -->
    
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import {designModes, CORE, cameraModes, modes, initStage} from './js/_spec.js'
import Tree from './components/tree.vue'

import verticalPanelDivider from './components/verticalPanelDivider.vue'
import Status from './components/status.vue'
import ContextMenu from './components/contextMenu.vue'
import DesignHelper from './js/helpers/DesignHelper.js'
import componentOptions from './components/componentOptions.vue'
import componentAdd from './components/componentAdd.vue'
import layoutControls from './components/layoutControls.vue'
import VisibilityToggle from './components/visibilityToggle.vue'
import sessionTimeoutWarning  from './components/sessionTimeoutWarning.vue'
import Dictionary from './js/helpers/Dictionary.js'

import toolSkirt from  './components/toolSkirt.vue'
import toolInset from  './components/toolInset.vue'
import toolWallHeight from './components/toolWallHeight.vue'
import ViewControls from './components/viewControls.vue'
import {designModule,c,m,v} from './../store/modules/designModule.js'
const maximumMobileWidth = 800;
import VModal from 'vue-js-modal'
import Vue from 'vue'
import VerticalPanelDivider from './components/verticalPanelDivider.vue'
import ContextLogic from '@/lib/contextLogic.js';
import Util from '@/lib/utility.js';

import SnuiWrapper from '@/components/SnuiWrapper.vue';
import LayerControls from './components/layerControls.vue';
import GlobalTools from './components/globalTools.vue';
import StatusIndicator from '@/components/StatusIndicator.vue';

Vue.use(VModal, {dialog:true});

let dictionary = new Dictionary();

export default {
  name: 'App',
  
  data: function(){
    return {               
      
      CORE,
      activeVisualControlTab: 'view',
      showLayerViewCollapseContent: false
    }
  },
  computed:{
    ...mapState('designModule',[
      'showSaveResult',
      'engRevRequired',
      'projectRef',
      'layers',
    ]),
    ...mapState('contextModule', [
      'user'
    ]),
    ...mapGetters('designModule', [
      'apiEndpoint',
      'loading',
      'error',
      'errored',
      'show',
      'tool',
      'tools',
      'showOptionsPanel',
      'inCameraModeFirst',
      'inCameraModeOrbit',
      'inCameraModeLayout',
      'branding',
      'isToolModalVisible',
      'loadingMessage',
      'name',
      'lowestRep',
      'specs',
      'editable',
      'designMode',
      'unsavedChanges',
      'save',
      'initStage',
      'isInLayoutView',
      'view',
      'showQuote',
      'designerWarningShow',
      'shouldShowQuoteLayer',
      'shouldShowToggleLayers',
      'camera',
      'designerWarningTitle',
      'designerWarningMessage',
    ]),
    projectLowestRep(){
      if(this.lowestRep)
        return this.lowestRep;

      return null;
    },
    projectName(){
      if(this.name)
        return this.name

      return ''
    },
    mode(){
      return this.designMode;      
    },
    layerFrame(){
      return this.view.layers.current.frame;
    },
    layers() {
        return this.view.layers.current;
    },
    showLayerArrowUp(){
      if(this.isEditable)
        return !this.show.layerTray;
      else
        return this.show.layerTray;
    },
    showLayerArrowDown(){
      if(this.isEditable)
        return this.show.layerTray;
      else
        return !this.show.layerTray;
    },    
    advDesignMode(){
      return true;
    },    
    pointerLocked(){
      return this.camera.pointerLocked;
    },
    isInToggleView(){
      return this.shouldShowToggleLayers;
    },
    isInQuoteView(){
      return this.shouldShowQuoteLayer;
    },
    showQuoting(){
        return this.showQuote;
    },
    showDesignerWarning() {
      return this.designerWarningShow;
    },
    warningTitle(){
      return this.designerWarningTitle; 
    },
    warningMessage() {
      return this.designerWarningMessage;
    },
    isInLayoutMode(){
      console.log("in camera layout mode", this.inCameraModeLayout)
      return this.inCameraModeLayout;
      
    },
    isInOrbitMode(){
      console.log("in camera orbit mode", this.inCameraModeOrbit)
      return this.inCameraModeOrbit;
    },
    isInFirstMode(){
      console.log("in camera first mode", this.inCameraModeFirst)
      return this.inCameraModeFirst;
    },
    isEditable(){
      return this.editable
    },
  },
  components: {
    Tree,
    Status,
    ContextMenu,
    VisibilityToggle,
    componentOptions,
    componentAdd,
    layoutControls,
    sessionTimeoutWarning,
    
    toolSkirt,
    toolWallHeight,
    toolInset,
    ViewControls,
    VerticalPanelDivider,

    SnuiWrapper,

    LayerControls,
    GlobalTools,
    StatusIndicator
  },
  watch:{
  },
  created: function(){
    setTimeout(()=>{        
      this.showBetaWarning(); // commented because the v-dialog component is not imported or a dependency anymore for some reason
    }, 3 * 1000) // 10 sec
    
    this.setupState(); 
    //this.startAutoSaveTimer(); // this generates a lot of extra processing and emails that are not helpful
  },
  async mounted(){
    console.log('DesignerView Mounted')
    // we need to assert reconstitution of the app state from localStorage    
    // then we can rely on appState like this.user which is mapped from the contextModule
    await ContextLogic.initContext(this, false);   
    console.log('DesignerView Mounted', this.user) 
    this.shareMode(this.user == null);
    await this.setInitStageAppMounted(this);  
    //this.initLayerViewTabs();
  },
  methods: {  
    
...mapMutations('designModule',[
      'collapseComponentTree',
      'saveInProgress',
      'saveSuccess',
      'updateLastSavedDesign',
      'updateCurrentDesign',
      'setEditingMode_View',
      'shareMode'
    ]),
    
    ...mapActions('designModule', [
      'setInitStageAppMounted',
      'runTool',
      'selectComponent',
      'switchToCamera',
      'cycleOrbitCamera',
      'saveDesign',
      ]),
      setActiveLayerViewTab(tab){
        const tabsCollapse = document.getElementById('layer-view-collapse'); 

        if(this.activeVisualControlTab == tab){

          if(this.showLayerViewCollapseContent){
					  snui.collapse.close(tabsCollapse);
          }
          else{
            snui.collapse.open(tabsCollapse);
          }

          this.showLayerViewCollapseContent = !this.showLayerViewCollapseContent

          return
        }
          

        this.activeVisualControlTab = tab;
        this.showLayerViewCollapseContent = true;
        snui.collapse.open(tabsCollapse);
      },

      closeGlobalTools(){
        this.$refs.globalToolsWrapper.click()
      },
      closeComponentAdd(){
        this.$refs.addElementWrapper.click()
      },
    toggleLayerSelector(){
      this.show.layerTray=!this.show.layerTray;
    },
    useAdvDesignMode(){
      this.designMode = designModes.advanced;
    },
    useGuidedDesignMode(){
        this.designMode = designModes.guided;
    },
    
    migrate(){
    },
    setupState(){
      let innerWidth = window.innerWidth
      if(innerWidth<maximumMobileWidth)
        this.collapseComponentTree();
    },
    
    startAutoSaveTimer(){
      // don't bother in dev
      if(window.location.host.includes('localhost'))
        return;
      if(this.isEditable===true)
        setTimeout(()=>{        
          this.saveDesign(false);
          this.startAutoSaveTimer();
        }, 30 * 60 * 1000) // 4 min * 60 sec/min * 1000 ms/sec
    },   
    showBetaWarning(){
      let hide = localStorage.getItem('betaHide2024.04.30');
      let show = hide!=='1';
      if(show)
      this.$modal.show('dialog', {
        title: 'Warning: 3D Designer (v3) is in Beta',
        text: `The 3D Designer is still in development. </br></br>
        <ul>
          <li>Saved designs may be deleted without warning.</li>
          <li>Quoted price and weight may change.</li>
          <li>Contact your sales rep for accurate pricing.</li>
        </ul>
            
        </br>
        Submit suggestions to: dev@metalstream.tech`,
        buttons: [
          {
            title: 'OK',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: `Don't show this again`,
            handler: () => {
              localStorage.setItem('betaHide2024.04.30', '1');
              this.$modal.hide('dialog')
            }
          },
        ]
      })
    },
    closeWarning() {
      this.designerWarningShow =false;
      this.designerWarningMessage='';
    }, 
    
    
    async delay(){
      return new Promise(resolve =>{
        console.log('fake save start');
        setTimeout(()=>{
          console.log('fake save end');
          resolve();
          
        }, 2000)
      });
      
    },
    async handleSave(){
      let si = this.$refs.si_saveDesign;
      if(si)
        si.loading();
      this.$modal.hide('dialog')
      console.log('saving')
      let saveSuccess = await this.saveDesign();
      if(saveSuccess){
        if(si) si.success();
        console.log('saved')
      }
      else
        if(si) si.fail()
    },
    promptClose(){
      if(this.unsavedChanges){
        this.$modal.show('dialog', {
          title: 'Warning: Unsaved Changes',
          text: `There are changes which have not be saved!`,          
          buttons: [
            {
              title: 'Save & Close',
              handler: async () => {

                await this.handleSave();
                this.close();
              }
            },
            {
              title: 'Discard & Close',
              handler: () => {
                this.$modal.hide('dialog')
                this.close();
              }
            },
            {
              title: `Cancel & stay here.`,
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
            
          ]
        })
      }
      else{
        this.close();
      }
    },
    close(){
      this.$router.push({path: `/project/${this.projectRef}`});

      // if(this.isEditable){
      //   this.$router.push({path: `/project/${this.projectRef}`});
      //   // this.$router.back();
      // }
      // else
      // {
      //   this.$router.push({path: `/share/${this.projectRef}`});
      // }
    },
    redirectLogin() {
      // go to login page
      if (window.corejs) {
        let url = window.corejs.fnSiteGetUrl("login"); // get the url for the login page
        url += "?ReturnUrl=" + window.location.pathname + window.location.search; // tack on the current location into a ReturnUrl param so we get redirected back here after loggin in
        window.location = url;
      }

    },
    initLayers:function(){
      v.setLayersByName(this.layers.current)      
    },    
    getObjects: function(){    
      return m.getEditableComponents();     
    },
    // give the tree component everything and let it sort it out
    getObjectTreeData:function(){      
        return  m.getComponentTreeMenuData();
    },      
    viewMode: function(){
      this.setEditingMode_View()
    },
    getContext: function(){      
      return c.getContextMenuData();      
    },
    getContextMenuData: function(id){
      return c.getContextMenuDataForComponent(id);      
    },
    toggleLayer(layer){

      v.getViewContext().camera.layers.toggle(layer);
    },
    
    async useOrbitCamera(){
      // this is used simply to switch from another camera mode (first or layout) to orbit (no rotation)
      await this.switchToCamera(cameraModes.orbit);
    },
    async cycleOrbitCamera(){
      // this is used to iterate through three orbit modes (no rotation, slow rotation, fast rotation)
      await this.cycleOrbitCamera();
    },
    async useFirstPersonCamera(){
      await this.switchToCamera(cameraModes.first);
    },
    async useLayoutCamera(){
      await this.switchToCamera(cameraModes.layout);
    },
  }
}
</script>

<style scoped>

.centered{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--clr-white);
}
</style>
