<template>
    <div>   
        <template v-if="mode == 1 || mode == 2">

            <div class="settingsGroup">
                <h1 class="h1">Materials</h1>
                <div class="section-group-title"><b>Trim Profiles</b></div>
                <div class="settingsGroupBody" v-for="item in trimProfilesCategories" :key="item.settingName">
                    <div class="setting">
                        <div class="inline" :for="item.settingName">{{ item.type }}</div><StatusIndicator :ref="statusRefs[item.settingName]" :successText="'Saved'"></StatusIndicator>    
                        <label class="block" style="font-size: var(--fs-3)">Select a default {{ item.type }} from your trim list</label>
                        <user-setting-dropdown  @change="setBusinessSetting" :customStyle="{display:'inline-block'}"
                                                :ref="item.settingName"
                                                :id="item.settingName"
                                                :val="getDynamicSettingValue(item.settingName)"
                                                :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                :options="supplierTrimProfiles">
                        </user-setting-dropdown>                        
                    </div>
                </div>
                <div class="section-group-title"><b>Panels</b></div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <div class="inline" for="qt_defaultRoofPanel">Default Roof Panel Profile</div><StatusIndicator ref="si_defaultRoofPanel" :successText="'Saved'"></StatusIndicator>    
                            <label class="block" style="font-size: var(--fs-3)">Select a default profile for roofs</label>
                            <user-setting-dropdown  @change="setBusinessSetting" :customStyle="{display:'inline-block'}"
                                                    ref="qt_defaultRoofPanel"
                                                    id="qt_defaultRoofPanel"
                                                    :val="qt_defaultRoofPanel"
                                                    :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                    :options="panelProfiles">
                            </user-setting-dropdown>
                        </div>
                    </div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <div class="inline" for="qt_defaultWallPanel">Default Wall Panel Profile</div><StatusIndicator ref="si_defaultWallPanel" :successText="'Saved'"></StatusIndicator>    
                            <label class="block" style="font-size: var(--fs-3)">Select a default profile for walls</label>
                            <user-setting-dropdown  @change="setBusinessSetting" :customStyle="{display:'inline-block'}"
                                                    ref="qt_defaultWallPanel"
                                                    id="qt_defaultWallPanel"
                                                    :val="qt_defaultWallPanel"
                                                    :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                    :options="panelProfiles">
                            </user-setting-dropdown>                            
                        </div>
                    </div>
                    <div class="settingsGroupBody">
                        <div class="setting">
                            <div class="inline" for="qt_defaultSoffitPanel">Default Wainscot & Soffit Panel Profile</div><StatusIndicator ref="si_defaultSoffitPanel" :successText="'Saved'"></StatusIndicator>    
                            <label class="block" style="font-size: var(--fs-3)">Select a default profile for wainscot & soffit</label>
                            <user-setting-dropdown  @change="setBusinessSetting" :customStyle="{display:'inline-block'}"
                                                    ref="qt_defaultSoffitPanel"
                                                    id="qt_defaultSoffitPanel"
                                                    :val="qt_defaultSoffitPanel"
                                                    :disable="!canUpdateSupplierSettings && !canUpdateContractorSettings"
                                                    :options="panelProfiles">
                            </user-setting-dropdown>                            
                        </div>
                    </div>

             </div> <!-- end settings group -->
        </template>  
    </div>
</template>

<script>
// @ is an alias to /src
import SwmAddAddress from '@/components/swm-add-address.vue';
//import swmbox  from '@/components/swmbox.vue';
import userSettingCheckbox from '@/components/user-setting-checkbox.vue';
import userSettingDropdown from '@/components/user-setting-dropdown.vue';
import colorSelect2 from '@/components/color-select2.vue';
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapActions, mapMutations, mapGetters} from 'vuex';
import Vue from 'vue'
import Vuex from 'vuex'
import StatusIndicator from '@/components/StatusIndicator.vue';
import api from '@/api';
import Util from '../lib/utility.js'
Vue.use(Vuex)


export default {
  name: 'Materials',
  components:{
    SwmAddAddress,
    //swmbox,
    userSettingCheckbox,
    userSettingDropdown,
    colorSelect2,
    StatusIndicator
  },
  data(){
    return {  
        entity: {},
        supplierPanelItems: [],
        panelProfiles: [],
        supplierTrimProfiles:[],
        editing: false,
        trimProfilesCategories:[
            {type:'Rake Profile',settingName: "qt_trimRakeProfile"},
            {type:'Ridge Profile',settingName: "qt_trimRidgeProfile"},
            {type:'Gutter Profile',settingName: "qt_trimGutterProfile"},
            {type:'Downspout Profile',settingName: "qt_trimDownspoutProfile"},
            {type:'Inside Corner Profile',settingName: "qt_trimInsideCornerProfile"},
            {type:'Outside Profile',settingName: "qt_trimOutsideCornerProfile"},
            //{type:'Head Profile',settingName: "qt_trimHeadProfile"},
            //{type:'Jamb Profile',settingName: "qt_trimJambProfile"},
            {type:'Drip/Rat Profile',settingName: "qt_trimDripRatProfile"},
            {type:'Eave Profile',settingName: "qt_trimEaveProfile"},

        ],      
        statusRefs: {   // settingName : ref
    
            "qt_defaultRoofPanel": "si_defaultRoofPanel",
            "qt_defaultWallPanel": "si_defaultWallPanel",
            "qt_defaultSoffitPanel": "si_defaultSoffitPanel",

            "qt_trimRakeProfile": "si_trimRakeProfile",
            "qt_trimRidgeProfile": "si_trimRidgeProfile",
            "qt_trimGutterProfile": "si_trimGutterProfile",
            "qt_trimDownspoutProfile": "si_trimDownspoutProfile",
            "qt_trimInsideCornerProfile": "si_trimInsideCornerProfile",
            "qt_trimOutsideCornerProfile": "si_trimOutsideCornerProfile",
        
            "qt_trimDripRatProfile": "si_trimDripRatProfile",
            "qt_trimEaveProfile": "si_trimEaveProfile",
            //   "qt_trimHeadProfile": "si_trimHeadProfile",
            //   "qt_trimJambProfile": "si_trimJambProfile",
    
        },
        classes: {
            container: "settingsGroup",
            header: "settingsGroupHeader",
            body: "settingsGroupBody",
            setting: "setting",
            value: "settingValue",
            description: "settingDescription"
        },
        isAuthenticated: false,

        showSupplierOnlySetting: false,
        canUpdateBusinessInfo: false,
        canUpdateSupplierSettings: false,
        canUpdateContractorSettings: false,

        qt_defaultRoofPanel: "",
        qt_defaultWallPanel: "",
        qt_defaultSoffitPanel: "",
        qt_trimRakeProfile: "",
        qt_trimRidgeProfile: "",
        qt_trimGutterProfile: "",
        qt_trimDownspoutProfile: "",
        qt_trimInsideCornerProfile: "",
        qt_trimOutsideCornerProfile: "",
        qt_trimDripRatProfile: "",
        qt_trimEaveProfile: "",
        // qt_trimHeadProfile: "RHE",
        // qt_trimJambProfile: "RHE",
        businessId: 0,
    }
  },
  computed:{
    ...mapState('contextModule',[
      'user'
      ,'userBusiness'
      ,'current'
    ]),
    ...mapGetters('contextModule',[
        'userIsSysAdmin'
        ,'userIsSupAdmin'
        ,'userIsContAdmin'
    ]),
    mode(){
      return this.current.mode
    }
  },
  methods: {
    ...mapActions('businessModule', ['getBusinessSettings','updateBusinessInfo', 'fetchBusiness', 'updateBusinessInfo','getBusinessTrimProfiles', 'getBusinessPanels','getBusinessPanelProfiles']),
    
    backToBusinesses(){
        // this needs to become fully centralized if every settings page is going to need it
        ContextLogic.clearBusinessContext(this.$store);

        if(this.userBusiness.type==1)
            this.$router.push({path: `/supplier/${this.userBusiness.id}/contractors`})
        else
            this.$router.push({path: `/businesses/list`})
    },
    getDynamicSettingValue(settingName){
        return this[settingName];
    },
    async setBusinessSetting(data){
        var val;
        // if the object has a hex property, it's a color setting, 
        if (data.val.hex)
            val = data.val.id; // use color.id for colors (changed week of 2023.11.07)
        else{
            val = `${data.val}`
        }
            
        let settingsGroup = {
            businessId: this.businessId,
            name: data.id,
            value: val
        }

        let statusRefId = this.statusRefs[data.id]; // use the setting name to know the setting status indicator name
        let si = this.$refs[statusRefId]; // use the setting status indicator name to get the status indicator component
        if(si != undefined)
        {
            if(Array.isArray(si)){ 
                // Vue takes overwhen refs are in an v-for context and makes the Ref an array no matter what.
                si= si[0];
            }
            si.loading();
        }

        let response = await api.setBusinessSetting(settingsGroup);

        if(response.isError){
            si.fail();
        }
        else{
            si.success();
        }
    },
    
   
    defaultAllSettings() {
        this.qt_defaultRoofPanel = "";
        this.qt_defaultWallPanel = "";
        this.qt_defaultSoffitPanel = "";
        this.qt_trimRakeProfile = "";
        this.qt_trimRidgeProfile = "";
        this.qt_trimGutterProfile = "";
        this.qt_trimDownspoutProfile = "";
        this.qt_trimInsideCornerProfile = "";
        this.qt_trimOutsideCornerProfile = "";
        this.qt_trimDripRatProfile = "";
        this.qt_trimEaveProfile = "";

    },
    async loadSettingOptions(){
        let pathParts = this.$route.path.split("/");
        let businessId = pathParts[2];

        await this.loadTrimProfiles(businessId);
        await this.loadPanels(businessId);
        await this.loadPanelProfiles();

    },
    async loadTrimProfiles(businessId){
        let response = await this.getBusinessTrimProfiles(businessId);
        if(response.isError){
            this.$toast.error(response.msg);
            console.error(response);
        }
        else
        {
            this.supplierTrimProfiles= [{name: "Please Configure", value: ""}, ...response.map(x => ({name: x.supplierCode, value: x.ninjaCode}))];
        }
    },
    async loadPanelProfiles(){
        let response = await this.getBusinessPanelProfiles();
        if(response.isError){
            this.$toast.error(response.msg);
            console.error(response);
        }
        else
        {
            this.panelProfiles = response.map(x => ({name: x, value: x}));;
        }
    },
    async loadPanels(businessId){
        let response = await this.getBusinessPanels(businessId);
        if(response.isError){
            this.$toast.error(response.msg);
            console.error(response);
        }
        else
        {
            this.supplierPanelItems =response.map(x => ({name: x.entityCode, value: x.systemCode}));
        }
    },
    async loadSettingValues(){
        let pathParts = this.$route.path.split("/");
        let businessId = pathParts[2];

        this.defaultAllSettings();
        let response = await this.getBusinessSettings(businessId);
        if(response.isError){
            this.$toast.error(response.msg);
        }
        else{
            let settings = response;
            this.initSettings(settings);
        }

    },
    initSettings(settings) {
      // assign the data values
      settings.forEach((settingsGroup) => {
          this.initSetting(settingsGroup);
      })               
      
      // assigning the logo value won't set the image, so apply the base64 data to the img src

    },
    initSetting(setting) {
        if (setting.value === "true" || setting.value === "false")
            setting.value = setting.value === 'true';

        if (setting.name === "des_colorRoof" || setting.name === "des_colorWall" || 
            setting.name === "des_colorWainscoting" || setting.name === "des_colorTrim") {
            this.colorPalette.forEach(c => {
                // match the itemID in the setting value to the itemID of a color in the color palette
                if (c.id === setting.value) {
                    // use that color
                    setting.value = c;
                }
            });
        }
        if (typeof setting.value !== 'undefined') {
            this[setting.name] = setting.value;
        }
    },
  },
  created() {
    this.dbEntitySettings = Util.debounce(this.setBusinessSetting,1000);
    this.businessId = Number(this.$route.params["id"]);
  },
  async mounted() {
    // Get context data
    await ContextLogic.initContext(this);
    this.isAuthenticated = this.$auth.isAuthenticated;
    let isContractorAdmin = this.userIsContAdmin;
    let isSupplierAdmin = this.userIsSupAdmin;
    let isSystemAdmin = this.userIsSysAdmin;
    console.log(`contractor admin: ${isContractorAdmin} || supplier admin: ${isSupplierAdmin} || system admin: ${isSystemAdmin}`)

    // set page context
    this.showSupplierOnlySetting = this.mode == 1;
    this.canUpdateSupplierSettings = isSupplierAdmin || isSystemAdmin;
    this.canUpdateContractorSettings = isContractorAdmin || isSystemAdmin;
    this.canUpdateBusinessInfo = this.canUpdateSupplierSettings || this.canUpdateContractorSettings;
    console.log(`can update contractor settings: ${this.canUpdateContractorSettings}`)
    console.log(`can update supplier settings: ${this.canUpdateSupplierSettings}`)
    console.log(`can update entity info: ${this.canUpdateBusinessInfo}`)


    // Load settings
    await this.loadSettingOptions();
    await this.loadSettingValues();

  }
}
</script>

<style>

@media (max-width: 4924px) {
       .settingsGroup {
           font-size: 1.3em;
       }
   }
   @media (max-width: 1280px) {
       .settingsGroup {
           font-size: 1.2em;
       }
   }
   @media (max-width: 1024px) {
       .settingsGroup {
           font-size: 1.1em;
       }
   }
   @media (max-width: 768px) {
       .settingsGroup {
           font-size: 1.0em;
       }
   }
   @media (max-width: 640px) {
       .settingsGroup {
           font-size: 1.0em;
       }
   }
   .settingGroupList {
       width: 90%;
       margin: auto;
   }

   .settingsContainer {
       display: flex;
       flex-flow: row wrap;
       justify-content: space-evenly;
       align-items: flex-start;
   }

   .settingsItem {
       display: block;
       margin: 5px;
       padding: 5px;
       margin-left: 20px;
       border: 1px solid lightgrey;        
       width:100%;
       text-align: left;
   }


   /* parent  */
   
   .settingsContainer {
       flex-grow: 2;
   }


   .settingsGroupList {
       display: flex;
       flex-flow: row wrap;

   }

   /* item */
   .settingsGroup {
       display: block;
       margin: 5px;
       padding: 2px;
   }

   .settingsGroup .settingsGroupBody {
       display: block;
       flex-grow: 1;
       color: black;
       flex-shrink: 2;
       text-align: left;       
   }

   .flex {
       display: flex;
       flex-flow: row wrap;
   }

   .settingsGroup .settingsGroupHeader {
       display: block;
       font-weight: bold;
       width:100%;
       background: lightgray;
       padding: 10px;
       text-align: left;
   }

   .setting {
       display: inline-block;
       margin: 10px;
       vertical-align: top;
   }

   .settingsGroup .settingDescription {
       display: inline-block;
       margin: 3px 0 0 5px;
       font-size: .8em;
       vertical-align: top;
       
   }

   .settingsGroup .settingValue {
       flex-grow: 1;
       text-align: left;
       display: inline-block;
       margin-left: 10px;
       
   }        


   .settingValue input {       
       flex-shrink: 1;
   }

   .settingValue .designLogoSml {
       margin: 3px;
       display: block;
       text-align: left
   }

   .settingValue .designLogoSml img {
       margin: 3px;
       max-width: 150px;
       box-shadow: 0px 0px 2px black;
       display: inline-block;
       text-align: center;
       margin-left: auto;
       margin-right: auto;
       background: black
   }

   .settingValue .designLogoLrg img {
       margin: 3px;
       max-width: 480px;
       box-shadow: 0px 0px 2px black;
       display: inline-block;
       text-align: center;
       margin-left: auto;
       margin-right: auto;
   }

   .hidden {
       display: none;
   }

   .logoTypeLabel {
       width: 120px;
       display: block;
       font-size: .7em;
   }

   .horsep {
       border-top: 1px solid lightgray;
       display:inline-block;
       margin:auto;
       height:10px;
       width:95%;
       text-align:center;
   }

   .digit2 {
       width: 51px;
   }
   .digit3 {
       width: 76px;
   }

   input[type=checkbox] {
       transform: scale(1.3);
       margin-right: 10px;     
       margin-bottom: 3px;
   }

   .logo-input{
        display: inline-flex;
        margin: 20px 50px;
    }

    .logo-lrg {
        width: 300px;
        
    }

    .logo-sml {
      width: 80px;
      border-radius: 15px;
      /* background-color: #f0f1f5; */
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      
      img {
        max-width: 100%;
        /* border-radius: 50%; */
      }
    }

    .section-group-title{
        margin: 15px 10px;
        text-align: left;
        background: rgb(231, 231, 231)
    }

    input.w-s{
        width:100px;
    }
    input.w-m{
        width:350px;
    }
    input.w-l{
        width:500px;
    }

</style>
