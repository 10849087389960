<template>
    <div> 
        <label class="composite-field">
              <div class="text">
                <div class="swatch-1" :style="{'background-color': selectedHex(initColor)}"></div>
              </div>  
              <span class="select">
                <select class="size-small"  name="select" id="field_250109094608"  v-model="color" @change="$emit('input', color)">
                    <option v-for="pColor in palette" :key="pColor.name" :value="pColor.id">{{ pColor.name }}</option>
                </select>
              </span>
        </label>
    </div>
  </template>
  
  <script>

  
  export default {
    props: 
        ['initColor', 'palette', 'value', 'openUp', 'title', 'enabled'],
    data(){
        return {
            showSelector:false,
            color: null,
        };
    },
    computed: {
      
      selectedName(id){
        return this.getPaletteColorById(id).name;

      }
    },
    created(){
        this.color = this.initColor;
    },
    mounted(){
      console.log(this.palette);
    },
    methods: {      
      getPaletteColorById(id){
        return this.palette.find((x) => x.id == id);
      },
      selectedHex(id){
          let col = this.getPaletteColorById(id);
          if(!col)
            return 5;
        return col.designerHex;
      },
    },
    components:{

    }
  }
  </script> 
  
  <style scoped>
  
  </style>
  
  
  