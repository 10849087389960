<template>
    <div>
        <div class="grid" style="column-gap: var(--sp-4); row-gap: var(--sp-5); grid-template-columns: 1fr 1fr">
            <div>
                <label for="wall">Wall</label>
                <span class="select">
                <select v-model="wall" class="size-small" name="select" id="wall">
                    <option value="fsw">Front</option>
                    <option value="bsw">Back</option>
                    <option value="lew">Left</option>
                    <option value="rew">Right</option>
                </select>
                </span>
            </div>
            <div>
                <label for="wallHeight">Wall Height</label>
                <label class="composite-field">
                <input class="size-small align-end" type="number" v-model="height" :disabled="open" id="wallHeight" name="input">
                <div class="text">in</div>
                </label>
            </div>
            <div>
                <div class="checkradio-item" style="font-size: var(--fs-3);">
                <input v-model="open" type="checkbox" name="field_name_250207191321" id="open">
                <label for="open">Open</label>
                </div>
            </div>
            <div>
                <div class="checkradio-item" style="font-size: var(--fs-3);">
                <input type="checkbox" name="updateSkirts" id="updateSkirts" v-model="updateSkirtedBays" >
                <label for="updateSkirts">Update Skirted Bays</label>
                </div>
            </div>
            </div><!-- grid -->
            <!-- <div class="grid mar-bs-6" style="column-gap: var(--sp-4); row-gap: var(--sp-5); grid-template-columns: 1fr 1fr">
                <div>
                    <button @click="cancel" class="btn btn-small btn-secondary" data-popover1-trigger="close" aria-controls="p-250128171119" aria-expanded="false">Cancel</button>
                </div>
                <div>
                    <button @click="apply" class="btn btn-small"  data-popover1-trigger="close" aria-controls="p-250128171119" aria-expanded="false">Apply</button>
                </div>
            </div> -->
    </div>
    <!-- <div style="border:0px solid black">
        <div style="margin:auto;text-align: center;font-weight: bold;">{{iTool.name}} Tool</div>
        <TargetStructureName />
        <table>        
        <tr>
            <td>
                <label>Wall:</label>
            </td>
            <td>
                <select id="wall" v-model="wall">
                    <option value="fsw">Front</option>
                    <option value="bsw">Back</option>
                    <option value="lew">Left</option>
                    <option value="rew">Right</option>
                </select>
            </td>
        </tr>
        <tr>
            <td ><label for="open">Open:</label></td>
            <td><input type="checkbox" id="open" v-model="open" /></td>
        </tr>
        <tr>
            <td><label>Wall height (in):</label></td><td><input type="number" v-model="height" :disabled="open" /></td>
        </tr>
        <tr>
            <td style="width:55%"><label for="updateSkirts">Update skirted bays:</label></td>
            <td><input type="checkbox" id="updateSkirts" v-model="updateSkirtedBays" /></td>
        </tr>
        </table>
        <div style="display:flex; justify-content: space-evenly ; padding:4px">
            
            <button class="btn cancel" @click="cancel">Cancel</button>
            <button class="btn btnGreen" @click="apply">Apply</button>
        </div>

    </div> -->
</template>

<script>
import { CORE } from '../js/_spec';
import TargetStructureName from './targetStructureName.vue';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
    props:{
        iTool: Object,
        },
    computed:{
         ...mapState('designModule',[
 'tool',
         ]), 
    ...mapGetters('designModule', [
    'structure',
        ]),
    },
    data(){
        return {
            wall: CORE.sides.leftEnd,
            open:true,
            height: 36,
            updateSkirtedBays: true
        }
    },
    mounted(){
        console.log('mounted tool')
        this.init();
    },
    methods:{
        
    ...mapActions('designModule',[
            'applyToolData',        
        ]),
    ...mapMutations('designModule',[
        'showToolModal',      
        ]),
        init(){
            this.wall = this.tool.data.wall;
            this.open = this.tool.data.open;
            this.height = this.tool.data.height;
            this.updateSkirtedBays = this.tool.data.updateSkirtedBays;
        },
        apply(){
            this.tool.data = {
                wall: this.wall,
                open: this.open,
                height: this.height,
                updateSkirtedBays: this.updateSkirtedBays,
            }; 

            this.applyToolData();
            this.showToolModal(false)
        },
        cancel(){
            this.showToolModal(false)
        }
    },
    components:{
        TargetStructureName
    }
   
}
</script>

<style scoped>


td:nth-child(1) {
    text-align:right;
}

.main {    
    background-color: #222;
    border-color: #080808;
    width: 100%;
    left:0px;
    top:0px;
    color:white;
    display: flex;
}

.meta{
    display:inline-block;
    margin-left:10px;
    flex-grow: 2;
}

.name {
    font-size: 1em;
}

.specs {
    font-size: .7em;
}

.logo{
    width:150px;
    position:relative;
}

.logo .img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.menuItem {
    margin:2px 10px;
    position:relative;
}

.menuItem.boxed{
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 3px;
    text-align: center;
}

.modeLabel{
    display: block;
    text-align: center;
}


.menuItem.title{
    overflow: hidden
}

.title .primary{
    font-weight: bold;
    font-style: italic;
    max-width: 95vw;
    font-size: 25px;
    display: block;
}

.title .beta{
    font-weight: bold;
    font-style: italic;
    max-width: 95vw;
    color:coral;
    font-size: 15px;
}

.title .beta{
    font-weight: bold;
    max-width: 95vw;
    font-size: 13px;
}

.btn {
    display:inline-block;
    width:auto;
    height: fit-content;
    /* nitty-gritty-override */
    min-height: unset;
}

.quote{
  display:block;
}

input[type="number"]{
    width: 80%;
}

td {
    padding:2px 5px;
    margin:2px 0px;
    max-width: 160px;
}

label{
    margin:10px
}

input{
    padding-left:5px;
}

</style>./toolWallHeight.vue