<template>
    <div class="color-picker">
      <div class="swatch" :style="{ backgroundColor: selectedHex }" @click="openPicker"></div>
      <input ref="colorInput" type="color" v-model="selectedHex" @input="updateColor" class="color-input" />
    </div>
  </template>
  
  <script>
  export default {
    props: {
      color: {
        type: Object,
      },
      hex:{
        type: String
      }
    },
    data() {
      return {
        currentColor: this.color,
        selectedHex: this.hex
      };
    },
    watch: {
      color(newColor) {
        this.currentColor = newColor
      },
      hex(newHex){
        this.selectedHex = newHex
      }
    },
    methods: {
      openPicker() {
        this.$refs.colorInput.click();
      },
      updateColor() {
        this.$emit('colorSelected', this.selectedHex, this.currentColor);
      }
    },
  };
  </script>
  
  <style scoped>
  .color-picker {
    display: inline-block;
    position: relative;
  }
  
  .swatch {
    width: 35px;
    height: 35px;
    border: 1px solid #ccc;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .color-input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  </style>
  