<template>
  <div class="color-manager p-4">
    <div class="flex justify-between items-center mb-4">
      <h1 class="text-2xl font-bold">Color Management</h1>
      <button 
        @click="showAddColorEditor = showAddColorEditor == false"
        class="px-4 py-2 rounded font-medium"
        :class="showAddColorEditor ? 'bg-green-500 text-white hover:bg-green-600' : 'bg-blue-500 text-white hover:bg-blue-600'"
        style="margin-left: 20px"
      >
        {{showAddColorEditor ? "Cancel Add Color" : "Add New Color"}}
      </button>
    </div>
    
    <!-- Add New Color Form -->
    <div v-if="showAddColorEditor" class="bg-gray-100 p-4 rounded-lg mb-6">
      

      <h2 class="text-xl font-semibold mb-3">Add New Color</h2>
      
      <form @submit.prevent="addColor" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div>
          <label class="block text-sm font-medium mb-1">Name*</label>
          <input 
            v-model="newColor.name"
            type="text"
            class="w-full px-3 py-2 border rounded"
            required
          >
        </div>
        
        <div>
          <label class="block text-sm font-medium mb-1">masterCode* (Max 50 chars)</label>
          <input 
            v-model="newColor.masterCode"
            type="text"
            class="w-full px-3 py-2 border rounded"
            required
          >
        </div>
        
        <div>
          <label class="block text-sm font-medium mb-1">Caulk Code (Max 50 chars)</label>
          <input 
            v-model="newColor.caulkCode"
            type="text"
            class="w-full px-3 py-2 border rounded"
          >
        </div>
        
        <div>
          <label class="block text-sm font-medium mb-1">MBS Code (Max 50 chars)</label>
          <input 
            v-model="newColor.mbsCode"
            type="text"
            class="w-full px-3 py-2 border rounded"
          >
        </div>
        
        <div>
          <label class="block text-sm font-medium mb-1">Media Hex Color*</label>
          <div class="flex items-center space-x-2">
            <input 
              v-model="newColor.mediaHex"
              type="text"
              pattern="^#[0-9A-Fa-f]{6}$"
              class="w-full px-3 py-2 border rounded"
              required
            >
            <ColorPalettePicker :color="newColor" :hex="newColor.mediaHex" @colorSelected="updateColorMediaHex"></ColorPalettePicker>
          </div>
        </div>
        
        <div>
          <label class="block text-sm font-medium mb-1">3D Designer Hex Color*</label>
          <div class="flex items-center space-x-2">
            <input 
              v-model="newColor.designerHex"
              type="text"
              pattern="^#[0-9A-Fa-f]{6}$"
              class="w-full px-3 py-2 border rounded"
              required
            >
            <ColorPalettePicker :color="newColor" :hex="newColor.designerHex" @colorSelected="updateColorDesignerHex"></ColorPalettePicker>
          </div>
        </div>
        
        <div class="flex items-center space-x-4">
          <label class="flex items-center">
            <input 
              v-model="newColor.forTrim"
              type="checkbox"
              class="mr-2"
            >
            <span>Trim</span>
          </label>
          
          <label class="flex items-center">
            <input 
              v-model="newColor.forPrime"
              type="checkbox"
              class="mr-2"
            >
            <span>Panel</span>
          </label>
          
        </div>
        
        <div class="col-span-full">
          <button 
            type="submit"
            class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Add Color
          </button>
        </div>
      </form>
    </div>
    
    <!-- Color List -->
    <div class="overflow-x-auto">
      <table class="min-w-full bg-white border">
        <thead>
          <tr class="bg-gray-100">
            <th class="px-4 py-2 text-left">Name</th>
            <th class="px-4 py-2 text-left">Alias</th>
            <th class="px-4 py-2 text-left">Caulk</th>
            <th class="px-4 py-2 text-left">MBS</th>
            <th class="px-4 py-2 text-center">Trim</th>
            <th class="px-4 py-2 text-center">Panel</th>
            <th class="px-4 py-2 text-left">Media Color</th>
            <th class="px-4 py-2 text-left">3D Color</th>
            <th class="px-4 py-2 text-center">Status</th>
            <th v-if="isEditMode" class="px-4 py-2 text-center">Actions</th>
            <th class="px-4 py-2 text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(color, index) in colors" 
            :key="color.id"
            :class="{ 'bg-gray-50': !color.enabled }"
          >
            <td class="px-4 py-2">
              <input 
                v-if="editingRow == index"
                v-model="color.name"
                @change="setEditFlag(color)"
                type="text"
                class="w-full px-2 py-1 border rounded"
              >
              <span v-else>{{ color.name }}</span>
            </td>
            <td class="px-4 py-2">
              <input 
                v-if="editingRow == index"
                v-model="color.masterCode"
                @change="setEditFlag(color)"
                type="text"
                class="w-full px-2 py-1 border rounded"
              >
              <span v-else>{{ color.masterCode }}</span>
            </td>
            <td class="px-4 py-2">
              <input 
                v-if="editingRow == index"
                v-model="color.caulkCode"
                @change="setEditFlag(color)"
                type="text"
                class="w-full px-2 py-1 border rounded"
              >
              <span v-else>{{ color.caulkCode }}</span>
            </td>
            <td class="px-4 py-2">
              <input 
                v-if="editingRow == index"
                v-model="color.mbsCode"
                @change="setEditFlag(color)"
                type="text"
                class="w-full px-2 py-1 border rounded"
              >
              <span v-else>{{ color.mbsCode }}</span>
            </td>
            <td class="px-4 py-2 text-center">
              <input 
                v-if="editingRow == index"
                v-model="color.forTrim"
                @change="setEditFlag(color)"
                type="checkbox"
                class="w-4 h-4"
              >
              <span v-else-if="color.forTrim">✓</span>
            </td>
            <td class="px-4 py-2 text-center">
              <input 
                v-if="editingRow == index"
                v-model="color.forPrime"
                @change="setEditFlag(color)"
                type="checkbox"
                class="w-4 h-4"
              >
              <span v-else-if="color.forPrime">✓</span>
            </td>            
            <td class="px-4 py-2">
              <div class="flex items-center space-x-3">
                <template v-if="editingRow != index">
                  <div 
                    class="w-8 h-8 border rounded flex-shrink-0"
                    :style="{ backgroundColor: color.mediaHex }"
                  ></div>
                  <span>{{ color.mediaHex }}</span>
                </template>
                <template v-else>
                  <ColorPalettePicker :color="color" :hex="color.mediaHex" @colorSelected="updateColorMediaHex"></ColorPalettePicker>
                  <input 
                    v-model="color.mediaHex"
                    @change="setEditFlag(color)"
                    type="text"
                    pattern="^#[0-9A-Fa-f]{6}$"
                    class="w-full px-2 py-1 border rounded"
                  >
                </template>
              </div>
            </td>
            <td class="px-4 py-2">
              <div class="flex items-center space-x-3">
                <template v-if="editingRow != index">
                  <div 
                    class="w-8 h-8 border rounded flex-shrink-0"
                    :style="{ backgroundColor: color.designerHex }"
                  ></div>
                  <span>{{ color.designerHex }}</span>
                </template>
                <template v-else>
                  <ColorPalettePicker :color="color" :hex="color.designerHex" @colorSelected="updateColorDesignerHex"></ColorPalettePicker>
                  <input 
                    v-model="color.designerHex"
                    @change="setEditFlag(color)"
                    type="text"
                    pattern="^#[0-9A-Fa-f]{6}$"
                    class="w-full px-2 py-1 border rounded"
                  >
                </template>
              </div>
            </td>
            <td class="px-4 py-2 text-center">
              <span 
                :class="{
                  'text-green-600': color.enabled,
                  'text-red-600': !color.enabled
                }"
              >
                {{ color.enabled ? 'Enabled' : 'Disabled' }}
              </span>
            </td>
            <td v-if="isEditMode" class="px-4 py-2 text-center">
              <button
                @click="toggleColorStatus(color)"
                class="px-2 py-1 rounded text-sm mr-2"
                :class="{
                  'bg-red-100 text-red-600 hover:bg-red-200': color.enabled,
                  'bg-green-100 text-green-600 hover:bg-green-200': !color.enabled
                }"
              >
                {{ color.enabled ? 'Deactivate' : 'Activate' }}
              </button>
              <button
                @click="deleteColor(color)"
                class="bg-red-100 text-red-600 px-2 py-1 rounded text-sm hover:bg-red-200"
              >
                Delete
              </button>
            </td>
            <td>
              <button 
                v-if="isEditMode && editingRow == index"
                class="px-4 py-2 rounded font-medium bg-green-500 text-white hover:bg-green-600"
                @click="validateAndSaveColor(color)"
              >
                Save Changes
              </button>
              <button 
                v-else 
                :disabled="isEditMode && editingRow != index" 
                @click="toggleEditMode(index)"  
                class="px-4 py-2 rounded font-medium bg-blue-500 text-white hover:bg-blue-600"
              >
                Edit Color
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapActions, mapMutations } from 'vuex';
import ColorPalettePicker from '@/components/ColorPalettePicker.vue';
export default {
  name: 'ColorManager',
  components:{
    ColorPalettePicker
  },
  data() {
    return {
      colors: [],
      newColor: this.getEmptyColorObject(),
      nextId: 24,
      isEditMode: false,
      showAddColorEditor: false,
      originalColors: null, // For storing original state when entering edit mode,
      editingRow: null,
    }
  },
  computed: {
    ...mapState('contextModule', ['current']),
    supplier()
    {
      return this.current.supplier;
    },
    nonDeletedColors(){
      return this.colors.filter(c => c.deleted != true);
    }
  },
  async mounted(){
    await ContextLogic.initContext(this);
    await this.loadColors();
  },
  methods: {
    updateColorDesignerHex(hex, color){
      color.designerHex = hex;
      this.setEditFlag(color);
    },
    updateColorMediaHex(hex, color){
      color.mediaHex = hex;
      this.setEditFlag(color);
    },
    setEditFlag(color){
      color.edited=true;
    },
    toggleEditMode(index) {
      if(this.editingRow === index){
        // Turn editing off
        this.editingRow = null;
        this.isEditMode = false;
      }
      else{
        // Turn editing on
        this.editingRow = index;
        this.isEditMode = true;
        this.originalColors = JSON.parse(JSON.stringify(this.colors));
      }
        

      // if (this.isEditMode) {
      //   // If we're currently in edit mode, validate changes before saving
      //   if (this.validateAllColors()) {
      //     this.isEditMode = false;
      //     this.originalColors = null; // Clear the backup
      //     this.saveEditedColors();
      //     this.colors = [];
      //     this.loadColors();

      //   }
      // } else {
      //   // Entering edit mode - create a backup of current colors
      //   this.originalColors = JSON.parse(JSON.stringify(this.colors));
      //   this.isEditMode = true;
      // }
    },
    async loadColors(){
      try{
        this.colors = await api.getSupplierColors(this.supplier.id);
      }
      catch{
        console.error("unable to get colors for company");
      }
    },
    async validateAndSaveColor(color){
      if(!color.edited){
        this.editingRow = null;
        this.isEditMode = false;
        return;
      }

      if (!this.validateColor(color, true)) {
          return 
      }

      await this.saveEditedColor(color);
    },
    async saveEditedColors(){
      this.colors.forEach(async (color)=> {
        if(!color.edited)
          return;

        await api.updateColor(color);
      })
    },
    async saveEditedColor(color){
      let response = await api.updateColor(color);
      if(response.isError){
        this.$toast.error(`Could not upddate color "${color.name}"`)
      }
      else{
        this.$toast.success(`Updated color "${color.name}"`)
        this.isEditMode = false;
        this.editingRow = null;
        this.originalColors = null; // Clear the backup

        this.colors = [];
        this.loadColors();

        // new color reset
        this.newColor = this.getEmptyColorObject()
        this.showAddColorEditor = false
      }
    },
    validateAllColors() {
      for (const color of this.colors) {
        if (!this.validateColor(color, true)) {
          return false;
        }
      }
      return true;
    },

    validateColor(color, isEdit = false) {
      // Check for duplicate name
      const duplicateName = this.colors.some(c => 
        c.name.toLowerCase() === color.name.toLowerCase() && 
        (!isEdit || c.id !== color.id)
      );
      if (duplicateName) {
        alert(`Only one color may use the name ${duplicateName}.`);
        return false;
      }
      
      // Check for duplicate masterCode
      const duplicateAbbr = this.colors.some(c => 
        c.masterCode.toLowerCase() === color.masterCode.toLowerCase() && 
        (!isEdit || c.id !== color.id)
      );
      if (duplicateAbbr) {
        alert(`Only one color may use the masterCode ${duplicateAbbr}.`);
        return false;
      }
      
      // Validate hex colors
      const hexRegex = /^#[0-9A-Fa-f]{6}$/;
      if (!hexRegex.test(color.mediaHex) || !hexRegex.test(color.designerHex)) {
        alert('Please enter valid hex color values (e.g., #FF0000)');
        return false;
      }
      
      // Validate color codes. 
      // 50 chars should be long enough, so that users aren't restricted
      if (color.masterCode.length > 50) {
        alert('masterCode must be exactly 50 characters or less.');
        return false;
      }
      
      if (color.caulkCode && color.caulkCode.length > 50) {
        alert('Caulk code must be exactly 50 characters or less.');
        return false;
      }
      
      if (color.mbsCode && color.mbsCode.length > 50) {
        alert('MBS code must be exactly 50 characters');
        return false;
      }
      
      return true;
    },
    getEmptyColorObject() {
      return {
        name: '',
        masterCode: '',
        caulkCode: '',
        mbsCode: '',
        mediaHex: '#000000',
        designerHex: '#000000',
        forTrim: false,
        forPrime: false,
        enabled: true
      }
    },
    
    validateNewColor(color) {
      // Check for duplicate name
      if (this.colors.some(c => c.name.toLowerCase() === color.name.toLowerCase())) {
        alert('A color with this name already exists.')
        return false
      }
      
      // Check for duplicate masterCode
      if (this.colors.some(c => c.masterCode.toLowerCase() === color.masterCode.toLowerCase())) {
        alert('A color with this masterCode already exists.')
        return false
      }
      
      // Validate hex colors
      const hexRegex = /^#[0-9A-Fa-f]{6}$/
      if (!hexRegex.test(color.mediaHex) || !hexRegex.test(color.designerHex)) {
        alert('Please enter valid hex color values (e.g., #FF0000)')
        return false
      }
      
      // Validate 2-character codes
      if (color.masterCode.length !== 2) {
        alert('masterCode must be exactly 2 characters')
        return false
      }
      
      if (color.caulkCode && color.caulkCode.length !== 2) {
        alert('Caulk code must be exactly 2 characters')
        return false
      }
      
      if (color.mbsCode && color.mbsCode.length !== 2) {
        alert('MBS code must be exactly 2 characters')
        return false
      }
      
      return true
    },
    
    async addColor() {
      if (!this.validateNewColor(this.newColor)) {
        return
      }
      
      const colorToAdd = {
        ...this.newColor,
        // id: this.nextId++,
        entityId: this.supplier.id
      }

      await this.saveEditedColor(colorToAdd)
      
      // this.colors.push(colorToAdd)
      // this.newColor = this.getEmptyColorObject()
      // this.showAddColorEditor = false
    },
    
    async toggleColorStatus(color) {
      color.enabled = !color.enabled
      this.setEditFlag(color);

      await validateAndSaveColor(color);
    },
    async deleteColor(colorToDelete) {
      if (confirm('Are you sure you want to delete this color?')) {
        colorToDelete.deleted = true;
        colorToDelete.enabled = false;
        //this.setEditFlag(colorToDelete)
        // this.colors = this.colors.filter(color => color.id !== colorToDelete.id)
  
          let response = await api.deleteColor(colorToDelete.id);
          if(response.isError){
            this.$toast.error(`Could not delete color "${colorToDelete.name}"`)
          }
          else{
            this.$toast.success(`Sucessfully deleted color "${colorToDelete.name}"`)
            // this.isEditMode = false;
            // this.editingRow = null;
            // this.originalColors = null; // Clear the backup

            // this.colors = [];
            // this.loadColors();
          }
        
   
      }

        this.isEditMode = false;
        this.editingRow = null;
        this.originalColors = null; // Clear the backup

        this.colors = [];
        this.loadColors();
      
      // await this.loadColors();
    }
  }
}
</script>

<style scoped>
/* Layout */
.p-4 { padding: 1rem; }
.px-2 { padding-left: 0.5rem; padding-right: 0.5rem; }
.px-3 { padding-left: 0.75rem; padding-right: 0.75rem; }
.px-4 { padding-left: 1rem; padding-right: 1rem; }
.py-1 { padding-top: 0.25rem; padding-bottom: 0.25rem; }
.py-2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
.mb-1 { margin-bottom: 0.25rem; }
.mb-3 { margin-bottom: 0.75rem; }
.mb-4 { margin-bottom: 1rem; }
.mb-6 { margin-bottom: 1.5rem; }
.mr-2 { margin-right: 0.5rem; }
.space-x-2 > * + * { margin-left: 0.5rem; }
.space-x-4 > * + * { margin-left: 1rem; }

/* Typography */
.text-sm { font-size: 0.875rem; }
.text-xl { font-size: 1.25rem; }
.text-2xl { font-size: 1.5rem; }
.font-medium { font-weight: 500; }
.font-semibold { font-weight: 600; }
.font-bold { font-weight: 700; }
.text-left { text-align: left; }
.text-center { text-align: center; }

/* Colors */
.text-white { color: white; }
.text-red-600 { color: #dc2626; }
.text-green-600 { color: #16a34a; }
.bg-white { background-color: white; }
.bg-gray-50 { background-color: #f9fafb; }
.bg-gray-100 { background-color: #f3f4f6; }
.bg-blue-500 { background-color: #3b82f6; }
.bg-green-500 { background-color: #36d330; }
.bg-red-100 { background-color: #fee2e2; }
.bg-green-100 { background-color: #dcfce7; }

.hover\:bg-blue-600:hover { background-color: #2563eb; }
.hover\:bg-red-200:hover { background-color: #fecaca; }
.hover\:bg-green-200:hover { background-color: #bbf7d0; }

/* Borders */
.border { border: 1px solid #e5e7eb; }
.rounded { border-radius: 0.25rem; }
.rounded-lg { border-radius: 0.5rem; }

/* Layout containers */
.color-manager {
    max-width: 1200px;
    margin: 0 auto;
}

.grid {
    display: grid;
    gap: 1rem;
}

.grid-cols-1 { grid-template-columns: repeat(1, 1fr); }

@media (min-width: 768px) {
    .md\:grid-cols-2 { grid-template-columns: repeat(2, 1fr); }
}

@media (min-width: 1024px) {
    .lg\:grid-cols-3 { grid-template-columns: repeat(3, 1fr); }
}

.col-span-full { grid-column: 1 / -1; }

/* Flex */
.flex { display: flex; }
.items-center { align-items: center; }

/* Table */
.min-w-full { min-width: 100%; }
/* .overflow-x-auto { overflow-x: auto; } */

/* Form elements */
.block { display: block; }
.w-full { width: 100%; }
.w-6 { width: 1.5rem; }
.w-8 { width: 2rem; }
.h-6 { height: 1.5rem; }
.h-8 { height: 2rem; }

/* Color preview boxes */
.color-preview {
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
}

/* Button styles */
button {
    cursor: pointer;
    border: none;
    transition: background-color 0.2s;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Form inputs */
input[type="text"],
input[type="checkbox"] {
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
}

input[type="text"]:focus {
    outline: 2px solid #3b82f6;
    outline-offset: -1px;
}

/* Table styles */
table {
    border-collapse: collapse;
    width: 100%;
}

th {
    font-weight: 600;
    text-align: left;
}

td, th {
    padding: 0.5rem 1rem;
    border: 1px solid #e5e7eb;
}
</style>
