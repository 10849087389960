<template>
  <div class="subPanel grid gap-4" style="grid-template-columns: 1fr var(--icon-button-2-size);">
    <SnuiWrapper>
      <div>
        <!-- cameras -->
        <div class="flex" style="justify-content: space-between;" id="view-modes">
          <div>
            <div class="tooltip-wrap align-bs">
              <button @click="useLayoutCamera" :class="{active : isInLayoutMode}" class="icon-button-3" aria-expanded="false" aria-controls="view-mode-content-1" aria-labelledby="tooltip-250128113244-1">
                <Icon aria-hidden="true" focusable="false" name="3d-move" custom-class="icon icon-size-3"/>
              </button>
              <div role="tooltip" id="tooltip-250128113244-1">2D View</div>
            </div>
          </div>
          <div>
            <div class="tooltip-wrap align-bs">
              <button @click="cycleOrbitCamera" :class="{active : isInOrbitMode}" class="icon-button-3" aria-expanded="true" aria-controls="view-mode-content-2" aria-labelledby="tooltip-250128113244-2">
                <Icon aria-hidden="true" focusable="false" name="3d-coordinate-axis" custom-class="icon icon-size-3"/>
              </button>
              <div role="tooltip" id="tooltip-250128113244-2">3D View</div>
            </div>
          </div>
          <div>
            <div class="tooltip-wrap align-bs">
              <button @click="useFirstPersonCamera" :class="{active : isInFirstMode}" class="icon-button-3" aria-expanded="false" aria-controls="view-mode-content-3" aria-labelledby="tooltip-250128113244-3">
                <Icon aria-hidden="true" focusable="false" name="user-home-single" custom-class="icon icon-size-3"/>
              </button>
              <div role="tooltip" id="tooltip-250128113244-3">Walk Around</div>
            </div>
          </div>
          <div>
            <div class="tooltip-wrap align-bs">
              <button @click="toggleQuoteView" :class="{active : view.layers.mode == 'quote'}" class="icon-button-3" aria-expanded="false" aria-controls="view-mode-content-4" aria-labelledby="tooltip-250128113244-4">
                <Icon aria-hidden="true" focusable="false" name="file-dollar" custom-class="icon icon-size-3"/>
              </button>
              <div role="tooltip" id="tooltip-250128113244-4">Show Only<br />Quoted Items</div>
            </div>
          </div>
        </div> <!-- end cameras -->

        <!-- high / low detail -->
        <div class="grid gap-3 mar-bs-5" style="grid-template-columns: 1fr auto;">
          <div>
            <label for="toggle-lod">Detail Preview</label>
          </div>
          <div>
            <label class="toggle-switch" style="--this-inline-size: 4.6rem;">
              <input @change="toggleLod" type="checkbox" id="toggle-lod" v-model="lod">
              <span class="text" aria-hidden="true">
                <span>High</span>
                <span>Low</span>
              </span>
            </label>
          </div>
        </div><!-- end high/low detail -->

        <div class="view-mode-content" v-if="isInLayoutMode" id="view-mode-content-1">
          <!-- <div class="pad-bs-5">view mode 1 dependant content</div> -->
        </div>
        <div class="view-mode-content" v-if="isInOrbitMode" id="view-mode-content-2">
          <ViewPresetControls></ViewPresetControls>
        </div>
        <div class="view-mode-content" v-if="isInFirstMode" id="view-mode-content-3">
          <!-- <div class="pad-bs-5">view mode 1 dependant content</div> -->
        </div>
        <div class="view-mode-content" id="view-mode-content-4">
          <!-- <div class="pad-bs-5">view mode 1 dependant content</div> -->
        </div>

      </div> <!--snui wrapper -->
    </SnuiWrapper>
    
  </div>
</template>

<script>
import SnuiWrapper from '@/components/SnuiWrapper.vue';
import { CORE, cameraModes} from '../js/_spec.js'
import VisibilityToggle from './visibilityToggle.vue'
import { ToggleButton } from 'vue-js-toggle-button' //https://www.npmjs.com/package/vue-js-toggle-button
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import ViewPresetControls from './viewPresetControls.vue';
export default {
  components:{
    VisibilityToggle,
    ToggleButton,
    SnuiWrapper,
    ViewPresetControls
  },
  data: ()=>{
      return {            
          CORE,
          showViewingAngles:false,
      }
  },
  computed:{
    ...mapState('designModule', [
      'view'
    ]),
    ...mapGetters('designModule',[
      'inCameraModeFirst',
      'inCameraModeOrbit',
      'inCameraModeLayout',
      'currentLayers',
      'shouldShowQuoteLayer',
      'showQuote'
    ]),
    showQuoting: {
      get() {
        return this.$store.state.designModule.showQuoting
      },
      set(value) {
        this.$store.commit('designModule/showQuoting', value)
      }
    },
    lod(){
        let v = this.$store.getters.lod == 'high'
        console.log(v);
        return v;
    },
    isInLayoutMode(){
      console.log("in camera layout mode", this.inCameraModeLayout)
      return this.inCameraModeLayout;
      
    },
    isInOrbitMode(){
      console.log("in camera orbit mode", this.inCameraModeOrbit)
      return this.inCameraModeOrbit;
    },
    isInFirstMode(){
      console.log("in camera first mode", this.inCameraModeFirst)
      return this.inCameraModeFirst;
    },
    editable(){
      return this.$store.state.editable;
    },
  },
  methods:{
    ...mapActions('designModule',[
      'updateQuoting',
      'showFront',
      'showBack',
      'showLeft',
      'showRight',
      'showFrontLeftPerspective',
      'showFrontRightPerspective',
      'showBackLeftPerspective',
      'showBackRightPerspective',
      'showToggleLayers',
      'showQuoteLayer',
      'switchToCamera',
      'cycleOrbitCamera', 
    ]),
    ...mapMutations('designModule',[
      'setDetail',
    ]),
    toggleLod(){
          if(CORE.preferences.des_levelOfDetail.value == CORE.levelOfDetail.low)
              this.setDetail(CORE.levelOfDetail.high);
          else
              this.setDetail(CORE.levelOfDetail.low);
      },
    hideViewingAngles(){
      this.showViewingAngles=false;
    },
    _showFront(){
        this.showFront()
        this.hideViewingAngles();
    },
    _showBack(){
        this.showBack()
        this.hideViewingAngles();
    },
    _showLeft(){
        this.showLeft()
        this.hideViewingAngles();
    },
    _showRight(){
        this.showRight()
        this.hideViewingAngles();
    },
    _showFrontLeftPerspective(){
        this.showFrontLeftPerspective()
        this.hideViewingAngles();
    },
    _showFrontRightPerspective(){
        this.showFrontRightPerspective()
        this.hideViewingAngles();
    },
    _showBackLeftPerspective(){
        this.showBackLeftPerspective()
        this.hideViewingAngles();
    },
    _showBackRightPerspective(){
        this.showBackRightPerspective()
        this.hideViewingAngles();
    },
    useToggleView(){
            this.showToggleLayers();
    },
    useQuoteView(){
        this.showQuoteLayer();
    },
    toggleQuoteView(){
      console.log('should show quote layer', this.shouldShowQuoteLayer)
        if(this.shouldShowQuoteLayer)
            this.useToggleView();
        else    
            this.useQuoteView();
    },    
        useLayoutCamera(){
      this.switchToCamera(cameraModes.layout);
    } ,       
    useFirstPersonCamera(){
      this.switchToCamera(cameraModes.first);
    },
    useOrbitCamera(){
      // this is used simply to switch from another camera mode (first or layout) to orbit (no rotation)
      this.switchToCamera(cameraModes.orbit);
    },
    // cycleOrbitCamera(){
    //   // this is used to iterate through three orbit modes (no rotation, slow rotation, fast rotation)
    //   this.cycleOrbitCamera();
    // },
  },
  mounted(){

  }
  
}
</script>

<style scoped>

</style>