
import * as THREE from 'three';
import { CORE, impactTypes, rebuildTypes } from '../_spec.js';
import BlueprintHelper from '../helpers/blueprintHelper.js';
import OptionHelper from '../helpers/optionHelper.js';
import cStructureSecondary from './StructureSecondary.js';
import Foundation from './foundationbase.js';

import { Vector3 } from 'three';
import _3dDistHori from '../3d/DistHori.js';
import ConstraintHelper from '../helpers/ConstraintHelper.js';
import PickHelper from '../helpers/PickHelper.js';
import UpdateHelper from '../helpers/UpdateHelper.js';
import ColorHelper from '../helpers/colorHelper.js';
import FeatureHelper from '../helpers/featureHelper.js';
import materialHelper from '../helpers/materialHelper.js';
import Appearance from './appearance.js';
import Options from './options.js';
import StructureSecondary from './StructureSecondary.js';
import StructureBase from './StructureBase.js';

export default class StructureLeanTo extends cStructureSecondary{
    /*
    this class represents a standard rectangular structure 
    The Ameristall raised center aisle would require another structure class
    */   
    constructor(design, side, mDesign, model, pos, rot){        
        super(design, side, mDesign, model, pos, rot);
    }

    
    initRebuildHierarchy(){        
        super.initRebuildHierarchy();
    }

    migrate(design){
        super.migrate(design);
        /*if(Util.isUndefined(design.left) || Util.isUndefined(design.right))
        {
            design.left = {
                wrapValid: false,
                wrapEnabled: false
            }
            design.right = {
                wrapValid: false,
                wrapEnabled: false
            }
        }*/

        if(!design.dim)
            design.dim ={
                heightOffset:0
            }
     
        design.roofFrameType= CORE.roof.frameTypes.bypass;
    }
    
    getOptionsSpec(){

        let baseOptions = super.getOptionsSpec();

        let myConstraint = ConstraintHelper.getConstraintWithChildId(this.masterDesign.constraintRootNode, this.design.id);
        let sideOfMyParent = myConstraint.parent.matingSide;
        const {left: adjLeftSide, right: adjRightSide} = BlueprintHelper.getAdjacentSides(sideOfMyParent);

        // [DONE] frame type (option only enabled for bolt-up main structure)
        // [DONE] EndSkirting        
        // [DONE]Wainscoting?
        // [DONE] Open to main structure (always back for now)
        // [DONE] Include back wall checkbox
        // 
        // start
        // length
        // width

        return [
            this.getOption_FrameType(),
            // OptionHelper.switch("roofType", "Roof", "Slope", "Gable", impactTypes.structureRoofType, {checked: '#666666', unchecked:"#666666"}, {checked: '#0000FF', unchecked:"#00FF00"},
            // true,
            // ()=>{//fnIn
            //     return this.roofDesign.roofType === CORE.roof.types.gable;
            // },
            // (v)=>{//fnOut
            //     if(v)
            //         return CORE.roof.types.gable;
            //     return CORE.roof.types.slope;
            // },
            // (v)=>{//fnChanged
            //     this.roofDesign.roofType = v;
            //     //this.updateMaxRidgeHeight()
            // }
            // ),

            OptionHelper.numericUpDown("width","Width", this.getImpactTypeForWidth(), "ft.", 
            this.getWidthMinimum(), 100, 1,
            true,
//            OptionHelper.numericUpDown("width","Width", impactTypes.structureWidth, "ft.", 20, 100, 1,
            ()=>{ //fnIn
                return this.frameDesign.width;
            }, 
            undefined,
            (v)=>{ //fnChange
                this.applyChangeWidth(v*12);
            },  
            ),

            OptionHelper.numericUpDown("length","Length", this.getImpactTypeForLength(), "ft.", 6, 300, 1,
            true,
//            OptionHelper.numericUpDown("length","Length", impactTypes.structureLength, "ft.", lengthMin, 300, 1,
            ()=>{
                return this.frameDesign.length;
            }, //fnIn
            undefined, //fnOut
            (v)=>{//fnChange
                this.frameDesign.length = v;
            }
            ),

            OptionHelper.numericUpDown("dim.heightOffset","Height offset",  impactTypes.structureHeight, "in.", undefined, undefined, 1, true,
            undefined,
            undefined,
            (v)=>{
                let shedMinPositiveHeightOffset = 12;
                // heightOffset are relative to the low-eave height of a main building
                // heightOffset has to either be flush or leave space (12 inches) for a short downspout
                // TODO: CBH - short-downspout rule enforcement is not as detailed as it should be...
                // this rule should ALWAYS apply to the FSW shed since the front of a main building is always a low side of the roof
                // this rule should apply to BSW sheds when the main building has a gabled roof
                // this rule should NEVER apply to BSW sheds when the main building has a single slope
                // this rule should apply to any shed wrapped with FSW shed because the entire wrapped shed should not go somewhere that the FSW shed cannot go
                // as of 2023.06.08, no detection of "is this shed wrapped with a front shed" is done. 
                // it's simplest and acceptable for now to always apply the rule to all sheds 

                // if this was flush, but now is in-between 0 and 12, go to 12, because the user increased the value and 12 is the next valid value above 0
                if(this.design.dim.heightOffset == 0 && v>0 && v<shedMinPositiveHeightOffset)
                    v=shedMinPositiveHeightOffset;
                
                // if this was 12, but now is in-between 0 and 12, go to 0, because the user decreased the value and 0 is the next valid value below 0
                else if(this.design.dim.heightOffset == 12 && v>0 && v<shedMinPositiveHeightOffset)
                    v=0;
                // if the new offset is in-between 0 and 12 (but the user did not request the next valid value up or down)
                else if (v>0 && v<shedMinPositiveHeightOffset) {
                    // if the value is coming from above the valid range, stop at the top edge of the valid range
                    if(this.design.dim.heightOffset > shedMinPositiveHeightOffset)
                        v = 12;                        
                    else // otherwise, the value is coming from below the valid range, stop at the bottom edge of the valid range
                        v =0;                    
                }
                
                this.design.dim.heightOffset = v;
                this.updateHeightFromHeightOffset(this.design, this.frameDesign, this.maxRidgeHeight );
            },
            "Inches below the low eave height. Negative values are allowed for tieing-in a shed above the low eave height, such as EW sheds or BSW sheds on a single slope building."),
            OptionHelper.numericUpDown("dim.height","Height",  impactTypes.structureHeight, "in.", 12, 240, 1, false, 

            ),
            OptionHelper.numericUpDown("roofPitch","Pitch", impactTypes.structureRoofPitch, "/12", 0, 6, 1.0/4.0,
            true,
            //fnIn
            ()=>{ 
                return this.roofDesign.pitch;
            },
            undefined,
            (v)=>{ // fnChanged
                StructureSecondary.updateRoofPitch(this.design, v);
            }
            ),
            OptionHelper.switch("roofFrameType", "Roof Frame Type", null, "Flush", "Bypass", impactTypes.structureRoofFrameType, {checked: '#666666', unchecked:"#666666"}, {checked: '#0000FF', unchecked:"#00FF00"},
            true,
            ()=>{//fnIn
                return this.roofDesign.roofFrameType === CORE.roof.frameTypes.bypass;
            },
            (v)=>{//fnOut
                if(v)
                    return CORE.roof.frameTypes.bypass;
                return CORE.roof.frameTypes.flush;
            },
            (v)=>{//fnChanged
                this.roofDesign.roofFrameType = v;
            }
            ),   

            // OptionHelper.header('Left End'),
            // this.getOption_OpenBaysLeftEnd(),
            // this.getOption_SkirtLeftEnd(),

            // OptionHelper.header('Right End'),
            // this.getOption_OpenBaysRightEnd(),
            // this.getOption_SkirtRightEnd(),

            ...this.getOption_Wainscoting(),

            // OptionHelper.header('Back Opening'),
            // OptionHelper.checkbox(`openToMain`,'Main Structure Opening', impactTypes.structureWalls,
            // true,
            // undefined, //fnIn
            // undefined, //fnOut
            // (v)=>{ // fnChanged
            //     this.design.openToMain = v;
            //     if(v)
            //      this.design.backWall = false;
            // }
            // ),

            // OptionHelper.checkbox(`backWall`,'Include Back Wall', impactTypes.leanToBack,
            // true,
            // undefined, //fnIn
            // undefined, //fnOut
            // (v)=>{
            //     this.design.backWall = v;
            //     if(v)
            //      this.design.openToMain = false;
            // }
            // ),

            

        ];
        
    }

    getImpactTypeForLength(){
        return impactTypes.leanToLength;
    }

    getImpactTypeForWidth(){
        return impactTypes.leanToWidth;
    }

    processInputGrabject(input){
        if(input.role === CORE.grabjects.position){
            throw 'Lean-To position grabject is not currently supported'
        }
        else
            return this.updateSize(input);
    }

    processImpactFromSelf(impact){
        impact.handled.push(this.design.id);
        switch(impact.change.name){
            case impactTypes.secondaryStructureConstraintChange:
            case impactTypes.structureWainscot: 
            case impactTypes.structureWidth:
            case impactTypes.structureHeight:
            case impactTypes.structureFrameType:
            case impactTypes.structureRoofPitch:
            case impactTypes.structureWrap:                
            //case impactTypes.structureLength:
            case impactTypes.structureRoofType:
            case impactTypes.structureRoofPitch:
            case impactTypes.structureOpenBays:
            case impactTypes.structureRoofFrameType:
            case impactTypes.addLeanTo:
            case impactTypes.leanToLength:
            case impactTypes.leanToWidth: 
            case impactTypes.leanToBack:           
                this.specificInit(impact);
                this.initBlueprints();
                this.addRebuildNeeded(rebuildTypes.full);
                break;
        }
        //this.calculateFramelines();
    }

    processImpactFromOther(impact){
        impact.handled.push(this.design.id);
        // impact is an impact from any component (including itself)
        // return 0+ impacts for itself 
        //console.log('Porch detecting impact:', impact);
        let constraint = ConstraintHelper.areConstrainedTogether(this.design.id, impact.id, this.masterDesign.constraintRootNode);
        let impactName = impact.change.name
        switch(impactName){
            case impactTypes.structureHeight:
            case impactTypes.leanToLength:
            case impactTypes.structureWidth:
            case impactTypes.structureLength:           
            case impactTypes.structureBaySpacing:
                // these primary impacts affects lean-to framelines
                if(constraint)
                    this.addRebuildNeeded(rebuildTypes.full);
                break;
           
            case impactTypes.foundationHeight:            
                //  check if the foundation's parent is constrained to this structure
                constraint = ConstraintHelper.areConstrainedTogether(this.design.id, impact.design.parent.id, this.masterDesign.constraintRootNode);
                if(constraint){
                    this.addRebuildNeeded(rebuildTypes.full);
                }
                break;
            // case impactTypes.deleteLeanTo:
            //     return this.detectLeanToDeleteImpact(impact);
            case impactTypes.structureRoofPitch:
            case impactTypes.optionsGutter:
            case impactTypes.optionsRoofExtFront:
            case impactTypes.optionsRoofExtBack:
            case impactTypes.optionsRoofExtLeft:
            case impactTypes.optionsRoofExtBack:
                this.addRebuildNeeded(rebuildTypes.roof);
                break;        
            case impactTypes.colorWall:
                let wallIndices = []
                this.addRebuildNeeded(rebuildTypes.full);
                this.components.forEach((c,i)=>{
                    if(FeatureHelper.objectTypeIsWall(c.design.type))
                        wallIndices.push(i);
                })
                wallIndices.forEach((i)=>{
                    this.components[i].design.color = impact.design.wall;    
                })
                break;
            case impactTypes.colorTrim:            
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.colorRoof:   
            case impactTypes.colorWainscot:  
                this.addRebuildNeeded(rebuildTypes.full);
            case impactTypes.colorTrimEaveAndRake:
                this.appearanceDesign.trims.eaveAndRake = impact.design.trims.eaveAndRake; 
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.colorTrimDownspout:
                this.appearanceDesign.trims.downspout = impact.design.trims.downspout; 
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.colorTrimCornerAndBase:
                this.appearanceDesign.trims.corner = impact.design.trims.corner; 
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.colorTrimDoor:
                this.appearanceDesign.trims.door = impact.design.trims.door; 
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.colorTrimWalksAndWindows:
                this.appearanceDesign.trims.walksAndWindows = impact.design.trims.walksAndWindows; 
                this.addRebuildNeeded(rebuildTypes.full);
                break;                                
            case impactTypes.optionsDripTrim:
            case impactTypes.optionsGalvanizedBeams:                
            case impactTypes.optionsGalvanizedPurlins:
            case impactTypes.optionsWrapTubeLeanToCols:
                this.addRebuildNeeded(rebuildTypes.full);
                break;
                // if other porch got shorter, update wrapValidity, issue change if appropriate
                // if other porch got longer, update wrapValidity, issue change if appropriate
        }
        this.specificInit(impact);
        this.initBlueprints();
    }
    getAddImpactType(){
        return impactTypes.addLeanTo;
    }

    getSideOfLeanToParent(id){
        return super.getSideOfSecondaryStructureParent(id);
    }

    // initTrimMaterials() {
    //     // Prevent color trim change on primary building from affecting lean-to color trim.
    //     // Lean-tos process both colorTrim impacts of itself and from its primary building. 
    //     // As a result walls are rebuilt and were using CORE.materials.trims values which was set by the primary building.
    //     let eaveAndRakeTrimMaterial;
    //     let downspoutTrimMaterial; 
    //     let cornerTrimMaterial; 
    //     let doorTrimMaterial; 
    //     let walkAndWindowTrimMaterial; 

    //     if(this.appearanceDesign.trimColorMode === CORE.modes.trimColor.single){            
    //         let allTrim = materialHelper.getTrimMaterial(this.appearanceDesign.trim, CORE.textures.trim.clone())

    //         eaveAndRakeTrimMaterial =
    //         downspoutTrimMaterial = 
    //         cornerTrimMaterial = 
    //         doorTrimMaterial = 
    //         walkAndWindowTrimMaterial = allTrim

    //     }
    //     else{
    //         eaveAndRakeTrimMaterial = new THREE.MeshPhongMaterial( { color: Number(this.appearanceDesign.trims.eaveAndRake), map: CORE.textures.trim.clone(), side: THREE.DoubleSide, wireframe:false});
    //         downspoutTrimMaterial =  new THREE.MeshPhongMaterial( 
    //             { color: Number(this.appearanceDesign.trims.downspout),
    //                 map: CORE.textures.trim.clone(),
    //                 side: THREE.DoubleSide,
    //                 wireframe:false,
    //                 shininess:10,
    //                 specular: new THREE.Color("skyblue")
    //             });            
    //          cornerTrimMaterial =  new THREE.MeshPhongMaterial( { color: Number(this.appearanceDesign.trims.corner), map: CORE.textures.trim.clone(), side: THREE.DoubleSide, wireframe:false});
    //         doorTrimMaterial =  new THREE.MeshPhongMaterial( { color: Number(this.appearanceDesign.trims.door), map: CORE.textures.trim.clone(), side: THREE.DoubleSide, wireframe:false});
    //         walkAndWindowTrimMaterial = new THREE.MeshPhongMaterial( { color: Number(this.appearanceDesign.trims.walksAndWindows), map: CORE.textures.trim.clone(), side: THREE.DoubleSide, wireframe:false});
    //     }

    //     let trimMaterials = {
    //         eaveAndRake: eaveAndRakeTrimMaterial,
    //         downspout: downspoutTrimMaterial,
    //         corner: cornerTrimMaterial,
    //         door: doorTrimMaterial,
    //         walkAndWindow: walkAndWindowTrimMaterial
    //     };

    //     return trimMaterials;
    // }

    specificInit(impact){
        // During lean-to first initialization, there is no impact
        let triggeredByFirstInit = false; 
        if(impact === undefined || impact === null)
            triggeredByFirstInit = true;
        
        this.design.type = CORE.components.leanTo;
        this.calculateFramelines(); 
        this.detectMatingFramelines();       
        this.frameDesign.isPorch = true;
        
        this.roofDesign.isPorch = false; 

        /*this.roofDesign.left={
            wrap: this.design.left.wrap
        }
        this.roofDesign.right={
            wrap: this.design.right.wrap
        }*/

        this.constrainingNode = ConstraintHelper.findConstrainerOfNodeWithId(this.masterDesign.constraintRootNode,this.design.id);
        let parentStructureDesign = this.masterDesign.getComponentDesignById(this.constrainingNode.id);
        if(this.constrainingNode && parentStructureDesign)
        {   
            if(triggeredByFirstInit || impact.change.name === impactTypes.addLeanTo){
                // During first initialization, a lean-to default should be just like it's main structure parent.
                // mergeDesign() is necessary for setting Appearance and Options design and allowing them to 
                // change independent of the main structure's settings. 
                this.foundationDesign = parentStructureDesign.getFoundation()
                    
                this.appearanceDesign = this.design.getAppearance();
                Appearance.migrate(this.appearanceDesign);
                //Appearance.mergeDesign(parentStructureDesign.getAppearance(), this.appearanceDesign)
                
                this.optionDesign = this.design.getOptions();
                //Options.mergeDesign(parentStructureDesign.getOptions(), this.optionDesign);

                this.jobSpecsDesign = this.design.getJobSpecs();
            }
            else{
                // During an impact, we only need to update the specific design change, not all of them
                switch(impact.change.name){
                    case impactTypes.structureWainscot:
                        this.appearanceDesign.wainscoting = parentStructureDesign.getAppearance().wainscoting;
                }
            }
            
        }

        this.applyColors(this.appearanceDesign);
        this.beamColor = this.getBeamColor();
        this.purlinColor = this.getPurlinColor();
    }

    getDescription(){
        if(this.design.name)
            return this.design.name;

        let sideName = '';
        if(!this.constrainingNode)
            return "LeanTo"

        let constrainerConstraints = ConstraintHelper.findConstraintsForNodeWithId(this.masterDesign.constraintRootNode,this.constrainingNode.id);
        if(constrainerConstraints && constrainerConstraints.length==1){
            let side = constrainerConstraints[0].parent.matingSide;
            sideName = CORE.sides.getName(side);
        }

        let name = this.design.name;
        if(sideName)
            name = sideName+" LeanTo"
        else
            name= "LeanTo"
        this.design.name = name;
    }

    buildPickOnly(){
        super.buildPickOnly(); // initialization is very important!

        
        let pitchHeight = 0; // unsure how to migrate
        let dim = new THREE.Vector3(this.frameDesign.length, this.frameDesign.height-pitchHeight, this.frameDesign.width).multiplyScalar(12);
        
        if(!this.meshPickDetectionXY){
            // a grabject intersection mesh cannot be removed during grabject editing or the grabjects will be jumpy, as the intersection relies on a consistent surface of some kind (even that surface is moving or affected)

            // XY
            var geoXY = new THREE.BoxGeometry(dim.x, dim.y*1.5, 2);
            geoXY.name = 'structureleanto picking xy'
            this.meshPickDetectionXY = new THREE.Mesh(geoXY, CORE.materials.transparent);
            this.meshPickDetectionXY.userData={
                type:  CORE.grabjects.pitch, // because this grabject has a basis of 'self', controller will look for an intersection with a mesh with userData type matching this
                id: this.design.id
            }
            this.meshPickDetectionXY.visible=false;
            this.meshPickDetectionXY.name = "grabject XY backboard mesh for pitch of " + this.getDescription();
            
            var geoX = new THREE.BoxGeometry(dim.x, 1, dim.z*2); 
            geoX.name = 'structureleanto picking x'
            this.meshPickDetectionX = new THREE.Mesh(geoX, CORE.materials.transparent);
            this.meshPickDetectionX.userData={
                type: CORE.grabjects.bottom,  // because this grabject has a basis of 'self', controller will look for an intersection with a mesh with userData type matching this
                id: this.design.id
            }
            this.meshPickDetectionX.visible=false;
            this.meshPickDetectionX.name = "grabject XZ backboard mesh for width of " + this.getDescription();

            this.gPick.add(this.meshPickDetectionXY);
            this.gPick.add(this.meshPickDetectionX);
            
        }
        this.meshPickDetectionXY.position.set(0,(dim.y)/2 ,dim.z/2 + 5);
        this.meshPickDetectionX.position.set(0, 7 ,dim.z/2);
        
    }

    buildGrabjects(dim){
        /*
        let halfDim = dim.clone().multiplyScalar(.5);
        this.grabjectGroup = new THREE.Group();
        this.grabjectGroup.name = 'lean-to grabject group';
        let grabjectSize = 15;
        let grabjectSizeVector = new THREE.Vector3(grabjectSize, grabjectSize, 5);
        let backOffset = this.getBackOffset();
        backOffset.z = 0;
        let frameSideParentComponents = [
            CORE.components.frameSide            
        ];

        let positionIndicators = [CORE.indicators.upDown];

        if(!this.design.left.wrapValid || !this.design.left.wrapEnabled)
            // top left (affects length)
            new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(-halfDim.x,dim.y,-halfDim.z), [CORE.indicators.leftRight], grabjectSizeVector, CORE.grabjects.topLeft, frameSideParentComponents, 'porch', new Vector3(0,15,5));
        
        if(!this.design.right.wrapValid || !this.design.right.wrapEnabled)
            // top right (affects length)
            new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(halfDim.x,dim.y,-halfDim.z), [CORE.indicators.leftRight], grabjectSizeVector, CORE.grabjects.topRight, frameSideParentComponents, 'porch', new Vector3(0,15,5));

        // porch height
        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(0,dim.y,-halfDim.z-backOffset.z), [CORE.indicators.upDown], grabjectSizeVector, CORE.grabjects.top, frameSideParentComponents, 'porch', new Vector3(0,15,5));
        // porch pitch
        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(0,dim.y,halfDim.z), [CORE.indicators.upDown], grabjectSizeVector, CORE.grabjects.pitch, [], 'self', new Vector3(0,0,5));
        // porch width (depth)
        new Grabject(this.grabjectGroup, this.design.id, new THREE.Vector3(0,10,halfDim.z), [CORE.indicators.inOut], grabjectSizeVector, CORE.grabjects.bottom, [], 'self', new Vector3(0,10,0));
        this.gDynamic.add(this.grabjectGroup);
        this.showGrabjects(this.design.selected);        
        */
    }

    buildDistanceMarkers(){
        // removeDistanceMarks undoes what's build here.
        super.buildDistanceMarkers();

        let flb = this.getWallFrontLeftBottom();
        let frb =  this.getWallFrontRightBottom();
        //let width = this.getStructureWidthInches();
       /* if(this.design.left.wrap)
            flb.x-=width;
        if(this.design.right.wrap)
            frb.x+=width;*/

        this.frontDistHori = new _3dDistHori(flb, frb, new THREE.Vector3(0,10,10), new THREE.Vector3(0,0,80), 1/12, CORE.layers.dimensions);
        this.groupMetal.add(this.frontDistHori.group);

        // if the peak lean-to height is not equal to main eave
        this.peakHeightDistHori = new _3dDistHori(this.getWallBackLeftBottom(), this.getWallBackLeftTop(),new THREE.Vector3(-5,.1,5), new THREE.Vector3(-20,.1,40), 1/12, CORE.layers.dimensions);
        this.groupMetal.add(this.peakHeightDistHori.group);
        this.lowHeightDistHori = new _3dDistHori(this.getWallFrontLeftBottom(), this.getWallFrontLeftTop(),new THREE.Vector3(-5,.1,5), new THREE.Vector3(0,.1,20), 1/12, CORE.layers.dimensions);
        this.groupMetal.add(this.lowHeightDistHori.group);

          this.backBayDistHoris = [];
        let columnWidth = 8;
        let structureHalfLengthInches = this.getStructureLengthInches()/2
        for(var fli = 1;fli<this.design.frameLines.length;fli++){
            
            let vLeft = this.getWallFrontLeftBottom();
            let vRight = this.getWallFrontRightBottom();
            vLeft.x = this.design.frameLines[fli-1] -structureHalfLengthInches;
            vRight.x = this.design.frameLines[fli] -structureHalfLengthInches;

            if(fli ===1) 
                vLeft.x-=columnWidth/2;
            if(fli === this.design.frameLines.length-1)
                vRight.x+=columnWidth/2;
            
                let backBayDistHori = new _3dDistHori(vLeft, vRight, new THREE.Vector3(0,10,5), new THREE.Vector3(0,0,50), 1/12, CORE.layers.dimensions)
            this.backBayDistHoris.push(backBayDistHori);
            this.groupMetal.add(backBayDistHori.group);
        }
    }

    
    // buildRoof(trimMaterials, frontEaveSegments, frontDownspouts, backEaveSegments, backDownspouts){
    //     // this.roofDesign.left ={wrap:false};
    //     // this.roofDesign.right={wrap:false};

    //     // super.buildRoof(trimMaterials);
    //     StructureBase.buildRoof(trimMaterials, frontEaveSegments, frontDownspouts, backEaveSegments, backDownspouts)
    // }

    buildFoundation(){
        let width = this.frameDesign.width*12;
        let length = this.frameDesign.length*12;
        
        // if(this.design.left.wrap)
        //     length+=width;

        let pos = new Vector3(0,0,0);// new Vector3().copy(dim).multiply(new Vector3(.5,0,.5)); // .5 inch wider foundation on each end      

        // if(this.design.left.wrap)
        //     pos.x -= width/2;

        
        this.foundation =  new Foundation(this.foundationDesign, new Vector3(length, 0, width), this.design);
        this.group.add(this.foundation.group);
        this.foundation.group.position.copy(pos);
        this.groupMetal.position.y = this.foundationDesign.height;
    }

    grabjectChangeLeft(newX){
        
        let increment = 3;
        let snapPoints = BlueprintHelper.subdivideLength(newX - increment*100, newX + increment*100, increment);
        let closest = PickHelper.getClosestValueToTarget(snapPoints, newX);
        let snapX = closest.closestValue;

        let frame = this.design.getFrame();
        let rightOld = frame.length*12;

        // if the left end has changed
        if(Math.abs(snapX) > increment/2){

            // adjust the length of the lean-to
            let newLength =rightOld+snapX; 
            frame.length = newLength/12;
            // the constraint for this lean-to must be handled so the lean-to doesn't jump over, now shorter.

            this.addRebuildNeeded(rebuildTypes.full);
            return UpdateHelper.createImpact(this.design, impactTypes.leanToLength);
        }
    }

    grabjectChangeRight(newX){
        if(this.parent.updatePorchRight(this.design, newX)){
        this.addRebuildNeeded(rebuildTypes.full);
            return UpdateHelper.createImpact(this.design, impactTypes.leanToLength);
        }
    }

    grabjectChangeTop(newY){
        let oldHeightOffset = this.design.dim.heightOffset;
        // we need to track the offset from the main structure eave height
        // we need to know that eave height
        // we need this newY 
        //let mainEaveHeight = this.maxRidgeHeight;//this.design.dim.parentHeight;
        let newOffset = this.maxRidgeHeight - newY;
        if(newOffset<6)
            newOffset=0;
        if(newOffset>6 && newOffset<12)
            newOffset=12;

        this.design.dim.heightOffset = newOffset;

        if(this.design.dim.heightOffset<0)
            this.design.dim.heightOffset=0;
        if(this.design.dim.heightOffset === oldHeightOffset)
            return;

        if(this.updateHeightFromHeightOffset(this.design, this.frameDesign, this.maxRidgeHeight) )
            this.addRebuildNeeded(rebuildTypes.full);
        return UpdateHelper.createImpact(this.design, impactTypes.leanToHeight);
    }

    applyChangeWidth(newWidthInches){
        // bound the input
        if(newWidthInches < this.getWidthMinimum()*12)
            newWidthInches = this.getWidthMinimum()*12;
        let newWidthFeet = newWidthInches/12;
        if(newWidthFeet === this.frameDesign.width)
            return; // short circuit

        
        // calculate new values from some existing value
        // use the old ridge height and the new width to calculate the new lowEaveHeight
        let pitchRatio = BlueprintHelper.pitchToPitchRatio(this.design.getRoof().pitch);
        let oldPitchHeight =  BlueprintHelper.pitchHeight(this.frameDesign.width*12, pitchRatio, this.design.getRoof().roofType);
        let ridgeHeight = this.frameDesign.height*12 + oldPitchHeight;
        
        let newPitchHeight =  BlueprintHelper.pitchHeight(newWidthInches, pitchRatio, this.design.getRoof().roofType);
        let newLowEaveHeight = ridgeHeight - newPitchHeight;

        let delta = newLowEaveHeight - (this.frameDesign.height*12)

        // apply new values
        this.frameDesign.width = newWidthFeet;
        this.frameDesign.height = newLowEaveHeight/12;

        
        this.updateBayHeights(delta)
                
        this.frontWallDesign.height+=delta;
        this.leftWallDesign.height+=delta;
        this.rightWallDesign.height+=delta;

        return UpdateHelper.createImpact(this.design, impactTypes.leanToWidth);
    }
    
    grabjectChangePitch(newY){
        // record old
        let oldRoofPitch = this.roofDesign.pitch;
        
        let pitchRatio = BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch);
        let pitchHeight = BlueprintHelper.pitchHeight(this.structureWidthInches(), pitchRatio, this.roofDesign.roofType )
        // calculate roofPitch height options
        let roofPitchHeights = [];

        for(var i=1;i<=6; i++)
            roofPitchHeights.push(12*this.frameDesign.height +pitchHeight- (12*this.frameDesign.width*i/12));
        
        let {closestIndex} = PickHelper.getClosestValueToTarget(roofPitchHeights, newY);
        //console.log(roofPitchHeights, closestIndex, newY)
        let newRoofPitch = closestIndex+1;
        if(newRoofPitch === oldRoofPitch)
            return;
        // calculate nearest roof pitch
        StructureSecondary.updateRoofPitch(this.design, newRoofPitch);
        return UpdateHelper.createImpact(this.design, impactTypes.leanToPitch);
    }

    
    // updateComponents(){
    //     this.components= []; // forget previous components
    //     // register these components so that sub-components and components they contain can be found
    // this.addComponent(this.foundation);
    // this.addComponent(this.frame);
    // this.addComponent(this.roof);
    // this.addComponent(this.appearance);
    // this.addComponent(this.options);
    // this.addComponent(this.jobSpecs);       
    // this.addComponent(this.wallFront);
    // this.addComponent(this.wallRight);
    // this.addComponent(this.wallBack);
    // this.addComponent(this.wallLeft);

    //     if(this.leftEndSkirt)
    //         this.addComponent(this.leftEndSkirt)

    //     if(this.rightEndSkirt)
    //         this.addComponent(this.rightEndSkirt)
    // }

}
