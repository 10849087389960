<template>
    <div>    
      <span class="select" >
        <select v-model="targetState">
          <option v-for="state in projectStates" :value="state" >{{ dictionary.getTerm(state) }}</option>
        </select>                        
      </span>
      <button class="inline btn btn-small" @click="set">Set</button>
    </div>
  </template>
  
  <script>
import { CORE } from '@/constants.js';
import Dictionary from '../Dictionary.js'

  export default {
    name: 'ProjectState',
    props:['initialState'],
    data() {      
      return {
        dictionary: [],
        projectStates: CORE.projectState,
        targetState: CORE.projectState.designed
      }
    },
    methods:{
      set(){
        this.$emit('set', this.targetState)
      }
    },
    created(){      
      this.dictionary = new Dictionary();
      this.targetState = this.initialState;
    }
  }
  </script>