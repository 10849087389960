import { render, staticRenderFns } from "./MobileMenuSubLink.vue?vue&type=template&id=60c7f1c4&scoped=true"
import script from "./MobileMenuSubLink.vue?vue&type=script&lang=js"
export * from "./MobileMenuSubLink.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60c7f1c4",
  null
  
)

export default component.exports