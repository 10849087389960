<template>
    <div>
        <div class="grid" style="column-gap: var(--sp-4); row-gap: var(--sp-5); grid-template-columns: 1fr 1fr">
            <div>
                <label for="skirtEndLeft">End</label>
                <span class="select">
                <select v-model="end" class="size-small" name="select" id="skirtEndLeft">
                    <option value="leftEnd">Left</option>
                    <option value="rightEnd">Right</option>
                </select>
                </span>
            </div>
            <div>
                <label for="bays">Bay Count</label>
                <input class="size-small align-end" type="number"  id="bays" name="input" v-model="bays">
            </div>
            <div>
                <div class="checkradio-item" style="font-size: var(--fs-3);">
                <input type="checkbox" name="addPartition" id="addPartition" v-model="addPartition">
                <label for="addPartition">Add partition</label>
                </div>
            </div>
        </div> <!-- grid -->
        <!-- <div class="grid mar-bs-6" style="column-gap: var(--sp-4); row-gap: var(--sp-5); grid-template-columns: 1fr 1fr">
            <div>
                <button @click="cancel" class="btn btn-small btn-secondary" data-popover1-trigger="close" aria-controls="p-250128171119" aria-expanded="false">Cancel</button>
            </div>
            <div></div>
            <div>
                <button @click="apply" class="btn btn-small"  data-popover1-trigger="close" aria-controls="p-250128171119" aria-expanded="false">Apply</button>
            </div>
        </div> -->
    </div>

</template>

<script>
import { CORE } from '../js/_spec';
import TargetStructureName from './targetStructureName.vue';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    props:{
        iTool: Object
        },
    computed:{
        ...mapState('designModule',[
            'tool',
        ]),
        ...mapGetters('designModule', [            
            'isGableTieInStructure'
        ]),
    },
    data(){
        return {
            end: CORE.sides.leftEnd,
            bays: 0,
            addPartition: true,
        }
    },
    mounted(){
        console.log('mounted tool')
        this.init();
    },
    methods:{
        ...mapActions('designModule',[
            'applyToolData',        
        ]),
    ...mapMutations('designModule',[
        'showToolModal',      
        ]),
        init(){
            this.end = this.tool.data.end;
            this.bays = this.tool.data.bays;
            this.addPartition = this.tool.data.addPartition;
        },
        apply(){        
            this.tool.data = {
                end: this.end,
                bays: this.bays,
                addPartition: this.addPartition
            }; 

            this.applyToolData()
            this.showToolModal(false)
        },
        cancel(){
            this.showToolModal(false)
        }
    },
    components:{
        TargetStructureName
    }
   
}
</script>

<style scoped>

td:nth-child(1) {
    text-align:right;
}
.main {    
    background-color: #222;
    border-color: #080808;
    width: 100%;
    left:0px;
    top:0px;
    color:white;
    display: flex;
}

.inset-tool-container {
    padding-bottom: 5px;
    border: 0px solid black;
}

.meta{
    display: inline-block;
    margin-left:10px;
    flex-grow: 2;
}

.name {
    font-size: 1em;
}

.specs {
    font-size: .7em;
}

.logo{
    width: 150px;
    position: relative;
}

.logo .img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.menuItem {
    margin:2px 10px;
    position:relative;
}

.menuItem.boxed{
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 3px;
    text-align: center;
}

.modeLabel{
    display: block;
    text-align: center;
}


.menuItem.title{
    overflow: hidden
}

.title .primary{
    font-weight: bold;
    font-style: italic;
    max-width: 95vw;
    font-size: 25px;
    display: block;
}

.title .beta{
    font-weight: bold;
    font-style: italic;
    max-width: 95vw;
    color:coral;
    font-size: 15px;
}

.title .beta{
    font-weight: bold;
    max-width: 95vw;
    font-size: 13px;
}

.btn {
    display:inline-block;
    width:auto;
    height: fit-content;
    /* nitty-gritty-override */
    min-height: unset;
}

.quote{
  display:block;
}

input[type="number"]{
    width: 80%;
}

td {
    padding:2px 5px;
    margin:2px 0px;
    max-width: 160px;
}

label{
    margin:10px
}

input{
    padding-left:5px;
}

</style>