<template>
    <div>
        <div class="box-shadow-1 mar-0 pad-0 color-context-neutral-light" style="border-radius: var(--br-4); overflow: hidden;">
            <div class="grid gap-4 border-be pad-i-4 pad-b-4" style="grid-template-columns: 1fr auto; align-items: center;">
                <div>
                <h3 class="h5" id="popover-add-element-title">Choose Element</h3>
                </div>
                <div>
                <button class="block" data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false">
                    <span class="sr-only">Close Popover</span>
                    <Icon name="delete-1" custom-class="icon block icon-size-1"/>
                </button>
                </div>
            </div>
            <div class="tabs-container" id="tabs-container-250206170411">
                <div class="tabs" role="tablist" aria-label="Content sections">
                    <!-- adjust the width of the first column to be just longer than the longest tab when it's active (bold font is a little wider than normal font). The second column should be set to accomodate the main panel content of all the tabs nicely and also fit on most screens. -->
                    <div class="grid" style="grid-template-columns: 15rem 28rem;">
                        <div class="border-ie pad-i-4 pad-b-4">
                            <div class="grid gap-3">
                                <div v-if="inOrbitMode">
                                    <button v-on:click="setActiveTab(tabs.fo)"
                                        role="tab" 
                                        aria-selected="true" 
                                        aria-controls="panel-framedOpenings" 
                                        id="tab-framedOpenings" 
                                        tabindex="0"
                                        class="tab tab-button-2"
                                        :class="{active: activeTab === tabs.fo}">
                                        <span>Framed Openings</span>
                                    </button>
                                </div>
                                <div v-if="inOrbitMode">
                                    <button v-on:click="setActiveTab(tabs.wd)"
                                        role="tab" 
                                        aria-selected="false" 
                                        aria-controls="panel-walkDoors" 
                                        id="tab-walkDoors" 
                                        tabindex="-1"
                                        class="tab tab-button-2"
                                        :class="{active: activeTab === tabs.wd}">
                                        <span>Walk Door</span>
                                    </button>
                                </div>
                                <div v-if="inOrbitMode">
                                    <button v-on:click="setActiveTab(tabs.cd)"
                                        role="tab" 
                                        aria-selected="false" 
                                        aria-controls="panel-commercialDoors" 
                                        id="tab-commercialDoors" 
                                        tabindex="-1"
                                        class="tab tab-button-2"
                                        :class="{active: activeTab === tabs.cd}">
                                        <span>Commercial Door</span>
                                    </button>
                                </div>
                                <div v-if="inOrbitMode">
                                    <button v-on:click="setActiveTab(tabs.ohd)"
                                        role="tab" 
                                        aria-selected="false" 
                                        aria-controls="panel-overheadDoors" 
                                        id="tab-overheadDoors" 
                                        tabindex="-1"
                                        class="tab tab-button-2"
                                        :class="{active: activeTab === tabs.ohd}">
                                        <span>Overhead Door</span>
                                    </button>
                                </div>
                                <div>
                                    <button v-on:click="setActiveTab(tabs.str)"
                                        role="tab" 
                                        aria-selected="false" 
                                        aria-controls="panel-structures" 
                                        id="tab-structures" 
                                        tabindex="-1"
                                        class="tab tab-button-2"
                                        :class="{active: activeTab === tabs.str}">
                                        <span>Structures</span>
                                    </button>
                                </div>
                                <div v-if="inLayoutMode">
                                    <button  v-on:click="setActiveTab(tabs.lt)"
                                        role="tab" 
                                        aria-selected="false" 
                                        aria-controls="panel-miscellaneous" 
                                        id="tab-miscellaneous" 
                                        tabindex="-1"
                                        class="tab tab-button-2"
                                        :class="{active: activeTab === tabs.lt}">
                                        <span>Lean-To</span>
                                    </button>
                                </div>
                                <div v-if="inLayoutMode">
                                    <button  v-on:click="setActiveTab(tabs.pch)"
                                        role="tab" 
                                        aria-selected="false" 
                                        aria-controls="panel-miscellaneous" 
                                        id="tab-miscellaneous" 
                                        tabindex="-1"
                                        class="tab tab-button-2"
                                        :class="{active: activeTab === tabs.pch}">
                                        <span>Porch</span>
                                    </button>
                                </div>
                                <div v-if="inOrbitMode">
                                    <button  v-on:click="setActiveTab(tabs.misc)"
                                        role="tab" 
                                        aria-selected="false" 
                                        aria-controls="panel-miscellaneous" 
                                        id="tab-miscellaneous" 
                                        tabindex="-1"
                                        class="tab tab-button-2"
                                        :class="{active: activeTab === tabs.misc}">
                                        <span>Miscellaneous</span>
                                    </button>
                                </div>
                            </div><!--end grid-->
                        </div>
                    <div>
                        <div class="pad-b-4 pad-i-4" style="block-size: 26rem; overflow-y: auto;">
                            <div v-if="activeTab === tabs.fo && inOrbitMode"
                            role="tabpanel" 
                            id="panel-framedOpenings" 
                            aria-labelledby="tab-framedOpenings"
                            class="tab-panel"
                            :class="{active: activeTab === tabs.fo}">
                                <div class="grid gap-3">
                                    <div>
                                        <div class="pad-4 hover-bg-shaded" style="border-radius: var(--br-3);">
                                            <div class="overlay" style="z-index: 1;">
                                            <button v-on:click="_addWindow(36,60)" class="size-100 no-outline-offset" style="border-radius: var(--br-3);"  data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false"><span class="sr-only">Insert Window</span></button>
                                            </div>
                                            <div class="grid gap-4" style="grid-template-columns: auto 1fr;">
                                            <div>
                                                <Icon name="custom-window" custom-class="icon block icon-size-5 shift-be-5"/>
                                            </div>
                                            <div>
                                                <p class="p strong">Window</p>
                                                <p class="p">Custom-sized <b>picture</b> or <b>double-hung</b> style window.</p>
                                            </div>
                                            </div>
                                        </div>
                                    </div><!-- end panel item -->
                                    <div>
                                        <div class="pad-4 hover-bg-shaded" style="border-radius: var(--br-3);">
                                            <div class="overlay" style="z-index: 1;">
                                            <button v-on:click="_addEmptyFramedOpening(36,60)" class="size-100 no-outline-offset" style="border-radius: var(--br-3);"  data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false"><span class="sr-only">Insert Empty Framed Opening</span></button>
                                            </div>
                                            <div class="grid gap-4" style="grid-template-columns: auto 1fr;">
                                            <div>
                                                <!-- adjust the shift-be- class value (1-10) to align the top of the icon with the top of the text. You'll need to set this per icon. -->
                                                <Icon name="custom-empty-framed-opening" custom-class="icon block icon-size-5 shift-be-6"/>
                                            </div>
                                            <div>
                                                <p class="p strong">Empty Framed Opening</p>
                                                <p class="p">Custom-sized placeholder for windows, second-story doors, and specialty openings.</p>
                                            </div>
                                            </div>
                                        </div>
                                    </div><!-- end panel item -->
                                    <div>
                                        <div class="pad-4 hover-bg-shaded" style="border-radius: var(--br-3);">
                                            <div class="overlay" style="z-index: 1;">
                                            <button v-on:click="_addLouverVent(36)" class="size-100 no-outline-offset" style="border-radius: var(--br-3);"  data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false"><span class="sr-only">Insert Louvered Vent</span></button>
                                            </div>
                                            <div class="grid gap-4" style="grid-template-columns: auto 1fr;">
                                            <div>
                                                <Icon name="custom-louvored-vent" custom-class="icon block icon-size-5 shift-be-3"/>
                                            </div>
                                            <div>
                                                <p class="p strong">Louvered Vent</p>
                                                <p class="p">Custom-sized louvered vent with or without a fan.</p>
                                            </div>
                                            </div>
                                        </div>
                                    </div><!-- end panel item -->
                                </div>
                            </div><!--end panel-->
                
                            <div v-if="activeTab === tabs.wd && inOrbitMode"
                            role="tabpanel" 
                            id="panel-walkDoors" 
                            aria-labelledby="tab-walkDoors"
                            class="tab-panel" 
                            :class="{active: activeTab === tabs.wd}">
                                <div class="grid gap-3">
                                    <div>
                                        <div class="pad-4 hover-bg-shaded" style="border-radius: var(--br-3);">
                                            <div class="overlay" style="z-index: 1;">
                                                <button v-on:click="_addWalkDoor(3)" class="size-100 no-outline-offset" style="border-radius: var(--br-3);"  data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false"><span class="sr-only">Insert Walk Door 3' x 7'</span></button>
                                            </div>
                                            <div class="grid gap-4" style="grid-template-columns: auto 1fr;">
                                            <div>
                                                <Icon name="custom-walk-in-door" custom-class="icon block icon-size-5 shift-be-5"/>
                                            </div>
                                            <div>
                                                <p class="p strong">3' x 7'</p>
                                                <p class="p">Fix-sized walk door with or without a deadbolt.</p>
                                            </div>
                                            </div>
                                        </div>
                                    </div><!-- end panel item -->
                                    <div>
                                        <div class="pad-4 hover-bg-shaded" style="border-radius: var(--br-3);">
                                            <div class="overlay" style="z-index: 1;">
                                            <button v-on:click="_addWalkDoor(4)" class="size-100 no-outline-offset" style="border-radius: var(--br-3);"  data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false"><span class="sr-only">Insert Walk Door 4' x 7'</span></button>
                                            </div>
                                            <div class="grid gap-4" style="grid-template-columns: auto 1fr;">
                                            <div>
                                                <Icon name="custom-walk-in-door" custom-class="icon block icon-size-5 shift-be-5"/>
                                            </div>
                                            <div>
                                                <p class="p strong">4' x 7'</p>
                                                <p class="p">Fix-sized walk door with or without a deadbolt.</p>
                                            </div>
                                            </div>
                                        </div>
                                    </div><!-- end panel item -->
                                    <div>
                                        <div class="pad-4 hover-bg-shaded" style="border-radius: var(--br-3);">
                                            <div class="overlay" style="z-index: 1;">
                                            <button v-on:click="_addWalkDoor(6)" class="size-100 no-outline-offset" style="border-radius: var(--br-3);"  data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false"><span class="sr-only">Insert Walk Door 6' x 7'</span></button>
                                            </div>
                                            <div class="grid gap-4" style="grid-template-columns: auto 1fr;">
                                            <div>
                                                <Icon name="custom-glass-door-double" custom-class="icon block icon-size-5 shift-be-5"/>
                                            </div>
                                            <div>
                                                <p class="p strong">6' x 7'</p>
                                                <p class="p">Fix-sized (double) walk door with or without a deadbolt.</p>
                                            </div>
                                            </div>
                                        </div>
                                    </div><!-- end panel item -->
                                </div>
                            </div><!--end panel-->
                
                            <div v-if="activeTab === tabs.cd && inOrbitMode"
                            role="tabpanel" 
                            id="panel-commercialDoors" 
                            aria-labelledby="tab-commercialDoors"
                            class="tab-panel"
                            :class="{active: activeTab === tabs.cd}">
                                <div class="grid gap-3">
                                    <div>
                                        <div class="pad-4 hover-bg-shaded" style="border-radius: var(--br-3);">
                                            <div class="overlay" style="z-index: 1;">
                                                <button v-on:click="_addCommGlassDoor(3)" class="size-100 no-outline-offset" style="border-radius: var(--br-3);"  data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false"><span class="sr-only">Insert Commercial Door 3' x 7'</span></button>
                                            </div>
                                            <div class="grid gap-4" style="grid-template-columns: auto 1fr;">
                                                <div>
                                                    <Icon name="custom-walk-in-door" custom-class="icon block icon-size-5 shift-be-5"/>
                                                </div>
                                                <div>
                                                    <p class="p strong">3' x 7'</p>
                                                    <p class="p">Fix-sized glass door with or without a deadbolt.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- end panel item -->
                                    <div>
                                        <div class="pad-4 hover-bg-shaded" style="border-radius: var(--br-3);">
                                            <div class="overlay" style="z-index: 1;">
                                                <button v-on:click="_addCommGlassDoor(4)" class="size-100 no-outline-offset" style="border-radius: var(--br-3);"  data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false"><span class="sr-only">Insert Commercial Door 4' x 7'</span></button>
                                            </div>
                                            <div class="grid gap-4" style="grid-template-columns: auto 1fr;">
                                                <div>
                                                    <Icon name="custom-walk-in-door" custom-class="icon block icon-size-5 shift-be-5"/>
                                                </div>
                                                <div>
                                                    <p class="p strong">4' x 7'</p>
                                                    <p class="p">Fix-sized glass door with or without a deadbolt.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- end panel item -->
                                    <div>
                                        <div class="pad-4 hover-bg-shaded" style="border-radius: var(--br-3);">
                                            <div class="overlay" style="z-index: 1;">
                                                <button v-on:click="_addCommGlassDoor(6)" class="size-100 no-outline-offset" style="border-radius: var(--br-3);"  data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false"><span class="sr-only">Insert Commercial Door 6' x 7'</span></button>
                                            </div>
                                            <div class="grid gap-4" style="grid-template-columns: auto 1fr;">
                                                <div>
                                                    <Icon name="custom-glass-door-double" custom-class="icon block icon-size-5 shift-be-5"/>
                                                </div>
                                                <div>
                                                    <p class="p strong">6' x 7'</p>
                                                    <p class="p">Fix-sized (double) glass door with or without a deadbolt.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- end panel item -->
                                </div>
                            </div><!--end panel-->

                            <div v-if="activeTab === tabs.ohd && inOrbitMode"
                            role="tabpanel" 
                            id="panel-overheadDoors" 
                            aria-labelledby="tab-overheadDoors"
                            class="tab-panel"
                            :class="{active: activeTab === tabs.ohd}">
                                <div class="grid gap-3">
                                    <div>
                                        <div class="pad-4 hover-bg-shaded" style="border-radius: var(--br-3);">
                                            <div class="overlay" style="z-index: 1;">
                                                <button v-on:click="_addDoorRollup" class="size-100 no-outline-offset" style="border-radius: var(--br-3);"  data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false"><span class="sr-only">Insert Rollup Door</span></button>
                                            </div>
                                            <div class="grid gap-4" style="grid-template-columns: auto 1fr;">
                                                <div>
                                                    <Icon name="custom-sectional-door" custom-class="icon block icon-size-5 shift-be-5"/>
                                                </div>
                                                <div>
                                                    <p class="p strong">Rollup</p>
                                                    <p class="p">Custom-sized roll-up door.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- end panel item -->
                                    <div>
                                        <div class="pad-4 hover-bg-shaded" style="border-radius: var(--br-3);">
                                            <div class="overlay" style="z-index: 1;">
                                                <button v-on:click="_addDoorSectional" class="size-100 no-outline-offset" style="border-radius: var(--br-3);"  data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false"><span class="sr-only">Insert Sectional Door</span></button>
                                            </div>
                                            <div class="grid gap-4" style="grid-template-columns: auto 1fr;">
                                                <div>
                                                    <Icon name="custom-sectional-door" custom-class="icon block icon-size-5 shift-be-5"/>
                                                </div>
                                                <div>
                                                    <p class="p strong">Sectional</p>
                                                    <p class="p">Custom-sized sectional door with or without light panels.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- end panel item -->
                                    <div>
                                        <div class="pad-4 hover-bg-shaded" style="border-radius: var(--br-3);">
                                            <div class="overlay" style="z-index: 1;">
                                                <button v-on:click="_addSliding" class="size-100 no-outline-offset" style="border-radius: var(--br-3);"  data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false"><span class="sr-only">Insert Sliding Door</span></button>
                                            </div>
                                            <div class="grid gap-4" style="grid-template-columns: auto 1fr;">
                                                <div>
                                                    <Icon name="custom-sliding-door" custom-class="icon block icon-size-5 shift-be-5"/>
                                                </div>
                                                <div>
                                                    <p class="p strong">Sliding</p>
                                                    <p class="p">Custom-sized sliding door with options for left, right, or dual-door configuration.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- end panel item -->
                                    <div>
                                        <div class="pad-4 hover-bg-shaded" style="border-radius: var(--br-3);">
                                            <div class="overlay" style="z-index: 1;">
                                                <button v-on:click="_addHangarDoor" class="size-100 no-outline-offset" style="border-radius: var(--br-3);"  data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false"><span class="sr-only">Insert Hangar Door</span></button>
                                            </div>
                                            <div class="grid gap-4" style="grid-template-columns: auto 1fr;">
                                                <div>
                                                    <Icon name="custom-hangar-door" custom-class="icon block icon-size-5 shift-be-5"/>
                                                </div>
                                                <div>
                                                    <p class="p strong">Hangar</p>
                                                    <p class="p">Custom-sized, end-wall hangar door with options for bi-fold or no-fold configurations.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- end panel item -->
                                </div>
                            </div><!--end panel-->

                            <div v-if="activeTab === tabs.str"
                                role="tabpanel" 
                                id="panel-structures" 
                                aria-labelledby="tab-structures"
                                class="tab-panel"
                                :class="{active: activeTab === tabs.str}">
                                <div class="grid gap-3">
                                    <div>
                                        <div class="pad-4 hover-bg-shaded" style="border-radius: var(--br-3);">
                                            <div class="overlay" style="z-index: 1;">
                                                <button v-on:click="_addStructure" class="size-100 no-outline-offset" style="border-radius: var(--br-3);"  data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false"><span class="sr-only">Insert Main Structure</span></button>
                                            </div>
                                            <div class="grid gap-4" style="grid-template-columns: auto 1fr;">
                                                <div>
                                                    <Icon name="home-3" custom-class="icon block icon-size-5 shift-be-5"/>
                                                </div>
                                                <div>
                                                    <p class="p strong">Main Structure</p>
                                                    <p class="p">Custom-sized building with fully adjustable aesthetic and structural features.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- end panel item -->
                                </div> 
                            </div> <!-- end panel -->
                            <div v-if="activeTab === tabs.lt"
                                role="tabpanel" 
                                id="panel-structures" 
                                aria-labelledby="tab-structures"
                                class="tab-panel"
                                :class="{active: activeTab === tabs.lt}">
                                <div >
                                    <LeanToControls></LeanToControls>
                                </div> 
                            </div> <!-- end panel -->
                            <div v-if="activeTab === tabs.pch"
                                role="tabpanel" 
                                id="panel-structures" 
                                aria-labelledby="tab-structures"
                                class="tab-panel"
                                :class="{active: activeTab === tabs.pch}">
                                <div >
                                    <porchControls></porchControls>
                                </div> 
                            </div> <!-- end panel -->
                            <div v-if="activeTab === tabs.misc"
                            role="tabpanel" 
                            id="panel-structures" 
                            aria-labelledby="tab-structures"
                            class="tab-panel"
                            :class="{active: activeTab === tabs.misc}">
                                <div class="grid gap-3">
                                    <div>
                                        <div class="pad-4 hover-bg-shaded" style="border-radius: var(--br-3);">
                                            <div class="overlay" style="z-index: 1;">
                                                <button v-on:click="_addWideOpening" class="size-100 no-outline-offset" style="border-radius: var(--br-3);"  data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false"><span class="sr-only">Insert Visual Wide Opening</span></button>
                                            </div>
                                            <div class="grid gap-4" style="grid-template-columns: auto 1fr;">
                                                <div>
                                                    <Icon name="custom-wide-opening" custom-class="icon block icon-size-5 shift-be-5"/>
                                                </div>
                                                <div>
                                                    <p class="p strong">Visual Wide Opening</p>
                                                    <p class="p">Custom-sized pseudo component for visual aid and communication purposes only. Does not impact the quote nor should it be used to represent overhead doors.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- end panel item -->
                                    <div>
                                        <div class="pad-4 hover-bg-shaded" style="border-radius: var(--br-3);">
                                            <div class="overlay" style="z-index: 1;">
                                            <button v-on:click="_addCupola" class="size-100 no-outline-offset" style="border-radius: var(--br-3);"  data-popover1-trigger="close" aria-controls="popover-add-element" aria-expanded="false"><span class="sr-only">Insert Cupola</span></button>
                                            </div>
                                            <div class="grid gap-4" style="grid-template-columns: auto 1fr;">
                                            <div>
                                                <Icon name="custom-cupola" custom-class="icon block icon-size-5 shift-be-5"/>
                                            </div>
                                            <div>
                                                <p class="p strong">Cupola</p>
                                                <p class="p">Fix-sized cupola available in sizes 2 ft., 2.5 ft., and 3 ft.</p>
                                            </div>
                                            </div>
                                        </div>
                                    </div><!-- end panel item -->
                                </div> 
                            </div> <!-- end panel -->
                                
                        </div>
                    </div>
                </div>
                
                </div><!-- tabs -->
            </div><!-- tab container -->
            
        </div>
    </div>
</template>

<script>
import {CORE} from '../js/_spec.js'
import LeanToControls from './leanToControls.vue';
import porchControls from './porchControls.vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    data: ()=>{
        return {
            show:{
                windowSizes: false, // 
                framedOpenings: false,
                doorSizes: false,
                porchSides:false,
                doorCommSizes: false,
                doorOverheadTypes: false,
            },
            tabs:{
                fo: "framedOpening",
                wd: "walkDoor",
                cd: "commercialDoor",
                ohd: "overheadDoor",
                str: "structure",
                lt: "leanTo",
                pch: "porch",
                misc: "miscellaneous",
            },
            activeTab: "structure",
            CORE
        }
    },
    computed:{
        ...mapState('designModule', [
            'addingComponent',
            'camera'
            ]),     
        inLayoutMode(){
            return this.camera.mode == 'layout'
        },
        inOrbitMode(){
            return this.camera.mode == 'orbit'
        },
        inFirstMode(){
            return this.camera.mode == 'first'
        }  
    },
    methods:{        
        ...mapActions('designModule',[
                'cancelAddingComponent',        
                'addWindow',
                'addEmptyFramedOpening',
                'addLouverVent',
                'addWalkDoor',
                'addCommercialGlassDoor',
                'addDoorRollup',
                'addDoorSectional',
                'addHangarDoor',
                'addSliding',
                'addWideOpening',
                'addStructure',
                'addCupola',
                'addModel',
                '',
            ]),
        setActiveTab(tab){
            this.activeTab = tab;
        },
        cancel(){            
            this.cancelAddingComponent();
        },
        closePopover(){
            this.$emit('closePopover');
        },
        _addWindow(width,height){
            this.hideArea('all')
            this.addWindow({width,height});      
        },        
        _addEmptyFramedOpening(width, height){
            this.hideArea('all')
            this.addEmptyFramedOpening({width,height});
        },
        _addLouverVent(sideLength){
            this.hideArea('all')
            this.addLouverVent(sideLength);
        },
        _addWalkDoor(w){
            this.hideArea('all')
            this.addWalkDoor(w);      
        },
        _addCommGlassDoor(w) {
            this.hideArea('all')
            this.addCommercialGlassDoor(w);
        },
        _addDoorRollup(){
            this.hideArea('all')
            this.addDoorRollup();
        },
        _addDoorSectional(){
            this.hideArea('all')
            this.addDoorSectional();
        },
        _addHangarDoor(){
            this.hideArea('all')
            this.addHangarDoor();
        },
        _addSliding(){
            this.hideArea('all')
            this.addSliding();
        },
        _addWideOpening(){
            this.hideArea('all')
            this.addWideOpening();
        },
        _addStructure(){
            this.hideArea('all')
            this.addStructure();
        },
        _addCupola(){
            this.hideArea('all')
            this.addCupola();
        },
        _addModel(){
            this.hideArea('all')
            this.addModel();
        },
        toggleArea(mode){
            let oldWindowSizeState = this.show.windowSizes;
            let oldframedOpeningsState = this.show.framedOpenings;
            let oldDoorSizesState = this.show.doorSizes;
            let oldDoorCommSizesState = this.show.doorCommSizes;
            let oldDoorOverheadTypesState = this.show.doorOverheadTypes;
            this.hideArea('all');
            switch(mode){
                case 'windowSizes':
                    this.show.windowSizes=!oldWindowSizeState;                    
                    break; 
                case 'framedOpenings':
                    this.show.framedOpenings=!oldframedOpeningsState;
                    break;
                case 'doorSizes':                    
                    this.show.doorSizes = !oldDoorSizesState;
                    break;                
                case 'doorCommSizes':                    
                    this.show.doorCommSizes = !oldDoorCommSizesState
                    break;
                case 'doorOverheadTypes':
                    this.show.doorOverheadTypes = !oldDoorOverheadTypesState
                    break;
            }
        },
        hideArea(area){
            this.closePopover();
            switch(area){
                case 'windowSizes':
                    this.show.windowSizes=false;
                    break;
                case 'framedOpenings':
                    this.show.framedOpenings=false;
                    break;
                case 'doorSizes':
                    this.show.doorSizes = false;
                    break;
                case 'doorCommSizes':
                    this.show.doorCommSizes = false;
                    break;
                case 'doorOverheadTypes':
                    this.show.doorOverheadTypes = false;
                    break;
                case 'all':
                    this.show.windowSizes=false;
                    this.show.framedOpenings=false;
                    this.show.doorSizes = false;
                    this.show.doorCommSizes = false;
                    this.show.doorOverheadTypes = false;
                    break;

            }
        }        
    },
    components:{
            porchControls,
            LeanToControls
        }
}
</script>

<style scoped>
.btn90{
  width:90%;
}

/* start nitty-gritty-override */

a {
    display:block;
    cursor:pointer;
}

.btn{
  min-height: unset;
  border-color: none;
  margin: 3px 1px;
}


/* end nitty-gritty-override */

</style>