<template>
  <div class="autocomplete" ref="container">
    <input
      type="text"
      v-model="searchTerm"
      @focus="openDropdown"
      @blur="handleBlur"
      @keydown.esc="resetSelection"
      @input="filterOptions"
      class="input"
      ref="input"
      placeholder="Select an option..."
    />
    <ul v-if="isOpen" class="dropdown" :style="{ maxHeight: dropdownMaxHeight + 'px' }">
      <li
        v-for="option in filteredOptions"
        @mousedown.prevent="selectOption(option)"
        :class="{ selected: option.name === selectedOption.name }"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    id:{
      type:String
    },
    options: {
      type: Array,
      required: true
    },
    selectedOption: {
      type: Object
    }
  },
  data() {
    return {
      searchTerm: this.selectedOption?.name || "",
      isOpen: false,
      filteredOptions: this.options,
      dropdownMaxHeight: 200
    };
  },
  watch: {
    selectedOption(newValue) {
      this.searchTerm = newValue.name;
    },
    options(newValue){
      this.filteredOptions = newValue;
    }
  },
  methods: {
    openDropdown() {
      // console.log('filteredOpts length', this.filteredOptions.length)
      this.isOpen = true;
      this.calculateDropdownMaxHeight();
      this.$nextTick(() =>{
        this.$refs.input.select();
        // this.searchTerm = ''
        // this.filterOptions();
      })
    },
    handleBlur() {
      setTimeout(() => {
        this.isOpen = false;
        this.searchTerm = this.selectedOption.name; // Restore selection on blur
      }, 100);
    },
    filterOptions() {
      this.filteredOptions = this.options.filter(option =>
        option.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    selectOption(option) {
      this.searchTerm = option.name;
      this.$emit("change", { id: this.id, val: option.value });
      this.isOpen = false;
      this.$nextTick(() => {
        this.$refs.input.blur(); // Lose focus after selecting an option
      });
    },
    resetSelection() {
      this.searchTerm = this.selectedOption.name;
      this.isOpen = false;
    },
    calculateDropdownMaxHeight() {
      this.$nextTick(() => {
        const inputRect = this.$refs.input.getBoundingClientRect();
        const spaceBelow = window.innerHeight - inputRect.bottom;
        this.dropdownMaxHeight = Math.max(spaceBelow - 10, 100); // Minimum height 100px
      });
    }
  },
};
</script>

<style scoped>
.autocomplete {
  position: relative;
  width: 250px;
}

.input {
  width: 100%;
  padding: 8px;
  /* border: 1px solid #ccc; */
  border-radius: 6px;
  text-align: center;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #eef3f6;
  border: 1px solid #ccc;
  /* border-top: none; */
  border-radius: 10px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
}

.dropdown li {
  padding: 8px;
  cursor: pointer;
  list-style: none;
}

.dropdown li:hover,
.dropdown li.selected {
  background: #1967D2;
  color:white;
}
</style>
