<template>
<div>
  <div class="pad-bs-4 pad-is-5 pad-ie-4"> <!--"pad-bs-4 pad-is-5 pad-ie-4 || pad-is-5 pad-ie-4"-->
    <!-- Component Options Title -->
    <div style="display:flex;align-items: center;justify-content: space-between;margin-bottom:30px">
      <span class='title' style="display:flex;align-items: center;"> 
        <!-- <Icon name="screwdriver-wrench" style="margin-right: 5px;" custom-class="icon icon-size-0 block"/> -->
        <h3 style="font-weight: 700;">{{ title }}</h3></span>
        <button @click='close' class='close'><Icon name="delete-1" custom-class="icon icon-size-1 block"/></button>
    </div> <!--end component options title-->
    <div v-if='shouldShowConstraintEditor'>            
        <ConstraintInput  />

        <hr/>
    </div>
    
    
    <div class="grid gap-4" style="grid-template-columns: 1fr auto; align-items: center;">
      <div class="grid" :class="{'basicInfo': optsContext.compType == CORE.components.structure, 'options': optsContext.compType == CORE.components.options, 'colors': optsContext.compType == CORE.components.appearance}" style=" overflow-y: auto; column-gap: var(--sp-4); row-gap: var(--sp-5); grid-template-columns: 1fr 1fr">							
        <div v-for="(option, index) in dataOptions" v-bind:key="option.key" :class="{'headerPos' : option.type==='header' || option.type === 'checkHeader' || option.type==='color', 'notePos':option.type==='note', 'checkPos': option.type === 'checkbox'}">

          <template v-if="option.type==='header'">
            <h4 class="mar-bs-4" style="font-weight: 700; font-size: var(--fs-3);">{{ option.text }}</h4>
            <span class="tooltip-container" v-on:mouseover="showInfo(option)" v-on:mouseleave="hideInfo(option)" v-if="option.info!==undefined" style="width:fit-content"><Icon style="margin-left: 10px;;" name="attribution" custom-class="icon icon-size-2 subdued" />
              <div id="opt_header"class="info tooltip" :ref="option.key+'info'"><span style="font-weight:bold;text-decoration: underline;">{{option.name}}</span>{{option.info}}</div>
            </span>
          </template>  
          
          <template v-if="option.type==='checkHeader'">
            <SnuiWrapper>
              <div class="checkradio-item" style="font-size: var(--fs-3);">
                <input type="checkbox" :id="option.key" v-model="option.value" @change="change" :disabled="optionDisabled(option.enabled)">
                <label :for="option.key">{{option.text}}</label>
              </div>
            </SnuiWrapper>
          </template> 

          <template v-if="option.type==='checkbox'">
            <SnuiWrapper>
              <div class="checkradio-item" style="font-size: var(--fs-3);">
                <input type="checkbox" :id="option.key" v-model="option.value" @change="change" :disabled="optionDisabled(option.enabled)">
              </div>
            </SnuiWrapper>
          </template> 
          
          <template v-if="option.type!=='note' && option.type!=='header'">              
                <helpTooltip v-if="option.helpText" :helpText="option.helpText" >
                <label class='ib' :for="option.key" :style="option.type === 'checkbox' ? {'font-size': 'var(--fs-3)'} : {}">{{option.name}} </label>
                </helpTooltip>
                <label v-else class='ib' :for="option.key" :style="option.type === 'checkbox' ? {'font-size': 'var(--fs-3)'} : {}">{{option.name}} </label>  
          </template>  

          <template v-if="option.type==='note'">
            <div class="mar-bs-4" >
              <!-- <label class="sr-only"  :for="option.key">Engineering Review Notes</label> -->
              <textarea v-model="option.value" @change="change" :disabled="optionDisabled(option.enabled)" class="size-small"  name="textarea" :id="option.key" rows="5" :placeholder="option.name"></textarea>
            </div>
          </template>

          <template v-if="option.type==='text'">
              <input class="size-small" :id="option.key" type='text' v-model="option.value" @change="change" :disabled="optionDisabled(option.enabled)"/>

          </template>   

          <template v-if="option.type==='nud'">
            <SnuiWrapper>
            <!-- <label for="field_250129084651">Width</label> -->
            <label class="composite-field">
              <input v-model="option.value" @change="change" inputmode="decimal" :min="option.min" :max="option.max" :step="option.increment" :disabled="optionDisabled(option.enabled)" :id="option.key" class="size-small align-end"  type="number" id="field_250129084651" name="input" value="40">
              <div class="text" v-if="option.unit!== undefined && option.unit!==''">{{ option.unit }}</div>
              <div v-if="option.info!== undefined" ><font-awesome-icon icon="fa-duotone fa-circle-info" size="lg" v-on:mouseover="showInfo(option)" v-on:mouseleave="hideInfo(option)" style="color:blue" />
                <div class="info text" :ref="(option.key+'info')"><span style="font-weight:bold;text-decoration: underline;">{{option.name}}</span>: {{option.info}}</div>
              </div>
            </label>
            </SnuiWrapper>
          </template>  

          <template v-if="option.type==='switch'">
            <div>
              <label class="toggle-switch" style="--this-inline-size: 4.6rem;">
                <input @change="change" :disabled="optionDisabled(option.enabled)" type="checkbox" id="toggle-lod" v-model="option.value">
                <span class="text" aria-hidden="true">
                  <span>{{option.valOn}}</span>
                  <span>{{option.valOff}}</span>
                </span>
              </label>
            </div>

          </template>

          <template v-if="option.type==='selection'">
            <SnuiWrapper>
              <label class="composite-field">
                <span class="select">
                  <select class="size-small" name="select" v-model="option.value" :disabled="optionDisabled(option.enabled)" @change="change">
                    <option v-for="so in option.selOptions" :key="so.value" :value="so.value">{{so.text}}</option>
                  </select>
                </span>
                <div class="text" v-if="option.unit!== undefined && option.unit!==''"><span>{{option.unit}}</span></div>
              </label>
            </SnuiWrapper>
          </template>

          <template v-if="option.type==='radio'" >
            <div v-for="so in option.selOptions" :key="so.value">
              <input type="radio" :id="so.value" :name="so.key" :value="so.value" v-model="option.value" :disabled="optionDisabled(option.enabled)" @change="change">
              <label :for="so.value">{{so.text}}</label>
            </div>
          </template>

          <template v-if="option.type==='range'">
            <label :for="option.key" >{{ option.leftLabel }}</label>
            <input type="range" :id="option.key" :name="option.key" v-model="option.value" :disabled="optionDisabled(option.enabled)" :min="option.min" :max="option.max" @input="change">
            <label :for="option.key" >{{ option.rightLabel }}</label>
          </template>  
          
          <div v-if="option.type==='color'">
            <!-- {{option}} -->
            <colorSelect 
              @input="change"
              :openUp=true
              :title=option.name
              :enabled="optionEnabled(option.enabled)"
              :initColor="option.value"
              v-model="option.value"
              :palette="option.selOptions"
              ></colorSelect>
              
            <!-- <label for="field_250109094608">{{ option.name }}</label> -->
            <!-- <label class="composite-field">
              <div class="text">
                <div class="swatch-1" :style="{'background-color': option.value.bang}"></div>
              </div>  
              <span class="select">
                <select class="size-small"  name="select" id="field_250109094608">
                  <option>Brown</option>
                  <option>Burnished Slate</option>
                  <option>Charcoal</option>
                  <option>Colony Green</option>
                  <option selected>Crimson</option>
                  <option>Desert Sand</option>
                  <option>Fern Green</option>
                  <option>Gallery Blue</option>
                  <option>Gulvaluem</option>
                  <option>Gray</option>
                  <option>Hawaiian Blue</option>
                  <option>Hunter Green</option>
                  <option>Lt Stone</option>
                  <option>Rustic Red</option>
                  <option>Tan</option>
                  <option>White</option>
                  <option>Copper Metallic</option>
                  <option>Black</option>
                </select>
              </span>
            </label> -->
          </div>


        </div> <!-- end v-for-->        
        
      </div><!-- grid -->
    </div><!--outer grid-->
    <hr>
    <div style="display:flex; justify-content:space-evenly">
      
      <button v-if="editable && optsContext.showCancel" v-on:click="cancel" class="btn" name='Cancel'>Cancel</button>
      <template v-for="(option) in actionOptions" >                    
          <button v-if="editable" v-on:click="action(option.key)" v-bind:key="option.key" class="btn" :name="option.name">{{option.name}}</button>
      </template>
      
      <button v-on:click="close" class="btn" name='Done'>{{optsContext.closeText}}</button>
      
    </div>
        
  </div><!-- end main div after template-->



</div>
</template>


<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import Dictionary from '../js/helpers/Dictionary.js'
import { ToggleButton } from 'vue-js-toggle-button' //https://www.npmjs.com/package/vue-js-toggle-button
import helpTooltip from './helpTooltip.vue'
import SnuiWrapper from '@/components/SnuiWrapper.vue';
import {CORE} from '../js/_spec.js'
import colorSelect from './colorSelect.vue';
import ConstraintInput from './constraintInput.vue';
let dictionary = new Dictionary();
export default {
  props: {
    context: Object
  },
  computed:{     
    ...mapState('designModule',[
      'editable',
      'show'
    ]),
    ...mapGetters('designModule',[
      'optsContext',
      'showConstraintEditor',
      'editingConstraint',
      'show',
  
    ]),    
    shouldShowConstraintEditor(){
      let showConstraintEditor = this.show.constraintEditor && !this.show.addOptions && 

      // show the constraint editor for all child structure types
      (this.design.type == CORE.components.structure || this.design.type == CORE.components.leanTo || this.design.type == CORE.components.porch);
      return showConstraintEditor;
    },
    title:{
      get: function(){
        if(!this.optsContext)
          return "Options";
        return `${this.optsContext.title}`;
      }
    } ,      
      dataOptions:{
        get: function(){       
          let opts = this.optsContext.options;
          let fopts = opts.filter((o)=>{return o.type!=='button'});

          return fopts;
        }
      },
      actionOptions:{
        get: function(){
          return this.optsContext.options.filter((o)=>{return o.type==='button'});
        }
      },
      design:{
        get: function(){
          return this.optsContext.design;
        }
      }  
  },
  data: ()=>{
      return {
        wrapperStyle:{
          "white-space": 'pre-wrap'
        } ,  
        color:         '#A463BF',
        previousFrameType: null,
        previousGalvBeams: null,
        previousGalvPurlins: null,
        previousStructureId: null,
        CORE
      };
  },
  created: function(){
  
  },
  mounted: function(){

  },
  methods: {
...mapActions('designModule',[
        'promptDeleteComponent',        
        'copyComponent',
        'centerComponent',
      ]),
    showInfo:function(option){
      let key = option.key+'info';
      this.$refs[key][0].style.display = 'block'; // this ref index is an array because the ref is in a v-for
    },
    hideInfo:function(option){
      let key = option.key+'info';
      this.$refs[key][0].style.display = 'none'; // this ref index is an array because the ref is in a v-for
    },
    optionEnabled:function(optEnabled){      
      return this.editable && optEnabled;
    },
    optionDisabled:function(optEnabled){      
      return !this.optionEnabled(optEnabled);
    },
    cancel:function(){
      if(this.optsContext.onCancel)
        this.optsContext.onCancel();
    },
    close: function(){
      if(this.optsContext.onClose)
        this.optsContext.onClose();
      //else
      //this.$store.commit('hideOptionsPanel');
    },
    change: function(){
      if(this.optsContext.onChange){
        console.log('change call')
        this.optsContext.onChange({
          componentId: this.design.id,
          options: this.dataOptions
          });
      }
    },
    action: function(key){
      switch(key){         
        case 'delete':
          this.promptDeleteComponent(this.design.id);
          break;
        case 'copy':
          this.copyComponent(this.design.id);
          break;
        case 'center':
          this.centerComponent(this.design.id);
          break;
      }
    },
  },
  components:{
    ToggleButton,
    helpTooltip,
    SnuiWrapper,
    colorSelect,
    ConstraintInput
  }
}
</script> 

<style scoped>

.info{
  border: 1px solid black;
  padding: 5px;
  border-radius: 4px;
  width: 250px;
  display: none;
  background: gray;
  color: white;
  box-shadow: 0px 0px 8px -1px black;
  text-wrap: wrap;
  
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  /* left: 50%; */
  bottom: 100%;
  /* transform: translateY(-50%); */

  z-index: 1000;
}

.headerPos, .notePos{
  grid-column-start: 1;
  grid-column: span 2;

  align-items: center;
}

.checkPos{
  grid-column-start: 2;
  display: flex;
}

</style>
