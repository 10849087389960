import {CORE} from '../constants.js'
import api from '@/api';
import { assert } from 'chai';
export default class ContextLogic{


    // static getBusinessNameByType(entityType){
    //     if(entityType == CORE.entities.enterprise)
    //         return 'entity'
    //     else if(entityType == CORE.entities.supplier)
    //         return 'company'
    //     else
    //         return 'contractor'
    // }

    // static getMode(){
    //     let currentContext = JSON.parse(localStorage.getItem('currentContext'))
    //     if(currentContext.contractor !== -1)
    //         return CORE.modes.contractor;
    //     else if(currentContext.company !== -1)
    //         return CORE.modes.supplier;
    //     else
    //         return CORE.modes.system;
    // }

    static routeToUserDefaultPage(store, entityType, businessId, router){
        if(entityType === CORE.entities.enterprise){
            console.log("routeToUserDefaultPage: already at default page")
        }
        else if(entityType === CORE.entities.supplier){
            console.log("routeToUserDefaultPage: route to supplier projects")
            let sRoute = `/supplier/${businessId}/projects`
            if(router.currentRoute.path != sRoute)
                router.push(sRoute);
        }
        else if(entityType === CORE.entities.contractor){
            console.log("routeToUserDefaultPage: route to contractor projects")
            let cRoute = `/contractor/${businessId}/projects`;
            if(router.currentRoute.path != cRoute)
                router.push(cRoute);  
        }
    }

    static async getDefaultMode(vue){
        let {$store, $auth, $router, $route} = vue;
        if(!$auth)
            return;
        if(!$auth.user)
            return;
        let loggedInUser = $auth.user;
        if(!loggedInUser || !loggedInUser.userRoles)
            return CORE.modes.contractor;

        if(loggedInUser.userRoles.includes('SysAdmin'))
            return CORE.modes.system;
        else{            
            //let currentUserJson = sessionStorage.getItem('user');
            //if(!currentUserJson)
                //return;

            //let currentUser = JSON.parse(currentUserJson);
            //let entity = await $store.dispatch('businessModule/fetchBusiness', currentUser.entityId);
            let entity = $store.state.contextModule.userBusiness;
            
            if(entity.type == CORE.entities.supplier){
                $store.commit('contextModule/setCurrentSupplier', entity)
            }
            else
            if(entity.type == CORE.entities.contractor){
                $store.commit('contextModule/setCurrentContractor', entity)
                let entityRep = await $store.dispatch('userModule/fetchUser', entity.repUserId)
                $store.commit('contextModule/setContractorParentData', {company: entity, rep: entityRep})
            }
            
            this.routeToUserDefaultPage($store, entity.type, entity.id, $router);
            return entity.type
        }
    }

    static async initUser(vue,requireAuth){
        let {$store, $auth, $router, $route} = vue;
        let user;
        // check the cache
        let sUser = sessionStorage.getItem('user');
        
        if(sUser){// if there, 
            user = JSON.parse(sUser); // recall the user value rather than fetching
            // raise an error log if the authentication and the recalled user align
            //assert.include($auth.user.sub, user.sub);            
            console.log('recall', $auth, user);
            
        }
        else
        {
            console.log("initUser: fetching user");
            // if the user is authenticated
            if($auth.user && $auth.user.sub)
            {                
                console.log("initUser: user authenticated");
                let sub = $auth.user.sub.substring($auth.user.sub.indexOf('|') + 1);
                user = await $store.dispatch('userModule/fetchMyUser', sub);  //fetch the user details

                if(user)
                    console.log("initUser: user found")
            }
            //else
                //user = null; // unauthenticated page
            if(requireAuth)
                assert.exists(user); // the user must be known here
        }
        
        // set state
        if(user){
            $store.commit('contextModule/setUser', user);
            console.log("initUser: user set")
        }
    }

    
    static async initUserBusiness(vue,requireAuth){
        let {$store, $auth, $router, $route} = vue;
        let userBusiness;
        // check the cache
        let suserBusiness = sessionStorage.getItem('userBusiness');        
        
        if(suserBusiness){ // if there, 
            userBusiness = JSON.parse(suserBusiness); // recall the user entity rather than fetching
            console.log('initUserBusiness: recall', $auth, userBusiness);
        }
        else
        {
            console.log('initUserBusiness: fetching user business');

             //fetch it             
             let user = $store.state.contextModule.user;
             if(requireAuth)
                assert.exists(user) // the user must be known here
             if(user && user.businessId){
                userBusiness = await $store.dispatch('businessModule/fetchBusiness', user.businessId); // fetch the entity details

             if(userBusiness)
                console.log("initUserBusiness: userBusiness found") 
            }
             //else
                //userBusiness = null; // unauthenticated page
            if(requireAuth)
            assert.exists(userBusiness); // the user entity must be known here
        }
        
        if(userBusiness){
            $store.commit('contextModule/setuserBusiness', userBusiness); // set state 
            console.log('initUserBusiness: user business set');
        }
    }

    static async initToken($auth){
        // recall the token
        let sToken = localStorage.getItem('token');
        if(!sToken && $auth.auth0Client)
        {
            console.log("initToken: no previous token")
            // attempt to fetch the token silently, based on the auth cookie
            let token = await $auth.getTokenSilently();
            localStorage.setItem('token', token);
        }
        // getTokenSilently may not succeed, so don't assert the token here
    }

    static async initMode(vue){
        let {$store, $auth, $router, $route} = vue;
        let mode;
        // check the cache
        let sMode = localStorage.getItem('mode');       
        
        if(sMode && sMode!="null") // if there (excluding "null"), 
            mode = JSON.parse(sMode); // recall the mode value (null is not valid)
        else
            mode = await this.getDefaultMode(vue); // calculate it from userBusiness            

        assert.exists(mode); // there must be a mode here
        
        $store.commit('contextModule/setMode', mode); // set state 

        this.updateStateForMode($store, $route);
    }

    
    static async initSupplier(vue){
        let {$store, $auth, $router, $route} = vue;
        let supplier;
        // check the cache
        let sSupplier = localStorage.getItem('supplier');
        
        if(sSupplier) // if truthy (including "null"), 
            supplier = JSON.parse(sSupplier); // recall the company value (null is valid)
        else
            supplier = this.calcDefaultSupplier($store.state.contextModule.userBusiness); // calculate it

        $store.commit('contextModule/setCurrentSupplier', supplier); // set state 
    }

    
    static async initContractor(vue){
        let {$store, $auth, $router, $route} = vue;
        // check the cache
        let sContractor = localStorage.getItem('contractor');
        let contractor;
        
        if(sContractor) // if there (including "null"),
            contractor = JSON.parse(sContractor); // recall the contractor value (null is valid)
        else
            contractor = this.calcDefaultContractor($store.state.contextModule.userBusiness); // calculate it
        
        $store.commit('contextModule/setCurrentContractor', contractor); // set state         

        if(contractor && contractor.repUserId)
        {
            let entityRep = await $store.dispatch('userModule/fetchUser', contractor.repUserId)
            $store.commit('contextModule/setContractorParentData', {company: contractor, rep: entityRep})
        }
    }

    static async calcDefaultSupplier(userBusiness){
        if(userBusiness.type !== CORE.entities.supplier)
            return null;        
        return userBusiness;
    }
    
    static async calcDefaultContractor(userBusiness){
        if(userBusiness.type !== CORE.entities.contractor)
            return;
        return userBusiness;
    }

    static clearBusinessContext($store){
         // otherwise, the mode must be system
        $store.commit('contextModule/setMode', CORE.modes.system); // apply to state
    }

    static async calcModeFromRoute(vue){
        let {$store, $auth, $router, $route} = vue;
        let mode = $store.state.contextModule.current.mode; // default to the existing mode state
        
        // trust the path
        
        if($route.name == "entityManager")
        {
            if($route.path.includes('contractors')) // contractors path is used only for company mode
            mode = CORE.modes.supplier;
            else if($route.path.includes("list")) //  entity list path is used only for sysAdmin mode
            mode = CORE.modes.system
        }
        else //if($route.name == 'dashboard' || $route.name == 'entityDashboard')
        {
            if($route.path.includes('supplier')) // company path is used only for company mode
                mode= CORE.modes.supplier;
            else if($route.path.includes('contractor')) // contractor path is used only for contractor mode
                mode= CORE.modes.contractor;
            // else
            // commented because simply navigating to projects should not put the system in admin mode
                // mode= CORE.modes.system; // otherwise, the mode must be system
        }
        $store.commit('contextModule/setMode', mode); // apply to state

        await this.updateStateForMode($store, $route);
    }

    static async initContext(vue, requireAuth=true) {
        /*
        Three Use Cases
         1) new login (existingSession = false && context not loaded)
         2) page navigation (existingSession = true && state loaded)
         3) hard refresh (existingSession = true && context not loaded)         
        */     
        let {$store, $auth, $router, $route} = vue;

        this.initToken($auth);

        // use the session storage to determine our use case (1, 2, or 3)
        let existingSession = 0; // assume the session does not exist
        let sExistingSession = sessionStorage.getItem('contextLoaded'); // get the cached value for contextLoaded        
        if(sExistingSession) // if something was stored
            existingSession = JSON.parse(sExistingSession); // parse the value


        let routeToDefaultPage=false;
        // if state says context is loaded, 
        if($store.state.contextModule.loaded){
            // there is an existing session, and this is use case 2) page navigation
            await this.calcModeFromRoute(vue);
            console.log("initContext: context is loaded, existing session")
        }
        else
        {
            console.log("initContext: context is not loaded, no existing sessions")

            // state doesn't show context as loaded, so this is use case 1) new login or 3) hard refresh
            // initialize the context data
            await this.initUser(vue,requireAuth);
            await this.initUserBusiness(vue,requireAuth);
            // existing session implies 3) hard refresh
            await this.initMode(vue); // recalled or calculated (cannot be null)
            await this.initSupplier(vue); // recalled or calculated from userBusiness (can be null)
            await this.initContractor(vue); // recalled or calculated from userBusiness (can be null)

            let user = $store.state.contextModule.user;
            if(requireAuth)
            {
                assert.exists(user);
                // if user is null because initContext was called by an anonymous page
                
            }

            // cache that the context has been loaded
            sessionStorage.setItem('contextLoaded',1);
            // update app state with context loaded
            $store.state.contextModule.loaded=true;

            let userBusiness = $store.state.contextModule.userBusiness;
            if(requireAuth)
            {
                assert.exists(userBusiness);
                this.routeToUserDefaultPage($store, userBusiness.type, userBusiness.id, $router);
            }

            console.log("initContext: context has been loaded")
        }
        
        console.log('initContext done');
    }

    static async updateStateForMode($store, $route){
        
        if($route.path.search("/project/")==0)
        {
            // project routing shouldn't adjust the user's context!
            return;
        }
        // this is for business pages
        let routeBusinessId = $route.params["id"];

        switch($store.state.contextModule.current.mode){
            case CORE.modes.system:
                $store.commit('contextModule/clearCurrentSupplier');
                $store.commit('contextModule/clearCurrentContractor');
            break;
            case CORE.modes.supplier:
                await this.setCurrentSupplier($store, routeBusinessId);
                $store.commit('contextModule/clearCurrentContractor');
                break;
            case CORE.modes.contractor:
                await this.setCurrentContractorAndParentSupplier($store, routeBusinessId);            
                break;        
        }
    }

    static async setCurrentSupplier($store, id){
        if(typeof id === 'undefined')
            return;
        // if this information is already known, short-circuit
        if($store.state.contextModule.current.supplier && $store.state.contextModule.current.supplier.id == id)
            return;
        // fetch the info
        let entityInfo = await api.getBusiness(id)
        // add to state
        $store.commit('contextModule/setCurrentSupplier', entityInfo);
    }

    static async setCurrentContractor($store, id){

    }

    static async setCurrentContractorAndParentSupplier($store, id){
        if(typeof id === 'undefined')
            return;
        // if this information is already known, short-circuit
        if($store.state.contextModule.current.contractor && $store.state.contextModule.current.contractor.id == id)            
            return;
        // fetch the info
        let contInfo = await api.getBusiness(id)
        // add to state
        $store.commit('contextModule/setCurrentContractor', contInfo);
        // fetch the company rep info
        let compRep = await $store.dispatch('userModule/fetchUser', contInfo.repUserId)
        // update the company too, if necessary        
        this.setCurrentSupplier($store, compRep.businessId)

    }

    static logout(){
        localStorage.removeItem('token');
        localStorage.removeItem('mode');
        localStorage.removeItem('supplier');
        localStorage.removeItem('contractor');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('userBusiness');
        sessionStorage.removeItem('contextLoaded');
    }

 
}