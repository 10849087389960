<template>
    <div :id="containerId" :style="customStyle">
        <StatusIndicator v-if="si" ref="status" :successText="'Saved'"></StatusIndicator>
        <span class="select">
            
            <template v-if="compStart || compEnd">                
                <label class="composite-field" >
                    <div class="text" v-if="compStart" >{{compStart}}</div>
                    
                    <select @change="change" :value="val" :disabled="disable">
                        
                        <template v-for="option in options">
                            <option :key="option.value" :value="option.value" :name="option.name">{{option.name}}</option>
                        </template>            
                    </select>
                    <div class="text" v-if="compEnd" >{{compEnd}}</div>
                </label>
            </template>
            <template v-if="!compStart && !compEnd">                
                <select @change="change" :value="val" :disabled="disable">
                    <template v-for="option in options">
                        <option :id="option.key ? option.key : option.value" :key="option.key ? option.key : option.value" :value="option.value" :name="option.name">{{option.name}}</option>
                    </template>            
                </select>                
            </template>
        </span>
    </div>
</template>

<script>
import StatusIndicator from './StatusIndicator.vue';

    export default {
        name:'user-setting-dropdown',
        props: ['id', 'name', 'val', 'options', 'customStyle', 'disable', 'si', 'compStart', 'compEnd'],
        data: function () {
            return {
            }
        },
        computed: {
            containerId: function () {
                return 'setting_' + this.id;
            }
        },
        watch: {

        },
        mounted: function () {
        },
        updated: function () {

        },
        methods: {
            change: function (e) {
                this.$emit('change', { id: this.id, val: e.target.value })
            },
            loading: function () {
                this.$refs.status.loading();
            },
            success: function () {
                this.$refs.status.success();
            },
            fail: function () {
                this.$refs.status.fail();
            }
        },
        components:{
            StatusIndicator
        }
    }
</script>
<style scoped>
</style>