
//wallBase.js (renamed from wall.js)

import * as THREE from 'three';
import { rebuildTypes, CORE, impactTypes } from '../_spec';
import {  Quaternion, Vector3 } from 'three';
import { OBB } from 'three/examples/jsm/math/OBB.js'; // https://threejs.org/examples/?q=bounding#webgl_math_obb
import CollisionHelper from '../helpers/CollisionHelper';
import ComponentHelper from '../helpers/featureHelper.js'
import Base from './Base.js';
import OptionHelper from '../helpers/optionHelper.js'
import ShapeHelper from '../helpers/shapeHelper.js'
import vHelper from '../helpers/vHelper.js'
import MemHelper from '../helpers/memHelper.js'
import Util from '../utility.js'
import BlueprintHelper from '../helpers/blueprintHelper.js'
import PickHelper from '../helpers/PickHelper.js'
import TreeNode from '../helpers/TreeNode.js'

import _3dFooter from '../3d/Footer.js'
import _3dTrimWallBottom from '../3d/TrimWallBottom.js'
import _3dGirtHoriC from '../3d/GirtHoriC.js'
import _3dColumnC  from '../3d/ColumnC.js'
import _3dDripTrim  from '../3d/DripTrim.js'

import layerHelper from '../helpers/layerHelper.js';
import SegmentHelper from '../helpers/SegmentHelper';
import materialHelper from '../helpers/materialHelper';


import { faTreeChristmas } from '@fortawesome/pro-duotone-svg-icons';
import Tri from '../3d/Tri';
import EarcutDataManager from '../helpers/EarcutDataManager';
import SheetingHelper from '../helpers/SheetingHelper';
import Sheeting from '../3d/Sheeting';
import BaySideFront from './BaySideFront.js';
import DesignHelper from '../helpers/DesignHelper.js';

export default class Wall_Base extends Base {
    constructor(
        masterDesign,
        design,
        structureConfig, 
        length,
        maxHeight,        
        facePosZ, 
        trimMaterials, 
        cbDesignChange, 
        supportsWainscot, 
        footerCollisions, 
        addToQuoteLayer=true, 
        allowDripTrim=false, 
        insulation,
        pitchRatio,
        roofType,
        beamColor, 
        girtColor,
        options
         ) {

        // facePosZ controls how the wall is built for the sake of anchoring openings to the wall origin
        
        super(design, undefined);
        this.structureConfig =structureConfig;
        this.length = length;
        this.masterDesign = masterDesign;
        this.maxHeight = maxHeight;
        this.pitchRatio = pitchRatio; 
        this.pitchRadians = BlueprintHelper.pitchRatioToRadians(this.pitchRatio);
        this.roofType = roofType;
        
        this.pitchHeight = BlueprintHelper.pitchHeight(this.length, this.pitchRatio, this.roofType);;
        this.ridgeHeight = this.maxHeight + this.pitchHeight;
        this.design.height = this.design.height? this.design.height : this.maxHeight;
        this.supportsWainscot = supportsWainscot?supportsWainscot:false;
        this.wallThickness = .1;
        this.buildFacingPositiveZ = facePosZ;
        this.position = new THREE.Vector3(0,0,0);
        this.rotationY = 0;
        this.footerCollisions = footerCollisions?footerCollisions:{enabled:true};
        this.addToQuoteLayer = addToQuoteLayer;
        this.allowDripTrim = allowDripTrim;
        this.insulation = insulation;        
        
        
        this.beamColor = beamColor;
        this.girtColor = girtColor;
        this.options = options;
        // we instantly swap in a new group 
        this.group.name = "Component group for " + this.getDescription();        // component group

        let columnGroup = new THREE.Group();
        columnGroup.name = "columns";
        this.group.add(columnGroup);

        this.trimMaterials = trimMaterials;

        this.componentsNotOnWall=[];
        
        this.cbDesignChange = cbDesignChange;
        // this extrudes in the positive z when the wall is built facing the positive z
        this.extrusionSettings = {
            depth: this.wallThickness, //used to be amount
            bevelEnabled: false,
            bevelThickness: 0.1,
            bevelSize: 1,
            bevelSegments: 1,
            material: 0,
            extrudeMaterial: 1
        };

        
        //this.localMerge(design,this.defaultDesign()); // fixes it
        Util.merge(design,this.defaultDesign(),  false);
        
        // this is a dynamic default for a full-height wall        
        // Scenario: full height wall
        // when building height is changed, we want openHeightOffset to be set to building height.
        // then when we update openHeight, it will be zero-ish so a full wall is drawn.
        // building will need to adjust wall designs

        // Scenario: mostly open wall
        // when building height is changed, we want openHeightOffset to remain where it is
        // then when we update openHeight, it will be relative to the building height
        // building will need to adjust wall designs
        
        if(this.design.wainscot.enabled===undefined)
            this.design.wainscot.enabled = true; 

        this.shape = new THREE.Shape();       
    }

    
    convertWallHoleToBayHoles(wallOpenings, start, end){
        
        let bayHoles = [];
        let bayLength = end-start;


        wallOpenings.forEach((wallHole)=>{
            let wallHoleLeft = wallHole.pos.x - wallHole.dim.width/2;
            let wallHoleRight = wallHole.pos.x + wallHole.dim.width/2;
            let holeTop = wallHole.pos.y + wallHole.dim.height/2;

            // has no overlap with this bay
            if(wallHoleLeft >= end || wallHoleRight < start){

                return;
            }
            // starting and not ending
            else if(wallHoleLeft > start && wallHoleLeft < end &&  wallHoleRight > end)
            {
                // the hole starts in this bay
                let bayHoleLeft = wallHoleLeft - start; // translate the wallLeft into bayLeft
                let bayHoleRight =  end; // we know the hole goes beyond this bay
                let bayHoleDimWidth = bayHoleRight-start - bayHoleLeft; 
                let bayHolePosX = bayHoleLeft + bayHoleDimWidth/2;                

                let bayHole = {
                    pos:{
                        x: bayHolePosX,
                        y: wallHole.pos.y
                    },
                    dim:{
                        width: bayHoleDimWidth,
                        height: wallHole.dim.height
                    }
                }
                bayHoles.push(bayHole)
            }
            // fully contained
            else if(wallHoleLeft <= start && wallHoleRight >= end)
            {
                let bayHoleDimWidth = bayLength;
                let bayHolePosX = bayLength/2;                

                let bayHole = {
                    pos:{
                        x: bayHolePosX,
                        y:wallHole.pos.y
                    },
                    dim:{
                        width: bayHoleDimWidth,
                        height: wallHole.dim.height
                    }
                }
                bayHoles.push(bayHole)
            }
            // ending and not starting
            else if (wallHoleRight > start && wallHoleRight < end && wallHoleLeft < start)
            {
                
                let bayHoleLeft = Math.max(wallHoleLeft, start);                
                let bayHoleDimWidth = wallHoleRight-bayHoleLeft;
                let bayHolePosX = bayHoleDimWidth/2;                

                let bayHole = {
                    pos:{
                        x: bayHolePosX,
                        y:wallHole.pos.y
                    },
                    dim:{
                        width: bayHoleDimWidth,
                        height: wallHole.dim.height
                    }
                }
                bayHoles.push(bayHole)
            }
            else{
                // starts and ends in the same bay, 
                let bayHoleLeft = wallHoleLeft - start
                let bayHoleRight = wallHoleRight - start
                let bayHoleDimWidth = bayHoleRight-bayHoleLeft;
                let bayHolePosX = bayHoleLeft + bayHoleDimWidth/2;                

                let bayHole = {
                    pos:{
                        x: bayHolePosX,
                        y:wallHole.pos.y
                    },
                    dim:{
                        width: bayHoleDimWidth,
                        height: wallHole.dim.height
                    }
                }
                bayHoles.push(bayHole)
            }
        })
        return bayHoles;
    }

    getDescription(){
        return "wall_base";
    }
    
    centerComponent(design){
        // assumes all component designs that can be centered use design.pos
        design.pos.x = this.length/2;
    }
    
    initRebuildHierarchy(){        
        this.rebuildTypeRoot = new TreeNode(null, rebuildTypes.full);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.dynamicOnly);
    }

    migrate(design){
        
    }

    migrateTo2(design){

        switch(design.v){
            case 1:
                design.components.forEach((c)=>{
                    if(c.type === CORE.components.doorWalkin3o7o || 
                        c.type === CORE.components.doorWalkin4o7o || 
                        c.type === CORE.components.doorWalkin6o7o)
                        c.pos.y = c.dim.height/2;
                 });
                break;
        }        
    }

    
    detectImpact(impact){
        // if this impact affects my design, 
        //  update my design 
        //  generate an impact.
        // set rebuildType
        // 

        //TODO: optimize all detectImpacts handlers if they're getting called alot
        // if a window changes, this is called.
        // if a wall option is changed, this is called.

        // see if a child component was impacted
        let myChild = this.getChildComponentById(impact.id);
        if(myChild)
            this.addRebuildNeeded(rebuildTypes.dynamicOnly);
        
        switch(impact.change.name){
            case impactTypes.openingPosition:
            case impactTypes.openingResize:
                if(this.design.type === CORE.components.fsw || this.design.type === CORE.components.bsw)
                    return;

                let found = false;
                // search the wall child components
                this.design.components.forEach((wallChildCompDes)=>{
                    if(found)
                        return;
                    // search the bay child components
                    wallChildCompDes.components.forEach((bayChildCompDesign) => {
                        if(!found && bayChildCompDesign.id === impact.id)
                         {
                            
                            this.addRebuildNeeded(rebuildTypes.full);
                            found=true;
                        }
                    })
                })
                break;
            case impactTypes.deleteOpening:
                if (myChild && myChild.design.type == CORE.components.doorHangar) {
                    let supportMargin = 2;
                    let leftX = myChild.design.pos.x - myChild.design.dim.width/2
                    let rightX = myChild.design.pos.x + myChild.design.dim.width/2
                    this.design.components.forEach((wallChildCompDes) => {
                        // reset open height of the bays obove the hangar 
                        if (wallChildCompDes.index && this.design.columnPositions[wallChildCompDes.index].pos >= leftX - supportMargin && this.design.columnPositions[wallChildCompDes.index].pos <= rightX)
                            if (wallChildCompDes.openHeight && wallChildCompDes.openHeight > 0) {
                                wallChildCompDes.height = this.maxHeight;
                                wallChildCompDes.openHeight = 0;
                            }
                    })
                }
                break;
            case impactTypes.wallOpen:
            case impactTypes.wallHeight:
            case impactTypes.wainscot:
            case impactTypes.wallPosition:
            case impactTypes.wallColumnOption: // this particular type is endWall specific so we should actually use some polymorphism here
                this.addRebuildNeeded(rebuildTypes.full);
                break;                
        }
    }
    
    processRebuildsInternal_Children(){
        let rebuildWallOpenings = !this.rebuildsNeededIncludes(rebuildTypes.full, true);

        if(rebuildWallOpenings) 
        {
            if(this.components.length>0){
                //console.group();
                this.components.forEach((comp) => {            
                    comp.processRebuildsInternal();
                });
                //console.groupEnd();
            }
        }
    }

    

    processRebuild(type){  
        switch(type){
            case rebuildTypes.full:
                this.rebuildFull();
                break;
            case rebuildTypes.dynamicOnly:
                this.rebuildDynamicOnly();
                break;
        }
    }

    rebuildFull(){
        this.remove();
        this.components = [];
        this.build();
    }

    rebuildDynamicOnly(){
        this.removeDynamicOnly();
        this.buildDynamicOnly()
    }

    isInverted(){
        return !this.buildFacingPositiveZ;
    }
    
    gridSnapWorldPosition(world){
        // snaps a point in the proper plane
        let w = new Vector3().copy(world);
        this.group.worldToLocal(w);                
        let localSnapped = PickHelper.getGridSnappedPosition(w, true, false); // all walls are in the XY plane in local space
        this.group.localToWorld(localSnapped);
        return localSnapped;
    }

    getRelativePosition(worldPosition){
        worldPosition = new Vector3().copy(worldPosition);    
        let relWorldPos = this.group.worldToLocal(worldPosition);
        return relWorldPos;
    }

    
    getChildWorldPosition(childCompId){
        //let worldPosition = this.group.localToWorld(new Vector3(0,0,0))
        //console.log(worldPosition);

        let childComp = this.getComponentById(childCompId);
        if(!childComp) throw 'Error: getChildWorldPosition()'
        
            

        let componentSceneWorldPos = new Vector3();
        this.group.getWorldPosition(componentSceneWorldPos)

        let componentSceneWorldQuat = new Quaternion();
        this.group.getWorldQuaternion(componentSceneWorldQuat);
        
        let desPos = childComp.design.pos;
        // desPos is principally x,y, but z can, technically, be zero or non-zerp
        
        let relPos = new Vector3().copy(desPos);
        relPos.applyQuaternion(componentSceneWorldQuat);
        relPos = new Vector3().addVectors(relPos,componentSceneWorldPos);


        //console.log('wb rel > world', desPos, relPos )
        return relPos;        
    }

    getWorldPositionFromRelative(rel){
        let componentSceneWorldPos = new Vector3();
        this.group.getWorldPosition(componentSceneWorldPos)

        let componentSceneWorldQuat = new Quaternion();
        this.group.getWorldQuaternion(componentSceneWorldQuat);
                        
        let relPos = new Vector3().copy(rel);
        //componentSceneWorldQuat.inverse(); // unsure about this.
        relPos.applyQuaternion(componentSceneWorldQuat);
        relPos = new Vector3().addVectors(relPos,componentSceneWorldPos);
        return relPos;
    }
    
    localMerge(a,b){
        for (var key in b) {
            // if this is specific to b
            if(b.hasOwnProperty(key)){
                
                // if this is not already in a
                if(a[key] === undefined || a[key] === null){
                    a[key] = b[key]
                }
            }
            
        }     
    }

    getColumnsGroup() {
        return this.group.children.find(c => c.name == "columns");
    }

    

    getIntrabaySubcomponentColumns(oldColumnPositions){
        // openings that only affect a single bay (these are grand-children components)
        let columnPoints = [];
        let supportMargin = 2;
        let overheads = this.design.getAllRollupDoors(true);
        //overheads.push(...this.design.getAllWideOpenings());
        overheads.push(...this.design.getAllSlidingDoors(true));
        overheads.push(...this.design.getAllSectionalDoors(true));
        //overheads.push(...this.design.getAllHangarDoors());

        

        let oldDefaultColumns = this.getDefaultColumnsFromArray(oldColumnPositions);

        overheads.forEach((o)=>{
            
            let leftColumnPosX = (o.pos.x + o.dim.width/2)  + supportMargin;
            
            let bayParentId = o.parent.id;
            let bayDesign = this.design.getChildDesign(bayParentId)
            let index = bayDesign.index;

            let start = this.getStartOfBayAtIndex(oldDefaultColumns, index)
            let end = this.getEndOfBayAtIndex(oldDefaultColumns, index)
            
            //let start = oldDefaultColumns[index];
            //let end = oldDefaultColumns[index+1];



            columnPoints.push({
                required: true,
                source: CORE.components.doorRollup,   // we're including type (or purpose) information so that we can remove a redundant column serving an unnecessary purpose.
                side: 'left',
                pos: leftColumnPosX + start.pos
            });

            let rightColumnPosX = (o.pos.x - o.dim.width/2) - supportMargin;

            columnPoints.push({
                required: true,
                source: CORE.components.doorRollup,
                side: 'right',
                pos: rightColumnPosX + start.pos
            });
        });
        
        return columnPoints;
    }

    addWindowSupport(wDesign, girtHeights){
        let supportMargin = 2;
        let leftColumnPosX;
        let rightColumnPosX;
        
        let midWindowX;

        if(!this.buildFacingPositiveZ) {
            midWindowX = (wDesign.pos.x - wDesign.dim.width/2) + wDesign.dim.width/2;
            leftColumnPosX = (wDesign.pos.x + wDesign.dim.width/2)  + supportMargin;
            rightColumnPosX = (wDesign.pos.x - wDesign.dim.width/2) - supportMargin;
            
        }
        else{

            midWindowX = (wDesign.pos.x - wDesign.dim.width/2)+ wDesign.dim.width/2;
            leftColumnPosX = (wDesign.pos.x - wDesign.dim.width/2)  - supportMargin;
            rightColumnPosX = (wDesign.pos.x + wDesign.dim.width/2) + supportMargin;
            
        }
        


        let z = 0;
        let standardFrameLineDimensions = BlueprintHelper.getStandardFrameMaterialSize()
        if(this.buildFacingPositiveZ)
            z -= 1+standardFrameLineDimensions.depth/2;
        else
            z += 1+standardFrameLineDimensions.depth/2;

        // calculate top of window supports as next highest girt or top
        // let topPosY = this.shapePoints[1].y;  // we used to assume we're taking the support all the way to the top left or top right corner height
        // this is the height of a side-wall
        // this is the low-end of the rake (as opposed to the peak) for an end-wall
        
        let leftSupportRoofHeight, rightSupportRoofHeight;
        
        if(this.design.type == CORE.components.lew || this.design.type == CORE.components.rew){
            let pitchedPurlinDimY = BlueprintHelper.pitchedPurlinDimY(this.pitchRatio);
            // Need to check what kind of wall this is here, Side/End and set these appropriately
            leftSupportRoofHeight = BlueprintHelper.getEndWallHeightAtPosition(leftColumnPosX , this.length, this.maxHeight, this.pitchRatio,this.roofType)  - pitchedPurlinDimY;
            rightSupportRoofHeight = BlueprintHelper.getEndWallHeightAtPosition(rightColumnPosX ,this.length, this.maxHeight, this.pitchRatio,this.roofType)  - pitchedPurlinDimY;
        }
        else{
            leftSupportRoofHeight = rightSupportRoofHeight = this.maxHeight;
        }        

        let leftSupportTopPosY, rightSupportTopPosY;
        leftSupportTopPosY = leftSupportRoofHeight;
        rightSupportTopPosY = rightSupportRoofHeight;

        let botPosY = 0; //assume the supports go all the way to the foundation
        let leftSupportTopFound=false;
        let rightSupportTopFound=false;

        let windowTopPosY = wDesign.pos.y + wDesign.dim.height/2;

        girtHeights.forEach((gh)=>{
            // for an end-wall, we need to consider the rake height

            // if this girt is above the top of the window
            if((gh-CORE.roof.purlin.dim.width) > windowTopPosY){

                // if we've not already found the left support's top position
                // and the girt height is less than the height of the roof
                if(!leftSupportTopFound && gh < leftSupportRoofHeight){
                    leftSupportTopFound=true; // stop searching for the left support's top position
                    leftSupportTopPosY = gh;
                }

                if(!rightSupportTopFound && gh < rightSupportRoofHeight){
                    rightSupportTopFound=true; // stop searching for the right support's top position
                    rightSupportTopPosY = gh;
                }                
            }

            // if this window's supports should stop at the next available girt
            if(wDesign.girtToGirt){
                // if this girt is above the previous best height, yet still below the bottom of the window
                if(gh > botPosY && gh < wDesign.pos.y - wDesign.dim.height/2){                    
                    botPosY = gh;
                }
            }
        })

        
        // Left column (faces with C opening to the right)
        let bottomPos = new THREE.Vector3(leftColumnPosX, botPosY, z);
        let topPos = new THREE.Vector3(leftColumnPosX, leftSupportTopPosY, z);
        let length = topPos.y - bottomPos.y;
        let colL = new _3dColumnC(CORE.preferences.des_levelOfDetail.value, length, false, BlueprintHelper.getStandardFrameMaterialSize(), this.girtColor);
        colL.group.position.copy(bottomPos.clone());
        colL.group.rotation.y = 0; // it's built to open toward the right end
        this.gDynamic.add(colL.group);
        if(this.addToQuoteLayer)
            layerHelper.enableLayer(colL.group, CORE.layers.quote);
        this.windowSupports.push(colL);

        // Right column (faces with C opening to the left)
        bottomPos = new THREE.Vector3(rightColumnPosX, botPosY, z);
        topPos = new THREE.Vector3(rightColumnPosX, rightSupportTopPosY, z);
        length = topPos.y - bottomPos.y;
        let colR = new _3dColumnC(CORE.preferences.des_levelOfDetail.value, length, false, BlueprintHelper.getStandardFrameMaterialSize(), this.girtColor);
        colR.group.position.copy(bottomPos.clone());
        colR.group.rotation.y = Math.PI;
        this.gDynamic.add(colR.group);
        if(this.addToQuoteLayer)
            layerHelper.enableLayer(colR.group, CORE.layers.quote);
        this.windowSupports.push(colR);

        let girtHeader = new _3dGirtHoriC(CORE.preferences.des_levelOfDetail.value, new THREE.Vector3(0, 0, 0), new THREE.Vector3(wDesign.dim.width + 2 * supportMargin, 0, 0), CORE.dir.rel.up, false, this.girtColor);
        girtHeader.build();
        this.gDynamic.add(girtHeader.group);
        if(this.addToQuoteLayer)            
            layerHelper.enableLayer(girtHeader.group, CORE.layers.quote);
        let frameInset = -girtHeader.dim.web/2-1;
        if(!this.buildFacingPositiveZ) 
            frameInset*=-1;
        girtHeader.group.position.set(midWindowX,wDesign.pos.y+wDesign.dim.height/2,frameInset);
        this.windowSupports.push(girtHeader);

        let girtSill = new _3dGirtHoriC(CORE.preferences.des_levelOfDetail.value, new THREE.Vector3(0, 0, 0), new THREE.Vector3(wDesign.dim.width + 2 * supportMargin, 0, 0), CORE.dir.rel.down, false, this.girtColor);
        girtSill.build();
        this.gDynamic.add(girtSill.group);
        if(this.addToQuoteLayer)            
            layerHelper.enableLayer(girtSill.group, CORE.layers.quote);
        girtSill.group.position.set(midWindowX,wDesign.pos.y+-wDesign.dim.height/2,frameInset);
        this.windowSupports.push(girtSill);
    }

    getOutlineMeshes(){
        if(this.design.openWall)
            return [];

        // accumulate the child bay outline meshes
        let outlineMeshes = [];
        if(this.components){
            this.components.forEach((child)=>{
                // only accumulate bay outlines
                if(!DesignHelper.bayComponents.includes(child.design.type))
                    return;

                let outline = child.getOutlineMeshes();
                if(outline)
                    outlineMeshes.push(...outline)
            })    
        }
        return outlineMeshes;
    }
    
    designChange(){
        if(this.cbDesignChange)
            this.cbDesignChange(this.design);
    }


    getOpenWallOption(){
        return OptionHelper.checkbox("openWall", "Open wall", impactTypes.wallOpen,
            true,
            undefined,
            undefined,
            (v)=>{
                this.design.openWall = v;

                // update all bays on this wall
                let bays = []
                bays.push(...this.design.getLeftEndBays(true));
                bays.push(...this.design.getRightEndBays(true));
                bays.push(...this.design.getFrontSideBays(true));
                bays.push(...this.design.getBackSideBays(true));
                if(bays)
                    bays.forEach((b)=>{
                        b.openWall = this.design.openWall;
                });

                this.designChange();                
            })
    }
    getOptionsSpec(){
        let partitions = this.masterDesign.getComponentDesignById(this.design.parent.id).getTransversePartitions(); 
        let lewTvP = partitions.filter(p => p.sheetingLeft == true).sort((a, b) => a.frameLine < b.FrameLine).at(0);
        let rewTvP = partitions.filter(p => p.sheetingRight == true).sort((a, b) => a.frameLine < b.FrameLine).at(0);
        let endWallWainscotOptionsEnabled = (this.design.type === CORE.components.lew && Util.isUndefined(lewTvP) || this.design.type === CORE.components.rew && Util.isUndefined(rewTvP) )
        let isSideWall = (this.design.type === CORE.components.fsw) || (this.design.type === CORE.components.bsw);
        let isWallFullHeight = !this.design.openWall && (this.maxHeight - this.design.height == 0)
        const wainscotOptionsEnabled = this.design.type === CORE.components.tvp || (endWallWainscotOptionsEnabled && isWallFullHeight)|| (isSideWall && isWallFullHeight);
        let spec= [
            this.getOpenWallOption(),
            //TODO: I need a consistent way of getting the height of a wall, given sloped roofs are possible
            OptionHelper.numericUpDown("openHeight", "Open height", impactTypes.wallHeight, "ft.", 0, (this.maxHeight-8)/12, 1,
            !this.design.openWall,
//            OptionHelper.numericUpDown("openHeight", "Open height", impactTypes.wallHeight, "ft.", 0, 19, 1,
            ()=>{
                // return a value in feet
                return (this.maxHeight - this.design.height)/12;
            },
            (v)=>{
                // return a value in inches
                return v*12; 
            },
            (v)=>{//fnChanged        
                let oldHeight = this.design.height;
                let newHeight = this.maxHeight - v;        
                //this.updateBayHeights(oldHeight,newHeight);
                this.design.height = newHeight;
                this.design.openHeight = v;
                let bays = []
                bays.push(...this.design.getLeftEndBays(true));
                bays.push(...this.design.getRightEndBays(true));
                bays.push(...this.design.getFrontSideBays(true));
                bays.push(...this.design.getBackSideBays(true));
                if(bays)
                    bays.forEach((b)=>{
                        b.height = this.design.height;
                        b.openHeight = this.design.openHeight;
                });
                this.designChange();
            }),
            OptionHelper.header("Wainscoting"),
            OptionHelper.checkbox('wainscot.enabled', 'Include Wainscoting', impactTypes.wainscot, 
            wainscotOptionsEnabled,
            undefined,
            undefined,
            (v)=>{
                this.design.wainscot.enabled=v;
            }),    
            OptionHelper.numericUpDown("wainscot.height", "Height", impactTypes.wainscot, "in.", 12, 48, 3, 
            wainscotOptionsEnabled,
//            OptionHelper.numericUpDown("wainscot.height", "Height",impactTypes.wainscot, "in.", 12, 48, 3, 
            undefined, // fnIn
            undefined, // fnOut
            (v)=>{ // fnChanged
                //this.pickDetectionMesh=undefined; // CBH 2021.11.30 I cannot find any justification for this line.
                this.design.wainscot.height = v;
            }),
            
        ]        
        return spec;
    }


    getOpenHeight(){
        return this.maxHeight - this.design.height;
    }

        doesTouchFoundation(){
            return this.design.height==this.maxHeight;
        }

        drawSolid(color, x,widthInches,ctx, canvasWidth, canvasHeight){
            let width = canvasWidth*(widthInches/this.length);
            ctx.fillStyle = '#'+color;
            ctx.fillRect(x, 0, width, canvasHeight);
        }

        drawFade(x,widthInches, fromColor, toColor, ctx, canvasWidth, canvasHeight){
            let width = canvasWidth*(widthInches/this.length);            
            var grd = ctx.createLinearGradient(x,0,x+width,0);
            grd.addColorStop(0,'#'+fromColor);
            grd.addColorStop(1,'#'+toColor);

            // Fill with gradient
            ctx.fillStyle = grd;
            ctx.fillRect(x, 0, width, canvasHeight);
        }
        


    getPbrBumpMap(length=undefined){
        if(length===undefined)
            length = this.length;

        let canvasWidth=4096;
        let canvasHeight=4096;

        //let canvas = document.querySelector('#test');
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');            
        canvas.width =canvasWidth;
        canvas.height = canvasHeight;
        // drawing gray scale areas
        ctx.fillStyle = '#000000';
        ctx.fillRect(0, 0, canvasWidth, canvasHeight);
        ctx.fillStyle = '#ffffff';
        let ridgeInches = 1.5;
        let slantWidthInches = 1.5
        let ribWidthInches = 1.5;
        let gapWidthInches = 1.5;
        let sectionWidthInches = 12;
        let sectionWidthCanvas = canvasWidth*(sectionWidthInches/length)
        let toCanvasUnitRatio = canvasWidth/length;
        let ribColor = '999999'
        let ridgeColor = 'ffffff'
        let gapColor = '000000'
        canvas.imageSmoothingQuality = "high"
        let x = 0
        while(x< canvasWidth)
        {
            //console.log(x);
            // ridge top 1"                
            this.drawSolid(ridgeColor,x,ridgeInches,ctx,canvasWidth, canvasHeight)
            x+=ridgeInches*toCanvasUnitRatio;
            this.drawFade(x,slantWidthInches,ridgeColor, gapColor, ctx,canvasWidth, canvasHeight)
            x+=slantWidthInches*toCanvasUnitRatio;
            this.drawSolid(gapColor,x,gapWidthInches,ctx,canvasWidth, canvasHeight)
            x+=gapWidthInches*toCanvasUnitRatio;
            this.drawSolid(ribColor,x,ribWidthInches,ctx,canvasWidth, canvasHeight)
            x+=ribWidthInches*toCanvasUnitRatio;
            this.drawSolid(gapColor,x,gapWidthInches,ctx,canvasWidth, canvasHeight)
            x+=gapWidthInches*toCanvasUnitRatio;
            this.drawSolid(ribColor,x,ribWidthInches,ctx,canvasWidth, canvasHeight)
            x+=ribWidthInches*toCanvasUnitRatio;
            this.drawSolid(gapColor,x,gapWidthInches,ctx,canvasWidth, canvasHeight)
            x+=gapWidthInches*toCanvasUnitRatio;
            this.drawFade(x,slantWidthInches, gapColor, ridgeColor, ctx,canvasWidth, canvasHeight)
            x+=slantWidthInches*toCanvasUnitRatio;
            //console.log(x);
        }

        //var dataURL = canvas.toDataURL("image/png");
        //var newTab = window.open('about:blank','image from canvas');
        //newTab.document.write("<body style='background:blue'><img src='" + dataURL + "' alt='from canvas'/></body>");
    
        return new THREE.CanvasTexture(canvas);
    }

        

    getAlphaMap() {
        let canvasDim = 2048;

        //let canvas = document.querySelector('#test');
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        canvas.width = canvasDim;
        canvas.height = canvasDim;
        // drawing gray scale areas
        ctx.fillStyle = '#ffffff'; // white is opaque
        ctx.fillRect(0, 0, canvasDim, canvasDim);
        ctx.fillStyle = '#000000'; // black is transparent
        
        this.holes.forEach((h) => {

            let windowLeft = h.pos.x - h.dim.width / 2;
            let windowTop = this.ridgeHeight - h.pos.y - h.dim.height / 2;

            let left = canvasDim * (windowLeft / this.length);
            let top = canvasDim * (windowTop / this.ridgeHeight);
            let width = canvasDim * (h.dim.width / this.length);
            let height = canvasDim * (h.dim.height / this.ridgeHeight);
            //console.log(left, top, width, height);
            ctx.fillRect(left, top, width, height);
        });

        const alphaMap = new THREE.CanvasTexture(canvas);
        alphaMap.anisotropy = 16;
        return alphaMap;
    }

    getInsulationColorMap(){
        let canvasSize=2048;

        //let canvas = document.querySelector('#test');
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');            
        canvas.width =canvasSize;
        canvas.height = canvasSize;
        // drawing gray scale areas
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, canvasSize, canvasSize);
        ctx.strokeStyle = '#000000';
        ctx.lineWidth = .1;
        let inetervalWidthInches = 1.5
        let toCanvasUnitRatio = canvasSize/this.length;
        canvas.imageSmoothingQuality = "high"
        let x = 0
        while(x< canvasSize)
        {
            this.drawLine(ctx, x, canvasSize); 
            x+=inetervalWidthInches*toCanvasUnitRatio;
        }

        //this.debugCanvasTexture(canvas);
    
        return new THREE.CanvasTexture(canvas);
    }

    debugCanvasTexture(canvas) {
        var dataURL = canvas.toDataURL("image/png");
        var newTab = window.open('about:blank', 'image from canvas');
        newTab.document.write("<body style='background:blue'><img src='" + dataURL + "' alt='from canvas'/></body>");
    }

    getColorMap(length=undefined){
        if (length===undefined)
            length = this.length;

        let canvasWidth=2048;
        let canvasHeight=2048;

        //let canvas = document.querySelector('#test');
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');            
        canvas.width =canvasWidth;
        canvas.height = canvasHeight;
        // drawing gray scale areas
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, canvasWidth, canvasHeight);
        ctx.strokeStyle = '#000000';
        ctx.lineWidth = .1;
        let ridgeInches = 1.5;
        let slantWidthInches = 1.5
        let ribWidthInches = 1.5;
        let gapWidthInches = 1.5;
        let sectionWidthInches = 12;
        let sectionWidthCanvas = canvasWidth*(sectionWidthInches/length)
        let toCanvasUnitRatio = canvasWidth/length;
        let ribColor = '999999'
        let ridgeColor = 'ffffff'
        let gapColor = '000000'
        canvas.imageSmoothingQuality = "high"
        let x = 0
        while(x< canvasWidth)
        {
            
            // ridge top 1"
            //this.drawLine(ctx, x, canvasHeight); 
            x+=ridgeInches*toCanvasUnitRatio;
            //this.drawLine(ctx, x, canvasHeight); 
            x+=slantWidthInches*toCanvasUnitRatio;
            this.drawLine(ctx, x, canvasHeight); 
            x+=gapWidthInches*toCanvasUnitRatio;
            //this.drawLine(ctx, x, canvasHeight); 
            x+=ribWidthInches*toCanvasUnitRatio;
            //this.drawLine(ctx, x, canvasHeight); 
            x+=gapWidthInches*toCanvasUnitRatio;
            //this.drawLine(ctx, x, canvasHeight); 
            x+=ribWidthInches*toCanvasUnitRatio;
            //this.drawLine(ctx, x, canvasHeight); 
            x+=gapWidthInches*toCanvasUnitRatio;
            this.drawLine(ctx, x, canvasHeight); 
            x+=slantWidthInches*toCanvasUnitRatio;
            //console.log(x);
        }

        //var dataURL = canvas.toDataURL("image/png");
        //var newTab = window.open('about:blank','image from canvas');
        //newTab.document.write("<body style='background:blue'><img src='" + dataURL + "' alt='from canvas'/></body>");
    
        return new THREE.CanvasTexture(canvas);
    }

    drawLine(ctx, x, canvasHeight) {
        ctx.beginPath(); // Start a new path
        ctx.moveTo(x, 0); // Move the pen to (30, 50)
        ctx.lineTo(x, canvasHeight); // Draw a line to (150, 100)
        ctx.stroke();
    }

/*

default: open child to the parent

then throw options at the user
 - mating wall of child
 - mating wall of parent

*/

        /*
        Status
         - Roofs look amazing
         - walls either look OLD or don't have holes

        Exit strategy
         - try researching alphaMap and coplanar transparent materials (45 min)
         - change from multiple panels to one giant panel, dynamically generating alpha and bump maps
         - check everything in, revert it all and email myself the pertinent info
         - call with Adam: confess off task, apologize, ask about panel cut template

         Plan:
         (45) research (1:33 + :45 => 2:15)
            https://discourse.threejs.org/t/transparency-changes-at-certain-camera-angles/34445
            https://discourse.threejs.org/t/alpha-map-at-an-angle/21783
         (1hr) giant dynamic wall textures
         (1hr) lighting tweaks
         (1hr) check everything in. Put back the old way.

         at 3:30, an hour past my research deadline, we have a working side wall, 
         but the end wall shape is an issue

            Why walls and really all panels are so hard
             - side walls have a 4 corner shape
             - end walls have a 4 or a 5 corner shape
             - walls have wainscot
             - threejs colorMap, bumpMap, & alphaMap all share wrapS (horizontal texture strecthing)
             - walls need openings (that are fully transparent)
             - wall openings pierce through wainscot plane, wainscot trim, exterior plane, interior plane, insulation plane
             - end walls and side walls are not the same height, so texture UVs must consider a consistent ridgeHeight to avoid compressing colorMap, alphaMap, or bumpMap


        */

    getExteriorPanelMaterial(colorMap, alphaMap, bumpMap){
        colorMap.wrapS = THREE.ClampToEdgeWrapping;
        colorMap.wrapT = THREE.ClampToEdgeWrapping;
        let materialExt = materialHelper.getExteriorPanelMaterial(this.design.color, colorMap)
        
        // make an alpha map for dynamic transparecny
        materialExt.alphaMap = alphaMap;
        materialExt.alphaTest=.9;
        materialExt.transparent = true;

        // make a bump map for realistic lighting
        materialExt.bumpMap = bumpMap;
        materialExt.bumpScale = 1;         
        materialExt.bumpMap.wrapS = THREE.ClampToEdgeWrapping;
        materialExt.bumpMap.wrapT = THREE.ClampToEdgeWrapping;

        return materialExt;
    }


    getInteriorPanelMaterial(colorMap, alphaMap, bumpMap){
        colorMap.wrapS = THREE.ClampToEdgeWrapping;
        colorMap.wrapT = THREE.ClampToEdgeWrapping;
        let materialExt = materialHelper.getInteriorPanelMaterial(0xFFFFFD, colorMap)
        
        // make an alpha map for dynamic transparecny
        materialExt.alphaMap = alphaMap;
        materialExt.alphaTest=.9;
        materialExt.transparent = true;

        // make a bump map for realistic lighting
        materialExt.bumpMap = bumpMap;
        materialExt.bumpScale = 1;         
        materialExt.bumpMap.wrapS = THREE.ClampToEdgeWrapping;
        materialExt.bumpMap.wrapT = THREE.ClampToEdgeWrapping;

        return materialExt;
    
    }

    getInteriorInsulationMaterial(alphaMap){
        let colorMap = this.getInsulationColorMap()
        colorMap.wrapS = THREE.ClampToEdgeWrapping;
        colorMap.wrapT = THREE.ClampToEdgeWrapping;
        let materialExt = materialHelper.getInsulationMaterial(0xEEEEEE, this.length, colorMap)
        materialExt.side = THREE.BackSide;

        
        // make an alpha map for dynamic transparecny
        materialExt.alphaMap = alphaMap
        materialExt.alphaTest=.9;
        materialExt.transparent = true;
        return materialExt;
    }


    getInteriorMaterial(colorMap, alphaMap, bumpMap){
        // 
        if(this.insulation)
            return this.getInteriorInsulationMaterial(alphaMap);
        else
            return this.getInteriorPanelMaterial(colorMap, alphaMap, bumpMap);
    }

    getWainscotMaterial(colorMap, alphaMap, bumpMap){
        let materialExt = materialHelper.getExteriorPanelPbrMaterial(this.design.wainscot.color, this.width)
        
        // make an alpha map for dynamic transparecny
        materialExt.alphaMap = alphaMap
        materialExt.alphaTest=.9;
        materialExt.transparent = true;

        // make a bump map for realistic lighting
        materialExt.bumpMap.repeat.set(this.width/36, 1)
        materialExt.bumpScale = 1;         
        materialExt.bumpMap.wrapS = THREE.ClampToEdgeWrapping;
        materialExt.bumpMap.wrapT = THREE.ClampToEdgeWrapping;

        return materialExt;
    }


    getWainscotTris(){
        let wainscotHeight = this.design.wainscot.height;
        let topHeightRatio = wainscotHeight / this.ridgeHeight;
        let bottom = 0;
        let bottomHeightRatio = bottom / this.ridgeHeight;
        let tris = [];
        let r = new Tri(
            'bottom right triangle',
            [
                new Vector3(-this.length / 2, bottom, 0),
                new Vector3(this.length / 2, bottom, 0),
                new Vector3(this.length / 2, wainscotHeight, 0),
            ],
            [
                0.0, bottomHeightRatio,
                1.0, bottomHeightRatio,
                1.0, topHeightRatio,
            ]
        );

        let l = new Tri(
            'middle left triangle',
            [
                new Vector3(-this.length / 2, bottom, 0),
                new Vector3(this.length / 2, wainscotHeight, 0),
                new Vector3(-this.length / 2, wainscotHeight, 0),
            ],
            [
                0.0, bottomHeightRatio,
                1.0, topHeightRatio,
                0.0, topHeightRatio
            ]
        );

        tris.push(r);
        tris.push(l);
        return tris;
    }


    addWainscotPanel(){
        let  wainscot = new EarcutDataManager();
        let wainscotHeight = this.design.wainscot.height;
        let bottom = 0;        
        
        
        wainscot.setOutline([
            -this.length / 2, bottom,
            this.length / 2, bottom,                
            this.length / 2, wainscotHeight, // front eave
            -this.length / 2, wainscotHeight, // back eave
        ]);
       

        this.holes.forEach((h)=>{
            let x = h.pos.x-this.length/2;            
            let y = h.pos.y;
            let bottomOfHole = y-h.dim.height/2;
            let heightOfWainscotHole = wainscotHeight - bottomOfHole;
            let yOfWainscotHole = bottomOfHole + heightOfWainscotHole/2;
            let width =  h.dim.width;
            let height = heightOfWainscotHole/2;
            wainscot.addSquareHole(x,yOfWainscotHole, width, heightOfWainscotHole);
        })
        let wainscotData = wainscot.generate();
            
        //let frontMaterial = materialHelper.getExteriorPanelPbrMaterial(this.design.wainscot.color, this.length);
        
        //let front = SheetingHelper.defineFrontPlane(frontMaterial);
        
        let wainscotSheeting = new Sheeting(
            CORE.preferences.des_levelOfDetail.value,
            wainscotData,
            this.getWainscotFrontPlane(),
            this.getWainscotBackPlane(),
            CORE.layers.walls);

        wainscotSheeting.group.position.x =this.length/2;
        wainscotSheeting.group.name = 'wainscot sheeting';
        if(this.buildFacingPositiveZ)
            wainscotSheeting.group.position.z = .5;
            else
            wainscotSheeting.group.position.z = -.5;
        this.gWall.add(wainscotSheeting.group);
    }


    buildPickMesh(){
        if(this.design.openWall)
            return;
        let w = this.length;
        let h = this.maxHeight;
        let bottomHeight = this.maxHeight-this.design.height
        let shapePoints = [
            new Vector3(0,bottomHeight,0),
            new Vector3(0,h,0),
            new Vector3(w,h,0),
            new Vector3(w,bottomHeight,0),
        ]



            // build some bays
            //this.buildBays();
            this.calculateBayDesigns(); // before buildComponents
            let framedOpenings = this.design.components.filter((c) => DesignHelper.allowedFramedOpeningsOnBays.includes(c.type));
            if(framedOpenings.length > 0)
                this.migrateFramedOpenings(framedOpenings);
            // a class that makes shape (shapeFactory)
            // takes in the initial corners in 2D.
            // takes in holes to punch
            
            // change all shapePoints to be relative to the origin
            this.sh = new ShapeHelper(shapePoints, undefined, undefined, false);
            
            //get just the four points for the outer wall dimensions
            // on end walls, there are 5 points, so we must ignore that 5th as ShapeHelper requires squares.
            let wainscotCorners = [];
           // shapePoints.forEach((p)=>{            
                //wainscotCorners.push(p);
            //})
            //if(shapePoints.length === 5) // endwall-detection since an end-wall can face the X axis, the Z axis, and any direction of those axes
                //wainscotCorners.splice(2,1);
            
            //if(this.addWainscoting)
                //this.wsh = new ShapeHelper(wainscotCorners, undefined, this.design.wainscot.height, true);

        // add an extruded shape for the side that has no holes in it to use for raycast picking,
        // so that editable objects have a surface at all points for 'picking' purposes  
        // this is invisible because it's only for picking
        // this pickMesh should have no holes in it.
        
        this.geoSolidWall = new THREE.ExtrudeGeometry(this.sh.getShape(), this.extrusionSettings);
        this.geoSolidWall.name= 'solidwall geo';

        if(typeof this.gWallPick === 'undefined'){
            this.gWallPick = new THREE.Group();
            this.gWallPick.name = 'pickgroup for '+ this.getDescription();
            this.group.add(this.gWallPick);

            //CORE.materials.transparent.depthWrite=false;
            //CORE.materials.transparent.depthTest=false;
            this.pickDetectionMesh = new THREE.Mesh(this.geoSolidWall, CORE.debug.pickingMeshes?CORE.materials.debug:CORE.materials.transparent);
            this.pickDetectionMesh.renderOrder=0;
            
            this.gWallPick.add(this.pickDetectionMesh);

            this.pickDetectionMesh.visible=CORE.debug.pickingMeshes;
            this.pickDetectionMesh.name=`wall pickDetection, ${this.design.typeDetail} side`;
            if(this.buildFacingPositiveZ)
                this.pickDetectionMesh.position.z-=.1;
            else
                this.pickDetectionMesh.position.z+=.1;
            
            this.pickDetectionMesh.userData = {
                type: this.design.type, // not sure this is necessary.
                id: this.design.id
            };
        }
    }


        buildDynamicOnly(){
            this.z=0;
            this.initDynamicGroup()
            //if(this.design.openWall===true)
                //return;
                
            this.componentsNotOnWall=[];

            this.gWall = new THREE.Group();  
            this.gWall.name = 'gWall';
            let touchesFoundation = this.doesTouchFoundation();

            //let shapePoints= this.shapePoints;
            this.addWainscoting = this.supportsWainscot && this.design.wainscot.enabled && touchesFoundation;

            // bottom points of the wall are the first and the last.
            //let last = shapePoints.length-1;
            // by sinking wall/wainscot shapes down into the concrete by some small amount (<=1), wall sub-components can be holes or protrusions from the top, but never fully overlapping (which is harder to solve)
            let shapeInsetForWallComponents = .1 
            let bottomOfWallPosY = this.maxHeight -shapeInsetForWallComponents - this.design.height;
            //shapePoints[0].y = bottomOfWallPosY;
            //shapePoints[last].y = bottomOfWallPosY;


            // build some bays
            //this.buildBays();
            this.calculateBayDesigns(); // before buildComponents
            

            this.buildPickMesh();   
           
            this.dynamicWallOpenings = [...this.wallOpenings]

            
            //let context = this.getBuildContextFor(subComponentsToBuild)

            

            this.design.components.forEach((c)=>{
                if(c.type !== CORE.components.wideOpening)
                    return;
                    console.log(c);  
                    this.dynamicWallOpenings.push({pos: c.pos, dim: c.dim})
                          
            });
            
            this.holes = [];
            //if(this.wallOpenings)
                //this.holes.push(...this.wallOpenings);
            // build any components that might need a hole punched in the wall
            this.buildComponents(); // this punches holes in this.sh

            //let colorMap = this.getColorMap();
            //let alphaMap = this.getAlphaMap()
            //let bumpMap = this.getPbrBumpMap();
            //this.addWallExteriorPanel();//colorMap.clone(), alphaMap.clone(), bumpMap.clone());
            //this.addWallInterior(colorMap.clone(), alphaMap.clone(), bumpMap.clone());
            

            
        let girtGroup = new THREE.Group();
        girtGroup.name = "girt group"
        this.gDynamic.add(girtGroup);

        
        // if(touchesFoundation){ // add base angle and wainscot (if enabled) anywhere the wall touches the foundation

        //     let footerGroup = new THREE.Group()
        //     footerGroup.name = 'dynamic footer';
        //     this.gWall.add(footerGroup);
        
        //     if(this.addWainscoting)
        //     {
        //         // build the wainscoting

        //         this.addWainscotPanel();//colorMap, alphaMap, bumpMap);

        //         this.geoExtWain = new THREE.ExtrudeGeometry(this.wsh.getShape(), this.extrusionSettings);

        //         this.meshWain = new THREE.Mesh(this.geoExtWain, this.matWain);
        //         this.meshWain.name = 'wainscot mesh'
        //         this.meshWain.layers.set(CORE.layers.walls);           
        //         if(this.buildFacingPositiveZ)
        //             this.meshWain.position.z=+.5; 
        //         else
        //             this.meshWain.position.z=-.5; 
        //         //this.gWall.add(this.meshWain);

        //         let trimPosY = this.design.wainscot.height;
        //         let segments = this.getWallSegments(trimPosY)            
        //         segments.forEach((seg)=>{
        //             let len = seg.b-seg.a;                

        //             let geohWainTopStrip = new THREE.BoxGeometry(len,2,1)
        //             geohWainTopStrip.name='wallbase geohWainTopStrip'
                    
        //             let meshWainTopStrip = new THREE.Mesh(geohWainTopStrip, 
        //                 materialHelper.getTrimMaterial(this.design.wainscot.color));
        //             meshWainTopStrip.name = 'wainscot trim top'
        //             if(this.buildFacingPositiveZ)
        //                 meshWainTopStrip.position.z=+1
        //             else
        //                 meshWainTopStrip.position.z=-1
        //             meshWainTopStrip.position.y=trimPosY;   
        //             meshWainTopStrip.position.x=seg.a+len/2;
        //             //meshWainTopStrip.renderOrder =1;
        //             meshWainTopStrip.layers.set(CORE.layers.walls);
        //             this.gWall.add(meshWainTopStrip);


        //             // waiscot CEE girt with open end down
        //             let girt = new _3dGirtHoriC(
        //                 CORE.preferences.des_levelOfDetail.value, 
        //                 new THREE.Vector3(seg.a,trimPosY-0,0), 
        //                 new THREE.Vector3(seg.b, trimPosY - 0, 0), CORE.dir.rel.down, false, this.girtColor);
        //             girt.build()
        //             if(this.addToQuoteLayer)            
        //                 layerHelper.enableLayer(girt.group, CORE.layers.quote);
        //             if(!this.buildFacingPositiveZ)
        //                 girt.group.position.z=+4.1
        //             else
        //                 girt.group.position.z=-4.1

        //             // wainscot angle on topo of CEE girt
        //             let footer = new _3dFooter(CORE.preferences.des_levelOfDetail.value, new THREE.Vector3(seg.a, trimPosY + .5, 0), new THREE.Vector3(seg.b, trimPosY + .5, 0), null, this.girtColor)
        //             girtGroup.add(footer.group);                    
        //             if(this.addToQuoteLayer)            
        //                 layerHelper.enableLayer(footer.group, CORE.layers.quote);
        //             if(!this.buildFacingPositiveZ)
        //             {
        //                 girt.group.position.z+=0;
        //                 footer.group.rotation.y = Math.PI;
        //             }
        //             else
        //             {
        //                 girt.group.position.z-=0;
        //             }
        //         })
        //     }
            
        // // BASE ANGLE
        //     let footerPosY = 2;
        //     let segments = this.getWallSegments(footerPosY)

        //     let z = -.0;
        //         if(!this.buildFacingPositiveZ)
        //             z=.0;

        //     segments.forEach((seg)=>{                
        //         let segA = seg.a
        //         let segB = seg.b
                
        //         let a = new THREE.Vector3(segA,0,z);
        //         let b = new THREE.Vector3(segB,0,z);
                
        //         let footer = new _3dFooter(CORE.preferences.des_levelOfDetail.value, a, b, null, this.girtColor)
        //         footerGroup.add(footer.group);
        //         //footer.build()
        //         if(this.addToQuoteLayer)            
        //             layerHelper.enableLayer(footer.group, CORE.layers.quote);
        //         if(!this.buildFacingPositiveZ)
        //             footer.group.rotation.y = Math.PI;
                
                    
                
        //         if(this.allowDripTrim){                        
        //             let length = a.distanceTo(b);
        //             let pos = new THREE.Vector3().copy(a);
        //             pos.lerp(b, .5);
        //             pos.y+=.1;

        //             let dripTrim = new _3dDripTrim(footerGroup, length, CORE.materials.trims.corner);                        
        //             dripTrim.group.position.copy(pos);
        //             if(!this.buildFacingPositiveZ)
        //                 dripTrim.group.rotation.y = Math.PI;
        //             layerHelper.enableLayer(dripTrim.group, CORE.layers.walls);
        //             if(this.addToQuoteLayer)
        //                 layerHelper.enableLayer(dripTrim.group, CORE.layers.quote);
        //         }

        //     })
        // }
        // else{
        //     // this wall is not a full height wall
        //     // add an open-face up Cee Girt to support the bottom of the panel
        //     //let segA,segB;
        //     let seg = SegmentHelper.getSegment(0,this.length);
        //     let segA = seg.a
        //     let segB = seg.b
        //     // support the open-height wall
        //     let girt = new _3dGirtHoriC(
        //         CORE.preferences.des_levelOfDetail.value, 
        //         new THREE.Vector3(segA,this.design.openHeight,0), 
        //         new THREE.Vector3(segB, this.design.openHeight, 0), CORE.dir.rel.up, false, this.girtColor);
        //     girt.build();
        //     this.gDynamic.add(girt.group);
        //     if(this.addToQuoteLayer)            
        //         layerHelper.enableLayer(girt.group, CORE.layers.quote);
        //     let frameInset = 1; // pulls it behind the wall
        //     let girtZ = -girt.dim.web/2-frameInset;
        //     if(!this.buildFacingPositiveZ)
        //         girtZ*=-1;
        
        //     girt.group.position.z=girtZ

        //     // trim the open-height wall
        //     let trimMat = CORE.materials.trims.corner;
        //     this.bottomTrim = new _3dTrimWallBottom(segB-segA,4,.15, trimMat);
        //     this.gDynamic.add(this.bottomTrim.group);
        //     this.bottomTrim.group.position.x = this.wallMid;
        //     this.bottomTrim.group.position.y = this.getOpenHeight()-.1; // -.1 so that it is tucked under the bottom edge of the wall
        //     if(this.buildFacingPositiveZ)
        //         this.bottomTrim.group.position.z = .05; // half wall-thickness
        //     else
        //         this.bottomTrim.group.position.z = -.05; // half wall-thickness
        //         if(this.addToQuoteLayer)            
        //             layerHelper.enableLayer(this.bottomTrim.group, CORE.layers.quote);
        // }

            
        // if(this.girtHeights)
        // {
        //     // GIRTS
        //     this.girts=[];
        //     // calculate girt locations between set locations (4, 7, 4 & 7)  and the roof ridge
        //     this.girtHeights.forEach((h)=>{        
        //         if(h>=this.getOpenHeight()) // if the girt is above the bottom of a partial wall.
        //             this.addGirtingAtHeight(h);
        //     });
        // }
        // // END GIRTS    
        
        
        // let windows = this.design.getAllWindows();
        // this.windowSupports = [];
        // windows.forEach((w)=>{
        //     //if(w.selected===true) // can't do this until we also know which supports go with what window
        //     this.addWindowSupport(w, this.girtHeights);
        // });
        
        // this.gDynamic.add(this.gWall);
        // //this.gDynamic.renderOrder=1;
        // //this.gWall.add(this.mesh);
        // if(this.footerCollisions.enabled){

        
        //     //this.mesh.updateMatrix();
        //     this.gWall.updateWorldMatrix(true,true);


        //         // add a 1' collision zone at bottom of wall
        //     let size = new THREE.Vector3(this.length, 12, 4)
        //     let position = new Vector3(this.wallMid,this.gWall.position.y, this.gWall.position.z);
        //     position.y+=size.y/2;

        //     let zoneFooter = CollisionHelper.getZone2(
        //         this.gWall,
        //         position,
        //         false,
        //         [CORE.collisions.classes.window],
        //         size,
        //         undefined, 
        //         'footer'
        //         //this.gWall.matrixWorld
                
        //         );

        //     //if(typeof this.getFooterCollisionZoneCenter === 'function')
        //         //zoneFooter.position = new Vector3().copy(this.gWall.position);// this.getFooterCollisionZoneCenter();
        //         //zoneFooter.position.x = this.wallMid;
        //     //else
        //         //zoneFooter.obb.position.x = this.wallMid;
            
        //     //zoneFooter.position.y += size.y/2; // centered at 6" off the ground puts the zone flush with the ground
        //     this.gWall.children.forEach((c)=>{
        //         c.userData.collisionZones = [zoneFooter];
        //     })
            
        // }

        this.contextMenuObject = new THREE.Mesh()            
        this.contextMenuObject.position.set(this.wallMid, bottomOfWallPosY, 0);

        this.contextMenuObject.visible = false; // this 
        this.gWall.add(this.contextMenuObject);
        if(this.addToQuoteLayer)            
            layerHelper.enableLayer(this.gWall,CORE.layers.quote);

        }

        removeDynamicOnly(){
            
            
            //this.group.remove(this.gDynamic);
            //MemHelper.dispose(this.gDynamic);
            //this.texture.dispose();
    
            //this.materialExt.dispose();
            // material
            if(this.matWain)
            {
                if(this.matWain.map)
                    this.matWain.map.dispose();
                this.matWain.dispose();
            }
            if(this.matWainTrim)
            {
                if(this.matWainTrim.map)
                    this.matWainTrim.map.dispose();
                this.matWainTrim.dispose();
            }
            
            if(this.girts) // open wall has no girts
            this.girts.forEach((g)=>{
                g.remove();
            });
    
            if(this.windowSupports) // open wall has no windows
            // TODO: Store window supports by window so that we can check which window is selected, so we can only remove the supports for the selected window            
            this.windowSupports.forEach((s)=>{                
                    s.remove();
            })

            if(this.bottomTrim){
                MemHelper.removeAllChildren(this.bottomTrim.group);
                this.gDynamic.remove(this.bottomTrim.group);            
            }

            if(this.lowDh)
                this.lowDh.remove();
            if(this.highDh)
                this.highDh.remove();

            this.destroyDynamicGroup();
        }

        buildStaticParts(){
            this.initStaticGroup();
            /*
            gWallPick
                pickDetectionMesh

            bottomWallTrim
            */
            if(this.design.openWall===true)
                return;
/*
            let touchesFoundation = this.doesTouchFoundation();
            if(touchesFoundation){ // add base angle anywhere the wall touches the foundation
            
                // BASE ANGLE
                let footerGroup = new THREE.Group()
                footerGroup.name = 'static footer';
                this.gWall.add(footerGroup);
                let footerPosY = 2;
                let segments = this.getWallSegments(footerPosY)
                let z = -.5;
                if(!this.buildFacingPositiveZ)
                    z=.5;
                segments.forEach((seg)=>{                
                    let segA = seg.a
                    let segB = seg.b
                    let a = new THREE.Vector3(segA,0,z)
                    let b = new THREE.Vector3(segB,0,z)
                   
                    // TODO: aren't we also doing this around line 1117?
                    let footer = new _3dFooter(CORE.preferences.des_levelOfDetail.value, a, b, null, this.girtColor)
                    //footerGroup.add(footer.group);
                    if(this.addToQuoteLayer)  
                        layerHelper.enableLayer(footer.group, CORE.layers.quote);
                    
                    if(!this.buildFacingPositiveZ)
                        footer.group.rotation.y = Math.PI;
                    
                        
                })

                
            }*/
        }

    buildDripTrim(group, z){

        let dripTrim = new _3dDripTrim(group, this.length, this.structureConfig.trimMaterials.corner);
        dripTrim.group.position.x = this.length/2;
        dripTrim.group.position.y = .1;
        dripTrim.group.position.z = z;
        layerHelper.enableLayer(dripTrim.group, CORE.layers.walls);
        if(this.addToQuoteLayer)
            layerHelper.enableLayer(dripTrim.group, CORE.layers.quote);
    }




    build(subComponentsToBuild){
        // use the design to build the component        
/*
        // because there was briefly a version published with a single bay type of "bay" instead of the following specific types
        
        bayEndLeft: 'bayEndLeft',
        bayEndRight: 'bayEndRight',
        baySideFront: 'baySideFront',
        baySideBack: 'baySideBack',

        we have to consider the requesting wall and patch the name before invoking the featureHelper class
        */
        let fixedBayType;
        switch(this.design.type){
            case CORE.components.lew:
                fixedBayType = CORE.components.bayEndLeft;
                break;
                case CORE.components.rew:
                fixedBayType = CORE.components.bayEndRight;
                break;
                case CORE.components.fsw:
                fixedBayType = CORE.components.baySideFront;
                break;
                case CORE.components.bsw:
                fixedBayType = CORE.components.baySideBack;
                break;
        }

        // 2022.11.01 build is the only that that should add to components.
        this.design.components.forEach((design) => {
            // if we're about to request a bay,
            if(design.type === 'bay')
                // patch the type first
                design.type = fixedBayType;
                
            let c = ComponentHelper.createComponentWithDesign(design) // this should not be creating bays?
            this.components.push(c);
        })

        // buildDynamic must remain re-usable, so it can't modify the list of child components
        this.buildDynamicOnly(subComponentsToBuild, true);
        //buildStaticParts must remain re-usable (for build and rebuild paths), so it can't modify the list of child components
        this.buildStaticParts();
        
    }

    addGirtingAtHeight(h){
        
        let segments = this.getWallSegments(h, true) // check heights so we don't draw girts to nowhere.

        segments.forEach((seg)=>{            
            let margin = 4; // so horizontal girts don't stick through each other at corners
            let segA = seg.a+margin;
            let segB = seg.b-margin;
            let frameInset = 1; // pulls it behind the wall
            let girt = new _3dGirtHoriC(CORE.preferences.des_levelOfDetail.value, new THREE.Vector3(segA, h, 0), new THREE.Vector3(segB, h, 0), CORE.dir.rel.down, false, this.girtColor);            
            girt.build()      
            this.gDynamic.add(girt.group);         
            if(this.addToQuoteLayer)            
                layerHelper.enableLayer(girt.group, CORE.layers.quote);
            if(this.buildFacingPositiveZ)
                girt.group.position.z=-girt.dim.web/2-frameInset;
            else
                girt.group.position.z=+girt.dim.web/2+frameInset;
            
            this.girts.push(girt);
        })
    }
    
    getExtrudedMesh(points, material){
        this.extrusionSettings = {
            depth: .75, //used to be amount
            bevelEnabled: false,
            bevelThickness: 0.1,
            bevelSize: 1,
            bevelSegments: 1,
            material: 0,
            extrudeMaterial: 1
        };

        let mesh = ShapeHelper.getMeshFromPoints( 
            points,        
        this.extrusionSettings,
        material );    
        mesh.castShadow=true;
        mesh.receiveShadow=false;    
        return mesh;
    }
    
    update(){
        // this function is used to efficiently rebuild a particular wall when a feature on that wall has changed
        // without incurring the cost of reconstructing the entire building from scratch
        
        // remove the wall mesh
        // remove the wall features
        // remove the wall collision zones
        this.remove();
        
        
        // build the wall 
        // build the wall features
        
        this.build();
    }

    openingRot(){
        let rot = 0;
        if(!this.buildFacingPositiveZ)
                rot = Math.PI;
        return rot;
    }

    replaceComponent(id, design){
        // this was added specifically due to the walkin door components which have a size option
        // that allows a component change to lead to an altogether different component being used        
        
        // instantiate the component
        let c = ComponentHelper.createComponentWithDesign(design)

        let bc = this.getBuildContextFor(design.id);
        // position the window in two dimensions, like we're looking at the 2D wall using x,y only
        let pos = new THREE.Vector2(design.pos.x,design.pos.y)
        c.initParent(this);
        c.updatePosition(pos, c.design);
        

        for(let ci = 0;ci<this.components.length;ci++)
        {
            if(this.components[ci].design.id === id){
                this.components[ci].remove();
                this.gDynamic.remove(this.components[ci].group)
                this.components[ci] = c;
                this.components[ci].build(bc);
                this.gDynamic.add(this.components[ci].group)
                break;
            }
        }
            
    }
    
    getDefaultColumnsFromArray(columnPositions){
        if(Util.isUndefined(columnPositions)) // end porches don't have any oldColumnPositions
            {
                throw "Wall_Base columnPositions undefined"
            }
        return columnPositions.filter((c)=> c.source=='default');
    }
    
    buildComponent(c,design){
        // design is guaranteed to be defined
        // c is not guaranteed to be defined
        
        // called from Wall_Base.buildComponents


        // build a child component of this component
        let des = design;
        if(c)
            c.options = this.options;

        /*
        delete all bay components before this
        */
        if(DesignHelper.isTypeBay(design)) {             
            let bay = this.buildBay(design)
            if(bay) // buildBay returns undefined
                this.components.push(bay);
            return;
        }

        if(des.type === CORE.components.null)
            return;

        //console.log(`wall base creating components`)
        // instantiate the component
        
        //c.settings = this.settings;

        if(des.selected===true){            
            this.drawDistanceMarkers(c);
        }

        //let offsetPosX=this.getOffsetX(des.pos.x);

        // build the component
        if(!ComponentHelper.componentIsWallOpening(des.type))
            {
                c.structureConfig = this.structureConfig;
                c.build();     
                this.gDynamic.add(c.group);           
                return;
            }
        // if(build)
        {
            //let bc = this.getBuildContextFor(des.id);
            // position the window in two dimensions, like we're looking at the 2D wall using x,y only
            let pos = new THREE.Vector2(des.pos.x,des.pos.y)
            c.initParent(this);
            c.structureConfig = this.structureConfig;
            c.updatePosition(pos, c.design);
            c.build();
            this.gDynamic.add(c.group);
            //console.log(`Wall adding group at: `, pos)
        }
     
        // don't push a hole in the wall if that hole isn't on the wall
        if(this.isOnWall(c.design)) 
        {
            let hole = c.getHoleShape(new THREE.Vector2(0,0));
            this.holes.push(c.design)
            //this.sh.removeShape(hole, true);
            //if(this.addWainscoting)
                //this.wsh.removeShape(hole,true);
            if(hole!==null)
                this.shape.holes.push(hole);

            
            
        }
        else
        {
            this.componentsNotOnWall.push(c.design.id);
        }
    }
    
   
    getBuildContextFor(subCompId){
        // this function allows the model to rebuild a component with that component's parent context

        let context = {
           
        }

        if(typeof subCompId !== 'undefined'){
            let cDes = this.design.getChildDesign(subCompId);
            let trimMaterial = this.trimMaterials.walkAndWindow;
            if(cDes.type === CORE.components.doorRollup || cDes.type === CORE.components.doorSliding)                
                trimMaterial = this.trimMaterials.door;
            context.trimMaterial = trimMaterial;
        }
        return context;
    }

    removeDistanceMarkers() {
        if(this.lowDh) {
            this.group.remove(this.lowDh.group);
            this.lowDh.remove();
        }
        if(this.highDh) {
            this.group.remove(this.highDh.group);
            this.highDh.remove();
        }
    }

    drawDistanceMarkers(comp){
        // Notes:
        // don't confuse the OH door's supports for the next closest columns. There's is a collision zone buffer of about 1 foot on either side
        // of each real column, that should allow us to start looking for columns 1 foot to the left and right of the sub-component (window/door)

        // endWalls have column positions for every column
        // sideWalls have column positions only for OH doors (and briefly, windows while girts were in development)

        this.removeDistanceMarkers()
        
        let markers = this.getComponentDistanceMarkers(comp);
        if(typeof markers === 'undefined')
            return

        this.lowDh = markers.newLowDh;
        this.highDh = markers.newHighDh;
        comp.parentObjs = [
            this.lowDh,
            this.highDh
        ];

        // use this.design.columnPositions to discover the location (possible size with some alterations) of any overhead door columns on the sideWall
        // as well as all columns on an end-wall
        
        // goal 2: use the list of columns with size and position data to  
        // find the closest column positions on either side of the sub-component.

        // goal 3: 
        // draw a distance marker from the left of the window to the left column
        // draw a distance marker from the right of the window to the right column 
    }   
    
    
    getWallSegments(y, checkHeights=false){
        // TODO: move this up and get rid of roofType in the baseWall
        // this figures out spaces between components at a given height (use for base angle, wainscot top trim, girts)

        // start with an array of 1 segment (the entire wall)

        let actualStart = 0;
        let actualEnd = this.length;

        // base angle doesn't need to check heights
        // wainscoting doesn't need to check heights
        // girts need to check heights so we don't put a girt through the roof
        if(checkHeights){

            // side walls have consistent heights
            if(this.design.type === CORE.sides.frontSide){
                if(y > this.maxHeight)
                    return [];
            }
            else if(this.design.type === CORE.sides.backSide){
                if(y >this.maxHeight)
                    return [];
            }
            // otherwise, this is the left or right end wall,
            else if(y > this.maxHeight)  // if the girt height is above the low height (gabled or sloped)
            {
                // checking heights because the roof line may prevent a girt from running from 
                // one end of the wall to the other
                // heightDiff = girt height - eave height = inset from start (which is opposite the pitch angle)
                let heightDiff = (y - this.maxHeight)
                // Angle is pitchAngle
                // tan(theta)=opp/adj => adj = opp/tan(pitch)
                let inset = heightDiff/Math.tan(this.pitchRadians)
                actualEnd -= inset; // sloped roof end walls 
                if(this.roofType === CORE.roof.types.gable)
                    actualStart += inset; // gabled requires the start be inset also
            }
        }

        if(actualStart === null || actualEnd===null || actualStart === actualEnd){
            return [];
        }


        let segments = [
            SegmentHelper.getSegment(actualStart,actualEnd)
        ]

        // for each component, divide the segment in which it falls into two segments 
        this.components.forEach((c)=>{
            let x = c.design.pos.x;

            if(c.design.pos.y + c.design.dim.height/2 < y ||
                c.design.pos.y - c.design.dim.height/2 > y)
                return;
            // find which segment this component interrupts
            let segi = SegmentHelper.getAffectedSegmentIndex(segments, x);
            if(segi==-1){
                // this component doesn't currently fit in a single segment (like during re-positioning, a top another component)
                return;
            }
            // generate two sub-segments that leave a hole
            let newSegs = SegmentHelper.divideSegment(segments[segi], x, c.design.dim.width);
            // replace the impacted segment with two new sub segments
            segments.splice(segi,1,newSegs.segA,newSegs.segB)
        })
        
        if(this.wallOpenings)
        // check holes required for shed
        this.wallOpenings.forEach((h)=>{
            let x = h.pos.x;

            if(h.pos.y + h.dim.height/2 < y ||
                h.pos.y - h.dim.height/2 > y)
                return;
            // find which segment this component interrupts
            let segi = SegmentHelper.getAffectedSegmentIndex(segments, x);
            if(segi==-1){
                // this component doesn't currently fit in a single segment (like during re-positioning, a top another component)
                return;
            }
            // generate two sub-segments that leave a hole
            let newSegs = SegmentHelper.divideSegment(segments[segi], x, h.dim.width);
            // replace the impacted segment with two new sub segments
            segments.splice(segi,1,newSegs.segA,newSegs.segB)
        })
        return segments;
    }

    SetBayDesignFromWall(wallDesign, bayDesign) {
        // a bay that has been customized looks no different than a bay that has inherited defaults at some point.
        // Thus, when a change is made, like opening a wall or changing a wall to partial height, 
        // it's hard to know which bays should take that change, versus which should not.
        // this is where an openHeight tool, coupled with bay-level open/height settings would make sense.
        
        // for where we are today, there's no telling what a bay has been told and how.
        // when a wall detects the need for a new bay not in the design, it can inherit all the things.
        // when a design loads, an existing bay design already captures the open / height state
        // when a wall is changed, which bays should be affected by that, given you might have used a tool to skirt part of a wall?

        // if we put a flag in the bays that have been skirted with the tool, then they'll never change again, you'd have to use the skirt tool to unskirt them.
        // if we always adjust all bays, then you just have to use the skirt tool AFTER the wall adjustments.

        // if there were an open wall tool, it could detect skirted bays and allow you to choose to include or exclude them.
        
        let p = this.masterDesign.getComponentDesignById(wallDesign.parent.id);

        // copies properties that bays inherit from wall or that the wall should override
        bayDesign.openHeight = wallDesign.openHeight;
        bayDesign.openWall = wallDesign.openWall;
        bayDesign.height = wallDesign.type === CORE.components.bsw ? BlueprintHelper.getBackSideWallHeightInches(p.getRoof(), p.getRoof())/12 : wallDesign.height;
        bayDesign.wainscot = {...wallDesign.wainscot}
    }

    remove(){
        
        MemHelper.removeAllChildren(this.gWallPick);
        this.group.remove(this.gWallPick);
        MemHelper.dispose(this.gWallPick);
        delete this.gWallPick;
        
        this.destroyDynamicGroup();

        MemHelper.removeAllChildren(this.gStatic);
        this.group.remove(this.gStatic);
        MemHelper.dispose(this.gStatic);

        //this.materialExt.dispose();
        // material
        //this.matWain.dispose();
        //this.matWainTrim.dispose();
        
        if(this.girts) // open wall has no girts
            this.girts.forEach((g)=>{
                g.remove();
            });


        if(this.bottomTrim){
            MemHelper.removeAllChildren(this.bottomTrim.group);
            this.group.remove(this.bottomTrim.group);            
        }
        
        this.components.forEach((f)=>{
            f.remove();
        });

        if(this.windowSupports) // open wall has no windows
            this.windowSupports.forEach((s)=>{
                s.remove();
            })


        super.remove();
        //MemHelper.removeAllChildren(this.group);
    }
    

    buildFor(subCompId){
        // this supports an attempt to optimze wall sub-component editing.
        let context = {
            subCompIds: [subCompId]
        };
        this.build();
    }

    // required by object interface
    getContextMenuPosition(){        
        // this returns the position of the contextMenuObject (created in build function) 
        // in terms of world position (considering all object groups, and transforms)
        return this.contextMenuObject.getWorldPosition(new THREE.Vector3());
    }

}
