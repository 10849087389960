<template>
    <div class="settingsGroup" v-if="mode == 1 || mode == 2">
        <h1 class="h1">Business Info</h1>
        <div class="settingsGroupBody">
            <div class="setting">
                <span for="legalName">Legal Name</span><StatusIndicator ref="legalName" :successText="'Saved'"></StatusIndicator>
                <label class="block" style="font-size: var(--fs-3)">The legal name of your business</label>                                                             
                <input type="text" class="w-l" id="legalName" v-model="entity.legalName" required @input="dbEntityInfo('legalName')" :disabled="!canUpdateBusinessInfo"/>                            
            </div>
        </div>
        <div class="settingsGroupBody">
            <div class="setting">
                <span for="businessName">D.B.A Name</span><StatusIndicator ref="businessName" :successText="'Saved'"></StatusIndicator>
                <label class="block" style="font-size: var(--fs-3)">The operating name of your business</label>                                                             
                <input type="text" class="w-l" v-model="entity.businessName" id="businessName" @input="dbEntityInfo('businessName')" :disabled="!canUpdateBusinessInfo"/>
                
            </div>
        </div>
        <div class="settingsGroupBody">
            <div class="setting">
                <span for="phoneNumber">Phone Number</span><StatusIndicator ref="phoneNumber" :successText="'Saved'"></StatusIndicator>
                <label class="block" style="font-size: var(--fs-3)">The primary phone number</label>
                <input id="phoneNumber" type="text" v-model="entity.phoneNumber" @input="dbEntityInfo('phoneNumber')" :disabled="!canUpdateBusinessInfo"/>
                
            </div>
        </div>
        <div class="settingsGroupBody">
            <div class="setting">
                <span for="url">Website</span><StatusIndicator ref="url" :successText="'Saved'"></StatusIndicator>
                <label class="block" style="font-size: var(--fs-3)">The URL for your business</label>                                                             
                <input id="url" class="w-m" type="text" v-model="entity.url" @input="dbEntityInfo('url')" :disabled="!canUpdateBusinessInfo"/>
                
            </div>
        </div>

        <div class="section-group-title"><b>Mailling Address</b></div>
        <div class="settingsGroupBody">
            <div class="setting">
                <label class="block" style="font-size: var(--fs-3)">Your primary mailing address</label>                                                             
                <span for="mailingAddress1">Address 1</span>
                <input id="mailingAddress1" type="text" class="w-m" v-model="entity.mailingAddress1" @input="dbEntityInfo('mailingAddress1')" :disabled="!canUpdateBusinessInfo"/>
                <StatusIndicator ref="mailingAddress1" :successText="'Saved'"></StatusIndicator>                            
                <span for="mailingAddress2">Address 2</span>
                <input id="mailingAddress2" type="text" class="w-m" v-model="entity.mailingAddress2" @input="dbEntityInfo('mailingAddress2')" :disabled="!canUpdateBusinessInfo"/>
                <StatusIndicator ref="mailingAddress2" :successText="'Saved'"></StatusIndicator>                            
            
                <span for="city">City</span>
                <input id="mailingAddressCity" type="text" class="w-m" v-model="entity.mailingAddressCity" @input="dbEntityInfo('mailingAddressCity')" :disabled="!canUpdateBusinessInfo"/>
                <StatusIndicator ref="cityStateZip" :successText="'Saved'"></StatusIndicator>    
                <span for="state">State</span>
                <input id="mailingAddressState" type="text" class="w-m" v-model="entity.mailingAddressState" @input="dbEntityInfo('mailingAddressState')" :disabled="!canUpdateBusinessInfo"/>
                <span for="zipcode">Zipcode</span>
                <input id="mailingAddressZip" type="text" class="w-s" v-model="entity.mailingAddressZip" @input="dbEntityInfo('mailingAddressZip')" :disabled="!canUpdateBusinessInfo"/>
                
            </div>           
        </div>
        <div class="section-group-title"><b>Branding</b></div>
        <div class="settingsGroupBody">
            <div class="setting" style="display:flex">
                <div>
                    <span for="logoUpload">Banner Logo</span><br/>                           
                    <label style="font-size:15px" >A larger and wider image, usually with your DBA name</label><br/>
                    <label style="font-size:15px" >A 3-to-1 aspect ratio and 72ppi resolution is recommended</label>
                    <label style="font-size:15px" >Example: 300 px wide x 100 px tall</label>
                </div>
                <div class="logo-input">
                    <input  type="file" id="logoUpload" @change="handleLogoUpload($event, 'logo_Lrg')" :disabled="!canUpdateBusinessInfo"/>
                    <StatusIndicator ref="logoLrg" :successText="'Saved'"></StatusIndicator>    
                    <div class="logo-lrg">
                        <img v-if="logo_Lrg" :src="logo_Lrg" alt="Logo" style="height:100px"/>
                        <span v-else>None</span>
                    </div>
                    
                </div>
            </div>
        </div>

        <div class="settingsGroupBody">
            <div class="setting" style="display:flex">
                <div>
                    <span for="logoUpload">Brand Symbol</span><br/>                           
                    <label style="font-size:16px" >A smaller and more square image that represents your brand.</label><br/>
                    <label style="font-size:15px" >An aspect ratio (width-to-height) of 1-to-1 and 72ppi resolution is recommended</label>
                </div>

                <div class="logo-input">
                    <input  type="file" id="logoUpload" @change="handleLogoUpload($event, 'logo_Sml')" :disabled="!canUpdateBusinessInfo"/>
                    <StatusIndicator ref="logoSml" :successText="'Saved'"></StatusIndicator>    
                    <div class="logo-sml">
                        <img v-if="logo_Sml" :src="logo_Sml" alt="Logo" />
                        <span v-else>None</span>
                    </div>
                    
                </div>
            </div>
        </div>

        <div class="section-group-title"><b>Bid sheets</b></div>
        <div class="settingsGroupBody">
            <div class="setting" style="display:flex">
                <div style="width:100%">
                    <span for="summary_tc">Summary Page Terms and Conditions</span><StatusIndicator ref="summary_tc" :successText="'Saved'"></StatusIndicator>    
                    <div class="block" style="font-size:var(--fs-3)">
                        <label  >Terms and Conditions that will appear only on the summary page</label> <label class="" style="font-size:var(--fs-3)" :class="{invalid: summary_tc.trim().length > termsAndConditionsMaximumLength}">({{summary_tc.trim().length}} / {{termsAndConditionsMaximumLength}} characters)</label>
                    </div>                    
                    <textarea style="height:250px" type="file" id="summary_tc" v-model="summary_tc" @input="dbEntitySettings('summary_tc')" :disabled="!canUpdateBusinessInfo"></textarea>
                </div>
            </div>
        </div>

        <div class="settingsGroupBody">
            <div class="setting" style="display:flex">
                <div style="width:100%">
                    <span for="detailed_tc">Detailed Page Terms and Conditions</span><StatusIndicator ref="detailed_tc" :successText="'Saved'"></StatusIndicator>    
                    <div class="block" style="font-size: var(--fs-3)">
                        <label>Terms and Conditions that will appear on each detailed structure page</label> <label :class="{invalid: detailed_tc.trim().length > termsAndConditionsMaximumLength}">({{detailed_tc.trim().length}} / {{termsAndConditionsMaximumLength}} characters)</label>
                    </div>
                    <textarea style="height:250px" type="file" id="detailed_tc" v-model="detailed_tc" @input="dbEntitySettings('detailed_tc')" :disabled="!canUpdateBusinessInfo"></textarea>
                </div>
            </div>
        </div>
    </div> <!-- end settings group -->
</template>
<script>
// @ is an alias to /src
import SwmAddAddress from '@/components/swm-add-address.vue';
import userSettingCheckbox from '@/components/user-setting-checkbox.vue';
import userSettingDropdown from '@/components/user-setting-dropdown.vue';
import colorSelect2 from '@/components/color-select2.vue';
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapActions, mapMutations } from 'vuex';
import Vue from 'vue'
import Vuex from 'vuex'
import StatusIndicator from '@/components/StatusIndicator.vue';
import api from '@/api';
import Util from '../lib/utility.js'
Vue.use(Vuex)


export default {
  name: 'HomeView',
  components:{
    SwmAddAddress,
    userSettingCheckbox,
    userSettingDropdown,
    colorSelect2,
    StatusIndicator
  },
  data(){
    return {  
        entity: {},
       
        classes: {
            container: "settingsGroup",
            header: "settingsGroupHeader",
            body: "settingsGroupBody",
            setting: "setting",
            value: "settingValue",
            description: "settingDescription"
        },
        isAuthenticated: false,
        showSupplierOnlySetting: false,
        canUpdateBusinessInfo: false,
        canUpdateSupplierSettings: false,
        canUpdateContractorSettings: false,
        dbEntitySettings: () => {},
        dbEntityInfo: () => {},
        logo_Sml: '',
        logo_Lrg: '',
        summary_tc: '',
        detailed_tc: '',
        termsAndConditionsMaximumLength: 1200
    }
  },
  computed:{
    ...mapState('contextModule',[
      'user'
      ,'userBusiness'
      ,'current'
    ]),
    mode(){
      return this.current.mode
    },    
  },
  methods: {
    ...mapActions('businessModule', ['getBusinessSettings','getBusinessSetting', 'updateBusinessInfo', 'fetchBusiness', 'updateBusinessInfo','getBusinessTrimProfiles']),
    backToBusinesses(){
        // this needs to become fully centralized if every settings page is going to need it
        ContextLogic.clearBusinessContext(this.$store);

        if(this.userBusiness.type==1)
            this.$router.push({path: `/supplier/${this.userBusiness.id}/contractors`})
        else
            this.$router.push({path: `/businesses/list`})
    },
    getDynamicSettingValue(settingName){
        return this[settingName];
    },
    async updateEntity(settingName) {
        let si = this.$refs[settingName];
        if(si != undefined)
            si.loading();

        let response = await this.updateBusinessInfo(this.entity);

        if(response){
            si.fail();
        }
        else{
            si.success();
        }
    },
    handleLogoUpload(event, settingName) {
        const file = event.target.files[0];
        if (!file) return;
        
        let logo = file;
        const reader = new FileReader();
        
        reader.onload = (e) => {
            if(settingName.includes("Sml")){
                this.logo_Sml = e.target.result;
                //let data = {id: settingName, val:this.logo_Sml};
                this.setBusinessSetting("logo_Sml");
            }
            else if(settingName.includes("Lrg")){
                this.logo_Lrg = e.target.result;
                //let data = {id: settingName, val:this.logo_Lrg};
                this.setBusinessSetting("logo_Lrg");
            }
        }
        reader.readAsDataURL(logo)
    },
    async setBusinessSetting(id){

        //let statusRefId = data.id; // use the setting name to know the setting status indicator name
        let si = this.$refs[id]; // use the setting status indicator name to get the status indicator component
        if(si != undefined)
        {
            if(Array.isArray(si)){ 
                // Vue takes overwhen refs are in an v-for context and makes the Ref an array no matter what.
                si= si[0];
            }
            si.loading();
        }

        let value = this[id];
        value = value.trim();
        if(id == "summary_tc" || id=="detailed_tc")
        {
            let length = value.length;
            if(length>this.termsAndConditionsMaximumLength){
                si.fail();
                return;
            }
        }

        console.log(id);

        let settingsGroup = {
            businessId: this.entity.id,
            name: id,
            value: this[id]
        }

        
        let response = await api.setBusinessSetting(settingsGroup);

        if(response.isError){
            si.fail();
        }
        else{
            si.success();
        }
    },
    async saveChanges(){
        let response = await this.updateBusinessInfo(this.entity);
        if(response.isError)
            this.commitDetailsError(response.data)
        else{
            this.editing = false;
            this.$router.push(this.$route.path.replace('/edit', ''));
        }
    },
    commitDetailsError(errData){
    this.$modal.show('dialog', {
        title: 'Warning',
        text: `${errData.msg}`,
        buttons:[
        {
            title: 'OK',
            handler: () => {
            this.$modal.hide('dialog')
            }
        },
        ]
    })
    },
    defaultAllSettings() {
      this.logo_Sml = '';
      this.logo_Lrg = '';
    },
    async loadSettingValues(){
        let pathParts = this.$route.path.split("/");
        let businessId = pathParts[2];

        this.defaultAllSettings();
        try{
            let settings = [
                await this.GetSettingByName("logo_Sml"),
                await this.GetSettingByName("logo_Lrg"),
                await this.GetSettingByName("summary_tc"),
                await this.GetSettingByName("detailed_tc")
            ];
            this.initSettings(settings);
        }
        catch{
            this.$toast.error(response.msg);
            console.error("unable to load and set business logos")
        }

    },
    async GetSettingByName(settingName){
        return await this.getBusinessSetting({businessId: this.entity.id, settingName});
    },
    initSettings(settings) {
      // assign the data values
      settings.forEach((settingsGroup) => {
          this.initSetting(settingsGroup);
      })               
      
      // assigning the logo value won't set the image, so apply the base64 data to the img src

    },
    initSetting(setting) {
        if (setting.value === "true" || setting.value === "false")
            setting.value = setting.value === 'true';

        
        if (typeof setting.value !== 'undefined') {
            this[setting.name] = setting.value;
        }
    },
  },
  created() {
    this.dbEntityInfo = Util.debounce(this.updateEntity, 1000);
    this.dbEntitySettings = Util.debounce(this.setBusinessSetting,1000);
  },
  async mounted() {
    let pathParts = this.$route.path.split("/");
    this.entity = await this.fetchBusiness(pathParts[2]);
    try{
        await this.loadSettingValues();
    }
    catch{
        console.error("unable to load setting values")
    }

    await ContextLogic.initContext(this);
    this.isAuthenticated = this.$auth.isAuthenticated;
    let isContractorAdmin = this.user.roles.includes(3);
    let isSupplierAdmin = this.user.roles.includes(1);
    let isSystemAdmin = this.user.roles.includes(0);
    console.log(`contractor admin: ${isContractorAdmin} || supplier admin: ${isSupplierAdmin} || system admin: ${isSystemAdmin}`)
    this.showSupplierOnlySetting = this.mode == 1;
    this.canUpdateSupplierSettings = isSupplierAdmin || isSystemAdmin;
    this.canUpdateContractorSettings = isContractorAdmin || isSystemAdmin;
    this.canUpdateBusinessInfo = this.canUpdateSupplierSettings || this.canUpdateContractorSettings;
    console.log(`can update contractor settings: ${this.canUpdateContractorSettings}`)
    console.log(`can update supplier settings: ${this.canUpdateSupplierSettings}`)
    console.log(`can update entity info: ${this.canUpdateBusinessInfo}`)



    
  }
}
</script>
<style scoped>


   @media (max-width: 4924px) {
       .settingsGroup {
           font-size: 1.3em;
       }
   }
   @media (max-width: 1280px) {
       .settingsGroup {
           font-size: 1.2em;
       }
   }
   @media (max-width: 1024px) {
       .settingsGroup {
           font-size: 1.1em;
       }
   }
   @media (max-width: 768px) {
       .settingsGroup {
           font-size: 1.0em;
       }
   }
   @media (max-width: 640px) {
       .settingsGroup {
           font-size: 1.0em;
       }
   }
   .settingGroupList {
       width: 90%;
       margin: auto;
   }

   .settingsContainer {
       display: flex;
       flex-flow: row wrap;
       justify-content: space-evenly;
       align-items: flex-start;
   }

   .settingsItem {
       display: block;
       margin: 5px;
       padding: 5px;
       margin-left: 20px;
       border: 1px solid lightgrey;        
       width:100%;
       text-align: left;
   }


   /* parent  */
   
   .settingsContainer {
       flex-grow: 2;
   }


   .settingsGroupList {
       display: flex;
       flex-flow: row wrap;

   }

   /* item */
   .settingsGroup {
       display: block;
       margin: 5px;
       padding: 2px;
   }

   .settingsGroup .settingsGroupBody {
       display: block;
       flex-grow: 1;
       color: black;
       flex-shrink: 2;
       text-align: left;       
   }

   .flex {
       display: flex;
       flex-flow: row wrap;
   }

   .settingsGroup .settingsGroupHeader {
       display: block;
       font-weight: bold;
       width:100%;
       background: lightgray;
       padding: 10px;
       text-align: left;
   }

   .setting {
       display: inline-block;
       margin: 10px;
       vertical-align: top;
   }

   .settingsGroup .settingDescription {
       display: inline-block;
       margin: 3px 0 0 5px;
       font-size: .8em;
       vertical-align: top;
       
   }

   .settingsGroup .settingValue {
       flex-grow: 1;
       text-align: left;
       display: inline-block;
       margin-left: 10px;
       
   }        


   .settingValue input {       
       flex-shrink: 1;
   }

   .settingValue .designLogoSml {
       margin: 3px;
       display: block;
       text-align: left
   }

   .settingValue .designLogoSml img {
       margin: 3px;
       max-width: 150px;
       box-shadow: 0px 0px 2px black;
       display: inline-block;
       text-align: center;
       margin-left: auto;
       margin-right: auto;
       background: black
   }

   .settingValue .designLogoLrg img {
       margin: 3px;
       max-width: 480px;
       box-shadow: 0px 0px 2px black;
       display: inline-block;
       text-align: center;
       margin-left: auto;
       margin-right: auto;
   }

   .hidden {
       display: none;
   }

   .logoTypeLabel {
       width: 120px;
       display: block;
       font-size: .7em;
   }

   .horsep {
       border-top: 1px solid lightgray;
       display:inline-block;
       margin:auto;
       height:10px;
       width:95%;
       text-align:center;
   }

   .digit2 {
       width: 51px;
   }
   .digit3 {
       width: 76px;
   }

   input[type=checkbox] {
       transform: scale(1.3);
       margin-right: 10px;     
       margin-bottom: 3px;
   }

   .logo-input{
        display: inline-flex;
        margin: 20px 50px;
    }

    .logo-lrg {
        width: 300px;
        
    }

    .logo-sml {
      width: 80px;
      border-radius: 15px;
      /* background-color: #f0f1f5; */
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      
      img {
        max-width: 100%;
        /* border-radius: 50%; */
      }
    }

    .section-group-title{
        margin: 15px 10px;
        text-align: left;
        background: rgb(231, 231, 231)
    }

    input.w-s{
        width:100px;
    }
    input.w-m{
        width:350px;
    }
    input.w-l{
        width:500px;
    }

.invalid{
    color:red;
}

</style>
