<template>
    <div>
      <h2 class="h3  mar-i-5">{{section}}</h2>
      <div>
        <ul class="mar-i-7">
          <li v-for="(item,i) in items" :key="i">{{ item }}</li>          
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NewsEntry',
    props: 
      ['section', 'items'],
  }
  </script>

  <style>    
  </style>