<template>
  <div>

    <h1 class="h1">Change Log</h1>

    <div class="mar-b-7">
      <h1 class="h1">3/19/2025</h1>        
      <NewsEntry :section="'Added'" :items="[
        'Added color pickers on Colors page for easy editibility.',
        'Added filter and search capabilities to Item Mappings page.',
        ''
      ]" />       
    </div>

    <div class="mar-b-7">
      <h1 class="h1">3/13/2025</h1>        
      <NewsEntry :section="'Fixed'" :items="[
        'Entire business logo can now be seen on both the Summary and Full Bidsheet',
        ''
      ]" />       
    </div>

    <div class="mar-b-7">
      <h1 class="h1">2/20/2025</h1>        
      <NewsEntry :section="'Added'" :items="[
        'Supplier-specific colors are now available in the designer and used in BOM output',
        ''
      ]" />       
    </div>

    <div class="mar-b-7">
      <h1 class="h1">2/14/2025</h1>        
      <NewsEntry :section="'Added'" :items="[
        'Added item code placeholder LFT functionality and documetation',
        'Added help description for Materials settings '
      ]" />
       <NewsEntry :section="'Fixed'" :items="[        
        'First quote after an supplier item code change now always uses the latest supplier code',
        'Removed unused items from the Materials default settings',
        'Fixed or improved numerous units of measure for settings'
      ]" />
    </div>


    <div class="mar-b-7">
      <h1 class="h1">2/11/2025</h1>        
      <NewsEntry :section="'Added'" :items="[
        'Moved Dashboard content to Support tab',        
        'Help articles now support links to one-another for Wiki-style browsing',
      ]" />
    </div>

    <div class="mar-b-7">
      <h1 class="h1">2/10/2025</h1>        
      <NewsEntry :section="'Added'" :items="[
        'A Help Center with searchable content is available under the Dashboard',
        'The What\'s New content has been moved under the Dashboard as a Change Log',
      ]" />
    </div>

    <div class="mar-b-7">
      <h1 class="h1">2/6/2025</h1>  
      <NewsEntry :section="'Fixed'" :items="[
        'Project location now reliably accepting input from the Google suggestion box.',
      ]" />
      <NewsEntry :section="'Added'" :items="[
        'Supplier Admins can now approve projects in the Engineering Review states.',
        'Added a link to feedback and support form for beta users\' convenience!'
      ]" />
     </div>
    <div class="mar-b-7">
      <h1 class="h1">2/5/2025</h1>  
      <NewsEntry :section="'Fixed'" :items="[
        'Galvanized Purlin Sell Multipler setting name and description is much more clear for suppliers.',
        'Materials settings page now saves selected setting values.',        
      ]" />
      <NewsEntry :section="'Added'" :items="[
        'Project state can be advanced from the project page.',
        'Projects show thier history, so you can review who did what and when!',
      ]" />

    </div>

    <div class="mar-b-7">
      <h1 class="h1">2/4/2025</h1>  
      <NewsEntry :section="'Added'" :items="[
        'Must supplier settings now have clear description!',
      ]" />

    </div>

    <div class="mar-b-7">
      <h1 class="h1">1/31/2025</h1>      
      <NewsEntry :section="'Fixed'" :items="[
      'Project List action menus do not open',
      'Project location picker is not near the input field'
    ]" />      
    </div>
    
    
  

  </div>
</template>


<script>
import NewsEntry from '@/components/NewsEntry.vue';

export default {
  name: 'ChangeLog',  
  methods:{
    
  },
  components:{
    NewsEntry
  }
}
</script>
