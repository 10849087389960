<template>
  
  <div class="grid" style="grid-template-columns: auto auto; gap:20px" >

    <section class="border-all pad-3">
      <span class="h1 block">Projects ({{ projectTargetCount }})</span>
      <div>
        <!-- <input class="inline" style="" type="text" placeholder="CSV project IDs" v-model="prjIdInput"> -->
      </div>
      <div>
        <label @click="copySql" style="cursor: pointer;">Copy template to clipboard</label>
        <input style="grid-column: span 1;" type="text" placeholder="CSV project Refs" v-model="prjRefInput">
      </div>
      <div class="" style="display:inline-block;width:50%">
        <label>
          Action Menu:
        </label>
        

        <div class="checkradio-items-block">
          <div class="checkradio-item" v-for="action in Object.values(action.project)">
            <input :id="action.id" type="checkbox"  @change="prjAddAction"/>
            <label :for="action.id">{{action.name}}</label>
          </div>
        </div>          

      </div>

      <div class="" style="display:inline-block;width:50%;vertical-align: top;">
        <button class="btn block" @click="prjListRun" :disabled="!projectCanRun">Run</button>
        <label>
          Action sequence per project:
        </label>
        <table>
          <tbody>            
            <tr v-for="(prjAction, i) in projectActions">
              <td>{{i+1}})</td>
              <td>{{prjAction}}</td>
            </tr>
          </tbody>
        </table>
        
      </div>
    </section>



    <section class="border-all pad-3">
      <span class="h1 block">Cache</span>
      <div class="" style="display:inline-block;width:50%">
        <label>
          Action Menu:
        </label>
        <div class="checkradio-items-block">
          <div class="checkradio-item" v-for="action in Object.values(action.cache)">
            <input :id="action.id" type="checkbox"  @change="cacheAddAction"/>
            <label :for="action.id">{{action.name}}</label>
          </div>
        </div>
          
      </div>

      <div class="" style="display:inline-block;width:50%;vertical-align: top;">
        <button class="btn block" @click="cacheRun">Run</button>
        <label>
          Actions:
        </label>
        <table>
          <tbody>            
            <tr v-for="(cacheAction, i) in cacheActions">
              <td>{{i+1}})</td>
              <td>{{cacheAction}}</td>
            </tr>
          </tbody>
        </table>
        
      </div>
    </section>

    <section></section>

    <section class="border-all pad-3">
      <span class="h1 block">Third Party Resources</span>
      <table class="table-1 lines-b" style="">
        <thead>
          <tr>
            <th>
              Service
            </th>
            <th>
              Vendor
            </th>
            <th>
              Link
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="link in links" class="border-bs hover-bg-shaded-2" style="cursor:pointer;">
            <td class="" @click="openLink(link.url)" >
              {{ link.service }}
            </td>
            <td class="" @click="openLink(link.url)" >
              {{ link.vendor }}
            </td>
            <td >
              <button class="btn btn-small" @click="copyToClipboard(link.url)">Copy</button> <a class="text-link" style="font-size: var(--fs-2" :key="link.url" @click="openLink(link.url)">{{link.url}}</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-for="link in links" class="" style="width:50%">
        
      </div>
    </section>

  </div>
</template>

<script>
import api from '@/api';
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapGetters } from 'vuex';

const prjRefInputStorageName = 'admin_prjRefs'
export default {
  data(){
    return {
      links: [
        {
          service: "Secret Management",
          url: "onepassword://open/Steel%20Ninja",
          vendor: "1Password"
        },
        {
          service: "Error Logging",
          url: "https://steel-ninja.sentry.io/",
          vendor: 'Sentry'
        },
        {
          service: "User Management",
          url: "https://manage.auth0.com/dashboard/us/steel-ninja/",
          vendor: 'Auth0'
        },
        {
          service: "Cloud Files",
          url: "https://netorgft17456000-my.sharepoint.com/my",
          vendor: 'OneDrive'
        },
        {
          service: "Back-End",
          url: "https://console.cloud.google.com/welcome?invt=Abttcw&project=metalninja&authuser=2",
          vendor: 'Google Clould'
        },
        {
          service: "Front-End",
          url: "https://app.netlify.com/sites/steelninja/overview",
          vendor: 'Netlify'          
        }
      ],
      prjIdInput: '',
      prjRefInput: '',      
      csvChar:',',
      action:{
        project:{
          backupQuotesFolder: {id:'backupQuotesFolder', name:'backup quotes folder'},
          deleteQuotesFolder: {id:'deleteQuotesFolder',name: 'delete quotes folder'},
          quote: {id:'quote', name:'quote'},
          deleteProjectFolder: {id:'deleteProjectFolder',name: 'delete project folder'},
        },
        cache:{
          projects: {id:'projects', name: 'projects'},
          businesses: {id:'businesses', name: 'businesses'},
          users: {id:'users', name: 'users'}
        }
      },
      projectActions: [],
      cacheActions: []
    }
  },
  computed:{
    ...mapState('contextModule', ['current']),
    ...mapGetters('contextModule', ['userIsSysAdmin']),
    projectCanRun(){
      return this.projectTargetCount>0 && this.projectActions.length>0
    },
    projectTargetCount(){
      // if(this.prjIdInput)
        // return this.splitAndClean(this.prjIdInput).length
      if(this.prjRefInput)
        return this.splitAndClean(this.prjRefInput).length
      return 0;
    }
  },
  mounted(){

  },
  async created(){
    await ContextLogic.initContext(this);
    if(!this.userIsSysAdmin)
      ContextLogic.logout();
    const adminProjList = localStorage.getItem(prjRefInputStorageName)
    this.prjRefInput = adminProjList;

  },
  methods: {
    async copyToClipboard(url){
      // clear clipboard
      await navigator.clipboard.writeText('');
      // Copy the link to clipboard
      await navigator.clipboard.writeText(url);
    },  
    openLink(url){
      window.open(url, '_blank')
    },
    async copySql(){
      await navigator.clipboard.writeText("SELECT string_agg(reference,',')  FROM [ninja-dev].[dbo].[EntityProjects] ep  left join Projects p on ep.projectId = p.id  where businessid = SUPPLIER_ID");
    },
    cacheAddAction(e){
      const action = e.target.id;
      if(!action)
        return;
      if(e.target.checked)
          this.cacheActions.push(action);
      else
        this.cacheActions.splice(this.cacheActions.indexOf(action),1);
    },
    async cacheRun(){
      this.cacheActions.forEach(async (action) => {
        await this.cacheRunAction(action);
      })
    },
    async cacheRunAction(pAction){
      switch(pAction){
        case this.action.cache.projects.id:
          await api.clearCache_Project();
          break;
        case this.action.cache.businesses.id:
        await api.clearCache_Business();
          break;
        case this.action.cache.users.id:
        await api.clearCache_User();
          break;
      }      
    },
    prjAddAction(e){
      const action = e.target.id;
      if(!action)
        return;
      if(e.target.checked)
          this.projectActions.push(action);
      else
        this.projectActions.splice(this.projectActions.indexOf(action),1);
    },
    prjListRun(){
      localStorage.setItem(prjRefInputStorageName, this.prjRefInput);
      const prjRefs = this.splitAndClean(this.prjRefInput)
      prjRefs.forEach(async (pRef) => {
        await this.prjRun(pRef);
      });      
    },
    async prjRun(pRef){
      this.projectActions.forEach(async (pAction) => {
        await this.prjRunAction(pRef, pAction);
      })
    },
    async prjRunAction(pRef,pAction){
      switch(pAction){
        case this.action.project.backupQuotesFolder.id:
          await api.backupProjectQuotesFolder(pRef);
          break;
        case this.action.project.deleteQuotesFolder.id:
          await api.deleteProjectQuotesFolder(pRef);
          break;
        case this.action.project.quote.id:
          await api.quoteProject(pRef);
          break;
        case this.action.project.deleteProjectFolder.id:
        await api.deleteProjectFolder(pRef);
          break;
      }      
    },
    splitAndClean(csvList){
      csvList.trim();
      
      const parts = csvList.split(this.csvChar);
      let cleanList = [];
      parts.forEach((p) => {
        const entry = p.trim();
        // prevent blanks
        if(!entry)
          return;
        
        // prevent duplicates
        if(cleanList.indexOf(entry)!=-1)
          return;

        cleanList.push(entry)        
      })

      return cleanList;
    }
  }
}
</script>