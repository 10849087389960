<template>
    <div>
        <SnuiWrapper>
            <div>
                 <!-- view presets -->
                <div class="view-mode-content" hidden id="view-mode-content-1">
                <!-- <div class="pad-bs-5">view mode 1 dependant content</div> -->
                </div>

                <div class="view-mode-content">
                    <div class="pad-bs-4">							
                        <div class="popover1-anchor">
                            <button class="bg-shaded pad-i-4 pad-b-2 size-i-100 color-subdued color-active-normal color-hover-normal" style="border-radius: var(--br-2);" data-popover1-trigger="open" aria-controls="p-250208133348" aria-expanded="false" id="demojs-250208133348-camera-presets-button" >
                                <span class="grid gap-4" style="grid-template-columns: 1fr auto; align-items: center;">
                                    <span class="shift-bs-1" >Camera Presets</span>
                                    <span>
                                        <Icon aria-hidden="true" focusable="false" name="webcam-video" custom-class="icon icon-size-1 color-active-normal color-hover-normal"/>
                                    </span>
                                </span>
                            </button>
                            <div id="p-250208133348" class="popover1 attach-outside-is align-be click-outside-close" style="inline-size: max-content; padding-inline-end: calc(var(--sp-5) * 2);" data-popover1-state="closed" role="dialog" aria-labelledby="p-250208133348-title">

                                <div class="box-shadow-1 mar-0 pad-0 color-context-neutral-light" style="border-radius: var(--br-4); overflow: hidden;">
                                    <div class="grid gap-4 border-be pad-i-4 pad-b-4" style="grid-template-columns: 1fr auto; align-items: center;">
                                        <div>
                                            <h3 class="h5" id="p-250208133348-title">Camera Angle</h3>
                                        </div>
                                        <div>
                                            <button class="block" data-popover1-trigger="close" aria-controls="p-250208133348" aria-expanded="false">
                                                <span class="sr-only">Close Popover</span>
                                                <Icon aria-hidden="true" focusable="false" name="delete-1" custom-class="icon block icon-size-1"/>
                                            </button>
                                        </div>
                                    </div><!-- grid -->
                                    <div class="pad-4" >
                                        <div class="grid gap-3" style="grid-template-columns: repeat(3, 1fr); grid-template-rows: repeat(3, 1fr);" id="demojs-250128151311-view-preset-buttons" >
                                            <div>
                                                <button @click="_showBackLeftPerspective(cameraAngles.bl)" class="icon-text-button-2" :class="{active : currentCameraAngle == cameraAngles.bl}">
                                                    <span class="sr-only">Back Left</span>
                                                    <Icon aria-hidden="true" focusable="false" name="line-arrow-diagonal-upper-left-large" custom-class="icon icon-size-1 rotate-180"/>
                                                </button>
                                            </div>
                                            <div>
                                                <button @click="_showBack(cameraAngles.b)" class="icon-text-button-2" :class="{active : currentCameraAngle == cameraAngles.b}">
                                                    <span>Back</span>
                                                </button>
                                            </div>
                                            <div>
                                                <button @click="_showBackRightPerspective(cameraAngles.br)" class="icon-text-button-2" :class="{active : currentCameraAngle == cameraAngles.br}">
                                                    <span class="sr-only">Back Right</span>
                                                    <Icon aria-hidden="true" focusable="false" name="line-arrow-diagonal-upper-left-large" custom-class="icon icon-size-1 rotate-270"/>
                                                </button>
                                            </div>
                                            <div>
                                                <button @click="_showLeft(cameraAngles.l)" class="icon-text-button-2" :class="{active : currentCameraAngle == cameraAngles.l}">
                                                    <span>Left</span>
                                                </button>
                                            </div>
                                            <div>
                                                <div class="icon-text-button-2-spacer"></div>
                                            </div>
                                            <div>
                                                <button @click="_showRight(cameraAngles.r)" class="icon-text-button-2" :class="{active : currentCameraAngle == cameraAngles.r}">
                                                    <span>Right</span>
                                                </button>
                                            </div>
                                            <div>
                                                <button @click="_showFrontLeftPerspective(cameraAngles.fl)" class="icon-text-button-2" :class="{active : currentCameraAngle == cameraAngles.fl}">
                                                    <span class="sr-only">Front Left</span>
                                                    <Icon aria-hidden="true" focusable="false" name="line-arrow-diagonal-upper-left-large" custom-class="icon icon-size-1 rotate-90"/>
                                                </button>
                                            </div>
                                            <div>
                                                <button @click="_showFront(cameraAngles.f)" class="icon-text-button-2" :class="{active : currentCameraAngle == cameraAngles.f}">
                                                    <span>Front</span>
                                                </button>
                                            </div>
                                            <div>
                                                <button @click="_showFrontRightPerspective(cameraAngles.fr)" class="icon-text-button-2" :class="{active : currentCameraAngle == cameraAngles.fr}">
                                                    <span class="sr-only">Front Right</span>
                                                    <Icon aria-hidden="true" focusable="false" name="line-arrow-diagonal-upper-left-large" custom-class="icon icon-size-1"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div><!-- box -->
                        
                            </div><!-- popover1 -->
                        </div><!-- popover anchor -->
                    </div>
                </div>
            </div>  
        </SnuiWrapper>
       
       
    </div>
</template>


<script>
import SnuiWrapper from '@/components/SnuiWrapper.vue';
import { CORE, cameraModes} from '../js/_spec.js'
import VisibilityToggle from './visibilityToggle.vue'
import { ToggleButton } from 'vue-js-toggle-button' //https://www.npmjs.com/package/vue-js-toggle-button
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  components:{
    VisibilityToggle,
    ToggleButton,
    SnuiWrapper
  },
  data: ()=>{
      return {            
          CORE,
          showViewingAngles:false,
          activeCameraPreset: null,
          cameraAngles:{
            bl: "backLeft",
            b: "back",
            br: "backRight",
            l: "left",
            r: "right",
            fl: "frontLeft",
            f: "front",
            fr: "frontRight"
          }
      }
  },
  computed:{
    currentCameraAngle(){
        return this.activeCameraPreset;
    }
  },
  methods:{
    ...mapActions('designModule',[
      'showFront',
      'showBack',
      'showLeft',
      'showRight',
      'showFrontLeftPerspective',
      'showFrontRightPerspective',
      'showBackLeftPerspective',
      'showBackRightPerspective',
      'showToggleLayers',
      'showQuoteLayer',
    ]),
    ...mapMutations('designModule',[
      'setDetail',
    ]),
    setActiveCameraPreset(angle){
        this.activeCameraPreset = angle;
    },
    updateAngleData(angle){
        // hide angle views
        this.showViewingAngles=false;

        // set active camera angle
        this.setActiveCameraPreset(angle)
    },
    _showFront(angle){
        this.showFront()
        this.updateAngleData(angle);
    },
    _showBack(angle){
        this.showBack()
        this.updateAngleData(angle);
    },
    _showLeft(angle){
        this.showLeft()
        this.updateAngleData(angle);
    },
    _showRight(angle){
        this.showRight()
        this.updateAngleData(angle);
    },
    _showFrontLeftPerspective(angle){
        this.showFrontLeftPerspective()
        this.updateAngleData(angle);
    },
    _showFrontRightPerspective(angle){
        this.showFrontRightPerspective()
        this.updateAngleData(angle);
    },
    _showBackLeftPerspective(angle){
        this.showBackLeftPerspective()
        this.updateAngleData(angle);
    },
    _showBackRightPerspective(angle){
        this.showBackRightPerspective()
        this.updateAngleData(angle);
    },
  },
  mounted(){

  }
  
}
</script>

<style scoped>

</style>