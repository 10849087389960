<template>
    <div>
        <SnuiWrapper>
        <button class="icon-text-button-1" :class="{'active' : visible}" v-on:click="toggle">
            <Icon aria-hidden="true" focusable="false" name="visible" custom-class="icon icon-size-1 active-show"/>
            <Icon aria-hidden="true" focusable="false" name="visual-blind" custom-class="icon icon-size-1 active-hide"/>
            <span>{{text}}</span> 
        </button>
        </SnuiWrapper>
    </div>
</template>

<script>

import SnuiWrapper from '@/components/SnuiWrapper.vue';
import {mapGetters, mapMutations} from 'vuex'

export default {
  props: ['text', 'layer'],
  components:{
    SnuiWrapper
  },
  data: function(){
      return {
      };
  },
  computed:{
    ...mapGetters('designModule',[
        'currentLayers'
    ]),
      visible(){
          let visible = this.currentLayers[this.layer];
          return visible;
      }
  },
  methods: {
    ...mapMutations('designModule',[
        'toggleLayer'
    ]),
    toggle: function(){
        this.toggleLayer(this.layer);          
    },
    initLayerIconButtons(){
        let iconTextButton1s = document.querySelectorAll('.icon-text-button-1');
        
        // add click event listeners to toggle active on and off
        iconTextButton1s.forEach(button => {
            button.addEventListener('click', function() {
                button.classList.toggle('active');
            });
        });
    },
  },
  mounted(){
    this.initLayerIconButtons();
  }
}
</script> 

<style scoped>

</style>


