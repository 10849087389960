

<template>
  <div class="pad-bs-4">
    <div class="treeWrapper">
      <!-- <div id="expandCollapseAll" >
        <div @click="expandAll" style="display:inline-block"><font-awesome-icon icon="fa-duotone fa-chevron-down" size="1x" title="Expand all" /></div>
        <div @click="collapseAll" style="display:inline-block"><font-awesome-icon icon="fa-duotone fa-chevron-up" size="1x" title="Collapse all" /></div>
      </div> -->
      <div v-if="componentTree.expanded">
        <!-- <div class='allObjects lvl3-el2-visual scrollable' v-if="mode === 'focus'">
            <CompPicker :comps="componentTreeData" v-on:contextMenu="showContextMenu"/>                    
        </div>  -->
        <div class='allObjects' style="height: 100%" v-if="mode === 'tree'">
            <treeObjectList ref="treeList"  :comps="componentTreeData" v-on:contextMenu="showContextMenu"/>                    
        </div> 

      </div>
    </div>

  <!-- This slot allows component options to always be to the right of the variable-width component tree-->
    <slot></slot> 
  </div>

  <!-- <div class="subPanel position flex-container non-scrollable">
    <div class="treeWrapper flex-container non-scrollable lvl1-el2-visual" >
      <div id="expandCollapseAll" >
        <div @click="expandAll" style="display:inline-block"><font-awesome-icon icon="fa-duotone fa-chevron-down" size="1x" title="Expand all" /></div>
        <div @click="collapseAll" style="display:inline-block"><font-awesome-icon icon="fa-duotone fa-chevron-up" size="1x" title="Collapse all" /></div>
      </div>
      <div v-if="componentTree.expanded" class="" style="height: 100%">
        <div class='allObjects lvl3-el2-visual scrollable' v-if="mode === 'focus'">
            <CompPicker :comps="componentTreeData" v-on:contextMenu="showContextMenu"/>                    
        </div> 
        <div class='allObjects lvl3-el2-visual scrollable' style="height: 100%" v-if="mode === 'tree'">
            <treeObjectList ref="treeList"  :comps="componentTreeData" :indent="false" v-on:contextMenu="showContextMenu"/>                    
        </div> 

      </div>
    </div> -->

  <!-- This slot allows component options to always be to the right of the variable-width component tree-->
    <!-- <slot></slot> 

    
  </div> -->
</template> 

<script>

import treeObjectList from './treeObjectList.vue'
import componentOptions from './componentOptions.vue'
import CompPicker from './compPicker.vue';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
export default {
  
  components:{
    treeObjectList,
    componentOptions,
    CompPicker
},
  data: ()=>{
      return {
          mode: 'tree', 
      };
  },  
  computed:{
    ...mapGetters('designModule', [
      'componentTree',
      'componentTreeData'
    ]),
      title(){
          return this.$store.getters.componentTreeTitle
      },      
      showOptions:{
        get(){          
          return this.$store.state.showOptionsPanel;
        }
      },
      isModeList:{
        get(){
          return this.mode === 'list';
        }
      },
      isModeWalls:{
        get(){
          return this.mode === 'walls';
        }
      },
      isModeTypes:{
        get(){
          return this.mode === 'types';
        }
      },      
      // getComponentTreeRoot:{
      //   get() {
      //   return this.$store.getters.componentTreeData
      // }
      //},
      areCollisions(){
            return this.$store.getters.collisions.length>0;
        },
        collisionCount(){
            return this.$store.getters.collisions.length;
        },
        collisionQuoteText(){
            let count = this.$store.getters.collisions.length;
            let msg = '';
            //return "Fix collisions to quote";
            if(count===1){
                msg = count+" collision!";
            }
            else{
                msg = count+" collisions!";
            }
            return msg;
        },
  },
  created: function(){
  },
  mounted: function(){
    console.log('tree mounted')
  },
  methods: {
    ...mapMutations('designModule',[
      'resolveCollisions',
      'toggleComponentTree',
      'getContextMenuData',
    ]),
    expandAll() {
      this.$refs.treeList.expandAll();
    },
    collapseAll(){
      this.$refs.treeList.collapseAll();
    },
    resolveCollisions(){
            this.resolveCollisions(); 
        },
    toggleExpanded() {
      this.toggleComponentTree();
    },
    showContextMenu: function(id){
      this.$emit('getContextMenuData', id)
    },
    hovered: function(){
    }
  }
}
</script> 

<style scoped>

/* 
.collapse{
  cursor: pointer;
  }

.expand{
  display: inline-block;
  cursor: pointer;
}
.object.selected{
  background:white;
}
.object.collided{
  background: salmon;
}

.modeOption{
  padding: 4px;
  margin: 0px;
  width:49%;
  background: #4c78b9;
  cursor: pointer;
  color: silver;
  display: inline-block;
  text-align:center;
}

.modeOption:hover{
background-color: #5e92df;
}

.modeOptions{
  display:inline-block;  
  padding:4px;
  width:100%
}

.modeSelected{  
  background: #4c78b9;
  border-bottom: 2px solid white;
  color: white;
}

div.categoryHeader{
  font-weight: bold;
}

div.category{
  padding:4px;  
}

div.objectsByType{
    display: inline-block;
    border-radius: 4px;
    padding:4px;
}

div.objectsByWall {
    display: inline-block;
    border-radius: 4px;
    padding:4px;
}

div.allObjects {
    display: inline-block;
    border-radius: 4px;    
    overflow-y: auto;
    max-height: 80vh;
    width:100%;
}

div.position {
  left: 10px;
}

div.treeWrapper{  
  vertical-align: top; 
  position:relative;
  height:100%;
}


#expandCollapseAll{
  position:absolute;
  top: 0px;
  right: 20px;
  z-index: 100;
  display:inline-block;
  width:fit-content
} */
</style>


