<template>
  <button class="mobile-menu-button-1 collapse-toggle" v-on="$listeners" @click="routeTo" :class="{ active: isActive }"  >
    
      <slot></slot>
    
  </button>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'MobileMenuLink',
  props: {
    routePath: String,
    query: Object,
    activeRoutes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      linkRoute: null
    };
  },
  computed: {
    isActive() {
      return this.linkIsActive();
    }
  },
  methods: {
    ...mapMutations('contextModule', ['refresh']),
    
    linkIsActive() {
      console.log('linkIsActive?', this.routePath)
      if (!this.$route) return false;
      // If activeRoutes prop is provided, check if current route matches any pattern
      if (this.activeRoutes.length > 0) {
        console.log(this.activeRoutes, this.$route.name);
        return this.activeRoutes.some((x) => x == this.$route.name);
      }
      // Fall back to exact route matching if no activeRoutes provided
      return this.alreadyHere();
    },

    alreadyHere() {
      return this.$route.path == this.routePath &&
        (typeof this.query == 'undefined' ||
          JSON.stringify(this.$route.query) == JSON.stringify(this.query));
    },

    routeTo(e) {
      // if (!this.linkRoute)
      //   this.$emit('click2', e)
      if (this.alreadyHere())
        this.refresh();
      else if(this.routePath)
        this.$router.push({ path: this.routePath, query: this.query });
    }
  },
  mounted() {
    if(this.routePath)
      this.linkRoute = this.$router.matcher.match(this.routePath);
  }
};
</script>

<style scoped>

</style>