<template>
  <div>
    <div id="helpArticle" ref="helpArticle" v-html="html" @click.capture="handleClick"></div>

  </div>
</template>

<script>
import markdownit from 'markdown-it'

export default {
  name: 'HelpArticle',
  props: ['md'],
  data(){
    return {
      html: '',
      styleRules:[
        {tag:'h1',classes: ['h1']},
        {tag:'h2',classes: ['h2']},
        {tag:'h3',classes: ['h3']},
        {tag:'h4',classes: ['h4']},
        {tag:'h5',classes: ['h5']},
        {tag:'h6',classes: ['h6']},
        {tag:'li',classes: ['li']},
        {tag:'ul',classes: ['ul']},
        {tag:'p',classes: ['p']},
        {tag:'small',classes: ['small']},
        {tag:'strong',classes: ['strong']},
        {tag:'em',classes: ['em']},
        {tag:'a',classes: ['text-link']},

      ]
    }
  }  ,
  methods:{
    applyClasses(){
      const root = this.$refs.helpArticle;
      
      this.styleRules.forEach((rule)=>{
        root.querySelectorAll(rule.tag).forEach(e => e.classList.add(rule.classes.join(' ')));
      })      
    },
    generateHtmlFromMd(md){
      console.log(md);
      let markdown = markdownit();
      this.html = markdown.render(md);
      this.$nextTick(()=>{
        this.applyClasses();
        //this.processLinks();
      })
    },
    handleClick(e) {
      if (e.target.tagName === 'A') {
        e.preventDefault()        
        
        let href = e.target.attributes['href'].value;
        // if this is a link to a markdown article,
        if(href.startsWith('md:'))
        {
          // distill the page id
          const articleId = href.replace('md:','');
          this.$emit('articleLinkClicked',articleId);
        }
        else{
          window.location = href;
        }
      }
    }
  },
  mounted(){    
    
  },
  watch:{
    md(newVal, oldVal) {      
      this.generateHtmlFromMd(newVal);
    }
  },
  components:{
    
  }
}
</script>
