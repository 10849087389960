import * as THREE from 'three' 
import ColorHelper from './colorHelper.js';
import { CORE, buildingCodes, impactTypes } from '../_spec.js';
import ConstraintHelper from './ConstraintHelper.js';
import DesignManager from '../design.js';

export default class DesignHelper {
    static componentCount = 1;
    static currentVersion = 3;


  static forEachStructureInSystem(systemDesign, callback){
    if(!systemDesign)
      return;
  
    systemDesign.components.forEach((structure) => {
      callback(structure);
    });  
  }

  static applyColorChangeToStructure(impact, structureDesign){
    console.log(structureDesign);
    if(!structureDesign)
      return;    
    
      const appearance = structureDesign.getAppearance();
    console.log(appearance);
    if(!appearance)
      return;


      switch(impact.change.name){
        case impactTypes.colorRoof: appearance.roof=(impact.design.roof); break;
        case impactTypes.colorWall: appearance.wall= (impact.design.wall); break;
        case impactTypes.colorWainscot: appearance.wainscoting = (impact.design.wainscoting); break;
        case impactTypes.colorTrim: 
                  appearance.trimColorMode = CORE.modes.trimColor.single

                  appearance.trim = (impact.design.trim); break; // we know impact appearance color trim  mode is single
        case impactTypes.colorTrimCornerAndBase: 
                  appearance.trimColorMode = CORE.modes.trimColor.separate
      
                  appearance.trims.corner = (impact.design.trims.corner); 
                  break;
        case impactTypes.colorTrimDoor: 
                  appearance.trimColorMode = CORE.modes.trimColor.separate
                  
                  appearance.trims.door = (impact.design.trims.door); 
                  break;
        case impactTypes.colorTrimDownspout: 
                  appearance.trimColorMode = CORE.modes.trimColor.separate
                  
                  appearance.trims.downspout= (impact.design.trims.downspout); 
                  break;
        case impactTypes.colorTrimEaveAndRake: 
                  appearance.trimColorMode = CORE.modes.trimColor.separate
                
                  appearance.trims.eaveAndRake= (impact.design.trims.eaveAndRake); 
                  break;
        case impactTypes.colorTrimWalksAndWindows: 
                  appearance.trimColorMode = CORE.modes.trimColor.separate
                  
                  appearance.trims.walksAndWindows = (impact.design.trims.walksAndWindows); 
                  break;
      }


  }

    static getDefaultBayDesign(i, framelineCount, bayComponent){
        return {
            type: bayComponent,
            openHeight:0,
            openWall:false,
            index: i,
            id: 0,//this.getBayId(i, framelineCount),
            components: []
        }
    }

    // helper method to initialize a new design manager
    // and its loaded components for testing 
    static initDesignManagerAndLoadedComponents(parentStructure, childStructure){
      ConstraintHelper.root = undefined;
      const dm = new DesignManager();
      this.initLoadedComponent(parentStructure, dm.version);
      this.initLoadedComponent(childStructure, dm.version);
    }

    static updateComponentVersion(c, newVersion){
      if(!c) return;

      c.v = newVersion
      this.updateComponentsVersion(c.components, newVersion);
    
    }
    static updateComponentsVersion(components, newVersion){
      if(!components) return;
        components.forEach((c)=>{
          this.updateComponentVersion(c, newVersion);
        });
    }


    
    static initLoadedComponent(c, version){

      // we can't init nothin' round here
      if(!c) return;

      c.v = version; // apply the designer version to every component design for the sake of components migrating themselves
      DesignHelper.addFunctions(c);
      c.selected=false;
      this.initLoadedComponents(c.components, version);
    }

    static initLoadedComponents(components, version){
      if(!components) return;
        components.forEach((c)=>{
          this.initLoadedComponent(c, version);
        });
    }


    static bayIdLeftMost = 'bay_left';
    static bayIdRightMost = 'bay_right';

    static getBayId(i, framelineCount){
        if(i===0)
            return this.bayIdLeftMost;
        if(i===framelineCount-1)
            return this.bayIdRightMost;
        return `bay_${i}`;
    }
    
    static isDesignMultiStructure(design){
        let test = design.getAllMainStructures().length
        return design.getAllMainStructures().length>1;
      }

    static addFunctions(design){

        function objectMatchesQuery(childDes, desQuery){
          let match = null;
          for(var d in desQuery){
            if(match===false) // if this is already not a match, 
              continue; // short-circuit
  
            if(!desQuery.hasOwnProperty(d)) continue; // only interrogate the candidate for the query's own properties
            // if the candidate doesn't have a matching property
            if(!childDes.hasOwnProperty(d)) return false;
            
            let check;
            let dqType= typeof desQuery[d]
            let cdType= typeof childDes[d];
            // if this property is not the same type for both objects
            if(dqType !== cdType)
              return false;
            
            // if this property is an object
            if(dqType === 'object'){
              check = objectMatchesQuery(childDes[d], desQuery[d]);
            }
            else
            {
              let qValue = desQuery[d];
              let tValue = childDes[d];
              check = tValue === qValue; // the result of the comparison for this property
            }
            
            if(match===null)
              // just take the result
              match = check;
            else
              // AND the result of the comparison
              match = match && check;
          }
          return match;
        }
  
        function getChildById(id){
          let des = null;
          design.components.forEach((childDes)=>{
            if(des!==null)
              return;
            if(childDes.id === id)
              des = childDes;
          });
          return des;
        }
  
        function getFirstChildMatchingDesign(desQuery){
          let first = null;
          design.components.forEach((childDes)=>{
            if(first!==null)
              return;
            let match = null;
  
            match = objectMatchesQuery(childDes, desQuery);
  
            if(match)
              first = childDes;          
          });
          return first;
        }
  
        design.getAllMatchingDesigns = (desQuery, recursive) => {
          let matches = [];
          if(!design.components)
            return matches;        
          design.components.forEach((childDes)=>{
            
            if(recursive)
              matches.push(...childDes.getAllMatchingDesigns(desQuery, recursive));
            
            let match = null;
  
            for(var d in desQuery){
              if(match===false)
                continue; // short-circuit
              if(!desQuery.hasOwnProperty(d)) continue;
              let check = childDes[d] === desQuery[d]; // the result of the comparison for this property
              if(match===null)
                // just take the result
                match = check;
              else
                // AND the result of the comparison
                match = match && check;
            }
  
            if(match)
              matches.push(childDes);
          });
          return matches;
        }
  
        design.getChildDesign = getChildById;
        
        design.getFrontWall = () => getFirstChildMatchingDesign({
          type: CORE.components.fsw,
        });
        design.getLeftEndBays = (recursive) => design.getAllMatchingDesigns({
          type: CORE.components.bayEndLeft
        }, recursive);
        design.getRightEndBays = (recursive) => design.getAllMatchingDesigns({
          type: CORE.components.bayEndRight
        }, recursive);
        design.getFrontSideBays = (recursive) => design.getAllMatchingDesigns({
          type: CORE.components.baySideFront
        }, recursive);
        design.getBackSideBays = (recursive) => design.getAllMatchingDesigns({
          type: CORE.components.baySideBack
        }, recursive);
        design.getBackWall = () => getFirstChildMatchingDesign({
          type: CORE.components.bsw,
        });  
        design.getLeftWall = () => getFirstChildMatchingDesign({
          type: CORE.components.lew,
        });
        design.getRightWall = () => getFirstChildMatchingDesign({
          type: CORE.components.rew,
        });
        design.getTransversePartitions = () => design.getAllMatchingDesigns({
          type: CORE.components.tvp,
        });
        design.getAppearance = () => getFirstChildMatchingDesign({
          type: CORE.components.appearance,
        });
        design.getOptions = () => getFirstChildMatchingDesign({
          type: CORE.components.options,
        });
        design.getFrame = () => getFirstChildMatchingDesign({
          type: CORE.components.frame        
        });
        design.getRoof = () => getFirstChildMatchingDesign({
          type: CORE.components.roof
        });      
        // design.getFrontFrameSide =()=> getFirstChildMatchingDesign({
        //   type: CORE.components.frameSide,
        //   dir:{
        //     rel:0
        //   } 
        // });
        // design.getRightFrameSide =()=> getFirstChildMatchingDesign({
        //   type: CORE.components.frameSide,
        //   dir:{
        //     rel:1
        //   } 
        // });
        // design.getBackFrameSide =()=> getFirstChildMatchingDesign({
        //   type: CORE.components.frameSide,
        //   dir:{
        //     rel:2
        //   } 
        // });
        // design.getLeftFrameSide =()=> getFirstChildMatchingDesign({
        //   type: CORE.components.frameSide,
        //   dir:{
        //     rel:3
        //   } 
        // });
        design.getFoundation =()=> getFirstChildMatchingDesign({
          type: CORE.components.foundation
        });
        design.getAllWindows =(recursive)=> design.getAllMatchingDesigns({
          type: CORE.components.window,
        },recursive);
        design.getAllEmptyFramedOpenings=(recursive)=> design.getAllMatchingDesigns({
          type: CORE.components.emptyFramedOpening,
        },recursive);
        design.getAllLouverVents=(recursive)=> design.getAllMatchingDesigns({
          type: CORE.components.louverVent,
        },recursive);
        design.getAllWalkins3x7s =(recursive)=> design.getAllMatchingDesigns({
          type: CORE.components.doorWalkin3o7o,
        },recursive);
        design.getAllWalkins4x7s =(recursive)=> design.getAllMatchingDesigns({
          type: CORE.components.doorWalkin4o7o,
        },recursive);
        design.getAllWalkins6x7s =(recursive)=> design.getAllMatchingDesigns({
          type: CORE.components.doorWalkin6o7o,
        },recursive);
        design.getAllCommGlassDoors =(recursive)=> design.getAllMatchingDesigns({
          type: CORE.components.doorCommGlass,
        },recursive);
        design.getAllRollupDoors =(recursive)=> design.getAllMatchingDesigns({
          type: CORE.components.doorRollup,
        },recursive);
        design.getAllHangarDoors =()=> design.getAllMatchingDesigns({
          type: CORE.components.doorHangar,
        });
        design.getAllSlidingDoors =(recursive)=> design.getAllMatchingDesigns({
          type: CORE.components.doorSliding,
        },recursive);
        design.getAllSectionalDoors =(recursive)=> design.getAllMatchingDesigns({
          type: CORE.components.doorSectional,
        },recursive);
        design.getAllWideOpenings =()=> design.getAllMatchingDesigns({
          type: CORE.components.wideOpening,
        });
        design.getCupolas =(recursive)=> design.getAllMatchingDesigns({
          type: CORE.components.cupola,
        },recursive);
        design.getLeftEndSkirt =()=> getFirstChildMatchingDesign({
          type: CORE.components.skirtLeft        
        });
        design.getRightEndSkirt =()=> getFirstChildMatchingDesign({
          type: CORE.components.skirtRight
        });
        design.getPorch =()=> getFirstChildMatchingDesign({
          type: CORE.components.porch
        });
        design.getStructure = () =>
        getFirstChildMatchingDesign({
          type: CORE.components.structure
        });
        design.getSystem = () =>
        getFirstChildMatchingDesign({
          type: CORE.components.system
        });
        let des = design;
        design.getWallFramedOpenings =() => {
          let components = [];
          // 2024.03.06  Walls now have bays that have framed openings
          components.push(...des.getAllWindows(true));
          components.push(...des.getAllEmptyFramedOpenings(true));
          components.push(...des.getAllLouverVents(true));
          components.push(...des.getAllRollupDoors(true));
          components.push(...des.getAllSectionalDoors(true));
          components.push(...des.getAllSlidingDoors(true));
          components.push(...des.getAllWalkins3x7s(true));
          components.push(...des.getAllWalkins4x7s(true));
          components.push(...des.getAllWalkins6x7s(true));
          components.push(...des.getAllCommGlassDoors(true));
          // walls still have wide openings and hangar doors
          //components.push(...des.getAllWideOpenings());
          //components.push(...des.getAllHangarDoors());
          return components;
        }
        
        design.fixUntypedOptionComponent =  () => {
          let o = getFirstChildMatchingDesign({
            insulband: false
          });
          if(o)
            o.type = CORE.components.options;
          return o;
        }      
        
          design.getJobSpecs = () => getFirstChildMatchingDesign({
              type: CORE.components.jobspecs
          });
          design.getAllSystems = () =>
          design.getAllMatchingDesigns({
            type: CORE.components.system
          });
        design.getAllMainStructures = () =>
          design.getAllMatchingDesigns({
            type: CORE.components.structure
          },true);
  
        
        design.getAllStructures = () => {
  
          let structures = 
            design.getAllMatchingDesigns({
              type: CORE.components.structure
            });
            structures.push(...
            design.getAllMatchingDesigns({
              type: CORE.components.porch
            })
            )
            structures.push(...
              design.getAllMatchingDesigns({
                type: CORE.components.leanTo
              })
              )
            
          return structures;
        }
        design.getAllMainAndLeanToStructures = () => {
          let structures = 
            design.getAllMatchingDesigns({
              type: CORE.components.structure
            });
            structures.push(...
            design.getAllMatchingDesigns({
              type: CORE.components.leanTo
            })
            )

          return structures;
        }

        design.getAllStructuresOfAllSystems = () => {
          let structures = []
          let systems = design.getAllSystems();
          if (!systems)
            return []

          systems.forEach((sys) => {
            let strs = sys.getAllStructures()
            structures.push(...strs)
          })
            
          return structures;
        }
  
        design.getConstraints = () => 
        design.getAllMatchingDesigns({
          type: CORE.components.constraint
        })
  
      }
      

      static GetBuiltOutShedStructureDesign(structureType, structureDesign, initData){
        if(!initData)
        initData = {
          width: 40,
          length: 60,
          height: 12, //default 12
          pitch: 2,
          frameType: CORE.frame.types.bolt
        }
        let eaveHeightInches = initData.height*12
        // frame
        let frameDesign = this.getDefaultFrameDesign(initData);        
        this.addComponent(structureDesign, frameDesign);
        
        let appearanceDesign = this.getDefaultAppearance(initData);
        this.addComponent(structureDesign, appearanceDesign);
  
        let optionDesign = DesignHelper.getDefaultOptions(initData);
        this.addComponent(structureDesign, optionDesign);
  
        let jobSpecsDesign = this.getDefaultJobSpecs(initData);
        this.addComponent(structureDesign, jobSpecsDesign);

        if(structureType === CORE.components.leanTo){
          // walls should be open by default on sheds
          let leftWallDesign = this.getShedLeftWallDesign(eaveHeightInches, false, initData.wainscot);
          this.addComponent(structureDesign, leftWallDesign);
          let rightWallDesign = this.getShedRightWallDesign(eaveHeightInches, false, initData.wainscot);
          this.addComponent(structureDesign, rightWallDesign);
          let frontWallDesign = this.getShedFrontWallDesign(eaveHeightInches, false, initData.wainscot);
          this.addComponent(structureDesign, frontWallDesign);
          let backWallDesign = this.getShedBackWallDesign();
          this.addComponent(structureDesign, backWallDesign);
        }
        
        let roofDesign = this.getShedRoofDesign(initData);
        this.addComponent(structureDesign, roofDesign);
        
        let foundation = this.getDefaultFoundationDesign();
        this.addComponent(structureDesign, foundation);
        
    }

    static getCurrentComponentCount() {
      return this.componentCount;
    }
    static getCurrentVersion() {
      return this.currentVersion;
    }
    
    static getNextComponentId(){
        return ++this.componentCount;
      }
  
    static GetBuiltOutPorchStructureDesign(structureDesign, initData){
      if(!initData)
      initData = {
        width: 15,
        length: 15,
        height: 10, //default 12
        frameType: CORE.frame.types.bolt
      }
      let eaveHeightInches = initData.height*12
      // frame
      let frameDesign = this.getDefaultFrameDesign(initData);        
      this.addComponent(structureDesign, frameDesign);
      
      let appearanceDesign = this.getDefaultAppearance();
      this.addComponent(structureDesign, appearanceDesign);
  
      let optionDesign = DesignHelper.getDefaultOptions();
      this.addComponent(structureDesign, optionDesign);
  
      let jobSpecsDesign = this.getDefaultJobSpecs(initData);
      this.addComponent(structureDesign, jobSpecsDesign);
  
      // walls should be open by default on sheds
      let leftWallDesign = this.getDefaultLeftWallDesign(eaveHeightInches);
      this.addComponent(structureDesign, leftWallDesign);
      let rightWallDesign = this.getDefaultRightWallDesign(eaveHeightInches);
      this.addComponent(structureDesign, rightWallDesign);
      let frontWallDesign = this.getDefaultFrontWallDesign(eaveHeightInches);
      this.addComponent(structureDesign, frontWallDesign);
      let backWallDesign = this.getDefaultBackWallDesign();
      this.addComponent(structureDesign, backWallDesign);
      
      let roofDesign = this.getDefaultRoofDesign();
      this.addComponent(structureDesign, roofDesign);
      
      let foundation = this.getDefaultFoundationDesign();
      this.addComponent(structureDesign, foundation);
      
  }
  
    static GetBuiltOutStructureDesign(structureDesign, initData){
        if(!initData)
        initData = {
          width: 40,
          length: 60,
          height: 12, //default 12
          frameType: CORE.frame.types.bolt,
          rotRad: 0
        }
        let eaveHeightInches = initData.height*12;
          // frame
          let frameDesign = this.getDefaultFrameDesign(initData);        
          this.addComponent(structureDesign, frameDesign);
  
          let appearanceDesign = this.getDefaultAppearance(initData);
          this.addComponent(structureDesign, appearanceDesign);
  
          let optionDesign = DesignHelper.getDefaultOptions(initData);
          this.addComponent(structureDesign, optionDesign);
  
          let jobSpecsDesign = this.getDefaultJobSpecs(initData);
          this.addComponent(structureDesign, jobSpecsDesign);
  
          // walls
          let leftWallDesign = this.getDefaultLeftWallDesign(eaveHeightInches);
          this.addComponent(structureDesign, leftWallDesign);
          let rightWallDesign = this.getDefaultRightWallDesign(eaveHeightInches);
          this.addComponent(structureDesign, rightWallDesign);
          let frontWallDesign = this.getDefaultFrontWallDesign(eaveHeightInches);
          this.addComponent(structureDesign, frontWallDesign);
          let backWallDesign = this.getDefaultBackWallDesign(eaveHeightInches);
          this.addComponent(structureDesign, backWallDesign);
          
          let roofDesign = this.getDefaultRoofDesign();
          this.addComponent(structureDesign, roofDesign);
          
          let foundation = this.getDefaultFoundationDesign();
          this.addComponent(structureDesign, foundation);
          
      }
      

      
    static getDefaultFrontWallDesign(eaveHeight, openWall=false){
        return {
          type: CORE.components.fsw,
          dir:{
            rel:0, // front wall
          },
          openWall,
          height: eaveHeight,
          wainscot:{
            enabled: false,
            height: 42,
            color: '0x8f8f8f'          
          },
          trim:{},
          components: []
        }
      }
  
    static getShedFrontWallDesign(eaveHeight, openWall=false, wainscot){
        return {
          type: CORE.components.fsw,
          typeDetail: CORE.sides.frontSide,
          dir:{
            rel:0, // front wall
          },
          openWall,
          height: eaveHeight,
          wainscot,
          trim:{},
          components: []
        }
      }
  
    static getDefaultBackWallDesign(eaveHeight, openWall=false){
        return {
          type: CORE.components.bsw,
          dir:{
            rel:2, // back wall
          },
          openWall,
          height: eaveHeight,
          wainscot:{
            enabled: false,
            height: 42,
            color: '0x8f8f8f'         
          },
          trim:{},
          components: []
        }
      }
      
      static getShedBackWallDesign(eaveHeight, openWall=true){
        return {
          type: CORE.components.bsw,
          typeDetail: CORE.sides.backSide,
          dir:{
            rel:2, // back wall
          },
          openWall,
          height: eaveHeight,
          wainscot:{
            enabled: false,
            height: 42,
            color: '0x8f8f8f'         
          },
          trim:{},
          components: []
        }
      }
  
      static getDefaultRightWallDesign(eaveHeight, openWall=false){
        return {
          type: CORE.components.rew, 
          typeDetail: CORE.sides.rightEnd,
          dir:{
            rel:1, // right wall
          },
          openWall,
          height: eaveHeight,
          openSupport: CORE.endWallColumns.columnsToGround.value,
          wainscot:{
            enabled: false,
            height: 42,
            color: '0x8f8f8f'         
          },
          trim:{},
          baySpacing: {
            center: 20,
            nonCenter: 20,
            mode: 'manual'
          },
          frameLine: 0, // inset 1 bay.
          components: []
        }
      }
      static getShedRightWallDesign(eaveHeight, openWall=false, wainscot){
        return {
          type: CORE.components.rew, 
          typeDetail: CORE.sides.rightEnd,
          dir:{
            rel:1, // right wall
          },
          openWall,
          height: eaveHeight,
          openSupport: CORE.endWallColumns.columnsToGround.value,
          wainscot,
          trim:{},
          baySpacing:{
            center: 20,
            nonCenter: 20,
            mode: 'manual'
          },
          frameLine: 0, // inset 1 bay.
          components: []
        }
      }
  
      static getDefaultLeftWallDesign(eaveHeight, openWall=false){
        return {
          type: CORE.components.lew, 
          typeDetail: CORE.sides.leftEnd,
          dir:{
            rel:3, // left wall
          },
          openWall,
          height: eaveHeight,
          openSupport: CORE.endWallColumns.columnsToGround.value,
          wainscot:{
            enabled: false,
            height: 42,
            color: '0x8f8f8f'
          },
          trim:{},
          baySpacing: {
            center: 20,
            nonCenter: 20,
            mode: 'manual'
          },
          frameLine: 0, // inset 1 bay.
          components: []
        }
      }
      static getShedLeftWallDesign(eaveHeight, openWall=false, wainscot){
        return {
          type: CORE.components.lew, 
          typeDetail: CORE.sides.leftEnd,
          dir:{
            rel:3, // left wall
          },
          openWall,
          height: eaveHeight,
          openSupport: CORE.endWallColumns.columnsToGround.value,
          wainscot,
          trim:{},
          baySpacing:{
            center: 20,
            nonCenter: 20,
            mode: 'manual'
          },
          frameLine: 0, // inset 1 bay.
          components: []
        }
      }
  
      static getDefaultPartitionDesign(frameLine = 0){
        return {
            id: 0,
            type: CORE.components.tvp,
            wainscot:{
                enabled: true,
                height: 36,
                color: '0x8f8f8f'
            },
            lineType: CORE.frame.lineTypes.standard,
            openHeight:0,
            openWall:false,
            baySpacing: {
                center: 20,
                nonCenter: 20,
                mode: 'auto'
            },
            components: [],
            frameLine: frameLine,
            sheetingLeft: true,
            sheetingRight: false,
        }
    }

      static getDefaultRootDesign(){
        return {
          id: null,
          type: CORE.components.design,
          components: [],        
        }
      }
  
      static getDefaultSystemDesign(){
        return {
          id: null,
          type: CORE.components.system,
          components: [],
          pos: new THREE.Vector3(),
          rotRad:0,
          name: 'Building System 1'
        }
      }
  
      static getDefaultStructureDesign(initData){
        if(!initData){
          initData = {
            rotRad:0
          }
        }
        return {
          type: CORE.components.structure,
          name: "Main Structure",
          components: [],
          pos:{
            x:0,
            z:0
          },
          sides:{
            baySpacing:{
              specialLeftSize:10,
              maxSize: 25,
              specialRightSize:null,
            }
          },
          wainscot:{
            height:42
          },
          rotRad: initData.rotRad// Math.PI/2
         
        }
      }

      static getLeanToStructureSpecificDesign(initData){
        initData = initData ? initData : {}
        initData.name=  initData.name ? initData.name : " Lean-to"
        initData.dim= initData.dim ? initData.dim : {}
        initData.dim.heightOffset = initData.dim.heightOffset ? initData.dim.heightOffset : 0

        // initData.left=  initData.left ? initData.left : {}
        // initData.left.wrapValid= initData.left.wrapValid ? initData.left.wrapValid : false
        // initData.left.wrapEnabled =initData.left.wrapEnabled ? initData.left.wrapEnabled : false
        // initData.right =initData.right ? initData.right : {}
        // initData.right.wrapValid =initData.right.wrapValid ? initData.right.wrapValid : false
        // initData.right.wrapEnabled =initData.right.wrapEnabled ? initData.right.wrapEnabled : false
        
        
        if(initData.wainscot){
          // avoid a  reference since saving removes circular references (anything it has seen before)
          initData.wainscot = {
            enabled: initData.wainscot.enabled ? initData.wainscot.enabled : false,
            height: initData.wainscot.height ? initData.wainscot.height : 42,
          } 
        }
        else{
          // avoid null too
          initData.wainscot = {} 
        }
  
        return {
          type: CORE.components.leanTo,
          name:initData.name,
          components: [],
          pos:{
            x:0,
            z:0
          },
          dim: initData.dim,
          //left: initData.left,
          //right: initData.right,
          // backWall: false,
          // openToMain: true,
          //isPorch: true,
          sides:{
            baySpacing:{
              specialLeftSize:null,
              maxSize: 25,
              specialRightSize:null,
            }
          },
          wainscot: {
            enabled: initData.wainscot.enabled,
            height: initData.wainscot.height,
            color: initData.wainscot.color
          },
          rotRad: 0// Math.PI/2
        }
      }
  
      static getPorchStructureSpecificDesign(initData){
        initData = initData ? initData : {}
        initData.name=  initData.name ? initData.name : " Porch"
        initData.left=  initData.left ? initData.left : {}
        initData.left.wrapValid= initData.left.wrapValid ? initData.left.wrapValid : false
        initData.left.wrapEnabled =initData.left.wrapEnabled ? initData.left.wrapEnabled : false
        initData.right =initData.right ? initData.right : {}
        initData.right.wrapValid =initData.right.wrapValid ? initData.right.wrapValid : false
        initData.right.wrapEnabled =initData.right.wrapEnabled ? initData.right.wrapEnabled : false
        initData.dim= initData.dim ? initData.dim : {}
        initData.dim.heightOffset = initData.dim.heightOffset ? initData.dim.heightOffset : 0
        
        
        if(initData.wainscot){
          // avoid a  reference since saving removes circular references (anything it has seen before)
          initData.wainscot = {
            enabled: initData.wainscot.enabled ? initData.wainscot.enabled : false,
            height: initData.wainscot.height ? initData.wainscot.height : 42,
          } 
        }
        else{
          // avoid null too
          initData.wainscot = {} 
        }
  
        return {
          type: CORE.components.porch,
          name:initData.name,
          components: [],
          pos:{
            x:0,
            z:0
          },
          dim: initData.dim,
          left: initData.left,
          right: initData.right,
          backWall: false,
          openToMain: false,
          //isPorch: true,
          sides:{
            baySpacing:{
              specialLeftSize:null,
              maxSize: 25,
              specialRightSize:null,
            }
          },
          wainscot: {
            enabled: initData.wainscot.enabled,
            height: initData.wainscot.height,
            color: initData.wainscot.color
          },
          rotRad: 0// Math.PI/2
        }
      }
  
  
  
  
      static getDefaultRoofDesign(){
        return {
          type: CORE.components.roof,
          roofType: CORE.roof.types.gable, // default gable
          roofFrameType: CORE.roof.frameTypes.bypass, // main structure default is bypass 
          pitch: 1, // default 1
          components: []
        }
  
        
      }
  
      
      static getShedRoofDesign(initData){
        initData = initData ? initData: {};
        initData.pitch = initData.pitch? initData.pitch: 1;
        return {
          type: CORE.components.roof,
          roofType: CORE.roof.types.slope, // default gable
          roofFrameType: CORE.roof.frameTypes.bypass, // main structure default is bypass 
          pitch: initData.pitch // default 1
        }
  
        
      }
  
      static getDefaultFrameDesign(initData){
        if(!initData)
          initData = {};
        if(!initData.width)
            initData.width = 40;
        if(!initData.length)
          initData.length = 60;
        if(!initData.height)
          initData.height = 12;
        if(!initData.frameType)
          initData.frameType = CORE.frame.types.bolt;
  
        return {
          //type: CORE.frame.types.bolt,
          type: CORE.components.frame,
          frameType: initData.frameType,         
          sides:{
            baySpacing:{
              specialLeftSize:null,
              maxSize: 25,
              specialRightSize:null,
            }
          },        
          width: initData.width, //default 40
          length: initData.length, // default 60
          height: initData.height // default 12
        }
      }
  
      static getDefaultOptions(initData){
        return {
          type: CORE.components.options,
          gutters: true,
          dripTrim: false,
          wrapTubePorchCols: true,
          touchUpPaint: true,
          jambCounterFlashing: false,
          ohJambLiner: true,
          tackyTapeRoof: false,
          plusGirtsCTL: false,
          galvBeams: initData.options ? initData.options.galvBeams : false,
          galvPurlins: initData.options ? initData.options.galvPurlins : false,
          dripTrim: initData.options ? initData.options.dripTrim : false,
          insulation: {
              wall:'VR.3_(R_10)',
              roof:'VR.3_(R_10)',
              chickWire: false,
              energySaver: {
                  type: 'none',
                  framelineStart: 0,
                  framelineStop: 3
              }
          },            
        }
      }
  
      static getDefaultJobSpecs(initData){
  
        let design = {
          type: CORE.components.jobspecs,
          buildingCode: buildingCodes.ibc15,
          seals: true
        }
  
        if(initData){
  
          if(initData.buildingCode)
            design.buildingCode = initData.buildingCode;
          if(!initData.seals)
            design.seals = initData.seals
        }
  
        return design;
      }
  
      static getDefaultAppearance(initData){
          if(initData.appearance)
            return initData.appearance;

          return {
            type: CORE.components.appearance,
            roof: ColorHelper.first().id,
            wall: ColorHelper.first().id,
            wainscoting: ColorHelper.first().id,
            trim: ColorHelper.first().id 
          }          
      }
  
      static getPorchStructureDesign(parent, l,w,h, pitch, frameType, roofFrameType){
        
        let structureDesign = this.getPorchStructureSpecificDesign(); 
            
        this.addComponent(null, structureDesign);
        // foundation
        
        let frameDesign = this.getPorchFrameDesign(l,w,h, frameType);        
        this.addComponent(structureDesign, frameDesign);
        // walls
        //let leftWallDesign = this.getDefaultLeftWallDesign();
        //this.addComponent(structureDesign, leftWallDesign);
        //let rightWallDesign = this.getDefaultRightWallDesign();
        //this.addComponent(structureDesign, rightWallDesign);
        //let frontWallDesign = this.getDefaultFrontWallDesign();
        //this.addComponent(structureDesign, frontWallDesign);
        // no back wall since this is a porch
        
        let roofDesign = this.getPorchRoofDesign(pitch, roofFrameType);
        this.addComponent(structureDesign, roofDesign);
        let foundation = this.getDefaultFoundationDesign();
        this.addComponent(foundation);
        return structureDesign;
      }
  
      static getDefaultFoundationDesign(){
        return {
          id: -2,
          type: CORE.components.foundation,
          height: 6,        
        }	
      }
  
      static getPorchRoofDesign(pitch, roofFrameType){
        if(Util.isUndefined(roofFrameType))
          roofFrameType = CORE.roof.frameTypes.flush
        return {
          type: CORE.components.roof,
          roofType: CORE.roof.types.slope, // broken
          roofFrameType: roofFrameType, // porch default it flush
          pitch: pitch
        }
      }
  
      static getPorchFrameDesign(l,w,h, frameType){
        return {
          type: CORE.components.frame,
          frameType: frameType, // broken
          sides:{
            baySpacing:{
              specialLeftSize:null,
              maxSize: 20,
              specialRightSize:null,
            }
          },   
          width: w, //default 40
          length: l, // default 60
          height: h // default 12
        }
      }
  
      static getNullComponentDesign(){
        return {
          type: CORE.components.null,
          components: []
        }
      } 

      
    static getJobSpecs(design)
    {
      let js = design.getJobSpecs();
      return js;     
    }

    static bayComponents = [CORE.components.bayEndLeft, CORE.components.bayEndRight, CORE.components.baySideFront, CORE.components.baySideBack];
    static isTypeBay(des){
      return this.bayComponents.includes(des.type)
    }

    static allowedFramedOpeningsOnBays = [CORE.components.doorWalkin3o7o, 
      CORE.components.doorWalkin4o7o, 
      CORE.components.doorWalkin6o7o,
      CORE.components.doorCommGlass,
      CORE.components.doorRollup,
      CORE.components.doorOverhead,
      CORE.components.doorSectional,
      CORE.components.doorSliding,
      CORE.components.window]

    static hasJobSpecs(design) 
    {
      return DesignHelper.getJobSpecs(design) !== null
    }

    
    static addComponent(p,c){
      // takes a parentDesign and childDesign

      // we need some unique, portable identity that is not an object, for the sake of threejs object picking via the userData.
      if (c.id && (c.type === CORE.components.structure || c.type == CORE.components.porch || c.type == CORE.components.leanTo))
      {
        console.log('structure addComponent');

      }      
      else
      {
        c.id = DesignHelper.getNextComponentId();
      }

      c.collided=false; // must be here now or Vue won't detect it after adding later
      c.selected=false; // must be here now or Vue won't detect it after adding later      
      DesignHelper.addFunctions(c);
      if(p){
        c.parent={
          id: p.id
        };
        
        p.components.push(c);
      }
      else
      {
        c.parent={
          id: null
        };
      }
      return c.id;
    }
}