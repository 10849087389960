<template>
    <div class="item-mapping">
      <div class="flex justify-between items-center mb-4">
        <h1 class="text-2xl font-bold">Item Mapping</h1>
      </div>
      <div class="mar-4">        
        <span class="block" style="margin-left: 20px" v-if="incompatibleUnitCount > 0">Unit conflicts: {{ incompatibleUnitCount }}</span>
        <span class="block" style="margin-left: 20px" v-if="unmappedNinjaItems().length > 0">
            <div class="dot active color-context-neutral-dark mar-is-2" style="vertical-align:middle; --this-size: var(--sp-4); --this-color: var(--color-fg-subdued); --this-active-color: var(--color-fg-vibrant);" ></div>
            Unmapped Items: {{ unmappedNinjaItems().length }} / {{ sysItems.length }} Total Items
        </span>
      </div>

      <div class="filters">
        <input type="text" v-model="searchQuery" placeholder="Search items...">      
      </div>
      
      <table>
        <thead>
          <tr>
            <th @click="updateSort('ninjaCode')">Ninja Item</th>
            <th @click="updateSort('description')">Ninja Description</th>
            <th @click="updateSort('category')">Ninja Category</th>
            <th @click="updateSort('supplierCode')">Supplier Item</th>
            <th style="border:none;background:transparent;padding:0;width:0;white-space: nowrap;"></th>
          </tr>
        </thead>
        <tbody>

          <tr v-for="sysItem in filteredAndSortedItems" :key="sysItem.id">            
            <td>{{ sysItem.ninjaCode  }}<StatusIndicator :ref="'si_map_'+sysItem.id" :successText="'Saved'"></StatusIndicator></td>
            <td>{{ sysItem.description }}</td>
            <td>{{ categoryTypes[sysItem.category] }}</td>
            <td style="width:50%">
                
                <AutocompleteSelect 
                :id="'map_'+sysItem.id" 
                :ref="'map_'+sysItem.id"
                @change="mapSupplierItemToNinjaItem" 
                style="display:inline" 
                :options="getOptionsForNinjaId(sysItem)" 
                :selectedOption="getSupplierItemForAutocompleteDropdown(sysItem.id)"
                ></AutocompleteSelect>
                
            </td>
            <td style="border:none;background:transparent;padding:0;width:0;white-space: nowrap;">
              <span v-if="ninjaItemMappingIncompatible(sysItem)" :title="`Unit mismatch detected. (${sysItem.ninjaCode}: ${itemUnits[sysItem.unit]}, ${getSupplierItemIdForNinjaItemId(sysItem.id).supplierCode}: ${itemUnits[getSupplierItemIdForNinjaItemId(sysItem.id).unit]})`">
                  <icon name="warning-triangle" custom-class="icon icon-size-1 inline-block color-warning shift-be-1" />
                </span>
            </td>
          </tr> 
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import MessageModal from '@/components/MessageModal.vue'
  import ContextLogic from '@/lib/contextLogic';
  import { mapState, mapActions, mapGetters } from 'vuex';
  import api from '@/api';
  import ProgressSpinner from '@/components/ProgressSpinner.vue';
  import userSettingDropdown from '@/components/user-setting-dropdown.vue';
  import StatusIndicator from '@/components/StatusIndicator.vue';
  import VModal from 'vue-js-modal'
  import Vue from 'vue';
  import AutocompleteSelect from '@/components/AutocompleteSelect.vue';
  Vue.use(VModal, {dialog:true});
  
  export default {
    components: {
      MessageModal,
      ProgressSpinner,
      userSettingDropdown,
      StatusIndicator,
      AutocompleteSelect
    },
    data() {
      return {
        itemUnits:{
          1: 'Ea',
          2: 'Lft',
          3: 'Sqft',
          4: 'Lft_Lb',
          5: 'Roll',
          6: 'Pair'
        },
        items: [
          { id: 1, systemCode: 'MS001', entityCode: 'CC001', description: 'Item 1', pricePerUnit: 100, weightPerUnit: 50 },
          { id: 2, systemCode: 'MS002', entityCode: 'CC002', description: 'Item 2', pricePerUnit: 200, weightPerUnit: 75 },
          { id: 3, systemCode: 'MS003', entityCode: 'CC003', description: 'Item 3', pricePerUnit: 150, weightPerUnit: 60 },
        ],
        categoryTypes: {
          0: "Taper_Frame",
          1: "Plate",
          2: "Beam",
          3: "Tube",
          4: "Weld_Plates",
          5: "EaveStrut",
          6: "Purlin",
          7: "Import_Purlin",
          8: "Clips",
          9: "Insulation",
          10: "Panel_Galvalume",
          11: "Panel_Kynar",
          12: "Panel",
          13: "Trim",
          14: "Fasteners",
          15: "Accessories"
        },
        searchQuery: '',
        sortBy: '',
        sortOrder: 'asc',
        showMessage: false,
        message: '',
        issues: [],
        uploadInProgress: false,
        sysItems: [],
        supItems: [],
        mappings: [],
        supplierMappedItems: {},
        ninjaMap: {},
        sortedSupplierOptions: null,
        isEditMode: false,
        originalMappings: null,
        incompatibleUnitCount: 0
      }
    },
    computed: {
      ...mapState('contextModule', ['currentContext', 'mode']),      
      filteredAndSortedItems() {
        let filtered = this.sysItems.filter(item => {
          // Check all direct values on the ninja item
          const directMatch =  Object.values(item).some(value => 
            value.toString().toLowerCase().includes(this.searchQuery.toLowerCase())
          )

          // Also check the supplier code matched to the ninjaItem
          const supplierItemId = this.getSupplierItemIdForNinjaItemId(item.id);
          let supplierCode = '';
          if (supplierItemId !== -1) {
            const supplierItem = this.getSupplierItemById(supplierItemId);
            supplierCode = supplierItem ? supplierItem.supplierCode : '';
          }
          else{
            supplierCode = 'Not Configured'
          }
 
          const supplierMatch = supplierCode.toLowerCase().includes(this.searchQuery.toLowerCase());

          return directMatch || supplierMatch;
        })

        if(this.sortBy == 'supplierCode'){
          return filtered.sort((a, b) => {

            let a_supplierItemId = this.getSupplierItemIdForNinjaItemId(a.id);
            let a_supplierItem = a_supplierItemId == -1 ? 'zzz' : this.getSupplierItemById(a_supplierItemId).supplierCode;

            let b_supplierItemId = this.getSupplierItemIdForNinjaItemId(b.id);
            let b_supplierItem = b_supplierItemId == -1 ? 'zzz' : this.getSupplierItemById(b_supplierItemId).supplierCode;

            let modifier = this.sortOrder === 'asc' ? 1 : -1
            if (a_supplierItem < b_supplierItem) return -1 * modifier
            if (a_supplierItem > b_supplierItem) return 1 * modifier
            return 0
          })
        }

        return filtered.sort((a, b) => {
          let modifier = this.sortOrder === 'asc' ? 1 : -1
          if (a[this.sortBy] < b[this.sortBy]) return -1 * modifier
          if (a[this.sortBy] > b[this.sortBy]) return 1 * modifier
          return 0
        })
        
      }
    },
    methods: {
      ...mapActions('businessModule', ['fetchBusiness',]),
      getSupplierItemForAutocompleteDropdown(sysItemId){
        let supplierItemId = this.getSupplierItemIdForNinjaItemId(sysItemId);
        if(supplierItemId == -1)
          return {name:"-- Not Configured --", value:-1}

        let supplierItem = this.getSupplierItemById(supplierItemId);
        return {name:`${supplierItem.supplierCode} (${supplierItem.description})`, value:supplierItem.supplierItemId} ;
      },
      getOptionsForNinjaId(ninjaItem){
        let category = ninjaItem.category;
        let supplierItems = this.supplierItemsInCategory(category);

        let dropDownOptionList = 
        [
          {name:"-- Not Configured --", value:-1},
          ...supplierItems.map(item => (
            {
              name: `${item.supplierCode} (${item.description})`, 
              value: item.supplierItemId, 
              key: ninjaItem.id+'_'+item.supplierItemId
          })),
        ]

        return dropDownOptionList
      },
      unmappedNinjaItems(){
        return this.sysItems.filter(i => !this.getMappingForNinjaItemId(i.id));
      },
      supplierItemsInCategory(category){
        let supItemHashMap={};
        this.supItems.filter((i) => {
          return i.category == category;
        }).forEach((i)=>{
          if(!supItemHashMap[i.supplierItemId])
          supItemHashMap[i.supplierItemId] = i;
        });
        return Object.values(supItemHashMap);
      },
      
      async mapSupplierItemToNinjaItem(opt){
        let ninjaItemData = opt.id.split("_");
        let systemItemId = Number(ninjaItemData[1]);
        let newSupplierItemId = Number(opt.val);
        console.log('newSupplierItemId', newSupplierItemId)
          
        // refs in a v-for become an array because reasons
        let refName = `si_${opt.id}`
        let si = this.$refs[refName];
        if(si.length==1)
        {
          si  = si[0]
          if(si != undefined)
              si.loading();
        }

        let oldSupplierItemId = -1;
        let mapping = this.getMappingForNinjaItemId(systemItemId);
        // if it's mapped
        if(mapping)
          // store the old supplier Id of the mapping (whether -1 for unmapped or a positive number indicating the supplier item)
          oldSupplierItemId = mapping.supplierItemId;
        
        // if there's a new supplier Id mapped
        if(newSupplierItemId!=-1)
        {
          // check for unit of measure compatibility
          let newSupplierItem = this.getSupplierItemById(newSupplierItemId);
          this.incompatibleUnitCount = this.countIncompatibleMappings();
          let ninjaItem = this.getNinjaItemById(systemItemId);

          // check that the units are the same
          if(ninjaItem.unit != newSupplierItem.unit){
            // Alter about the unit misalignment
              alert(`The units of measure for these items do not match, which can cause project price calculations to be inaccurate. NinjaItem ${mapping.ninjaItem.ninjaCode} requires ${mapping.ninjaItem.sUnit} but supplier item ${newSupplierItem.supplierCode} has a unit of ${newSupplierItem.sUnit}.`)
          }
        }

        let response = await api.updateItems(-1, {systemItemId, oldSupplierItemId, newSupplierItemId });
        if(response.isError){
          si.fail();
        }
        else{
          si.success();
        }

        // refresh our data
        await this.getItemMappingOptions();
        this.$nextTick(()=>{
          // there are arrays and a LOT of dom option tags 
          // without this, VUE loses track of what the latest data says, and the GUI is out of sync
          this.$forceUpdate();
        })
      },
      getSupplierItemIdForNinjaItemId(sysItemId){
        // the mapping is always followed from Ninja item to Supplier item
        // a ninja Item points to a single supplier item
        // a ninja Item cannot point to two supplier items
        let mapping = this.getMappingForNinjaItemId(sysItemId)

        if(!mapping)
          return -1; // sentinel value for unmapped

        return mapping.supplierItemId;
      },
      getNinjaItemById(id){
        return this.sysItems.find((i) => i.id == id);
      },
      getMappingForNinjaItemId(sysItemId){
        return this.mappings.find((m) => m.systemItemId == sysItemId);
      },      
      getSupplierItemById(id){
        return this.supItems.find((i) => i.supplierItemId == id)        
      },
      async getItemMappingOptions(){
        
        let id = this.$route.params.id;
        let mapData = await api.getMappingOptionsForBusiness(id);
        this.sysItems = mapData.sysItems;
        this.supItems = mapData.supItems;
        this.mappings = mapData.mappings;

        // create a hash map of those supplier items that are mapped to anything.
        // this is needed twice for every supplier item (hundreds) for eveny ninja item (hundreds), so it's too expensive to do during render when it is static
        this.supplierMappedItems = {};
        this.supItems.forEach((item) => {
          if(!this.isSupplierItemMappedToAnything(item.supplierItemId))
            return;
          this.supplierMappedItems[item.supplierItemId]=true; // the hash is the whole point
        });

        this.incompatibleUnitCount = this.countIncompatibleMappings();                
      },
      isSupplierItemMappedToAnything(supItemId){
        // this cannot be invoked by anything reactive, per supplier item.         
        let mapping = this.mappings.filter((m) => m.supplierItemId == supItemId)        
        return mapping.length!=0
      },
      countIncompatibleMappings(){
        let count = 0;
        this.mappings.forEach((m) => {
          if(this.incompatibleUnitsMapped(m))
            count++;          
        })
        return count;
      },      
      incompatibleUnitsMapped(mapping){        
        const { systemItemId } = mapping;                
        const ninjaItem = this.getNinjaItemById(systemItemId);
        return this.ninjaItemMappingIncompatible(ninjaItem);
      },
      ninjaItemMappingIncompatible(ninjaItem){
        const mapping = this.getMappingForNinjaItemId(ninjaItem.id);
        if(!mapping)
          return;
        const supplierItem = this.getSupplierItemById(mapping.supplierItemId);
        
        return ninjaItem.unit != supplierItem.unit          
      },
      updateSort(key) {
        if (this.sortBy === key) {
          this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
        } else {
          this.sortBy = key
          this.sortOrder = 'asc'
        }
      },
    },

    async created(){
      await ContextLogic.initContext(this);
      await this.getItemMappingOptions();
    }
  }
  // 351 to end of script
  // 531 to end of file
  </script>
  
  <style scoped>
  .item-management {
    margin: 0 20px;
  }
  
  .actions {
    margin-bottom: 20px;
  }
  
  .filters {
    margin-bottom: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f2f2f2;
    cursor: pointer;
  }
  
  tr:hover {
    /* background-color: #f5f5f5; */
  }
  
  button, input[type="file"] {
    margin-right: 10px;
  }
  
  a {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .unused{
    background:lightgray;
    color: gray;
  }

  /* Layout */
.p-4 { padding: 1rem; }
.px-2 { padding-left: 0.5rem; padding-right: 0.5rem; }
.px-3 { padding-left: 0.75rem; padding-right: 0.75rem; }
.px-4 { padding-left: 1rem; padding-right: 1rem; }
.py-1 { padding-top: 0.25rem; padding-bottom: 0.25rem; }
.py-2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
.mb-1 { margin-bottom: 0.25rem; }
.mb-3 { margin-bottom: 0.75rem; }
.mb-4 { margin-bottom: 1rem; }
.mb-6 { margin-bottom: 1.5rem; }
.mr-2 { margin-right: 0.5rem; }
.space-x-2 > * + * { margin-left: 0.5rem; }
.space-x-4 > * + * { margin-left: 1rem; }

/* Typography */
.text-sm { font-size: 0.875rem; }
.text-xl { font-size: 1.25rem; }
.text-2xl { font-size: 1.5rem; }
.font-medium { font-weight: 500; }
.font-semibold { font-weight: 600; }
.font-bold { font-weight: 700; }
.text-left { text-align: left; }
.text-center { text-align: center; }

/* Colors */
.text-white { color: white; }
.text-red-600 { color: #dc2626; }
.text-green-600 { color: #16a34a; }
.bg-white { background-color: white; }
.bg-gray-50 { background-color: #f9fafb; }
.bg-gray-100 { background-color: #f3f4f6; }
.bg-blue-500 { background-color: #3b82f6; }
.bg-green-500 { background-color: #36d330; }
.bg-red-100 { background-color: #fee2e2; }
.bg-green-100 { background-color: #dcfce7; }

.hover\:bg-blue-600:hover { background-color: #2563eb; }
.hover\:bg-red-200:hover { background-color: #fecaca; }
.hover\:bg-green-200:hover { background-color: #bbf7d0; }

/* Borders */
.border { border: 1px solid #e5e7eb; }
.rounded { border-radius: 0.25rem; }
.rounded-lg { border-radius: 0.5rem; }

/* Layout containers */
.color-manager {
    max-width: 1200px;
    margin: 0 auto;
}

.grid {
    display: grid;
    gap: 1rem;
}

.grid-cols-1 { grid-template-columns: repeat(1, 1fr); }

@media (min-width: 768px) {
    .md\:grid-cols-2 { grid-template-columns: repeat(2, 1fr); }
}

@media (min-width: 1024px) {
    .lg\:grid-cols-3 { grid-template-columns: repeat(3, 1fr); }
}

.col-span-full { grid-column: 1 / -1; }

/* Flex */
.flex { display: flex; }
.items-center { align-items: center; }

/* Table */
.min-w-full { min-width: 100%; }
.overflow-x-auto { overflow-x: auto; }

/* Form elements */
.block { display: block; }
.w-full { width: 100%; }
.w-6 { width: 1.5rem; }
.w-8 { width: 2rem; }
.h-6 { height: 1.5rem; }
.h-8 { height: 2rem; }

/* Color preview boxes */
.color-preview {
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
}

/* Button styles */
button {
    cursor: pointer;
    border: none;
    transition: background-color 0.2s;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Form inputs */
input[type="text"],
input[type="checkbox"] {
    border: 1px solid #e5e7eb;
    border-radius: 0.25rem;
}

input[type="text"]:focus {
    outline: 2px solid #3b82f6;
    outline-offset: -1px;
}

/* Table styles */
table {
    border-collapse: collapse;
    width: 100%;
}

th {
    font-weight: 600;
    text-align: left;
}

td, th {
    padding: 0.5rem 1rem;
    border: 1px solid #e5e7eb;
}

.filters {
  margin-bottom: 20px;
}
  </style>