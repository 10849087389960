<template>
    <div class="">
        <h1 class="h1">Sales Tax</h1>

        <div class="">
            <div class="setting">
                <label for="des_taxes">Default Sales Tax Rate</label>
                <user-setting-dropdown @change="setBusinessSetting" :customStyle="{display:'inline'}"
                                ref="qt_defaultSalesTaxRate"
                                id="qt_defaultSalesTaxRate"
                                :val="qt_defaultSalesTaxRate"
                                name=""
                                :disable="!canUpdateSupplierSettings"
                                :options=enabledTaxOptions>                                        
                </user-setting-dropdown>
                <StatusIndicator ref="si_defaultSalesTaxRate" :successText="'Saved'"></StatusIndicator>
            </div>
            <br/>
            <button class="btn" v-on:click="initNewTaxOption" :disabled="newTaxOption != null">Create New Tax Option</button> <StatusIndicator :ref="'new'" :successText="'Saved'"></StatusIndicator>
            <div v-if="newTaxOption" class="">
                <input v-model="newTaxOption.option.name"  :disabled="!canUpdateSupplierSettings" style=""/> 
                <input type="number" class="digit3" v-model="newTaxOption.option.rate" min="0" :disabled="!canUpdateSupplierSettings" style=""/>%
                <button class="btn btn-small btn-primary" v-on:click="createNewTaxOption">Add</button>
                <button class="btn btn-small btn-secondary" v-on:click="cancelNewTaxOption(newTaxOption.newOptionId)">Cancel</button>
            </div>
        </div>        

        <div class="">
            <Table class="table-1" style="width:fit-content">
                <thead>
                    <tr>              
                        <th style="padding: 0 10px ">Enabled</th>
                        <th>Name</th>
                        <th>Rate</th>
                        <th>Action</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>                    
                    <tr v-for="(to,i) in taxOptions" :key="to.option.id" :style="getStyle(to)">
                        <td style="text-align: center;">
                            <input :disabled="!canUpdateSupplierSettings || to.option.deleted" @change="handleTaxOptionActivation(to)" type="checkbox" v-model="to.option.enabled" >
                        </td>
                        <td>
                            <input type='text' :key="to.option.id + '-name'" v-model="to.option.name" @input="handleTaxOptionNameChange(to)" :disabled="!to.option.enabled || !canUpdateSupplierSettings || to.option.deleted"/> 
                        </td>
                        <td style="padding-right: 20px;">
                            <label class="composite-field">
                                <input :key="to.option.id + '-rate'" type="number" @input="handleTaxOptionRateChange(to)" v-model="to.option.rate" min="0" step=.05 :disabled="!to.option.enabled || !canUpdateSupplierSettings || to.option.deleted" class="digit3 align-end"/>
                                <div class="text">%</div>
                            </label>
                            
                        </td>
                        <td style="text-align: center; display: flex; align-items: center;">
                            
                            <StatusIndicator :style="{'display:none': pendingSave[to.option.reference]}" :ref="'to_'+i" :successText="'Saved'"></StatusIndicator>
                            <div v-if="showDelete(to.option.reference)" @click="handleTaxOptionDelete(to)">
                                <Icon name="delete-1" custom-class="icon-size-2 color-subdued block" />
                            </div>
                            <button v-if="to.showSuccessorPicker" class="btn btn-small btn-secondary" v-on:click="revertTaxOptSuccessor(to)">Cancel</button>
                            
                        </td>
                        <td v-if="to.showSuccessorPicker" class="grid gap-5" style="--grid-template-columns-sm: auto auto;">                            
                            <span class="select" style="display:inline-block">
                                <select id="taxOptions" :value="to.successor" @change="setTaxOptSuccessor(to, $event.target.value)">
                                    <option v-for="opt in enabledTaxOptions" :key="opt.reference" :value="opt.reference">
                                        {{ opt.name }} - {{ opt.rate }}%
                                    </option>
                                </select>
                            </span>
                            <button style="display:inline-block" class="btn btn-small btn-secondary" v-on:click="confirmTaxOptSuccessor(to)">Set Successor</button>
                        </td>
                        <td v-else-if="to.successorConfirmed">
                            <span>Projects currently using {{ to.option.name }} will be updated to use {{ enabledTaxOptions.find(opt => opt.value == to.successor).name }}</span>
                        </td>
                    </tr>
                </tbody>
            </Table>
            </br>
            <span><b>Note:</b> Updating, disabling, or deleting a tax option may result in projects with outdated pricing. 
                Projects with outdated pricing will be flagged with a warning symbol in the Project list, 
                and a manual requote will be required to see accurate pricing. </span>
            </br>
            
        </div>
                
        <ProjectTaxOptionModal :showProjectTaxOptModal.sync="showProjectTaxOptModal" :disabledDeletedTaxOptProjectPairs="disabledOrDeletedTaxOptionProjects" :enabledTaxOptions="projectTaxOptModalDropDownOptions"></ProjectTaxOptionModal>
    </div>
  </template>
  
  <script>
  // Trello Card
  // https://trello.com/c/oZcJQrGJ/52-sales-tax-rate-vs-default-sales-tax-rate
  import StatusIndicator from '@/components/StatusIndicator.vue';
  import { mapState, mapGetters , mapActions, mapMutations } from 'vuex';
  import ContextLogic from '@/lib/contextLogic';
  import Util from '@/lib/utility';
  import api from '@/api';
  import ProjectTaxOptionModal from '@/components/ProjectTaxOptionModal.vue';
  import VModal from 'vue-js-modal'
  import Vue from 'vue'
  import Vuex from 'vuex'
    import UserSettingDropdown from '@/components/user-setting-dropdown.vue';
import Icon from '@/components/Icon.vue';

Vue.use(Vuex)
Vue.use(VModal, {dialog:true});
  export default {
    components: {
        UserSettingDropdown,
        StatusIndicator,
        ProjectTaxOptionModal,
        Icon
    },
    data() {
        return {

            debug: false,
            showProjectTaxOptModal: false,
            disabledOrDeletedTaxOptionProjects:[],
            projectTaxOptModalDropDownOptions: [],
            taxOptions: [],
            newTaxOptionCount: 0,
            newTaxOption: null,        
            isAuthenticated: false,
            showSupplierOnlySetting: false,
            canUpdateBusinessInfo: false,
            canUpdateSupplierSettings: false,
            canUpdateContractorSettings: false,
            qt_defaultSalesTaxRate: "",
            statusRefs: {   // settingName : ref
                "qt_defaultSalesTaxRate": "si_defaultSalesTaxRate",
            },
            taxOptionSavers:[],
            pendingSave:[]
            
        }
    },
    computed:{
      ...mapState('contextModule',[
        'user'
        ,'userBusiness'
        ,'current'
      ]),
      ...mapGetters('contextModule',[
        'userIsSysAdmin'
        ,'userIsSupAdmin'
        ,'userIsContAdmin'
      ]),      
      pendingChanges(){
        let pendingChanges = [];
        let taxOptsToCheck = [];
        taxOptsToCheck = [...this.taxOptions]
        
        taxOptsToCheck.forEach(wrapper => {
            let changes = [];

            if(wrapper.newOptionId){
                changes.push(`Added as new tax option`)
                pendingChanges.push({originalName: wrapper.option.name, changes: changes});
                return;
            }

            let taxName = wrapper.originalName ? wrapper.originalName : wrapper.option.name;
        
            if(wrapper.pendingDisable){
                let enableState = wrapper.option.enabled ? "Enabled" : "Disabled"
                changes.push(`${enableState} ${wrapper.option.name}`)
            }
            if(wrapper.pendingDelete){
                changes.push(`Deleted ${wrapper.option.name}`)
            }
            if(wrapper.pendingNameChange){
                changes.push(`Renamed as ${wrapper.option.name}`)
            }
            if(wrapper.pendingRateChange){
                changes.push(`Updated rate to ${wrapper.option.rate}`)
            }

            if(changes.length > 0)
                pendingChanges.push({originalName: taxName, changes: changes});
        })
        console.log(pendingChanges)
        return pendingChanges;
      },
      enabledTaxOptions() {
        let dropdownOptions = [];
        let enabledRates = this.taxOptions.filter(optWrapper => optWrapper.option.enabled && !optWrapper.option.deleted)

        enabledRates.forEach((optWrapper) =>{
            let nameVal = {name: optWrapper.option.name, value: optWrapper.option.reference, reference: optWrapper.option.reference, enabled: optWrapper.option.enabled, rate: optWrapper.option.rate}
            dropdownOptions.push(nameVal);
        })
        return dropdownOptions;
      },
      currentTaxOptions(){
        return this.taxOptions;
      },
      mode(){
        return this.current.mode
      },
        
      itemManagerRoutePath(){
        let pathParts = this.$route.path.split("/");
        let businessId = pathParts[2];
        return `/${pathParts[1]}/${businessId}/itemManager`
      }

    },
    methods: {
        ...mapActions('pricingModule', ['fetchPricingLevels', 'updatePricingLevels']),
        ...mapActions('businessModule', ['getBusinessSettings','getBusinessSetting', 'updateBusinessInfo', 'fetchBusiness', 'updateBusinessInfo']),
        showDelete(ref){
            return !(this.pendingSave.includes(ref) || this.newTaxOption)
        },
       getStyle(taxOption){
        if(taxOption.pendingDelete) return 'background-color: var(--clr-danger-90); color: var(--clr-danger-50);'
        return '';
       },
       getTaxOptionSaver(taxOptionWrapper){
        if(!taxOptionWrapper.option.reference)            
              console.error(`tax option has no name to use for debounced save`)

        let ref = taxOptionWrapper.option.reference;
        if(!this.taxOptionSavers[ref])
            this.taxOptionSavers[ref]= Util.debounce(this.processTaxOptionChange, 1000);
        console.log('saver for ', ref)
        if(!this.pendingSave.includes(ref))
                this.pendingSave.push(ref);

        return this.taxOptionSavers[ref]
       },
       async refreshData(){
            try {
                console.log('getAllBusinessTaxOptions')
                let taxOpts = await api.getAllBusinessTaxOptions(this.entity.id);
                
                await this.initTaxOpts(taxOpts);
            } catch (error) {
                // Any failed
                console.error(error);
            }        
       },
        async updateBusiness(settingName) {
            let si = this.$refs[this.statusRefs[settingName]];
            if(si != undefined)
                si.loading();

            let response = await this.updateBusinessInfo(this.entity);

            if(response){
                si.fail();
            }
            else{
                si.success();
            }
        },
        async setBusinessSetting(data){
            var val;
            // if the object has a hex property, it's a color setting, 
            if (data.val.hex)
                val = data.val.id; // use color.id for colors (changed week of 2023.11.07)
            else{
                val = `${data.val}`
            }
                
            let settingsGroup = {
                businessId: this.entity.id,
                name: data.id,
                value: val
            }
            let si = this.$refs[this.statusRefs[data.id]];
            if(si != undefined)
                si.loading();

            let response = await api.setBusinessSetting(settingsGroup);

            if(response.isError){
                si.fail();
            }
            else{
                si.success();
            }
        },

        async confirmTaxOptSuccessor(optionWrapper){
            optionWrapper.showSuccessorPicker = false;
            optionWrapper.successorConfirmed = true;

            if(optionWrapper.pendingDelete){
                await this.$modal.show('dialog', {
                    title: 'Confirmation',
                    text: `Are you sure you want to permanently delete "${optionWrapper.option.name}"?`,
                    buttons:[
                        {
                            title: 'Cancel',
                            handler: async () => {
                                this.$modal.hide('dialog')
                                this.revertTaxOptSuccessor(optionWrapper);
                            }
                        },
                        {
                            title: 'Delete',
                            handler: async () => {
                                this.$modal.hide('dialog')
                                this.processTaxOptionChange(optionWrapper);
                            },
                        },
                    ]
                })
            }
            else
                this.processTaxOptionChange(optionWrapper);
        },
        setTaxOptSuccessor(optionWrapper, successor){
            optionWrapper.successor = successor;
        },
        revertTaxOptSuccessor(optionWrapper){
            optionWrapper.successor = null;
            optionWrapper.showSuccessorPicker = false;
            optionWrapper.successorConfirmed = false;
            if(optionWrapper.pendingDisable){
                optionWrapper.pendingDisable = false;
                optionWrapper.option.enabled = !optionWrapper.option.enabled;
            }
            if(optionWrapper.pendingDelete){
                optionWrapper.pendingDelete = false;
                optionWrapper.option.deleted = !optionWrapper.option.deleted;
            }

            this.processTaxOptionChange(optionWrapper);
        },
        async handleTaxOptionActivation(optionWrapper){
            // Sepcial case: If tax option has been disabled and
            // successor is required, but user enables tax option.
            // We need to revert tax option back to original and cancel change request
            if(optionWrapper.pendingDisable || optionWrapper.showSuccessorPicker || optionWrapper.successorConfirmed){
                this.$set(optionWrapper, 'successor', false);
                this.$set(optionWrapper, 'successorConfirmed', false);
                this.$set(optionWrapper, 'showSuccessorPicker', false);
                this.$set(optionWrapper, 'pendingDisable', false);
                this.processTaxOptionChange(optionWrapper);
            }
            else{
                let isCurrentDefaultSalesTaxOption = this.qt_defaultSalesTaxRate == optionWrapper.option.reference;
                if(isCurrentDefaultSalesTaxOption){
                    // Vue's checkbox binding directly reflects the DOM event, overriding your programmatic change. 
                    // To force the checkbox to update visually, you need to use $nextTick() to wait for the DOM update cycle
                    this.$nextTick(() => {
                        this.$set(optionWrapper.option, 'enabled', true);
                    })
                    
                    this.$toast.error(`"${optionWrapper.option.name}" is currently in use as the default sales tax rate. A different tax option must be selected before disabling.`)

                    return false;
                }
                if(this.enabledTaxOptions.length < 1){
                    this.$set(optionWrapper.option, 'enabled', true);
                    this.$toast.error("There must be at least one enabled tax option at all times.")
                    return 
                }
     
                this.$set(optionWrapper, 'pendingDisable', true);
                this.$set(optionWrapper.option, 'userId', this.user.id);

                let saver = this.getTaxOptionSaver(optionWrapper)
                saver(optionWrapper); 
            }
            
        },
        async handleTaxOptionDelete(optionWrapper){
            let isCurrentDefaultSalesTaxOption = this.qt_defaultSalesTaxRate == optionWrapper.option.reference;
            if(isCurrentDefaultSalesTaxOption){
                // this.$nextTick(() => {
                //     this.$set(optionWrapper.option, 'deleted', false);
                // })                
                this.$toast.error(`"${optionWrapper.option.name}" is currently in use as the default sales tax rate. A different tax option must be selected before deleting.`)
                return
            }
            if(this.enabledTaxOptions.length < 1){
                // this.$nextTick(() => {
                //     this.$set(optionWrapper.option, 'deleted', false);
                // })
                this.$toast.error("There must be at least one enabled tax option at all times.")
                return 
            }

            this.$set(optionWrapper.option, 'deleted', true);

            // This seems redundant since pricessTaxOptionChanges calls checkForDependents, 
            // but its necessary if we want to avoid asking for deletion confirmation twice.
            let hasDependents = await this.checkForDependents_Single(optionWrapper);
            if(hasDependents){
                this.$set(optionWrapper, 'pendingDelete', true);
                this.$set(optionWrapper.option, 'userId', this.user.id);

                this.processTaxOptionChange(optionWrapper);
                return;
            }
            
            await this.$modal.show('dialog', {
                title: 'Confirmation',
                text: `Are you sure you want to permanently delete "${optionWrapper.option.name}"?`,
                buttons:[
                    {
                        title: 'Cancel',
                        handler: async () => {
                            this.$modal.hide('dialog')
                            this.$set(optionWrapper.option, 'deleted', false);
                            if(optionWrapper.showSuccessorPicker)
                                this.revertTaxOptSuccessor(optionWrapper);
                        }
                    },
                    {
                        title: 'Delete',
                        handler: async () => {
                            this.$set(optionWrapper, 'pendingDelete', true);
                            this.$set(optionWrapper.option, 'userId', this.user.id);
                            this.$modal.hide('dialog');
                            this.processTaxOptionChange(optionWrapper);
                        },
                    },
                ]
            })
        },
        async handleTaxOptionRateChange(optionWrapper){
            if(!optionWrapper.newOptionId && optionWrapper.option.rate == optionWrapper.originalRate){
                this.$set(optionWrapper, 'pendingRateChange', false);
                return;
            }

            this.$set(optionWrapper, 'pendingRateChange', true);
            this.$set(optionWrapper.option, 'userId', this.user.id);
            console.log(optionWrapper);
            let saver = this.getTaxOptionSaver(optionWrapper);
            saver(optionWrapper);
        },
        async handleTaxOptionNameChange(optionWrapper){
            console.log('name change',optionWrapper);
            if(!optionWrapper.newOptionId && optionWrapper.option.name == optionWrapper.originalName){
                this.$set(optionWrapper, 'pendingNameChange', false);
                return
            }
                
            this.$set(optionWrapper, 'pendingNameChange', true);
            this.$set(optionWrapper.option, 'userId', this.user.id);            
            let saver =  this.getTaxOptionSaver(optionWrapper);
            saver(optionWrapper);
            //this.dbAlertAndSaveTaxOptionRateChange();
        },
        
        async processTaxOptionChange(changedOption){
            // We only need to check tax options that have been disabled or deleted
            // for dependents. A dependent is a project relying on that tax option.
            let dependentCheckIsRequired = changedOption.pendingDelete || changedOption.pendingDisable
            let hasDependents = false;
            if(dependentCheckIsRequired)
                hasDependents = await this.checkForDependents_Single(changedOption);

            if(hasDependents){                
                if(changedOption.showSuccessorPicker == true)
                    return;
            }
        
            // If a tax option has dependents, but a successor has not been set yet, return
            let successorsNotSet = changedOption.showSuccessorPicker == true && changedOption.successorConfirmed == false;
            if(successorsNotSet)
                return;
            console.log("processTaxOptionChange");            
            this.saveTaxOption(changedOption);
        },
        
        // async processTaxOptionChanges(){
        //     // We only need to check tax options that have been disabled or deleted
        //     // for dependents. A dependent is a project relying on that tax option.
        //     let changedTaxOptions = [...this.taxOptions.filter(wrapper => wrapper.pendingDelete || wrapper.pendingDisable)]

        //     let dependentCheckIsRequired = changedTaxOptions.length >= 1;
        //     let hasDependents = false;
        //     if(dependentCheckIsRequired)
        //         hasDependents = await this.checkForDependents_Multi(changedTaxOptions);

        //     if(hasDependents){
        //         for(const optKey in this.taxOptions){
        //             let wrapperOpt = this.taxOptions[optKey];
        //             if(wrapperOpt.showSuccessorPicker == true)
        //                 return;
        //         }
        //     }
        
        //     // If a tax option has dependents, but a successor has not been set yet, return
        //     let successorsNotSet = changedTaxOptions.filter(wrapper => wrapper.showSuccessorPicker == true && wrapper.successorConfirmed == false);
        //     if(successorsNotSet.length >= 1){
        //         return;
        //     }

        //     this.dbTaxOptions();
        // },
       
        initNewTaxOption(){
            this.newTaxOptionCount++;
            let newTaxOption = {
                name: `New Tax Option ${this.newTaxOptionCount}`,
                value: 6.00,
                enabled: true,
                businessId: this.entity.id
            }
            let optionWrapper = {newOptionId: `nto_${this.newTaxOptionCount}`, option: newTaxOption, si: `nto_${this.newTaxOptionCount}`}

            this.newTaxOption = optionWrapper;
        },
        cancelNewTaxOption(newOptionId){
            this.newTaxOption = null;//this.newTaxOptions.filter(opt => opt.newOptionId != newOptionId)
        },
        async createNewTaxOption(){
            if(!this.newTaxOption)
                return;
 
                let si = this.$refs['new'];
            if(si)
                si.loading();

            // Mapping the changed tax options to the server dto form
            
            let optionWrapper = {
                taxOption: this.newTaxOption.option,
            }

            if(!optionWrapper.taxOption.rate)
                optionWrapper.taxOption.rate = 0;

            console.log('await createNewTaxOption')
            let response = await api.saveTaxOptions(this.entity.id, [optionWrapper]);
            if(response.isError){
                if(si)
                    si.fail();
            }
            else{
                if(si)
                    si.success();
                this.newTaxOption = null;
                await this.refreshData();
            }
        },
        async saveTaxOption(taxOption){
            if(!taxOption)
                return;
 
                let si = this.$refs[taxOption.si]
                if(si.length && si.length>0)
                    si = si[0];
                
                if(si)
                    si.loading();

            // Mapping the changed tax options to the server dto form
            
            let optionWrapper = {
                taxOption: taxOption.option,
            }

            if(!optionWrapper.taxOption.rate)
                optionWrapper.taxOption.rate = 0;

            console.log('await saveTaxOption');
            let response = await api.saveTaxOptions(this.entity.id, [optionWrapper]);            
            console.log('remove pending save');
            

            if(response.isError){
                if(si)
                    si.fail();
            }
            else{
                if(si)
                    await si.asyncSuccess();
                this.pendingSave = this.pendingSave.filter(item => item !== taxOption.option.reference);
                               
                await this.refreshData();
                
            }
        },
        
        async saveTaxOptions(){
            let changedTaxOptions = [];
            let taxOptionsToUpdate = [];
            let indicators = [];

            changedTaxOptions = [
                ...this.taxOptions.filter(wrapper => wrapper.pendingDisable || wrapper.pendingDelete || wrapper.pendingNameChange || wrapper.pendingRateChange),
                
            ];            
            
            if(changedTaxOptions.length < 1)
            return; 
            
            // Spin the status indicators
            changedTaxOptions.forEach(opt => {
                let si = this.$refs[opt.si];
                if(si.length!=1)
                    return;
                si  = si[0]; // v-for takes control of ref and makes it an array by force
                if(indicators.includes(si))
                    return;
                indicators.push(si);
            });
            
            indicators.forEach((si) => {            
                if(si != undefined)
                    si.loading();
            });

            // Mapping the changed tax options to the server dto form
            changedTaxOptions.forEach((opt) =>{
                let srcOpt = opt.option;
                let optionWrapper = {
                    taxOption: srcOpt,
                    successorReference: opt.successor
                }

                if(!optionWrapper.taxOption.rate)
                    optionWrapper.taxOption.rate = 0;

                taxOptionsToUpdate.push(optionWrapper)
            })

            console.log('await saveTaxOptions')
            let response = await api.saveTaxOptions(this.entity.id, taxOptionsToUpdate);
            if(response.isError){
              indicators.forEach((si) => {            
                  if(si)
                    si.fail();
                });          
            }
            else{
                const indicatorsWaits = [];
                indicators.forEach((si) => {            
                    if(si)
                        indicatorsWaits.push(si.asyncSuccess());
                }); 

                this.disabledOrDeletedTaxRateProjectPairs = [];
                changedTaxOptions.forEach((wrapper) => {
                    // Set all of our flags to false now that everything is up to date
                    wrapper.pendingDisable = false;
                    wrapper.pendingDelete = false;
                    wrapper.pendingNameChange = false;
                    wrapper.pendingRateChange = false;

                    let newOptionId = wrapper.newOptionId;
                    if(newOptionId)
                    {
                        console.log('filting out newTaxOptions')                        
                    }
                });


                await this.refreshData();
                
            }
        },
        async checkForDependents_Single(changedTaxOption){
            
            let projectsForBusiness = await api.getProjects(this.entity.id);
            let dependentsExist = false;
            
            let optWrapper = changedTaxOption
                            
            // Get all unarchived, unlocked projects for a business
            let unarchivedUnlockedProjects = projectsForBusiness.filter(project => !project.archive || !project.pricingLocked)

            // Filter down project list to only projects with the current changed tax option
            // and map to new array with just the project id's.
            let projectsToUpdate = unarchivedUnlockedProjects
                .filter(project => project.taxOptionRef == optWrapper.option.reference)
                .map(project => project.id);

            // If there are no projectsToUpdate, all is well.
            // This specific tax option does not have dependents
            if(projectsToUpdate.length < 1)
                return false;

            if(!optWrapper.option.enabled || optWrapper.option.deleted){
                // Only set showSuccessorPicker to true if we haven't already 
                // confirmed a successor
                if(!optWrapper.successorConfirmed){
                    optWrapper.showSuccessorPicker = true; // this is a sideeffect
                }

                dependentsExist = true;
            }

            if(dependentsExist){
                return true;
            }               

            return false;           
        },
        async checkForDependents_Multi(changedTaxOptions){
            // let enabledTaxOpts = this.enabledTaxOptions;
            // let currentDefaultTaxHasBeenDisabled = changedTaxOptions.find(optWrapper => optWrapper.option.id == this.qt_defaultSalesTaxRate && optWrapper.option.enabled == false)
            // let currentDefaultTaxHasBeenDeleted = changedTaxOptions.find(optWrapper => optWrapper.option.id == this.qt_defaultSalesTaxRate && optWrapper.option.deleted == true)
            // if(enabledTaxOpts.length < 1){

            //     this.$toast.error("There must be at least one enabled tax option at all times.")
 
            //     return true;
            // }
            // else if(currentDefaultTaxHasBeenDisabled){
            //     this.$toast.error(`"${currentDefaultTaxHasBeenDisabled.option.name}" is currently in use as the default sales tax rate. A different tax option must be selected before disabling.`)
            //     return true;
            // }
            // else if(currentDefaultTaxHasBeenDeleted){
            //     this.$toast.error(`"${currentDefaultTaxHasBeenDisabled.option.name}" is currently in use as the default sales tax rate. A different tax option must be selected before deleting.`)
                
            //     return true;
            // }
            // else{

                let projectsForBusiness = await api.getProjects(this.entity.id);
                let dependentsExist = false;
                for(const optKey in changedTaxOptions){
                    
                    let optWrapper = changedTaxOptions[optKey]
                    let isAlreadyInArray = this.disabledOrDeletedTaxOptionProjects.some(opt => opt.option.id == optWrapper.option.id);
                    if(isAlreadyInArray)
                        continue;
                    
                    // Get all unarchived, unlocked projects for a business
                    let unarchivedUnlockedProjects = projectsForBusiness.filter(project => !project.archive || !project.pricingLocked)

                    // Filter down project list to only projects with the current changed tax option
                    // and map to new array with just the project id's.
                    let projectsToUpdate = unarchivedUnlockedProjects
                        .filter(project => project.taxOptionRef == optWrapper.option.reference)
                        .map(project => project.id);

                    // If there are no projectsToUpdate, all is well.
                    // This specific tax option does not have dependents
                    if(projectsToUpdate.length < 1)
                        continue;

                    if(!optWrapper.option.enabled || optWrapper.option.deleted){
                        // Only set showSuccessorPicker to true if we haven't already 
                        // confirmed a successor
                        if(!optWrapper.successorConfirmed){
                            optWrapper.showSuccessorPicker = true;
                        }

                        dependentsExist = true;

                        // optWrapper.dependentProjects = projectsToUpdate;
                        
                    }
                }

                if(dependentsExist){
                    //this.projectTaxOptModalDropDownOptions = enabledTaxOpts
                    //this.showProjectTaxOptModal = true;
                    return true;
                }
               
            //}

            return false;
           
        },
        async initTaxOpts(taxOpts){
            this.taxOptions = [];            
            taxOpts.sort((a, b) => a.name.localeCompare(b.name));
            await taxOpts.forEach((opt, i) => {
                this.taxOptions.push({option: opt, si:`to_${i}`, showSuccessorPicker: false, successorConfirmed: false, originalRate: opt.rate, originalName: opt.name})
            });
            
        },        
        async loadDefaultSalesTax(businessId){         
            let response = await this.getBusinessSetting({businessId, settingName: 'qt_defaultSalesTaxRate'});
              if(response.isError){
                  this.$toast.error(response.msg);
              }
              else{
                  this.qt_defaultSalesTaxRate = response.value
              }
        }
    },
    async created() {
        this.debug = false;
        let pathParts = this.$route.path.split("/");
        let businessId = pathParts[2];

        this.dbBusinessSettings = Util.debounce(this.setBusinessSetting,1000);

        this.dbTaxOptions = Util.debounce(this.saveTaxOptions,1000);
        // this.dbAlertAndSaveTaxOptionRateChange = Util.debounce(this.alertAndSaveTaxOptionRateChange,1000);
        // this.dbValidateAndSaveTaxOptionDisableDelete = Util.debounce(this.validateAndSaveTaxOptionDisableDelete,1000);
        this.dbHandleTaxOptionNameChange = Util.debounce(this.handleTaxOptionNameChange, 500);
        this.dbHandleTaxOptionRateChange = Util.debounce(this.handleTaxOptionRateChange, 500);
        // this.dbhandleTaxOptionActivation = Util.debounce(this.handleTaxOptionActivation, 1000);
        // this.dbHandleTaxOptionDelete = Util.debounce(this.handleTaxOptionDelete, 1000);
        this.dbTestDB = Util.debounce(this.testDB, 1000);

        //this.dbProcessTaxOptionChanges = Util.debounce(this.processTaxOptionChanges, 1000);

        
        await ContextLogic.initContext(this);
        this.isAuthenticated = this.$auth.isAuthenticated;
        let isContractorAdmin = this.userIsContAdmin;
        let isSupplierAdmin = this.userIsSupAdmin;
        let isSystemAdmin = this.userIsSysAdmin;
        console.log(`contractor admin: ${isContractorAdmin} || supplier admin: ${isSupplierAdmin} || system admin: ${isSystemAdmin}`)
        this.showSupplierOnlySetting = this.mode == 1;
        this.canUpdateSupplierSettings = isSupplierAdmin || isSystemAdmin;
        this.canUpdateContractorSettings = isContractorAdmin || isSystemAdmin;
        this.canUpdateBusinessInfo = this.canUpdateSupplierSettings || this.canUpdateContractorSettings;

        this.entity = await this.fetchBusiness(businessId);
        await this.refreshData();
        await this.loadDefaultSalesTax(businessId);

        
        
    },
  }
  </script>
  
  <style scoped>
  .schemes-container{
    display:flex;
    flex-wrap: wrap;
  }
  .markup-configuration {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .schemes-container {
    display: flex;
    justify-content: center;
    gap: 65px;
    width: fit-content;
  }
  
  @media (max-width: 768px) {
    .schemes-container {
      flex-direction: column;
    }
  }

  @media (max-width: 4924px) {
       .settingsGroup {
           font-size: 1.3em;
       }
   }
   @media (max-width: 1280px) {
       .settingsGroup {
           font-size: 1.2em;
       }
   }
   @media (max-width: 1024px) {
       .settingsGroup {
           font-size: 1.1em;
       }
   }
   @media (max-width: 768px) {
       .settingsGroup {
           font-size: 1.0em;
       }
   }
   @media (max-width: 640px) {
       .settingsGroup {
           font-size: 1.0em;
       }
   }
   .settingGroupList {
       width: 90%;
       margin: auto;
   }

   .settingsContainer {
       display: flex;
       flex-flow: row wrap;
       justify-content: space-evenly;
       align-items: flex-start;
   }

   .settingsItem {
       display: block;
       margin: 5px;
       padding: 5px;
       margin-left: 20px;
       border: 1px solid lightgrey;        
       width:100%;
       text-align: left;
   }


   /* parent  */
   
   .settingsContainer {
       flex-grow: 2;
   }


   .settingsGroupList {
       display: flex;
       flex-flow: row wrap;

   }

   /* item */
   .settingsGroup {
       /*
       flex-grow: 1;
       */
       display: block;
       margin: 5px;
       padding: 2px;       
       /*justify-content: center;*/
       /*min-width: 218px;*/
       border: 2px solid rgb(136, 136, 136);
   }

   .settingsGroup .settingsGroupBody {
       display: block;
       flex-grow: 1;
       color: black;
       flex-shrink: 2;
       text-align: left;       
   }

   .flex {
       display: flex;
       flex-flow: row wrap;
   }

   .settingsGroup .settingsGroupHeader {
       display: block;
       font-weight: bold;
       
       background: lightgray;
       padding: 10px;
       text-align: left;
   }

   .setting {
       display: inline-block;
       margin: 10px;
       vertical-align: top;
   }

   .settingsGroup .settingDescription {
       display: inline-block;
       margin: 3px 0 0 5px;
       font-size: .8em;
       vertical-align: top;
       
   }

   .settingsGroup .settingValue {
       flex-grow: 1;
       text-align: left;
       display: inline-block;
       margin-left: 10px;
       
   }        


   .settingValue input {       
       flex-shrink: 1;
   }

   .settingValue .designLogoSml {
       margin: 3px;
       display: block;
       text-align: left
   }

   .settingValue .designLogoSml img {
       margin: 3px;
       max-width: 150px;
       box-shadow: 0px 0px 2px black;
       display: inline-block;
       text-align: center;
       margin-left: auto;
       margin-right: auto;
       background: black
   }

   .settingValue .designLogoLrg img {
       margin: 3px;
       max-width: 480px;
       box-shadow: 0px 0px 2px black;
       display: inline-block;
       text-align: center;
       margin-left: auto;
       margin-right: auto;
   }

   .hidden {
       display: none;
   }

   .logoTypeLabel {
       width: 120px;
       display: block;
       font-size: .7em;
   }

   .horsep {
       border-top: 1px solid lightgray;
       display:inline-block;
       margin:auto;
       height:10px;
       width:95%;
       text-align:center;
   }

   .digit2 {
       width: 51px;
   }
   
   .digit3 {
       width: 76px;
   }

   .logo-input{
        display: inline-flex;
        margin: 20px 50px;
    }

    .logo-lrg {
        width: 300px;
        
    }

    .logo-sml {
      width: 80px;
      border-radius: 15px;
      /* background-color: #f0f1f5; */
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      
      img {
        max-width: 100%;
        /* border-radius: 50%; */
      }
    }

    .section-group-title{
        margin: 15px 10px;
        text-align: left;
        background: rgb(231, 231, 231)
    }

    input.w-s{
        width:100px;
    }
    input.w-m{
        width:350px;
    }
    input.w-l{
        width:500px;
    }






        .table-1 td {
            padding-block-start: 0;
            padding-block-end: 0;
        }

 /* this is a temporary hack against nitty-gritty to disable the on-boarding button since it will exist but doesn't yet */
button[disabled]{
text-align: center;
    line-height: var(--btn-line-height);
    padding-inline: var(--btn-medium-padding-inline);
    padding-block: var(--btn-medium-padding-block);
    font-family: var(--font-family-btn);
    font-style: var(--btn-font-style);
    text-transform: var(--btn-text-transform);
    border-style: solid;
    border-width: var(--btn-medium-border-width);
    border-radius: var(--btn-medium-border-radius);
    min-height: var(--btn-medium-min-height);
    font-weight: var(--btn-medium-font-weight);
    font-size: var(--btn-medium-font-size);
    justify-content: center;
    align-items: center;
    column-gap: var(--btn-medium-column-gap);
    border-color: gray;
    background-color: lightgray;
    color: gray;
    cursor:default;
}

button[disabled] svg{
  stroke: gray;
}
  </style>