import * as THREE from 'three';
import { FrontSide } from 'three';
import {CORE} from './../_spec'
import Util from '../utility';
import ColorHelper from './colorHelper';

export default class materialHelper {

    static materials() {
        return {
            transparent: new THREE.MeshBasicMaterial({ color: 0x000000,  side: THREE.DoubleSide, opacity:0, transparent:true}),
            debug: new THREE.MeshBasicMaterial({ color: 0x3333ff,  side: THREE.DoubleSide, opacity:.5, transparent:true}),
            bolt: new THREE.MeshStandardMaterial({ 
                color: 0x888888,
                envMap: CORE.envMap,
                metalness:1,
                roughness:0,
                envMapIntensity:1,
                emissive: Number(0x888888),
                emissiveIntensity: 1 
            }),
            stainless: new THREE.MeshStandardMaterial({ 
                color: 0x54535B,
                envMap: CORE.envMap,
                metalness:1,
                roughness:0,
                envMapIntensity:1,
                emissive: Number(0x54535B),
                emissiveIntensity: .1 }),
            //frame: this.matFrame, //new THREE.MeshLambertMaterial({ color: CORE.frame.color}),
            //frameWhite: this.matFrameWhite,
            trim: new THREE.MeshPhongMaterial( { color: CORE.frame.redOxideColor} ),
            trims:{
                gutter: new THREE.MeshPhongMaterial( { color: CORE.frame.redOxideColor} ),
                downspout: new THREE.MeshPhongMaterial( { color: CORE.frame.redOxideColor} ),
                rake: new THREE.MeshPhongMaterial( { color: CORE.frame.redOxideColor} ),
                corner: new THREE.MeshPhongMaterial( { color: CORE.frame.redOxideColor} ),
                door: new THREE.MeshPhongMaterial( { color: CORE.frame.redOxideColor} ),
                window: new THREE.MeshPhongMaterial( { color: CORE.frame.redOxideColor} )
            }
        }
    };

    static getSquareColumnFrameMaterial_HD(depth, height, color){
        // Create a custom texture for this object
        let texture = CORE.textures.beam.clone();
        texture.wrapT = texture.wrapS = THREE.MirroredRepeatWrapping;
        texture.encoding = THREE.sRGBEncoding;
        texture.repeat.set(depth, height);
        return materialHelper.getFrameMaterial_HD(color, texture);
    }

    static getSquareColumnFrameMaterial_LD(){
        
    }

    static getSteelFastenerMaterial_HD(){
        let mat = new THREE.MeshStandardMaterial({ 
            color: 0x888888,
            envMap: CORE.envMap,
            metalness:1,
            roughness:0,
            envMapIntensity:1,
            emissive: Number(0x888888),
            emissiveIntensity: 1 
        })
        return mat;
    }

    
    static getSteelFastenerMaterial_LD(){
        let mat = new THREE.MeshBasicMaterial({ 
            color: 0x888888,
            wireframe:false,
        })
        return mat;
    }


    static getFrameMaterial_HD(color=CORE.frame.redOxideColor){
        let nColor = Number(color);
        let map = CORE.textures.beam.clone();        

        return new THREE.MeshStandardMaterial({
            color: nColor, 
            map,
            side: THREE.FrontSide,
            metalness: 1,
            roughness: 1,
            emissive: nColor,
            emissiveIntensity: .5,
        });

    }

    
    static getFrameMaterial_LD(color=CORE.frame.redOxideColor){
        let nColor = Number(color);
        return new THREE.MeshPhongMaterial({
            color: nColor, 
            //map: texture, 
            side: THREE.DoubleSide,
            //emissive: nColor,
            //emissiveIntensity: .5,
        });
    }


    static getInsulationMaterial(color, width, texture){
        let nColor = Number(color);
        let mat = new THREE.MeshStandardMaterial({
            color: nColor, 
            side: THREE.DoubleSide,
            metalness: 0,
            roughness: .5,            
            emissive: nColor,
            emissiveIntensity: .2,
        });
        if(texture){
            mat.map = texture.clone();
            mat.map.wrapS = THREE.RepeatWrapping
            mat.map.wrapT = THREE.ClampToEdgeWrapping      
            mat.map.anisotropy = 32;
            mat.map.repeat.set(width/36,1)        
        }        
        return mat;
    }
    


    static getTrimMaterial(color, texture){
        let nColor = Number(color);
        let mat=  new THREE.MeshStandardMaterial({
            color: nColor, 
            side: THREE.DoubleSide,
            roughness: .1,
            metalness: .7,
            emissive: nColor,
            emissiveIntensity: .7,
        });
        //if(texture)
            //mat.map = texture;
            return mat;
    }

    
    static getDoorMaterial(color, texture){
        let nColor = Number(color);
        let mat=  new THREE.MeshStandardMaterial({
            color: nColor, 
            side: THREE.DoubleSide,
            roughness: .1,
            metalness: .7,
            emissive: nColor,
            emissiveIntensity: .7,
        });
        if(texture)
            mat.map = texture;
            return mat;
    }

    static getdoorRollupDoorMaterial(color, texture){
        let nColor = Number(color);
        let mat=  new THREE.MeshStandardMaterial({
            color: nColor, 
            side: THREE.DoubleSide,
            roughness: .1,
            metalness: .7,
            //emissive: nColor,
            emissive: nColor,
            emissiveIntensity: .7,
        });
        if(texture)
            mat.map = texture;
            return mat;
    }
    
    static getPanelMaterial(color, width=1, side=THREE.DoubleSide, map, envMapIntensity, bumpMap){
        let nColor = Number(color);
        let mat = new THREE.MeshStandardMaterial({
            color: nColor, 
            side,
            roughness: .1,
            metalness: .7,
            emissive: nColor,
            emissiveIntensity: .7,
        });
        if(map)
        {
            if(!map.isTexture)
                throw 'map must be a texture'
            mat.map = map;
            mat.map.wrapS = THREE.RepeatWrapping
            mat.map.wrapT = THREE.ClampToEdgeWrapping      
            mat.map.anisotropy = 32;
            mat.map.repeat.set(width/36,1)        
        }
        if(bumpMap)
        {
            mat.bumpMap = bumpMap;
            mat.bumpScale =1;
            mat.bumpMap.wrapS = THREE.RepeatWrapping
            mat.bumpMap.wrapT = THREE.ClampToEdgeWrapping      
            mat.bumpMap.anisotropy = 32;
            mat.bumpMap.repeat.set(width/36,1)
        }
        return mat;
    }

    static getPanelPbrMaterial(color, width, side){
        let panel = this.getPanelMaterial(color, width, side, CORE.textures.panelPbrColorMap.clone(), 1, CORE.textures.panelPbrBumpMap.clone());
        return panel
    }

    static getExteriorPanelMaterial(color, texture){
        return this.getPanelMaterial(color, 1, THREE.FrontSide, texture)
    }

    static getInteriorPanelMaterial(color, texture){
        return this.getPanelMaterial(color, 1, THREE.BackSide, texture)
    }

    static getInteriorPanelPbrMaterial(color, width, side = THREE.BackSide){
        return this.getPanelMaterial(color,  width, side, CORE.textures.panelPbrColorMap.clone(), 1, CORE.textures.panelPbrBumpMap.clone());
    }
    static getExteriorPanelPbrMaterial(color, width, side = THREE.FrontSide){
        return this.getPanelMaterial(color, width, side, 
            CORE.textures.panelPbrColorMap.clone(),
            //undefined,
             1,             
            CORE.textures.panelPbrBumpMap.clone());
    }

    static getConcreteMaterial(color, texture){
        let nColor = Number(color);
        let mat = new THREE.MeshStandardMaterial({
            color: nColor, 
            side: THREE.FrontSide,
            metalness: 0,
            roughness: 1,            
            emissive: nColor,
            emissiveIntensity: 1,
        });
        if(texture)
            mat.map = texture;
        return mat;
    }

    static getGlassMaterial_Ultra_HD() {
        let mat = new THREE.MeshPhysicalMaterial({
            color: Number(0x555555), 
            side: THREE.DoubleSide, 
            metalness: 0,
            roughness: 0,            
            transmission: .8,
            thickness: .5
        });        
        return mat;
    }

    static getTintedGlassMaterial_HD(){
        
        let mat = new THREE.MeshStandardMaterial({
            color: Number(0x222222), 
            side: THREE.DoubleSide, 
            opacity:.6, 
            transparent:true,
            metalness: 0,
            roughness: 0,            
            emissive: Number(0x000000),
            emissiveIntensity: 1,
        });        
        return mat;
    }

    static getTintedGlassMaterial_LD(){
        
        let mat = new THREE.MeshBasicMaterial({
            color: Number(0x888888), 
            side: THREE.DoubleSide,                         
        });        
        return mat;
    }
    

    static getGlassMaterial_HD(){
        
        let mat = new THREE.MeshStandardMaterial({
            color: Number(0xbbbbff), 
            side: THREE.DoubleSide, 
            opacity:.05, 
            transparent:true,
            metalness: 0,
            roughness: 0,            
            emissive: Number(0x000000),
            emissiveIntensity: 1,
        });        
        return mat;
    }

    
    static getGlassMaterial_LD(){
        
        let mat = new THREE.MeshBasicMaterial({
            color: Number(0xaaaaff), 
            side: THREE.DoubleSide,                         
        });        
        return mat;
    }

    static getPlasticMaterial_LD(color=0xaaaaff){
        
        let mat = new THREE.MeshBasicMaterial({
            color: Number(color), 
            side: THREE.DoubleSide,                         
        });        
        return mat;
    }

    static getPlasticMaterial_HD(color){
        let nColor = Number(color);
        let mat = new THREE.MeshStandardMaterial({
            color: nColor, 
            side: THREE.FrontSide, 
            metalness: 0,
            roughness: .3,            
            emissive: Number(0x000000),
            emissiveIntensity: 1,
        });        
        return mat;
    }

    static getMaterialFromTexture(tex, color, rX, rY){
        let texture = tex; 
        texture.wrapS = texture.wrapT = THREE.MirroredRepeatWrapping;
        texture.encoding = THREE.sRGBEncoding;
        texture.repeat.set(rX, rY);

        let mat =  new THREE.MeshStandardMaterial({
            color: Number(color), 
            map: texture, 
            side: THREE.FrontSide,
        });
        return mat;
    }

    
    static getFrameMaterialWithTexture(tex, color, rX, rY){
        let texture = tex; 
        texture.wrapS = texture.wrapT = THREE.MirroredRepeatWrapping;
        texture.encoding = THREE.sRGBEncoding;
        texture.repeat.set(rX, rY);

        let mat = this.getFrameMaterial_HD(color, texture)        
        return mat;
    }

    static getPbrBumpMap(length=undefined){
        if(length===undefined)
            length = this.length;

        let canvasWidth=4096;
        let canvasHeight=4096;

        //let canvas = document.querySelector('#test');
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');            
        canvas.width =canvasWidth;
        canvas.height = canvasHeight;
        // drawing gray scale areas
        ctx.fillStyle = '#000000';
        ctx.fillRect(0, 0, canvasWidth, canvasHeight);
        ctx.fillStyle = '#ffffff';
        let ridgeInches = 1.5;
        let slantWidthInches = 1.5
        let ribWidthInches = 1.5;
        let gapWidthInches = 1.5;
        let sectionWidthInches = 12;
        let sectionWidthCanvas = canvasWidth*(sectionWidthInches/length)
        let toCanvasUnitRatio = canvasWidth/length;
        let ribColor = '999999'
        let ridgeColor = 'ffffff'
        let gapColor = '000000'
        canvas.imageSmoothingQuality = "high"
        let x = 0
        while(x< canvasWidth)
        {
            //console.log(x);
            // ridge top 1"                
            this.drawSolid(ridgeColor,x,ridgeInches,ctx,canvasWidth, canvasHeight, length)
            x+=ridgeInches*toCanvasUnitRatio;
            this.drawFade(x,slantWidthInches,ridgeColor, gapColor, ctx,canvasWidth, canvasHeight, length)
            x+=slantWidthInches*toCanvasUnitRatio;
            this.drawSolid(gapColor,x,gapWidthInches,ctx,canvasWidth, canvasHeight, length)
            x+=gapWidthInches*toCanvasUnitRatio;
            this.drawSolid(ribColor,x,ribWidthInches,ctx,canvasWidth, canvasHeight, length)
            x+=ribWidthInches*toCanvasUnitRatio;
            this.drawSolid(gapColor,x,gapWidthInches,ctx,canvasWidth, canvasHeight, length)
            x+=gapWidthInches*toCanvasUnitRatio;
            this.drawSolid(ribColor,x,ribWidthInches,ctx,canvasWidth, canvasHeight, length)
            x+=ribWidthInches*toCanvasUnitRatio;
            this.drawSolid(gapColor,x,gapWidthInches,ctx,canvasWidth, canvasHeight, length)
            x+=gapWidthInches*toCanvasUnitRatio;
            this.drawFade(x,slantWidthInches, gapColor, ridgeColor, ctx,canvasWidth, canvasHeight, length)
            x+=slantWidthInches*toCanvasUnitRatio;
            //console.log(x);
        }

        //var dataURL = canvas.toDataURL("image/png");
        //var newTab = window.open('about:blank','image from canvas');
        //newTab.document.write("<body style='background:blue'><img src='" + dataURL + "' alt='from canvas'/></body>");
    
        return new THREE.CanvasTexture(canvas);
    }

    static drawSolid(color, x,widthInches,ctx, canvasWidth, canvasHeight, length){
        let width = canvasWidth*(widthInches/length);
        ctx.fillStyle = '#'+color;
        ctx.fillRect(x, 0, width, canvasHeight);
    }

    static drawFade(x,widthInches, fromColor, toColor, ctx, canvasWidth, canvasHeight, length){
        let width = canvasWidth*(widthInches/length);            
        var grd = ctx.createLinearGradient(x,0,x+width,0);
        grd.addColorStop(0,'#'+fromColor);
        grd.addColorStop(1,'#'+toColor);

        // Fill with gradient
        ctx.fillStyle = grd;
        ctx.fillRect(x, 0, width, canvasHeight);
    }
}