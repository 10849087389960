
import Base from './Base'
import OptionHelper from '../helpers/optionHelper.js'
import ColorHelper from '../helpers/colorHelper.js'
import * as THREE from 'three';
import {impactTypes, CORE} from '../_spec.js';
import Util from '../utility.js'

export default class Appearance extends Base{
    constructor(design){
        Appearance.migrate(design);
        super(design);
    }

    static migrate(design) {

        //data migration during beta (introduction of trim color mode)
        if(typeof design.trimColorMode === 'undefined'){
            design.trimColorMode = CORE.modes.trimColor.single;
            design.trims = {
                eaveAndRake: design.trim,
                downspout: design.trim,
                corner: design.trim,
                door: design.trim,
                window: design.trim,
            }
        }

        // smp-color removal migration
        // find anything with "white" color D8E7E9
        // find SMP-Color with FFFFFF
        // make them the new white
        //console.log('appearance migration - design', design);
        
        
        if (!design.roof)
          design.roof = ColorHelper.first().id;

        if (!design.wall)
         design.wall = ColorHelper.first().id;

        if (!design.wainscoting)
          design.wainscoting = ColorHelper.first().id;

        if (design.trimColorMode == CORE.modes.trimColor.single) {
          if (!design.trim)
            design.trim = ColorHelper.first().id;
        } else if (design.trimColorMode == CORE.modes.trimColor.separate) {
          for (const trimType in design.trims) {
            if (!design.trims[trimType]) {
              design.trims[trimType] = ColorHelper.first().id;
            }
          }
        }
        
        // if(design.trims.window){
        //     //https://trello.com/c/7k3A3Jye/170-group-walk-door-colors-with-windows-instead-of-oh-doors
        //     design.trims.walksAndWindows = Util.copy(design.trims.window);
        //     delete design.trims.window;
        // }
    }




    static mergeDesign(src, tgt ){
        
            tgt.type= CORE.components.appearance,
            tgt.trimColorMode= src.trimColorMode,
            tgt.roof= (src.roof),
            tgt.wall= (src.wall),
            tgt.wainscoting= (src.wainscoting),
            tgt.trim= (src.trim),
            tgt.trims= {
                eaveAndRake: src.trims.eaveAndRake? (src.trims.eaveAndRake): src.trims.eaveAndRake,
                downspout: src.trims.downspout? (src.trims.downspout) : src.trims.downspout,
                corner: src.trims.corner? (src.trims.corner):src.trims.corner,
                door: src.trims.door?(src.trims.door):src.trims.door,
                walksAndWindows: src.trims.walksAndWindows?(src.trims.walksAndWindows):src.trims.walksAndWindows,
            }
        
    }

    defaultDesign(){
        return {
            type: CORE.components.appearance,
            roof: '#',
            wall: '#',
            wainscoting: '#',
            trim: '#'
        }
    }
    
    static canEdit(){return true;}
    static canDelete(){return false;}
    static canCopy(){return false;}

    getOutlineMeshes(){        
        return null;
    }
    getDescription (){
        return `Colors`
    }
    
    getOptionsSpec(){
    
        let opts = [
            OptionHelper.header("Main Colors"),
            OptionHelper.colorPanel("roof", "Roof", impactTypes.colorRoof, undefined,
            true, // enabled
            undefined,
            undefined,
            undefined,
            undefined,
            ),

            OptionHelper.colorPanel("wall", "Wall", impactTypes.colorWall, undefined,
            true, // enabled
            undefined,
            undefined,
            undefined,
            undefined
            ),

            OptionHelper.colorPanel("wainscoting", "Wainscoting", impactTypes.colorWainscot, undefined,
            true, // enabled
            undefined,
            undefined,
            undefined,
            undefined,
            ),
            OptionHelper.colorTrim("trim", "Trim", impactTypes.colorTrim, undefined,
            this.design.trimColorMode === CORE.modes.trimColor.single, // enabled
            undefined,
            undefined,
            undefined,
            undefined,
            ),
            OptionHelper.checkbox("trimColorMode", "Separate Trim Colors", impactTypes.colorModeTrim,            
            true,
              ()=>{ // fnIn
                return this.design.trimColorMode === CORE.modes.trimColor.separate;
            },
            undefined,
            (v)=>{
              if(v) {
                  this.design.trimColorMode = CORE.modes.trimColor.separate;
                  // initialize the specific trim colors to the single trim color (would this be better to do only if the specific trim color is not set?)
                  let cId = this.design.trim;
                  for (const trimType in this.design.trims) {
                    this.design.trims[trimType] = cId;
                  }
              }
              else
                  this.design.trimColorMode = CORE.modes.trimColor.single;
            }),
        ];
        
        if(this.design.trimColorMode === CORE.modes.trimColor.separate){
            opts.push(
                OptionHelper.header("Trim Colors"),
            );

            let optTrimColorEaveAndRake = OptionHelper.colorTrim("trims.eaveAndRake", "Eave and Rake", impactTypes.colorTrimEaveAndRake, undefined,
                true, // enabled
                undefined,
                undefined,
                undefined,
                undefined,
                )

            let optTrimColorDownspout = OptionHelper.colorTrim("trims.downspout", "Downspouts", impactTypes.colorTrimDownspout, undefined,
                true, // enabled
                undefined,
                undefined,
                undefined,
                undefined,
                )       
                
            let optTrimColorCorner = OptionHelper.colorTrim("trims.corner", "Corners and Base Trim", impactTypes.colorTrimCornerAndBase, undefined,
                true, // enabled
                undefined,
                undefined,
                undefined,
                undefined,
                )

                
            let optTrimColorDoor = OptionHelper.colorTrim("trims.door", "doorRollup Doors", impactTypes.colorTrimDoor, undefined,
                true, // enabled
                undefined,
                undefined,
                undefined,
                undefined,
                )

                
            let optTrimColorWalksAndWindows = OptionHelper.colorTrim("trims.walksAndWindows", "Walks/Windows", impactTypes.colorTrimWalksAndWindows, undefined,
                true, // enabled
                undefined,
                undefined,
                undefined,
                undefined,
                )
                
            opts.push(...[
                optTrimColorEaveAndRake,
                optTrimColorDownspout,
                optTrimColorCorner,
                optTrimColorDoor,
                optTrimColorWalksAndWindows
            ]);
        }

        return opts;
    }

    build(){        
    }
    
    remove(){
    }
    
    getCollisionClass(){
        return [CORE.collisions.classes.none];
    }

    getTypeDisplayName(){
        return "Colors"
    }
}
