<template>
  <div>
    <!-- <div>
      <img :src="$auth.user.picture" />
      <h2>{{ $auth.user.name }}</h2>
      <p>{{ $auth.user.email }}</p>
    </div>
      {{ user }} -->
       <div style="position:relative;display: flex;align-items: center;">
        <h1 class="h1">My Settings</h1> 

        <div v-if="isLoggedInUserSystemAdmin" style="position:absolute;display: flex;align-items: center;margin-left: 10px;left:40%;">
          <label>Viewing</label>
          <AutocompleteSelect 
            style="margin-left: 10px;"
            :disable="allUsers.length < 1"
            :id="'userSelector'"
            :options="allUsers"
            :selectedOption="formattedViewingUser"
            @change="switchViewingUser"
          >
          </AutocompleteSelect>
        </div>
      
       </div>
    
  
      <template>
        <div class="settingsGroup">
            <div class="settingsGroupHeader"><b>User Info</b></div> 

            <div class="settingsGroupBody">
                <div class="setting">
                    <span for="firstName">First Name</span><StatusIndicator ref="si_firstName" :successText="'Saved'"></StatusIndicator>
                    <!-- <label class="block" style="font-size: var(--fs-3)">Your first name</label>                                                              -->
                    <input style="width:200px" type="text" class="w-l" id="firstName" v-model="viewingUser.firstName" required @input="dbUserInfo('firstName')"/>                            
                </div>
            </div>
            <div class="settingsGroupBody">
                <div class="setting">
                    <span for="lastName">Last Name</span><StatusIndicator ref="si_lastName" :successText="'Saved'"></StatusIndicator>
                    <!-- <label class="block" style="font-size: var(--fs-3)">Your last name</label>                                                              -->
                    <input style="width:200px" type="text" class="w-l" id="lastName" v-model="viewingUser.lastName" required @input="dbUserInfo('lastName')"/>                            
                </div>
            </div>
            <div class="settingsGroupBody">
                <div class="setting">
                    <span for="phoneNumber">Phone Number</span><StatusIndicator ref="si_phoneNumber" :successText="'Saved'"></StatusIndicator>
                    <!-- <label class="block" style="font-size: var(--fs-3)">Your phone number</label>                                                              -->
                    <input style="width:200px" type="text" class="text" v-model="viewingUser.phoneNumber" id="phoneNumber" @input="dbUserInfo('phoneNumber')"/>
                    
                </div>
            </div>
            <div class="settingsGroupBody">
                <div class="setting">
                    <span for="emailAddress">Email</span><StatusIndicator ref="si_email" :successText="'Saved'"></StatusIndicator>
                    <!-- <label class="block" style="font-size: var(--fs-3)">Your email</label> -->
                    <input disabled="true" style="width:300px" id="email" type="text" v-model="viewingUser.emailAddress" @input="dbUserInfo('emailAddress')"/>
                    
                </div>
            </div>
        </div> <!-- end settings group -->
      </template>  

    <template>
      <UserProjectSettings v-if="!viewingUser.roles.includes(0)" :viewingUserId="viewingUser.id"></UserProjectSettings>
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import UserProjectSettings from '@/components/UserProjectSettings.vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import Util from '../lib/utility.js'
import StatusIndicator from '@/components/StatusIndicator.vue';
import ContextLogic from '@/lib/contextLogic.js';
import AutocompleteSelect from '@/components/AutocompleteSelect.vue';
import api from '@/api.js';
export default {
name: 'UserSettingsView',
data(){
  return {
    json: {},
    statusRefs: {   // settingName : ref
        "firstName": "si_firstName",
        "lastName": "si_lastName",
        "phoneNumber": "si_phoneNumber",
        "email": "si_email",
    },
    viewingUser: null,
    allUsers: [],
    isLoggedInUserSystemAdmin: false
  }
},
computed:{
  ...mapState('contextModule',[
    'user'
  ]),  
  formattedViewingUser(){
    return   {
      name: this.viewingUser.name, 
      value: this.viewingUser.id, 
      key: this.viewingUser.lastName+'_'+this.viewingUser.id
    }
  }
},
watch: { 
  },
components: {
  UserProjectSettings,
  StatusIndicator,
  AutocompleteSelect
},
methods:{
  ...mapActions('userModule', ['updateUserInfo', 'fetchUser']),
  ...mapMutations('contextModule', ['setUser']),
  async switchViewingUser(opt){
    let newViewingUserId = opt.val;
    let newViewingUser = await api.getUser(newViewingUserId);

    this.viewingUser = newViewingUser;
  },
  async updateUser(settingName) {
    let idOfUpdatedUser = this.viewingUser.id;
      let si = this.$refs[this.statusRefs[settingName]];
      if(si != undefined)
          si.loading();

      let response = await this.updateUserInfo(this.viewingUser);

      if(response && response.isError){
          si.fail();
      }
      else{
          si.success();
          //only update user in context if its the logged in user

          if(this.viewingUser.id == this.user.id)
            this.setUser(this.user);

          // If it was a name change, reflect in the auto select label
          if(settingName == 'firstName' || settingName == 'lastName'){
            await this.getAllUsers();  
            this.viewingUser = await api.getUser(idOfUpdatedUser);
          }
                  
      }

      console.log('viewingUser', this.viewingUser)
  },
  async getAllUsers(){
    let response = await api.getAllUsers();

    if(response.isError){
      this.allUsers = [];
    }
    else{
      this.allUsers = response.map(user => (
        {
          name: user.name, 
          value: user.id, 
          key: user.lastName+'_'+user.id
        }))

    }
  }
},
async mounted(){
  await ContextLogic.initContext(this);
  this.viewingUser = await api.getUser(this.user.id);

  if(this.user.roles.includes(0))
    this.isLoggedInUserSystemAdmin = true;
  
},
async created() {
  this.dbUserInfo = Util.debounce(this.updateUser, 1000);
  await this.getAllUsers(); 
}
}
</script>