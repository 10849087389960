/*
Responsible for using threejs to render the model, based on the constructor's output.
Ex:
Model says the barn is 30' wide with a window. 
Constructor knows where the beams must fall in order to support that model.

*/
import * as THREE from 'three';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import PickHelper from './helpers/PickHelper'

import { OrbitControls, MapControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls.js';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { SMAAPass } from 'three/examples/jsm/postprocessing/SMAAPass.js';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader.js';
import MemHelper from './helpers/memHelper.js'

import { cameraModes, CORE, modes } from './_spec';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import {PMREMGenerator} from 'three/src/extras/PMREMGenerator'
import { CSS2DRenderer} from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import panelPbrColorMap from '@/assets/images/PBR-panel 3ft Dark 512x512.jpg'
import panelPbrBumpMap from  '@/assets/images/PBR_bump2.jpg';
import doorRollup from  '@/assets/images/doorOverhead2.jpg';
import insulation from  '@/assets/images/Insulation_Texture-512x512.jpg';
import concrete from  '@/assets/images/concrete.jpg';
import beam from  '@/assets/images/Beam512x512.jpg';
import trim from  '@/assets/images/Trim_Texture_Dark_256x256.jpg';
import alphaMap from  '@/assets/images/alpha.jpg';
export default class view {

    constructor(c){   
		this.showstats=true;//window.location.origin.includes("localhost");;

		this.controller = c;
		this.constants = {
			scale: 20
		};
		this.pickHelper = new PickHelper();
		this.options = this.getDefaultOptions();

		this.pickPosition = {x: 0, y: 0};
		
		this.clearPickPosition();

		this.velocity = new THREE.Vector3();
		this.direction = new THREE.Vector3();
		this.moveForward = false;
		this.moveBackward = false;
		this.moveLeft = false;
		this.moveRight = false;
		
		
	}



	toggleLayerByName(layerName){
		for(var layer in CORE.layers)
			if(layer === layerName)
				this.toggleLayerForAllCameras(layer);
	}

	toggleLayerForAllCameras(layer){
		this.cameraOrbit.layers.toggle(CORE.layers[layer]);
		this.cameraFirst.layers.toggle(CORE.layers[layer]);
		this.cameraOrthographic.layers.toggle(CORE.layers[layer]);
	}

	enableLayerForAllCameras(layer){
		this.cameraOrbit.layers.enable(CORE.layers[layer]);
		this.cameraFirst.layers.enable(CORE.layers[layer]);
		this.cameraOrthographic.layers.enable(CORE.layers[layer]);
	}

	disableLayerForAllCameras(layer){
		this.cameraOrbit.layers.disable(CORE.layers[layer]);
		this.cameraFirst.layers.disable(CORE.layers[layer]);
		this.cameraOrthographic.layers.disable(CORE.layers[layer]);
	}

	async initViewEditMode(){
		await this.createScene();
		await this.createEnvironment();
		await this.updateEnvironment();

		await this.setupCameras();

		this.lightGroup = new THREE.Group();
		await this.updateLighting();

		await this.setupRenderers();

		this.ctrlOrbit=null;
		await this.setupControls();

		switch(this.store.getters.camera.mode){
			case cameraModes.layout:
				await this.switchCameraTo_Layout();
				break;
			case cameraModes.orbit:
				await this.switchCameraTo_Orbit();
				break;
		}

		await this.setupStats();
		
		//await this.updateEnvironmentMap();
	}

	async initSandboxMode(){
		this.backgroundColor = 0x555555;
		this.groundColor = 0x444444;
		
		await this.createScene();
		await this.createEnvironment(false);
		await this.updateEnvironment();

		await this.setupCameras();
		
		this.lightGroup = new THREE.Group();
		await this.updateLighting();

		await this.setupRenderers();

		await this.setupControls();

		this.store.dispatch('switchToCamera',  cameraModes.orbit);

		await this.setupStats();
		
		//await this.updateEnvironmentMap();
	}

	setLayersByName(layers){
		for(var layer in CORE.layers){
		  	if(layers[layer]===undefined)
				continue;

			if(layers[layer] === true)
				this.enableLayerForAllCameras(layer);
			else
				this.disableLayerForAllCameras(layer);
			
		}
	  }

	getDefaultOptions(){
		return {
			frame: true,
			walls: false,
			roof: false,
			windows: true,
			walkins: true,
			overheads: true,
		}
	}

	getOptions(){
		return this.options;
	}

	getScene(){
		return this.scene;
	}

	getScreenshotData(){
		var strMime = "image/jpeg";
		this.composer.render();
		return this.renderer.domElement.toDataURL(strMime, .5);
	}
		
	clearPickPosition() {
	// unlike the mouse which always has a position
	// if the user stops touching the screen we want
	// to stop picking. For now we just pick a value
	// unlikely to pick something
		this.pickPosition.x = -100000;
		this.pickPosition.y = -100000;
	}
	
 getCanvasRelativePosition(event) {
	const rect = this.canvas.getBoundingClientRect();
	return {
	  x: (event.clientX - rect.left) * this.canvas.width  / rect.width,
	  y: (event.clientY - rect.top ) * this.canvas.height / rect.height,
	};
  }

  getCanvasRelativePositionTouch(event) {
	const rect = this.canvas.getBoundingClientRect();
	let changedTouch = event.changedTouches[0];
	return {
	  x: (changedTouch.clientX - rect.left) * this.canvas.width  / rect.width,
	  y: (changedTouch.clientY - rect.top ) * this.canvas.height / rect.height,
	};
  }
   
   setPickPosition(event) {
	const pos = this.getCanvasRelativePosition(event);
	this.pickPosition.x = (pos.x / this.canvas.width ) *  2 - 1;
	this.pickPosition.y = (pos.y / this.canvas.height) * -2 + 1;  // note we flip Y
  }

  
  setPickPositionTouch(event) {
	const pos = this.getCanvasRelativePositionTouch(event);
	this.pickPosition.x = (pos.x / this.canvas.width ) *  2 - 1;
	this.pickPosition.y = (pos.y / this.canvas.height) * -2 + 1;  // note we flip Y
  }

  setCameraPosition(x,y,z, set){
	this.animateCamera=true;
	this.cameraPositionTarget.set(x,y,z);	
	if(set)
		this.ctrlOrbit.object.position.set(x,y,z);	
  }
 
  

	
  remove(building){
	if(!building)
	return;

	building.remove(); // memory management reasons
	this.scene.remove(building.group)
  }

	setOutlinedMeshes(meshes){
		// the collision zones are being obscured by transparent outline and pickDetection meshes: https://stackoverflow.com/questions/11165345/three-js-webgl-transparent-planes-hiding-other-planes-behind-them
		// so to work around that (for the most part), we just toggle visibility of outline meshes as they're set/cleared.
		// this is why all components have separate outline / pickDetection meshes from their textured meshes.
		try{
			this.outlinePass.selectedObjects.forEach((f)=>{
				if(f)
					f.visible = false;
			})
			if(!meshes)
				this.outlinePass.selectedObjects = [];
			else{
				this.outlinePass.selectedObjects = Array.from(meshes);
				this.outlinePass.selectedObjects.forEach((f)=>{
					if(f)
						f.visible = true;
				})
			}
		}
		catch(error){
			console.log("setOutlinedMeshes", error.message);
		}
		
	}

		
	async loadAssets(){
    
		let loader = new THREE.TextureLoader();
		CORE.textures = {};
		
		this.store.commit('setLoadingMessage', "Loading assets...");
		CORE.textures.panelPbrColorMap = await loader.load(panelPbrColorMap);
		CORE.textures.panelPbrBumpMap = await loader.load(panelPbrBumpMap);
		CORE.textures.doorRollup = await loader.load(doorRollup);
		CORE.textures.insulation = await loader.load(insulation);
		CORE.textures.concrete = await loader.load(concrete);
		CORE.textures.beam = await loader.load(beam);
		CORE.textures.trim = await loader.load(trim);
		CORE.textures.alphaMap = await loader.load(alphaMap);
		CORE.models = [];
		let twoDec = new Intl.NumberFormat('en-US', {
			style: 'decimal',
			maximumFractionDigits: 2
		}); 

		/*
		this.store.commit('setLoadingMessage', "Loading models...");
		const gltf = new GLTFLoader();
		console.log('loading models: starting')
        await gltf.loadAsync('./Ren3.json',  
		( xhr ) => {
			this.store.commit('setLoadingMessage', "Loading models..."+ twoDec.format(( xhr.loaded / xhr.total * 100 )) + '% loaded');
        })
		.then( (gltf) => {
            
			//console.log(gltf.scene)
            //gltf.scene.scale.set(50,50,50);
			//console.log(gltf.scene)
			CORE.models.push(
				{
					name: "jeep",
					data: gltf.scene
				}
			)
        })
		.catch(        
          ( error ) =>{
            console.error( error );
        }  );
	   	
		console.log('loading models: complete')
		*/
	}

	// limit renders
	firstChanged(ctrlFirst, global) {
		ctrlFirst = ctrlFirst.target;
		global.animate();
	}
	
	// limit renders
	orbitChanged(ctrlOrbit, global) {
		ctrlOrbit = ctrlOrbit.target;
		if(global.animateCamera )
			ctrlOrbit.object.position.lerp(global.cameraPositionTarget, .3);
			
			if(ctrlOrbit.object.position.distanceTo(global.cameraPositionTarget) < 5){
				ctrlOrbit.object.position.set(global.cameraPositionTarget.x,global.cameraPositionTarget.y,global.cameraPositionTarget.z);
				global.animateCamera=false;
			}
			
			global.animate();
	}


	async createEnvironment(includeGround = true){
		this.updateEnvironment();
		let groundColor = 0x9ac0a5
		const groundGeo = new THREE.PlaneGeometry( 80000, 80000 );
		const groundMat = new THREE.MeshStandardMaterial( { color: groundColor } );
		groundMat.emissive = new THREE.Color(Number(groundColor))
		groundMat.emissiveIntensity=.1;
		
		const ground = new THREE.Mesh( groundGeo, groundMat );
		ground.name = 'ground Mesh'
		ground.rotation.x = - Math.PI / 2;
		ground.receiveShadow = false;
		ground.userData = {
			type: CORE.components.ground,
			//id: -3
		}
		if(includeGround)
			this.scene.add( ground );
		
		
		 const skyGeo = new THREE.SphereGeometry( 40000, 32, 15 );
		 const skyMat = new THREE.MeshBasicMaterial({color:this.skySphereColor})
		 skyMat.side = THREE.BackSide;
		
		const sky = new THREE.Mesh( skyGeo, skyMat );
		sky.name = 'sky';
		this.scene.add( sky );
	}

	async updateEnvironment(){

		this.dayTime = true;
		if(this.dayTime)
		{
			// day
			// if(CORE.preferences.des_levelOfDetail.value === CORE.levelOfDetail.high)
			// {
			// 	// high
			// }
			// else{
			// 	// low
			// }
			this.skySphereColor = 0x94bbff; // #94bbff
			this.backgroundColor = Number(this.skySphereColor);
		}
		else 
		{
			// night
			this.backgroundColor = 0x000000;
			this.skySphereColor = 0x94bbff;
			this.ambientColor = 0x000000;
		}

		
		//this.scene.overrideMaterial = new THREE.MeshBasicMaterial({color: 'green'});

		
		this.scene.background = new THREE.Color( this.backgroundColor );
		//this.scene.background = new THREE.Color().setHSL( 0.6, 0, 1 );
		this.scene.fog = new THREE.Fog( new THREE.Color(this.skySphereColor), 10000, 50000 );
		//this.scene.fog = new THREE.Fog( 0xcce0ff, 20000, 30000 );


	}


	async addLighting_LD(){
		//check the docs at this //LINK - docs/topics/lighting.md
				
		var ambient = new THREE.AmbientLight( this.ambientColor, 1 );
		this.lightGroup.add(ambient); 

		const hemiLight = new THREE.HemisphereLight( 0xffffff, 0xffffff, .5 );
		hemiLight.color.setHSL( 0.6, 1, 0.6 ); // blue sky
		hemiLight.color = new THREE.Color(Number(0x333388))
		hemiLight.groundColor = new THREE.Color(Number(0x888888))
		hemiLight.position.set( 0, 5000, 0 );
		//this.lightGroup.add( hemiLight );

	}

	async addLighting_HD(){
		//check the docs at this //LINK - docs/topics/lighting.md

		let a = new THREE.AmbientLight(0xFFFFFF,.6);
		this.lightGroup.add(a);
		a.visible=true;


		let mapSize = 32; //32 works well, 256 is amazing.
		let dlSize = 3000;
		// Add a light like the Sun to generate some nice shadows
		this.directionalLight = new THREE.DirectionalLight(0xFFFFFF, .65);			
		this.directionalLight.castShadow=false;
		this.directionalLight.position.set(-1400,1000,1400);
		//Set up shadow properties for the light
		this.directionalLight.shadow.mapSize.width = mapSize; // default
		this.directionalLight.shadow.mapSize.height = mapSize; // default
		this.directionalLight.shadow.camera.near = 0.5; // default
		this.directionalLight.shadow.camera.far = 1200; // default
		//this.directionalLight.lookAt(0,0,0);
		this.directionalLight.shadow.camera.top = dlSize;
		this.directionalLight.shadow.camera.bottom = -dlSize;
		this.directionalLight.shadow.camera.left = -dlSize;
		this.directionalLight.shadow.camera.right = dlSize;
		this.lightGroup.add(this.directionalLight);

	// Add a light like the Sun to generate some nice shadows
		this.directionalLight2 = new THREE.DirectionalLight(0xFFFFFF, .65);			
		this.directionalLight2.castShadow=false;
		this.directionalLight2.position.set(1400,1000,-1400);
		//Set up shadow properties for the light
		this.directionalLight2.shadow.mapSize.width = mapSize; // default
		this.directionalLight2.shadow.mapSize.height = mapSize; // default
		this.directionalLight2.shadow.camera.near = 0.5; // default
		this.directionalLight2.shadow.camera.far = 1200; // default
		//this.directionalLight2.lookAt(0,0,0);
		this.directionalLight2.shadow.camera.top = dlSize;
		this.directionalLight2.shadow.camera.bottom = -dlSize;
		this.directionalLight2.shadow.camera.left = -dlSize;
		this.directionalLight2.shadow.camera.right = dlSize;
		this.lightGroup.add(this.directionalLight2); 

		this.dlHelper = new THREE.DirectionalLightHelper(this.directionalLight, 50 );
		//this.lightGroup.add( this.dlHelper );

		this.dlHelper = new THREE.DirectionalLightHelper(this.directionalLight2, 50 );
		//this.lightGroup.add( this.dlHelper );
	}

	async updateLighting(){
		MemHelper.removeAllChildren(this.lightGroup);

		if(CORE.preferences && CORE.preferences.des_levelOfDetail && CORE.preferences.des_levelOfDetail.value === CORE.levelOfDetail.high){
			this.addLighting_HD();
		}
		else{
			this.addLighting_LD();
		}
		this.scene.add(this.lightGroup);

		
	}

	async updateEnvironmentMap(){
		//if(CORE.preferences.des_levelOfDetail.value === CORE.levelOfDetail.high)
		{
			let pmremGenerator = new PMREMGenerator(this.renderer, 0, .1, 1000);
			pmremGenerator.compileEquirectangularShader() 

			CORE.envMap = pmremGenerator.fromScene(this.scene).texture;
		}
	}


	async setupCameras(){
		this.orbitCameraHomePosition = new THREE.Vector3( -500, 800, 0 );
		this.firstCameraHomePosition = new THREE.Vector3(0,72,0)

		this.width = this.container.offsetWidth;
		this.height = window.innerHeight - this.container.offsetTop;

		let viewSize = 1900;
		let halfViewSize = viewSize/2;
		// camera
		let orbitNearPlane = 100;
		let orbitFarPlane = 15000;
		

		let aspect = this.width/this.height;
		this.cameraOrbit = new THREE.PerspectiveCamera(30, aspect, orbitNearPlane, orbitFarPlane );
		this.cameraOrbit.name='Orbit cam';
		this.updateCameras();
		this.cameraFirst = new THREE.PerspectiveCamera(30, aspect, 10, 55000 );
		this.cameraFirst.name='First person cam';
		//this.cameraFirst.near = 10;
		
		//this.cameraOrthographic = new THREE.OrthographicCamera(this.width / - 2,this.width / 2, this.height / 2, this.height / - 2, 1, 10000 );
		this.cameraOrthographic = new THREE.OrthographicCamera(-aspect*halfViewSize, aspect*halfViewSize, halfViewSize, -halfViewSize, -1,5000 );
		this.cameraOrthographic.name = 'layout cam'
		
		
		this.cameraPositionTarget = new THREE.Vector3(0,0,0); //new home
		//this.cameraPositionTarget = new THREE.Vector3(-900,500,1200); //home for the longest time
		//this.camera.position.set( -900, 500, 1200 ); //home
		this.cameraOrbit.position.copy(this.orbitCameraHomePosition);
		//this.camera.position.set( 190, 10, 400 ); //debug rafter textures
		//this.camera.position.set( -100, 170, -100 ); //debug tiny eave
		//this.camera.position.set( -20, 120, -20 ); //debug back eave corner
	}

	async updateCameras(){
		let orbitNearPlane = 100;
		let orbitFarPlane = 15100;
		if(CORE.preferences && CORE.preferences.des_levelOfDetail &&
		CORE.preferences.des_levelOfDetail.value === CORE.levelOfDetail.high)
		{
			orbitNearPlane=10;
			orbitFarPlane=55000;
		}
		this.cameraOrbit.near = orbitNearPlane;
		this.cameraOrbit.far = orbitFarPlane;
		

		this.cameraOrbit.updateProjectionMatrix();	
	}

	async setupRenderers(){
		// renderer
		let canvasNode = document.createElement('canvas');
		this.canvas = this.container.appendChild(canvasNode);	
		this.canvas.tabIndex=1;					
		this.context = this.canvas.getContext('webgl2');

		try{
		this.renderer = new THREE.WebGLRenderer( { 
			antialias: true ,
			//preserveDrawingBuffer: true,
			canvas: this.canvas,
			context: this.context,
			stencil: false 
		});
		}catch(e){
			console.error("there was an error while setting up graphics")
			console.error(e)
		}
		this.renderer.setPixelRatio( window.devicePixelRatio );
		this.renderer.setSize( this.width, this.height);
		this.renderer.domElement.id = 'WebGLRenderer'
		this.renderer.outputColorSpace = THREE.SRGBColorSpace;
		this.renderer.shadowMap.enabled = true;
		this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
		

		this.labelRenderer = new CSS2DRenderer();
		this.labelRenderer.setSize( window.innerWidth, window.innerHeight );
		this.labelRenderer.domElement.style.position = 'absolute';
		this.labelRenderer.domElement.style.top = '66px';
		this.labelRenderer.domElement.style.pointerEvents = 'none'
		this.labelRenderer.domElement.id = 'CSS2DRenderer'
		this.labelRenderer.domElement.style.zIndex = '0'
		let rp = document.querySelector('#renderPane');
		rp.appendChild( this.labelRenderer.domElement );
		
		this.renderer.domElement.addEventListener('click', () => {
			if(this.store.getters.camera.mode !== cameraModes.first)
				return;

			if(this.ctrlFirst.isLocked){
				this.ctrlFirst.unlock();					
			}					
			else if(this.store.getters.editingMode === 'view'){
				this.ctrlFirst.lock();					
			}
				
		})
	}

	async setupControls(){
		this.setupPerspectiveEffectComposer(this.cameraOrbit);
		this.setupPerspectiveOrbitControls();
		this.setupOrthographicControls();
		//this.store.dispatch('showFrontLeftPerspective');

		//https://threejs.org/examples/misc_controls_pointerlock.html
		//view-source:https://threejs.org/examples/misc_controls_pointerlock.html
		//https://discourse.threejs.org/t/unable-to-use-pointer-lock-api/11092
		
		this.ctrlFirst = new PointerLockControls(this.cameraFirst, this.renderer.domElement);
		this.ctrlFirst.enabled = false;

		this.ctrlFirst.addEventListener('lock', () => {
			this.store.commit('pointerLocked',true); // keep the store up to date with what only the API truly knows
		})

		this.ctrlFirst.addEventListener('unlock', () => {
			this.store.commit('pointerLocked',false); // keep the store up to date with what only the API truly knows
		})

		
		let _thisView = this;
		this.canvas.addEventListener('focus', () => {
			//alert('has focus press a key');
		});
		
		this.canvas.addEventListener('blur', () => {
			//alert('lost focus');
		});
		
		this.canvas.addEventListener('keydown', (e) => {
			_thisView.controller.inputKeyDown(e);
		});

		this.canvas.addEventListener('keyup', (e) => {
			_thisView.controller.inputKeyUp(e);
		});

		window.addEventListener( 'resize', (e) => {				
			this.onWindowResize(e)
		}, false );
		
		let commonClickInput = function(){
			

			let picked;
			if(_thisView.store.getters.camera.mode === cameraModes.orbit)
				picked = _thisView.pickHelper.pick(_thisView.pickPosition, _thisView.scene, _thisView.cameraOrbit);
			else
				picked = _thisView.pickHelper.pick(_thisView.pickPosition, _thisView.scene, _thisView.cameraOrthographic);

			if(picked!=null)
				_thisView.controller.inputClick(picked);
		}
		this.touching = false;
		let commonMoveInput = function() {
	
			let picked;
			if(_thisView.store.getters.camera.mode === cameraModes.orbit)
				picked = _thisView.pickHelper.pick(_thisView.pickPosition, _thisView.scene, _thisView.cameraOrbit);
			else
				picked = _thisView.pickHelper.pick(_thisView.pickPosition, _thisView.scene, _thisView.cameraOrthographic);
			if(picked===null)
				return
				
			_thisView.controller.inputMove(picked);
			
			
			if(_thisView.touching)
			{
				_thisView.controller.inputActiveMove(picked);
			}			
			
		}

		this.canvas.addEventListener('mousedown', (event) => {
			this.setPickPosition(event);
			this.animateCamera=false;
			//console.debug('mousedown')
			this.touching = true;
			commonClickInput();
		});

		function activeInputEnd(){
			_thisView.controller.activeInputEnd();
		}
		
		this.canvas.addEventListener('mouseup', (event) => {
			this.touching = false;
			//console.debug('mouseup')
			activeInputEnd();
		});

		this.canvas.addEventListener('touchstart', (event) => {
			this.setPickPositionTouch(event);
			this.animateCamera=false;
			commonClickInput();
			});

			this.canvas.addEventListener('touchend', (event) => {				
			this.touching = false;
			activeInputEnd();				
			});
			
		this.canvas.addEventListener('mousemove', (event) => {
			this.setPickPosition(event); // mouse
			//this.setPickPositionTouch(event); // touch
			commonMoveInput();
			});

			this.canvas.addEventListener('touchmove', (event) => {
			//this.setPickPosition(event); // mouse
			this.setPickPositionTouch(event); // touch
			let picked = _thisView.pickHelper.pick(_thisView.pickPosition, _thisView.scene, _thisView.cameraOrbit);
			if(picked===null)
				return
			_thisView.controller.inputActiveMove(picked);
			});
	}

	async setupStats(){

		// performance monitor

		if(this.showstats){
			this.stats = new Stats();
			this.container.appendChild( this.stats.dom );
			this.stats.domElement.id = 'stats'
			this.stats.domElement.style.top = '75px';
			//this.stats.domElement.style.bottom = '0px';
			this.stats.domElement.style.height = '200px';
			this.stats.domElement.style.display = 'none';

		}
	}
	

	
	levelOfDetailChanged(){
		this.updateEnvironment();
		this.updateCameras();
		this.updateLighting();
		//this.updateEnvironmentMap();
	}

	createScene(){
		// scene
		this.scene = new THREE.Scene();
		this.scene.name='scene'
	}

    async init(store){		
		console.log('view.init');
		this.container = document.querySelector('#renderPane');
		
		this.store = store;
		this.store.commit('setLoadingMessage', "Loading scene...");		

		switch(this.store.getters.mode){
			case modes.viewEdit:
				await this.initViewEditMode();
				break;
				case modes.sandbox:
				await this.initSandboxMode()				
				break;
		}

		await this.loadAssets();
		this.animate( 0 );
	}


	setupPerspectiveOrbitControls(){
		
		if(!this.ctrlOrbit)
		{
			
			// orbit controls
			this.ctrlOrbit = new OrbitControls( this.cameraOrbit, this.renderer.domElement );
			// limit renders
			// let _this = this;
			// this.ctrlOrbit.addEventListener('change', function(ctrlOrbit) {
			// 	_this.orbitChanged(ctrlOrbit, _this)
			// 	}
			// 	);
			//this.controls.target.set(0,325,0) //normal center
			this.ctrlOrbit.target.set(0,80,0)
			this.ctrlOrbit.maxPolarAngle = Math.PI / 2.005 ;
			this.ctrlOrbit.minDistance = 100;
			this.ctrlOrbit.maxDistance = 10000;
			//this.controls.change = this.controller.cameraMoved;
			//this.controls.addEventListener( 'change', this.controller.cameraMoved );
			this.ctrlOrbit.enableDamping=true;
			this.ctrlOrbit.dampingFactor=.75;
			this.ctrlOrbit.enabled=true;
			this.ctrlOrbit.enablePan=true;
			this.ctrlOrbit.autoRotate=true;
			this.ctrlOrbit.autoRotateSpeed=0;
		}
	}


	enableControls(){
		switch(this.store.getters.camera.mode){
			case cameraModes.orbit:
				this.enableOrbitControls();
				break;

			case cameraModes.free:
				break;

			case cameraModes.first:
				this.ctrlFirst.enabled = true;				
				break;
				
			case cameraModes.layout:
				if(this.ctrlOrtho)
				this.ctrlOrtho.enabled=true;
				break;
		}
	}

	disableControls(){
		switch(this.store.getters.camera.mode){
			case cameraModes.orbit:
				this.disableOrbitControls();
				break;
			case cameraModes.free:
				break;
			case cameraModes.first:
				this.ctrlFirst.enabled = false;
				break;
			case cameraModes.layout:
				this.ctrlOrtho.enabled=false;
				break;
		}
	}

	enableOrbitControls(){
		if(this.ctrlOrbit.enabled===false)
			this.ctrlOrbit.reset();			
		if(!this.ctrlOrbit.enabled===true){
			this.ctrlOrbit.enabled=true;
		}
		
	}

	disableOrbitControls(){
		if(this.ctrlOrbit.enabled===true)
			this.ctrlOrbit.saveState();
		  if(!this.ctrlOrbit.enabled===false){
			this.ctrlOrbit.enabled=false;
		}
	}

	processKeyDownFirstPerson(event){
		switch ( event.code ) {

			case 'Space':
				//this.togglePointerLock();
				break;
			case 'ArrowUp':
			case 'KeyW':
				this.moveForward = true;
				break;

			case 'ArrowLeft':
			case 'KeyA':
				this.moveLeft = true;
				break;

			case 'ArrowDown':
			case 'KeyS':
				this.moveBackward = true;
				break;

			case 'ArrowRight':
			case 'KeyD':
				this.moveRight = true;
				break;

			

		}
	}

	processKeyUpFirstPerson(event){
		switch ( event.code ) {

			case 'ArrowUp':
			case 'KeyW':
				this.moveForward = false;
				break;

			case 'ArrowLeft':
			case 'KeyA':
				this.moveLeft = false;
				break;

			case 'ArrowDown':
			case 'KeyS':
				this.moveBackward = false;
				break;

			case 'ArrowRight':
			case 'KeyD':
				this.moveRight = false;
				break;
		}
	}

	onWindowResize() {
		this.width = this.container.offsetWidth;
		this.height = window.innerHeight - this.container.offsetTop;
		this.cameraOrbit.aspect = this.width/this.height;
		this.cameraOrbit.updateProjectionMatrix();
		this.renderer.setSize( this.width, this.height );
		this.labelRenderer.setSize( this.width, this.height );
		this.composer.setSize( this.width, this.height );
	}

	processControls(delta){
		if(!this.store)
			return

		switch(this.store.getters.camera.mode){
			case 'orbit':
				this.processControls_Orbit(delta);
				break;
			case 'first':
				this.processControls_First(delta);
				break;
		}
	}

	renderLabels(){
		if(!this.store)
			return
		switch(this.store.getters.camera.mode){
			case 'first':
				this.labelRenderer.render(this.scene, this.cameraFirst); // this is required to properly hide the labels when their layer is disabled
				break;
			case 'orbit':
				this.labelRenderer.render(this.scene, this.cameraOrbit);
				break;
			case 'layout':
				this.labelRenderer.render(this.scene, this.cameraOrthographic);
				break;
		}
		
	}

	switchCameraTo_Orbit(){
		// clean up previous camera mode
		if(this.ctrlFirst.isLocked)
			this.ctrlFirst.unlock();
		this.ctrlFirst.enabled=false;		
		this.ctrlOrtho.enabled=false;

		////////////////////
		// setup next camera
		// set position
		//this.cameraOrbit.position.copy(this.orbitCameraHomePosition);
		this.animateCamera=false;
		// adjust near clipping plane
		
		//this.cameraOrbit.updateProjectionMatrix();
		this.setupPerspectiveEffectComposer(this.cameraOrbit);
		//this.setupPerspectiveOrbitControls();
		this.ctrlOrbit.enabled=true;
	}

	switchCameraTo_First(){
		// clean up previous camera mode		
		if(this.ctrlOrbit)
			this.ctrlOrbit.enabled=false;
		if(this.ctrlOrtho)
			this.ctrlOrtho.enabled=false;

		////////////////////
		// setup next camera
		// calculate a position near front left corner
		
		
		if(this.cameraFirst.position.x === 0 &&  this.cameraFirst.position.y === 0 && this.cameraFirst.position.z === 0){
			let frontLeftPadding = -230;
			let camPos = new THREE.Vector3(frontLeftPadding, 72, -frontLeftPadding);
			this.cameraFirst.position.copy(camPos);
		}		
		
		//this.cameraFirst.updateProjectionMatrix();
		this.setupPerspectiveEffectComposer(this.cameraFirst);
		
		this.ctrlFirst.enabled=true;		
	}

	switchCameraTo_Layout(){
		// clean up previous camera mode
		if(this.ctrlFirst.isLocked)
			this.ctrlFirst.unlock();
		this.ctrlFirst.enabled=false;
		this.ctrlOrbit.enabled=false;

		////////////////////
		// setup next camera
		// set position
		this.composer.renderer.clear();
		this.cameraOrthographic.position.set(0,3000,0);
		
		this.cameraOrthographic.lookAt(0,0,0);
		
		this.setupOrthographicEffectComposer();
		this.ctrlOrtho.enabled=true;
		this.ctrlOrtho.update();
	}


	
	setupPerspectiveEffectComposer(camera){
		//let aspect = this.width/this.height;
		//this.cameraPerspective = new THREE.PerspectiveCamera(30, aspect, 100, 55000 );
		this.composer = new EffectComposer(this.renderer);

		let renderPass = new RenderPass(this.scene, camera);
		this.composer.addPass( renderPass );

		this.outlinePass = new OutlinePass( new THREE.Vector2( this.width, this.height ), this.scene, camera);
	
		var params = {
			edgeStrength: 5.0,
			edgeGlow: 3.0,
			edgeThickness: 1.0,
			pulsePeriod: 5,
			rotate: false,
			usePatternTexture: false
		};

		this.outlinePass.edgeStrength = params.edgeStrength
		this.outlinePass.edgeGlow = params.edgeGlow;
		this.outlinePass.edgeThickness =params.edgeThickness
		this.outlinePass.pulsePeriod = params.pulsePeriod;
		this.outlinePass.usePatternTexture = params.usePatternTexture;
		this.outlinePass.visibleEdgeColor.set( '#ffffff' );	
		this.outlinePass.hiddenEdgeColor.set( '#aaaaff' );
	
		this.composer.addPass( this.outlinePass );//

		const pr = this.renderer.getPixelRatio()
		const passAA = new SMAAPass( window.innerWidth * pr, window.innerHeight * pr );
		this.composer.addPass( passAA );
	}

	setupOrthographicEffectComposer(){
		this.composer = new EffectComposer(this.renderer);
		let renderPass = new RenderPass(this.scene, this.cameraOrthographic);
		this.composer.addPass( renderPass );
	}

	setupOrthographicControls(){
		
		if(!this.ctrlOrtho)
		{			
			this.ctrlOrtho = new OrbitControls(this.cameraOrthographic, this.renderer.domElement );
			this.ctrlOrtho.minPolarAngle=0;
			this.ctrlOrtho.maxPolarAngle=0;	
			this.ctrlOrtho.maxAzimuthAngle=0;
			this.ctrlOrtho.minAzimuthAngle=0;
			this.ctrlOrtho.enableRotate=false;		
			this.ctrlOrtho.enabled=false;
			this.ctrlOrtho.maxZoom = 15;
			this.ctrlOrtho.minZoom = .5;
		}
	}
	
	processControls_First(delta){

		if ( this.ctrlFirst.isLocked === false ) 
			return;
		let decay = .9;
		let speed = 1000;
		this.velocity.x *=decay;//-= this.velocity.x * .01 * delta;
		this.velocity.z *=decay;//-= this.velocity.z * .01 * delta;
		this.direction.z = Number( this.moveForward ) - Number( this.moveBackward );
		this.direction.x = Number( this.moveRight ) - Number( this.moveLeft );
		this.direction.normalize(); // this ensures consistent movements in all directions

		if ( this.moveForward || this.moveBackward ) {
			this.velocity.z -= this.direction.z * speed* delta;
		}
		if ( this.moveLeft || this.moveRight ) {
			this.velocity.x -= this.direction.x * speed * delta;
		}

		this.ctrlFirst.moveRight( - this.velocity.x * delta );
		this.ctrlFirst.moveForward( - this.velocity.z * delta );
		//this.cameraPositionTarget.position.copy(this.ctrlFirst.object.position);
		
	}

	processControls_Orbit(){
		if(this.ctrlOrbit.enabled)
			this.ctrlOrbit.update();

		if(this.animateCamera )
			this.ctrlOrbit.object.position.lerp(this.cameraPositionTarget, .3);
			{
			if(this.ctrlOrbit.object.position.distanceTo(this.cameraPositionTarget) < 5){
				this.ctrlOrbit.object.position.set(this.cameraPositionTarget.x,this.cameraPositionTarget.y,this.cameraPositionTarget.z);
				this.animateCamera=false;
			}
		}
	}

	getObjectTriVertCounts(print=false){
		let scene = this.scene;
		let objects = 0, vertices = 0, triangles = 0;

		for ( let i = 0, l = scene.children.length; i < l; i ++ ) {

			const object = scene.children[ i ];

			object.traverseVisible( function ( object ) {

				objects ++;

				if(print)
					console.log('object: ', object.type, object.name)

				if ( object.isMesh ) {
					
					const geometry = object.geometry;
					if(print)
						console.log('geometry: ', geometry.name)

					vertices += geometry.attributes.position.count;

					if ( geometry.index !== null ) {

						triangles += geometry.index.count / 3;

					} else {

						triangles += geometry.attributes.position.count / 3;

					}

				}

			} );

		}

		return {objects, triangles, vertices}
}


	animate( now ) {
		if(this.pointLight && this.pointLight.parent!=null)
			this.pointLight.position.set(this.cameraOrbit.position.x, this.cameraOrbit.position.y+20,this.cameraOrbit.position.z  );
		//this.directionalLight.position.set(this.camera.position.x, this.camera.position.y+1000,this.camera.position.z  );
		this.time = performance.now();

		let deltaRAF = requestAnimationFrame( this.animate.bind(this) ); // limit renders (comment)

		let delta = (this.time - this.prevTime)/1000;
		//simulate( now );
		
		this.composer.render();
		this.renderLabels()
		
		
		this.processControls(delta);  // limit renders (comment)
		//let objects =0
		//let tris = 0
		//let verts =0
		


		if(this.showstats)
			this.stats.update();
		
		this.prevTime = this.time;
	}	

	tjsrender() {		
		this.lightHelper.update();	

		//this.dlHelper.update();
		this.renderer.render( this.scene, this.cameraOrbit );

	}
}
