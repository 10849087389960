<template>
    <div class="subPanel">
            <div v-if="shouldShowQuoteLayer">                    
                <div><span>Toggle off quote view to control individual layers.</span></div>
            </div>
            <div v-else>    
                <div class="grid">
                    <div>
                        <VisibilityToggle  text='Walls' layer='walls' />
                        <VisibilityToggle  text='Frame' layer='frame'/>
                        <VisibilityToggle  text='Roof' layer='roof'/>
                        <VisibilityToggle  text='Windows' layer='windows'/>
                        <VisibilityToggle  text='Walkin Doors' layer='doorsWalkin'/>
                        <VisibilityToggle  text='Overhead Doors' layer='doorsOverhead'/>
                        <VisibilityToggle  text='Dimensions' layer='dimensions'/>
                        <VisibilityToggle v-if="editable" text='Opening Dimensions' layer='openingDimensions'/>            
                        <VisibilityToggle  text='Structure Names' layer="buildingLabels" />
                    </div>
                </div>
            </div>
    </div>
 </template>
 
 <script>
 import { CORE, cameraModes} from '../js/_spec.js'
 import VisibilityToggle from './visibilityToggle.vue'
 import { ToggleButton } from 'vue-js-toggle-button' //https://www.npmjs.com/package/vue-js-toggle-button
 import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
 export default {
     data: ()=>{
         return {            
             CORE,
             showViewingAngles:false,
         }
     },
     computed:{
       ...mapGetters('designModule',[
         'inCameraModeFirst',
         'inCameraModeOrbit',
         'inCameraModeLayout',
         'currentLayers',
         'shouldShowQuoteLayer',
         'showQuote'
       ]),
       showQuoting: {
         get() {
           return this.$store.state.designModule.showQuoting
         },
         set(value) {
           this.$store.commit('designModule/showQuoting', value)
         }
       },
       lod(){
             let v = this.$store.getters.lod == 'high'
             console.log(v);
             return v;
         },
     
         isInLayoutMode(){
       return this.$store.getters.isInLayoutView;
     },
     editable(){
       return this.$store.state.editable;
     },
     },
     methods:{
       ...mapActions('designModule',[
         'updateQuoting',
         'showFront',
         'showBack',
         'showLeft',
         'showRight',
         'showFrontLeftPerspective',
         'showFrontRightPerspective',
         'showBackLeftPerspective',
         'showBackRightPerspective',
         'showToggleLayers',
         'showQuoteLayer',
         'switchToCamera',
         'cycleOrbitCamera', 
       ]),
       ...mapMutations('designModule',[
         'setDetail',
       ]),
       toggleLod(){
             if(CORE.preferences.des_levelOfDetail.value == CORE.levelOfDetail.low)
                 this.setDetail(CORE.levelOfDetail.high);
             else
                 this.setDetail(CORE.levelOfDetail.low);
         },
     hideViewingAngles(){
       this.showViewingAngles=false;
     },
     _showFront(){
         this.showFront()
         this.hideViewingAngles();
     },
     _showBack(){
         this.showBack()
         this.hideViewingAngles();
     },
     _showLeft(){
         this.showLeft()
         this.hideViewingAngles();
     },
     _showRight(){
         this.showRight()
         this.hideViewingAngles();
     },
     _showFrontLeftPerspective(){
         this.showFrontLeftPerspective()
         this.hideViewingAngles();
     },
     _showFrontRightPerspective(){
         this.showFrontRightPerspective()
         this.hideViewingAngles();
     },
     _showBackLeftPerspective(){
         this.showBackLeftPerspective()
         this.hideViewingAngles();
     },
     _showBackRightPerspective(){
         this.showBackRightPerspective()
         this.hideViewingAngles();
     },
     useToggleView(){
             this.showToggleLayers();
     },
     useQuoteView(){
         this.showQuoteLayer();
     },
     toggleQuoteView(){
       console.log('should show quote layer', this.shouldShowQuoteLayer)
         if(this.shouldShowQuoteLayer)
             this.useToggleView();
         else    
             this.useQuoteView();
     },    
         useLayoutCamera(){
       this.switchToCamera(cameraModes.layout);
     } ,       
     useFirstPersonCamera(){
       this.switchToCamera(cameraModes.first);
     },
     useOrbitCamera(){
       // this is used simply to switch from another camera mode (first or layout) to orbit (no rotation)
       this.switchToCamera(cameraModes.orbit);
     },
     // cycleOrbitCamera(){
     //   // this is used to iterate through three orbit modes (no rotation, slow rotation, fast rotation)
     //   this.cycleOrbitCamera();
     // },
     },
     components:{
         VisibilityToggle,
         ToggleButton,
         }
 }
 </script>
 
 <style scoped>
 
 </style>