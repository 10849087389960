
import * as THREE from 'three';
import {CORE, rebuildTypes , rakeTrimStyles, eaveTrimStyles, impactTypes} from '../_spec.js';
import Foundation from './foundationbase';
import RoofPorchSlope from './RoofPorchSlope';
import BlueprintHelper from '../helpers/blueprintHelper.js'
import cStructureBase from './StructureBase'
import OptionHelper from '../helpers/optionHelper.js'
import BuildLogic from './BuildLogic.js';

import Util from '../utility.js'
import FramelineHelper from '../blueprints/FramelineHelper.js'
import { Vector3, Quaternion } from 'three';
import _3dTrimCorner from '../3d/TrimCorner.js'
import _3dTrimGutterDownspout from '../3d/TrimGutterDownspout.js'
import _3dColumnStraight from '../3d/ColumnStraight.js'
import _3dRafterStraight from '../3d/RafterStraight.js';
import _3dColumnSquare from '../3d/ColumnSquare.js'
import _3dDistHori from '../3d/DistHori.js'
import layerHelper from '../helpers/layerHelper.js';
import StructureHelper from '../helpers/StructureHelper.js';
import Grabject from './grabject.js';
import UpdateHelper from '../helpers/UpdateHelper.js';
import materialHelper from '../helpers/materialHelper.js';
import PickHelper from '../helpers/PickHelper.js';
import TreeNode from '../helpers/TreeNode.js';
import ConstraintHelper from '../helpers/ConstraintHelper.js';
import StructureBase from './StructureBase';
import { isPowerOfTwo } from 'three/src/math/MathUtils.js';
import DesignManager from '../design.js';
import SystemHelper from '../helpers/SystemHelper.js';
import DesignHelper from '../helpers/DesignHelper.js';
import ColorHelper from '../helpers/colorHelper.js';
import Appearance from './appearance.js';
import Options from './options.js';
import FeatureHelper from '../helpers/featureHelper.js';

export default class StructureSecondary extends cStructureBase{
    /*
    this class constains the shared methods between lean-to's and porches
    */   

    constructor(design, side, mDesign, model, pos, rot){        
        super(design, side, mDesign, model, pos, rot);
    }

    
    initRebuildHierarchy(){        
        this.rebuildTypeRoot = new TreeNode(null, rebuildTypes.full);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.roof);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.pitch);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.width);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.wall);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.trim);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.move);
    }

    getRotationInputEnabled()
    {
        return false;
    }

    buildBackFrameSide(){
        let constrainerConstraint = ConstraintHelper.getConstraintWithChildId(this.masterDesign.constraintRootNode,this.design.id);        
        if(!constrainerConstraint)
            return;
        if(constrainerConstraint.child.matingSide == CORE.sides.backSide){
            return;
        }
        super.buildBackFrameSide();
            
        
    }

    // buildBackSideWall(trimMaterials, frame, wallSideLength,insulation) {
    //     let constrainerConstraint = ConstraintHelper.getConstraintWithChildId(this.masterDesign.constraintRootNode,this.design.id);        
    //     if(!constrainerConstraint)
    //         return;
    //     if(constrainerConstraint.child.matingSide == CORE.sides.backSide && !this.design.backWall){
    //         return;
    //     }
    //     this.backWallDesign.height = this.maxRidgeHeight - this.design.dim.heightOffset;
    //     super.buildBackSideWall(trimMaterials, frame, wallSideLength,insulation, this.beamColor, this.purlinColor);   
    // }

    buildNewBSW(trimMaterials, frame, insulation, beamColor, girtColor){
        let constrainerConstraint = ConstraintHelper.getConstraintWithChildId(this.masterDesign.constraintRootNode,this.design.id);        
        if(!constrainerConstraint)
            return;
        if(this.design.type === CORE.components.porch && constrainerConstraint.child.matingSide == CORE.sides.backSide && !this.design.backWall){
            return;
        }
        //this.backWallDesign.openWall = false;
        //this.backWallDesign.getBackSideBays().forEach((bay)=>{bay.openWall = false})
        this.backWallDesign.height = this.maxRidgeHeight - this.design.dim.heightOffset;
        super.buildNewBSW(trimMaterials, frame, insulation, beamColor, girtColor);   
    }

    updateMaxRidgeHeight(maxRidgeHeight){
        this.maxRidgeHeight = maxRidgeHeight;
        
        if(this.updateHeightFromHeightOffset(this.design, this.frameDesign, this.maxRidgeHeight))
            this.addRebuildNeeded(rebuildTypes.full);
    }
    
    static updateRoofPitch(design, v){

        let interval = 1.0/4.0;
        v = Math.round(v/interval)*interval;

        let frame = design.getFrame()
        let roof =  design.getRoof();
        let width = frame.width;

        let oldPitchRatio = roof.pitch/12;
        let oldPitchHeight = width * oldPitchRatio; 
        let oldRidgeHeight = frame.height + oldPitchHeight
        let newPitchRatio = v/12;
        let newPitchHeight = width * newPitchRatio; 

        roof.pitch = v;

        if(design.type === CORE.components.leanTo){
            let delta = (oldPitchHeight - newPitchHeight)*12;
            this.updateWallHeights(design.getFrontWall(), design.getFrontWall().getFrontSideBays(), delta);
            this.updateWallHeights(design.getLeftWall(), design.getLeftWall().getLeftEndBays(), delta);
            this.updateWallHeights(design.getRightWall(), design.getRightWall().getRightEndBays(), delta);
        }
        
        frame.height = oldRidgeHeight - newPitchHeight;
        console.log(`${oldRidgeHeight} - ${newPitchHeight} = ${frame.height}`)
        
        this.updatePitchHeight(design);
    }
    
    static updateWallHeights(wall, bays, delta){
        // detect how the change in pitch affected the wall's height
        wall.height += delta;
        bays.forEach((b)=>{
            b.height+=delta;
        })

        // ensure that the wall height is positive
        if(wall.height < 0)
        {
            wall.height = 0
            bays.forEach((b)=>{
                b.height=0;
            })
        }
        
    }

    static updatePitchHeight(design){
        let width = design.getFrame().width;
        let pitchRatio = design.getRoof().pitch/12;
        design.pitchHeight =  width * pitchRatio; 
    }

    static updateRoofFrameType(design, v){
        design.roofFrameType = v;
    }


    /*
    detectSelection(change, type){

        switch(type)
        {
            default:
                return this.detectSelectionDefault(change);
        }
    }

    detectSelectionDefault(change){

        //console.log(`${this.design.side} Porch detectSelectionDefault`)
        const {left: adjLeftSide, right: adjRightSide} = BlueprintHelper.getAdjacentSides(this.design.side);
        
        // if this impact is for a porch adjacent to this porch
        if(adjLeftSide=== change.design.side && this.design.left.wrapEnabled)
        {
            //console.log(` left is wrapped`)
            return {
                design: this.design
            }
        }

        if(adjRightSide=== change.design.side && this.design.right.wrapEnabled)
        {
            //console.log(` right is wrapped`)
            return {
                design: this.design
            }
        }
    }
    */

    
    getRelativePosition(worldPosition, role){
        let componentSceneWorldPos = new THREE.Vector3();
        this.group.getWorldPosition(componentSceneWorldPos)
        //componentSceneWorldPos.z+=this.design.dim.width;

        let relPos = new THREE.Vector3().subVectors(componentSceneWorldPos,worldPosition);
        relPos.y = worldPosition.y - componentSceneWorldPos.y;
        
        // shortcut if there's a simple, known answer
        if(role === 'pitch')
            return new THREE.Vector3(relPos.x, relPos.y, 0) // 
        if(role === 'bottom'){
            //console.log('grpfw: ',this.getRelativePositionFromWorld(worldPosition));
            //componentSceneWorldPos.z+=this.design.dim.width+4; // works for front, but not back
            //relPos = new THREE.Vector3().subVectors(worldPosition, componentSceneWorldPos);
            //console.log(`Comp Scene wp ${componentSceneWorldPos.z} - click wp ${worldPosition.z} = ${relPos.z}`);
            //console.log(`click wp ${worldPosition.z} - com scene wp ${componentSceneWorldPos.z} = ${relPos.z}`);
            relPos = this.getRelativePositionFromWorld(worldPosition);
            let newP= new THREE.Vector3(relPos.x, 0,  Math.abs(relPos.z));
            return newP;
        }
        
        if(Math.abs(relPos.x) < .01) // the world position has the same X as the clicked plane
            return new THREE.Vector3(-relPos.z, relPos.y,0)
        if (Math.abs(relPos.z) < .01) // otherwise, the world position has the same Z ()
            return new THREE.Vector3(relPos.x, relPos.y, 0) // 
        throw 'Unable to detect which axis to drop '
    } 

    getRelativePositionFromWorld(world){
        // 2021.09.28 this is a legit 
        let componentSceneWorldPos = new Vector3();
        this.group.getWorldPosition(componentSceneWorldPos)
        let componentSceneWorldQuat = new Quaternion();
        this.group.getWorldQuaternion(componentSceneWorldQuat);

        let worldPos = new Vector3().copy(world);
        worldPos = new Vector3().subVectors(worldPos,componentSceneWorldPos);
        worldPos.applyQuaternion(componentSceneWorldQuat);
        
        return worldPos;
    }

    
    getWorldPositionFromRelative(rel){
        let componentSceneWorldPos = new Vector3();
        this.group.getWorldPosition(componentSceneWorldPos)
        let componentSceneWorldQuat = new Quaternion();
        this.group.getWorldQuaternion(componentSceneWorldQuat);
        let relPos = new Vector3().copy(rel);
        relPos.applyQuaternion(componentSceneWorldQuat);
        relPos = new Vector3().addVectors(relPos,componentSceneWorldPos);
        return relPos;
    }

    
    gridSnapWorldPosition(world){
        //TODO: this seems wasteful at best and wrong at worst
        let w = new Vector3().copy(world);
        this.group.worldToLocal(w);                
        let worldSnapped = this.getWorldPositionFromRelative(w);
        return worldSnapped;
    } 

    applyDesign(design){
        this.design.collided= design.collided,
        this.design.pos = new Vector3( design.pos.x, design.pos.y, design.pos.z),
        this.design.type = design.type,
        //this.design.id = design.id,
        this.design.parent = {};
        this.design.parent.id = design.parent.id;

        if(Util.isUndefined(this.design.dim))
            this.design.dim = {};
        this.design.dim.width= design.dim.width;
        this.design.dim.height= design.dim.height;
        this.design.dim.length= design.dim.length;

        this.design.dim.parentHeight = design.dim.parentHeight;
        this.design.dim.parentPitchHeight = design.dim.parentPitchHeight;     
        this.design.dim.parentRoofPitch = design.dim.parentRoofPitch;

        this.design.frame.height = design.frame.height;
        this.design.dim.heightOffset = design.dim.heightOffset;
        this.updateRoofPitch(this.design, design.roofPitch);
        this.design.selected= false;
    }

    processInputGrabject(input){
        if(input.role === CORE.grabjects.position){
            throw 'Lean-To position grabject is not currently supported'
        }
        else
            return this.updateSize(input);
    }

    
    updateSize(input){

        let {role, relPosition} = input;
        let newCoordY = relPosition.y;
        let newCoordX;
        newCoordX = relPosition.x;
        //oldCoordX = this.design.pos.x;

        let impact;
        switch(role){
            case CORE.grabjects.topLeft:
                impact = this.grabjectChangeLeft(newCoordX);                
                break;
            case CORE.grabjects.top:
                impact = this.grabjectChangeTop(newCoordY);
                break;
            case CORE.grabjects.topRight:
                impact = this.grabjectChangeRight(newCoordX);
                break;
            case CORE.grabjects.bottom:
                newCoordX = relPosition.z;
                //oldCoordX = this.design.pos.z;
                impact = this.applyChangeWidth(relPosition.z);
                if(impact){
                    this.addRebuildNeeded(rebuildTypes.width);
                    return impact;
                }
                break;
            case CORE.grabjects.pitch:
                impact = this.grabjectChangePitch(newCoordY);                
                if(impact){
                    this.addRebuildNeeded(rebuildTypes.pitch);
                    return impact;
                }
                break;
        }
        
        // return details regarding the impact this input change had, if any
        if(impact){
            this.addRebuildNeeded(rebuildTypes.full);
            return impact;
        }
    }

    detectImpact(impact){
        // if this impact affects my design, 
        //  update my design 
        //  generate an impact.
        // set rebuildType

        if(this.isOwnImpact(impact))
        {
            if(this.shouldProcessOwnImpact(impact, this.design.id)){
                this.processImpactFromSelf(impact);
            }
        }
        else{
            if(!this.hasHandledImpact(impact))
                return this.processImpactFromOther(impact);
        }
    }

    getSideOfSecondaryStructureParent(id){
        let constraint = ConstraintHelper.getConstraintWithChildId(this.masterDesign.constraintRootNode, id);
        return constraint.parent.matingSide
    }
    
    processRebuild(type){
        //console.log(`processRebuild ${this.design.id} ${this.getDescription()}`)
        switch(type){            
            
            case rebuildTypes.full:
                this.destroy(); // everything
                this.create();  // everything                
                break;
            case rebuildTypes.width:
            case rebuildTypes.pitch:
            case rebuildTypes.roof:
                this.rebuildNonPick();
                break;
        }
    }

    rebuildNonPick(){
        this.remove(true,true,true);
        this.initBlueprints();
        //this.buildStaticPart1()
        //this.buildDynamicOnly();
        //this.buildStaticPart2();
        this.build();
    }

    destroyPickGroup(){
        super.destroyPickGroup();
        delete this.meshPickDetectionX
        delete this.meshPickDetectionXY
    }

    static canEdit(){return true;}

    useDefaultDesigns() {
        // TODO: CBH: I think this is a bug because this is a direct reference to another structure's appearance object
        this.appearanceDesign = this.masterDesign.design.getSystem().getStructure().getAppearance();
        
        this.optionDesign = DesignHelper.getDefaultOptions();
        this.foundationDesign = DesignHelper.getDefaultFoundationDesign();
    }

    detectMatingFramelines(){
        this.design.matingframeLines = this.generateFrameLineData(this.frameDesign, this.masterDesign).points;
        this.design.frameLines = this.design.matingframeLines;
    }

    getOutlineMeshes(){        
        let meshes = []
        
        meshes.push(...this.roof.getOutlineMeshes())
        //meshes.push(...this.foundation.getOutlineMeshes());
        return meshes;
    }

    buildDynamicOnly(){
        super.buildDynamicOnly();
        //let pitchHeight = 0; // unsure how to migrate
        //console.log(`height: ${this.frameDesign.height}, pitchHeight: ${this.design.pitchHeight}`);
        //let dim = new THREE.Vector3(this.frameDesign.length, this.frameDesign.height-pitchHeight, this.frameDesign.width).multiplyScalar(12);
        //this.buildGrabjects(dim);
    }
    
    create(){
        super.create();
        
        this.built=true;
    }


    destroy(){
        super.destroy();
    }

    getBackOffset(){
        //TODO: static columnWidth = 8
        let columnWidth = 8;
        let backOffset = new Vector3(0,0,-columnWidth);
        return backOffset;
    }
    
    buildRoof(trimMaterials, frontEaveSegments, frontDownspouts, backEaveSegments, backDownspouts){
        //super.buildTransitionTrim(ConstraintHelper.getConstraintWithChildId(this.masterDesign.constraintRootNode,this.design.id), trimMaterials.eaveAndRake)

        if(this.design.type === CORE.components.leanTo){
            super.buildRoof(trimMaterials, frontEaveSegments, frontDownspouts, backEaveSegments, backDownspouts)
        }
        else if(this.design.type === CORE.components.porch){
            let roofPos = this.getRoofPosition();

            let rotTexture = this.design.side === CORE.sides.backSide; // this is necessary for back lean-to roof texture alignment.
            this.roofDesign.gutters = this.optionDesign.gutters
            this.roofDesign.color = ColorHelper.to3(this.appearanceDesign.roof);

            this.roof = new RoofPorchSlope(this.roofDesign, this.frameDesign.frameType, this.structureWidthInches(), this.structureLengthInches(), trimMaterials, roofPos, 0, rotTexture, this.roofDesign.pitch, this.getPurlinColor())
            this.roof.group.position.set(0, this.getStructureHeightInches(), 0);
            this.groupMetal.add(this.roof.group);
        }
    }
    generateFrameLineData(desFrame, masterDesign){
        return StructureSecondary.generateFrameLineData(desFrame, masterDesign)
    }

    static generateFrameLineData(desFrame, masterDesign){


        // generate frameDesign based on standard bay spacing rules
        let frameData = FramelineHelper.generate(desFrame, BuildLogic.getOutsideFramelineInset(), desFrame.length*12)

        
        let constrainerConstraint = ConstraintHelper.getConstraintWithChildId(masterDesign.constraintRootNode,desFrame.parent.id);
        // a secondary structure will always have a constraining structure (may or maybe not be modeled in the data though)
        if(constrainerConstraint){
            // get the design for the constraining structure
            let constainerStructureDesign = masterDesign.getComponentDesignById(constrainerConstraint.parent.structureID);

            if(!constainerStructureDesign)
            {
                // the parent structure for this shed may have been deleted!
                return frameData;
            }
            

            // get the design for the frame of the constraining structure            
            let constrainerFrameDesign = constainerStructureDesign.getFrame();

            // we have to get the correct set of column positions for the lean-to to inherit from
            let constrainerColumnPositions;
            switch(constrainerConstraint.parent.matingSide){                
                case CORE.sides.frontSide:
                case CORE.sides.backSide:
                    
                    // get the column positions for the frame of the constraining structure
                    constrainerColumnPositions = constrainerFrameDesign.columnPositions;
                    break;
                case CORE.sides.leftEnd:
                    // get the design for the frame of the constraining structure            
                    // get the column positions for the frame of the constraining structure
                    constrainerColumnPositions = constainerStructureDesign.frameLeftEndColumnPositions;
                    break;
                case CORE.sides.rightEnd:
                    // get the design for the frame of the constraining structure            
                    // get the column positions for the frame of the constraining structure
                    constrainerColumnPositions = constainerStructureDesign.frameRightEndColumnPositions;                    
                    break;                
            }

            // detemine the length of the side/end along which this constrained secondary structure is mated to the constraining structure
            let matingWidth = SystemHelper.getConstraintPartMatingLength(constrainerConstraint.child, desFrame);
            // determine where the constraining structure mating point
            let constrainerX = SystemHelper.getConstrainerRelPosX(constrainerConstraint.parent, constrainerFrameDesign);
            let inheritedPoints = [];
            let columnWidth = 8;
            let halfColumnWidth = columnWidth/2;
            let minimumSpaceBetweenFramelineAndSoldier=12;
            constrainerColumnPositions.forEach((p)=>{
                // apply the offset in order to put the framelines of the constraining structure in terms of this secondary structure
                let offsetConstrainerFrameline;
                if(constrainerConstraint.parent.matingSide === CORE.sides.frontSide)
                    offsetConstrainerFrameline = p-constrainerX;
                if(constrainerConstraint.parent.matingSide === CORE.sides.backSide)
                    offsetConstrainerFrameline = matingWidth-p+(constrainerX-matingWidth);
                if(constrainerConstraint.parent.matingSide === CORE.sides.leftEnd)
                    offsetConstrainerFrameline = p-constrainerX;
                if(constrainerConstraint.parent.matingSide === CORE.sides.rightEnd)
                    offsetConstrainerFrameline = matingWidth-p+(constrainerX-matingWidth);;
                
                // if the main structure has a frameline within 12 inches of the start of the lean-to, 
                if(offsetConstrainerFrameline <= halfColumnWidth + minimumSpaceBetweenFramelineAndSoldier)
                    return; // don't tie into this frameline of the constraining structure
                // if the main structure has a frameline within 12 inches of the end of the lean-to, 
                if(offsetConstrainerFrameline >= matingWidth-halfColumnWidth - minimumSpaceBetweenFramelineAndSoldier)
                    return; // don't tie into this frameline of the constraining structure 
                
                // TODO: avoid creating a secondary structure bay larger than 28ft
                inheritedPoints.push(offsetConstrainerFrameline);
            })
       
            // add the "compareFunction" (a-b) so that numbers are sorted by asc order and not by the first digit in the number like so:
            // we got [288, 576, 864, 1151] => [1151, 864, 576, 288] but we wanted: [288, 576, 864, 1151]
            inheritedPoints.sort(function(a, b){return a-b});
            frameData.points = [halfColumnWidth, ...inheritedPoints, matingWidth-halfColumnWidth]
            
        }
        
        return frameData;
    }

    build(){
        super.build()
        this.buildSideLabels();
    }

    getBackSideLabelText(){
        return "High Side"
    }

    getFrontSideLabelText(){
        return "Low Side"
    }

    buildFrame(leanToFrameLineIndices, trimMaterials){
        super.buildFrame(leanToFrameLineIndices);
    }

    buildFrontGutterTrim(frame, trimMaterials, offsetFromStructure, offsetFromBottom, eaveTrimHeight){
        if(this.design.type === CORE.components.leanTo){
            return super.buildFrontGutterTrim(frame, trimMaterials, offsetFromStructure, offsetFromBottom, eaveTrimHeight)
        }
        else if(this.design.type === CORE.components.porch){
            // gutters go on front (slope) or front and back (gable).
            // front gutters (always)
            let frontZ = this.structureWidthInches()/2;
            
            let gutterPositions = [];
            let gutterHeights = []        

            let frontEaveSegments = [];
            let frontDownspouts = [];
        
        let fullHeightFront=(this.getFrontSideWallHeightInches());

            // there is no front lean-to on a lean-to
            // use one continuous eave trim across the front
            frontEaveSegments = [{
                start:  this.getWallFrontLeftTop(),
                end: this.getWallFrontRightTop(),
                leftHoriAngle: Math.PI/4,
                rightHoriAngle: Math.PI/4
                }]

            // set gutter positions and heights            
            // TODO: remove posX defect
            gutterPositions = Array.from(this.design.frameLines);//.map((fl)=>fl.posX); // gutter at every frameline
            //if(this.design.left.wrap)
                //gutterPositions.splice(0,0, -this.structureWidthInches()); // add one for wrap left
            //if(this.design.right.wrap)
                //gutterPositions.push(gutterPositions[gutterPositions.length-1]+frame.design.width*12); // add one for wrap left
            gutterHeights = gutterPositions.map((gp)=>fullHeightFront-offsetFromBottom); // full height at every frameline
            
            
            if( this.optionDesign.gutters===true){
                gutterPositions.forEach((posX,i)=>{ 

                    let g = new _3dTrimGutterDownspout(gutterHeights[i], trimMaterials.downspout);
                    this.groupMetal.add(g.group);
                    let pos = new THREE.Vector3(posX -this.structureLengthInches()/2, fullHeightFront-eaveTrimHeight, frontZ+offsetFromStructure)
                    
                    g.group.position.set(pos.x,pos.y,pos.z);
                    //if(i===0 && this.design.left.wrap)
                        //g.group.rotation.y = -Math.PI/4;
                    layerHelper.enableLayer(g.group, CORE.layers.quote);
                    this.gutters.push(g);
                    frontDownspouts.push({
                        x: posX,
                        length: gutterHeights[i],
                    })
                });
            }
        
            return {frontEaveSegments,frontDownspouts};
        }
    }

    updateHeightFromHeightOffset(design, frameDesign, maxRidgeHeight){

        let newWidthInches = frameDesign.width*12;
        let pitchRatio = BlueprintHelper.pitchToPitchRatio(design.getRoof().pitch);
        let pitchHeight =  BlueprintHelper.pitchHeight(newWidthInches, pitchRatio, design.getRoof().roofType);

        let newRidgeHeight = (maxRidgeHeight - design.dim.heightOffset);
        let newLowEaveHeightInches = newRidgeHeight - pitchHeight;
        let oldLowEaveHeightInches = frameDesign.height*12;
        if(frameDesign.height === newLowEaveHeightInches/12)
            return false;
        
        let delta = newLowEaveHeightInches - oldLowEaveHeightInches;

        // fix the wall heights here too
        this.updateBayHeights(delta);
        let leftWall = design.getLeftWall();
        if(leftWall)
            leftWall.height += delta;
        let rightWall = design.getRightWall();
        if(rightWall)
            rightWall.height += delta;
        let frontWall = design.getFrontWall();
        if(frontWall)
            frontWall.height += delta;
        let backWall = design.getBackWall();
        if(backWall)
            backWall.height += delta;

        frameDesign.height = newLowEaveHeightInches/12;

        return true;
    }

    getWidthMinimum()
    {
        return 4; // ft
    }
    // applyChangeWidth(newWidthInches){

    //     // bound the input
    //     if(newWidthInches < this.getWidthMinimum()*12)
    //         newWidthInches = this.getWidthMinimum()*12;
    //     let newWidthFeet = newWidthInches/12;
    //     if(newWidthFeet === this.frameDesign.width)
    //         return; // short circuit

        
    //     // calculate new values from some existing value
    //     // use the old ridge height and the new width to calculate the new lowEaveHeight
    //     let pitchRatio = BlueprintHelper.pitchToPitchRatio(this.design.getRoof().pitch);
    //     let oldPitchHeight =  BlueprintHelper.pitchHeight(this.frameDesign.width*12, pitchRatio, this.design.getRoof().roofType);
    //     let ridgeHeight = this.frameDesign.height*12 + oldPitchHeight;
        
    //     let newPitchHeight =  BlueprintHelper.pitchHeight(newWidthInches, pitchRatio, this.design.getRoof().roofType);
    //     let newLowEaveHeight = ridgeHeight - newPitchHeight;


    //     // apply new values
    //     this.frameDesign.width = newWidthFeet;
    //     this.frameDesign.height = newLowEaveHeight/12;

    //     return UpdateHelper.createImpact(this.design, impactTypes.leanToWidth);
    // }
    
    // grabjectChangePitch(newY){
    //     // record old
    //     let oldRoofPitch = this.roofDesign.pitch;
        
    //     let pitchRatio = BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch);
    //     let pitchHeight = BlueprintHelper.pitchHeight(this.structureWidthInches(), pitchRatio, this.roofDesign.roofType )
    //     // calculate roofPitch height options
    //     let roofPitchHeights = [];

    //     for(var i=1;i<=6; i++)
    //         roofPitchHeights.push(12*this.frameDesign.height +pitchHeight- (12*this.frameDesign.width*i/12));
        
    //     let {closestIndex} = PickHelper.getClosestValueToTarget(roofPitchHeights, newY);
    //     //console.log(roofPitchHeights, closestIndex, newY)
    //     let newRoofPitch = closestIndex+1;
    //     if(newRoofPitch === oldRoofPitch)
    //         return;
    //     // calculate nearest roof pitch
    //     StructureLeanTo.updateRoofPitch(this.design, newRoofPitch);
    //     return UpdateHelper.createImpact(this.design, impactTypes.leanToPitch);
    // }

    
    updateComponents(){
        this.components= []; // forget previous components
        // register these components so that sub-components and components they contain can be found
    this.addComponent(this.foundation);
    this.addComponent(this.frame);
    this.addComponent(this.roof);
    this.addComponent(this.appearance);
    this.addComponent(this.options);
    this.addComponent(this.jobSpecs);       
    this.addComponent(this.wallFront);
    this.addComponent(this.wallRight);
    this.addComponent(this.wallBack);
    this.addComponent(this.wallLeft);

    this.partitions.forEach((p) => this.addComponent(p));

        if(this.leftEndSkirt)
            this.addComponent(this.leftEndSkirt)

        if(this.rightEndSkirt)
            this.addComponent(this.rightEndSkirt)
    }

}
