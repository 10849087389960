<template>   
    <div>
        <label class="composite-field" v-if="colors.length > 0">
            <div class="text">
                <div class="swatch-1" :style="{'background-color': colors.find(c => c.id == selectedValue).mediaHex}"></div>
            </div>   
            <select :id="uniqueId" v-model="selectedValue" class="form-control" style="padding:5px;" v-on:change=colorChange(selectedValue) :disabled="disable">
                <option v-for="col in colors" :value="col.id" :style="getOptionBackgroundColor(col)">
                    {{ col.name }}
                </option>
            </select>
        </label>
        <label v-else class="text-red-600">
            No colors found.
        </label>
    </div>
</template>

<!-- Color Select 2 is a copy of the original color select vue component -->
<!-- It's used on the Customer Settings page and the way changes are emited up the chain
        needed to work differently on that page than it does elsewhere. This vue component has a v-on:change on the select/option element instead of a watch on the value.
        This change was needed for whenever an employee is switching customers, the initialization of the color selector should not emit a color change -->

<script>
import NinjaColorHelper from '@/lib/colorHelper';
import util from '@/lib/utility';

    export default {
        name: 'color-select2',
        props: ['colors', 'selected', 'disable', 'settingName'],
        data: function () {
            return {
                uniqueId: '',
                color: {},
                selectedValue: null
            };
        },
        created(){
            this.selectedValue = this.selected;
            this.uniqueId = `color_${this.jFnRandomId()}`;
            let foundColor = NinjaColorHelper.findById(this.colors, Number(this.selected));
            this.color = foundColor;
            
        },
        computed: {

        },
        methods: {
            jFnRandomId: function() {
                return '_' + Math.random().toString(36).substr(2, 9);
            },
            getHex: function (hex) {
                if (hex == null || hex == "")
                    return '';
                if (hex.length > 0 && hex[0] == '#')
                    return hex;
                return `#${hex}`;
            },
            getOptionBackgroundColor: function (col) {
                if (col) {
                    const hex = col.designerHex;
                    let s = `background-color: ${hex};`;
                    if (this.isDark(hex))
                        s += ` color: white;`;
                    return s;
                }
            },
            isDark(hexColor) {
                // Claude AI
            // Remove # prefix if it exists
            hexColor = hexColor.replace(/^#/, '');
            
            // Validate hex color format
            if (!/^[0-9A-Fa-f]{6}$/.test(hexColor)) {
                throw new Error('Invalid hex color format. Expected 6 characters (RGB)');
            }
            
            // Convert hex to RGB
            const r = parseInt(hexColor.slice(0, 2), 16);
            const g = parseInt(hexColor.slice(2, 4), 16);
            const b = parseInt(hexColor.slice(4, 6), 16);
            
            // Calculate perceived brightness using YIQ formula
            // YIQ = (R * 299 + G * 587 + B * 114) / 1000
            const yiq = (r * 299 + g * 587 + b * 114) / 1000;
            
            // YIQ < 128 is considered dark
            return yiq < 128;
        },
            colorChange(selected) {
                // let found = this.colors.find(c => c.id == selected);
                
                // this.color = util.deepCopy(found);//$.extend(true, {}, found);
                //this.$emit('input', util.deepCopy(found));
                this.$emit('change', {id:this.settingName, val:String(selected)});
            }
        },
        watch: {
            'selected': function(newSelect) {
                if (newSelect) {
                    this.selectedValue = newSelect;
                }
            },
            'colors': function(newColors) {
                console.log('colors',this.colors)
                console.log('selected color', this.selectedValue)
            },

        },
        mounted: function () {
            if (this.selected)
                this.selectedValue = this.selected;
            else
                this.selectedValue = NinjaColorHelper.first(this.colors)
        },
    }
</script>
<style scoped>
   /* Colors */
   .text-white { color: white; }
    .text-red-600 { color: #dc2626; }
</style>