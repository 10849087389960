<template>
  <div >
    
    <div class="grid" style="--grid-template-columns-sm:  auto 1fr;">
      
      <div class="border-ie page-layout-1 pad-ie-5" >        
        <div class="search-picker" ref="searchPicker">
          <label class="sr-only" for="helpSearch">Composite Field (Icon)</label>
          <label class="composite-field no-dividers darker" style="--this-border-radius: var(--br-full);">
            <div class="text">
              <Icon name="magnifying-glass" custom-class="icon icon-size-1 mar-is-4 shift-be-2" style="margin-inline-end: calc(var(--sp-1) * -1);" />
            </div>
            <!-- I'm here fighting this debounce & search query race condition -->
            <input id="helpSearch" v-model="searchQuery" @input="debouncedSearch"           
            class="darker"  type="text" name="input" placeholder="Search Help Topics" autocomplete="off">
          </label>
          <div class="grid gap-5 mar-bs-5">
            <h2 class="h4" v-if="!searching">
              {{ listLabel }}               
            </h2>            
            <h2 class="h4" v-if="searching">
              Searching 
            </h2>            
          </div>

          <StatusIndicator ref="progress"></StatusIndicator>
          <HelpArticleList ref="list" v-if="!searching" :iArticles="articles" @selected="articleLinkClicked"></HelpArticleList>
          <a v-if="!searching && (!searchMode || this.results.length==0)" href="#" @click="$router.push('/changeLog')" class="mar-1">Change Log</a>
          
        </div>
      </div>

      <div class="mar-3" >
        <HelpArticle :md="md" @articleLinkClicked="articleLinkClicked"></HelpArticle>        
      </div>
    </div>  
    
    
  </div>
</template>


<script>
import HelpArticle from '@/components/HelpArticle.vue';
import HelpArticleList from '@/components/HelpArticleList.vue';
import ProgressSpinner from '@/components/ProgressSpinner.vue';
import StatusIndicator from '@/components/StatusIndicator.vue';
import Util from '@/lib/utility';
import {articles} from '@/help/content'

function sortStrings(arr) {
 return arr.sort((a, b) => {
   const x = a.title.toLowerCase();
   const y = b.title.toLowerCase();
   if (x < y) return -1;
   if (x > y) return 1;
   return 0;
 });
}

sortStrings(articles)

export default {
  name: 'HelpView',
  data(){
    return {
      mode:'search',
      searchQuery:'',
      md:'',
      articles,  
      searching:false,
      results:[],      
    }
  },  
  computed:{
    searchTerm(){
      return this.searchQuery.toLowerCase().trim()
    },
    searchMode(){
      return this.searchQuery.trim()!=''
    },
    listLabel(){
      // if in the middle of a search
      if(this.searching)
        return 'Searching...'
      // if a search was requested
      if(this.searchMode){
        // exactly zero results
        if(this.results.length==0)
          return 'No Matches. All Articles:'
        // exactly one results
        else if(this.results.length==1)          
          return `1 Match:`
        else
          // multiple results
          return `${this.articlesToShow.length} Matches:`
      }
      else
        // no search requested
        return 'Articles:'
    },
    articlesToShow(){
      // if there are search results
      if(this.results.length>0)
        return this.results; // show those
      // otherwise, show all articles
      return this.articles;
    }
  },
  methods:{
    articleLinkClicked(link){
      const path = `/support/${link}`
      if(this.$router.currentRoute.path!= path)
        this.$router.push({path})
    },    
    doSearch(){
      const term = this.searchTerm;
      this.results = [];
      // do the search
      this.articles.forEach((article)=>{
        if(article.title.toLowerCase().includes(term) || 
          article.tags.find(tag => tag.toLowerCase().includes(term)))
          this.results.push(article);
      });
      
      this.searchDone();

      // if there are no results
      if(this.results.length==0)
        return; // short circuit

      // auto-show the result if there's only 1
      if(this.results.length == 1)
      {        
        this.$nextTick(()=>{
          this.$refs.list.setSelected(0);
          this.showArticle(this.results[0].id);
        });
      }
    },
    searchDone(){
      this.$refs.progress.hide();
      this.searching = false;
      this.$nextTick(()=> {
        if(this.$refs.list)
          // force reactivity in child component
          this.$refs.list.setArticles(this.articlesToShow);
      })
    },
    showArticle(id) {
      console.log(id);
      const picked = this.articles.find(item => item.id == id)
      console.log(picked);
      if (picked) {
        this.md = picked.src;
      }
    }, 
    debouncedSearch(){
      const term = this.searchTerm;
      if(term==='') {
        this.results = [];
        this.searchDone();
        return;
      }

      this.searching=true;
      this.results=[];
      this.$refs.progress.loading();

      // allow the user a moment to enter more input
      Util.debounce(() => {        
        this.doSearch()      
      }, 250)();
    }   
  },
  created(){
    // if user is navigating from a link to a specific article
    let articleId = this.$route.params["articleId"]    
    if(!articleId)
      return;

    //const paramPage = this.articles.find(item => item.id == id);
    this.$nextTick(()=>{
      // give the article list a tick to load, or there are no articles in which to find this particular one!
      this.showArticle(articleId);
    })
    
  },
  components:{
    HelpArticle,
    HelpArticleList,
    ProgressSpinner,
    StatusIndicator
  }
}
</script>


<style scoped>
</style>