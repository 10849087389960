<template>
  <button class="main-menu-button" v-on="$listeners" @click="routeTo" :class="{ active: active }">
    <Icon v-if="icon" :name="icon" custom-class="icon-size-2 block" />
    <span>
      <slot></slot>
    </span>
  </button>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'SideBarLink',
  props: {
    routePath: String,
    query: Object,
    icon: String,
    activeRoutes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      linkRoute: null
    };
  },
  computed: {
    active() {
      return this.mainMenuLinkIsActive();
    }
  },
  methods: {
    ...mapMutations('contextModule', ['refresh']),
    
    mainMenuLinkIsActive() {
      if (!this.$route || !this.linkRoute) return false;
      
      // If activeRoutes prop is provided, check if current route matches any pattern
      if (this.activeRoutes.length > 0) {
        return this.activeRoutes.some(pattern => {
          // Convert route pattern to regex
          const regexPattern = pattern
            .replace(/:\w+/g, '[^/]+') // Replace :id with any non-slash chars
            .replace(/\//g, '\\/');    // Escape forward slashes
          const regex = new RegExp(`^${regexPattern}$`);
          return regex.test(this.$route.path);
        });
      }
      console.log('fallback')
      // Fall back to exact route matching if no activeRoutes provided
      return this.alreadyHere();
    },

    alreadyHere() {
      return this.$route.path == this.routePath &&
        (typeof this.query == 'undefined' ||
          JSON.stringify(this.$route.query) == JSON.stringify(this.query));
    },

    routeTo(e) {
      if (!this.linkRoute)
        this.$emit('click', e)
      if (this.alreadyHere())
        this.refresh();
      else if(this.routePath)
        this.$router.push({ path: this.routePath, query: this.query });
    }
  },
  mounted() {
    if(this.routePath)
      this.linkRoute = this.$router.matcher.match(this.routePath);
  }
};
</script>

<style scoped>

</style>