<template>      
    <div>        
        <div v-for="comp in comps"  :key="comp.id" :value="comp.id">
            <treeObject  ref="objs" :obj="comp" :describe="true"  /> 
        </div>         
    </div>
</template> 

<script>

import { bool } from 'three/examples/jsm/nodes/shadernode/ShaderNode';
import treeObject from './treeObject.vue'

export default {
    name: 'treeObjectList',
    components:{
        treeObject: treeObject,
    },
    props: {
        comps: Array,
        indent: Boolean
    },
    data: ()=>{
        return {
            expanded:true,
            selected: 0
        };
    },    
    computed:{
        
        selectedComp() {
            if(this.comps.length==1 || this.selected==0)
                return this.comps[0];
            console.log('selected', this.selected);
            if(this.selected==0)
                return {}
            let s = this.comps.filter((c) => c.id == this.selected)[0];
            console.log('selectedComp', s);
            return s
        },
        
      
    },
    created: function(){
    },
    mounted: function(){
        console.log(this.comps)
    },
    methods: {  
        expandAll() {
            this.$refs.objs.forEach((c)=>{
                c.expandAll();
            })
        },
        collapseAll() {
            this.$refs.objs.forEach((c)=>{
                c.collapseAll();
            })
        },
        hasChildren(child) {
            return child.components && child.components.length>0
        },
        onSelect() {
            console.log('selected', this.selected)
        }
        
    }  
}
</script> 

<style scoped>

</style>


