const API_BASE_URL = process.env.VUE_APP_API_BASE_URL
const USE_TEST_DATA = process.env.VUE_APP_USE_TEST_DATA === "true";
import { getInstance } from "./auth/index.js";
import Util from "./lib/utility.js";
import { testProject, testProjects, testEntities } from "./testData";
import { jwtDecode } from "jwt-decode";

class Api {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }


  getApiRequestPath(endpoint){
    const url = `${this.baseUrl}${endpoint}`;
    return url;
  }

  getAuthToken(){
    return `${localStorage.getItem('token')}`;
  }


  async request(endpoint, method = 'GET', data = null, stringifyBody=true) {
    if (USE_TEST_DATA) {
      return this.getTestData(endpoint);
    }

    if(this.isTokenExpired()){
      const authService = getInstance();
      localStorage.removeItem('token');
      try{
        localStorage.setItem('token', await authService.getTokenSilently())
      }
      catch (error){
        console.log(error);        
      }
    }

    let authToken = localStorage.getItem('token');
    const url = this.getApiRequestPath(endpoint)
    const headers = {
      //'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
      'X-Request-ID': crypto.randomUUID(),
      'X-Request-Time': new Date().toISOString()
    };
    

    const options = {
      method,
      headers,
      //credentials: 'include', // This is important for handling cookies, if your API uses them
    };

    // check if we are sending FormData
    const isFormData = data instanceof FormData;

    if (data && (method === 'POST' || method === 'PUT' || method === 'PATCH')) {
      //if the data if FormData, do not set Content-Type
      if(isFormData){}
        //headers['Content-Type'] = 'multipart/form-data'
      else
        headers['Content-Type'] = 'application/json';
      options.body = stringifyBody ? JSON.stringify(data) : data;
    
    }

    try {
      const response = await fetch(url, options)

      if (!response.ok) {
        if(authToken) // if we don't have an auth token, this is expected to fail (can't do this earlier, due to some allowed anonymous requests)
          throw new Error(`HTTP error! Could not connect to the server. Status: ${response.status}`);

      }
   
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        let resp = await response.json();
        return resp;
      } else {
        return await response.text();
      }
    } catch (error) {
      console.error('API request failed:', error);
      throw error;
    }
  }

  getTestData(endpoint) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (endpoint === '/projects') {
          resolve({ data: testProjects });
        } else if (endpoint.startsWith('/projects/')) {
          const projectId = endpoint.split('/')[2];
          let testProject = testProjects.find(o => o.id === projectId)
          resolve({ data: testProject });
        } else if(endpoint.startsWith('/entities')){
          resolve({ data: testEntities});
        } else {
          resolve({ data: null });
        }
      }, 200); // Simulate network delay
    });
  }

  isTokenExpired(){
    const token = localStorage.getItem('token');
    if(!token) return true;

    const decoded = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000); // current time in seconds

    return decoded.exp < currentTime;

  }

  //#region COMMON methods
  async get(endpoint) {
    return this.request(endpoint);
  }

  async post(endpoint, data, stringifyBody=true) {
    return this.request(endpoint, 'POST', data, stringifyBody) ;
  }

  async put(endpoint, data) {
    return this.request(endpoint, 'PUT', data);
  }

  async patch(endpoint, data) {
    return this.request(endpoint, 'PATCH', data);
  }

  async delete(endpoint) {
    return this.request(endpoint, 'DELETE');
  }
  async sayHi(){
    return this.get(`/businessManager/sayHi`);
  }
  //#endregion
 
  //#region BUSINESS methods
  async getAllBusinesses() {
    return this.get(`/businessManager`)
  }
  async getBusiness(id){
    return await this.get(`/business/${id}`);
  }
  async getBusinessLimited(id){
    return await this.get(`/business/getBusinessLimited/${id}`);
  }
  async setBusinessSetting(settingsGroup){
    return this.post(`/business/setBusinessSetting`, settingsGroup)
  }
  async getAllSuppliers(){
    return this.get(`/businessManager/getAllSuppliers`)
  }
  async getAllContractors(){
    return this.get(`/businessManager/getAllContractors`)
  }
  async getSubBusinesses(id){
    return this.get(`/businessManager/getSubBusinesses/${id}`);
  }
  async getContractorsByRep(sub){
    return this.get(`/businessManager/getContractorsByRep/${sub}`)
  }
  //#endregion


  //PRICING
  async getPricingConfig(businessId){
    return this.get(`/business/${businessId}/pricingConfig`);
  }
  async setPricingConfig(businessId, config){
    return this.post(`/business/${businessId}/pricingConfig`, config);
  }
  async saveTaxOptions(businessId, data){
    return this.put(`/price/updateTaxOptions/${businessId}`, data)
  }
  async getAllBusinessTaxOptions(businessId){
    return this.get(`/price/getTaxOptions/${businessId}`);
  }
  async deleteTaxOption(option){
    return this.post(`/price/deleteTaxOption`, option)
  }

  //#region PROJECT methods
  async getProjects(businessId){
    let path = `/project/list`;
    if(businessId)
      path = `${path}?businessId=${businessId}`
    
    return this.get(path)
  }
  async getProject(projectId){
    return this.get(`/project/${projectId}`)
  }

  async setProjectPriceLock(projectId,days){
    return this.post(`/project/${projectId}/priceLock/${days}`)
  }

  async getProjectHistory(ref){
    return this.get(`/project/${ref}/history`)
  }

  async requestCsvBomExport(payload){
    return await this.post('/project/requestCsvBomExport', payload)
  }

  async getContractorFeatureFlagsFromProjectRef(ref){
    return await this.get(`/project/featureFlags/${ref}`)
  }
  
  async uploadDocuments(payload){
    return await this.post(`/project/uploadDocuments?reference=${payload.ref}`, payload.documents, false)
  }

  async userCanApprove(ref){
    return await this.get(`/project/approval?reference=${ref}`);
  }
  // design > a review state (engineer or pm)
  async requestProjectReview(ref){
    return await this.post(`/project/requestReview`, ref)
  }

  async setProjectState(ref, toState){
    return await this.post(`/project/designStateAction?reference=${ref}&toState=${toState}`)
  }



  async handleOutdatedPricing(projects){
    return await this.post(`/project/handleOutdatedPricing`, projects)
  }

  async updatePricing(ref){
    return await this.get(`/project/${ref}/updateProjectPricing`)
  }
  //#endregion

  //#region DESIGN methods
  async getDesign(reference){
    return await this.get(`/design/${reference}`)
  }

  async getIsDesignEditable(reference){
    return await this.get(`/project/${reference}/editable`)
  }

  async saveDesign(body){
    return await this.post(`/design/save`, body, false)
  }

  async loadDesign(body){
    return await this.post(`/designs/loadDesign`, body)
  }
  async quoteDesign(body){
    return await this.post(`/design/quoteDesign3`, body, false);
  }

  async backupProjectQuotesFolder(reference){
    return await this.post(`/project/${reference}/backupQuotesFolder`);
  }
  async deleteProjectQuotesFolder(reference){
    return await this.post(`/project/${reference}/deleteQuotesFolder`);
  }
  async quoteProject(reference){
    return await this.post(`/project/${reference}/save`);
  }
  async deleteProjectFolder(reference){
    return await this.post(`/project/${reference}/deleteProjectFolder`);
  }


  //#endregion

  async requestCsvBomExport(payload){
    return await this.post('/project/requestCsvBomExport', payload)
  }
  //#region USER methods
  async getMe(){
    return this.get(`/user`)
  }
  async getUser(identifier){
    return this.get(`/user/${identifier}`)
  }
  async getUserLimited(identifier){
    return this.get(`/user/getUserLimited/${identifier}`)
  }
  async getUsersFromSupplier(businessId){
    return this.get(`/user/getUsersFromSupplier/${businessId}`)
  }
  async getUsersFromContractor(businessId){
    return this.get(`/user/getUsersFromContractor/${businessId}`)
  }
  async setUserSetting(payload){
    return this.post(`/user/setUserSetting/${payload.userId}`, payload.settingsGroup);
  }
  async getUserSettings(userId){
    return this.get(`/user/getUserSettings/${userId}`);
  }
  async getAllUsers(){
    return this.get('/user/getAllUsers')
  }
  //#endregion



  //#region ITEM LIST methods
  async getItemListForEntity(businessId){
    return this.get(`/itemlist/entity/${businessId}`)
  }

  async getItemListForSystem(){
    return this.get(`/itemlist/entity/${1}`)
  }
  async getMappingOptionsForBusiness(businessId){
    return await this.get(`/itemList/getMapping/${businessId}`);
  }
  async getUnmappedItemsForBusiness(businessId){
    return await this.get(`/itemList/getUnmappedItemsForBusiness/${businessId}`);
  }
  async updateItems(businessId, supplierItemsToUpdate){
    return await this.post(`/itemList/updateItems/${businessId}`, supplierItemsToUpdate);
  }

  //#endregion

  // ITEMS
  async getTrimProfilesForEntity(businessId){
    return this.get(`/items/entity/${businessId}/trimProfiles`)
  }

  async getPanelsForEntity(businessId){
    return this.get(`/items/entity/${businessId}/panels`)
  }

  async getPanelProfiles(){
    return this.get(`/items/panelProfileTypes`)
  }
  async updateItem(itemToUpdate, businessId){
    return this.put(`/items/updateItem/${businessId}`, itemToUpdate)
  }


  // SETTINGS

  

  //////////////////////// END SETTINGS


  // COLORS

  async getProjectColors(lowestRepBusinessId){
    return this.get(`/project/${lowestRepBusinessId}/colors`)
  }
  async getSupplierColors(supplierId){
      return this.get(`/color/${supplierId}`);
  }
  async getSupplierForUser(userId){
    return this.get(`/user/${userId}/supplier`);
  }

  async updateColor(color){
    return await this.post(`/color`, color);
  }
  async deleteColor(id){
    return await this.delete(`/color/delete/${id}`)
  }

  //////////////////////// END COLORS


//////////////////////// BEGIN CACHE

async clearCache_Project(){
  return await this.get(`/cache/clear/project`)
}
async clearCache_Business(){
  return await this.get(`/cache/clear/business`)
}
async clearCache_User(){
  return await this.get(`/cache/clear/user`)
}
//////////////////////// END  CACHE
  
}

export default new Api(API_BASE_URL);