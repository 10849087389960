<template>
  <div>
    <div class="box-shadow-1 mar-0 pad-0 color-context-neutral-light" style="border-radius: var(--br-4); overflow: hidden;">
        <div class="pad-4" >
            <div class="grid gap-3" style="grid-template-columns: repeat(3, 1fr); grid-template-rows: repeat(3, 1fr);" id="demojs-250128151311-view-preset-buttons" >
                <div>
                    <button class="porchToggle icon-text-button-2" @click="_wrapShed(CORE.sides.backSide, CORE.sides.leftEnd)" :class="{selected: hasBackLeftPorch}" :disabled="backLeftDisabled" title='Back Left'>
                        <span class="sr-only">Back Left</span>
                        <Icon aria-hidden="true" focusable="false" name="line-arrow-diagonal-upper-left-large" custom-class="icon icon-size-1 rotate-180"/>
                    </button>
                </div>
                <div>
                    <button class="porchToggle icon-text-button-2" @click="togglePorch(CORE.sides.backSide)" :class="{selected: hasBackSidePorch}" :disabled="isBackPorchDisabled">
                        <span>Back</span>
                    </button>
                </div>
                <div>
                    <button class="porchToggle icon-text-button-2" @click="_wrapShed(CORE.sides.rightEnd, CORE.sides.backSide)" :class="{selected: hasBackRightPorch}" :disabled="backRightDisabled" title='Back Right'>
                        <span class="sr-only">Back Right</span>
                        <Icon aria-hidden="true" focusable="false" name="line-arrow-diagonal-upper-left-large" custom-class="icon icon-size-1 rotate-270"/>
                    </button>
                </div>
                <div>
                    <button class="porchToggle icon-text-button-2" @click="togglePorch(CORE.sides.leftEnd)" :class="{selected: hasLeftSidePorch}" :disabled="isDisabled">
                        <span>Left</span>
                    </button>
                </div>
                <div>
                    <div class="icon-text-button-2-spacer"></div>
                </div>
                <div>
                    <button class="porchToggle icon-text-button-2" @click="togglePorch(CORE.sides.rightEnd)" :class="{selected: hasRightSidePorch}" :disabled="isDisabled">
                        <span>Right</span>
                    </button>
                </div>
                <div>
                    <button class="porchToggle icon-text-button-2" @click="_wrapShed(CORE.sides.leftEnd, CORE.sides.frontSide)" title='Front Left' :class="{selected: hasFrontLeftPorch}" :disabled="frontLeftDisabled">
                        <span class="sr-only">Front Left</span>
                        <Icon aria-hidden="true" focusable="false" name="line-arrow-diagonal-upper-left-large" custom-class="icon icon-size-1 rotate-90"/>
                    </button>
                </div>
                <div>
                    <button class="porchToggle icon-text-button-2" @click="togglePorch(CORE.sides.frontSide)" title=''  :class="{selected: hasFrontSidePorch}" :disabled="isDisabled">
                        <span>Front</span>
                    </button>
                </div>
                <div>
                    <button class="porchToggle icon-text-button-2" @click="_wrapShed(CORE.sides.frontSide, CORE.sides.rightEnd)" title='Front Right' :class="{selected: hasFrontRightPorch}" :disabled="frontRightDisabled">
                        <span class="sr-only">Front Right</span>
                        <Icon aria-hidden="true" focusable="false" name="line-arrow-diagonal-upper-left-large" custom-class="icon icon-size-1"/>
                    </button>
                </div>
            </div>
        </div>
    </div><!-- box -->

    
        
    </div>
  
</template>

<script>
import { trueGray } from 'tailwindcss/colors.js';
import {CORE} from '../js/_spec.js'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  props: { },
  data: ()=>{
      return {
         CORE,
      };
  },
  mounted(){
    
  },  
  computed:{
    ...mapGetters('designModule', [
      'structure',
      'selectedComponent',
    ]),

    structureName(){
        if(this.structure)
            return this.structure.design.name;
        return "";
    },
    selectedComp(){
        return this.selectedComponent     
    },
    isDisabled(){
        if(this.selectedComp){
            if(this.selectedComp.design.type === CORE.components.structure || this.selectedComp.design.type === CORE.components.leanTo)
             return false;
        }

        return true;
    },
    isParentLeanTo(){
        if(this.selectedComp && this.selectedComp.design.type === CORE.components.leanTo){
            return true
        }
        return false;
    },
    isBackPorchDisabled(){
        if(!this.isDisabled){
            if(!this.isParentLeanTo)
                return false
        }
        return true;
      },
    isMainStructure(){
        return this.selectedComp && this.selectedComp.design.type === CORE.components.structure;
    },
    frontSidePorch(){
        if(!this.isMainStructure && !this.isParentLeanTo)
            return null;
        return this.selectedComp.getFrontSidePorch();     
    },    
    backSidePorch(){
        if(!this.isMainStructure)
            return null;
        return this.selectedComp.getBackSidePorch();
    },
    leftSidePorch(){
        if(!this.isMainStructure && !this.isParentLeanTo )
            return null;
        return this.selectedComp.getLeftEndPorch();
    },
    rightSidePorch(){
        if(!this.isMainStructure && !this.isParentLeanTo)
            return null;
        return this.selectedComp.getRightEndPorch();
    },    
    hasFrontSidePorch(){
        if(!this.isMainStructure)
            return null;
        return this.selectedComp.hasFrontSidePorch();
        // return this.frontSidePorch !== null        
    },
    hasBackSidePorch(){
        if(!this.isMainStructure)
            return null;
        return this.selectedComp.hasBackSidePorch();
        // return this.backSidePorch !== null        
    },
    hasLeftSidePorch(){
        if(!this.isMainStructure)
            return null;
        return this.selectedComp.hasLeftEndPorch();
    },
    hasRightSidePorch(){
        if(!this.isMainStructure)
            return null;
        return this.selectedComp.hasRightEndPorch();
        // return this.rightSidePorch !== null        
    },
    hasFrontLeftPorch(){        
        let frontPorch = this.frontSidePorch
        if(frontPorch !== null)
            return frontPorch.left.wrapEnabled && frontPorch.left.wrapValid;
        return false;
    },
    hasFrontRightPorch(){        
        let frontPorch = this.frontSidePorch
        if(frontPorch !== null)
            return frontPorch.right.wrapEnabled && frontPorch.right.wrapValid;
        return false;
    },
    hasBackLeftPorch(){        
        let backPorch = this.backSidePorch
        if(backPorch !== null)
            return backPorch.right.wrapEnabled && backPorch.right.wrapValid;
        return false;
    },
    hasBackRightPorch(){        
        let backPorch = this.backSidePorch
        if(backPorch !== null)
            return backPorch.left.wrapEnabled && backPorch.left.wrapValid;
        return false;
    },
    hasFullWrapPorch(){
        return this.hasFrontLeftPorch && 
        this.hasFrontRightPorch &&
        this.hasBackLeftPorch && 
        this.hasBackRightPorch 
    },
    backRightDisabled(){
        // this button does not requires both porches exist
        let backPorch = this.backSidePorch
        let backRightDisabled = false;
        let res = backRightDisabled || this.isDisabled;
        if(backPorch == null) return true;

        if(this.rightSidePorch == null) return true;

        // but if both porches do exist, then they need to match
        backRightDisabled = !backPorch.left.wrapValid; // wrapValid strictly requires both porches exist and match
        
        return res;
    },
    backLeftDisabled(){
        // this button does not requires both porches exist
        let backPorch = this.backSidePorch
        let backLeftDisabled = false;
        let res = backLeftDisabled || this.isDisabled;
        if(backPorch == null) return true;

        if(this.leftSidePorch == null) return true ;

        // but if both porches do exist, then they need to match
        backLeftDisabled = !backPorch.right.wrapValid; // wrapValid strictly requires both porches exist and match
        return res;
    },
    frontRightDisabled(){
        // this button does not requires both porches exist
        let frontPorch = this.frontSidePorch
        let frontRightDisabled = false;
        let res = frontRightDisabled || this.isDisabled;
        if(frontPorch == null) return true;

        if(this.rightSidePorch == null) return true;

        // but if both porches do exist, then they need to match
        frontRightDisabled = !frontPorch.right.wrapValid; // wrapValid strictly requires both porches exist and match
        return res;
    },
    frontLeftDisabled(){
        // this button does not requires both porches exist
        let frontPorch = this.frontSidePorch
        let frontLeftDisabled = false;
        let res = frontLeftDisabled || this.isDisabled;
        if(frontPorch == null) return true;

        if(this.leftSidePorch == null) return true;

        // but if both porches do exist, then they need to match
        frontLeftDisabled = !frontPorch.left.wrapValid; // wrapValid strictly requires both porches exist and match
        return res;
    }
  },

  methods: {
    
...mapActions('designModule',[
        'togglePorch',        
        'wrapShed',
        'fullWrapPorch',
      ]),
    _wrapShed(sideL, sideR){
        this.wrapShed({sideL, sideR});
    },
  },
  components:{
    
  }
}
</script> 

<style scoped>
/* 
.porchToggle{
    width: 46px;
  white-space: nowrap;
    border: 1px solid gray; 
  padding: 0 4px;

  border-radius: 4px;;
  background: green;
  color:white;
}

.porchToggle.selected{
    background: #34ab34;
    border: 1px solid white; 
}


button.porchToggle[disabled] {
  background:gray ;
  color: darkgrey;
}

.btnGreen{
    background: green;
}

.btnGreen:hover{
    background: #34ab34;
}

button{
    cursor: pointer;
  } */

</style>


