<template>
  <div @mouseleave="selectedIndex = -1">
    <table class="lines-b">
      <tr class="" v-for="(article, index) in articles"
        :key="index"
        @click="select(article.id)"
        @mouseover="selectedIndex = index"        
        :class="{ 'color-context-accent1-shaded': index === selectedIndex }"
        >
        <td>
          <a href="#">{{ article.title }}</a>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>

export default {
  name: 'HelpArticleList',
  props: {
    iArticles: {
      type: Array
    }
  }, 
  data(){
    return {
      articles: [],
      selectedIndex: -1,
    }
  },
  methods:{     
    setArticles(articles){
      console.log('setArticles', articles.length)
      this.articles = Array.from(articles);
    },
    setSelected(i){
      this.selectedIndex=i;      
    },
    select(id){      
      this.$emit('selected',id);
    }
  },
  mounted(){    
    this.articles = this.iArticles;    
  },

  watch:{
    
  },
  components:{
    
  }
}
</script>