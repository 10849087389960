<template id="objTemplate">
  <div >
    <div :class="[getClassNames(), {active : isComponentSelected}]">
      <div class="sidebar-item-1__cover-button align-bs">
        <button @click="select"><span class="sr-only">Select Item</span></button>
      </div>
      <div>
        <Icon :name="getIconNames()" custom-class="icon icon-size-1"/>
      </div>
      <button class="sidebar-item-1__text" @dblclick="toggle">
        {{component.description}}
      </button>
      <div class="sidebar-item-1__actions">
        <!-- actions menu button -->
        <div class="sidebar-item-1__hover-display">

          <div class="popover1-anchor">
            <button @click="toggleActionPopover(component)" class="sidebar-item-1__action-button"  data-popover1-trigger="toggle" :aria-controls="'actions-'+component.id" :aria-expanded="showActionPopoverFor(component)">
              <span class="sr-only">Actions Menu</span>
              <Icon name="horizontal-menu-square" custom-class="icon icon-size-1"/>
            </button>
            
            <!-- popover menu -->
            <div :ref="'actions-'+component.id" :id="'actions-'+component.id" class="popover1 attach-outside-be align-ie click-outside-close" :data-popover1-state="showActionPopoverFor(component)?'open':'closed'" role="dialog" :aria-labelledby="'actions-title-'+component.id">
              <div class="popover-menu-1 color-context-neutral-dark mar-bs-2">
                <h2 class="sr-only" :id="'actions-title-'+component.id">User Actions</h2>
                <div class="grid">

                  <template v-for="(option) in actionOptions" >                    
                      <button v-if="editable" v-on:click="action(option.key)" data-popover1-trigger="close" :aria-controls="'actions-title-'+component.id" aria-expanded="false" v-bind:key="option.key" :name="option.name">{{option.name}}</button>
                  </template>
          
                  <!-- <button data-popover1-trigger="close" :aria-controls="'actions-title-'+component.id" aria-expanded="false">Duplicate</button>
                  <button data-popover1-trigger="close" :aria-controls="'actions-title-'+component.id" aria-expanded="false">Delete</button>
                  <button data-popover1-trigger="close" :aria-controls="'actions-title-'+component.id" aria-expanded="false">Some Action</button> -->
                </div>
              </div>
            </div>

          </div>

  
        </div>
        <div v-if="component.components.length > 0">
          <button class="sidebar-item-1__action-button" :aria-expanded="expanded" :aria-controls="component.id+'-objChildren'" v-on:click="toggle">
            <span class="sr-only">View structures</span>
            <Icon name="arrow-down-1" custom-class="icon icon-size-0 aria-expanded-rotate-180"/>
          </button>
        </div>
      </div>
    </div>
    

    <div :id="component.id+'-objChildren'" v-if="expanded" class="pad-bs-3">
        <treeObjectList ref="treeList" :comps="component.components" />
    </div>
  </div> <!-- end vue template main div wrapper-->

</template>

<script>
//import treeObjectList  from './treeObjectList.vue'
import {mapActions, mapGetters, mapState, mapMutations} from 'vuex'
import { CORE } from '../js/_spec';
import SnuiWrapper from '@/components/SnuiWrapper.vue';
export default {
  name: 'treeObject',
  components:{
    SnuiWrapper
  },
  props: {
    obj: Object,
    describe: false,
  },
  data: ()=>{    
      return {
        hover: false,
        expanded: true,
        actionPopoverCompId: false,
      };
  },
  computed:{
    ...mapGetters('designModule',[
      'optsContext',
      'constraintGuiData',
      
    ]), 
    ...mapState('designModule',[
      'editable',
      'selectedComponentId'
    ]),
    isComponentSelected(){
      return this.selectedComponentId == this.component.id;
    },
    actionOptions(){
      let opts = this.optsContext.options.filter((o)=>{return o.type==='button'});
      return opts;
    },
    component:{
        get(){
            return this.obj;
        }
    },
    hasAnyChildren() {
      return this.component.components && this.component.components.length>0
    }

  },
  mounted(){
    console.log('treeObject mounted')
    
  },
  methods: {
    ...mapActions('designModule',[
        'selectComponent',
        'showEditComponentOptions',
        'promptDeleteComponent',        
        'copyComponent',
        'centerComponent',
        'editConstraint',
    ]),
    ...mapMutations('designModule',[
       'showConstraintEditor'
    ]),
    showActionPopoverFor(comp){
      return comp.id === this.actionPopoverCompId;
    },
    toggleActionPopover(comp) {
      this.select();
      this.actionPopoverCompId = comp.id;
      if (this.actionPopoverCompId) {
        document.addEventListener("click", this.handleClickOutsideActionPopover);          
        this.documentClickReady=false;
      } else {
        document.removeEventListener("click", this.handleClickOutsideActionPopover);
      }
    },
    handleClickOutsideActionPopover(event) {
      if(!this.documentClickReady)
        {
          this.documentClickReady=true;
          return;
        }

      let pop = this.$refs['actions-'+this.actionPopoverCompId];
      console.log(pop)
      if(pop.length && pop.length==1)
        pop = pop[0]; // because refs in v-for are special
      if (pop && pop.contains(event.target)) 
        return;
      
      this.actionPopoverCompId = null;
      document.removeEventListener("click", this.handleClickOutsideActionPopover);
      
    },
    action: function(key){
      switch(key){         
        case 'delete':
          this.promptDeleteComponent(this.design.id);
          break;
        case 'copy':
          this.copyComponent(this.design.id);
          break;
        case 'center':
          this.centerComponent(this.design.id);
          break;
      }
    },
    getClassNames(){
      switch (this.obj.type){
        case CORE.components.system:
          return 'sidebar-item-1 level-1'
        case CORE.components.structure:
        case CORE.components.leanTo:
        case CORE.components.porch:
          return 'sidebar-item-1 level-2'
        case CORE.components.fsw:
        case CORE.components.bsw:
        case CORE.components.lew:
        case CORE.components.rew:
        case CORE.components.cupola:
        case CORE.components.options:
        case CORE.components.jobspecs:
        case CORE.components.appearance:
          return 'sidebar-item-1 level-3'
        default:
          return 'sidebar-item-1 level-4'
      }
    },
    getIconNames(){
      switch (this.obj.type){
        case CORE.components.system: // Systems
          return 'hierarchy-3'
        case CORE.components.structure: // Structures
        case CORE.components.leanTo:
        case CORE.components.porch:
          return 'home-3'
        case CORE.components.fsw: // Walls
        case CORE.components.bsw:
          return 'custom-cube-dashed'
        case CORE.components.lew:
        case CORE.components.rew:
          return 'custom-home-dashed'
        case CORE.components.cupola: // Cupola
          return 'custom-cupola'
        case CORE.components.window: // Framed Openings
          return 'custom-window'
        case CORE.components.emptyFramedOpening:
          return 'custom-square'
        case CORE.components.louverVent:
          return 'custom-louvored-vent'
        case CORE.components.doorWalkin3o7o: // Doors Walk
        case CORE.components.doorWalkin4o7o:
          return 'custom-walk-in-door'
        case CORE.components.doorWalkin6o7o:
          return 'custom-glass-door-double'
        case CORE.components.doorCommGlass: // Door Glass
          return 'custom-glass-door'
        case CORE.components.wideOpening: // Visual Wide Opening
          return 'custom-wide-opening'
        case CORE.components.doorHangar: // Overhead
          return 'custom-hangar-door'
        case CORE.components.doorRollup:
          return 'custom-sectional-door'
        case CORE.components.doorSectional:
          return 'custom-sectional-door'
        case CORE.components.doorSliding:
          return 'custom-sliding-door'
        default:
          return 'cog'
      }
    },
    // expandAll(){
    //   this.expanded=true;
    //   if(this.$refs.treeList)
    //     this.$refs.treeList.expandAll();
    // },
    // collapseAll(){
    //   this.expanded=false;
    //   this.$nextTick(()=>{
    //   if(this.$refs.treeList)
    //     this.$refs.treeList.collapseAll();
    //   });
    // },
    toggle(){      
      this.expanded=!this.expanded;
    },
    select: function(){
      this.selectComponent(this.component.id);
    },
    context: function(){
      this.select();
      this.showEditComponentOptions(this.component.id);
    },

  },
  components:{
    treeObjectList: () => import('./treeObjectList.vue')
  }
}
</script> 


<style scoped>
/* 
.selected{
  background: white;
  border: solid 1px black;
}

.collided {
  color: red;
  font-weight:bold;
}

.component:hover{
  background: lightgray;
}

.component.selected:hover{
  background: white;
  border: solid 1px black;
}

.componentSelector{  
  border-radius: 4px;  
  display: inline-block;
  white-space: nowrap;  
}

.componentOptions{
  display:block;
  float:right;
  width: 25px;
  background: #4c78b9;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  border-radius: 5px;
  margin-top:3px;
  margin-right:3px;
  margin-left:3px;
color: lightgray;
}


.componentOptions:hover{
  background: #5e92df;
  color: white;
}

div.component{    
  cursor: pointer;
  border-radius: 4px;  
  display: inline-block;  
  padding: 0 4px;
}

.treeLevel{
    margin-left: 15px;
}

.hideOptionArrow{
  visibility: hidden;
}

.treeNodeToggle{
  position: absolute;
  left: -15px;
  cursor: pointer;


}

.treeNodeToggle:hover{
  color:white;
}
 */

</style>
