<template>
  <div id="app">
    <div class="page-layout-1">
      
      <header class="header-bar color-context-neutral-dark">
        <button @click="clearBusinessContext">
          <img class="header-bar-logo" src="/snui/snui-images/logos/steel-ninja-logo-white.svg" alt="Steel Ninja">
        </button>
        <button @click="openFeedback" class="btn btn-small btn-secondary" style="margin: 0px 20px;">Beta Feedback
          <icon name="arrow-right-1" custom-class="icon-size-1" ></icon>
        </button>
        <SnuiWrapper>
          <div>
            <button class="pad-2 block" style="border-radius: var(--br-2); --display-md: none;" data-open-modal="#mobile-menu">        
              <span class="sr-only">Menu</span>
              <Icon name="equal-sign" custom-class="icon-size-2 color-subdued block" />
            </button>
          </div>
        </SnuiWrapper>
      </header>
      <!-- nitty-gritty-override page-grid is not compatible with the designer YET -->
      <div :class="{'page-grid': pageNeedsGrid }" style="--grid-template-columns-md: auto 1fr;">
        <SideBar v-if="user && showSidebar" :submenuRoutes="[
              '/supplier/:id/itemManager',
              '/supplier/:id/itemMapping',
              '/supplier/:id/settings',
              '/supplier/:id/pricing',
              '/supplier/:id/taxes',
              '/supplier/:id/pricingLevels',
              '/supplier/:id/pricingModels',
              '/supplier/:id/colors',
              '/supplier/:id/materials',
              '/contractor/:id/settings',
              '/contractor/:id/materials',
              '/itemEditor',
              '/employeeManager'
            ]">
            
          <!-- SysAdmin Links -->
          <template v-if="displayAsAdmin">
            <SideBarLink :routePath="projectPath" icon="open-folder" :activeRoutes="[
                '/',
                '/contractor/:id/projects',
                '/supplier/:id/projects',
                '/project/:ref'
              ]"> Projects </SideBarLink>
            <SideBarLink :routePath="businessesPath" icon="interface-home-2" :activeRoutes="[
                '/businesses/:id',
                '/onboarding',
                '/supplier/:id/settings',
                '/supplier/:id/itemManager',
                '/supplier/:id/itemMapping',
                '/supplier/:id/materials',
                '/supplier/:id/pricing',                
                '/supplier/:id/pricingLevels',
                '/supplier/:id/pricingModels',
                '/supplier/:id/taxes',
                '/supplier/:id/colors',
              ]"> {{ businessTermPlural }} </SideBarLink>
            

          </template>
          <!-- Supplier Links -->
          <template v-if="displayAsSupplier">
            <SideBarLink :routePath="`/supplier/${currentSupplierId}/projects`" icon="open-folder" :activeRoutes="[
                '/',
                '/supplier/:id/projects',
                '/project/:ref'
              ]"> Projects </SideBarLink>
            <SideBarLink :routePath="businessesPath" icon="construction-helmet-2" :activeRoutes="[
                '/supplier/:id/contractors',
                '/contractor/:id/settings'
              ]"> {{ businessTermPlural }} </SideBarLink>

<div>

  <SideBarLink :routePath="`/supplier/${currentSupplierId}/settings`" icon="screwdriver-wrench" :activeRoutes="[
    '/supplier/:id/itemManager',
    '/supplier/:id/itemMapping',
    '/supplier/:id/settings',
    '/supplier/:id/pricing',
    '/supplier/:id/taxes',
    '/supplier/:id/pricingLevels',
    '/supplier/:id/pricingModels',
    '/supplier/:id/colors',
    '/supplier/:id/materials',
    '/itemEditor',
    '/employeeManager'
  ]">Setup
          <div v-if="attentionItemMapping" class="dot active color-context-neutral-dark mar-is-2" style="vertical-align:middle; --this-size: var(--sp-4); --this-color: var(--color-fg-subdued); --this-active-color: var(--color-fg-vibrant);" ></div>
  </SideBarLink>
          </div>
          </template>
          
          <!-- Contractor Links -->
          <template v-if="displayAsContractor">
            <SideBarLink :routePath="`/contractor/${current.contractor.id}/projects`" icon="open-folder" :activeRoutes="[
                '/',
                '/contractor/:id/projects',
                '/project/:ref'
              ]"> Projects </SideBarLink>
            <SideBarLink :routePath="`/contractor/${current.contractor.id}/settings`" icon="screwdriver-wrench" :activeRoutes="[
              '/contractor/:id/settings',
              '/contractor/:id/materials',
              '/employeeManager',
            ]">Setup</SideBarLink>
          </template>

          <!-- Everyon Links -->
          <SideBarLink :routePath="`/support`" icon="help-chat-2" :activeRoutes="[                
                 '/support',
                '/support/:articleId',
                '/changeLog'
              ]">Support</SideBarLink>
          <SideBarLink icon="file-text" v-if="userIsSysAdmin" @click="gotoSpec">SNUI Spec</SideBarLink>
          <SideBarLink :route-path="'/admin'" icon="warning-triangle" v-if="userIsSysAdmin" >Ninja HQ</SideBarLink>
          
          
          <template v-slot:main-menu-bottom>
            <div class="popover1-anchor">
              <div class="grid gap-3" style="grid-template-columns: 1fr auto; align-items: center;">
                <div>
                  <div class="grid gap-3" style="grid-template-columns: auto 1fr; align-items: center;">
                    <div>
                      <Icon name="attribution" custom-class="icon-size-2 block" />
                    </div>
                    <div style="line-height: 1;">
                      <div class="strong">{{ user.name }}</div>
                      <div class="small one-line-truncate">{{ userIsSysAdmin ? 'Steel Ninja Admin' : (currentBusiness ? currentBusiness.businessName : '') }}</div>
                    </div>
                  </div>
                </div>
                <SnuiWrapper>
                  <div>
                    <button class="pad-3 hover-bg-shaded mar-is-2" style="margin: calc(var(--sp-3) * -1); border-radius: var(--br-2);"  data-popover1-trigger="toggle" aria-controls="popover-user-menu" aria-expanded="false">
                      <span class="sr-only">Open User Menu</span>
                      <Icon name="horizontal-menu-square" custom-class="icon-size-1 block" />
                    </button>
                    <!-- user menu -->
                    <div id="popover-user-menu" class="popover1 attach-outside-bs align-ie click-outside-close"
                      data-popover1-state="closed" role="dialog" aria-labelledby="popover-user-menu-title">
                      <div class="mar-be-3 color-context-neutral-dark"
                        style="inline-size: calc(var(--sidebar-inline-size) - (var(--sp-4) * 2)); border-radius: var(--br-3); border-color: var(--color-fg-normal); overflow: hidden; margin-inline-end: calc(var(--sp-4) * -1);">
                        <h2 class="sr-only" id="popover-user-menu-title">User Menu</h2>
                        <div class="grid mar-b-2">  
                          <button v-if="displayAsAdmin" class="block pad-b-2 pad-i-4 hover-bg-shaded" data-popover1-trigger="close"
                            aria-controls="popover-user-menu" aria-expanded="false" @click="$router.push('/userSettings')">Settings</button>                        
                          <button v-else-if="displayAsSupplier" class="block pad-b-2 pad-i-4 hover-bg-shaded" data-popover1-trigger="close"
                            aria-controls="popover-user-menu" aria-expanded="false" @click="$router.push({path: '/userSettings', query:{business:current.supplier.id}})">Settings</button>
                          <button v-else-if="displayAsContractor" class="block pad-b-2 pad-i-4 hover-bg-shaded" data-popover1-trigger="close"
                            aria-controls="popover-user-menu" aria-expanded="false" @click="$router.push({path: '/userSettings', query:{business:current.contractor.id}})">Settings</button>
                          <button class="block pad-b-2 pad-i-4 hover-bg-shaded" data-popover1-trigger="close"
                            aria-controls="popover-user-menu" aria-expanded="false" @click="logout">Logout</button>
                        </div>
                      </div>
                    </div>
                  </div><!-- div -->
                </SnuiWrapper>
              </div>
            </div><!-- popover1-anchor -->
          </template>
          
          <template v-slot:submenu>

            <template >
              <h3 v-if="showSubMenuBusinessName" class="submenu-title">{{ currentBusiness?.businessName }}</h3>
              

              <!-- Supplier settings -->
              <template v-if="current.mode == 1 && current.supplier">
                <div class="submenu-heading">Pricing Settings</div>
                <div class="submenu-links">
                  
                  <SubmenuLink text="Items" :routePath="`/supplier/${current.supplier.id}/itemManager`" />
                  <div>
                    <SubmenuLink text="Item Mapping" :routePath="`/supplier/${current.supplier.id}/itemMapping`">
                    <div v-if="attentionItemMapping" class="dot active color-context-neutral-dark mar-is-2" style="vertical-align:middle; --this-size: var(--sp-4); --this-color: var(--color-fg-subdued); --this-active-color: var(--color-fg-vibrant);" ></div>
                    </SubmenuLink>
                  </div>
                  <SubmenuLink text="Pricing Models" :routePath="`/supplier/${current.supplier.id}/pricingModels`"/>
                  <SubmenuLink text="Pricing Levels" :routePath="`/supplier/${current.supplier.id}/pricingLevels`"/>
                  <SubmenuLink text="Sales Tax" :routePath="`/supplier/${current.supplier.id}/taxes`"/>
                  <SubmenuLink text="Shipping" :routePath="`/supplier/${current.supplier.id}/pricing`"
                    :query="{ section: 'shipping' }" />
                  <SubmenuLink text="Fabrication" :routePath="`/supplier/${current.supplier.id}/pricing`"
                    :query="{ section: 'fabrication' }" />
                  <SubmenuLink text="Beams" :routePath="`/supplier/${current.supplier.id}/pricing`"
                    :query="{ section: 'beams' }" />
                  <SubmenuLink text="Fasteners" :routePath="`/supplier/${current.supplier.id}/pricing`"
                    :query="{ section: 'fasteners' }" />
                </div><!-- submenu-links -->
                <!-- etc... -->
              </template>
              <!-- Settings for all modes -->
              <div class="submenu-heading">Business Settings</div>
              <div class="submenu-links">
                
                <template v-if="current.mode == 1 && current.supplier">
                  <SubmenuLink text="Colors" :routePath="`/supplier/${current.supplier.id}/colors`" />
                  <SubmenuLink text="Business Info." :routePath="`/supplier/${current.supplier.id}/settings`" />
                  <SubmenuLink text="Materials" :routePath="`/supplier/${current.supplier.id}/materials`"/>
                  <!-- <SubmenuLink
                    text="Subscription"
                    :routePath="`/supplier/${current.supplier.id}/subscription`"
                    />
                    <SubmenuLink
                      text="Users"
                      :routePath="`/supplier/${current.supplier.id}/users`"
                    /> -->
                </template>
                <template v-if="mode == 2 && current.contractor">
                  <SubmenuLink text="Business Info." :routePath="`/contractor/${current.contractor.id}/settings`" />
                  <SubmenuLink text="Materials" :routePath="`/contractor/${current.contractor.id}/materials`"></SubmenuLink>
                </template>
              </div>
          </template>           
          </template>
        </SideBar>
        <div class="main-content"  :class="{designer: isDesigner}" style="overflow-y: auto; height: 94vh;">
          
          <div v-if="showBackToBusinesses" class="grid gap-5" style="grid-template-columns: auto 1fr;" >
            <div>
              <button type="button" class="btn btn-secondary" @click="backToBusinesses">
                <Icon name="line-arrow-left" ></Icon>                
                <span>Back to {{ businessTermPlural }}</span>
              </button>
            </div>            
          </div>

          <div v-if="showBackToSupport" class="grid gap-5" style="grid-template-columns: auto 1fr;" >
            <div>
              <button type="button" class="btn btn-secondary" @click="backToSupport">
                <Icon name="line-arrow-left" ></Icon>                
                <span>Back to Support</span>
              </button>
            </div>            
          </div>

          <router-view :key="refreshKey+$route.fullPath" />
        </div>
      </div><!-- page-grid -->

      <!-- mobile menu -->
      
      
        <dialog class="modal modal-screen-size color-context-neutral-shaded-2" style="padding-block-start: var(--header-bar-block-size); block-size: 100%; overflow: hidden; --modal-backdrop-color:transparent; --display-md: none;" id="mobile-menu" ref="mobilemenu" autofocus>
          <button type="button" class="mobile-menu-close-button color-context-neutral-dark" aria-label="close" @click="closeMobileMenu">
            <Icon name="delete-1" custom-class="icon icon-size-2"/>
          </button>
          
          <div class="modal-content pad-0"  style="block-size: 100%; overflow-y: auto;">
            <div class="grid mar-b-6">
              
              <div class="mobile-menu-section">
                <!-- add the active class to the button if the current page is in the projects section -->
                <!-- when the user clicks a button that navigates to a new page, close the modal -->
                 <MobileMenuLink :routePath="projectPath" :activeRoutes="projectRouteNames" @click="closeMobileMenu">
                    <Icon name="open-folder" custom-class="icon icon-size-3"/>					
                    <span class="text">
                      Projects
                    </span>
                 </MobileMenuLink>
              </div>
              
              <!-- Supplier sees this simple Contractors (or Businesses) button initially. -->
              <div class="mobile-menu-section" id="demojs-mm-contractors-simple" v-if="!showBusinessComplex">
                <MobileMenuLink :routePath="businessesPath" :activeRoutes="contractorRouteNames" @click="closeMobileMenu">
                  <Icon name="construction-helmet-2" custom-class="icon icon-size-3"/>					
                  <span class="text">{{businessTermPlural}}</span>
                </MobileMenuLink>
              </div>
              
              <!-- Once an admin or supplier goes to the Contractors screen (or Businesses screen) and clicks to view that business's settings, this mobile menu item will include a menu like this. -->
              <!-- when the button opens or closes a collapse, do not close the modal -->
              <div class="mobile-menu-section" id="demojs-mm-contractors-complex" v-if="showBusinessComplex">
                <div class="collapse">
                  <MobileMenuLink :activeRoutes="contractorRouteNames" :aria-expanded="mobileMenuPath.includes('businesses')" @click="toggleMobileMenuBasePath('businesses');">
                    <Icon name="construction-helmet-2" custom-class="icon icon-size-3" />					
                    <span class="text">{{businessTermPlural}}</span>
                    <Icon name="arrow-down-1" custom-class="icon icon-size-1 toggle-rotate-180"/>
                  </MobileMenuLink>
                  <!-- <button class="mobile-menu-button-1 no-outline-offset collapse-toggle" :aria-expanded="mobileBusinesses" @click="toggleMobileMenuBasePath('businesses')">
                    <Icon name="construction-helmet-2" custom-class="icon icon-size-3"/>					
                    <span class="text">{{businessTermPlural}}</span>
                    <Icon name="arrow-down-1" custom-class="icon icon-size-1 toggle-rotate-180"/>
                  </button> -->
                    
                  <div class="collapse-content active toggle-transition-height">
                    <div class="tth-inner">
                      <div class="mar-i-4 mar-bs-4">
                        <h3 class="submenu-title">{{ currentBusiness?.businessName }}</h3>
                      </div>
                      <div class="grid gap-3 mar-b-4 collapse-accordion">

                        <div class="collapse" >
                          <!-- no need to add an active class to these submenu items.
                          The active styling is triggered by aria-expanded, which is controlled
                          by the collapse js. -->
                          <button class="mobile-menu-button-2 no-outline-offset collapse-toggle" :aria-expanded="mobileMenuPath=='businesses/group1'" @click="toggleMobileMenuSubPath('businesses','group1')">
                            <span>Business Settings</span>
                            <Icon name="arrow-down-1" custom-class="icon icon-size-1 toggle-rotate-180"/>
                          </button>
                          <div class="collapse-content toggle-transition-height">
                            <div class="tth-inner">
                              <div class="pad-b-3">
                                <div class="grid gap-0">
                                  <!-- For sub-submenu items, add the active class when this item corresponds to the current page. -->
                                  <MobileMenuSubLink @click="closeMobileMenu" :routePath="settingsPath" :activeRouteNames="['settings']">Business Info.</MobileMenuSubLink>
                                  <MobileMenuSubLink @click="closeMobileMenu" :routePath="materialsPath" :activeRouteNames="['businessMaterialSettings']">Materials</MobileMenuSubLink>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div><!-- grid -->

                    </div><!-- tth-inner -->
                  </div><!-- collapse-content -->
                </div><!-- collapse -->
              </div><!-- mobile-menu-section -->

              <div class="mobile-menu-section" id="demojs-mm-setup">
                <div class="collapse">
                  <button class="mobile-menu-button-1 collapse-toggle" :class="{active: isSetupRoute}" :aria-expanded="mobileMenuPath.includes('setup')" @click="toggleMobileMenuBasePath('setup')">
                    <Icon name="screwdriver-wrench" custom-class="icon icon-size-3"/>
                    <span class="text">Setup</span>                  
                    <Icon name="arrow-down-1" custom-class="icon icon-size-1 toggle-rotate-180"/>
                  </button>
                  <div class="collapse-content toggle-transition-height">
                    <div class="tth-inner">
                      <div class="grid gap-3 mar-b-4 collapse-accordion">

                        <!-- For admin, this section would show when a supplier's settings are being shown. -->                         
                         
                        <div class="collapse">
                          <button ref="supplierSettings" class="mobile-menu-button-2 no-outline-offset collapse-toggle" :aria-expanded="mobileMenuPath=='setup/settings'" @click="toggleMobileMenuSubPath('setup','settings')">
                            <span>Supplier Settings</span>
                            <Icon name="arrow-down-1" custom-class="icon icon-size-1 toggle-rotate-180"/>
                          </button>
                          <div class="collapse-content toggle-transition-height">
                            <div class="tth-inner">
                              <div class="pad-b-3">
                                <div class="grid gap-0">
                                  
                                  <MobileMenuSubLink @click="closeMobileMenu" :routePath="itemPath" :activeRouteNames="['itemManager']">Items</MobileMenuSubLink>
                                  <MobileMenuSubLink @click="closeMobileMenu" :routePath="itemMappingPath" :activeRouteNames="['itemManager']">Item Mapping</MobileMenuSubLink>
                                  <MobileMenuSubLink @click="closeMobileMenu" :routePath="pricingModelPath" :activeRouteNames="['pricingModels']">Pricing Models</MobileMenuSubLink>
                                  <MobileMenuSubLink @click="closeMobileMenu" :routePath="pricingLevelPath" :activeRouteNames="['pricingLevels']">Pricing Levels</MobileMenuSubLink>
                                  <MobileMenuSubLink @click="closeMobileMenu" :routePath="shippingPath" :activeRouteNames="['pricing']" :query="{ section: 'shipping' }">Shipping</MobileMenuSubLink>
                                  <MobileMenuSubLink @click="closeMobileMenu" :routePath="fabricationPath" :activeRouteNames="['pricing']" :query="{ section: 'fabrication' }">Fabrication</MobileMenuSubLink>
                                  <MobileMenuSubLink @click="closeMobileMenu" :routePath="beamPath" :activeRouteNames="['pricing']" :query="{ section: 'beams' }">Beams</MobileMenuSubLink>
                                  <MobileMenuSubLink @click="closeMobileMenu" :routePath="fastenerPath" :activeRouteNames="['pricing']" :query="{ section: 'fasteners' }">Fasteners</MobileMenuSubLink>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      
                        <div class="collapse">
                          <button class="mobile-menu-button-2 no-outline-offset collapse-toggle" :aria-expanded="mobileMenuPath=='setup/business'" @click="toggleMobileMenuSubPath('setup','business')">
                            <span>Company Settings</span>
                            <Icon name="arrow-down-1" custom-class="icon icon-size-1 toggle-rotate-180"/>
                          </button>
                          <div class="collapse-content toggle-transition-height">
                            <div class="tth-inner">
                              <div class="pad-b-3">
                                <div class="grid gap-0">
                                  <MobileMenuSubLink @click="closeMobileMenu" :routePath="colorPath" :activeRouteNames="['colors']">Colors</MobileMenuSubLink>
                                  <MobileMenuSubLink @click="closeMobileMenu" :routePath="settingsPath" :activeRouteNames="['settings']">Business Info.</MobileMenuSubLink>
                                  <MobileMenuSubLink @click="closeMobileMenu" :routePath="materialsPath" :activeRouteNames="['businessMaterialSettings']">Materials</MobileMenuSubLink>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div><!-- grid -->

                    </div><!-- tth-inner -->
                  </div><!-- collapse-content -->
                </div><!-- collapse -->
              </div><!-- mobile-menu-section -->
              
            </div>
          </div>
        </dialog>
      
      

    </div><!-- page-layout-1 -->
  </div>
</template>

<script>
import ContextLogic from './lib/contextLogic';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import SideBar from './components/SideBar.vue';
import SideBarLink from './components/SideBarLink.vue';
import SideBarSection from './components/SideBarSection.vue';
import SubmenuLink from './components/SubmenuLink.vue';
import Icon from './components/Icon.vue';
import Vue from 'vue'
import * as Sentry from "@sentry/vue";
import { current } from 'tailwindcss/colors';
import SnuiWrapper from './components/SnuiWrapper.vue';
import MobileMenuLink from './components/MobileMenuLink.vue';
import MobileMenuSubLink from './components/MobileMenuSubLink.vue';
import Util from './lib/utility';
import api from './api';


export default{
  name: 'App',
  data(){
    return{
      routePath: this.$route?.path || window.location.pathname,
      logo:'',
      mobileMenuPath:'',
      projectRouteNames: [
        'project',
        'projects',
        'design',
      ],
    }
  },
  components:{
    SideBar,
    SideBarLink,
    SideBarSection,
    SubmenuLink,
    MobileMenuLink,
    MobileMenuSubLink
  },
  watch:{
    async currentBusiness(v){

      console.log('current entity is now', v )
      if(!v)
        return;
        this.logo = await this.getCompanyImage(v.id);  
    },
    $route(to, from) {
      if(to.path == from.path)
        return;

      // React to route changes...
      console.log('Route changed from', from.path, 'to', to.path)
      // Perform any necessary actions here
      this.routePath = this.$route.path; // update this to force showSidebar to recalculate
    }
  },
  computed:{
    ...mapState('contextModule', ['current','user', 'userBusiness', 'refreshKey' ]),
    ...mapGetters('contextModule', ['currentBusiness', 'userIsSysAdmin', 'userIsSupAdminOrSysAdmin', 'userIsContAdminOrSysAdmin']),

    /*
      Businesses should 
    */

    mobileBusinesses(){
      return this.mobileMenuPath.includes('businesses')
    },
    showBusinessComplex(){
      if(this.current.mode ==2) return true;
      if(this.displayAsAdmin && this.current.mode ==1) return true;
      return false;
    },
    setupRouteNames(){
      let routes= [
        'itemManager',
        'pricingModels',
        'pricingLevels',
        'colors'
      ];
      if(this.current.mode != 2)
        routes.push('settings');
      return routes;
    },
    contractorRouteNames(){
        // can't put `settings` here even though I want to because suppliers use the same path
        // maybe I can compute this on the fly based on who the user is
      let routes = [        
        'businessesManager',
      ]

      if(this.current.mode == 2)      
        routes.push('settings');
        return routes;
    },
    settingsPath(){
      if(this.current.mode == 2)
        return `/contractor/${this.current.contractor.id}/settings`
        if(this.current.mode == 1)
        return `/supplier/${this.current.supplier.id}/settings`
      return ''
    },
    materialsPath(){
      if(this.current.mode == 2)
        return `/contractor/${this.current.contractor.id}/materials`
      if(this.current.mode == 1)
        return `/supplier/${this.current.supplier.id}/materials`
      return ''
    },
    colorPath(){return `/supplier/${this.currentSupplierId}/colors`},
    itemPath(){return `/supplier/${this.currentSupplierId}/itemManager`},
    itemMappingPath(){return `/supplier/${this.currentSupplierId}/itemMapping`},
    pricingModelPath(){return `/supplier/${this.currentSupplierId}/pricingModels`},
    pricingLevelPath(){return `/supplier/${this.currentSupplierId}/pricingLevels`},
    shippingPath(){return `/supplier/${this.currentSupplierId}/pricing?section=shipping`},
    fabricationPath(){return `/supplier/${this.currentSupplierId}/pricing?section=fabrication`},
    beamPath(){return `/supplier/${this.currentSupplierId}/pricing?section=beams`},
    fastenerPath(){return `/supplier/${this.currentSupplierId}/pricing?section=fasteners`},
    projectPath(){      
      if(this.displayAsSupplier)
        return `/supplier/${this.currentSupplierId}/projects`
      if(this.displayAsContractor){
        return `/contractor/${this.currentContractorId}/projects`
      }
      return '/'; // sysAdmin
    },
    businessesPath(){
      if(this.displayAsSupplier)
        return `/supplier/${this.currentSupplierId}/contractors`
      if(this.displayAsContractor){
        return ``
      }
      return '/businesses/list'; // sysAdmin
    },
    isProjectRoute(){
      console.log(this.projectRouteNames, this.$route.name)
      return this.projectRouteNames.includes(this.$route.name)
    },
    isContractorRoute(){
      console.log(this.contractorRouteNames, this.$route.name)
      return this.contractorRouteNames.includes(this.$route.name)      
    },
    isSetupRoute(){
      
      return this.setupRouteNames.includes(this.$route.name)      
    },
    async attentionItemMapping(){
      if(this.mode==2)
        return false;
      if(!this.current || !this.current.supplier || !this.current.supplier.id)
        return false;
      
      let unmapped = await api.getUnmappedItemsForBusiness(this.current.supplier.id);
      return unmapped.length>0;

    },
    currentSupplierId(){
      // we need this to prevent an reading from a null during init
      if(!this.current || !this.current.supplier || !this.current.supplier.id)
        return '';

      return this.current.supplier.id;
    },
    showSubMenuBusinessName(){
      if(this.userIsSysAdmin)
        return true;
      if(this.supplierViewingContractor)
        return true;
      return false;

    },
    supplierViewingContractor(){
      // is a supplier looking at a contractor
      if(!this.userBusiness)
        return false;
      if(this.userBusiness.type != 1)
        return false;
      if(this.userBusiness.type == 1 && this.mode == 2)
        return true;
      else
        return false;
      
    },
    supplierViewingSelf(){
      // is a supplier looking at a supplier (self)
      if(!this.userBusiness)
        return false;
      if(this.userBusiness.type != 1)
        return false;
      if(this.mode == 1)
        return true;
      return false;
    },
    contractorViewingSelf(){
      // is a contractor looking at a contractor (Self)
      if(!this.userBusiness)
        return false;
      if(this.userBusiness.type != 2)
        return false;
      if(this.mode == 2)
        return true;
      return false;
    },
    businessTermPlural(){
      if (this.userIsSysAdmin)
        return 'Businesses'
      return 'Contractors'
    },    
    displayAsAdmin(){
      return this.userIsSysAdmin;
    },
    displayAsSupplier(){
      return this.userBusiness?.type==1
    },
    displayAsContractor(){
      return this.userBusiness?.type==2
    },
    pageNeedsGrid(){
      return !this.isDesigner && !this.$route.path.includes('login')
    },
    isDesigner(){
      return this.$route.path.includes('design')
    },
    
    mode(){
      return this.current.mode
    },
    
    showSidebar(){
      console.log('showSidebar called', {
        route: this.$route?.path,
        routePath: this.routePath,
        auth: !!this.$auth,
        isAuthenticated: this.$auth?.isAuthenticated
      });
      
      if(!this.$auth || !this.$auth.isAuthenticated) {
        console.log('showSidebar: auth check failed');
        return false;
      }
      
      // Initialize routePath if it's not set
      if (!this.routePath) {
        this.routePath = this.$route.path;
      }
      
      let pathsWithoutSidebar = ['design', 'login'];
      let result = !pathsWithoutSidebar.some(path => this.routePath.includes(path));
      console.log('showSidebar returning:', result);

      return result;
    },
    
    toggleLabel(){
      return this.collapsed?"]>":"<]";
    },
    showBackToSupport(){
      let showForRouteNames = [
            'changeLog',
          ]
        return showForRouteNames.some( x => x == this.$route?.name);
    },
    showBackToBusinesses(){
      if(this.supplierViewingSelf)
        return false;
      if(this.contractorViewingSelf)
        return false;
      
      //snui.collapse.close(setupCollapse); // unsure about adding this here CBH 2025.02.26
        
      if(this.supplierViewingContractor || this.userIsSysAdmin){
          let showForRouteNames = [
            'itemManager',
            'itemMapping',
            'settings',
            'pricing',
            'taxes',
            'itemEditor',
            'colors',
            'businessMaterialSettings'
          ]
        return showForRouteNames.some( x => x == this.$route?.name);
      }
      return false;
    },
    
    sdkClientLoaded(){
      //Check that the SDK client is not currently loading before accessing is methods
      // console.log('isLoading',this.$auth.loading)
      // console.log('isAuth', this.$auth.isAuthenticated)
      return !this.$auth.loading;
    }
  },
  methods: {
    ...mapActions('businessModule', ['getBusinessSetting']),
    toggleMobileMenuBasePath(base){
      console.log('toggle base', base)
      if(this.mobileMenuPath.includes(base))
      {
        this.mobileMenuPath='';
      }
      else
      {
        this.mobileMenuPath=base;
      }
        
      console.log('result', this.mobileMenuPath)
    },
    toggleMobileMenuSubPath(base, sub){
      if(this.mobileMenuPath.includes(sub))
        this.mobileMenuPath=base;
      else
        this.mobileMenuPath=`${base}/${sub}`
    },
    closeMobileMenu(){
      console.log('close mobile menu')
      this.$refs.mobilemenu.close();
    },
    openMobileMenu(){

    },    
    openFeedback(){
      window.open("https://docs.google.com/forms/d/1dSq3T72hseOq5cEXSty-Gi34vxDzfoKcEiuUP572OPw/edit#settings", "_blank")
    },
    gotoSpec(){
      window.open('/snui/demo-html/', '_blank');    
    },
    backToSupport(){
      this.$router.push({path: `/support`})
    },
    backToBusinesses(){
        // this needs to become fully centralized if every settings page is going to need it
        ContextLogic.clearBusinessContext(this.$store);

        if(this.userBusiness.type==1)
            this.$router.push({path: `/supplier/${this.userBusiness.id}/contractors`})
        else
            this.$router.push({path: `/businesses/list`})
    },
    clearBusinessContext(){
      ContextLogic.clearBusinessContext(this.$store);
      if(this.$router.currentRoute.path != '/')
        this.$router.push('/');
    },
    logout() {
      console.log('app: logout')
      console.log('logging out, redirecting to: ', `${process.env.VUE_APP_SITE_BASE_URL}/login`)
      
      ContextLogic.logout();

      this.$auth.logout({
        logoutParams: {
          returnTo: `${process.env.VUE_APP_SITE_BASE_URL}/login`
        }
      });
    },
    
    async getCompanyImageSetting(businessId, settingName){
      return await this.getBusinessSetting({businessId: businessId, settingName});
    }, 
    async getCompanyImage(businessId){
      
      let response = await this.getCompanyImageSetting(businessId, 'logo_Sml');
      if(!response.isError)
      {
          if (!response.value) 
          {
            response = await this.getCompanyImageSetting(businessId, 'logo_Lrg');
            if(!response.isError)
            {
                if (!response.value) 
                  return ''
                else
                  return response.value;
            }
          }          
          else
            return response.value;
      }
      else
        return '';      
    }, 
      projectManagerRoutePath() {
      if (this.$route.name == 'dashboard') {
        return '/projects'
      }
      else
        return `${this.$route.path}/projects`
    },
    entityManagerRoutePath() {
      if (this.$route.name == 'dashboard') {
        return '/entities/list'
      }
      else {
        return `${this.$route.path}/contractors`
      }
    },

    toggleSidebar(){
      this.collapsed=!this.collapsed;
      localStorage.setItem('sidebar-collapsed', JSON.stringify(this.collapsed))
    },
    
    ...mapMutations('contextModule', ['setMode']),
  },
  async created() {
    console.log('App created, route:', this.$route.path);
    console.log('app created')
    
  },
   async mounted(){
    console.log('App mounted', {
      route: this.$route?.path,
      auth: !!this.$auth,
      isAuthenticated: this.$auth?.isAuthenticated
    });
    
    
Sentry.init({
  Vue: Vue,
  dsn: "https://2d6ef3f15d027ad1500c720c37ad0e95@o4508350211489792.ingest.us.sentry.io/4508350219812864",

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 1.0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    Sentry.replayIntegration(
      {
      maskAllText: false,
      blockAllMedia: false,
      }
    ),
    Sentry.replayCanvasIntegration(),
    Sentry.browserTracingIntegration(this.$router)
  ],

  tracePropagationTargets: [
    'localhost',
    /^https:\/\/app\.steel\.ninja/,
    /^https:\/\/api\.steel\.ninja/,
  ],
});

    // If auth isn't ready yet, wait for it
    if (this.$auth?.loading) {
      await new Promise(resolve => {
        const checkAuth = setInterval(() => {
          if (!this.$auth.loading) {
            clearInterval(checkAuth);
            resolve();
          }
        }, 100);
      });
    }
    
    this.routePath = this.$route.path;
  }
}
 
</script>

<style lang="scss">
  .page-layout-1 {
    height: 50%;
  }

  body {
    min-height:95%;
    overflow:hidden;
  }

.ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.designer{
  padding-block: 0;
  padding-inline: 0;
}

</style>

