<template>
  <div class="item-management">
    <h1 class="h1">Item Management</h1>
    <div class="actions color-context-accent1-shaded" style="border:2px solid black;padding:10px">
      <div><h3 class="h3">Bulk Item Management</h3></div>
      Step 1: Download the <button class="btn btn-small" @click="downloadItemList">Existing Item List</button> or a <button class="btn btn-small" >Item List Template</button>
      
      <div class="">

        Step 2: Fill in or update the item list. Consulting our <div class="btn btn-small" @click="showImportHelp" >Item Bulk Import File Guidelines</div><br/>
        Step 3: Select the updated item list
        <label class="input-file" style="display:inline-block">
          <input type="file" ref="fileInput" @change="handleFileUpload" accept=".csv,.xlsx" />
          <span class="btn btn-small">Select File</span>
        </label>
        <br/>
        Step 4: Upload         <button class="btn btn-small" @click="uploadItemList" :disabled="uploadInProgress">Upload Supplier Item List</button>
        <ProgressSpinner v-if="uploadInProgress"></ProgressSpinner>
        <br/>        
        
      </div>
    </div>

    <div class="filters">
      <input type="text" v-model="searchQuery" placeholder="Search items...">      
    </div>

    <table>
      <thead>
        <tr>
          <!-- <th @click="updateSort('ninjaCode')">Ninja Code</th> -->
          <th @click="updateSort('supplierCode')">Supplier Code</th>
          <th @click="updateSort('description')">Description</th>
          <th @click="updateSort('sUnit')">Unit</th>
          <th @click="updateSort('category')">Category</th>
          <th @click="updateSort('pricePerUnit')">Price</th>
          <th @click="updateSort('weightPerUnit')">Weight</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in filteredAndSortedItems" :key="item.id" :class="{unused: isUnusedItem(item)}">
          <!-- <td>{{ item.ninjaCode }}</td> -->
          <td>{{ item.supplierCode }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.sUnit }}</td>
          <td>{{ categoryTypes[item.category] }}</td>
          <td>{{ item.pricePerUnit }}</td>
          <td>{{ item.weightPerUnit }}</td>
          <td>
            <span class="tooltip-wrap align-bs">
              <button aria-labelledby="tooltip-edit-item" class="icon-button-1" @click="editItem(item.id)">
                  <span class="sr-only">Edit</span>
                  Edit
                </button>
              <span role="tooltip" id="tooltip-edit-item">Comming Soon</span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <message-modal
      :show="showMessage"
      :message="message"
      :issues="issues"
      @close="closeMessage"
    />

    <modal name="faq" :height="'auto'" >
      <b>Item Bulk Import Guidelines</b>
      <br />
      <br />
      <div style="text-align: left;">

        Item List upload files <span style="text-decoration: underline;">must</span>:
        
        <ul style="text-align: left;">
          <li>have a comma separated value (.csv) file format</li>
          <li>have a top row of column headers</li>
          <li>have exactly 8 columns in this order
            <ol>
              <li>SystemCode</li>
              <li>SupplierCode</li>
              <li>Description</li>
              <li>WeightPerUnit</li>
              <li>PricePerUnit</li>
              <li>CostPerUnit</li>
              <li>Unit</li>
              <li>Category</li>
            </ol>
          </li>
          <li>Specify <b>UNUSED</b> as the SupplierCode for items you do not supply</li>
        </ul>

        Item List upload files <span style="text-decoration: underline;">can</span>:
        
        <ul style="text-align: left;">
          <li>have a blank Price, Weight, and Cost for items with a SupplierCode of UNUSED </li>
        </ul>
      </div>
      <div @click="$modal.hide('faq')" style="cursor:pointer;display:inline-block;margin:auto" class="btn"><b>Close</b></div>      
    </modal>
  </div>
</template>

<script>
import MessageModal from '@/components/MessageModal.vue'
import ContextLogic from '@/lib/contextLogic';
import { mapState, mapActions, mapGetters } from 'vuex';
import api from '@/api';
import Util from '@/lib/utility';
import ProgressSpinner from '@/components/ProgressSpinner.vue';

import VModal from 'vue-js-modal'
import Vue from 'vue'
Vue.use(VModal, {dialog:true});

export default {
  components: {
    MessageModal,
    ProgressSpinner
  },
  data() {
    return {
      items: [
        { id: 1, systemCode: 'MS001', entityCode: 'CC001', description: 'Item 1', pricePerUnit: 100, weightPerUnit: 50 },
        { id: 2, systemCode: 'MS002', entityCode: 'CC002', description: 'Item 2', pricePerUnit: 200, weightPerUnit: 75 },
        { id: 3, systemCode: 'MS003', entityCode: 'CC003', description: 'Item 3', pricePerUnit: 150, weightPerUnit: 60 },
      ],
      categoryTypes: {
        0: "Taper_Frame",
        1: "Plate",
        2: "Beam",
        3: "Tube",
        4: "Weld_Plates",
        5: "EaveStrut",
        6: "Purlin",
        7: "Import_Purlin",
        8: "Clips",
        9: "Insulation",
        10: "Panel_Galvalume",
        11: "Panel_Kynar",
        12: "Panel",
        13: "Trim",
        14: "Fasteners",
        15: "Accessories"
      },
      searchQuery: '',
      sortBy: 'supplierCode',
      sortOrder: 'asc',
      showMessage: false,
      message: '',
      issues: [],
      uploadInProgress: false
    }
  },
  computed: {
    ...mapState('contextModule', ['currentContext', 'mode']),
    filteredAndSortedItems() {
      console.log('filter', this.items)
      console.log('filter', typeof(this.items))
      let filtered = this.items.filter(item => {
        return Object.values(item).some(value => 
          value.toString().toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      })

      return filtered.sort((a, b) => {
        let modifier = this.sortOrder === 'asc' ? 1 : -1
        if (a[this.sortBy] < b[this.sortBy]) return -1 * modifier
        if (a[this.sortBy] > b[this.sortBy]) return 1 * modifier
        return 0
      })
    }
  },
  methods: {
    ...mapActions('itemModule', ['downloadItemListTemplate', 'uploadItemListTemplate']),
    ...mapActions('businessModule', ['fetchBusiness',]),
    isUnusedItem(item){
      return item.entityCode == 'UNUSED';
    },
    showImportHelp(){

      this.$modal.show('faq',
        {
          buttons: [
          {
            title: 'Close',
            handler: () => {
              this.$modal.hide('faq')
            }
          }
        ]
        }
      )
    },
    updateSort(key) {
      if (this.sortBy === key) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortBy = key
        this.sortOrder = 'asc'
      }
    },
    async downloadItemList() {
      let response = await this.downloadItemListTemplate(this.$route.params.id);
      if(response.isError){
        this.showErrorMessage(response.msg);
      }
      else{
        let business = await this.fetchBusiness(this.$route.params.id);
        let templateData = response;
        let filename = `SteelNinja_${business.businessName.replace(/\s+/g, '')}_ItemListTemplate.csv`; // this.quoteName.replace(/[^a-z0-9_\-]/gi, '_').replace(/_{2,}/g, '_') + ".USR";
        var blob = Util.b64toBlob(templateData.fileContents, templateData.contentType);
        Util.downloadBlob(blob, filename);
      }
    },
    handleFileUpload(event) {
      const file =  event.target.files[0];
      if(file && file.type === 'text/csv')
        this.selectedFile = file;
      else
        this.showErrorMessage('Please select a valid CSV file');
    },
    async uploadItemList() {
      if (this.selectedFile) {
        await this.parseFile()
        console.log(this.$refs.fileInput)
        this.$refs.fileInput.value = '';
      } else {
        this.showErrorMessage('Please select a file to upload')
      }
    },
    async parseFile() {
      this.uploadInProgress = true;
      let formData = new FormData(); //FormData() expects an html form element or not arguments
      formData.append('file', this.selectedFile); 

      let businessId = this.$route.params.id;
      let payload = {businessId: businessId, file: formData}
      let response = await this.uploadItemListTemplate(payload);
      if(response.isError){
        this.uploadInProgress = false;
        if(response.data)        
          this.showErrorMessage(response.msg, response.data.issues);
        else
        this.showErrorMessage(response.msg);

      }
      else{
        this.items
        this.uploadInProgress = false;
        this.showSuccessMessage("File uploaded and items updated successfully.")
      }

      this.refreshItemList();
    },
    editItem(itemId) {
      // Implementation for editing an item
      console.log(`Editing item ${itemId}`)
    },
    showSuccessMessage(message) {
      this.message = message
      this.issues = []
      this.showMessage = true
    },
    showErrorMessage(message, issues = []) {
      this.message = message
      this.issues = issues
      this.showMessage = true
    },
    closeMessage() {
      this.showMessage = false
    },
    async refreshItemList(){
      let id = this.$route.params.id;
      if(id)
        this.items = await api.getItemListForEntity(id)
      else
        this.items = await api.getItemListForSystem()
    
    }
  },
  async created(){
    await ContextLogic.initContext(this);
    await this.refreshItemList();
    console.log(this.items)
  }
}
</script>

<style scoped>
.item-management {
  margin: 0 20px;
}

.actions {
  margin-bottom: 20px;
}

.filters {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  cursor: pointer;
}

tr:hover {
  background-color: #f5f5f5;
}

button, input[type="file"] {
  margin-right: 10px;
}

a {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.unused{
  background:lightgray;
  color: gray;
}
</style>