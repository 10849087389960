<template>
    <div>
        <div class="box-shadow-1 mar-0 pad-0 color-context-neutral-light" style="border-radius: var(--br-4); overflow: hidden;">
            <div class="pad-4" >
                <div class="grid gap-3" style="grid-template-columns: repeat(3, 1fr); grid-template-rows: repeat(3, 1fr);" id="demojs-250128151311-view-preset-buttons" >
                    <div>
                        <div class="icon-text-button-2-spacer"></div>
                    </div>
                    <div>
                        <button class="porchToggle icon-text-button-2" @click="toggleLeanTo(CORE.sides.backSide)" :class="{selected: hasBackSideLeanTo}" :disabled="isBackLeanToDisabled">
                            <span>Back</span>
                        </button>
                    </div>
                    <div>
                        <div class="icon-text-button-2-spacer"></div>
                    </div>
                    <div>
                        <button class="porchToggle icon-text-button-2" @click="toggleLeanTo(CORE.sides.leftEnd)" :class="{selected: hasLeftEndLeanTo}" :disabled="isDisabled">
                            <span>Left</span>
                        </button>
                    </div>
                    <div>
                        <div class="icon-text-button-2-spacer"></div>
                    </div>
                    <div>
                        <button class="porchToggle icon-text-button-2" @click="toggleLeanTo(CORE.sides.rightEnd)" :class="{selected: hasRightEndLeanTo}" :disabled="isDisabled">
                            <span>Right</span>
                        </button>
                    </div>
                    <div>
                        <div class="icon-text-button-2-spacer"></div>
                    </div>
                    <div>
                        <button class="porchToggle icon-text-button-2" @click="toggleLeanTo(CORE.sides.frontSide)" title=''  :class="{selected: hasFrontSideLeanTo}" :disabled="isDisabled">
                            <span>Front</span>
                        </button>
                    </div>
                    <div>
                        <div class="icon-text-button-2-spacer"></div>
                    </div>
                </div>
            </div>
        </div><!-- box -->
      </div>
    
  </template>
  
  <script>
  import {CORE} from '../js/_spec.js'
  import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

  export default {
    props: { },
    data: ()=>{
        return {
           CORE,
        };
    },
    mounted(){
      
    },  
    computed:{
        ...mapGetters('designModule', [
      'structure',
      'selectedComponent',
    ]),
        structureName(){
        if(this.structure)
            return this.structure.design.name;
        return "";
    },
      selectedComp(){
            return this.selectedComponent
      },
      isDisabled(){
        if(this.selectedComp){
            if(this.selectedComp.design.type === CORE.components.structure || this.selectedComp.design.type === CORE.components.leanTo)
             return false;
        }

        return true;
      },
      isParentLeanTo(){
        if(this.selectedComp && this.selectedComp.design.type === CORE.components.leanTo){
            return true
        }
        return false;
      },
      isBackLeanToDisabled(){
        if(!this.isDisabled){
            if(!this.isParentLeanTo)
                return false
        }
        return true;
      },
      frontSideLeanTo(){
          if(!this.structure)
              return null;
          return this.structure.getFrontSideLeanTo();     
      },
      backSideLeanTo(){
          if(!this.structure)
              return null;
          return this.structure.getBackSideLeanTo();
      },
      leftEndLeanTo(){
          if(!this.structure)
              return null;
          return this.structure.getLeftEndLeanTo();
      },
      rightEndLeanTo(){
          if(!this.structure)
              return null;
          return this.structure.getRightEndLeanTo();
      },    
      hasFrontSideLeanTo(){
          if(!this.structure)
              return null;
          return this.structure.hasFrontSideLeanTo();
          // return this.frontSidePorch !== null        
      },
      hasBackSideLeanTo(){
          if(!this.structure)
              return null;
          return this.structure.hasBackSideLeanTo();
          // return this.backSidePorch !== null        
      },
      hasLeftEndLeanTo(){
          if(!this.structure)
              return null;
          return this.structure.hasLeftEndLeanTo();
      },
      hasRightEndLeanTo(){
          if(!this.structure)
              return null;
          return this.structure.hasRightEndLeanTo();
          // return this.rightSidePorch !== null        
      },
    },
  
    methods: {
        ...mapActions('designModule',[
        'toggleLeanTo',        
      ]),
    },
    components:{
      
    }
  }
  </script> 
  
  <style scoped>
  
  .leanToToggle{
      width: 46px;
    white-space: nowrap;
      border: 1px solid gray; 
    padding: 0 4px;
  
    border-radius: 4px;;
    background: green;
    color:white;
  }
  
  .leanToToggle.selected{
      background: #34ab34;
      border: 1px solid white; 
  }
  
  
  button.leanToToggle[disabled] {
    background:gray ;
    color: darkgrey;
  }
  
  .btnGreen{
      background: green;
  }
  
  .btnGreen:hover{
      background: #34ab34;
  }
  
  button{
    cursor: pointer;
  }
  </style>
  
  
  