var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',{staticClass:"h1"},[_vm._v("Change Log")]),_c('div',{staticClass:"mar-b-7"},[_c('h1',{staticClass:"h1"},[_vm._v("3/19/2025")]),_c('NewsEntry',{attrs:{"section":'Added',"items":[
      'Added color pickers on Colors page for easy editibility.',
      'Added filter and search capabilities to Item Mappings page.',
      ''
    ]}})],1),_c('div',{staticClass:"mar-b-7"},[_c('h1',{staticClass:"h1"},[_vm._v("3/13/2025")]),_c('NewsEntry',{attrs:{"section":'Fixed',"items":[
      'Entire business logo can now be seen on both the Summary and Full Bidsheet',
      ''
    ]}})],1),_c('div',{staticClass:"mar-b-7"},[_c('h1',{staticClass:"h1"},[_vm._v("2/20/2025")]),_c('NewsEntry',{attrs:{"section":'Added',"items":[
      'Supplier-specific colors are now available in the designer and used in BOM output',
      ''
    ]}})],1),_c('div',{staticClass:"mar-b-7"},[_c('h1',{staticClass:"h1"},[_vm._v("2/14/2025")]),_c('NewsEntry',{attrs:{"section":'Added',"items":[
      'Added item code placeholder LFT functionality and documetation',
      'Added help description for Materials settings '
    ]}}),_c('NewsEntry',{attrs:{"section":'Fixed',"items":[        
      'First quote after an supplier item code change now always uses the latest supplier code',
      'Removed unused items from the Materials default settings',
      'Fixed or improved numerous units of measure for settings'
    ]}})],1),_c('div',{staticClass:"mar-b-7"},[_c('h1',{staticClass:"h1"},[_vm._v("2/11/2025")]),_c('NewsEntry',{attrs:{"section":'Added',"items":[
      'Moved Dashboard content to Support tab',        
      'Help articles now support links to one-another for Wiki-style browsing',
    ]}})],1),_c('div',{staticClass:"mar-b-7"},[_c('h1',{staticClass:"h1"},[_vm._v("2/10/2025")]),_c('NewsEntry',{attrs:{"section":'Added',"items":[
      'A Help Center with searchable content is available under the Dashboard',
      'The What\'s New content has been moved under the Dashboard as a Change Log',
    ]}})],1),_c('div',{staticClass:"mar-b-7"},[_c('h1',{staticClass:"h1"},[_vm._v("2/6/2025")]),_c('NewsEntry',{attrs:{"section":'Fixed',"items":[
      'Project location now reliably accepting input from the Google suggestion box.',
    ]}}),_c('NewsEntry',{attrs:{"section":'Added',"items":[
      'Supplier Admins can now approve projects in the Engineering Review states.',
      'Added a link to feedback and support form for beta users\' convenience!'
    ]}})],1),_c('div',{staticClass:"mar-b-7"},[_c('h1',{staticClass:"h1"},[_vm._v("2/5/2025")]),_c('NewsEntry',{attrs:{"section":'Fixed',"items":[
      'Galvanized Purlin Sell Multipler setting name and description is much more clear for suppliers.',
      'Materials settings page now saves selected setting values.',        
    ]}}),_c('NewsEntry',{attrs:{"section":'Added',"items":[
      'Project state can be advanced from the project page.',
      'Projects show thier history, so you can review who did what and when!',
    ]}})],1),_c('div',{staticClass:"mar-b-7"},[_c('h1',{staticClass:"h1"},[_vm._v("2/4/2025")]),_c('NewsEntry',{attrs:{"section":'Added',"items":[
      'Must supplier settings now have clear description!',
    ]}})],1),_c('div',{staticClass:"mar-b-7"},[_c('h1',{staticClass:"h1"},[_vm._v("1/31/2025")]),_c('NewsEntry',{attrs:{"section":'Fixed',"items":[
    'Project List action menus do not open',
    'Project location picker is not near the input field'
  ]}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }