import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import * as THREE from 'three';
import { CORE, rakeTrimStyles, eaveTrimStyles, impactTypes, rebuildTypes, buildingCodes } from '../_spec.js';
import Foundation from './foundationbase';
import FrameBase from './framebase';
import BaySideFront from './BaySideFront.js';
import BaySideBack from './BaySideBack.js';;
import BayEndLeft from './BayEndLeft.js';
import BayEndRight from './BayEndRight.js';
import EndSkirt from './endSkirt.js'
import EndSkirtLeft from './endSkirtLeft.js'
import EndSkirtRight from './endSkirtRight.js'
import BlueprintHelper from '../helpers/blueprintHelper.js'
import Base from './Base'
import Appearance from './appearance'
import Options from './options'
import OptionHelper from '../helpers/optionHelper.js'

import Util from '../utility.js'
import { Vector3 } from 'three';
import DesignManager from '../design.js'
import TreeNode from '../helpers/TreeNode.js'

import _3dTrimCorner from '../3d/TrimCorner.js'
import _3dTrimTransitionStrip from '../3d/TrimTransitionStrip.js'
import _3dTrimGutterDownspout from '../3d/TrimGutterDownspout.js'
import _3dDistHori from '../3d/DistHori.js'
import MemHelper from '../helpers/memHelper.js';
import layerHelper from '../helpers/layerHelper.js';
import BpFrameLine from '../blueprints/bpFrameLineMain.js';
import BuildLogic from './BuildLogic.js';
import vHelper from '../helpers/vHelper.js';
import UpdateHelper from '../helpers/UpdateHelper.js';
import StructureHelper from '../helpers/StructureHelper.js';
import materialHelper from '../helpers/materialHelper.js';
import FramelineHelper from '../blueprints/FramelineHelper.js';
import ConstraintHelper from '../helpers/ConstraintHelper.js';
import ColumnSoldier from '../3d/ColumnSoldier.js';
import ConstraintMarker from '../3d/ConstraintMarker.js';
import ColorHelper from '../helpers/colorHelper.js';
import RoofMainGable from './RoofMainGable.js';
import RoofMainSlope from './RoofMainSlope.js';
import JobSpecs from './JobSpecs.js';
import WideOpening from './WideOpening.js';
import ComponentHelper from '../helpers/featureHelper.js';
import SystemHelper from '../helpers/SystemHelper.js';
import FSW from './Wall_FSW.js';
import BSW from './Wall_BSW.js';
import LEW from './Wall_LEW.js';
import REW from './Wall_REW.js';
import DesignHelper from '../helpers/DesignHelper.js';
import TransversePartition from './Wall_TransversePartition.js';

export default class StructureBase extends Base{
    /*
    this class represents a standard rectangular structure 
    The Ameristall raised center aisle would require another structure class
    */
    constructor(design, side, mDesign, model){
        super(design, mDesign);
        this.model = model;
        this.group.name = this.design.name || 'structure'
        //this.masterDesign = mDesign;
        this.isRotationInputEnabled=true;
        // we instantly swap in a new group 
        //let group = new THREE.Group();       
        //group.name = "CompGroup Structure";        // component group

        

        this.design.side = side; // we must support this for when a back porch has a structure with a roof whose texture needs to be rotated to align with the main structure.
        

        
        if(this.design.girts===undefined)
            this.design.girts={
                setLocations: CORE.girts.seven,
                maxSpacing: 60
            }
        
        if(this.design.wainscot===undefined){
            this.design.wainscot={
                enabled:false
            };
        }
        if(this.design.leftEnd===undefined){
            this.design.leftEnd={
                mainFrame:false,
                baySpacing:{
                    center: CORE.frame.end.spacing.center.default,
                    nonCenter: CORE.frame.end.spacing.nonCenter.default,
                    mode: CORE.modes.baySpacing.auto
                }
            };
        }
        if(this.design.rightEnd===undefined){
            this.design.rightEnd={
                mainFrame:false,
                baySpacing:{
                    center: CORE.frame.end.spacing.center.default,
                    nonCenter: CORE.frame.end.spacing.nonCenter.default,
                    mode: CORE.modes.baySpacing.auto
                }
            };
        }

        if(typeof design.leftEnd.baySpacing.mode === 'undefined')
            design.leftEnd.baySpacing.mode = 'auto'
        if(typeof design.rightEnd.baySpacing.mode === 'undefined')
            design.rightEnd.baySpacing.mode = 'auto'

        
        this.frameDesign = this.design.getFrame();

        this.roofDesign = this.design.getRoof();
        this.constraineeConstraints = ConstraintHelper.findConstraintsForNodeWithId(this.masterDesign.constraintRootNode,this.design.id);
        this.specificInit();
        
        if(this.optionDesign === null) // this is a data migration from 2020.12.29, during Beta 
            this.optionDesign = this.design.fixUntypedOptionComponent();
        
        if(this.optionDesign === null){
            mDesign.addComponent(design, DesignHelper.getDefaultOptions());
            this.optionDesign = this.design.getOptions();
        }

        if(this.foundationDesign === null)
        {
            mDesign.addComponent(design, DesignHelper.getDefaultFoundationDesign())
            this.foundationDesign = this.design.getFoundation();
        }

        if (this.jobSpecsDesign === null) {
            mDesign.addComponent(design, DesignHelper.getDefaultJobSpecs());
            this.jobSpecsDesign = this.design.getJobSpecs();
        }
        
        //this.recalculateTrimColors();
        
        if(this.optionDesign)
            this.roofDesign.gutters = this.optionDesign.gutters
        if(this.appearanceDesign)
            this.roofDesign.color = ColorHelper.to3(this.appearanceDesign.roof);
        this.initBlueprints()

        //this.constraineeDesigns = ConstraintHelper.getConstrainedStructures(mDesign.rootConstraintNode,this.design.id);
        
        
        this.create();
    }

    
    calculateFramelines(){   
        let f = this.design.getFrame();
        if(this.design.type === CORE.components.leanTo){
            let constraint = ConstraintHelper.getConstraintWithChildId(this.model.designManager.constraintRootNode, this.design.id)
            let parentFrame = this.masterDesign.getComponentDesignById(constraint.parent.structureID).getFrame();
            if(constraint.parent.matingSide === CORE.sides.backSide){
                f.sides.baySpacing.maxSize = parentFrame.sides.baySpacing.maxSize;
                f.sides.baySpacing.specialLeftSize = parentFrame.sides.baySpacing.specialRightSize;
                f.sides.baySpacing.specialRightSize = parentFrame.sides.baySpacing.specialLeftSize;
            }
            else{    
                f.sides.baySpacing.maxSize = parentFrame.sides.baySpacing.maxSize;
                f.sides.baySpacing.specialLeftSize = parentFrame.sides.baySpacing.specialLeftSize;
                f.sides.baySpacing.specialRightSize = parentFrame.sides.baySpacing.specialRightSize;
            }
        }
        
        this.design.frameLines = this.generateFrameLineData(f, this.masterDesign).points;
        this.design.nominalBayLengths = FramelineHelper.GetBayLengths(f);

    }


    disableRotationInput(){
        this.isRotationInputEnabled=false;
    }

    enableRotationInput(){
        if(!this.isRotationInputEnabled)
        {
            this.addRebuildNeeded(rebuildTypes.full);
        }
        this.isRotationInputEnabled=true;
    }

    lowEaveHeightInches()
    {
        return this.frameDesign.height * 12;
    }

    backEaveHeightInches(){
        return BlueprintHelper.height_BackEaveBottom(this.roofDesign.roofType, this.getStructureWidthInches(), this.lowEaveHeightInches(), this.GetPitchRatio())
    }
    
    structureLengthInches()
    {
        return this.frameDesign.length * 12;
    }


    structureWidthInches()
    {
        return this.frameDesign.width * 12;
    }

    getStructureScopedConfig(appearanceDesign){
        // this creates an object of information scoped to this building, specifically for sharing to all descendant components   
        let structureConfig = {
            wallColor: ColorHelper.to3(appearanceDesign.wall),
            roofColor: ColorHelper.to3(appearanceDesign.roof),
            trimMaterials: {},
            wainscotColor: ColorHelper.to3(appearanceDesign.wainscoting),
        }

        if(appearanceDesign.trimColorMode === CORE.modes.trimColor.single){            
            structureConfig.trimMaterials.eaveAndRake= materialHelper.getTrimMaterial(ColorHelper.to3(appearanceDesign.trim), CORE.textures.trim.clone());
            structureConfig.trimMaterials.downspout= materialHelper.getTrimMaterial(ColorHelper.to3(appearanceDesign.trim), CORE.textures.trim.clone());
            structureConfig.trimMaterials.corner= materialHelper.getTrimMaterial(ColorHelper.to3(appearanceDesign.trim), CORE.textures.trim.clone());
            structureConfig.trimMaterials.door= materialHelper.getTrimMaterial(ColorHelper.to3(appearanceDesign.trim), CORE.textures.trim.clone());
            structureConfig.trimMaterials.walksAndWindows= materialHelper.getTrimMaterial(ColorHelper.to3(appearanceDesign.trim), CORE.textures.trim.clone());
        }
        else
        {
            structureConfig.trimMaterials.eaveAndRake= new THREE.MeshPhongMaterial( { color: ColorHelper.to3(appearanceDesign.trims.eaveAndRake), map: CORE.textures.trim.clone(), side: THREE.DoubleSide, wireframe:false});
            structureConfig.trimMaterials.downspout= new THREE.MeshPhongMaterial( 
                { color: ColorHelper.to3(this.appearanceDesign.trims.downspout),
                    map: CORE.textures.trim.clone(),
                    side: THREE.DoubleSide,
                    wireframe:false,
                    shininess:10,
                    specular: new THREE.Color("skyblue")
                });            
            structureConfig.trimMaterials.corner= new THREE.MeshPhongMaterial( { color: ColorHelper.to3(appearanceDesign.trims.corner), map: CORE.textures.trim.clone(), side: THREE.DoubleSide, wireframe:false});
            structureConfig.trimMaterials.door= new THREE.MeshPhongMaterial( { color: ColorHelper.to3(appearanceDesign.trims.door), map: CORE.textures.trim.clone(), side: THREE.DoubleSide, wireframe:false});
            structureConfig.trimMaterials.walksAndWindows= new THREE.MeshPhongMaterial( { color: ColorHelper.to3(appearanceDesign.trims.walksAndWindows), map: CORE.textures.trim.clone(), side: THREE.DoubleSide, wireframe:false});
        }
        return structureConfig;
    }

    applyColors(appearanceDesign) {
        
        this.frontWallDesign = this.design.getFrontWall();
        if(this.frontWallDesign){
            this.frontWallDesign.color = ColorHelper.to3(appearanceDesign.wall);
            
            if(!this.frontWallDesign.wainscot)
                this.frontWallDesign.wainscot = {}
            if(!this.frontWallDesign.trim)
                this.frontWallDesign.trim = {}
            this.frontWallDesign.wainscot.color = ColorHelper.to3(appearanceDesign.wainscoting);
            this.frontWallDesign.trim.color = ColorHelper.to3(appearanceDesign.trim);
        }
        
        
            this.rightWallDesign = this.design.getRightWall();
        if(this.rightWallDesign){
            this.rightWallDesign.color = ColorHelper.to3(appearanceDesign.wall);
            if(!this.rightWallDesign.wainscot)
                this.rightWallDesign.wainscot = {}
            if(!this.rightWallDesign.trim)
                this.rightWallDesign.trim = {}
            this.rightWallDesign.wainscot.color = ColorHelper.to3(appearanceDesign.wainscoting);
            this.rightWallDesign.trim.color = ColorHelper.to3(appearanceDesign.trim);
        }

        
        this.backWallDesign = this.design.getBackWall();
        if(this.backWallDesign){
            this.backWallDesign.color = ColorHelper.to3(appearanceDesign.wall);
            if(!this.backWallDesign.wainscot)
                this.backWallDesign.wainscot = {}
            if(!this.backWallDesign.trim)
                this.backWallDesign.trim = {}
            this.backWallDesign.wainscot.color = ColorHelper.to3(appearanceDesign.wainscoting);
            this.backWallDesign.trim.color = ColorHelper.to3(appearanceDesign.trim);
        }

        
        this.leftWallDesign = this.design.getLeftWall();
        if(this.leftWallDesign){
            this.leftWallDesign.color = ColorHelper.to3(appearanceDesign.wall);
            if(!this.leftWallDesign.wainscot)
                this.leftWallDesign.wainscot = {}
            if(!this.leftWallDesign.trim)
                this.leftWallDesign.trim = {}
            this.leftWallDesign.wainscot.color = ColorHelper.to3(appearanceDesign.wainscoting);
            this.leftWallDesign.trim.color = ColorHelper.to3(appearanceDesign.trim);
        }

        this.partitionDesigns = this.design.getTransversePartitions();
        if (this.partitionDesigns) {
            this.partitionDesigns.forEach((des) => {
                if(des){
                    des.color = ColorHelper.to3(appearanceDesign.wall);
                    if(!des.wainscot)
                        des.wainscot = {}
                    if(!des.trim)
                        des.trim = {}
                    des.wainscot.color = ColorHelper.to3(appearanceDesign.wainscoting);
                    des.trim.color = ColorHelper.to3(appearanceDesign.trim);
                }
            });
        }
    }


    getDimensions(){
        return {        
            // add the world position of the structure
            xmin: this.design.pos.x + Math.min(this.cornerBL.position.x,this.cornerBR.position.x,this.cornerFL.position.x,this.cornerFR.position.x),
            xmax: this.design.pos.x + Math.max(this.cornerBL.position.x,this.cornerBR.position.x,this.cornerFL.position.x,this.cornerFR.position.x),
            zmin: this.design.pos.z + Math.min(this.cornerBL.position.z,this.cornerBR.position.z,this.cornerFL.position.z,this.cornerFR.position.z),
            zmax: this.design.pos.z + Math.max(this.cornerBL.position.z,this.cornerBR.position.z,this.cornerFL.position.z,this.cornerFR.position.z)
        }        
    }

    
    initRebuildHierarchy(){        
        this.rebuildTypeRoot = new TreeNode(null, rebuildTypes.full);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.roof);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.width);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.wall);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.trim);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.move);
        this.rebuildTypeRoot.addChildNode(rebuildTypes.name);

    }

   /* getInterpolatedPoint(o1, o2, lengthFt){

        
        let wp1 = new THREE.Vector3();
        o1.getWorldPosition(wp1);
        let wp2 = new THREE.Vector3();
        o2.getWorldPosition(wp2);
        let point = wp1.clone()
        let distance = wp1.distanceTo(wp2)
        let lengthInches = lengthFt*12;
        let ratio = lengthInches/distance        
        point = point.lerp(wp2, ratio);
        return point;
    }*/

    getPointInWorld(o1){
        let wp1 = new THREE.Vector3();
        o1.getWorldPosition(wp1);
        return wp1;
    }

    rotateToward(pos){

        // for reference: center of Right End wall is 0 radians

        // to rotate that toward pos, we need an angle in radians
        // that angle represents radian distance from 0 (center REW)


        // pos is in world coordinates
        // put center in world coordinates

        let vector = pos.sub(new Vector3(this.design.pos.x, 0, this.design.pos.z));
        //let vector = new Vector3(this.design.pos.x, 0, this.design.pos.z).sub(pos);//.sub();
        console.log(vector);
        vector.z*=-1;
        let newRot = Math.atan(vector.x/vector.z);
        newRot-=Math.PI/2;
        if(vector.z<=0)
            newRot-=Math.PI;
        newRot*=-1;
        newRot = Math.floor(newRot/(Math.PI/12))*Math.PI/12;
        
        //console.log(newRot);
        this.setRotation(newRot);
        return UpdateHelper.createImpact(this.design, impactTypes.structureRotation)            

    }

    adjustRotation(y){        
        this.setRotation(this.design.rotRad + y);
    }

    setRotation(y){
        this.design.rotRad = y;
        this.group.rotation.y=this.design.rotRad;
        //this.group.updateMatrix();

    }

    alterPosition(delta){
        this.design.pos.x+=delta.x;
        this.design.pos.z+=delta.z;
    }

    getConstrainerPosition(constraint){
    
        // use corner objects to interpolate to the correct point
        let pos;
        if(constraint.matingSide === CORE.sides.frontSide)
            pos= StructureHelper.getInterpolatedPointBetweenObjectsInWorldSpace(this.cornerFL,this.cornerFR, constraint.length);        
        else if(constraint.matingSide === CORE.sides.backSide)
            pos= StructureHelper.getInterpolatedPointBetweenObjectsInWorldSpace(this.cornerBR,this.cornerBL, constraint.length);
        else if(constraint.matingSide === CORE.sides.leftEnd)
            pos= StructureHelper.getInterpolatedPointBetweenObjectsInWorldSpace(this.cornerBL,this.cornerFL, constraint.length);        
        else if(constraint.matingSide === CORE.sides.rightEnd)
            pos= StructureHelper.getInterpolatedPointBetweenObjectsInWorldSpace(this.cornerFR,this.cornerBR, constraint.length);
        else 
            throw 'getConstrainerPosition: constraint not supported';
        
        return pos;
/*        
        // use corner objects to interpolate to the correct point
        let pos;
        if(constraint.matingSide === CORE.sides.frontSide)
            pos= StructureHelper.getInterpolatedPoint(this.cornerFL.position,this.cornerFR.position, constraint.length);        
        else if(constraint.matingSide === CORE.sides.backSide)
            pos= StructureHelper.getInterpolatedPoint(this.cornerBR.position,this.cornerBL.position, constraint.length);
        else if(constraint.matingSide === CORE.sides.leftEnd)
            pos= StructureHelper.getInterpolatedPoint(this.cornerBL.position,this.cornerFL.position, constraint.length);        
        else if(constraint.matingSide === CORE.sides.rightEnd)
            pos= StructureHelper.getInterpolatedPoint(this.cornerFR.position,this.cornerBR.position, constraint.length);
        else 
            throw 'getConstrainerPosition: constraint not supported';
        
        return pos;
        */
    }

    getConstraineePosition(constraint){
    
        // use corner objects to locate to the correct point
        let pos;
        if(constraint.matingSide === CORE.sides.frontSide)
            pos= this.getPointInWorld(this.cornerFR,this.cornerFR, 0);        
        else if(constraint.matingSide === CORE.sides.backSide)
            pos= this.getPointInWorld(this.cornerBL,this.cornerBL, 0);
        else if(constraint.matingSide === CORE.sides.leftEnd)
            pos= this.getPointInWorld(this.cornerFL,this.cornerFL, 0);
        else if(constraint.matingSide === CORE.sides.rightEnd)
            pos= this.getPointInWorld(this.cornerBR,this.cornerBR, 0);
        else
            throw 'getConstraineePosition: constraint not supported';
        
        return pos
    }

    getCornerPointObject(corner, point){
        return {corner,point};
    }
    getCorners(){
        return [
            this.getCornerPointObject(CORE.corners.FL,this.cornerFL),
            this.getCornerPointObject(CORE.corners.FR,this.cornerFR),
            this.getCornerPointObject(CORE.corners.BL,this.cornerBL),
            this.getCornerPointObject(CORE.corners.BR,this.cornerBR)
        ];
    }



    recalculateTrimColors()
    {
        if(this.appearanceDesign.trimColorMode === CORE.modes.trimColor.single){            
            let allTrim = materialHelper.getTrimMaterial(ColorHelper.to3(this.appearanceDesign.trim), CORE.textures.trim.clone())

            CORE.materials.trims.eaveAndRake= 
            CORE.materials.trims.downspout= 
            CORE.materials.trims.corner= 
            CORE.materials.trims.door= 
            CORE.materials.trims.walksAndWindows= allTrim;
        }
        else{
            CORE.materials.trims.eaveAndRake= new THREE.MeshPhongMaterial( { color: ColorHelper.to3(this.appearanceDesign.trims.eaveAndRake), map: CORE.textures.trim.clone(), side: THREE.DoubleSide, wireframe:false});
            CORE.materials.trims.downspout= new THREE.MeshPhongMaterial( 
                { color: ColorHelper.to3(this.appearanceDesign.trims.downspout),
                    map: CORE.textures.trim.clone(),
                    side: THREE.DoubleSide,
                    wireframe:false,
                    shininess:10,
                    specular: new THREE.Color("skyblue")
                });            
            CORE.materials.trims.corner= new THREE.MeshPhongMaterial( { color: ColorHelper.to3(this.appearanceDesign.trims.corner), map: CORE.textures.trim.clone(), side: THREE.DoubleSide, wireframe:false});
            CORE.materials.trims.door= new THREE.MeshPhongMaterial( { color: ColorHelper.to3(this.appearanceDesign.trims.door), map: CORE.textures.trim.clone(), side: THREE.DoubleSide, wireframe:false});
            CORE.materials.trims.walksAndWindows= new THREE.MeshPhongMaterial( { color: ColorHelper.to3(this.appearanceDesign.trims.walksAndWindows), map: CORE.textures.trim.clone(), side: THREE.DoubleSide, wireframe:false});
        }
    }

    initBlueprints(){
            
        let roof = this.design.getRoof();
        this.roofFrameType = roof.roofFrameType;
        this.pitchRatio = roof.pitch/12;
        let pitchAngle = Math.atan(this.pitchRatio) // radians     
        let frame = this.design.getFrame();
        let frameWidth = Util.Convert.ftToIn(frame.width);
        let frameHeight = Util.Convert.ftToIn(frame.height);
        let purlinDimY = CORE.roof.purlin.dim.height;// + (2*CORE.roof.purlin.dim.thickness);
        let pitchDimY= frameWidth * this.pitchRatio;
        let pitchedPurlinDimY = BlueprintHelper.pitchedPurlinDimY(this.pitchRatio);
        let roofFrameType = roof.roofFrameType
        let standardFrameDims = BlueprintHelper.getStandardFrameMaterialSize();
        this.frameLineStandard = BpFrameLine.generate(CORE.frame.lineTypes.standard, standardFrameDims, frameHeight, this.design.frameType, pitchAngle, roof.roofType, pitchDimY, pitchedPurlinDimY, frameWidth, this.pitchRatio, roofFrameType);
    }



    migrate(design){
       
        let frameDesign = design.components.filter((c)=>{return c.type === CORE.components.frame})[0];

        // if the old bay spacing properties are present, 
        if(frameDesign.sideBaySpacing){

            FrameBase.migrateSides(frameDesign); // just creating this to get migrated
            design.sides = frameDesign.sides;
        }

    }

    static canEdit(){return true;}
    static canDelete(){return true;}
    static canCopy(){return false;}

    getOutlineMeshes(){     
        if(this.wallBack && this.wallBack && this.wallLeft && this.wallRight && this.foundation && this.roof)               
        return [
            ...this.wallLeft.getOutlineMeshes(),
            ...this.wallRight.getOutlineMeshes(),
            ...this.wallBack.getOutlineMeshes(),
            ...this.wallBack.getOutlineMeshes(),
            ...this.foundation.getOutlineMeshes(),
            ...this.roof.getOutlineMeshes()
        ];
        else
        return [];
    }

    detectImpact(impact){
        
        if(this.shouldProcessOwnImpact(impact, this.design.id))
            this.processImpactFromSelf(impact);
        // impact is an impact from any component (including itself)
        // return 0+ impacts for itself 
        //console.log('Porch detecting impact:', impact);
        if(!this.isOwnImpact(impact))
            this.processImpactFromOther(impact);        
    }

    // process impact from OTHER COMPONENT
    processImpactFromOther(impact){
        impact.handled.push(this.design.id);
        let constraint;
        switch(impact.change.name){
            case impactTypes.optionsGutter:
            case impactTypes.optionsInsulationEnergySaverFramelineStart:
            case impactTypes.optionsInsulationEnergySaverFramelineStop:
            case impactTypes.optionsInsulationRoof:
            case impactTypes.optionsRoofExtFront:
            case impactTypes.optionsRoofExtBack:
            case impactTypes.optionsRoofExtLeft:
            case impactTypes.optionsRoofExtBack:
                this.addRebuildNeeded(rebuildTypes.roof);                
                break;
            case impactTypes.optionsDripTrim:
            case impactTypes.optionsInsulationWall:
            case impactTypes.structureWalls:
            case impactTypes.porchWidth: 
            case impactTypes.porchLength: 
            case impactTypes.leanToWdith:
            case impactTypes.leanToLength:
                this.addRebuildNeeded(rebuildTypes.wall);
                break;
            case impactTypes.structureRotation:
                constraint = ConstraintHelper.areConstrainedTogether(this.design.id, impact.id, this.masterDesign.constraintRootNode);
                if(constraint && constraint.parent.structureID === impact.design.id)
                {
                    this.design.rotRad = impact.design.rotRad;
                    
                    this.addRebuildNeeded(rebuildTypes.move);
                }
                break;
            case impactTypes.foundationHeight:
                if(this.getChildComponentById(impact.design.id))
                {
                    this.groupMetal.position.y = impact.design.height;
                }
                
                break;
            case impactTypes.wrapTubePorchCols:
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.optionsOhJambLiner:                                
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.openWall:
                this.addRebuildNeeded(rebuildTypes.full);
                break;
        }
        this.specificInit();
    }

    // process impact from THIS COMPONENT
    processImpactFromSelf(impact){
        impact.handled.push(this.design.id);
        switch(impact.change.name){
            case impactTypes.structureRoofFrameType:
            case impactTypes.porchRoofFrameType:
            case impactTypes.optionsGalvanizedPurlins:
            case impactTypes.optionsGalvanizedBeams:
                this.addRebuildNeeded(rebuildTypes.full);
                break;
            case impactTypes.structureRotation:
                this.addRebuildNeeded(rebuildTypes.move);
                break;
            case impactTypes.structureLength:
                this.specificInit();
                break;
            case impactTypes.structureName:
                this.addRebuildNeeded(rebuildTypes.name);
                break;
            
        }
    }

    getImpactTypeForWidth(){
        return impactTypes.structureWidth;
    }

    getImpactTypeForLength(){
        return impactTypes.structureLength;
    }


    getOption_FrameType(){
        let selOptions = [  {
            value: CORE.frame.types.bolt,
            text: `Bolt Up`
        },
        {
            value: CORE.frame.types.weld,
            text:`Weld Up`
        }];
        if(this.model.store.state.isSouthWestMetalUser){
            selOptions.push({
                value: CORE.frame.types.weldPlus,
                text: `Weld PLUS`
            })
        }

        return OptionHelper.selection("frameType","Frame", impactTypes.structureFrameType, undefined, selOptions,
        true,
        ()=>{//fnIn
            return this.frameDesign.frameType;
        },
        undefined,
            (v) => {//fnChanged
            // these job spec fields need to change when the frametype changes.
            if(this.jobSpecsDesign) // this may be a shed which has no job Specs itself
            {
                this.jobSpecsDesign.buildingCode = buildingCodes.ibc15;
                this.jobSpecsDesign.seals = (v === CORE.frame.types.bolt);
            }

            if(v !== CORE.frame.types.bolt){
                if(this.design.type === CORE.components.structure || this.design.type === CORE.components.leanTo){
                    if(this.optionDesign.galvBeams || this.optionDesign.galvPurlins){
                        this.optionDesign.galvBeams = false;
                        this.optionDesign.galvPurlins = false;
                        this.model.store.dispatch("showUnappliedGalvOptionsWarning", {"structureName": this.design.name, "structureFrameType": v})
                        this.frameDesign.frameType = v;
                    }
                    else 
                        this.frameDesign.frameType = v;
                }
                else if(this.design.type === CORE.components.porch){
                    if(this.optionDesign.galvBeams || this.optionDesign.galvPurlins){
                        let parentId = ConstraintHelper.getConstraintWithChildId(this.model.designManager.constraintRootNode, this.design.id).parent.structureID;
                        this.model.store.dispatch("showUnappliedPorchFrameTypeWarning", {"parentName": this.model.getComponentById(parentId).design.name, "porchName": this.design.name, "porchFrameType": v});
                    }
                    else
                        this.frameDesign.frameType = v;
                }
            } 
            else
                this.frameDesign.frameType = v;

            
        }
        );
    }

    getOption_Wainscoting(){
        return [
        OptionHelper.header('Wainscoting'),
        OptionHelper.checkbox('wainscot.enabled', 'Include Wainscoting', impactTypes.structureWainscot, 
        true,
        undefined,
        undefined,
        (v)=>{
            if(this.frontWallDesign)
                this.frontWallDesign.wainscot.enabled= v;
            if(this.backWallDesign)
                this.backWallDesign.wainscot.enabled= v;
            if(this.leftWallDesign)
            {
                let LEWtvP;
                let tvPartitions = this.design.getTransversePartitions();
                tvPartitions.forEach((tvp)=>{
                    if(tvp.sheetingLeft){
                        LEWtvP = tvp;
                        return;
                    }
                })

                if(LEWtvP){
                    LEWtvP.wainscot.enabled=v;
                    this.leftWallDesign.wainscot.enabled = false;
                }
                else
                    this.leftWallDesign.wainscot.enabled= v;
                // this.leftWallDesign.wainscot.enabled= v;
                // if(this.leftWallDesign.openWall || this.leftWallDesign.openHeight > 0)
                // {
                //     let tvPartitions = this.design.getTransversePartitions();
                //     tvPartitions.forEach((tvp)=>{
                //         if(tvp.sheetingLeft)
                //             tvp.wainscot.enabled=true;
                //     })
                // }
            }
            if(this.rightWallDesign)
            {
                let REWtvP;
                let tvPartitions = this.design.getTransversePartitions();
                tvPartitions.forEach((tvp)=>{
                    if(tvp.sheetingRight){
                        REWtvP = tvp;
                        return;
                    }
                })

                if(REWtvP){
                    REWtvP.wainscot.enabled=v;
                    this.rightWallDesign.wainscot.enabled = false;
                }
                else
                    this.rightWallDesign.wainscot.enabled= v;
                // this.rightWallDesign.wainscot.enabled= v;
                // if(this.rightWallDesign.openWall || this.rightWallDesign.openHeight > 0)
                // {
                //     let tvPartitions = this.design.getTransversePartitions();
                //     tvPartitions.forEach((tvp)=>{
                //         if(tvp.sheetingRight)
                //             tvp.wainscot.enabled=true;
                //     })
                // }
            }
            this.design.wainscot.enabled=v;
        }),            
        OptionHelper.numericUpDown("wainscot.height", "Height", impactTypes.structureWainscot, "in.", 0, 48, 1, 
        true,
//            OptionHelper.numericUpDown("wainscot.height", "Height", impactTypes.structureWainscot, "in.", 0, 48, 1, 
        ()=>{
            if(this.frontWallDesign)
                return this.frontWallDesign.wainscot.height;
            else
                return 38;
        }, // fnIn
        undefined, // fnOut
        (v)=>{ // fnChanged
            if(this.frontWallDesign)
                this.frontWallDesign.wainscot.height= v;
            if(this.backWallDesign)
                this.backWallDesign.wainscot.height= v;
            if(this.leftWallDesign)
                this.leftWallDesign.wainscot.height= v;
            if(this.rightWallDesign)
                this.rightWallDesign.wainscot.height= v;
            this.design.wainscot.height=v;
        })];
    }

    getOption_SkirtRightEnd(){
        return OptionHelper.checkbox("rightEnd.skirt", "Skirt", impactTypes.structureEndSkirt,false, undefined, undefined,
        (v)=>{

            this.design.rightEnd.skirt = v;
            if(!this.design.rightEnd.skirt){
                this.components = this.components.filter((c) => c.design.type !== CORE.components.skirtRight)
                delete this.rightEndSkirt
            }
        });
    }

    getOption_SkirtLeftEnd(){
        return OptionHelper.checkbox("leftEnd.skirt", "Skirt", impactTypes.structureEndSkirt,false,undefined,undefined,
        (v)=>{

            this.design.leftEnd.skirt = v;
            if(!this.design.leftEnd.skirt){
                this.components = this.components.filter((c) => c.design.type !== CORE.components.skirtLeft)
                delete this.leftEndSkirt
            }
        })
    }
    getOption_OpenBaysLeftEnd(){
        let lewOpenBayMax = (this.design.frameLines.length - 1) - (this.rightWallDesign.frameLine+1);
        return OptionHelper.numericUpDown("leftEnd.openBays", "Open Bays", impactTypes.structureOpenBays, "", 0, lewOpenBayMax, 1, 
        false,
        //let leftEndOpenBays = OptionHelper.numericUpDown("leftEnd.openBays", "Open Bays", impactTypes.structureOpenBays, "", 0, lewOpenBayMax, 1, 
        ()=>{ // fnIn
            return this.leftWallDesign.frameLine;
        },
        undefined, // fnOut
        (v)=>{ // fnChanged
            if(v>lewOpenBayMax)
                v = lewOpenBayMax;
            if(this.leftWallDesign.frameLine === 0 && v > 0) // only if going from coplanar to not coplanar
                this.design.leftEnd.mainFrame = true; // change this automatically
            this.leftWallDesign.frameLine = v;            
        });
    }

    getOption_OpenBaysRightEnd(){
        let rewOpenBayMax = (this.design.frameLines.length - 1) - (this.leftWallDesign.frameLine+1);
        return OptionHelper.numericUpDown("rightEnd.openBays", "Open Bays", impactTypes.structureOpenBays, "", 0, rewOpenBayMax, 1, 
        false,
//        let rightEndOpenBays = OptionHelper.numericUpDown("rightEnd.openBays", "Open Bays", impactTypes.structureOpenBays, "", 0, rewOpenBayMax, 1, 
        ()=>{ // fnIn
            return this.rightWallDesign.frameLine;
        }, 
        undefined, // fnOut
        (v)=>{ // fnChanged
            if(v>rewOpenBayMax)
                v = rewOpenBayMax;
            // if the previous right wall frame line was the last frame line
            // and the new value is not the last frame line
            if(this.rightWallDesign.frameLine === 0 && v > 0)
                // automatically make the right end have main-frames
                this.design.rightEnd.mainFrame = true;
            // update the current setting to the new value;
            this.rightWallDesign.frameLine = v; // right is zero-based from the length-1
        });
    }
    
    getOptionsSpec(){    
        //let hasLeftEndPorch = this.hasLeftEndPorch();
        //let hasRightEndPorch = this.hasRightEndPorch();

        // let leftEndMainFrame = OptionHelper.checkbox("leftEnd.mainFrame", "Main Frame", impactTypes.structureMainFrame,
        // true,
        // ()=>{ // fnIn
        //     return this.design.leftEnd.mainFrame;
        // },
        // undefined,
        // (v)=>{
        //     this.design.leftEnd.mainFrame = v;
            
        // });
        // leftEndMainFrame.procOrder = 1; // leftEnd.openBays can overwrite this value, so this needs to occur first

        // let rightEndMainFrame = OptionHelper.checkbox("rightEnd.mainFrame", "Main Frame", impactTypes.structureMainFrame,
        // true,
        // ()=>{ // fnIn
        //     return this.design.rightEnd.mainFrame;
        // },
        // undefined,
        // (v)=>{
        //     this.design.rightEnd.mainFrame = v;
        // })
        // rightEndMainFrame.procOrder = 1;  // rightEnd.openBays can overwrite this value, so this needs to occur first

        //let leftEndOpenBays = this.getOption_OpenBaysLeftEnd();
        //leftEndOpenBays.procOrder = 2; // leftEnd.baySpacing.mode depends on frameline having already been set.
    
        //let rightEndOpenBays = this.getOption_OpenBaysRightEnd();
        //rightEndOpenBays.procOrder = 2; // rightEnd.baySpacing.mode depends on frameline having already been set.

        let lengthMin = this.frameDesign.sides.baySpacing.specialRightSize + this.frameDesign.sides.baySpacing.specialLeftSize;
        let specialLeftBayConstrainedMax = this.frameDesign.length - this.frameDesign.sides.baySpacing.specialRightSize;
        let specialRightBayConstrainedMax = this.frameDesign.length - this.frameDesign.sides.baySpacing.specialLeftSize;

        let opts = [
            OptionHelper.text("name","Name", impactTypes.structureName, 
                (v)=>{//fnIn
                    return v;
                }, 
                (v)=>{//fnOut
                    const regex = new RegExp('[\\\\/:*?"<>|]', 'g')
                    if (regex.test(v)) {
                        return v.replaceAll(regex, '');
                    }
                    else {
                        return v;
                    }
                },
                (v)=> {
                    //fnChange
                    if(StructureHelper.StructureNameIsUnique(this.masterDesign, v)) {
                        this.design.name = v;
                        return { canceled: false, message: ""}
                    }                        
                    else {
                        this.changed = false;
                        return { canceled: true, message: v}
                    }  
                }
                
            ),
            this.getOption_FrameType(),
            OptionHelper.selection("roofType","Roof", impactTypes.structureRoofType, undefined, [
                {
                    value: CORE.roof.types.gable,
                    text: `Gable`
                },
                {
                    value: CORE.roof.types.slope,
                    text:`Slope`
                }],
                true,
                ()=>{//fnIn
                    return this.roofDesign.roofType
                },
                // (v)=>{//fnOut
                //     if(v == CORE.roof.types.gable)
                //         return CORE.roof.types.gable;
                //     return CORE.roof.types.slope;
                // },
                undefined,
                (v)=>{//fnChanged
                    this.roofDesign.roofType = v;
                    const bswDelta = BlueprintHelper.getBackSideWallHeightDeltaInches(this.getStructureWidthInches(), this.roofDesign.pitch, this.roofDesign.roofType);
                    let bswBays = this.backWallDesign.getBackSideBays();
                    bswBays.forEach((b)=>{
                        b.height+=bswDelta;
                    })
                }
            ),
            // OptionHelper.switch("roofType", "Roof", null, "Slope", "Gable", impactTypes.structureRoofType, {checked: '#666666', unchecked:"#666666"}, {checked: '#0000FF', unchecked:"#00FF00"},
            // true,
            // ()=>{//fnIn
            //     return this.roofDesign.roofType === CORE.roof.types.gable;
            // },
            // (v)=>{//fnOut
            //     if(v)
            //         return CORE.roof.types.gable;
            //     return CORE.roof.types.slope;
            // },
            // (v)=>{//fnChanged
            //     this.roofDesign.roofType = v;
            //     const bswDelta = BlueprintHelper.getBackSideWallHeightDeltaInches(this.getStructureWidthInches(), this.roofDesign.pitch, this.roofDesign.roofType);
            //     let bswBays = this.backWallDesign.getBackSideBays();
            //     bswBays.forEach((b)=>{
            //         b.height+=bswDelta;
            //     })
            // }
            // ),

            OptionHelper.numericUpDown("width","Width", this.getImpactTypeForWidth(), "ft.", 
            this.getWidthMinimum(), 100, 1,
            true,
//            OptionHelper.numericUpDown("width","Width", impactTypes.structureWidth, "ft.", 20, 100, 1,
            ()=>{ //fnIn
                return this.frameDesign.width;
            }, 
            undefined,
            (v)=>{ //fnChange
                this.applyChangeWidth(v*12);
            },  
            ),

            OptionHelper.numericUpDown("length","Length", this.getImpactTypeForLength(), "ft.", lengthMin, 300, 1,
            true,
//            OptionHelper.numericUpDown("length","Length", impactTypes.structureLength, "ft.", lengthMin, 300, 1,
            ()=>{
                return this.frameDesign.length;
            }, //fnIn
            undefined, //fnOut
            (v)=>{//fnChange

                this.frameDesign.length = v;
            }
            ),

            OptionHelper.numericUpDown("height","Height", impactTypes.structureHeight, "ft.", 7, 20, 1, 
            true,
//            OptionHelper.numericUpDown("height","Height", impactTypes.structureHeight, "ft.", 7, 20, 1, 
            ()=>{
                return this.frameDesign.height;
            }, //fnIn
            undefined, //fnOut
            (v)=>{//fnChange
                let delta = (v-this.frameDesign.height)*12;
                this.frameDesign.height = v;

                this.updateBayHeights(delta)
                
                this.backWallDesign.height+=delta;
                this.frontWallDesign.height+=delta;
                this.leftWallDesign.height+=delta;
                this.rightWallDesign.height+=delta;
            }),

            OptionHelper.numericUpDown("pitch","Roof Pitch", impactTypes.structureRoofPitch, "/12", 0, 6, 1.0/4.0,
            true,
//            OptionHelper.numericUpDown("pitch","Roof Pitch", impactTypes.structureRoofPitch, "/12", 1, 6, 1,
            ()=>{
                return this.roofDesign.pitch;
            }, //fnIn
            undefined, //fnOut
            (v)=>{//fnChange
                if(v>6)
                    v=6;
                if(v<0)
                    v=0;
                
                let interval = 1.0/4.0;
                v = Math.round(v/interval)*interval;

                if(this.roofDesign.roofType === CORE.roof.types.slope){
                    let oldV = BlueprintHelper.pitchHeight(this.getStructureWidthInches(),BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch), this.roofDesign.roofType);
                    let newV =  BlueprintHelper.pitchHeight(this.getStructureWidthInches(),BlueprintHelper.pitchToPitchRatio(v), this.roofDesign.roofType);
                    // detect how the change in pitch affected the back wall's height
                    let delta = newV-oldV;
                    this.backWallDesign.height+= delta;
                    let bswBays = this.backWallDesign.getBackSideBays();
                    bswBays.forEach((b)=>{
                        b.height+=delta;
                    })

                }

                this.roofDesign.pitch = v;

                if(this.roofDesign.roofType === CORE.roof.types.slope){
                    // update the maximum height the back wall can be                    
                    this.wallBack.maxHeight = this.getBackSideWallHeightInches()
 
                    // ensure that the back wall height is positive
                    if(this.backWallDesign.height < 0)
                    {
                        this.backWallDesign.height = 0
                        let bswBays = this.backWallDesign.getBackSideBays();
                            bswBays.forEach((b)=>{
                            b.height=0;
                        })
                    }
                }
            }),
            OptionHelper.selection("roofFrameType","Roof Frame Type", impactTypes.structureRoofFrameType, undefined, [
                {
                    value: CORE.roof.frameTypes.flush,
                    text: `Flush`
                },
                {
                    value: CORE.roof.frameTypes.bypass,
                    text:`Bypass`
                }],
                true,
                ()=>{//fnIn
                    return this.roofDesign.roofFrameType;
                },
                undefined,
                (v)=>{//fnChanged
                    this.roofDesign.roofFrameType = v;
                }
            ),
            // OptionHelper.switch("roofFrameType", "Roof Frame Type", null, "Flush", "Bypass", impactTypes.structureRoofFrameType, {checked: '#666666', unchecked:"#666666"}, {checked: '#0000FF', unchecked:"#00FF00"},
            // true,
            // ()=>{//fnIn
            //     return this.roofDesign.roofFrameType === CORE.roof.frameTypes.bypass;
            // },
            // (v)=>{//fnOut
            //     if(v)
            //         return CORE.roof.frameTypes.bypass;
            //     return CORE.roof.frameTypes.flush;
            // },
            // (v)=>{//fnChanged
            //     this.roofDesign.roofFrameType = v;
            // }
            // ),            

            OptionHelper.numericUpDown("sides.baySpacing.specialLeftSize","Special LEW Bay",  impactTypes.structureBaySpacing, "ft.", CORE.frame.side.spacing.endBay.min, Math.min(specialLeftBayConstrainedMax, CORE.frame.side.spacing.endBay.max), 1,
            // is this a gable tie-in?
            !this.isGableTieStructure(),
//            OptionHelper.numericUpDown("sides.baySpacing.specialLeftSize","Special LEW Bay", impactTypes.structureBaySpacing, "ft.", CORE.frame.side.spacing.endBay.min, Math.min(specialLeftBayConstrainedMax, CORE.frame.side.spacing.endBay.max), 1,
            ()=>{
                if(this.frameDesign.sides.baySpacing.specialLeftSize)                    
                    return this.frameDesign.sides.baySpacing.specialLeftSize;
                return "";
            },
            undefined,
            (v)=>{
                if(v)
                    this.frameDesign.sides.baySpacing.specialLeftSize = v;                
                else
                    this.frameDesign.sides.baySpacing.specialLeftSize = null;    
            }),
            OptionHelper.numericUpDown("sides.baySpacing.specialRightSize","Special REW Bay", impactTypes.structureBaySpacing, "ft.", CORE.frame.side.spacing.endBay.min, Math.min(specialRightBayConstrainedMax, CORE.frame.side.spacing.endBay.max), 1,
            true,
//            OptionHelper.numericUpDown("sides.baySpacing.specialRightSize","Special REW Bay", impactTypes.structureBaySpacing, "ft.", CORE.frame.side.spacing.endBay.min, Math.min(specialRightBayConstrainedMax, CORE.frame.side.spacing.endBay.max), 1,
            ()=>{
                if(this.frameDesign.sides.baySpacing.specialRightSize)
                    return this.frameDesign.sides.baySpacing.specialRightSize;
                return "";
            },
            undefined,
            (v)=>{
                if(v)
                    this.frameDesign.sides.baySpacing.specialRightSize = v;
                else
                    this.frameDesign.sides.baySpacing.specialRightSize = null;
            }),

            OptionHelper.numericUpDown("sides.baySpacing.maxSize","Max. SW Bay", impactTypes.structureBaySpacing, "ft.", CORE.frame.side.spacing.equalBay.min, CORE.frame.side.spacing.equalBay.max, 1,
            true,
//            OptionHelper.numericUpDown("sides.baySpacing.maxSize","Max. SW Bay Spacing", impactTypes.structureBaySpacing, "ft.", CORE.frame.side.spacing.equalBay.min, CORE.frame.side.spacing.equalBay.max, 1,
            ()=>{
                return this.frameDesign.sides.baySpacing.maxSize;
            },
            undefined,
            (v)=>{
                this.frameDesign.sides.baySpacing.maxSize = v;                
            }
            ),
            
            OptionHelper.header('Girts'),

            OptionHelper.selection("girts.setLocations","Set Locations", impactTypes.structureGirting, "ft.", [
                {
                    value: CORE.girts.four,
                    text: `4`
                },
                {
                    value: CORE.girts.seven,
                    text:`7.33`
                },
                {
                    value: CORE.girts.fourAndSeven,
                    text: `4, 7.33'`
                }
            ],
            true,
            undefined,
            undefined,
            /*
            (v)=>{
                switch(v){
                    case CORE.girts.four:
                        return [4]
                    case CORE.girts.seven:
                        return [7];
                    case CORE.girts.fourAndSeven:
                        return [4, 7.3333];
                }
            },*/
            undefined,
            ),
            OptionHelper.numericUpDown("girts.maxSpacing","Max. Spacing", impactTypes.structureGirting, "in.", 36, 60, 3, true),
//            OptionHelper.numericUpDown("girts.maxSpacing","Max. Spacing", impactTypes.structureGirting, "in.", 36, 60, 3),
            

/*
            OptionHelper.header('Left End'),
            OptionHelper.switch("leftEnd.baySpacing.mode", "Bay Spacing Mode", "Auto.", "Manual", impactTypes.structureBaySpacing,
            undefined, // colors
            undefined, // colors
            ()=>{ //fnEnabled                
                //EW coplanar with frame end 
                //if(this.leftWallDesign.frameLine===0)
                    // porch => false => disabled
                    // no porch => true => enabled
                    return !hasLeftEndPorch;
                //else
                    // force disabled
                    //return false;
            },
            ()=>{ //fnIn
                return this.design.leftEnd.baySpacing.mode === CORE.modes.baySpacing.manual
            },
            (v)=>{ //fnOut                
                //EW coplanar with frame end 
                //if(this.leftWallDesign.frameLine===0)
                    // use the user value
                    return v?CORE.modes.baySpacing.manual:CORE.modes.baySpacing.auto;
                //else
                    // force to manual
                    //return CORE.modes.baySpacing.manual;
                
            },
            (v)=>{ //fnChanged
                this.design.leftEnd.baySpacing.mode = v;//?CORE.modes.baySpacing.auto:CORE.modes.baySpacing.manual;
            }),
            OptionHelper.numericUpDown("leftEnd.baySpacing.center", "Center Spacing", impactTypes.structureBaySpacing, "ft.", 0, 27, 1,true),
            OptionHelper.numericUpDown("leftEnd.baySpacing.nonCenter", "Max. Spacing", impactTypes.structureBaySpacing, "ft.", 5, 27, 1,
            true,
//            OptionHelper.numericUpDown("leftEnd.baySpacing.center", "Center Spacing", impactTypes.structureBaySpacing, "ft.", 0, 27, 1),
//            OptionHelper.numericUpDown("leftEnd.baySpacing.nonCenter", "Max. Spacing", impactTypes.structureBaySpacing, "ft.", 5, 27, 1,
            undefined,
            (v)=>{ // fnOut
                if(v<5)
                    v=5;
                if(v>27)
                    v=27;
                return v;
            }),

            //leftEndOpenBays,

            leftEndMainFrame,
            this.getOption_SkirtLeftEnd(),
            */
            /*
            OptionHelper.header('Right End'),

            OptionHelper.switch("rightEnd.baySpacing.mode", "Bay Spacing Mode", "Auto.", "Manual", impactTypes.structureBaySpacing, 
            undefined, // colors
            undefined, // colors
            ()=>{ //fnEnabled                
                //EW coplanar with frame end 
                //if(this.rightWallDesign.frameLine===0) 
                    // porch => false => disabled
                    // no porch => true => enabled
                    return !hasRightEndPorch;
                //else
                    // force disabled
                    //return false;
            },
            ()=>{ //fnIn
                return this.design.rightEnd.baySpacing.mode === CORE.modes.baySpacing.manual
            },
            (v)=>{ //fnOut                
                //EW coplanar with frame end 
                //if(this.rightWallDesign.frameLine===0)
                    // use the user value
                    return v?CORE.modes.baySpacing.manual:CORE.modes.baySpacing.auto;
                //else
                    // force to manual
                    //return CORE.modes.baySpacing.manual;
            },
            (v)=>{ //fnChanged
                this.design.rightEnd.baySpacing.mode = v;//?CORE.modes.baySpacing.auto:CORE.modes.baySpacing.manual;
            }),
            OptionHelper.numericUpDown("rightEnd.baySpacing.center", "Center Spacing", impactTypes.structureBaySpacing, "ft.", 0, 27, 1,
            true,
//            OptionHelper.numericUpDown("rightEnd.baySpacing.center", "Center Spacing", impactTypes.structureBaySpacing, "ft.", 0, 27, 1,
            undefined,
            undefined,
            (v)=>{
                this.design.rightEnd.baySpacing.center=v;
                console.log(this.design.rightEnd.baySpacing.center);
            }),
            OptionHelper.numericUpDown("rightEnd.baySpacing.nonCenter", "Max. Spacing", impactTypes.structureBaySpacing, "ft.", 5, 27, 1,
            true,
//            OptionHelper.numericUpDown("rightEnd.baySpacing.nonCenter", "Max. Spacing", impactTypes.structureBaySpacing, "ft.", 5, 27, 1,
            undefined,
            (v)=>{ // fnOut
                if(v<5)
                    v=5;
                if(v>27)
                    v=27;
                return v;                
            }),

            //rightEndOpenBays,

            rightEndMainFrame,
            this.getOption_SkirtRightEnd(),
            */
            ...this.getOption_Wainscoting()
            
        ];

        //console.table(opts);

        return opts;
    }
    
    updateBayHeights(delta){
        let bayDesigns = [];
        bayDesigns.push(...this.design.getLeftEndBays(true));
        bayDesigns.push(...this.design.getRightEndBays(true));
        bayDesigns.push(...this.design.getFrontSideBays(true));
        bayDesigns.push(...this.design.getBackSideBays(true));

        bayDesigns.forEach((d)=>{
            d.height+=delta;
        })
    }

    getWidthMinimum()
    {
        return 20; // ft
    }
    applyChangeWidth(newWidthInches){        

        if(this.roofDesign.roofType == CORE.roof.types.slope){
            let delta = newWidthInches - this.frameDesign.width*12;

            const bswDelta = BlueprintHelper.pitchHeight(delta, BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch), CORE.roof.types.slope);;//BlueprintHelper.getBackSideWallHeightDeltaInches(this.getStructureWidthInches(), this.roofDesign.pitch, this.roofDesign.roofType);
            this.backWallDesign.height +=bswDelta;//BlueprintHelper.pitchHeight(delta, BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch), CORE.roof.types.slope);
            let bswBays = this.backWallDesign.getBackSideBays();
                bswBays.forEach((b)=>{
                    b.height+=bswDelta;
                    
                })
        }
        this.frameDesign.width = newWidthInches/12;        
        this.wallBack.maxHeight = this.getBackSideWallHeightInches();
    }


    rebuildName(){
        MemHelper.removeAllChildren(this.groupName);
        buildNameLabel();        
    }

    rebuildFull(){
        this.remove(true,true,true);
        this.initBlueprints();
        this.build();    
    }

    rebuildMove(){
        let rotationY = this.design.rotRad;
        this.group.rotation.y = rotationY;
        let foundationHeight = this.design.getFoundation().height;
        let pos2 = new Vector3(this.design.pos.x,0,this.design.pos.z);
        this.group.position.copy(pos2);

        let frameDesign = this.design.getFrame();
        //this.groupMetal.position.set(-frameDesign.length*12/2,foundationHeight,-frameDesign.width*12/2);
    }

    rebuildHeight(){
        // remove walls
        this.removeWalls();

        // remove frameSides
        this.removeFrameSides();

        // remove frame
        this.removeFrameBase();

        // remove downspouts
        this.removeDownspouts();
        
        // build it all back

    }

    processRebuild(type){
        switch(type){
            /*
            // it's not quite worth it just yet to make the structure rebuildable. Soon though! 2021.11.12
            case rebuildTypes.height:
                // move roof up                
                this.rebuildHeight();
                break;*/
            case rebuildTypes.roof:
                this.rebuildRoofAndGutter();
                break;
            case rebuildTypes.full:
                this.rebuildFull();
                break;
            case rebuildTypes.move:
                this.rebuildMove();
                break;
            default:
                this.rebuildFull();
                break;
        }

        /*
I need to think about the imapctTypes I've defined so far, and the rebuildTypes the structure should support
A window only needs full.
A wall only needs full or openings.
A structure's rebuiltTypes are changes in dimension (LWH), shape (pitch/style), frameline positions, frameLine style 


While the foundation could listen for structure length changes, there's really no need. The foundation changes only when a lot of other things change.
independently, the foundation can change on it's own (dirt, asphault) and rebuild internally with out the structure doing anything.

            Parts
            roof, ends walls, side walls, frameline positions, framelines, foundation, eaveTrim, downspouts, frameSide,


            What's not rebuilt:
            Length
                -end Walls
            Height
                -roof moved
                -eaveTrim moved                
                -foundation untouched
            Width
                -frameLine positions
            
            pitch (gable)
                - side Walls 
                - foundation

            pitch (slope)
                - front side wall (eaveTrim, downspouts)
                - frameSide
                - foundation
                - frameline positions
                - framelines
            
            baySpacing
                - frameSides
                - walls,
                - roof


            What may impact child components / 3d objects

            roof
                - length, width, pitch, options
            
            end walls
                - width, height, pitch, wainscoting,
            
            front side wall
                - 

            
            



        */



    }
   

    getDescription(){        
        return "Main Structure"
    }

    getDeleteImpactType(){
        return impactTypes.deleteStructure;
    }

   
    getAbsRotationCorrectionQuaternion(){
        // returns a quaternion representing the rotation necessary to correct for this component's absolute rotation
        let correction = this.getDirRotation();
        const qCorrection = new THREE.Quaternion();
        qCorrection.setFromAxisAngle( new THREE.Vector3( 0, 1, 0 ), -correction );
        return qCorrection;
    }

    getDirRotation(){
        switch(this.design.side){
            case CORE.sides.rightEnd:
                return Math.PI/2;
            case CORE.sides.frontSide:
                return 0;
            case CORE.sides.backSide:
                return -Math.PI;
            case CORE.sides.leftEnd:
                return -Math.PI/2;
        }
        return 0;
    }

    buildComponents(){
        this.components.map((c)=>{c.masterDesign = this.masterDesign});
    }

    getRotationInputEnabled()
    {
        return this.isRotationInputEnabled;
    }

    buildFoundation(){
        //let  addRotationGrabject = this.getRotationInputEnabled();
        let foundationDim = new Vector3(this.structureLengthInches(), 0, this.structureWidthInches());
        this.foundation =  new Foundation(this.design.getFoundation(), foundationDim);
        //let foundationPos = new Vector3().copy(foundationDim);
        
        this.gStatic.add(this.foundation.group);
    }

    createObjectAtPositionXZ(x,z){
        let o  = new THREE.Object3D();
        o.position.set(x,0,z);
        return o;
    }

    buildCornerReferences(){
        
        this.cornerBL = this.createObjectAtPositionXZ(-this.frameDesign.length*12/2,-this.frameDesign.width*12/2)
        this.cornerBR = this.createObjectAtPositionXZ(this.frameDesign.length*12/2,-this.frameDesign.width*12/2)
        this.cornerFL = this.createObjectAtPositionXZ(-this.frameDesign.length*12/2,this.frameDesign.width*12/2)
        this.cornerFR = this.createObjectAtPositionXZ(this.frameDesign.length*12/2,this.frameDesign.width*12/2)

        this.gStatic.add(this.cornerBL);
        this.gStatic.add(this.cornerBR);
        this.gStatic.add(this.cornerFL);
        this.gStatic.add(this.cornerFR);
    }
    
    setPosition(design){
        let rotationY = design.rotRad ? design.rotRad : 0;
        let pos2 = design.pos? new Vector3(design.pos.x,0,design.pos.z) : new Vector3();
        this.group.position.copy(pos2);
        this.group.rotation.y = rotationY;

        //this.specificInit();
        this.addRebuildNeeded(rebuildTypes.full)
    }

    
    getBeamColor(){
        return this.optionDesign && this.optionDesign.galvBeams ? CORE.frame.galvanizedColor : CORE.frame.redOxideColor;
    }

    getPurlinColor(){
        return this.optionDesign && this.optionDesign.galvPurlins ? CORE.frame.galvanizedColor : CORE.frame.redOxideColor;
    }

    buildDynamicOnly(){
        this.initDynamicGroup();

        let leftEndWallDesignChanged = (des)=>{
            if(des.openWall && this.design.leftEnd.mainFrame===false )
                this.design.leftEnd.mainFrame=true;
        }
        let rightEndWallDesignChanged = (des)=>{
            if(des.openWall && this.design.rightEnd.mainFrame===false )
                this.design.rightEnd.mainFrame=true;
        }

        // list of frame line indices that have a porch tied into them on front or back side
        let leanToFrameLineIndices = this.getLeanToFrameLines(this.design)

        
        let trimMaterials = this.initTrimMaterials();

        ///////////////////
        /* WALLS *///
        /////////////////
        //this.frontWallDesign.pseudoComponents = [];
        this.buildShedOpenings_All();

        let insulation = this.optionDesign.insulation.wall != CORE.insulation.standard.none.value;
        let beamColor = this.getBeamColor();
        let purlinColor = this.getPurlinColor();
        // create the four walls
        // walls get built first so that end frameSides can use the column locations calculated by endWalls, when wall and frameSide are coplanar.
        // if not coplanar, then the frameSide can revert to calculating column locations per default end bay spacing
        let wallLeftPos;
        let leftWallPosX;
        if(this.leftWallDesign)
        {   
            //leftWallPosX = BlueprintHelper.getFramelinePositionXByIndex(this.getStructureLengthInches()/12, this.design.frameLines, this.leftWallDesign.frameLine, this.design.isPorch);
            //let halfColumnDepth = BlueprintHelper.getStandardFrameMaterialSize().depth/2;
            //leftWallPosX -= halfColumnDepth;
            leftWallPosX = BuildLogic.GetLeftEndWallPosX( this.getStructureLengthInches());
            wallLeftPos = new Vector3(leftWallPosX, 0, -this.getStructureWidthInches()/2);
            // The wall determines the location of columns based on it's subcomponents. 
            // The type of columns it should instantiate depends on the presence of a left porch if the wall is co-planar with the left-most frame line
            //this.buildLeftEndWall(porchFrameLineIndices, wallLeftPos, trimMaterials, leftEndWallDesignChanged, insulation, beamColor, purlinColor);
            this.buildNewLew(leanToFrameLineIndices, trimMaterials, leftEndWallDesignChanged, insulation, beamColor, purlinColor);
        
        }

        let wallRightPos;
        let rightWallPosX;
        if(this.rightWallDesign)
        {  
            rightWallPosX = BuildLogic.GetRightEndWallPosX(this.getStructureLengthInches());
            // inset walls needs to be considered here
            wallRightPos = new Vector3(rightWallPosX, 0, -this.getStructureWidthInches()/2);
            //this.buildRightEndWall(porchFrameLineIndices, wallRightPos, trimMaterials, rightEndWallDesignChanged, insulation, beamColor, purlinColor);
            this.buildNewRew(leanToFrameLineIndices, trimMaterials, rightEndWallDesignChanged, insulation, beamColor, purlinColor);
            
        }

        this.buildTransversePartitions()     

        let wallSideLength =this.getSideWallLength();

      //this.buildFrontSideWall(trimMaterials, this.frame, wallSideLength, insulation, beamColor, purlinColor);

        this.buildNewFSW(trimMaterials, this.frame, insulation, beamColor, purlinColor);

        //this.buildBackSideWall(trimMaterials, this.frame, wallSideLength, insulation, beamColor, purlinColor);
      
        this.buildNewBSW(trimMaterials, this.frame, insulation, beamColor, purlinColor);
      

      this.buildAndOrientCornerTrim(leftWallPosX, rightWallPosX, trimMaterials);
      //this.buildCornerTrim(leftWallPosX, rightWallPosX,trimMaterials)
    }

    
    create2dLabelAtPosition(text,pos, id){
        let existing = document.getElementById(id)
        if(existing)
            existing.remove();

        let labelDiv = document.createElement('div');   
        labelDiv.id = id     
        labelDiv.textContent = text;
        labelDiv.className = 'label3d';
        let label2d = new CSS2DObject(labelDiv);            
        label2d.position.copy(pos.clone());
        return label2d;
    }
    
    buildSideLabels(){
        let factor = .8;

        let bswLabelText = this.getBackSideLabelText();
        let fswLabelText = this.getFrontSideLabelText();

        let labelFront = this.create2dLabelAtPosition(
            fswLabelText,
            new Vector3(0, 300, factor*this.getStructureWidthInches()/2),
            this.design.id + 'fsw'
            );
        labelFront.layers.set(CORE.layers.constraint);
        this.groupMetal.add(labelFront);

        

        let labelBack = this.create2dLabelAtPosition(bswLabelText,new Vector3(0, 300, factor*-this.getStructureWidthInches()/2),
        this.design.id + 'bsw');
        labelBack.layers.set(CORE.layers.constraint);
        this.groupMetal.add(labelBack);

        let labelLeft = this.create2dLabelAtPosition("LEW",new Vector3(factor*-this.getStructureLengthInches()/2, 300, 0),
        this.design.id + 'lew')
        labelLeft.layers.set(CORE.layers.constraint);
        this.groupMetal.add(labelLeft);

        let labelRight = this.create2dLabelAtPosition("REW",new Vector3(factor*this.getStructureLengthInches()/2, 300, 0),
        this.design.id + 'rew');
        labelRight.layers.set(CORE.layers.constraint);
        this.groupMetal.add(labelRight);
    }

    buildCornerTrim(leftWallPos, rightWallPos,trimMaterials){
        
        let cornerMargin =.5;
        if (this.frontWallDesign && (typeof this.frontWallDesign.openWall === 'undefined' || this.frontWallDesign.openWall === false)) {
            let trimFlHeight = this.getTallerTrimHeight(this.frontWallDesign, this.leftWallDesign);
            this.trimFL = new _3dTrimCorner(trimFlHeight, trimMaterials.corner);
            this.groupMetal.add(this.trimFL.group);
            layerHelper.enableLayer(this.trimFL.group, CORE.layers.quote);
            this.trimFL.group.position.set(leftWallPos - cornerMargin, this.getFrontSideWallHeightInches() - trimFlHeight, this.structureWidthInches()/2+ cornerMargin);
            this.trimFL.group.rotation.y = 0;
        }

        if (this.backWallDesign && (typeof this.backWallDesign.openWall === 'undefined' || this.backWallDesign.openWall === false)) {
            let trimBlHeight = this.getTallerTrimHeight(this.backWallDesign, this.leftWallDesign);
            if (this.roofDesign.roofType === CORE.roof.types.slope)
                trimBlHeight += this.GetPitchHeight()
            this.trimBL = new _3dTrimCorner(trimBlHeight, trimMaterials.corner);
            this.groupMetal.add(this.trimBL.group);
            layerHelper.enableLayer(this.trimBL.group, CORE.layers.quote);
            this.trimBL.group.position.set(leftWallPos - cornerMargin, this.getBackSideWallHeightInches() - trimBlHeight, -this.structureWidthInches()/2 - cornerMargin);
            this.trimBL.group.rotation.y = 3 * Math.PI / 2;
        }

        
        if (this.frontWallDesign && (typeof this.frontWallDesign.openWall === 'undefined' || this.frontWallDesign.openWall === false)) {
            let trimFrHeight = this.getTallerTrimHeight(this.frontWallDesign, this.rightWallDesign);
            this.trimFR = new _3dTrimCorner(trimFrHeight, trimMaterials.corner);
            this.groupMetal.add(this.trimFR.group);
            layerHelper.enableLayer(this.trimFR.group, CORE.layers.quote);
            this.trimFR.group.position.set(rightWallPos + cornerMargin, this.getFrontSideWallHeightInches() - trimFrHeight, this.structureWidthInches()/2+ cornerMargin);
            this.trimFR.group.rotation.y = Math.PI / 2;
        }

        if (this.backWallDesign && (typeof this.backWallDesign.openWall === 'undefined' || this.backWallDesign.openWall === false)) {
            let trimBrHeight = this.getTallerTrimHeight(this.backWallDesign, this.rightWallDesign);
            if (this.roofDesign.roofType === CORE.roof.types.slope)
                trimBrHeight += this.GetPitchHeight();
            this.trimBR = new _3dTrimCorner(trimBrHeight, trimMaterials.corner);
            this.groupMetal.add(this.trimBR.group);
            layerHelper.enableLayer(this.trimBR.group, CORE.layers.quote);
            this.trimBR.group.position.set(rightWallPos + cornerMargin, this.getBackSideWallHeightInches() - trimBrHeight, -this.structureWidthInches()/2 - cornerMargin);
            this.trimBR.group.rotation.y = Math.PI;
        }
    }

    buildAndOrientCornerTrim(leftWallPos, rightWallPos,trimMaterials, cornerDetails){
        if(Util.isUndefined(cornerDetails))
            return; 

        let cornerMargin = 0.5;

        if (this.frontWallDesign && (typeof this.frontWallDesign.openWall === 'undefined' || this.frontWallDesign.openWall === false)) {
            let trimFLY = this.getFrontSideWallHeightInches();
            for(const corner of cornerDetails[CORE.corners.FL]){
                let flRotation = StructureHelper.getCornerTrimRotation(corner.orientation, CORE.corners.FL);
                if(flRotation !== null){
                    let trimFlHeight = corner.height; 
                    trimFLY = trimFLY - trimFlHeight;
                    this.trimFL = new _3dTrimCorner(trimFlHeight, trimMaterials.corner);
                    this.groupMetal.add(this.trimFL.group);
                    layerHelper.enableLayer(this.trimFL.group, CORE.layers.quote);
                    this.trimFL.group.position.set(leftWallPos - cornerMargin, trimFLY, this.structureWidthInches()/2 + cornerMargin);
                    this.trimFL.group.rotation.y = flRotation;
                }
            }
            
        }

        if (this.backWallDesign && (typeof this.backWallDesign.openWall === 'undefined' || this.backWallDesign.openWall === false || this.design.type === CORE.components.leanTo)) {
            let trimBLY = this.getBackSideWallHeightInches();
            for(const corner of cornerDetails[CORE.corners.BL]){
                let blRotation = StructureHelper.getCornerTrimRotation(corner.orientation, CORE.corners.BL);
                if(blRotation !== null){
                    let trimBlHeight = corner.height;
                    trimBLY = trimBLY - trimBlHeight;
                    // if (this.roofDesign.roofType === CORE.roof.types.slope)
                    //     trimBlHeight += this.GetPitchHeight()
                    this.trimBL = new _3dTrimCorner(trimBlHeight, trimMaterials.corner);
                    this.groupMetal.add(this.trimBL.group);
                    layerHelper.enableLayer(this.trimBL.group, CORE.layers.quote);
                    this.trimBL.group.position.set(leftWallPos - cornerMargin, trimBLY, -this.structureWidthInches()/2 - cornerMargin);
                    this.trimBL.group.rotation.y = blRotation;
                }
            }
        }

        if (this.backWallDesign && (typeof this.backWallDesign.openWall === 'undefined' || this.backWallDesign.openWall === false || this.design.type === CORE.components.leanTo)) {
            let trimBRY = this.getBackSideWallHeightInches();
            for(const corner of cornerDetails[CORE.corners.BR]){
                let brRotation = StructureHelper.getCornerTrimRotation(corner.orientation, CORE.corners.BR);
                if(brRotation !== null){
                    let trimBrHeight = corner.height;
                    trimBRY = trimBRY - trimBrHeight;
                    // if (this.roofDesign.roofType === CORE.roof.types.slope)
                    //     trimBrHeight += this.GetPitchHeight();
                    this.trimBR = new _3dTrimCorner(trimBrHeight, trimMaterials.corner);
                    this.groupMetal.add(this.trimBR.group);
                    layerHelper.enableLayer(this.trimBR.group, CORE.layers.quote);
                    this.trimBR.group.position.set(rightWallPos + cornerMargin, trimBRY, -this.structureWidthInches()/2 - cornerMargin);
                    this.trimBR.group.rotation.y = brRotation;
                }
            }
        }

        if (this.frontWallDesign && (typeof this.frontWallDesign.openWall === 'undefined' || this.frontWallDesign.openWall === false)) {
            let trimFRY = this.getFrontSideWallHeightInches();
            for(const corner of cornerDetails[CORE.corners.FR]){
                let frRotation = StructureHelper.getCornerTrimRotation(corner.orientation, CORE.corners.FR);
                if(frRotation !== null){
                    let trimFrHeight = corner.height;
                    trimFRY = trimFRY - trimFrHeight;
                    this.trimFR = new _3dTrimCorner(trimFrHeight, trimMaterials.corner);
                    this.groupMetal.add(this.trimFR.group);
                    layerHelper.enableLayer(this.trimFR.group, CORE.layers.quote);
                    this.trimFR.group.position.set(rightWallPos + cornerMargin, trimFRY, this.structureWidthInches()/2+ cornerMargin);
                    this.trimFR.group.rotation.y = frRotation;
                }
            }
        }
    }

    buildStaticPart1(){
        this.initStaticGroup();

        this.groupMetal = new THREE.Group();       
        let foundationHeight = this.design.getFoundation().height;
        let pos2 = new Vector3(0,foundationHeight,0);
        this.groupMetal.position.copy(pos2);

        this.gStatic.add(this.groupMetal);
        this.groupMetal.name = "CompGroup OnFoundation";        // component group
        let trimMaterials = this.initTrimMaterials();

        // list of frame line indices that have a porch tied into them on front or back side
        let leanToFrameLineIndices = this.getLeanToFrameLines(this.design)
        
        this.buildFoundation();        

        
        this.buildFrame(leanToFrameLineIndices, trimMaterials);

        this.buildCornerReferences();

        this.buildDistanceMarkers();    
        
        this.setGirtLocations();

        
    }

    buildStaticPart2(){

        this.appearance = new Appearance(this.appearanceDesign);

        this.options = new Options(this.optionDesign, this.model);

        this.jobSpecs = new JobSpecs(this.jobSpecsDesign);

        ///////////////////
        /* GUTTERS *///
        /////////////////
        /*
        Gutters and Eave Trim are intimately connected, as porches affect both, and in different ways.
        Logical Rules:
        if the porch is at the top of the side wall, the eave trim is interrupted 
        If the porch is at the top of the side wall, the tie-in framelines get no gutter at all 
        If a porch is not at the top of the side wall, the tie-in framelines get short gutters above it
        
        // no eave, left eave only, right eave only, full length eave, or both left eave and right eave
        // view of the front side:
                
                //   =====_____=====   eave/porch/eave
                //   |    |   |    |   framelines
        */ 

        let trimMaterials = this.initTrimMaterials();
        let {frontEaveSegments, frontDownspouts, backEaveSegments, backDownspouts} = this.buildGutterTrim(this.frame, trimMaterials);
        
        
        // create the frameSides, attributing the direction of the structure (0 = front) to the direction each frame faces
        // frontSide and backSide column locations are already calculated and static (post blueprints) and can just be used
        // leftSide and rightSide column locations need to be accepted and used if necessary

        // front and back side must have identical frameline column position
        let frontAndBackColumnPositions = this.design.frameLines;
        
        
        // left and right ends do not have to have identical columns because
        // A) there are no length-wise framelines and wall subcomponents affect column locations
        // ALSO,
        // the left and right end walls might be inset and not even be at the left and right end of the frame.
        // When the left wall not inset, the frameBase builds the front and back column of the left end wall,
        // and the wall builds the intermediate columns dynamically.        
        
        // the frame has front/back columns on the left end of the frame and may have intermediate columns
        
        // FrameBase: always builds front-most and back-most column
        // Wall: always builds intermediate wall columns
        // frameLine: builds end columns when wall inset and end is not-skirted.
        // endSkirt: builds end frame columns when wall is inset and end is skirted (requires wall be inset)

        // condition > intermediate column builder 
        // wall at end > wall builds intermediate columns        
        // wall inset & end !skirted > frameline builds intermediate columns
        // wall inset & end skirted > end skirt builds intermediate columns
        


        // if we know all contrainee structures right here, 
        // we could find where they will be and add soldier columns where needed.
        //

        let leftEndFrameLine = this.frame.getFrameLineLeftEnd();
        if(!leftEndFrameLine)
            console.error('Porch problem: no left end frame line');//

        // start with the outer most column positions
        let leftColumnPositions = Array.from(leftEndFrameLine.columnPositions);
        leftColumnPositions = leftColumnPositions.map( (x)=>{
            return x + this.getStructureWidthInches()/2;
        })
        // if left wall is not inset, use left wall's design's columns (dynamic intermediate columns)
        if(this.leftWallDesign && // if the wall exists, and
            this.leftWallDesign.frameLine === 0  && // the wall is not inset
            // and the wall is not open
            (typeof this.leftWallDesign.openWall === 'undefined' || this.leftWallDesign.openWall===false)){
            // add the columns the wall determined necessary
            this.wallLeft.design.columnPositions.forEach((x)=>{
                // only include structural columns
                //let xPos = x.pos - this.getStructureWidthInches()/2;
                // only include unique positions                
                if(x.structural && !leftColumnPositions.includes(x.pos))
                    leftColumnPositions.push(x.pos);
            });
            // since we're mixing, we need to sort the list
            leftColumnPositions=leftColumnPositions.sort((a,b)=>{return a-b});
            // this.leftFrameSideDesign.baySpacing={
            //     // there's no meaning to this value, except to communicate that the  frame should not attempt to create any columns on its own
            //     mode: 'inherit' // columns are inherited from wall, whose mode depends on presence of a porch
            // }
        }
        else
        {
            // the frame side can add it's own columns
            // this.leftFrameSideDesign.baySpacing={
            //     mode: CORE.modes.baySpacing.manual,
            //     center: this.design.leftEnd.baySpacing.center,
            //     nonCenter: this.design.leftEnd.baySpacing.nonCenter,
            // }
        }
        this.design.frameLeftEndColumnPositions = leftColumnPositions;
        
        let rightColumnPositions = Array.from(this.frame.getFrameLineRightEnd().columnPositions);
        rightColumnPositions = rightColumnPositions.map( (x)=>{
            return x + this.getStructureWidthInches()/2;
        })
        // if right wall is not inset, use right wall's design's columns
        if(this.rightWallDesign && this.rightWallDesign.frameLine === 0 &&
            (typeof this.rightWallDesign.openWall === 'undefined' || this.rightWallDesign.openWall===false)){ 
            this.wallRight.design.columnPositions.forEach((x)=>{
                // only include structural columns
                // only include unique positions
                if(x.structural && !rightColumnPositions.includes(x.pos))
                    rightColumnPositions.push(x.pos);
            });
            // since we're mixing, we need to sort the list
            rightColumnPositions=rightColumnPositions.sort((a,b)=>{return a-b});
            // this.rightFrameSideDesign.baySpacing={
            //     // there's no meaning to this value, except to communicate that the  frame should not attempt to create any columns on its own
            //     mode: 'inherit' // columns are inherited from wall, whose mode depends on presence of a porch
            // }
        }
        else
        {            
            // this.rightFrameSideDesign.baySpacing={
            //     mode: CORE.modes.baySpacing.manual,
            //     center: this.design.rightEnd.baySpacing.center,
            //     nonCenter: this.design.rightEnd.baySpacing.nonCenter,
            // }
        }

        this.design.frameRightEndColumnPositions = rightColumnPositions;
        
        this.buildSoldierColumns_All(frontAndBackColumnPositions, leftColumnPositions, rightColumnPositions);
        
        //this.buildFrameSides(frontAndBackColumnPositions, leftColumnPositions, rightColumnPositions);

        let constraint = ConstraintHelper.getConstraintWithChildId(this.masterDesign.constraintRootNode, this.design.id);
        if(Util.isDefined(constraint) && constraint.parent.structureID !== 0)
            this.buildTransitionTrim(constraint, trimMaterials.eaveAndRake)

        this.buildRoof( trimMaterials, frontEaveSegments, frontDownspouts, backEaveSegments, backDownspouts);

        this.buildEndSkirts(frontAndBackColumnPositions, trimMaterials, this.endGirtHeights, this.frontGirtHeights, this.backGirtHeights, ColorHelper.to3(this.appearanceDesign.wall));

        this.buildConstraintMarker();
    }

    buildShedOpenings_All(){
        if(this.design.type !== CORE.components.structure)
            return;
      
    }

    

    getFrontWallOpeningForStructures(c){
        let specs = this.getMatingSpecs(c);
        if(!specs)
            return;
        //       target       reference
        const {constrainerX, constraineeX, constraineeMatingWidth} = specs;
        
        // get the correct height of the opening
        let referenceConstraint =  (c.parent.structureID === this.design.id) ? c.child : c.parent
        let referenceStructure = this.masterDesign.getComponentDesignById(referenceConstraint.structureID);
        let openingHeight;
        if(referenceStructure.type === CORE.components.structure){
            let refereneceHeight;
            if(referenceConstraint.matingSide === CORE.sides.backSide)
                refereneceHeight = BlueprintHelper.getBackSideWallHeightInches(referenceStructure.getRoof(), referenceStructure.getFrame());
            else
                refereneceHeight = referenceStructure.getFrame().height*12;

            // we can't make the opening taller than the structure, so just stick with the eave height of "this" 
            if(refereneceHeight > this.lowEaveHeightInches())
                openingHeight = this.lowEaveHeightInches();
            else
                openingHeight = refereneceHeight;     
        }
        else if(referenceStructure.type === CORE.components.leanTo){
            openingHeight = this.lowEaveHeightInches() - referenceStructure.dim.heightOffset;
        }
            
        // calc the start position of the reference structure in terms of this target structure
        let start = constrainerX - constraineeX;
        // calc the end position of the reference structure in terms of this target structure
        let end = start + constraineeMatingWidth; 
        let height = openingHeight;
        height-=4;  
        start+=4;
        end-=4;
        let length = (end-start);
        return this.getShedOpening(start, length, height);
    }

    getBackWallOpeningForStructures(c){
        let specs = this.getMatingSpecs(c);
        if(!specs)
            return
        const {constrainerX, constraineeX, constraineeMatingWidth} = specs;

        // get "this" part of the constraint
        let targetConstraint = (c.parent.structureID === this.design.id) ? c.parent : c.child;
        let targetStructure = this.masterDesign.getComponentDesignById(targetConstraint.structureID);
        let targetMatingSideHeight = BlueprintHelper.getBackSideWallHeightInches(targetStructure.getRoof(), targetStructure.getFrame())

        // get the correct height of the opening
        let referenceConstraint =  (c.parent.structureID === this.design.id) ? c.child : c.parent
        let referenceStructure = this.masterDesign.getComponentDesignById(referenceConstraint.structureID);
        let openingHeight;
        if(referenceStructure.type === CORE.components.structure){
            let refereneceHeight;
            if(referenceConstraint.matingSide === CORE.sides.backSide) 
                refereneceHeight = BlueprintHelper.getBackSideWallHeightInches(referenceStructure.getRoof(), referenceStructure.getFrame());
            else
                refereneceHeight = referenceStructure.getFrame().height*12;
            // we can't make the opening taller than the structure, so just stick with the eave height of "this" 
            if(refereneceHeight > targetMatingSideHeight)
                openingHeight = targetMatingSideHeight;
            else
                openingHeight = refereneceHeight
        }
        else if(referenceStructure.type === CORE.components.leanTo){
            openingHeight = this.lowEaveHeightInches() - referenceStructure.dim.heightOffset;
        }

        
        // calc the end position of the constrained structure in terms of this constraining structure
        let end = constrainerX;
        // calc the start position of the constrained structure in terms of this constraining structure
        let start = end - constraineeMatingWidth;
        let height = openingHeight;
        height-=4;
        start+=4;
        end-=4;
        
        let length = (end-start);
        return this.getShedOpening(start, length, height);

    }

    getLeftWallOpeningForStructures(c){
       let specs = this.getMatingSpecs(c);
       if(!specs)
        return;
       const {constrainerX, constraineeX, constraineeMatingWidth} = specs;

       // get the correct height of the opening
       let referenceConstraint =  (c.parent.structureID === this.design.id) ? c.child : c.parent
       let referenceStructure = this.masterDesign.getComponentDesignById(referenceConstraint.structureID);
       let openingHeight;
       if(referenceStructure.type === CORE.components.structure){
            let refereneceHeight;
            if(referenceConstraint.matingSide === CORE.sides.backSide)
                refereneceHeight = BlueprintHelper.getBackSideWallHeightInches(referenceStructure.getRoof(), referenceStructure.getFrame());
            else
                refereneceHeight = referenceStructure.getFrame().height*12;
            // we can't make the opening taller than the structure, so just stick with the eave height of "this" 
           if(refereneceHeight > this.lowEaveHeightInches())
               openingHeight = this.lowEaveHeightInches();
           else //refereneceHeight <= this.lowEaveHeightInches()
               openingHeight = refereneceHeight
       }
       else if(referenceStructure.type === CORE.components.leanTo){
           openingHeight = this.lowEaveHeightInches() - referenceStructure.dim.heightOffset;
       }

        // calc the start position of the constrained structure in terms of this constraining structure
        let start = constrainerX - constraineeX;
        // calc the end position of the constrained structure in terms of this constraining structure
        let end = start + constraineeMatingWidth;
        let height = openingHeight;
        height-=4;
        start+=4;
        end-=4;
        
        let length = (end-start);
        return this.getShedOpening(start, length, height);
    }
    
    getRightWallOpeningForStructures(c){
        let specs = this.getMatingSpecs(c);
        if(!specs)
            return;
        const {constrainerX, constraineeX, constraineeMatingWidth} = specs;

        // get the correct height of the opening
        let referenceConstraint =  (c.parent.structureID === this.design.id) ? c.child : c.parent
        let referenceStructure = this.masterDesign.getComponentDesignById(referenceConstraint.structureID);
        let openingHeight;
        if(referenceStructure.type === CORE.components.structure){
            let refereneceHeight;
            if(referenceConstraint.matingSide === CORE.sides.backSide)
                refereneceHeight = BlueprintHelper.getBackSideWallHeightInches(referenceStructure.getRoof(), referenceStructure.getFrame());
            else
                refereneceHeight = referenceStructure.getFrame().height*12;

            // we can't make the opening taller than the structure, so just stick with the eave height of "this" 
            if(refereneceHeight > this.lowEaveHeightInches())
                openingHeight = this.lowEaveHeightInches();
            else
                openingHeight = refereneceHeight
        }
        else if(referenceStructure.type === CORE.components.leanTo){
            openingHeight = this.lowEaveHeightInches() - referenceStructure.dim.heightOffset;
        }

        // calc the start position of the constrained structure in terms of this constraining structure
        let end = constrainerX;
        // calc the end position of the constrained structure in terms of this constraining structure
        let start =end - constraineeMatingWidth;
        let height = openingHeight;
        height-=4;
        start+=4;
        end-=4;
        
        let length = (end-start);
        return this.getShedOpening(start, length, height);
    }

    hasFrontSideLeanTo(){
        return this.hasSecondaryStructureOnSide(CORE.components.leanTo, CORE.sides.frontSide);    
    }
    hasBackSideLeanTo(){
        return this.hasSecondaryStructureOnSide(CORE.components.leanTo, CORE.sides.backSide);    
    }
    hasLeftEndLeanTo(){
        return this.hasSecondaryStructureOnSide(CORE.components.leanTo, CORE.sides.leftEnd);          
    }
    hasRightEndLeanTo(){
        return this.hasSecondaryStructureOnSide(CORE.components.leanTo, CORE.sides.rightEnd);    
    }
    getFrontSideLeanTo(){
        return this.getSecondaryStructureOnSide(CORE.components.leanTo, CORE.sides.frontSide);    
    }
    getBackSideLeanTo(){
        return this.getSecondaryStructureOnSide(CORE.components.leanTo, CORE.sides.backSide);    
    }
    getLeftEndLeanTo(){
        return this.getSecondaryStructureOnSide(CORE.components.leanTo, CORE.sides.leftEnd);    
    }
    getRightEndLeanTo(){
        return this.getSecondaryStructureOnSide(CORE.components.leanTo, CORE.sides.rightEnd);    
    }

    hasFrontSidePorch(){
        return this.hasSecondaryStructureOnSide(CORE.components.porch, CORE.sides.frontSide);    
    }
    hasBackSidePorch(){
        return this.hasSecondaryStructureOnSide(CORE.components.porch, CORE.sides.backSide);    
    }
    hasLeftEndPorch(){
        return this.hasSecondaryStructureOnSide(CORE.components.porch, CORE.sides.leftEnd);          
    }
    hasRightEndPorch(){
        return this.hasSecondaryStructureOnSide(CORE.components.porch, CORE.sides.rightEnd);    
    }
    getFrontSidePorch(){
        return this.getSecondaryStructureOnSide(CORE.components.porch, CORE.sides.frontSide);    
    }
    getBackSidePorch(){
        return this.getSecondaryStructureOnSide(CORE.components.porch, CORE.sides.backSide);    
    }
    getLeftEndPorch(){
        return this.getSecondaryStructureOnSide(CORE.components.porch, CORE.sides.leftEnd);    
    }
    getRightEndPorch(){
        return this.getSecondaryStructureOnSide(CORE.components.porch, CORE.sides.rightEnd);    
    }
    
    hasSecondaryStructureOnSide(secondaryStructureType, side){
        let sideConstraineeConstraints = this.constraineeConstraints.filter(c => {
            return c.parent.matingSide === side;
        })
        
        let found = false;

        sideConstraineeConstraints.forEach((c)=>{
            if(found)
                return;
            let childStructure = this.masterDesign.getComponentDesignById(c.child.structureID);
            if(!childStructure) // it is possible for a porch(shed) delete to trigger vue to update some computed props before the design has had a chance to be updated
                return;
            if(childStructure.type  === secondaryStructureType)
                found = true;            
        })

        return found;
    }

    getSecondaryStructureOnSide(secondaryStructureType, side){
        let sideConstraineeConstraints = this.constraineeConstraints.filter(c => {
            return c.parent.matingSide === side;
        })
        
        let found= null;

        sideConstraineeConstraints.forEach((c)=>{
            if(found)
                return;
            let childStructure = this.masterDesign.getComponentDesignById(c.child.structureID);
            if(!childStructure) // it is possible for a porch(shed) delete to trigger vue to update some computed props before the design has had a chance to be updated
                return;
            if(childStructure.type === secondaryStructureType)
                found = childStructure;
        })

        return found;
    }

    isConstraintGableTieStructure(constraint) {
        if (constraint === null || constraint === undefined || constraint.parent.structureID===0)
            return false;
        
        let tieIn = false

        if (!constraint || !constraint.parent || !constraint.child)
            return false;

        let parent = this.masterDesign.getComponentDesignById(constraint.parent.structureID);
        let child = this.masterDesign.getComponentDesignById(constraint.child.structureID);
        
        if ( !parent || !child)
            return false;

        let pRoof = parent.getRoof();
        let pFrame = parent.getFrame();
        let childRoof = child.getRoof();
        let childFrame = child.getFrame();
        //if (ConstraintHelper.isConstraintValidGableTieIn(constraint, parent, child))
        let parentRidgeHeight = BlueprintHelper.ridgeHeight(pFrame.height, pFrame.width, pRoof.pitch/12, pRoof.roofType)
        let childRidgeHeight = BlueprintHelper.ridgeHeight(childFrame.height, childFrame.width, childRoof.pitch/12, childRoof.roofType)
            
        if(!BlueprintHelper.GableCanTieInSides(constraint.child.matingSide, constraint.parent.matingSide, pRoof.roofType))
            return false;
        if(!BlueprintHelper.GableCanTieInHeight(childFrame.height, childRidgeHeight, pFrame.height, parentRidgeHeight))
            return false;
        if( !SystemHelper.IsChildSideContainedByParentSide(this.masterDesign, constraint))
            return false;

        tieIn = true;
        

        return tieIn;
        
    }
    isGableTieStructure() {

        let constraint = ConstraintHelper.findFirstConstraintNodeWithId(this.masterDesign.constraintRootNode, this.design.id);
        
        if (constraint === null || constraint === undefined || constraint.parentNode.id===0)
            return false;
        
        let tieIn = false

        constraint.parentNode.constraints.forEach(constraint => {
            if (!constraint || !constraint.parent || !constraint.child)
                return false;

            let parent = this.masterDesign.getComponentDesignById(constraint.parent.structureID);
            let child = this.masterDesign.getComponentDesignById(constraint.child.structureID);
            
            if ( !parent || !child)
                return false;

            let pRoof = parent.getRoof();
            let pFrame = parent.getFrame();
            let childRoof = child.getRoof();
            let childFrame = child.getFrame();
            //if (ConstraintHelper.isConstraintValidGableTieIn(constraint, parent, child))
            let parentRidgeHeight = BlueprintHelper.ridgeHeight(pFrame.height, pFrame.width, pRoof.pitchRatio, pRoof.roofType)
            let childRidgeHeight = BlueprintHelper.ridgeHeight(childFrame.height, childFrame.width, childRoof.pitchRatio, childRoof.roofType)
                
            if(!BlueprintHelper.GableCanTieInSides(constraint.child.matingSide, constraint.parent.matingSide, pRoof.roofType))
                return false;
            if(!BlueprintHelper.GableCanTieInHeight(childFrame.height, childRidgeHeight, pFrame.height, parentRidgeHeight))
                return false;
            if( !SystemHelper.IsChildSideContainedByParentSide(this.masterDesign, constraint))
                return false;

            tieIn = true;
        });

        return tieIn;
        
    }



    
    updateConstraineeContraints(){
                                
        this.constraineeConstraints = ConstraintHelper.findConstraintsForNodeWithId(this.masterDesign.constraintRootNode,this.design.id);
    }

    // forEachMainAndLeanToOnSide(side,func){
    //     let sideConstraineeConstraints = this.constraineeConstraints.filter(c => {
    //         return c.parent.matingSide === side;
    //     })
        
    //     sideConstraineeConstraints.forEach((c)=>{
            
    //         let childStructure = this.masterDesign.getComponentDesignById(c.child.structureID);
    //         if(!childStructure){
    //             throw "constraint tree mis-alignment"                
    //         }
    //         if(!childStructure.type === CORE.components.leanTo || !childStructure.type === CORE.components.structure)
    //             return;

    //         //constrainerX is left end location of the constraining (parent) structure, as viewed while facing that wall
    //         //constrainee is left end location of the constrained (child) structure, as viewed while facing that wall
    //         const specs = SystemHelper.getConstraineeMatingSpecs(c,this.frameDesign, this.masterDesign)            
    //         func(childStructure, specs);
            
    //     })
    // }

    getMatingSpecs(c){
        // targetConstraint represents the structure we're poking a hole in.
        // referenceConstraint represents the structure used for the dimensions of the hole.
        let targetConstraint = (c.parent.structureID === this.design.id) ? c.parent : c.child
        let referenceConstraint =  (c.parent.structureID === this.design.id) ? c.child : c.parent
        let referenceStructure = this.masterDesign.getComponentDesignById(referenceConstraint.structureID);
        if(!referenceConstraint){
            throw "constraint tree mis-alignment"                
        }
        if(referenceStructure.type !== CORE.components.porch && referenceStructure.type !== CORE.components.leanTo && referenceStructure.type !== CORE.components.structure)
            return;


        // at this point we know that "this" is not the wall owner,
        // but we do need to know if it is the child or parent
        let specs;
        if(targetConstraint.structureID === c.parent.structureID){
            specs = SystemHelper.getConstraineeMatingSpecs(c, this.frameDesign, this.masterDesign)
        }
        else{
            let desReference = this.masterDesign.getComponentDesignById(referenceConstraint.structureID);
            if(!desReference)
                return; // added purely to allow hot-reload
            // get the design of the frame of the constrained structure
            let desReferenceFrame = desReference.getFrame();
            specs = SystemHelper.getConstrainerMatingSpecs(referenceConstraint, targetConstraint, desReferenceFrame, this.frameDesign)
        }


        //constrainerX is left end location of the target structure, as viewed while facing that wall
        //constrainee is left end location of the reference structure, as viewed while facing that wall
        //SystemHelper.getConstrainerMatingSpecs(targetConstraint, referenceConstraint,this.frameDesign, this.masterDesign)

        return specs;
    }

    buildShedOpening_FrontSide(start,length, height){
        // add a wide opening to the appropriate wall
        // adding the component will mean it is still there next time and would have to be "found"
        // giving the wall information to use without a component isn't really supported.
        // there could be psuedo-components that aren't saved, I suppose.
        // they component would need to be kept in a separate list, yet considered like the others.
        // if we add them to the design, but don't deserialize them, they can't be saved.
        if(!this.frontWallDesign.pseudoComponents)
            this.frontWallDesign.pseudoComponents=[];

        let wideOpening = ComponentHelper.createComponentOfType(CORE.components.wideOpening);
        
        let woDefault = wideOpening.defaultDesign();
        woDefault.pos.x = start + (length/2);
        woDefault.dim.width = length;
        woDefault.dim.height =height;

        //this.frontWallDesign.pseudoComponents.push(woDefault);

    }

    getShedOpening(start,length, height){
        

        return {
            pos:{
                x:start + (length/2),
                y:height/2,
            },
            dim:{
                width: length,
                height: height
            }
        }
        //this.frontWallDesign.pseudoComponents.push(woDefault);

    }
    
    getShedOpening_BackSide(start,length, height){        
        // woDefault.pos.x = start + (length/2);
        // woDefault.dim.width = length;
        // woDefault.dim.height =height;         

        return {
            pos:{
                x:start + (length/2),
                y:height/2,
            },
            dim:{
                width: length,
                height: height
            }
        }
        //this.backWallDesign.pseudoComponents.push(woDefault);
    }
    
    getShedOpening_LeftSide(start,length, height){
        if(!this.leftWallDesign.pseudoComponents)
            this.leftWallDesign.pseudoComponents=[];

        let wideOpening = ComponentHelper.createComponentOfType(CORE.components.wideOpening);
    
        let woDefault = wideOpening.defaultDesign();
        woDefault.pos.x = start + (length/2);
        woDefault.dim.width = length;
        woDefault.dim.height =height;

        //this.leftWallDesign.pseudoComponents.push(woDefault);
    }

    
    getShedOpening_RightSide(start,length, height){
        if(!this.rightWallDesign.pseudoComponents)
            this.rightWallDesign.pseudoComponents=[];

        let wideOpening = ComponentHelper.createComponentOfType(CORE.components.wideOpening);
    
        let woDefault = wideOpening.defaultDesign();
        woDefault.pos.x = start + (length/2);
        woDefault.dim.width = length;
        woDefault.dim.height =height;

        //this.rightWallDesign.pseudoComponents.push(woDefault);
    }


    buildRoof(trimMaterials, frontEaveSegments, frontDownspouts, backEaveSegments, backDownspouts){
        let insulation = {};
        
        Util.merge(insulation, this.optionDesign.insulation);
        insulation.framelines =this.design.frameLines;
        
        if(this.leftWallDesign && this.rightWallDesign)
        {
            insulation.leftWall = this.leftWallDesign.frameLine
            insulation.rightWall = this.frame.frameLines.length - (this.rightWallDesign.frameLine+1)
        }
        else 
        {
            insulation.roof = CORE.insulation.standard.none.value
        }

        this.roofDesign.color = ColorHelper.to3(this.appearanceDesign.roof);

        // if this roof is a gable, 
        
            let leftTieIn;
            let rightTieIn
            let tieIns = [];

            
            let thisBuildingHeight = this.frameDesign.height;
            let thisBuildingRidgeHeight =  BlueprintHelper.ridgeHeight(thisBuildingHeight, this.frameDesign.width, this.pitchRatio, this.roofDesign.roofType)
            let thisBuildingLength = this.frameDesign.length;

            // we have to detect roofs of other buildings that this gable ties into            
            // a tie-in may be the result of a child->parent or parent->child constraint
            //  if this building is a constraint child, then there is a single constraint parent whose design info we need
            let constraint = ConstraintHelper.getConstraintWithChildId(this.masterDesign.constraintRootNode, this.design.id);
            if(constraint)
            {
                // get the constrainer's design info
                let otherBuildingDesign = this.masterDesign.getComponentDesignById(constraint.parent.structureID);
                if(otherBuildingDesign)
                {
                    // get the design of the roof of the constraining structure
                    //----------------------------
                    let thisBuildingMatingSide = constraint.child.matingSide;

                    //----------------------------
                    let otherBuildingRoofDesign = otherBuildingDesign.getRoof();                    
                    
                    let otherBuildingEave = otherBuildingDesign.getFrame().height;
                    let otherBuildingLength = otherBuildingDesign.getFrame().length;
                    let otherBuildingWidth = otherBuildingDesign.getFrame().width;                    
                    let otherBuildingHeight = otherBuildingDesign.getFrame().height
                    let otherBuildingPitch = otherBuildingRoofDesign.pitch
                    let otherBuildingRoofType =otherBuildingRoofDesign.roofType;
                    let otherBuildingPitchRatio = BlueprintHelper.pitchToPitchRatio(otherBuildingPitch)
                    let otherBuildingRidgeHeight = BlueprintHelper.ridgeHeight(otherBuildingHeight, otherBuildingWidth, otherBuildingPitchRatio, otherBuildingRoofType)                   
                    let otherBuildingMatingSide = constraint.parent.matingSide;
                    //----------------------------

                    // end-wall building must have a ridge/high-side between side-wall building eave and ridge
                    // end-wall building must have eave at or below side-wall building eave

                    if(true)
                        {

                    switch(thisBuildingMatingSide){
                        case CORE.sides.leftEnd:
                            if(!BlueprintHelper.GableCanTieInSides(thisBuildingMatingSide, otherBuildingMatingSide, otherBuildingRoofType))
                                break;
                            if(!BlueprintHelper.GableCanTieInHeight(thisBuildingHeight, thisBuildingRidgeHeight,otherBuildingHeight, otherBuildingRidgeHeight))
                                break;
                            if( !SystemHelper.IsChildSideContainedByParentSide(this.masterDesign, constraint))
                                break;
                            if(this.roofDesign.roofType === CORE.roof.types.gable)
                                // then the constrainer is on the left                            
                                leftTieIn = {
                                    pitch: otherBuildingPitch,
                                    eave: otherBuildingEave
                                }
                            break;

                        // if the constrainee (this building) is mated on its right end,
                        case CORE.sides.rightEnd:
                            if(!BlueprintHelper.GableCanTieInSides(thisBuildingMatingSide, otherBuildingMatingSide, otherBuildingRoofType))
                                break;
                            // side-wall building is other building
                            // end-wall building is this building
                            if(!BlueprintHelper.GableCanTieInHeight(thisBuildingHeight, thisBuildingRidgeHeight,otherBuildingHeight, otherBuildingRidgeHeight))
                                break;

                            if(!SystemHelper.IsChildSideContainedByParentSide(this.masterDesign, constraint))
                                break;
                            if(this.roofDesign.roofType === CORE.roof.types.gable)                            
                                // then the constrainer is on the right end of this building
                                rightTieIn = {
                                    pitch: otherBuildingPitch,
                                    eave: otherBuildingEave
                                }
                            break;

                        case CORE.sides.frontSide:
                            if(!BlueprintHelper.GableCanTieInSides(thisBuildingMatingSide, otherBuildingMatingSide, otherBuildingRoofType))
                                break;
                            // side-wall building is this building
                            // end-wall building is other building
                            if(!BlueprintHelper.GableCanTieInHeight(otherBuildingHeight, otherBuildingRidgeHeight, thisBuildingHeight, thisBuildingRidgeHeight))
                                break;

                            // gable and slope both support tie-ins on the front slope
                            // offset length is always from the parent's perspective, but this data is headed to the child
                            let tieIn = this.getTieInHoleInfo(thisBuildingLength, constraint, thisBuildingMatingSide, otherBuildingWidth, otherBuildingPitch, otherBuildingEave);
                            tieIns.push(tieIn)                            
                            break;
                        case CORE.sides.backSide:
                            if(!BlueprintHelper.GableCanTieInSides(thisBuildingMatingSide, otherBuildingMatingSide, otherBuildingRoofType))
                                break;
                            if(!BlueprintHelper.GableCanTieInHeight(otherBuildingHeight, otherBuildingRidgeHeight, thisBuildingHeight, thisBuildingRidgeHeight))
                                break;
                            // gable supports tie-ins on the back slope
                            // single-slope has no back slope, so no tie-ins
                            if(this.roofDesign.roofType === CORE.roof.types.gable)
                            {
                                // offset length is always from the parent's perspective, but this data is headed to the child
                                let tieIn = this.getTieInHoleInfo(thisBuildingLength, constraint, thisBuildingMatingSide, otherBuildingWidth, otherBuildingPitch, otherBuildingEave);
                                tieIns.push(tieIn)
                            }
                            break;
                    }
                }
                }
            }                

            let constrainees = ConstraintHelper.findConstraintsForNodeWithId(this.masterDesign.constraintRootNode, this.design.id)

            if(constrainees){
                constrainees.forEach((c)=>{
                    //----------------------------
                    let thisBuildingMatingSide = c.parent.matingSide;
                    //----------------------------
                    let otherBuildingMatingSide = c.child.matingSide;
                    let otherBuildingDesign = this.masterDesign.getComponentDesignById(c.child.structureID);
                    let otherBuildingRoofType =otherBuildingDesign.getRoof().roofType;
                    let otherBuildingWidth = otherBuildingDesign.getFrame().width
                    let otherBuildingHeight = otherBuildingDesign.getFrame().height
                    let otherBuildingPitch = otherBuildingDesign.getRoof().pitch
                    let otherBuildingPitchRatio = BlueprintHelper.pitchToPitchRatio(otherBuildingPitch)
                    let otherBuildingRidgeHeight = BlueprintHelper.ridgeHeight(otherBuildingHeight, otherBuildingWidth, otherBuildingPitchRatio, otherBuildingRoofType)                   
                    //----------------------------

                    
                    switch(thisBuildingMatingSide)
                    {                        
                        case CORE.sides.leftEnd:
                            
                            if(!SystemHelper.IsParentSideContainedByChildSide(this.masterDesign, c))
                                return;
                            
                            if(!BlueprintHelper.GableCanTieInHeight( thisBuildingHeight, thisBuildingRidgeHeight, otherBuildingHeight, otherBuildingRidgeHeight,))
                                return;
                            
                            leftTieIn = this.getTieInForEnd(otherBuildingMatingSide, otherBuildingRoofType,otherBuildingPitch, otherBuildingHeight);
                            break;
                        case CORE.sides.rightEnd:
                            if(!SystemHelper.IsParentSideContainedByChildSide(this.masterDesign, c))
                                return;
                            if(!BlueprintHelper.GableCanTieInHeight( thisBuildingHeight, thisBuildingRidgeHeight, otherBuildingHeight, otherBuildingRidgeHeight,))
                            return;
                                
                            rightTieIn = this.getTieInForEnd(otherBuildingMatingSide, otherBuildingRoofType,otherBuildingPitch, otherBuildingHeight);
                            break;
                        case CORE.sides.backSide:
                        case CORE.sides.frontSide:
                            
                            if(otherBuildingRoofType !== CORE.roof.types.gable)
                                break;
                            if(!SystemHelper.IsChildSideContainedByParentSide(this.masterDesign, c))
                                return;
                            if(!BlueprintHelper.GableCanTieInHeight( otherBuildingHeight, otherBuildingRidgeHeight,thisBuildingHeight, thisBuildingRidgeHeight,))
                                return;

                            switch(otherBuildingMatingSide)
                            {
                                case CORE.sides.leftEnd:                            
                                case CORE.sides.rightEnd:
                                    let tieIn = {
                                        side: thisBuildingMatingSide,
                                        offset: c.parent.length*12,
                                        width:  otherBuildingWidth*12,
                                        pitch: otherBuildingPitch,
                                        eave: otherBuildingHeight
                                    }
                                    tieIns.push(tieIn);
                                    break;
                            }
                            break;
                    }
                 });
                // tie-ins to another roof (where this does the tieing in)
                // constrainee must be on the left or right end to be considered 
                
                // if a constrainee is sloped and this building is mated to its backSide, skip it.
                // if a constrainee is sloped and this building is mated to its frontSide, then we have a tie-in
                // if a constrainee is gabled, then we have a tie-in


                // tie-ins into this roof (where the constrainee does the gable tie-in)
                // if the constrainee is on the front or back side of this building, and the constrainee is mated on its leftEnd or rightEnd
                // AND the constrainee is a gable, then we have a tie-in

                // move validation for eaves and within the goal-posts

            }
        let panelColor = ColorHelper.to3(this.appearanceDesign.wall);
        let trimColor = ColorHelper.to3(this.appearanceDesign.trim);

        
        let roofExtOptions = this.optionDesign.roofExt;

        let roofConfig = {
            gutters: this.optionDesign.gutters
        }
        if(this.roofDesign.roofType === CORE.roof.types.gable)
            this.roof = new RoofMainGable(
                this.roofDesign, 
                this.structureLengthInches(), 
                this.structureWidthInches(), 
                this.lowEaveHeightInches(), 
                BlueprintHelper.purlinType(this.frameDesign.frameType), 
                this.getStructureScopedConfig(this.appearanceDesign), 
                frontEaveSegments, 
                frontDownspouts, 
                backEaveSegments, 
                backDownspouts, 
                insulation, 
                this.getBeamColor(), 
                this.getPurlinColor(), 
                leftTieIn, 
                rightTieIn, 
                tieIns, 
                panelColor, 
                trimColor,
                 roofExtOptions,
                 this.design.frameLines, 
                 roofConfig)
        else
            this.roof = new RoofMainSlope(
        this.roofDesign, 
        this.structureLengthInches(), 
        this.structureWidthInches(), 
        this.lowEaveHeightInches(), 
        BlueprintHelper.purlinType(this.frameDesign.frameType), 
        trimMaterials, 
        frontEaveSegments,
        frontDownspouts,
        backEaveSegments, 
        false, 
        insulation, 
        this.getPurlinColor(), 
        tieIns,
        roofExtOptions,
        this.design.frameLines, 
        roofConfig)
        this.roof.group.position.set(0, this.getStructureHeightInches(), 0);
        this.groupMetal.add(this.roof.group);
        if(this.components.length>2)
            this.components[2] = this.roof;
        //console.log('Structure Main buildRoof', this.roof.this.group.uuid);
    }

    buildTransitionTrim(c, trimMaterial){
        // If we are in this method, we know there is a constraint and "this" is the child constraint. 
        let desParent = this.masterDesign.getComponentDesignById(c.parent.structureID);
        if(Util.isUndefined(desParent))
            return;

        let isSecondaryStructure = this.design.type === CORE.components.porch || this.design.type === CORE.components.leanTo
        if(isSecondaryStructure && this.design.dim.heightOffset != 0)
            return
        
        //pitch transition trim piece (when heights align, but pitch doesn't)
        let heightAlignmentDetectionEpsilon = 5;
        let heightsAligned = false;
		if(Math.abs(this.frameDesign.height - desParent.getFrame().height) < heightAlignmentDetectionEpsilon)
            heightsAligned = true;
            
        if(heightsAligned && 
            this.roofDesign.pitch != desParent.getRoof().pitch &&            
            (c.child.matingSide === CORE.sides.frontSide || c.child.matingSide === CORE.sides.backSide)){// only front and back side secondary structrures have the potential to need this.

            let transitionStrip = new _3dTrimTransitionStrip(CORE.preferences.des_levelOfDetail, this.group, desParent.getRoof().pitch, this.roofDesign.pitch, this.frameDesign.length*12, this.frameDesign.width*12, desParent.getFrame().height*12, this.getDescription(), trimMaterial)
            layerHelper.enableLayer(transitionStrip.group, CORE.layers.roof);
            this.groupMetal.add(transitionStrip.group);
        }
    }

    getTieInHoleInfo(swBuildingLength, constraint, swBuildingMatingSide, ewBuildingWidth, ewBuildingPitch,ewBuildingEave) {
        let offset = (swBuildingLength - (ewBuildingWidth - constraint.parent.length));
        offset *= 12;

        let tieIn = {
            side: swBuildingMatingSide,
            offset,
            width: ewBuildingWidth * 12,
            pitch: ewBuildingPitch,
            eave: ewBuildingEave
        };
        return tieIn;
    }

    getTieInForEnd(sideOfOtherBuilding, otherBuildingRoofType,parentPitch, parentEave) {
        switch (sideOfOtherBuilding) {
            case CORE.sides.backSide:
                switch (otherBuildingRoofType) {
                    // leftEnd of this building
                    // backSide of other building
                    // gables or single slope
                    case CORE.roof.types.gable:
                        return {
                            pitch: parentPitch,
                            eave: parentEave
                        };
                    case CORE.roof.types.slope:
                        // BackSide of a single-slope has no roof surface to tie into. just a vertical wall.
                        // may need a tie-in type to signal removal of trim on that end!
                        break;
                }
                break;
            case CORE.sides.frontSide:

                switch (otherBuildingRoofType) {
                    // left end of this building
                    // front side of other building
                    // gables or single slope
                    case CORE.roof.types.gable:
                    case CORE.roof.types.slope:
                        return {
                            pitch: parentPitch,
                            eave: parentEave
                        };
                }
                break;
        }
    }

    buildConstraintMarker(){
        //this.constraineeConstraints = ConstraintHelper.findConstraintsForNodeWithId(mDesign.constraintRootNode,this.design.id);
        let constraint = ConstraintHelper.getConstraintWithChildId(this.masterDesign.constraintRootNode, this.design.id);
        if(!constraint)
            return;
        
        if(!constraint.parent.structureID)
            return; // tier 1 constraint nodes are not actually constrained, just all under the NULL root node.
        
        
        // detemine the length of the side/end along which the constrained structure is mated to this constraining structure
        //let constrainerMatingWidth = StructureBase.getConstraintPartMatingWidth(constraint.parent, desConstraineeFrame);
        //let constraineeMatingWidth = StructureBase.getConstraintPartMatingWidth(constraint.child, desConstraineeFrame);

        

        switch(constraint.child.matingSide){
            case CORE.sides.backSide:
                this.buildBackSideConstraintMarker(constraint);                   
                break;
            case CORE.sides.frontSide:                
                this.buildFrontSideConstraintMarker(constraint);                   
                
                break;
            case CORE.sides.leftEnd:
                this.buildLeftEndConstraintMarker(constraint)
                
                break;
            case CORE.sides.rightEnd:
                this.buildRightEndConstraintMarker(constraint)
                break;
        }

        
    }


    getConstraintMarkerDetails(constraint){
        let desConstrainer = this.masterDesign.getComponentDesignById(constraint.parent.structureID);
        if(!desConstrainer)
            return; // added purely to allow hot-reload
        // get the design of the frame of the constrained structure
        let desConstrainerFrame = desConstrainer.getFrame();
        // cr = constrainER
        let lengthOfCr = SystemHelper.getConstraintPartMatingLength(constraint.parent, desConstrainerFrame);
        // ce = constinerEE
        let lengthOfCe = SystemHelper.getConstraintPartMatingLength(constraint.child, this.frameDesign);

        let offsetFromLeftEnd = constraint.parent.length*12;
 
        let crStart = 0
        let crEnd = lengthOfCr;
        let ceStart = offsetFromLeftEnd;
        let ceEnd = ceStart + lengthOfCe;

        let innerStart = crStart;
        if(crStart<ceStart)
            innerStart = ceStart;

        let innerEnd = ceEnd;
        if(crEnd<ceEnd)
            innerEnd = crEnd;

        let matingWidth = innerEnd-innerStart;
        // this x is not absolute, it represents an offset in a totally arbitrary horizontal direction
        let offsetX = crStart - ceStart;
        
        return {offsetX, matingWidth}
       }
    
    buildLeftEndConstraintMarker(constraint){

        let details = this.getConstraintMarkerDetails(constraint);
        let posX;
        if(constraint.parent.length>0){
            posX = this.getStructureWidthInches()/2 - details.matingWidth/2;
        }
        else{
            posX = this.getStructureWidthInches()/2 - details.offsetX - details.matingWidth/2;
        }

        //return {constrainerX, constraineeX, constraineeMatingWidth}
        // draw a constraintMarker on the correct side of this structure
        let tjsColor = ColorHelper.hexToTjs(constraint.color);
        let c = new ConstraintMarker(details.matingWidth, tjsColor);
        c.group.position.y = 500;
        c.group.position.z = posX;
        c.group.position.x-=this.getStructureLengthInches()/2;
        c.group.rotation.y=Math.PI/2;
        this.groupMetal.add(c.group);
    }

    buildRightEndConstraintMarker(constraint){
        let details = this.getConstraintMarkerDetails(constraint);
        let posX;
        if(constraint.parent.length>0){
            posX = -this.getStructureWidthInches()/2 + details.matingWidth/2;
        }
        else{
            posX = -this.getStructureWidthInches()/2 + details.offsetX + details.matingWidth/2;
        }

        //return {constrainerX, constraineeX, constraineeMatingWidth}
        // draw a constraintMarker on the correct side of this structure
        let tjsColor = ColorHelper.hexToTjs(constraint.color);
        let c = new ConstraintMarker(details.matingWidth, tjsColor);
        c.group.position.y = 500;
        c.group.position.z = posX;
        c.group.position.x+=this.getStructureLengthInches()/2;
        c.group.rotation.y=Math.PI/2;
        this.groupMetal.add(c.group);
    }
    
    buildFrontSideConstraintMarker(constraint){

        let details = this.getConstraintMarkerDetails(constraint);
        let posX;
        if(constraint.parent.length>0){
            posX = this.getStructureLengthInches()/2 - details.matingWidth/2;
        }
        else{
            posX = this.getStructureLengthInches()/2 - details.offsetX - details.matingWidth/2;
        }

        //return {constrainerX, constraineeX, constraineeMatingWidth}
        // draw a constraintMarker on the correct side of this structure
        let tjsColor = ColorHelper.hexToTjs(constraint.color);
        let c = new ConstraintMarker(details.matingWidth, tjsColor);
        c.group.position.y = 500;
        c.group.position.x = posX;
        c.group.position.z+=this.getStructureWidthInches()/2;
        this.groupMetal.add(c.group);
    }

    buildBackSideConstraintMarker(constraint){

        let details = this.getConstraintMarkerDetails(constraint);
        let posX;
        if(constraint.parent.length>0){
            posX = -this.getStructureLengthInches()/2 + details.matingWidth/2;
        }
        else{
            posX = -this.getStructureLengthInches()/2 + details.offsetX + details.matingWidth/2;
        }
        
        // draw a constraintMarker on the correct side of this structure
        let tjsColor = ColorHelper.hexToTjs(constraint.color);
        let c = new ConstraintMarker(details.matingWidth, tjsColor);
        c.group.position.y = 500;        
        c.group.position.x = posX;
        c.group.position.z-=this.getStructureWidthInches()/2;
        this.groupMetal.add(c.group);
    }


    

    buildSoldierColumns_All(frontAndBackColumnPositions, leftColumnPositions, rightColumnPositions){

        if(this.design.type  === CORE.components.porch || this.design.type === CORE.components.leanTo)
            return;
        this.buildSoldierColumns_FrontConstrainees(frontAndBackColumnPositions);
        this.buildSoldierColumns_BackConstrainees(frontAndBackColumnPositions);
        this.buildSoldierColumns_LeftConstrainees(leftColumnPositions);
        this.buildSoldierColumns_RightConstrainees(rightColumnPositions);
    }

    buildSoldierColumns_LeftConstrainees(leftColumnPositions){
        let leftConstraineeConstraints = this.constraineeConstraints.filter(c => {
            return c.parent.matingSide === CORE.sides.leftEnd;
        })

        leftConstraineeConstraints.forEach((c)=>{

            const {constrainerX, constraineeX, constraineeMatingWidth} = SystemHelper.getConstraineeMatingSpecs(c,this.frameDesign, this.masterDesign)
            // calc the start position of the constrained structure in terms of this constraining structure
            let start = constrainerX - constraineeX;
            // calc the end position of the constrained structure in terms of this constraining structure
            let end = start + constraineeMatingWidth;

            this.buildSoldierColumns_LeftConstrainee(leftColumnPositions, start, end);
        })
    }

    buildSoldierColumns_LeftConstrainee(leftColumnPositions, start, end){
        let halfWidth = this.getStructureWidthInches()/2
        // find any framelines within 12 inches of the start position        
        let framelinesWithin12InchesOfStart = leftColumnPositions.filter((fl)=>{return Math.abs(fl - start)<12 });
        // find any framelines within 12 inches of the end position
        let framelinesWithin12InchesOfEnd = leftColumnPositions.filter((fl)=>{return Math.abs(fl - end)<12 });

        let x = -this.getStructureLengthInches()/2 +6; // 6 is to get the column inside the wall
        let zRef =-halfWidth;

        if(zRef + start > -halfWidth && zRef + start < halfWidth)
            // if there were no start framelines within 12 inches, add a soldier column
            if(framelinesWithin12InchesOfStart.length==0){
                let sc = this.buildSoldierColumn(x, zRef+start);
                sc.group.rotation.y = Math.PI/2;
                            }

        if(zRef + end > -halfWidth && zRef + end < halfWidth)
            // if there were no end framelines within 12 inches, add a soldier column
            if(framelinesWithin12InchesOfEnd.length==0){
                let sc = this.buildSoldierColumn(x, zRef+end);
                sc.group.rotation.y = Math.PI/2;
            }
    }

    buildSoldierColumns_RightConstrainees(rightColumnPositions){
        let rightConstraineeConstraints = this.constraineeConstraints.filter(c => {
            return c.parent.matingSide === CORE.sides.rightEnd;
        })

        rightConstraineeConstraints.forEach((c)=>{

            const {constrainerX, constraineeX, constraineeMatingWidth} = SystemHelper.getConstraineeMatingSpecs(c,this.frameDesign, this.masterDesign)
            // calc the start position of the constrained structure in terms of this constraining structure
            let end = constrainerX;
            // calc the end position of the constrained structure in terms of this constraining structure
            let start =end - constraineeMatingWidth;

            this.buildSoldierColumns_RightConstrainee(rightColumnPositions, start, end);
        })
    }

    buildSoldierColumns_RightConstrainee(rightColumnPositions, start, end){
        let halfWidth = this.getStructureWidthInches()/2
        let x = this.getStructureLengthInches()/2-6; // 6 is to get the column inside the wall
        let zRef = -halfWidth;
        

        // find any framelines within 12 inches of the start position        
        let framelinesWithin12InchesOfStart = rightColumnPositions.filter((fl)=>{return Math.abs(fl - start)<12 });
        // find any framelines within 12 inches of the end position
        let framelinesWithin12InchesOfEnd = rightColumnPositions.filter((fl)=>{return Math.abs(fl - end)<12 });
                

        if(zRef + start > -halfWidth && zRef + start < halfWidth)
            // if there were no start framelines within 12 inches, add a soldier column
            if(framelinesWithin12InchesOfStart.length==0){
                let sc = this.buildSoldierColumn(x, zRef + start);
                sc.group.rotation.y = Math.PI/2;
            }
            
        if(zRef + end > -halfWidth && zRef + end < halfWidth)    
        // if there were no end framelines within 12 inches, add a soldier column
        if(framelinesWithin12InchesOfEnd.length==0){
            let sc = this.buildSoldierColumn(x, zRef + end);
            sc.group.rotation.y = Math.PI/2;
        }
    }

    buildSoldierColumns_FrontConstrainees(frontAndBackColumnPositions){
        let frontConstraineeConstraints = this.constraineeConstraints.filter(c => {
            return c.parent.matingSide === CORE.sides.frontSide;
        })

        frontConstraineeConstraints.forEach((c)=>{

            const {constrainerX, constraineeX, constraineeMatingWidth} = SystemHelper.getConstraineeMatingSpecs(c,this.frameDesign, this.masterDesign)
            // calc the start position of the constrained structure in terms of this constraining structure
            let start = constrainerX - constraineeX;
            // calc the end position of the constrained structure in terms of this constraining structure
            let end = start + constraineeMatingWidth;

            this.buildSoldierColumns_FrontConstrainee(frontAndBackColumnPositions, start, end);
        })
    }
    
    buildSoldierColumns_BackConstrainees(frontAndBackColumnPositions){
        let backConstraineeConstraints = this.constraineeConstraints.filter(c => {
            return c.parent.matingSide === CORE.sides.backSide;
        })

        backConstraineeConstraints.forEach((c)=>{

            const {constrainerX, constraineeX, constraineeMatingWidth} = SystemHelper.getConstraineeMatingSpecs(c,this.frameDesign, this.masterDesign)
            
            // calc the end position of the constrained structure in terms of this constraining structure
            let end = constrainerX;
            // calc the start position of the constrained structure in terms of this constraining structure
            let start = end - constraineeMatingWidth;
            this.buildSoldierColumns_BackConstrainee(frontAndBackColumnPositions, start, end);
        })
    }


    buildSoldierColumns_FrontConstrainee(frontAndBackColumnPositions, start, end){
        
        let halfLength = this.getStructureLengthInches()/2;
        // find any framelines within 12 inches of the start position        
        let framelinesWithin12InchesOfStart = frontAndBackColumnPositions.filter((fl)=>{return Math.abs(fl - start)<12 });
        // find any framelines within 12 inches of the end position
        let framelinesWithin12InchesOfEnd = frontAndBackColumnPositions.filter((fl)=>{return Math.abs(fl - end)<12 });
        let z = this.getStructureWidthInches()/2-6;// 6 is to get the column inside the wall
        let xRef = -halfLength;
        if(xRef+start > -halfLength && xRef+start < halfLength)
        // if there were no start framelines within 12 inches, add a soldier column
        if(framelinesWithin12InchesOfStart.length==0){
            this.buildSoldierColumn(xRef+start, z);
        }

        if(xRef+end > -halfLength && xRef+end < halfLength)
        // if there were no end framelines within 12 inches, add a soldier column
        if(framelinesWithin12InchesOfEnd.length==0){
            this.buildSoldierColumn(xRef+end, z);
        }
    }
    
    buildSoldierColumns_BackConstrainee(frontAndBackColumnPositions, start, end){
        let halfLength = this.getStructureLengthInches()/2;
        // find any framelines within 12 inches of the start position        
        let framelinesWithin12InchesOfStart = frontAndBackColumnPositions.filter((fl)=>{return Math.abs(fl - start)<12 });
        // find any framelines within 12 inches of the end position
        let framelinesWithin12InchesOfEnd = frontAndBackColumnPositions.filter((fl)=>{return Math.abs(fl - end)<12 });


        let z = -this.getStructureWidthInches()/2+6;// 6 is to get the column inside the wall
        let xRef = -halfLength;
        if(xRef+start > -halfLength && xRef+start < halfLength)
            // if there were no start framelines within 12 inches, add a soldier column
            if(framelinesWithin12InchesOfStart.length==0){
                this.buildSoldierColumn(xRef+start, z);
            }

        if(xRef+end > -halfLength && xRef+end < halfLength)
            // if there were no end framelines within 12 inches, add a soldier column
            if(framelinesWithin12InchesOfEnd.length==0){
                this.buildSoldierColumn(xRef+end, z);
            }
    }

    buildSoldierColumn(posX, posZ){
        let sc = new ColumnSoldier(CORE.preferences.des_levelOfDetail.value,this.frameDesign.height*12,false, BlueprintHelper.getStandardFrameMaterialSize());
        //sc.build(new Vector3(posX,0,posZ), 0);
        sc.group.position.set(posX,0,posZ);
        sc.group.rotation.y = 0;
        
        this.groupMetal.add(sc.group);
        return sc;

    }

    buildPickOnly(){
        this.initPickGroup();
    }

    initTrimMaterials() {
        let eaveAndRakeTrimMaterial;
        let downspoutTrimMaterial; 
        let cornerTrimMaterial; 
        let doorTrimMaterial; 
        let walkAndWindowTrimMaterial; 

        if(this.appearanceDesign.trimColorMode === CORE.modes.trimColor.single){            
            let allTrim = materialHelper.getTrimMaterial(ColorHelper.to3(this.appearanceDesign.trim), CORE.textures.trim.clone())

            eaveAndRakeTrimMaterial =
            downspoutTrimMaterial = 
            cornerTrimMaterial = 
            doorTrimMaterial = 
            walkAndWindowTrimMaterial = allTrim

        }
        else{
            eaveAndRakeTrimMaterial = new THREE.MeshPhongMaterial( { color: ColorHelper.to3(this.appearanceDesign.trims.eaveAndRake), map: CORE.textures.trim.clone(), side: THREE.DoubleSide, wireframe:false});
            downspoutTrimMaterial =  new THREE.MeshPhongMaterial( 
                { color: ColorHelper.to3(this.appearanceDesign.trims.downspout),
                    map: CORE.textures.trim.clone(),
                    side: THREE.DoubleSide,
                    wireframe:false,
                    shininess:10,
                    specular: new THREE.Color("skyblue")
                });            
             cornerTrimMaterial =  new THREE.MeshPhongMaterial( { color: ColorHelper.to3(this.appearanceDesign.trims.corner), map: CORE.textures.trim.clone(), side: THREE.DoubleSide, wireframe:false});
            doorTrimMaterial =  new THREE.MeshPhongMaterial( { color: ColorHelper.to3(this.appearanceDesign.trims.door), map: CORE.textures.trim.clone(), side: THREE.DoubleSide, wireframe:false});
            walkAndWindowTrimMaterial = new THREE.MeshPhongMaterial( { color: ColorHelper.to3(this.appearanceDesign.trims.walksAndWindows), map: CORE.textures.trim.clone(), side: THREE.DoubleSide, wireframe:false});
        }

        let trimMaterials = {
            eaveAndRake: eaveAndRakeTrimMaterial,
            downspout: downspoutTrimMaterial,
            corner: cornerTrimMaterial,
            door: doorTrimMaterial,
            walkAndWindow: walkAndWindowTrimMaterial
        };

        return trimMaterials;
    }

    rebuildRoofAndGutter()
    {
        this.removeRoof();
        this.removeDownspouts();
        
        let trimMaterials = this.initTrimMaterials();
        let {frontEaveSegments, frontDownspouts, backEaveSegments, backDownspouts} = this.buildGutterTrim(this.frame,  trimMaterials);
        
        
        this.buildRoof(  trimMaterials, frontEaveSegments, frontDownspouts, backEaveSegments, backDownspouts);

        
    }

    

    buildFrame(porchFrameLineIndices) {
        let sDesign = this.design;
        let frameDesign = sDesign.getFrame();

        if (this.leftWallDesign && (this.leftWallDesign.openWall || this.leftWallDesign.mainFrame))
            this.design.leftEnd.mainFrame = true;
        else    
            this.design.leftEnd.mainFrame = false;

        
        if (this.rightWallDesign && (this.rightWallDesign.openWall || this.rightWallDesign.mainFrame))
            this.design.rightEnd.mainFrame = true;
        else    
            this.design.rightEnd.mainFrame = false;

        if (this.leftWallDesign)
            frameDesign.leftWallFrameLine = this.leftWallDesign.frameLine;

        else
            frameDesign.leftWallFrameLine = undefined;

        if (this.rightWallDesign)
            frameDesign.rightWallFrameLine = this.getRightWallFrameLineIndexLtR(this.rightWallDesign.frameLine);
        else
            frameDesign.rightWallFrameLine = undefined;

        frameDesign.leftOpenMainFrame = this.design.leftEnd.mainFrame;
        frameDesign.rightOpenMainFrame = this.design.rightEnd.mainFrame;
        // frameDesign.leftOpenMainFrame = this.design.leftEnd.mainFrame;
        // frameDesign.rightOpenMainFrame = this.design.rightEnd.mainFrame;
        frameDesign.columnPositions = this.design.frameLines;

        /*
        Before we create the frame, which creates framelines, we need to know
        which framelines tie in with a porch.
        If a frameline ties in with a porch, that frameline must be upgraded from a cold-formed frameline, to a post/beam frameline.
        If a frameline ties in with a porch and is a mainframe, no upgrade need be done.
    
        To do that:
            get the front and back side design.
            for each, get up to one porch on that side.
            get that porch's frameIndexLo and frameIndexHigh
            When frameBase is processing frameLines, make any none-main-frames, mains (8x4).
        
        */
        frameDesign.leftEnd = {
            baySpacing: {
                mode: CORE.modes.baySpacing.manual,
                center: this.design.leftEnd.baySpacing.center,
                nonCenter: this.design.leftEnd.baySpacing.nonCenter,
            },
            hasPorch: this.getLeftPorchConstraints().length>0,
            skirt: this.design.leftEnd.skirt
        };

        frameDesign.rightEnd = {
            baySpacing: {
                mode: CORE.modes.baySpacing.manual,
                center: this.design.rightEnd.baySpacing.center,
                nonCenter: this.design.rightEnd.baySpacing.nonCenter,
            },
            hasPorch: this.getRightPorchConstraints().length>0,
            skirt: this.design.rightEnd.skirt
        };

        //let framelineData = this.generateFrameLineData(frameDesign, this.masterDesign);
        let beamColor = this.getBeamColor();
        let purlinColor = this.getPurlinColor();
        this.frame = new FrameBase(
            frameDesign,
            this.getStructureScopedConfig(this.appearanceDesign),
            this.design.frameLines,
            frameDesign.width*12,
            frameDesign.frameType,
            frameDesign.height*12,
            porchFrameLineIndices,             
            this.optionDesign.wrapTubePorchCols,
            this.roofDesign.roofFrameType,
            this.roofDesign.pitch,
            this.roofDesign.roofType,
            beamColor,
            purlinColor
            );

        this.groupMetal.add(this.frame.group);
        
    }

    generateFrameLineData(design){
        return FramelineHelper.generate(design, BuildLogic.getOutsideFramelineInset(), design.length*12)
    }

    buildBackSideWall(trimMaterials, frame, wallSideLength, insulation, beamColor, girtColor) {
        if (!this.backWallDesign) 
        return;
        
        let wallBackPos = this.getBackSideWallPosition();
        let pitchRatio = BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch);
        let eaveHeight = this.getBackSideWallHeightInches();

        let shedHoles = this.getBackWallOpeningsForStructures()

        //wallBackPos.x = 0
        //wallBackPos.z = -20;
        this.wallBack = new BSW(
            this.backWallDesign,
            this.getStructureScopedConfig(this.appearanceDesign),
            wallSideLength,
            eaveHeight,
            //this.settings,
            trimMaterials,
            this.backGirtHeights,
            frame.frameLines,
            wallBackPos,
            0,
            true, // supportsWainscot
            undefined, //footerCollisions
            undefined, //addToQuoteLayer
            this.optionDesign.dripTrim,
            insulation,
            pitchRatio,            
            this.getStructureLengthInches(),
            beamColor, 
            girtColor,
            this.getWallOptions(),
            shedHoles
        );      

        // the components array has the original component reference.
        // if you change a component, you have to go update that exact referece

        // otherwise,
        // you have one version of the BSW in this.wallBack and another in the components array (which is frustrating)
        
        /*
        solutions:
             - when an update is applied, the update system gets the component from the component array.
                if it got it directly from the parent component, at that moment, it wouldn't do that.
             - if you update the original components array directly via some helper method, that wouldn't be too bad.
             - what's the problem. the problem is it would be nice if there was a single instance of each component that stuck around
                AND we didn't lose track of it while tearing down and re-building the component tree, because the component is processing
                changes. In this case, the structure was updating the new component, but the old component was processing the change.
             - can't we just put the maxHeight in the design so it's remembered? Yes, but design is about to be huge, let's not bloat it more.


        */
        
        this.groupMetal.add(this.wallBack.group);
    }

    getWallOptions(){
        return {
            ohJambLiner: this.design.getOptions().ohJambLiner
        }
    }

    
    buildNewFSW(trimMaterials, frame, insulation, beamColor, girtColor) {
        if (!this.frontWallDesign)
            return;

        let eaveHeight = this.frameDesign.height*12;
        
        let shedHoles = [];
        let frontSideConstraints = ConstraintHelper.getConstraintsOnSide(this.masterDesign, this.design, CORE.sides.frontSide); 
        if(frontSideConstraints.length > 0){ 
            frontSideConstraints.forEach(c =>{
                ConstraintHelper.migratePartyWallOwnership(c, this.masterDesign);
                if(c.partyWallOwnerID === this.design.id){ // if "this" owns the wall
                    // if "this" is a primary structure, do nothing
    
                    // if "this" is a secondary structure, close the wall
                    if(this.design.type === CORE.components.leanTo){
                        this.frontWallDesign.openWall = false;
                        let closeAllBays = true;
                        this.frontWallDesign.getFrontSideBays().forEach((bay)=>{
                            if(!bay.openWall){
                                // If "this" lean-to's front wall was not previously the pwo, all the bays would be open.
                                // If one of the bays is not open, there is probably a skirt or inset wall,
                                // so we don't want to close all the bays
                                closeAllBays = false;      
                            }
                        })

                        if(closeAllBays)
                            this.frontWallDesign.getFrontSideBays().forEach((bay)=>{bay.openWall = false})
                    }
                    else if(this.design.type === CORE.components.porch)
                        return;
                }
                else{ // if "this" does not own the wall   
                    
                    // if "this" is a primary structure, carve a hole
                    //if(this.design.type === CORE.components.structure){
                        let opening = this.getFrontWallOpeningForStructures(c);
                        if(opening) {shedHoles.push(opening);}
                    //}
                    
                    // if "this" is a secondary structure, open the wall
                    // if(this.design.type === CORE.components.leanTo){
                    //     this.frontWallDesign.openWall = true;
                    //     this.frontWallDesign.getFrontSideBays().forEach((bay)=>{bay.openWall = true})
                    // }
                }
            })
            
        }

        this.wallFront = new FSW(
            this.masterDesign,
            this.frontWallDesign,
            this.getStructureScopedConfig(this.appearanceDesign),
            this.getStructureLengthInches(),
            eaveHeight,
            //this.settings,
            trimMaterials,
            this.frontGirtHeights,
            frame,
            true, // supportsWainscot
            undefined, //footerCollisions
            undefined, //addToQuoteLayer
            this.optionDesign.dripTrim, //allowDripTrim
            insulation, //insulation
            this.getStructureLengthInches(), ///structureLength
            beamColor, 
            girtColor,
            this.getWallOptions(),
            shedHoles
        );
        this.groupMetal.add(this.wallFront.group);
        let wallFrontPos = this.getFrontSideWallPosition();
        this.wallFront.group.position.copy(wallFrontPos);
        
    }

    
    buildNewBSW(trimMaterials, frame, insulation, beamColor, girtColor) {
        if (!this.backWallDesign)
            return;

        let eaveHeight = this.getBackSideWallHeightInches();
        
        let shedHoles = [];
        let backSideConstraints = ConstraintHelper.getConstraintsOnSide(this.masterDesign, this.design, CORE.sides.backSide); 
        if(backSideConstraints.length > 0){ 
            backSideConstraints.forEach(c =>{
                ConstraintHelper.migratePartyWallOwnership(c, this.masterDesign);
                if(c.partyWallOwnerID === this.design.id){ // if "this" owns the wall
                    // if "this" is a primary structure, do nothing
    
                    // if "this" is a secondary structure, close the wall
                    if(this.design.type === CORE.components.leanTo){
                        this.backWallDesign.openWall = false;
                        let closeAllBays = true;
                        this.backWallDesign.getBackSideBays().forEach((bay)=>{
                            if(!bay.openWall){
                                // If "this" lean-to's back wall was not previously the pwo, all the bays would be open.
                                // If one of the bays is not open, there is probably a skirt or inset wall,
                                // so we don't want to close all the bays
                                closeAllBays = false;      
                            }
                        })
                    
                        if(closeAllBays)
                            this.backWallDesign.getBackSideBays().forEach((bay)=>{bay.openWall = false})
                    }
                    else if(this.design.type === CORE.components.porch)
                        return;
                }
                else{ // if "this" does not own the wall   
                    
                    // if "this" is a primary structure, carve a hole
                    //if(this.design.type === CORE.components.structure){
                        let opening = this.getBackWallOpeningForStructures(c);
                        if(opening) {shedHoles.push(opening);}
                    //}
                    
                    // if "this" is a secondary structure, open the wall
                    // if(this.design.type === CORE.components.leanTo){
                    //     this.backWallDesign.openWall = true;
                    //     this.backWallDesign.getBackSideBays().forEach((bay)=>{bay.openWall = true})
                    // }
                }
            })
            
        }


        this.wallBack = new BSW(
            this.masterDesign,
            this.backWallDesign,
            this.getStructureScopedConfig(this.appearanceDesign),
            this.getStructureLengthInches(),
            eaveHeight,
            //this.settings,
            trimMaterials,
            this.backGirtHeights,
            frame,
            true, // supportsWainscot
            undefined, //footerCollisions
            undefined, //addToQuoteLayer
            this.optionDesign.dripTrim, //allowDripTrim
            insulation, //insulation
            this.getStructureLengthInches(), ///structureLength
            beamColor, 
            girtColor,
            this.getWallOptions(),
            shedHoles
        );
        this.groupMetal.add(this.wallBack.group);
        let wallBackPos = this.getBackSideWallPosition();
        this.wallBack.group.position.copy(wallBackPos);
    }


    buildTransversePartitions(){
        this.partitions = [];
        let partitionDesigns = this.design.getTransversePartitions()
        if(partitionDesigns && partitionDesigns.length > 0)
            partitionDesigns.forEach((p)=>{
                let part = this.buildTransversePartition(p);
                if (part) {
                    this.partitions.push(part);
                    //this.components.push(part);
                }
            })

    }

    buildTransversePartition(p,
        trimMaterials,
        insulation,
        beamColor,
        purlinColor) {

        let pitchRatio = BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch);
        //p.lineType = CORE.frame.lineTypes.main; // use main
        //p.baySpacing.mode = CORE.modes.baySpacing.auto;

        // calculate the distance between this wall's frameline and the next interior frameline
        
        // this is logic for the LEW specifically
        let partWallFrameLineIndex = p.frameLine;
        let nextInteriorFramelineIndex = partWallFrameLineIndex+1;
        let thisFramelinePos = this.design.frameLines[partWallFrameLineIndex];        
        let nextInteriorFramelinePos = this.design.frameLines[nextInteriorFramelineIndex];
        let distToNextFrameline = nextInteriorFramelinePos-thisFramelinePos;
        
        // wall uses dynamic columns only
        // frameline 1 is fixed columns only
        // Instantiate the left wall (includes any interior columns if appropriate)
        let length = this.structureWidthInches();
        let eaveHeight = this.lowEaveHeightInches();
        let partition = new TransversePartition(
            this.masterDesign,
            p,
            this.getStructureScopedConfig(this.appearanceDesign),
            length,
            eaveHeight,
            trimMaterials,
            () => {},
            this.endGirtHeights,
            true, // supportsWainscot
            undefined, //footerCollisions
            undefined, //addToQuoteLayer
            this.optionDesign.dripTrim, // can't put drip trim on non-exterior wall
            insulation,
            pitchRatio,
            this.roofDesign.roofType,
            this.frame.frameType,
            distToNextFrameline,
            0,
            beamColor,
            purlinColor,
            this.getWallOptions(),
        );

        partition.group.position.x = -this.getStructureLengthInches()/2+thisFramelinePos;
        partition.group.position.z = -this.getStructureWidthInches()/2;
        // band-aid fix for partition wall placements
        // the mainframe sticks out farther (on the x-axis) than it does on the far ends.
        // so it ends up protruding through the wall, if we move the wall a little bit you can't
        // see the mainframe anymore but there is a slight gap between the partition wall and the corner
        if(partition.buildFacingPositiveZ)
            partition.group.position.x -= 4;
        else 
            partition.group.position.x += 4;
        partition.group.rotation.y =-Math.PI / 2; // rot
        this.groupMetal.add(partition.group);
        
        return partition
    }


    buildNewLew(porchFrameLineIndices, 
        //wallLeftPos, 
        trimMaterials,
        leftEndWallDesignChanged, 
        insulation,
        beamColor,
        purlinColor) {
            
        let hasLeftEndPorch = this.hasLeftEndPorch();
        let pitchRatio = BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch);

        /*
        this.leftWallDesign.lineType = CORE.frame.lineTypes.standard; // assume standard
        if (this.design.leftEnd.mainFrame === true) // if the left end of the frame must use main frames (which includes the LEW)
            this.leftWallDesign.lineType = CORE.frame.lineTypes.main; // use main
        else if (porchFrameLineIndices.includes(this.leftWallDesign.frameLine) || // if a front or back porch runs across this wall line, or
            (this.leftWallDesign.frameLine === 0 && hasLeftEndPorch)) // left end wall is at left end of frame and there's a left porch
            this.leftWallDesign.lineType = CORE.frame.lineTypes.postAndBeam; // porches require more than a standard Cee Column, so use a post-and-beam type columns

        this.leftWallDesign.baySpacing.center = this.design.leftEnd.baySpacing.center;
        this.leftWallDesign.baySpacing.nonCenter = this.design.leftEnd.baySpacing.nonCenter;
        */
        //EW coplanar with frame end 
        if (this.leftWallDesign.frameLine === 0) {
            // porch                
            if (hasLeftEndPorch) {
                //  manual bay spacing (default) => fixed columns using manual override inputs
                //this.leftWallDesign.baySpacing.mode = CORE.modes.baySpacing.manual;
                //this.design.leftEnd.baySpacing.mode = CORE.modes.baySpacing.manual;
            }
            else { // no porch
                //this.leftWallDesign.baySpacing.mode = this.design.leftEnd.baySpacing.mode;
                //  auto bay spacing (default) => dynamic columns based on manual override inputs
                //  manual bay spacing => fixed columns using manual override inputs
            }
        }

        // calculate the distance between this wall's frameline and the next interior frameline
        
        let leftWallFrameLineIndex = this.leftWallDesign.frameLine;
        let nextInteriorFramelineIndex = leftWallFrameLineIndex+1;
        let thisFramelinePos = this.design.frameLines[leftWallFrameLineIndex];        
        let nextInteriorFramelinePos = this.design.frameLines[nextInteriorFramelineIndex];
        let distToNextFrameline = nextInteriorFramelinePos-thisFramelinePos;
        
        let lewOpenBayMax = (this.design.frameLines.length) - (this.rightWallDesign.frameLine+1);
        //wallLeftPos.x=-1000
        // wall uses dynamic columns only
        // frameline 1 is fixed columns only
        // Instantiate the left wall (includes any interior columns if appropriate)
        let length = this.structureWidthInches();
        let eaveHeight = this.lowEaveHeightInches();
        let shedHoles = [];
        let leftEndConstraints = ConstraintHelper.getConstraintsOnSide(this.masterDesign, this.design, CORE.sides.leftEnd)
        if(leftEndConstraints.length > 0){ 
            leftEndConstraints.forEach(c =>{
                ConstraintHelper.migratePartyWallOwnership(c, this.masterDesign);
                if(c.partyWallOwnerID === this.design.id){ // if "this" owns the wall
                    // if "this" is a primary structure, make sure the wall is visible
                    this.leftWallDesign.openWall = false;
                    
                    // if "this" is a secondary structure, close the back wall 
                    if(this.design.type === CORE.components.leanTo){
                        this.leftWallDesign.openWall = false;
                        this.leftWallDesign.getLeftEndBays().forEach((bay)=>{bay.openWall = false})
                    }
                }
                else{ // if "this" does not own the wall
                    this.leftWallDesign.openWall = false;
                    let opening = this.getLeftWallOpeningForStructures(c)
                    if(opening){shedHoles.push(opening);}
                }
            })
        }
        this.wallLeft = new LEW(
            this.masterDesign,
            this.leftWallDesign,
            this.getStructureScopedConfig(this.appearanceDesign),
            length,
            eaveHeight,
            trimMaterials,
            leftEndWallDesignChanged,
            this.endGirtHeights,
            true, // supportsWainscot
            undefined, //footerCollisions
            undefined, //addToQuoteLayer
            this.optionDesign.dripTrim,
            insulation,
            pitchRatio,
            this.roofDesign.roofType,
            this.frame.frameType,
            distToNextFrameline,
            lewOpenBayMax,
            beamColor,
            purlinColor,
            this.getWallOptions(),
            shedHoles
        );

        this.wallLeft.group.position.x = -this.getStructureLengthInches()/2;
        this.wallLeft.group.position.z = -this.getStructureWidthInches()/2;
        //this.wallLeft.group.position.z = 10+this.getStructureWidthInches()/2;
        this.wallLeft.group.rotation.y =-Math.PI / 2; // rot
        this.groupMetal.add(this.wallLeft.group);


    }

    buildNewRew(porchFrameLineIndices, 
        //wallLeftPos, 
        trimMaterials,
        rightEndWallDesignChanged , 
        insulation,
        beamColor,
        purlinColor) {
            
        let hasRightEndPorch = this.hasRightEndPorch();
        let pitchRatio = BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch);

        /////////////////////// STANDARD END-WALL LOGIC///////////////////////
        // wall settings
        //    mainFrame
        //    baySpacing
        
        // environment
        //    postAndBeam
        //this.rightWallDesign.lineType = CORE.frame.lineTypes.standard; // assume standard
        //if (this.design.rightEnd.mainFrame === true) // if the left end of the frame must use main frames (which includes the LEW)
            //this.rightWallDesign.lineType = CORE.frame.lineTypes.main; // use main
        //else if (porchFrameLineIndices.includes(this.getRightWallFrameLineIndexLtR()) || // if a front or back porch runs across this wall line, or
            //(this.rightWallDesign.frameLine === 0 && hasRightEndPorch)) // left end wall is at left end of frame and there's a left porch
            //this.rightWallDesign.lineType = CORE.frame.lineTypes.postAndBeam; // porches require more than a standard Cee Column, so use a post-and-beam type columns
        //////////////////////////////////////////////////////////////////////

        //this.rightWallDesign.baySpacing.center = this.design.rightEnd.baySpacing.center;
        //this.rightWallDesign.baySpacing.nonCenter = this.design.rightEnd.baySpacing.nonCenter;
        //EW coplanar with frame end 
        if (this.rightWallDesign.frameLine === 0) {
            // porch                
            if (hasRightEndPorch) {
                //  manual bay spacing (default) => fixed columns using manual override inputs
                //this.leftWallDesign.baySpacing.mode = CORE.modes.baySpacing.manual;
                //this.design.rightEnd.baySpacing.mode = CORE.modes.baySpacing.manual;
            }
            else { // no porch
                //this.leftWallDesign.baySpacing.mode = this.design.rightEnd.baySpacing.mode;
                //  auto bay spacing (default) => dynamic columns based on manual override inputs
                //  manual bay spacing => fixed columns using manual override inputs
            }
        }

        // calculate the distance between this wall's frameline and the next interior frameline
        
        let rightWallFrameLineIndex =(this.design.frameLines.length-1)- this.rightWallDesign.frameLine;
        let nextInteriorFramelineIndex = rightWallFrameLineIndex-1;
        let thisFramelinePos = this.design.frameLines[rightWallFrameLineIndex];        
        let nextInteriorFramelinePos = this.design.frameLines[nextInteriorFramelineIndex];
        let distToNextFrameline = nextInteriorFramelinePos-thisFramelinePos;

        let rewOpenBayMax = (this.design.frameLines.length) - (this.leftWallDesign.frameLine+1);
        //wallLeftPos.x=-1000
        // wall uses dynamic columns only
        // frameline 1 is fixed columns only
        // Instantiate the left wall (includes any interior columns if appropriate)
        let length = this.structureWidthInches();
        let eaveHeight = this.lowEaveHeightInches();
        let shedHoles = [];
        let rightEndConstraint = ConstraintHelper.getConstraintsOnSide(this.masterDesign, this.design, CORE.sides.rightEnd)
        if(rightEndConstraint.length > 0){ 
            rightEndConstraint.forEach(c =>{
                ConstraintHelper.migratePartyWallOwnership(c, this.masterDesign);
                if(c.partyWallOwnerID === this.design.id){ // if "this" owns the wall
                    // if "this" is a primary structure, make sure the wall is visible
                    this.rightWallDesign.openWall = false;

                    // if "this" is a secondary structure, close the back wall 
                    if(this.design.type === CORE.components.leanTo){
                        this.rightWallDesign.openWall = false;
                        this.rightWallDesign.getRightEndBays().forEach((bay)=>{bay.openWall = false})
                    }
                }
                else{ // if "this" does not own the wall
                    this.rightWallDesign.openWall = false;
                    let opening = this.getRightWallOpeningForStructures(c)
                    if(opening){shedHoles.push(opening);}
                }
            })
        }
        this.wallRight = new REW(
            this.masterDesign,
            this.rightWallDesign,
            this.getStructureScopedConfig(this.appearanceDesign),
            length,
            eaveHeight,
            trimMaterials,
            rightEndWallDesignChanged,
            this.endGirtHeights,
            true, // supportsWainscot
            undefined, //footerCollisions
            undefined, //addToQuoteLayer
            this.optionDesign.dripTrim,
            insulation,
            pitchRatio,
            this.roofDesign.roofType,
            this.frame.frameType,
            distToNextFrameline,
            rewOpenBayMax,
            beamColor,
            purlinColor,
            this.getWallOptions(),
            shedHoles
        );

        this.wallRight.group.position.x = this.getStructureLengthInches()/2;
        this.wallRight.group.position.z = -this.getStructureWidthInches()/2;
        this.wallRight.group.rotation.y = -Math.PI / 2; // rot
        this.groupMetal.add(this.wallRight.group);
    }



    buildFrontSideWall(trimMaterials, frame, wallSideLength,insulation, beamColor, girtColor) {
        if (!this.frontWallDesign)
            return;

        let pitchRatio = BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch);
        let wallFrontPos = this.getFrontSideWallPosition();
        let eaveHeight = this.frameDesign.height*12;
        

        let shedHoles = this.getFrontWallOpeningsForStructures()

        this.wallBack = new FSW(            
            this.frontWallDesign,
            this.getStructureScopedConfig(this.appearanceDesign),
            wallSideLength,
            eaveHeight,
            //this.settings,
            trimMaterials,
            this.frontGirtHeights,
            Array.from(frame.frameLines),
            true, // supportsWainscot
            undefined, //footerCollisions
            undefined, //addToQuoteLayer
            this.optionDesign.dripTrim, //allowDripTrim
            insulation, //insulation
            pitchRatio, //pitchRatio            
            this.getStructureLengthInches(), ///structureLength
            beamColor, 
            girtColor,
            this.getWallOptions(),
            shedHoles
        );
        this.groupMetal.add(this.wallBack.group);
        
    }

    getWallEndRightPositionX()
    {
        return BuildLogic.GetRightEndWallPosX(this.getStructureLengthInches());
         // used for side wall length
         let wallRightPos=this.getStructureLengthInches();
         if(this.rightWallDesign)
             wallRightPos = this.design.frameLines[this.frameDesign.rightWallFrameLine]; // the coord of the right wall.
         
             wallRightPos -= this.getStructureLengthInches()/2;
        wallRightPos += BlueprintHelper.getStandardFrameMaterialSize().depth/2;
        return wallRightPos;
    }

    getStructureLengthInches(){
        if(!this.frameDesign)
            return 0;
        return this.frameDesign.length*12;
    }
    getStructureWidthInches(){
        if(!this.frameDesign)
            return 0;
        return this.frameDesign.width*12;
    }
    getStructureHeightInches(){
        return BlueprintHelper.getStructureHeightInches(this.frameDesign);
    }

    getWallEndLeftPositionX()
    {
        return BuildLogic.GetLeftEndWallPosX( this.getStructureLengthInches() );
        // used for front wall positioning
        let wallLeftPos=0;
        if(this.leftWallDesign)
            wallLeftPos = this.design.frameLines[this.frameDesign.leftWallFrameLine]; // the coord of the left wall.
        wallLeftPos -= this.getStructureLengthInches()/2;
        wallLeftPos -= BlueprintHelper.getStandardFrameMaterialSize().depth/2;
        return wallLeftPos;
    }

    getFrontSideWallPosition(){
        let wallLeftPos = this.getWallEndLeftPositionX()

        return new Vector3(wallLeftPos,0,this.structureWidthInches()/2)
    }

    getBackSideWallPosition(){
        let wallLeftPos = this.getWallEndLeftPositionX()
        return new Vector3(wallLeftPos,0,-this.structureWidthInches()/2)
    }

    getSideWallLength(){
        let wallLeftPos = this.getWallEndLeftPositionX()

        let wallRightPos=this.getWallEndRightPositionX();

        return wallRightPos - wallLeftPos;   
    }

    getFrontSideWallHeightInches(){
        return BlueprintHelper.getStructureHeightInches(this.frameDesign);
    }

    getBackSideWallHeightInches(){
        return BlueprintHelper.getBackSideWallHeightInches(this.roofDesign, this.frameDesign);
    }
    

    buildRightEndWall(
        porchFrameLineIndices, 
        wallRightPos, 
        trimMaterials, 
        rightEndWallDesignChanged, 
        insulation,
        beamColor,
        purlinColor
        ) {
        let hasRightEndPorch = this.hasRightEndPorch();
        let pitchRatio = BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch);
        // update rightWallDesign based on context.
        this.rightWallDesign.lineType = CORE.frame.lineTypes.standard;
        if (this.design.rightEnd.mainFrame === true)
            this.rightWallDesign.lineType = CORE.frame.lineTypes.main;
        else if (porchFrameLineIndices.includes(this.getRightWallFrameLineIndexLtR()) || // if a front or back porch runs across this wall line, or
            (this.rightWallDesign.frameLine === 0 && hasRightEndPorch)) // left end wall is at left end of frame and there's a left porch
            this.rightWallDesign.lineType = CORE.frame.lineTypes.postAndBeam;


        this.rightWallDesign.baySpacing.center = this.design.rightEnd.baySpacing.center;
        this.rightWallDesign.baySpacing.nonCenter = this.design.rightEnd.baySpacing.nonCenter;
        //EW coplanar with frame end 
        if (this.rightWallDesign.frameLine === 0) {
            // porch                
            if (hasRightEndPorch) {
                //  manual bay spacing (default) => fixed columns using manual override inputs
                this.rightWallDesign.baySpacing.mode = CORE.modes.baySpacing.manual;
                this.design.rightEnd.baySpacing.mode = CORE.modes.baySpacing.manual;
            }
            else { // no porch
                this.rightWallDesign.baySpacing.mode = this.design.rightEnd.baySpacing.mode;
                //  auto bay spacing (default) => dynamic columns based on manual override inputs
                //  manual bay spacing => fixed columns using manual override inputs
            }
        }
        else //EW not coplanar with frame end 
        {
            this.rightWallDesign.baySpacing.mode = CORE.modes.baySpacing.auto; // the wall is dynamic and not manually controllable                
            //this.rightWallDesign.baySpacing.center = 20;
            //this.rightWallDesign.baySpacing.nonCenter = 20;
        }


        //let rightFrameLine = frame.frameLines[this.getRightWallFrameLineIndexLtR()];
        //let halfColumnDepth = BlueprintHelper.getStandardFrameMaterialSize().depth/2;
        //let rightWallPos = rightFrameLine.posX + (halfColumnDepth + .5);//        
        
        let wallRightRotY = -Math.PI / 2;
        //wallRightRotY=-0;
        //wallRightPos.x = -20;
        //wallRightPos.y = 200;
        //wallRightPos.z = -80;
        
        let rightWallFrameLineIndex =(this.design.frameLines.length-1)- this.rightWallDesign.frameLine;
        let nextInteriorFramelineIndex = rightWallFrameLineIndex-1;
        let thisFramelinePos = this.design.frameLines[rightWallFrameLineIndex];        
        let nextInteriorFramelinePos = this.design.frameLines[nextInteriorFramelineIndex];
        let distToNextFrameline = nextInteriorFramelinePos-thisFramelinePos;

        let rewOpenBayMax = (this.design.frameLines.length) - (this.leftWallDesign.frameLine+1);
        // Instantiate the right wall
        let length = this.structureWidthInches();
        let eaveHeight = this.lowEaveHeightInches();
        let shedHoles = this.getRightWallOpeningsForStructures()

        this.wallRight = new REW(
            this.rightWallDesign,
            this.getStructureScopedConfig(this.appearanceDesign),
            length,
            eaveHeight,            
            //this.settings,
            trimMaterials,
            //shapeRight, 
            rightEndWallDesignChanged, 
            this.endGirtHeights,
            wallRightPos,
            wallRightRotY,
            true, // supportsWainscot
            undefined, //footerCollisions
            undefined, //addToQuoteLayer
            this.optionDesign.dripTrim,
            insulation,
            pitchRatio,
            this.roofDesign.roofType,
            this.frame.frameType,
            distToNextFrameline,
            rewOpenBayMax,
            beamColor, 
            purlinColor,
            this.getWallOptions(),
            shedHoles
        );
        this.groupMetal.add(this.wallRight.group);


    }

    buildLeftEndWall(porchFrameLineIndices, 
        wallLeftPos, 
        trimMaterials,
        leftEndWallDesignChanged, 
        insulation,
        beamColor,
        purlinColor) {
        let hasLeftEndPorch = this.hasLeftEndPorch();
        let pitchRatio = BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch);
        this.leftWallDesign.lineType = CORE.frame.lineTypes.standard; // assume standard
        if (this.design.leftEnd.mainFrame === true) // if the left end of the frame must use main frames (which includes the LEW)
            this.leftWallDesign.lineType = CORE.frame.lineTypes.main; // use main
        else if (porchFrameLineIndices.includes(this.leftWallDesign.frameLine) || // if a front or back porch runs across this wall line, or
            (this.leftWallDesign.frameLine === 0 && hasLeftEndPorch)) // left end wall is at left end of frame and there's a left porch
            this.leftWallDesign.lineType = CORE.frame.lineTypes.postAndBeam; // porches require more than a standard Cee Column, so use a post-and-beam type columns

        this.leftWallDesign.baySpacing.center = this.design.leftEnd.baySpacing.center;
        this.leftWallDesign.baySpacing.nonCenter = this.design.leftEnd.baySpacing.nonCenter;
        //EW coplanar with frame end 
        if (this.leftWallDesign.frameLine === 0) {
            // porch                
            if (hasLeftEndPorch) {
                //  manual bay spacing (default) => fixed columns using manual override inputs
                this.leftWallDesign.baySpacing.mode = CORE.modes.baySpacing.manual;
                this.design.leftEnd.baySpacing.mode = CORE.modes.baySpacing.manual;
            }
            else { // no porch
                this.leftWallDesign.baySpacing.mode = this.design.leftEnd.baySpacing.mode;
                //  auto bay spacing (default) => dynamic columns based on manual override inputs
                //  manual bay spacing => fixed columns using manual override inputs
            }
        }
        else //EW not coplanar with frame end 
        {

            //this.design.leftEnd.baySpacing.mode=CORE.modes.baySpacing.manual; // the frame end is manual only and needs no automatic support
            this.leftWallDesign.baySpacing.mode = CORE.modes.baySpacing.auto; // the wall is dynamic and not manually controllable                
            //this.leftWallDesign.baySpacing.center = this.design.leftEnd.baySpacing.center;
            //this.leftWallDesign.baySpacing.nonCenter = this.design.leftEnd.baySpacing.nonCenter;
        }


        // inset walls are considered here
        //.GetLeftEndWallPosX
        
        //let leftFrameLine = frame.frameLines[this.leftWallDesign.frameLine];
        //let halfColumnDepth = BlueprintHelper.getStandardFrameMaterialSize().depth/2;
        //let leftWallPos = leftFrameLine.posX - (halfColumnDepth + .5);

        // calculate the distance between this wall's frameline and the next interior frameline
        
        let leftWallFrameLineIndex = this.leftWallDesign.frameLine;
        let nextInteriorFramelineIndex = leftWallFrameLineIndex+1;
        let thisFramelinePos = this.design.frameLines[leftWallFrameLineIndex];        
        let nextInteriorFramelinePos = this.design.frameLines[nextInteriorFramelineIndex];
        let distToNextFrameline = nextInteriorFramelinePos-thisFramelinePos;
        
        let lewOpenBayMax = (this.design.frameLines.length) - (this.rightWallDesign.frameLine+1);
        //wallLeftPos.x=-1000
        // wall uses dynamic columns only
        // frameline 1 is fixed columns only
        // Instantiate the left wall (includes any interior columns if appropriate)
        let length = this.structureWidthInches();
        let eaveHeight = this.lowEaveHeightInches();
        let shedHoles = this.getLeftWallOpeningsForStructures()
        this.wallLeft = new LEW(
            this.leftWallDesign,
            this.getStructureScopedConfig(this.appearanceDesign),
            length,            
            eaveHeight,
            trimMaterials,
            leftEndWallDesignChanged,
            this.endGirtHeights,
            wallLeftPos,
            -Math.PI / 2, // rot
            true, // supportsWainscot
            undefined, //footerCollisions
            undefined, //addToQuoteLayer
            this.optionDesign.dripTrim,
            insulation,
            pitchRatio,
            this.roofDesign.roofType,
            this.frame.frameType,
            distToNextFrameline,
            lewOpenBayMax,
            beamColor,
            purlinColor,
            this.getWallOptions(),
            shedHoles
        );
        this.groupMetal.add(this.wallLeft.group);


    }

    setGirtLocations(){
        let girtHeights = [];
        
        switch(this.design.girts.setLocations){
            case CORE.girts.four:
                girtHeights.push(4*12)
                break;
            case CORE.girts.seven:
                girtHeights.push(7.3333*12);
                break;
            case CORE.girts.fourAndSeven:
                girtHeights.push(4*12)
                girtHeights.push(7.3333*12);
                break;
        }

        this.frontGirtHeights = this.getSideWallGirtHeights(girtHeights, this.lowEaveHeightInches()); // front wall height is always structure height
        this.backGirtHeights = this.getSideWallGirtHeights(girtHeights, this.getBackSideWallHeightInches()) // back wall height may be 1 or .5 pitch height
        this.endGirtHeights = this.getEndWallGirtHeights(girtHeights, this.getRoofRidgeHeight());
        
        // ignore end-wall girts that would fall within 24" of the roof peak
        let maxEndWallGirtHeight =  this.getRoofRidgeHeight() - 24 // https://trello.com/c/ZFBYkFeh/222-imagepng
        this.endGirtHeights = this.endGirtHeights.filter(h => h < maxEndWallGirtHeight);
    }

    getTallerTrimHeight(wallDesignA, wallDesignB){
        if(!wallDesignA && !wallDesignB)
            return 0;
        else if(!wallDesignA || !wallDesignA.height)
            return wallDesignB.height;
        else if(!wallDesignB || !wallDesignB.height)
        return wallDesignA.height;



        //https://trello.com/c/ZfK3v0rL/209-fix-vertical-wall-corner-trim-behavior
        let heightA = wallDesignA.height;
        let heightB = wallDesignB.height;


        if(wallDesignA.openWall)
            heightA = 0;
        if(wallDesignB.openWall)
            heightB = 0;

        return Math.max(heightA, heightB);

        

        // if left wall is open, just use front height
        // if front wall is open, just use left height
        // if use the taller of the two 
    }


    buildEndSkirtLeft(frontAndBackColumnPositions, trimMaterials, endGirtHeights, frontGirtHeights, backGirtHeights, wallColor){            

        let lesDesign = this.design.getLeftEndSkirt();
        // if there's no existing left end skirt design
        let baySpacing = this.getLeftOpenBays();
        let pitchRatio = BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch);
        let pitchHeight = BlueprintHelper.pitchHeight(this.getStructureWidthInches(), pitchRatio, this.roofDesign.roofType)
        let needsNewLeftSkirtDesign = !lesDesign ;//|| !lesDesign.roofType // migrate left end skirt 2021.03.19 (the absence of roofType is used as a signal)

        if(needsNewLeftSkirtDesign){
        
            // generate one                    
            lesDesign= EndSkirt.getDesign(                
                CORE.components.skirtLeft,
                this.getStructureWidthInches(),
                this.roofDesign.roofType,
                pitchHeight,                
                baySpacing,
                ColorHelper.to3(this.appearanceDesign.wall)
            );
        
            // add it to the master design
            this.masterDesign.addComponent(this.design, lesDesign);
        }
        else{
                EndSkirt.updateDesign(
                    lesDesign,
                    this.getStructureWidthInches(),
                    this.roofDesign.roofType,
                    pitchHeight,                
                    baySpacing,
                    ColorHelper.to3(this.appearanceDesign.wall));

            }

            let hasLeftEndPorch = this.hasLeftEndPorch();
            lesDesign.lineType = CORE.frame.lineTypes.standard; // assume standard
            if(this.design.leftEnd.mainFrame===true) // if the left end of the frame must use main frames (which includes the LEW)
                lesDesign.lineType = CORE.frame.lineTypes.main; // use main
            else if(hasLeftEndPorch) // left end wall is at left end of frame and there's a left porch
                lesDesign.lineType = CORE.frame.lineTypes.postAndBeam; // porches require more than a standard Cee Column, so use a post-and-beam type columns
            lesDesign.baySpacing= {};
            lesDesign.baySpacing.center = this.design.leftEnd.baySpacing.center;
            lesDesign.baySpacing.nonCenter = this.design.leftEnd.baySpacing.nonCenter;
            // porch                
            if(hasLeftEndPorch){
                //  manual bay spacing (default) => fixed columns using manual override inputs
                
                this.design.leftEnd.baySpacing.mode=CORE.modes.baySpacing.manual;
                lesDesign.baySpacing.mode=CORE.modes.baySpacing.manual;
            }
            else{ // no porch
                lesDesign.baySpacing.mode=this.design.leftEnd.baySpacing.mode;
                //  auto bay spacing (default) => dynamic columns based on manual override inputs
                //  manual bay spacing => fixed columns using manual override inputs
            }


        // calculate the distance between this wall's frameline and the next interior frameline
        let thisFramelinePos = frontAndBackColumnPositions[0];        
        let nextInteriorFramelinePos = frontAndBackColumnPositions[1];
        let distToNextFrameline = Math.abs(nextInteriorFramelinePos-thisFramelinePos);
            
        this.leftEndSkirt = new EndSkirtLeft(
                lesDesign, 
                this.getStructureScopedConfig(this.appearanceDesign), 
                this.getStructureLengthInches(),
                this.getStructureWidthInches(),
                this.getStructureHeightInches(),
                this.frameDesign.frameType,
                distToNextFrameline,
                trimMaterials,
                endGirtHeights,
                this.frontGirtHeights,
                backGirtHeights, 
                wallColor,
                pitchRatio,
                this.getBeamColor()
                );
        this.leftEndSkirt.group.position.x = -this.getStructureLengthInches()/2;
        this.leftEndSkirt.group.position.y = this.getStructureHeightInches() + pitchHeight;
        this.leftEndSkirt.group.rotateY(-Math.PI/2);
        this.leftEndSkirt.build();
        this.groupMetal.add(this.leftEndSkirt.group);
    }

    buildEndSkirtRight(frontAndBackColumnPositions, trimMaterials, endGirtHeights, frontGirtHeights, backGirtHeights, wallColor){    

        let resDesign = this.design.getRightEndSkirt();
        let pitchRatio = BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch);
        let pitchHeight = this.GetPitchHeight()
        let baySpacing = this.getRightOpenBays();

        let needsNewRightSkirtDesign = !resDesign;// || !resDesign.roofType // migrate left end skirt 2012.03.19


        if(needsNewRightSkirtDesign){

            // first add only
            resDesign = EndSkirt.getDesign( 
                CORE.components.skirtRight,
                this.frameDesign.width*12,
                this.roofDesign.roofType,
                this.GetPitchHeight(),
                baySpacing,
                ColorHelper.to3(this.appearanceDesign.wall)
            );

            // first add only
            this.masterDesign.addComponent(this.design, resDesign);
            
        }
        else
        {
            EndSkirt.updateDesign(resDesign, 
                this.frameDesign.width*12,
                this.roofDesign.roofType,
                this.GetPitchHeight(),                
                baySpacing,
                ColorHelper.to3(this.appearanceDesign.wall)
                );
        }

    
        let hasRightEndPorch = this.hasRightEndPorch();
        resDesign.lineType = CORE.frame.lineTypes.standard; // assume standard
        if(this.design.rightEnd.mainFrame===true) // if the left end of the frame must use main frames (which includes the LEW)
            resDesign.lineType = CORE.frame.lineTypes.main; // use main
        else if(hasRightEndPorch) // left end wall is at left end of frame and there's a left porch
            resDesign.lineType = CORE.frame.lineTypes.postAndBeam; // porches require more than a standard Cee Column, so use a post-and-beam type columns
        resDesign.baySpacing= {};
        resDesign.baySpacing.center = this.design.rightEnd.baySpacing.center;
        resDesign.baySpacing.nonCenter = this.design.rightEnd.baySpacing.nonCenter;
        // porch                
        if(hasRightEndPorch){
            //  manual bay spacing (default) => fixed columns using manual override inputs
            
            this.design.rightEnd.baySpacing.mode=CORE.modes.baySpacing.manual;
            resDesign.baySpacing.mode=CORE.modes.baySpacing.manual;
        }
        else{ // no porch
            resDesign.baySpacing.mode=this.design.rightEnd.baySpacing.mode;
            //  auto bay spacing (default) => dynamic columns based on manual override inputs
            //  manual bay spacing => fixed columns using manual override inputs
        }

        // calculate the distance between this wall's frameline and the next interior frameline
        let thisFramelinePos = frontAndBackColumnPositions[frontAndBackColumnPositions.length-1];        
        let nextInteriorFramelinePos = frontAndBackColumnPositions[frontAndBackColumnPositions.length-2];
        let distToNextFrameline = Math.abs(nextInteriorFramelinePos-thisFramelinePos);

        // must be done every time
        this.rightEndSkirt = new EndSkirtRight(
            resDesign,
            this.getStructureScopedConfig(this.appearanceDesign), 
            this.getStructureLengthInches(),
            this.getStructureWidthInches(),
            this.getStructureHeightInches(),
            this.frameDesign.frameType,
            distToNextFrameline,
            trimMaterials,
            endGirtHeights,
            this.frontGirtHeights,
            backGirtHeights, 
            wallColor,
            pitchRatio,
            this.getBeamColor());

        this.rightEndSkirt.group.position.x = this.getStructureLengthInches()/2;
        this.rightEndSkirt.group.position.y = this.getStructureHeightInches() + pitchHeight;
        this.rightEndSkirt.group.rotateY(Math.PI/2);
        this.rightEndSkirt.build();
        this.groupMetal.add(this.rightEndSkirt.group);
    }


    buildEndSkirts(frontAndBackColumnPositions,  trimMaterials, endGirtHeights, frontGirtHeights, backGirtHeights, wallColor){
        // if openBays is > 1 and skirt is checked,
        if(this.leftWallDesign && this.leftWallDesign.frameLine > 0 &&
            this.design.leftEnd.skirt===true)
            this.buildEndSkirtLeft(frontAndBackColumnPositions, trimMaterials, endGirtHeights, frontGirtHeights, backGirtHeights, wallColor);
        
        // if openBays is > 1 and skirt is checked,
        if(this.rightWallDesign && this.rightWallDesign.frameLine > 0 &&
            this.design.rightEnd.skirt===true)
            this.buildEndSkirtRight(frontAndBackColumnPositions, trimMaterials, endGirtHeights, frontGirtHeights, backGirtHeights, wallColor);
    }

    getRoofPosition(){
        return new THREE.Vector3(0,this.getStructureHeightInches(),0)
    }

    // buildFrameSides(frontandBackColumnPositions, leftColumnPositions, rightColumnPositions){
    //     let frontSideFrameHeight = this.lowEaveHeightInches(); // front is the low side
        
    //     let backSideFrameHeight = this.getBackSideWallHeightInches();

    //     let length = this.design.getFrame().length*12; //
    //     let width = this.design.getFrame().width*12;


    //     this.buildFrontFrameSide(
    //         frontandBackColumnPositions, 
    //         length,
    //         width,
    //         frontSideFrameHeight);
            
    //     this.buildBackFrameSide(
    //         frontandBackColumnPositions, 
    //         length,
    //         width,
    //         backSideFrameHeight);

    //     this.buildLeftFrameSide(
    //         leftColumnPositions, 
    //         length,
    //         width,
            
    //         frontSideFrameHeight); // guessing here that the front(low) side constrains the height of porches on the LEW and REW

    //     this.buildRightFrameSide(
    //         rightColumnPositions,
    //         length,
    //         width,
    //         frontSideFrameHeight
    //         );
    // }

    buildFrontFrameSide(frontandBackColumnPositions, length, width, height){

        const points = BlueprintHelper.generatePointsWithOffset(length,height,0)
        // a simple rectangular plane requires 6 points, 3 for each triangle, with two overlapping points
        points.push(points[0]);
        points.push(points[2]);
        let verts = vHelper.getFloat32ArrayFromPoints(points);
        var geometry = new THREE.BufferGeometry();

        geometry.setAttribute( 'position', new THREE.BufferAttribute( verts, 3 ) );

        this.sideFront = new FrameSideFront(
            this.frontFrameSideDesign,             
            frontandBackColumnPositions,
            this.gStatic,
            geometry,
            this.masterDesign,
            new THREE.Vector3(-length/2,0,width/2),
            length,
            this.roofDesign.roofType,
            this.roofDesign.pitch,
            this.frameDesign.width,
            this.frameDesign.height,
            this.frameDesign.frameType
            );
    }
    
    buildBackFrameSide(frontandBackColumnPositions, length, width, height){

        let points = BlueprintHelper.generatePointsWithOffset(length,height,0)
        // a simple rectangular plane requires 6 points, 3 for each triangle, with two overlapping points
        points.push(points[0]);
        points.push(points[2]);
        let verts = vHelper.getFloat32ArrayFromPoints(points);
        var geometry = new THREE.BufferGeometry();

        geometry.setAttribute( 'position', new THREE.BufferAttribute( verts, 3 ) );

        this.sideBack = new FrameSideBack(
            this.backFrameSideDesign,
            frontandBackColumnPositions,
            this.gStatic,
            geometry,
            this.masterDesign,
            new THREE.Vector3(-length/2,0,-width/2),
            length,
            this.roofDesign.roofType,
            this.roofDesign.pitch,
            this.frameDesign.width,
            this.frameDesign.height,
            this.frameDesign.frameType
            );

    }
    
    buildLeftFrameSide(leftColumnPositions, length, width, height){

        let pitchRatio = BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch);
        let pitchHeight = BlueprintHelper.pitchHeight(this.frameDesign.width*12, pitchRatio, this.design.getRoof().roofType);
        let points = BlueprintHelper.generatePointsWithOffset(width,height,this.roofDesign.roofType, pitchHeight);
        // a simple rectangular plane requires 6 points, 3 for each triangle, with two overlapping points
        points.push(points[0]);
        points.push(points[2]);
        let verts = vHelper.getFloat32ArrayFromPoints(points);
        var geometry = new THREE.BufferGeometry();

        geometry.setAttribute( 'position', new THREE.BufferAttribute( verts, 3 ) );
       
            this.sideLeft = new FrameSideLeft(
                this.leftFrameSideDesign,
                leftColumnPositions,
                this.gStatic,
                geometry,
                this.masterDesign,
                new THREE.Vector3(-length/2,0,-width/2),
                width,
                this.roofDesign.roofType,
                this.roofDesign.pitch,
                this.frameDesign.width,
                this.frameDesign.height,
                this.frameDesign.frameType

                );
    }


    buildRightFrameSide(rightColumnPositions, length, width, height){

        let pitchRatio = BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch);
        let pitchHeight = BlueprintHelper.pitchHeight(this.frameDesign.width*12, pitchRatio, this.design.getRoof().roofType);
        let points = BlueprintHelper.generatePointsWithOffset(width,height,this.roofDesign.roofType, pitchHeight);
        // a simple rectangular plane requires 6 points, 3 for each triangle, with two overlapping points
        points.push(points[0]);
        points.push(points[2]);
        let verts = vHelper.getFloat32ArrayFromPoints(points);
        
        var geometry = new THREE.BufferGeometry();

        geometry.setAttribute( 'position', new THREE.BufferAttribute( verts, 3 ) );
        
            this.sideRight = new FrameSideRight(
                this.rightFrameSideDesign,
                rightColumnPositions,
                this.gStatic,
                geometry,
                this.masterDesign,
                new THREE.Vector3(length/2,0,-width/2),
                width,
                this.roofDesign.roofType,
                this.roofDesign.pitch,
                this.frameDesign.width,
                this.frameDesign.height,
                this.frameDesign.frameType
                );

    }

    getWallBackLeftBottom(){

        let leftWallPos = BuildLogic.GetLeftEndWallPosX(this.getStructureLengthInches());
        let pos = new Vector3(leftWallPos,0,-this.getStructureWidthInches()/2)
        return pos;
    }

    getWallBackLeftTop(){
        let leftWallPos = BuildLogic.GetLeftEndWallPosX( this.getStructureLengthInches());
        let pos = new Vector3(leftWallPos, this.getBackSideWallHeightInches() ,-this.getStructureWidthInches()/2)
        return pos;
    }

    getWallBackRightBottom(){

        let rightWallPos = BuildLogic.GetRightEndWallPosX(this.getStructureLengthInches());
        let pos = new Vector3(rightWallPos,0,-this.getStructureWidthInches()/2)
        return pos;
    }

    
    getWallBackRightTop(){
        let rightWallPos = BuildLogic.GetRightEndWallPosX(this.getStructureLengthInches());
        let pos = new Vector3(rightWallPos, this.getBackSideWallHeightInches() , -this.getStructureWidthInches()/2)
        return pos;
    }


    getWallFrontLeftBottom(){
        let leftWallPos = BuildLogic.GetLeftEndWallPosX( this.getStructureLengthInches());
        let pos = new Vector3(leftWallPos,0,this.getStructureWidthInches()/2)
        return pos;
    }

    getWallFrontLeftTop(){
        let leftWallPos = BuildLogic.GetLeftEndWallPosX( this.getStructureLengthInches());
        let pos = new Vector3(leftWallPos,this.getFrontSideWallHeightInches(),this.getStructureWidthInches()/2)
        return pos;
    }

    getWallFrontRightBottom(){
        let rightWallPos = BuildLogic.GetRightEndWallPosX(this.getStructureLengthInches());
        let pos = new Vector3(rightWallPos,0,this.getStructureWidthInches()/2)
        return pos;
    }

    getWallFrontRightTop(){
        let rightWallPos = BuildLogic.GetRightEndWallPosX(this.getStructureLengthInches());
        let pos = new Vector3(rightWallPos,this.getFrontSideWallHeightInches(),this.getStructureWidthInches()/2)
        return pos;
    }

    getRoofType(){
        return this.roofDesign.roofType;
    }

    getRoofRidgeHeight(){        
        return this.getStructureHeightInches() + BlueprintHelper.pitchHeight(this.getStructureWidthInches(),BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch),this.roofDesign.type);
    }

    getGableRidgeLeft(){
        let pos = new Vector3(-this.getStructureLengthInches()/2,this.getRoofRidgeHeight(),0);
        return pos;
    }

    getGableRidgeRight(){
        let pos = new Vector3(this.getStructureLengthInches()/2,this.getRoofRidgeHeight(),0);
        return pos;
    }


    GetPitchRatio(){
        return BlueprintHelper.pitchToPitchRatio(this.roofDesign.pitch);
    }

    GetPitchHeight(){        
        return BlueprintHelper.pitchHeight(this.getStructureWidthInches(), this.GetPitchRatio(), this.roofDesign.roofType)
    }

    GetBackEaveHeight(){
        return BlueprintHelper.height_BackEaveBottom(this.roofDesign.type, this.getStructureWidthInches(), this.getStructureHeightInches(), this.GetPitchRatio());        
    }

    GetFrontEaveHeight(){
        return BlueprintHelper.height_FrontEaveBottom(this.getStructureHeightInches(), this.GetPitchRatio());
    }

    


    buildDistanceMarkers(){
        // removeDistanceMarks undoes what's built here.        
        this.leftDistHori = new _3dDistHori(this.getWallBackLeftBottom(), this.getWallFrontLeftBottom(), new THREE.Vector3(-5,10,0),new THREE.Vector3(-60,0,0), 1/12, CORE.layers.dimensions);
        this.groupMetal.add(this.leftDistHori.group);
    }


    getBackBaySpaceDistanceMarker(endBay){
        if(endBay)
            return getBackEndBaySpaceDistanceMarker();
        else
            return getBackMidBaySpaceDistanceMarker();
    }

    getBackEndBaySpaceDistanceMarker(){

    }

    getBackMidBaySpaceDistanceMarker(){

    }

    getRightWallFrameLineIndexRtL(){
        return this.rightWallDesign.frameLine;
    }
    
    getRightWallFrameLineIndexLtR(){
        //Left end frameline is the first frameline (zero-based index of 0)
        return (this.design.frameLines.length-1)-this.rightWallDesign.frameLine;
    }

    getFirstFrameLineIndexRtL(){
        return 0;
    }
    getFirstFrameLineIndexLtR(){
        return 0;
    }

    buildGutterTrim(frame, trimMaterials){
        this.gutters = [];
        let offsetFromStructure = .25;
        let offsetFromBottom = 5;
        let eaveTrimHeight = 4; // pulls the gutter downspouts down to within the eave trim

        // these methods do two things:
        // - they build downspouts at the framelines, where appropriate.
        // - they compute segments of eave that need eave gutter

        // eave segments need to be moved to the roof
        // downpouts seem like territory for the structure, but the roof could build them just as well, if it had enough info
        
        // roof doesn't need to be overly complicated, worrying about other structures and porches. 
        // keep it deterministic and subordinate.. just tell it where to put eaves and downspouts, and to what height

        let {frontEaveSegments,frontDownspouts} = this.buildFrontGutterTrim(frame, trimMaterials, offsetFromStructure, offsetFromBottom, eaveTrimHeight);
        let {backEaveSegments, backDownspouts} = this.buildBackGutterTrim(frame, trimMaterials, offsetFromStructure, offsetFromBottom, eaveTrimHeight);
        return {frontEaveSegments, frontDownspouts, backEaveSegments, backDownspouts}
    }

    buildBackGutterTrimOld(frame, trimMaterials, offsetFromStructure, offsetFromBottom, eaveTrimHeight){
        let backDownspouts= [];
        
        // lean-to's back wall will always be mated to a main structure or another lean-to and never a porch
        // main structure's back wall might constrain a lean-to or a porch
        let bwSecondaryConstraint = [];
        let backZ = -this.getStructureWidthInches()/2;
        if(this.design.type === CORE.components.structure)
            bwSecondaryConstraint.push(...this.getBackPorchConstraints(),...this.getBackLeanToConstraints());
       
        else if(this.design.type === CORE.components.leanTo)
            bwSecondaryConstraint.push(ConstraintHelper.getConstraintWithChildId(this.masterDesign.constraintRootNode, this.design.id))

        let bswEaveHeight=this.getBackSideWallHeightInches();
        let halfLength = this.getStructureLengthInches()/2;
        let gabledRoof = (this.roofDesign.roofType === CORE.roof.types.gable);
        let gutterPositions = [];
        let gutterHeights = []        
        let backEaveSegments = [];
       
        if(bwSecondaryConstraint.length>0){
            if(this.design.type === CORE.components.leanTo)
                return {backEaveSegments, backDownspouts};

            // this is different than the FSW because a sloped roof slants down toward the front
            // and a porch/lean-to will never reach the primary structure's BSW peak to collide with the eave trim
            if(!gabledRoof && this.design.type !== CORE.components.leanTo){
                backEaveSegments = [{
                        start: new Vector3(-halfLength, bswEaveHeight, backZ),
                        end: new Vector3(halfLength, bswEaveHeight, backZ),
                        leftHoriAngle: Math.PI/4,
                        rightHoriAngle: Math.PI/4
                    }
                ]
                
            }else{                
                // there is a porch or a lean-to
                let bsp = this.masterDesign.getComponentDesignById(bwSecondaryConstraint[0].child.structureID);
                let porchLength = bsp.getFrame().length*12;
                let heightOffset = bsp.dim.heightOffset; // the height of the gutter downspouts
                let roofLeft = new Vector3(-halfLength, bswEaveHeight, backZ);
                let roofRight = new Vector3(halfLength, bswEaveHeight, backZ);
                let porchRight= new THREE.Vector3(roofRight.x-(bwSecondaryConstraint[0].parent.length*12), bswEaveHeight, backZ)
                let porchLeft= new THREE.Vector3(porchRight.x - porchLength, bswEaveHeight, backZ)

                // if the porch is full height, then there's no need for gutters at all
                if(heightOffset===0){
                    // we need to calculate the eave segments: either 0, 1, or 2 segments

                    //if the porch starts at the left-most frameline
                    if(roofLeft.x === porchLeft.x)
                    {
                        // if the porch doesn't end at the right-most frameline
                        if(porchRight.x !== roofRight.x)
                            // we need eave between porch right and right-most frameline
                            backEaveSegments.push({
                                start: porchRight,
                                end: roofRight,
                                leftHoriAngle: Math.PI/4,
                                rightHoriAngle: 0
                            });
                    }
                    else 
                    // if the porch ends at the right-most frameline
                    if(roofRight.x === porchRight.x)
                    {
                        // this porch does not start at the left-most frameline
                        backEaveSegments.push({
                            start: roofLeft,
                            end: porchLeft,
                            leftHoriAngle: 0,
                            rightHoriAngle: Math.PI/4
                        });
                    }
                    else
                    {// the porth starts and ends inbetween the left-most frameline and right-most frameline
                        
                        // left eave segments
                        backEaveSegments.push({
                            start: roofLeft,
                            end: porchLeft,
                            leftHoriAngle: 0,
                            rightHoriAngle: Math.PI/4
                        });
                        // right eave segments
                        backEaveSegments.push({
                            start: porchRight,
                            end: roofRight,
                            leftHoriAngle: Math.PI/4,
                            rightHoriAngle: 0
                        });
                    }

                    if(gabledRoof)
                        // set gutter positions and heights
                        this.design.frameLines.forEach((fl, i)=>{
                            let posX = BlueprintHelper.getFramelinePositionXByIndex(this.getStructureLengthInches()/12,this.design.frameLines,i,this.design.isPorch)
                            // put a gutter at each frameline
                            //gutterPositions.push(posX);
                            // if there is no porch at this frameline
                            if(posX<porchLeft.x || posX>porchRight.x){                        
                                // put a gutter here
                                gutterPositions.push(posX);
                                // make the gutter the full height of the front wall
                                gutterHeights.push(bswEaveHeight-offsetFromBottom);
                            }
                        });
                    
                }
                else { // the porch is not full height, so we need some gutter

                    // use one continuous eave trim across the front
                    backEaveSegments = [{
                        start:  this.getWallBackLeftTop(),
                        end: this.getWallBackRightTop(),
                        leftHoriAngle: Math.PI/4,
                        rightHoriAngle: Math.PI/4
                        }
                    ];

                    if(gabledRoof)
                        // set gutter positions and heights
                        this.design.frameLines.forEach((fl, i)=>{ 
                        let posX = BlueprintHelper.getFramelinePositionXByIndex(this.getStructureLengthInches()/12,this.design.frameLines,i,this.design.isPorch)
                        // put a gutter at each frameline
                        gutterPositions.push(posX);
                        // if there is no porch at this frameline
                        if(posX<porchLeft.x || posX>porchRight.x)
                            gutterHeights.push(bswEaveHeight-offsetFromBottom); // make the gutter the full height of the front wall
                        else
                            gutterHeights.push(heightOffset-8); // make the gutter only as tall as the porch offsetHeight
                    });
                }
            }
        }
        else 
        {   // there is no porch
            // use one continuous eave trim 
            backEaveSegments = [{
                start:  new Vector3(-halfLength, this.GetBackEaveHeight(), backZ),//this.getWallFrontLeftTop(),
                end: new Vector3(halfLength, this.GetBackEaveHeight(), backZ),//this.getWallFrontRightTop(),
                leftHoriAngle: Math.PI/4,
                rightHoriAngle: Math.PI/4
                }
            ]

            if(gabledRoof){
                // set gutter positions and heights            
                gutterPositions = this.design.frameLines.map((fl,i)=> 
                BlueprintHelper.getFramelinePositionXByIndex(this.getStructureLengthInches()/12,this.design.frameLines,i,this.design.isPorch)
                ); // gutter at every frameline
                gutterHeights = frame.frameLines.map((fl)=>bswEaveHeight-offsetFromBottom); // full height at every frameline
            }
        }        
        
        
        if(this.optionDesign.gutters===true){
            gutterPositions.forEach((posX,i)=>{   
                if(gutterHeights.length<=i)
                    return;             
                //let g = new _3dTrimGutterDownspout(gutterHeights[i], trimMaterials.downspout);
                //this.groupMetal.add(g.group);
                //let pos = new THREE.Vector3(posX, this.getWallBackLeftTop().y-eaveTrimHeight, backZ-offsetFromStructure)
                //g.group.position.set(pos.x,pos.y,pos.z);
                //g.group.rotation.y = Math.PI;
                //layerHelper.enableLayer(g.group, CORE.layers.quote);
                //this.gutters.push(g);

                /* each downspout has
                position
                downspout length (a height)
                downspout trimMaterial
                //probably not rotation
                */

                backDownspouts.push({
                    x: posX,
                    length: gutterHeights[i],
                })



            });
        }
        
        return {backEaveSegments, backDownspouts};
    }

    buildBackGutterTrim(frame, trimMaterials, offsetFromStructure, offsetFromBottom, eaveTrimHeight){        
        // lean-to's back wall will always be mated to a main structure or another lean-to and never a porch
        // main structure's back wall can constrain a lean-to, porch, single-slope main, or a gable tie in.
        let bswConstraints;
        if(this.design.type === CORE.components.structure)
            bswConstraints = ConstraintHelper.getConstraintsOnSide(this.masterDesign, this.design, CORE.sides.backSide);

        let bswEaveHeight=this.getBackSideWallHeightInches();
        let backZ = -this.getStructureWidthInches()/2;
        let halfLength = this.getStructureLengthInches()/2;
        let isGabledRoof = (this.roofDesign.roofType === CORE.roof.types.gable);
        let gutterPositions = [];
        let gutterHeights = []        
        let backEaveSegments = [];
        let backDownspouts= [];

        let roofLeft = new Vector3(-halfLength, bswEaveHeight, backZ);
        let roofRight = new Vector3(halfLength, bswEaveHeight, backZ);

        if(this.design.type === CORE.components.leanTo || this.design.type === CORE.components.porch)
            return {backEaveSegments, backDownspouts};
       
        if(bswConstraints.length>0){
            let childrenConstraintDetails = [];
            bswConstraints.forEach(c =>{
                // there is a back porch, lean-to, or main structure on the back of "this" structure.
                // at this point, parent and child have no meaning. the constraint simply provides which buildings are involved.
                // ref prefix will indicate the structure mated onto "this" structure
                let ref;
                let refMatingSide;
                let childRightX;
                if(this.design.id === c.child.structureID){
                    // get the building mated to "this"
                    ref = this.masterDesign.getComponentDesignById(c.parent.structureID);
                    refMatingSide = c.parent.matingSide;

                    // calculate the appropriate ref right starting x pos (child left is neg x and child right is pos x)
                    if(refMatingSide === CORE.sides.leftEnd || refMatingSide === CORE.sides.rightEnd)
                        childRightX = roofRight.x - (this.design.getFrame().length - (ref.getFrame().width + -(c.parent.length)))*12
                    else {
                        childRightX = roofRight.x - (this.design.getFrame().length - (ref.getFrame().length + -(c.parent.length)))*12
                    }
                }
                else{
                    // get the building mated to "this"
                    ref = this.masterDesign.getComponentDesignById(c.child.structureID);
                    refMatingSide = c.child.matingSide
                    childRightX = roofRight.x - (c.parent.length*12)
                }

                // meta data about the ref
                let refStructureLength = ref.getFrame().length*12;
                let isSecondaryStructure = (ref.type === CORE.components.porch || ref.type === CORE.components.leanTo)

                // height = eave height for secondary structures // height = peak height for primary structures
                let refHeight = isSecondaryStructure ? ref.dim.heightOffset : (BlueprintHelper.pitchHeight(ref.getFrame().width,BlueprintHelper.pitchToPitchRatio(ref.getRoof().pitch),ref.getRoof().roofType) + ref.getFrame().height)*12
                let bswHeight = BlueprintHelper.getBackSideWallHeightInches(ref.getRoof(), ref.getFrame());
                let isBelowParentEave;
                
                // determine if ref is below or above "this" eave height
                if(isGabledRoof){
                    if(isSecondaryStructure)
                        isBelowParentEave = refHeight > 0
                    else{
                        if(refMatingSide === CORE.sides.leftEnd || refMatingSide === CORE.sides.rightEnd)
                            isBelowParentEave = refHeight < bswEaveHeight
                        else{
                            isBelowParentEave = bswHeight < bswEaveHeight
                        }
                    }
                }
                else{
                    if(isSecondaryStructure)
                        isBelowParentEave = bswHeight < bswEaveHeight
                    else
                        isBelowParentEave = refHeight < bswEaveHeight
                }

                let childRight = new THREE.Vector3(childRightX, bswEaveHeight, backZ)
                let childLeft;
                if(isSecondaryStructure){
                    childLeft = new THREE.Vector3(childRight.x - refStructureLength, bswEaveHeight, backZ)
                }
                else{
                    if(refMatingSide === CORE.sides.leftEnd || refMatingSide === CORE.sides.rightEnd){
                        childLeft = new THREE.Vector3(childRight.x - ref.getFrame().width*12, bswEaveHeight, backZ)
                    }
                    else
                        childLeft = new THREE.Vector3(childRight.x - refStructureLength, bswEaveHeight, backZ)
                }

                childrenConstraintDetails.push({isBelowParentEave: isBelowParentEave, bswHeight: bswHeight, height: refHeight, childLeft: childLeft, childRight: childRight, isSecondaryStructure: isSecondaryStructure})
            })

            // sort the constraints on the bsw from left to right 
            childrenConstraintDetails.sort((a, b) => (a.childRight.x > b.childRight.x ? 1 : b.childRight.x > a.childRight.x ? -1 : 0))

            
 
            for(let [index, d] of childrenConstraintDetails.entries()){
                if(index == 0){
                    // special case for the left most child
                    if(roofLeft.x === d.childLeft.x){
                        // child starts at the left-most frameline
                        let start;
                        let end;

                        if(d.isBelowParentEave){
                            start = roofLeft
                            if(childrenConstraintDetails.length > 1){
                                // there is another child structure to the right of the current child.
                                // When this child is below the parent's eave, we do not care if the next child is immediately up against it.
                                end = childrenConstraintDetails[index + 1].childLeft
                            }
                            else
                                end = roofRight;
                        }
                        else{
                            start = d.childRight;
                            if(childrenConstraintDetails.length > 1){
                                // there is another child structure to the right of the current child, but make sure it's not immediately up against this child.
                                if(d.childRight.x != childrenConstraintDetails[index + 1].childLeft.x)
                                    end = childrenConstraintDetails[index + 1].childLeft
                            }
                            else if(d.childRight.x < roofRight.x){
                                // only if this child's right side does not go past the parent's right side can we set the segment's end.
                                end = roofRight;
                            }
                        }
                        
                        if(Util.isDefined(end)){
                            // right eave segment
                            backEaveSegments.push({
                                start: start,
                                end: end,
                                leftHoriAngle: 0,
                                rightHoriAngle: Math.PI/4
                            });
                        }
                    }
                    else{
                         // child does not start at the left-most frameline
                         let leftEaveStart;
                         let leftEaveEnd;
                         let rightEaveStart;
                         let rightEaveEnd;
 
                         if(d.isBelowParentEave){
                            leftEaveStart = roofLeft;
                             if(childrenConstraintDetails.length > 1){
                                // there is another child structure to the right of the current child.
                                // When this child is below the parent's eave, we do not care if the next child is immediately up against it.
                                leftEaveEnd = childrenConstraintDetails[index + 1].childLeft;
                             }
                             else
                                 leftEaveEnd = roofRight;
                         }
                         else{
                            if(d.childLeft.x < roofLeft.x){
                                // if this child's left goes past the parent's left...
                                leftEaveStart = d.childRight;
                            }
                            else{ // facts: if we enter this else, the child's left must be containd by the parent
                                leftEaveStart = roofLeft;

                                // only if the child's left is contained by the parent do we need a left eave segment end
                                leftEaveEnd = d.childLeft
                            }

                            rightEaveStart = d.childRight
                            if(childrenConstraintDetails.length > 1){
                                // there is another child structure to the right of the current child, but make sure it's not immediately up against this child.
                                if(d.childRight.x != childrenConstraintDetails[index + 1].childLeft.x)
                                    rightEaveEnd = childrenConstraintDetails[index + 1].childLeft
                            }
                            else if(d.childRight.x < roofRight.x){
                                // only if this child's right side does not go past the parent's right side can we set the segment's end.
                                rightEaveEnd = roofRight;
                            }
                         }
 
 
                         // left eave segment
                         if(Util.isDefined(leftEaveEnd)){
                            backEaveSegments.push({
                                start: leftEaveStart,
                                end: leftEaveEnd,
                                leftHoriAngle: 0,
                                rightHoriAngle: Math.PI/4
                            });
                            }
 
                         if(Util.isDefined(rightEaveEnd)){
                             // right eave segment
                             backEaveSegments.push({
                                 start: rightEaveStart,
                                 end: rightEaveEnd,
                                 leftHoriAngle: 0,
                                 rightHoriAngle: Math.PI/4
                             });
                         }
                         
                     }
                }
                else{
                     // after the first child, we only handle right eave segments


                    let start;
                    let end;

                    if(d.isBelowParentEave){
                        start = d.childLeft
                        if(index + 1 < childrenConstraintDetails.length){
                            // there is another child structure to the right of the current child.
                            // When this child is below the parent's eave, we do not care if the next child is immediately up against it.
                            end = childrenConstraintDetails[index + 1].childLeft
                        }
                        else
                            end = roofRight
                    }
                    else{
                        start = d.childRight  
                        if(index + 1 < childrenConstraintDetails.length){
                            // there is another child structure to the right of the current child, but make sure it's not immediately up against this child.
                            if(d.childRight.x != childrenConstraintDetails[index + 1].childLeft.x)
                                end = childrenConstraintDetails[index + 1].childLeft
                        }
                        else if(d.childRight.x < roofRight.x){
                            // only if this child's right side does not go past the parent's right side can we set the segment's end.
                            end = roofRight;
                        }
                    }

                    if(Util.isDefined(end)){
                        // right eave segments
                        backEaveSegments.push({
                            start: start,
                            end: end,
                            leftHoriAngle: 0,
                            rightHoriAngle: Math.PI/4
                        });
                    }
                }
            }

            // set gutter positions and heights
            this.design.frameLines.forEach((fl, i)=>{            
                let posX = BlueprintHelper.getFramelinePositionXByIndex(this.getStructureLengthInches()/12,this.design.frameLines,i,this.design.isPorch)
                let isAnyStructureAtFrameLine = false;
                let structureAtFrameLine;
                childrenConstraintDetails.forEach(d =>{
                    // loop through each constraint and determine if it is on one of its parent's framelines
                    let tempPosX = posX;
                    if(i == 0)
                        tempPosX -= 4;
                    if(i == this.design.frameLines.length - 1)
                        tempPosX += 4;
                    let epsilon = 0.5;
                    //if there is a porch at this frameline
                    if(tempPosX >= (d.childLeft.x - epsilon) && tempPosX <= (d.childRight.x + epsilon)){
                        isAnyStructureAtFrameLine = true;  
                        structureAtFrameLine = d
                    }
                })

                if(isAnyStructureAtFrameLine){
                    let gutterHeight;

                    if(structureAtFrameLine.isBelowParentEave){
                        if(structureAtFrameLine.isSecondaryStructure){
                            if(structureAtFrameLine.height > 0)
                                gutterHeight = structureAtFrameLine.height - 8
                            else{
                                if(!isGabledRoof){
                                    gutterHeight = bswEaveHeight - (structureAtFrameLine.bswHeight - structureAtFrameLine.height)
                                }
                                    
                            }
                        }
                        else
                            gutterHeight = bswEaveHeight - structureAtFrameLine.height // make the gutter as tall as the space between the peak of the child and eave of the parent

                        if(Util.isDefined(gutterHeight)){
                            // put a gutter here
                            gutterPositions.push(posX);
                            gutterHeights.push(gutterHeight);
                        }
                    }
                
                }
                else{
                    // put a gutter here
                    gutterPositions.push(posX);

                    // make the gutter the full height of the front wall
                    gutterHeights.push(bswEaveHeight - offsetFromBottom);
                }
            })
            
        }
        else 
        {   // there is no porch
            // use one continuous eave trim 
            backEaveSegments = [{
                start:  new Vector3(-halfLength, this.GetBackEaveHeight(), backZ),//this.getWallFrontLeftTop(),
                end: new Vector3(halfLength, this.GetBackEaveHeight(), backZ),//this.getWallFrontRightTop(),
                leftHoriAngle: Math.PI/4,
                rightHoriAngle: Math.PI/4
                }
            ]

            if(isGabledRoof){
                // set gutter positions and heights            
                gutterPositions = this.design.frameLines.map((fl,i)=> 
                BlueprintHelper.getFramelinePositionXByIndex(this.getStructureLengthInches()/12,this.design.frameLines,i,this.design.isPorch)
                ); // gutter at every frameline
                gutterHeights = frame.frameLines.map((fl)=>bswEaveHeight-offsetFromBottom); // full height at every frameline
            }
        }        
        
        
        if(this.optionDesign.gutters===true){
            gutterPositions.forEach((posX,i)=>{   
                if(gutterHeights.length<=i)
                    return;             
                //let g = new _3dTrimGutterDownspout(gutterHeights[i], trimMaterials.downspout);
                //this.groupMetal.add(g.group);
                //let pos = new THREE.Vector3(posX, this.getWallBackLeftTop().y-eaveTrimHeight, backZ-offsetFromStructure)
                //g.group.position.set(pos.x,pos.y,pos.z);
                //g.group.rotation.y = Math.PI;
                //layerHelper.enableLayer(g.group, CORE.layers.quote);
                //this.gutters.push(g);

                /* each downspout has
                position
                downspout length (a height)
                downspout trimMaterial
                //probably not rotation
                */

                backDownspouts.push({
                    x: posX,
                    length: gutterHeights[i],
                })



            });
        }
        
        return {backEaveSegments, backDownspouts};
    }

    buildFrontGutterTrim(frame, trimMaterials, offsetFromStructure, offsetFromBottom, eaveTrimHeight){
        // gutters go on front (slope) or front and back (gable).
        // front gutters (always)
        let frontZ = this.getStructureWidthInches()/2;
        let fswConstraints = ConstraintHelper.getConstraintsOnSide(this.masterDesign, this.design, CORE.sides.frontSide)
        let gutterPositions = [];
        let gutterHeights = []        
        let frontEaveSegments = [];
        let frontDownspouts = [];
       
       let fswEaveHeight=this.getFrontSideWallHeightInches();
       let fswZ = this.getStructureWidthInches()/2;
       let roofLeft = new Vector3(-this.getStructureLengthInches()/2, fswEaveHeight, fswZ);//fspConstraint.parent.length
       let roofRight = new Vector3(this.getStructureLengthInches()/2, fswEaveHeight, fswZ);//fspConstraint.parent.length

        if(fswConstraints.length>0){
            let childrenConstraintDetails = [];
            fswConstraints.forEach(c =>{
                // there is a front porch, lean-to, or main structure on the front of "this" structure.
                // at this point, parent and child have no meaning. the constraint simply provides which buildings are involved.
                // ref prefix will indicate the structure mated onto "this" structure
                let ref;
                let refMatingSide;
                if(this.design.id === c.child.structureID){
                    ref = this.masterDesign.getComponentDesignById(c.parent.structureID);
                    refMatingSide = c.parent.matingSide;
                }
                else{
                    ref = this.masterDesign.getComponentDesignById(c.child.structureID);
                    refMatingSide = c.child.matingSide
                }
                let refStructureLength = ref.getFrame().length*12;
                let isSecondaryStructure = (ref.type === CORE.components.porch || ref.type === CORE.components.leanTo)

                // height = height offset for secondary structures // height = peak height for primary structures
                let refHeight = isSecondaryStructure ? ref.dim.heightOffset : (BlueprintHelper.pitchHeight(ref.getFrame().width,BlueprintHelper.pitchToPitchRatio(ref.getRoof().pitch),ref.getRoof().roofType) + ref.getFrame().height)*12
                let bswHeight = BlueprintHelper.getBackSideWallHeightInches(ref.getRoof(), ref.getFrame());
                let isBelowParentEave;

                // determine if ref is below or above "this" eave height
                if(isSecondaryStructure)
                    isBelowParentEave = refHeight > 0
                else{
                    if(refMatingSide === CORE.sides.leftEnd || refMatingSide === CORE.sides.rightEnd)
                        isBelowParentEave = refHeight < fswEaveHeight
                    else{
                        isBelowParentEave = bswHeight < fswEaveHeight
                    }
                }

                let childLeft = new THREE.Vector3(roofLeft.x+(this.getMatingSpecs(c).constrainerX), fswEaveHeight, fswZ)
                let childRight;
                if(isSecondaryStructure){
                    childRight = new THREE.Vector3(childLeft.x+ refStructureLength, fswEaveHeight, fswZ)
                }
                else{
                    if(refMatingSide === CORE.sides.leftEnd || refMatingSide === CORE.sides.rightEnd){
                        childRight = new THREE.Vector3(childLeft.x+ ref.getFrame().width*12, fswEaveHeight, fswZ)
                    }
                    else
                        childRight = new THREE.Vector3(childLeft.x+ refStructureLength, fswEaveHeight, fswZ)
                }

                childrenConstraintDetails.push({isBelowParentEave: isBelowParentEave, height: refHeight, childLeft: childLeft, childRight: childRight, isSecondaryStructure: isSecondaryStructure})
            })

            // sort the constraints on the fsw from roofLeft to roofRight
            childrenConstraintDetails.sort((a, b) => (a.childLeft.x > b.childLeft.x ? 1 : b.childLeft.x > a.childLeft.x ? -1 : 0))
            
            for(let [index, d] of childrenConstraintDetails.entries()){
                if(index == 0){
                    // special case for the left most child
                    if(roofLeft.x === d.childLeft.x){
                        // child starts at the left-most frameline
                        let start;
                        let end;

                        if(d.isBelowParentEave){
                            start = roofLeft
                            if(childrenConstraintDetails.length > 1){
                                // there is another child structure to the right of the current child.
                                // When this child is below the parent's eave, we do not care if the next child is immediately up against it.
                                end = childrenConstraintDetails[index + 1].childLeft
                            }
                            else
                                end = roofRight;
                        }
                        else{
                            start = d.childRight;
                            if(childrenConstraintDetails.length > 1){
                                // there is another child structure to the right of the current child, but make sure it's not immediately up against this child.
                                if(d.childRight.x != childrenConstraintDetails[index + 1].childLeft.x)
                                    end = childrenConstraintDetails[index + 1].childLeft
                            }
                            else if(d.childRight.x != roofRight.x){
                                 // only if this child's right side does not go past the parent's right side can we set the segment's end.
                                end = roofRight;
                            }
                        }
                        
                        if(Util.isDefined(end)){
                            // right eave segment
                            frontEaveSegments.push({
                                start: start,
                                end: end,
                                leftHoriAngle: 0,
                                rightHoriAngle: Math.PI/4
                            });
                        }
                    }
                    else{
                        // child does not start at the left-most frameline
                        let leftEaveStart;
                        let leftEaveEnd;
                        let rightEaveStart;
                        let rightEaveEnd;

                        if(d.isBelowParentEave){
                            leftEaveStart = roofLeft;
                            if(childrenConstraintDetails.length > 1){
                                // there is another child structure to the right of the current child.
                                // When this child is below the parent's eave, we do not care if the next child is immediately up against it.
                                leftEaveEnd = childrenConstraintDetails[index + 1].childLeft
                            }
                            else
                                leftEaveEnd = roofRight
                        }
                        else{
                            if(d.childLeft.x < roofLeft.x){
                                // if this child's left goes past the parent's left...
                                leftEaveStart = d.childRight;
                            }
                            else{ // facts: if we enter this else, the child's left must be containd by the parent
                                leftEaveStart = roofLeft;

                                // only if the child's left is contained by the parent do we need a left eave segment end
                                leftEaveEnd = d.childLeft
                            }

                            rightEaveStart = d.childRight
                            if(childrenConstraintDetails.length > 1){
                                // there is another child structure to the right of the current child, but make sure it's not immediately up against this child.
                                if(d.childRight.x != childrenConstraintDetails[index + 1].childLeft.x)
                                    rightEaveEnd = childrenConstraintDetails[index + 1].childLeft
                            }
                            else if(d.childRight.x < roofRight.x){
                                // only if this child's right side does not go past the parent's right side can we set the segment's end.
                                rightEaveEnd = roofRight;
                            }
                                
                        }

                        // left eave segment
                        if(Util.isDefined(leftEaveEnd))
                        frontEaveSegments.push({
                            start: leftEaveStart,
                            end: leftEaveEnd,
                            leftHoriAngle: 0,
                            rightHoriAngle: Math.PI/4
                        });

                        if(Util.isDefined(rightEaveEnd)){
                            // right eave segment
                            frontEaveSegments.push({
                                start: rightEaveStart,
                                end: rightEaveEnd,
                                leftHoriAngle: 0,
                                rightHoriAngle: Math.PI/4
                            });
                        }
                        
                    }
                }
                else{
                    // after the first child, we only handle right eave segments

                    let start;
                    let end;

                    if(d.isBelowParentEave){
                        start = d.childLeft
                        if(index + 1 < childrenConstraintDetails.length){
                            // there is another child structure to the right of the current child.
                            // When this child is below the parent's eave, we do not care if the next child is immediately up against it.
                            end = childrenConstraintDetails[index + 1].childLeft
                        }
                        else
                            end = roofRight
                    }
                    else{
                        start = d.childRight  
                        if(index + 1 < childrenConstraintDetails.length){
                            // there is another child structure to the right of the current child, but make sure it's not immediately up against this child.
                            if(d.childRight.x != childrenConstraintDetails[index + 1].childLeft.x)
                                end = childrenConstraintDetails[index + 1].childLeft
                        }
                        else if(d.childRight.x < roofRight.x)
                            end = roofRight;
                    }

                    if(Util.isDefined(end)){
                        // right eave segments
                        frontEaveSegments.push({
                            start: start,
                            end: end,
                            leftHoriAngle: 0,
                            rightHoriAngle: Math.PI/4
                        });
                    }
                }
            }
            
            // set gutter positions and heights
            this.design.frameLines.forEach((fl, i)=>{            
                let posX = BlueprintHelper.getFramelinePositionXByIndex(this.getStructureLengthInches()/12,this.design.frameLines,i,this.design.isPorch)
                let isAnyStructureAtFrameLine = false;
                let structureAtFrameLine;
                childrenConstraintDetails.forEach(d =>{
                    // loop through each constraint and determine if it is on one of its parent's framelines
                    let tempPosX = posX;
                    if(i == 0)
                        tempPosX -= 4;
                    if(i == this.design.frameLines.length - 1)
                        tempPosX += 4;

                    let epsilon = 0.5;
                    //if there is a porch at this frameline
                    if(tempPosX >= (d.childLeft.x - epsilon) && tempPosX <= (d.childRight.x + epsilon)){
                        isAnyStructureAtFrameLine = true;  
                        structureAtFrameLine = d
                    }
                })

                if(isAnyStructureAtFrameLine){
                    if(structureAtFrameLine.isBelowParentEave){
                        let gutterHeight;
                        if(structureAtFrameLine.isSecondaryStructure)
                            gutterHeight = structureAtFrameLine.height - 8 // make the gutter only as tall as the porch offsetHeight
                        else
                            gutterHeight = fswEaveHeight - structureAtFrameLine.height;

                        // put a gutter here
                        gutterPositions.push(posX);
                        gutterHeights.push(gutterHeight);
                    }
                }
                else{
                    // put a gutter here
                    gutterPositions.push(posX);

                    // make the gutter the full height of the front wall
                    gutterHeights.push(fswEaveHeight - offsetFromBottom);
                }
            })
        }
        else {
            // there is no front porch
            // use one continuous eave trim across the front
            frontEaveSegments = [{
                start:  new Vector3(-this.getStructureLengthInches()/2, this.GetFrontEaveHeight(), fswZ),//this.getWallFrontLeftTop(),
                end: new Vector3(this.getStructureLengthInches()/2, this.GetFrontEaveHeight(), fswZ),//this.getWallFrontRightTop(),
                leftHoriAngle: Math.PI/4,
                rightHoriAngle: Math.PI/4
                }
            ]

            // set gutter positions and heights            
            gutterPositions = frame.frameLines.map((fl,i)=>
                BlueprintHelper.getFramelinePositionXByIndex(this.getStructureLengthInches()/12,this.design.frameLines,i,this.design.isPorch)
            ); // gutter at every frameline
            gutterHeights = gutterPositions.map((gp)=>fswEaveHeight-offsetFromBottom); // full height at every frameline
        }
        
        if( this.optionDesign.gutters===true ){
            gutterPositions.forEach((posX,i)=>{            
                if(gutterHeights.length<=i)
                    return;
                //let g = new _3dTrimGutterDownspout(gutterHeights[i], trimMaterials.downspout);
                //this.groupMetal.add(g.group);
                //let pos = new THREE.Vector3(posX, fswEaveHeight-eaveTrimHeight, frontZ+offsetFromStructure)
                //g.group.position.set(pos.x,pos.y,pos.z);
                //layerHelper.enableLayer(g.group, CORE.layers.quote);
                //this.gutters.push(g);
                frontDownspouts.push({
                    x: posX,
                    length: gutterHeights[i],
                })
            });
        }
        return {frontEaveSegments,frontDownspouts};
    }

    getEndWallGirtHeights(heights,maxGirtHeight){
        let girtHeights = Array.from(heights);
        let currentGirtHeight = girtHeights[girtHeights.length-1];        
        currentGirtHeight+= this.design.girts.maxSpacing;
        while(currentGirtHeight < maxGirtHeight){
            girtHeights.push(currentGirtHeight);
            currentGirtHeight+= this.design.girts.maxSpacing;
        }
        return girtHeights;
    }

    getSideWallGirtHeights(heights, sideHeight){
        let girtHeights = Array.from(heights);
        let currentGirtHeight = girtHeights[girtHeights.length-1];
        let lastHeight = currentGirtHeight     
        currentGirtHeight+= this.design.girts.maxSpacing;
        
        let done = false;        
        while(!done){            
            // if the girt is within 2 feet of the top of the front wall's height
            if(currentGirtHeight > sideHeight-24){
                // split the difference between the previous girt and the top of the wall 
                let newSpace = (sideHeight - lastHeight) / 2;
                // if that new spacing is less than half of the max-spacing setting,
                if(newSpace < this.design.girts.maxSpacing / 2){
                    // don't bother squeezing a girt into this vertical space
                    done=true;
                    continue;
                }
                currentGirtHeight= lastHeight + newSpace;
            }
            girtHeights.push(currentGirtHeight);
            
            lastHeight = currentGirtHeight; // track the last girt height            
            currentGirtHeight+= this.design.girts.maxSpacing; // move to the next increment
            // if the girt is above the wall,
            if(currentGirtHeight >= sideHeight)
                // no more girts are needed
                done=true;
        }

        return girtHeights;
    }

    getFrontPorchConstraints(){
        return ConstraintHelper.getSecondaryStructuresAttachedToStructureSide(CORE.components.porch, this.masterDesign, this.design, CORE.sides.frontSide);        
    }

    getBackPorchConstraints(){
        return ConstraintHelper.getSecondaryStructuresAttachedToStructureSide(CORE.components.porch, this.masterDesign, this.design, CORE.sides.backSide);        
    }
    
    getLeftPorchConstraints(){
        return ConstraintHelper.getSecondaryStructuresAttachedToStructureSide(CORE.components.porch, this.masterDesign, this.design, CORE.sides.leftEnd);       
    }
    
    getRightPorchConstraints(){
        return ConstraintHelper.getSecondaryStructuresAttachedToStructureSide(CORE.components.porch, this.masterDesign, this.design, CORE.sides.rightEnd);
    }

    getLeanToFrameLines(design){        

        let frontLeanToConstraints = this.getFrontLeanToConstraints();
        let backLeanToConstraints = this.getBackLeanToConstraints();
        let leftLeanToConstraints = this.getLeftLeanToConstraints();
        let rightLeanToConstraints = this.getRightLeanToConstraints();

        let indices=[];
        // make a list of all the frame line indices that must support porches
        for(var fli = 0;fli<design.frameLines.length;fli++){
            let framePosition = design.frameLines[fli];
            if(fli===0 && leftLeanToConstraints.length>0)
                indices.push(fli);
            else //only push an index once
            if(fli===design.frameLines.length-1 && rightLeanToConstraints.length>0)
                indices.push(fli);
            else //only push an index once
            if(frontLeanToConstraints.length>0){                
                frontLeanToConstraints.forEach((c)=>{
                    let d = this.masterDesign.getComponentDesignById(c.child.structureID);
                    if(d==null)
                        return;
                    let frame = d.getFrame();                    
                    let frameLength = frame.length;
                    if(framePosition >= c.parent.length*12 && framePosition < (c.parent.length + frameLength)*12)
                        indices.push(fli);
                });
            }
            else //only push an index once
            if(backLeanToConstraints.length>0){
                backLeanToConstraints.forEach((c)=>{
                    let d = this.masterDesign.getComponentDesignById(c.child.structureID);
                    if(d==null)
                        return;
                    let frame = d.getFrame();                    
                    let frameLength = frame.length;
                    if(framePosition >= c.parent.length*12 && framePosition < (c.parent.length + frameLength)*12)
                        indices.push(fli);
                });
            }
        }

        return indices;
    }

    getFrontLeanToConstraints(){
        return ConstraintHelper.getSecondaryStructuresAttachedToStructureSide(CORE.components.leanTo, this.masterDesign, this.design, CORE.sides.frontSide);        
    }

    getBackLeanToConstraints(){
        return ConstraintHelper.getSecondaryStructuresAttachedToStructureSide(CORE.components.leanTo, this.masterDesign, this.design, CORE.sides.backSide);        
    }
    
    getLeftLeanToConstraints(){
        return ConstraintHelper.getSecondaryStructuresAttachedToStructureSide(CORE.components.leanTo, this.masterDesign, this.design, CORE.sides.leftEnd);       
    }
    
    getRightLeanToConstraints(){
        return ConstraintHelper.getSecondaryStructuresAttachedToStructureSide(CORE.components.leanTo, this.masterDesign, this.design, CORE.sides.rightEnd);
    }

    

    getLeftOpenBays(){
        let bays = [];
        
        for(var b = 0;b<this.leftWallDesign.frameLine;b++){
            let start = this.design.frameLines[b];
            let end = this.design.frameLines[b+1];
            bays.push(
                {
                    index:b,
                    numRelative:bays.length+1,
                    length:end-start
                }                
            );
            start==end; // TODO: Remove
        }
        return bays;
    }

    getRightOpenBays(){
        let bays = [];

        for(var b = this.design.frameLines.length-1;b>this.getRightWallFrameLineIndexLtR();b--) {
            let start  =  this.design.frameLines[b];
            let end = this.design.frameLines[b-1];
            bays.push(
                {                    
                    index:b,
                    numRelative:bays.length+1,
                    length:start-end
                }
            );
            start==end;
        }
        return bays;
    }

    removeRoof(){

        //if(!this.frameDesign.isPorch)
            //console.log('Structure Main removeRoof', this.roof.this.group.uuid);
        this.roof.remove();
    }
    removeFoundation(){
        this.foundation.remove();
    }
    removeWalls(){
        if(this.wallBack)
            this.wallBack.remove();
        
        this.wallFront.remove();
        this.wallLeft.remove();
        this.wallRight.remove();
    }
    removeFrameSides(){
        this.sideFront.remove();
        this.sideBack.remove();
        this.sideLeft.remove();
        this.sideRight.remove();
    }
    removeFrameBase(){
        this.frame.remove();
    }
    removeCornerTrim(){
        
        if(this.trimFL)
            this.trimFL.remove()
        if(this.trimBL)
            this.trimBL.remove()
        if(this.trimFR)
            this.trimFR.remove()
        if(this.trimBR)
            this.trimBR.remove()
    }
    removeDownspouts(){
        if(this.gutters)
            this.gutters.forEach((g)=>{
            g.remove();
        })
    }
    removeEndSkirts(){
        if(this.leftEndSkirt)
            this.leftEndSkirt.remove();
        if(this.rightEndSkirt)
            this.rightEndSkirt.remove();
    }
    
updateComponents(){
    this.components= []; // forget previous components
    // register these components so that sub-components and components they contain can be found
    this.addComponent(this.foundation);
    this.addComponent(this.frame);
    this.addComponent(this.roof);
    this.addComponent(this.appearance);
    this.addComponent(this.options);
    this.addComponent(this.jobSpecs);
    /*this.addComponent(this.sideFront);
    this.addComponent(this.sideBack);
    this.addComponent(this.sideLeft);
    this.addComponent(this.sideRight);*/        
    this.addComponent(this.wallFront);
    this.addComponent(this.wallRight);
    this.addComponent(this.wallBack);
    this.addComponent(this.wallLeft);

    this.partitions.forEach((p) => this.addComponent(p));

    if(this.leftEndSkirt)
        this.addComponent(this.leftEndSkirt)

    if(this.rightEndSkirt)
        this.addComponent(this.rightEndSkirt)
}
    
    create(){
        this.build();
        this.updateComponents()
        this.buildComponents();
    }

    
    destroy(){
        super.destroy();
        this.remove(true,true,true)
    }

    build(){
        // update constraints
        this.updateConstraineeContraints();
        // build builds everything from scratch
        this.buildStaticPart1()
        this.buildDynamicOnly();
        this.buildStaticPart2();        
        this.buildPickOnly();
        this.updateComponents();
        // when a wall changes, it doesn't affect the blueprint.
        // when the roof changes, it affects the blueprint, which isn't re-built here under the roof's parent component.
        // Options:
        //  - options can indicate the blueprint must be updated, not just the parent. much easier and less work
        //  - support recalculating only part of the blueprint, at the necessary level. might come in handy in the long run
        //
    }



    remove(d=true,s=true,p=true){
        //console.log('structure remove()')

        //if(!this.frameDesign.isPorch)
            //console.log('Structure Main remove()', this.roof.this.group.uuid);
        
        if(d){
            this.destroyDynamicGroup();
        }
        if(s){
            this.destroyStaticGroup();
            this.removeWalls();
            this.removeFoundation();
            
            this.removeDistanceMarkers();

            this.removeCornerTrim();

            this.removeDownspouts();
            
        }

        if(p)
        {
            
            this.destroyPickGroup();
        }

        //this.components.forEach((f)=>{
            //f.remove();            
        //})
        
        super.remove();

        //MemHelper.removeAllChildren(this.group);
        //this.group.remove(this.collisionMesh); // this is only built when the whole barn is built
    }

    removeDistanceMarkers(){

        if(this.frontDistHori)
            this.frontDistHori.remove();
        if(this.backBayDistHoris)
            this.backBayDistHoris.forEach((dm) => {
                dm.remove();         
            });   
        if(this.leftDistHori)
            this.leftDistHori.remove();
        if(this.lowHeightDistHori)
            this.lowHeightDistHori.remove();
        if(this.peakHeightDistHori)
            this.peakHeightDistHori.remove();
    }

    
    getCollisionClass(){
        return [CORE.collisions.classes.none];
    }

    getTypeDisplayName(){
        return `Main Structure`;
    }

    getBuildContextFor(subCompId){
        // this function allows the model to rebuild a component with that component's parent context

        let context = {
        }

        
       
        return context;
    }
}
