import * as THREE from 'three';

export default class ColorHelper{

    static allColors=[];
    static panelColors=[]; // roof, wall, wainscot, soffit
    static trimColors=[]; // gutter, wainscot trim, 

    static getConstraintColorFromInt(val){
        let index = val%10;
        return this.getConstraintPalette()[index].hex;
    }

    static getConstraintPalette(){
        return [            
            {id: 0, hex:`ff0000`},
            {id: 1, hex:`fc4444`},
            {id: 2, hex:`fc6404`},
            {id: 3, hex:`fcd444`},            
            {id: 4, hex:`8cc43c`},
            {id: 5, hex:`029658`},
            {id: 6, hex:`1abc9c`},
            {id: 7, hex:`5bc0de`},
            {id: 8, hex:`6454ac`},
            {id: 9, hex:`fc8c84`},
        ]
    }

    static getRandomColor() {
        // Generate random RGB values between 0 and 1
        var r = Math.random();
        var g = Math.random();
        var b = Math.random();
      
        // Create and return the THREE.Color object
        return new THREE.Color(r, g, b);
      }


    static first(){
        const firstKey = Object.keys(ColorHelper.allColors)[0];
        const firstValue = ColorHelper.allColors[firstKey];
        return firstValue;
    }

    static to3(id){
        // returns the threejs compatible hex value, or the first color in the palette

        //  Steel Ninja is migrating from object color values to int color values
        // if we get an object,
        if(typeof(id) == 'object'){
            // just pick the first color in the color palette            
            return Number(ColorHelper.first().tjs);
        }

        // look up the referenced color
        
        let refColor =ColorHelper.allColors[id];
        if(!refColor) 
        {
            console.log(refColor)
            return Number(ColorHelper.first().tjs);
        }

        return Number(refColor.tjs);
    }


    static bangToHex(bang){
        return bang.substr(1);
    }

    static tjsToHex(tjs){
        return bang.substr(2);
    }

    static hexToTjs(hex){
        return `0x${hex}`
    }

    static hexToBang(hex){
        return `#${hex}`
    }

        
}
