
// Dynamically import all .md files from the directory
//const articles = importAll(require.context("!!raw-loader!@/help/articles", false, /\.md$/));
export let articles=[
  {
    title: 'Introduction',
    id: 'Introduction',
    src: require('!!raw-loader!@/help/articles/introduction.md').default,
    tags: ['into']
  },
  {
    title: 'Bulk Item Import Guidelines',
    id: 'ItemBulkImport',
    src: require('!!raw-loader!@/help/articles/itemBulkImport.md').default,
    tags: ['bulk import', 'item import']
  },
  {
    title: 'Detailed Bid Sheet',
    id: 'BidSheetDetailed',
    src: require('!!raw-loader!@/help/articles/bidsheet/BidSheetDetailed.md').default,
    tags: ['bid sheet', 'bidsheet', 'full']
  },
  {
    title: 'Summary Bid Sheet',
    id: 'BidSheetSummary',
    src: require('!!raw-loader!@/help/articles/bidsheet/BidSheetSummary.md').default,
    tags: ['bid sheet', 'bidsheet', 'summary']
  },
  {
    title: '3D Designer Intro',
    id: 'DesignerIntro',
    src: require('!!raw-loader!@/help/articles/designer/DesignerIntro.md').default,
    tags: ['designer', '3d', 'intro']
  },
  {
    title: '3D Designer Save',
    id: 'DesignerSave',
    src: require('!!raw-loader!@/help/articles/designer/DesignerSave.md').default,
    tags: ['designer', '3d', 'save']
  },
  {
    title: 'Outdated Pricing',
    id: 'OutdatedPricing',
    src: require('!!raw-loader!@/help/articles/project/OutdatedPricing.md').default,
    tags: ['pricing', 'outdated', 'refresh']
  },
  {
    title: 'Price Lock',
    id: 'PriceLock',
    src: require('!!raw-loader!@/help/articles/project/PriceLock.md').default,
    tags: ['pricing', 'lock', 'unlock']
  },
  {
    title: 'Bill of Materials',
    id: 'ProjectBom',
    src: require('!!raw-loader!@/help/articles/project/ProjectBom.md').default,
    tags: ['bom','bill','materials','download','file']
  },
  {
    title: 'Project Creation',
    id: 'ProjectCreation',
    src: require('!!raw-loader!@/help/articles/project/ProjectCreation.md').default,
    tags: ['project','new','create',]
  },
  {
    title: 'MBS .USR file',
    id: 'ProjectMbs',
    src: require('!!raw-loader!@/help/articles/project/ProjectMbs.md').default,
    tags: ['mbs','download','usr','file']
  },
  {
    title: 'Project State',
    id: 'ProjectState',
    src: require('!!raw-loader!@/help/articles/project/ProjectState.md').default,
    tags: ['project', 'state', 'pipeline', 'review']
  },
  {
    title: 'User Roles',
    id: 'Roles',
    src: require('!!raw-loader!@/help/articles/system/Roles.md').default,
    tags: ['user','role','permission']
  },
  {
    title: 'Pricing Models',
    id: 'PricingModels',
    src: require('!!raw-loader!@/help/articles/setup/PricingModels.md').default,
    tags: ['pricing','model','price']
  },
  
  {
    title: 'Pricing Levels',
    id: 'PricingLevels',
    src: require('!!raw-loader!@/help/articles/setup/PricingLevels.md').default,
    tags: ['pricing','level','price']
  },
  {
    title: 'Item Code Placeholders',
    id: 'ItemCodePlaceholders',
    src: require('!!raw-loader!@/help/articles/setup/ItemCodePlaceholders.md').default,
    tags: ['item','code','placeholder']
  },
  {
    title: 'Colors',
    id: 'Colors',
    src: require('!!raw-loader!@/help/articles/setup/Colors.md').default,
    tags: ['color', 'panel', 'trim', 'prime', 'finish', 'mbs', '3d', 'hex', 'paint', 'coating']
  },
  {
    title: 'Item List',
    id: 'ItemList',
    src: require('!!raw-loader!@/help/articles/setup/ItemList.md').default,
    tags: ['item', 'list', 'itemlist', 'code']
  },
  {
    title: 'Item Mappings',
    id: 'ItemMapping',
    src: require('!!raw-loader!@/help/articles/setup/ItemMapping.md').default,
    tags: ['item', 'map', 'mapping', 'itemmap', 'code', 'ninja']
  },
  {
    title: 'Item Setup Overview',
    id: 'ItemOverview',
    src: require('!!raw-loader!@/help/articles/setup/ItemOverview.md').default,
    tags: ['item', 'setup', 'map', 'mapping', 'itemmap', 'code', 'ninja', 'bom', 'placeholder', 'encod']
  },
  {
    title: 'Supplier Setup Overview',
    id: 'SupplierSetupOverview',
    src: require('!!raw-loader!@/help/articles/setup/SupplierSetupOverview.md').default,
    tags: ['start','supplier', 'item', 'setup', 'map', 'mapping', 'itemmap', 'code', 'ninja', 'bom', 'placeholder', 'encod']
  }
]

